<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'OnlineServices'"
      :ActiveMenu="'PrepaideTag'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Get Prepaid eTag"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Prepaid Prepaid eTag"
        breadcrumbitem3="New"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

           <!-- begin nav-pills -->
      <ul class="nav nav-pills">
        <li class="nav-items">
          <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active nav-pills-tab-1">
            <span class="d-sm-none">Apply</span>
            <span class="d-sm-block d-none">Apply</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">Orders</span>
            <span class="d-sm-block d-none">Orders</span>
          </a>
        </li>
      </ul>
      <!-- end nav-pills -->

        <!-- begin tab-content -->
        <div class="tab-content">

      <div class="tab-pane fade active show" id="nav-pills-tab-1">
        <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Apply
                      <small class="text-ellipsis">Prepaid eTag</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-3">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Complete Registration</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->
                    <div class="d-flex">
<a class="w-60px" href="javascript:;">
<img src="https://www.taxiassociations.co.za/img/etolltag.jpg" alt="" class="mw-50 rounded">
</a>
<div class="ps-3 flex-1">
<h5 class="mb-2">Sanral Prepaid Prepaid eTag</h5>
<p class="mb-1">How It Works</p>
<p class="mb-1">
  <ol>
  <li>Lorem ipsum dolor sit amet</li>
  <li>Consectetur adipiscing elit</li>
</ol>
</p>
</div>
</div>
<hr class="bg-gray-500"/>
<br/>

                    <div class="row">

                      <div class="col-md-12">
                      <div class="row panel panel-inverse">


                        <!-- begin col-8 -->
                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                             Select Vehicles
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <select
                                name="TaxiAffiliationName"
                                class="form-control"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="ADDTAXIASSOCIATIONS._fk_taxi_affiliation_id"
                              >
                                <option disabled value>Select Taxi Affiliation</option>
                                <option
                                  v-for="item in TAXIAFFILIATION"
                                  v-bind:key="item._id"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->

                       
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Vehicle Register Number
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="e.g. RASGP84652DFD/"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._ras_registration_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Licence Plate:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="taxiassociationname"
                                placeholder="e.g. Midrand Taxi Association"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._name"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                           <!-- begin form-group -->
                           <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Make
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="taxiassociationname"
                                placeholder="e.g. Midrand Taxi Association"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._name"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                           <!-- begin form-group -->
                           <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Model
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="taxiassociationname"
                                placeholder="e.g. Midrand Taxi Association"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._name"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Vin Number
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="taxiassociationname"
                                placeholder="e.g. Midrand Taxi Association"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._name"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Engine Number
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="taxiassociationname"
                                placeholder="e.g. Midrand Taxi Association"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._name"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Fuel Type
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="Abbreviation"
                                placeholder="e.g. MTA"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._abbriviated_name"
                              />
                            </div>
                          </div>
                        </div>
                        <!-- end col-8 -->

                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Owner ID Number
                              <span class="text-danger"></span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                name="MaxNumberOfOperators"
                                placeholder="Enter Number Or Leave Empty"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._maximum_number_members"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Owner Name & Surname
                              <span class="text-danger"></span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                name="MaxNumberOfTaxisOperators"
                                placeholder="Enter Number Or Leave Empty"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._maximum_number_vehicles"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Cellphone Number
                              <span class="text-danger"></span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                name="MaxNumberOfTaxisOperators"
                                placeholder="Enter Number Or Leave Empty"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._maximum_number_vehicles"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Email Address
                              <span class="text-danger"></span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                name="MaxNumberOfTaxisOperators"
                                placeholder="Enter Number Or Leave Empty"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._maximum_number_vehicles"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Delivery Address
                              <span class="text-danger"></span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                name="MaxNumberOfTaxisOperators"
                                placeholder="Enter Number Or Leave Empty"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._maximum_number_vehicles"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                           <!-- begin form-group -->
                           <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Special Instructions
                              <span class="text-danger"></span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                name="MaxNumberOfTaxisOperators"
                                placeholder="Enter Number Or Leave Empty"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._maximum_number_vehicles"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                         
                        </div>
                       

                    
                      </div>
                    </div>

                 
                    </div>
                    <!-- end row -->
                  </fieldset>
                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->
              

                <!-- begin step-3 -->
                <div id="step-3">
                  <div class="jumbotron m-b-0 text-center">
                    <h2 class="text-inverse">Successfully</h2>
                    <p class="m-b-30 f-s-16 ">
                     
                    </p>
                    <p>
                      <a href="#" class="btn btn-primary btn-lg hide">Proceed to User Profile</a>
                    </p>
                  </div>
                </div>
                <!-- end step-3 -->
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>
        <!-- end col-12 -->
      </div>
      </div>

      <!-- begin row -->

       <!-- begin tab-pane -->
       <div class="tab-pane fade" id="nav-pills-tab-2">
        <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <div class="table-responsive">
            <table class="table table-invoice">
              <thead>
                <tr>
                  <th>Owner Full Name</th>
                  <th>Mobile No</th>
                  <th>License Plate</th>
                  <th>License Fee</th>
                  <th>Expiry Date</th>
                  <th>Admin Fees</th>
                  <th>Delivery Fee</th>
                  <th>Applcation Date</th>
                  <th>Date Delivered</th>
                  <th>Status</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item) in GETALL"
                  v-bind:key="item.id"
                >
                  <td>{{item.fueltypes_desc}}</td>
                  <td>{{item.fuel_price | newcurrencywithSymbol}}</td>
                  <td>{{item.vatpercentage | newcurrencywithSymbol}}</td>
                  <td>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.is_active, 'label-secondary':!item.is_active  }"
                      >{{item.rebatefeepercentage}}%</span>
                      </td>

                  <td>{{item.fueltypes_code}}</td>
                  <td class="with-btn valign-middle" nowrap>
                      <div class="btn-group" role="group" aria-label="Basic example">
                      <button v-if="!item.is_active && item.deleted_by===null" type="button" @click="EnableDisableAccount(context,item,true)" class="btn btn-danger"
                        :disabled="$wait.is('UpdateNextOfKinAction-' +item.id)">
                 <v-wait v-bind:for="'UpdateNextOfKinAction-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                   Delete
                  </v-wait>
                        
                    </button> 

                    <button v-if="item.is_active" type="button" @click="Edit(context,item)" class="btn btn-info"
                      :disabled="$wait.is('UpdateNextOfKinAction-' +item.id)"
                    >
                 <v-wait v-bind:for="'UpdateNextOfKinAction-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Edit
                  </v-wait>
                        
                    </button> 
                  </div>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
        </div>

      </div>
        <!-- end tab-pane -->

      

      <!-- end row -->

  

      <!-- begin #Main Body Template -->

      

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers(
  "fuelmanager_dashboard"
);

export default {
  name: "AddSystemUser",
  data: function () {
    return {
      TAXIAFFILIATION: [],
      GETALLSUBURBS: [],
      BILLINGTYPE: [],
      GETBILLINGTYPES: [],
      BANKS: [],
      TPAYPROFILE: this.$store.getters["auth/TPAYPROFILE"],
      isUserNameExists: true,
      ISMOBILEVERIFIED: false,
      GETDRIVERSBYASSID: [],
      GETALL: [],
      ASSOCIATIONRANKS: [],
      ASSOCIATIONROUTES: [],
      OPT: "",
      USERNAME: "",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      SERPROFILE: this.$store.getters["auth/USERPROFILE"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      taxi_association_id:
        this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      selectedUserGroupId: 0,
      GETALLUSERGROUPS: [],
      resolutionWidth: undefined,
      resolutionHeight: undefined,
      hacksmartwizard: false,
      SELECTEDRANK: null,
      SELECTEDTAMSRANK: null,
      EDITMODE: false,
      TAXIFARE: 0,
      ADDSYSTEMUSER: {
        taxi_association_id:
          this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
        fueldescription: "",
        vatpercentage: 15.0,
        fuelprice: 0.0,
        rebatefeepercentage: 0.0,
        fuelcode: "",
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      EDITSYSTEMUSER: {
        id: 0,
        fueldescription: "",
        vatpercentage: 15.0,
        fuelprice: 0.0,
        rebatefeepercentage: 0.0,
        fuelcode: "",
        updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      VERIFIY: {
        user_id: "",
        webpushtoken: "",
        one_time_pin: "",
      },
      ADDTAXIASSOCIATIONS: Object.assign(
        {},
        this.$store.getters["TA/ADDTAXIASSOCIATIONS"]
      ),
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    CapturePhotosControl,
  },
  computed: {
    ...mapGetters(["GETALLTITLES", "GETALLGENDERS"]),
  },
  watch: {
    USERNAME: function (val) {
      if (val.toString().length > 3) {
        this.context.$store
          .dispatch("tpayauth/LicenseNoExists", {
            licencenumber: val,
          })
          .then((rb) => {
            this.isUserNameExists = rb.Status;
          });
      }
    },
  },
  methods: {
    ...mapActions([
      "getall",
      "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted",
    ]),
    init: (file) => {},
    Edit: (context, item) => {
      context.EDITSYSTEMUSER.id = item.id;
      context.ADDSYSTEMUSER.fueldescription = item.fueltypes_desc;
      context.ADDSYSTEMUSER.vatpercentage = item.vatpercentage;
      context.ADDSYSTEMUSER.fuelprice = item.fuel_price;
      context.ADDSYSTEMUSER.rebatefeepercentage = item.rebatefeepercentage;
      context.ADDSYSTEMUSER.fuelcode = item.fueltypes_code;
      context.EDITMODE = true;

      $("#wizard").smartWizard("prev");
      $(".sw-btn-next").text("Edit");
      $(".nav-pills-tab-1").click();
    },
    GetALLRoutesById: (context, id) => {
      context.$store
        .dispatch("fuelmanager_dashboard/getFuelTypeByTaxiAssId", {
          id,
          getbyid: context.USERID,
          router: context.$router,
        })
        .then((rb) => {
          context.GETALL = rb.data;
        })
        .finally((error) => {
          var options = {
            responsive: false,
            autoFill: false,
            colReorder: false,
            keys: false,
            rowReorder: false,
            select: false,
            order: [[0, "asc"]],
          };

          // if ($(window).width() <= 767) {
          //   options.rowReorder = false;
          //   options.colReorder = false;
          //   options.autoFill = false;
          // }

          $(".table-invoice").DataTable(options);
        });
    },
    getbyAssId: (context) => {
      context.$store
        .dispatch("tpay_systemuser/GetALLRANKSById", {
          id: context.taxi_association_id,
        })
        .then((data) => {
          context.ASSOCIATIONRANKS = data.Data;
        })
        .catch((error) => {
          console.log("these are the getter errors:", error);
        })
        .finally((error) => {
          $("#data-table-default").DataTable({
            responsive: true,
          });
        });
    },
    getRoutes: (context) => {
      context.$store
        .dispatch("TA/getTaxiAssRoutes", {
          router: context.$router,
          id: context.taxi_association_id,
        })
        .then((data) => {
          context.ASSOCIATIONROUTES = data;
        })
        .catch((error) => {
          console.log("these are the getter errors:", error);
        })
        .finally((error) => {
          $("#data-table-default").DataTable({
            responsive: true,
          });
        });
    },
  },
  mounted: function () {
    //this.get(this.context);

    App.init();

    this.getbyAssId(this.context);
    this.GetALLRoutesById(this.context, this.taxi_association_id);

    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "slide",
      transitionSpeed: 400,
      useURLhash: false,
      showStepURLhash: false,
      toolbarSettings: {
        toolbarPosition: "bottom",
      },
    });

    $("#wizard").data("context", this.context);

    $("#wizard").on(
      "leaveStep",
      function (e, anchorObject, stepNumber, stepDirection) {
        const context = $("#wizard").data("context");
        const currentstep = stepNumber + 1;
        var res = $('form[name="form-wizard"]')
          .parsley()
          .validate("step-" + currentstep);

        if (currentstep === 2 && stepDirection == "backward") {
          context.hacksmartwizard = false;
          $(".sw-btn-next").text("Next");
        }

        if (context.hacksmartwizard) return true;

        $(".sw-btn-next").text("Next");

        if (currentstep === 1 && stepDirection == "forward") {
          if (!res) {
            return res;
          }

          $(".sw-btn-next").text("Submit");
        }

        if (currentstep === 1 && stepDirection == "forward") {
          const context = $("#wizard").data("context");

          if (context.EDITMODE) {
            context.ADDSYSTEMUSER.vatpercentage = parseFloat(
              context.ADDSYSTEMUSER.vatpercentage
            );
            context.ADDSYSTEMUSER.rebatefeepercentage = parseFloat(
              context.ADDSYSTEMUSER.rebatefeepercentage
            );
            context.ADDSYSTEMUSER.fuelprice = parseFloat(
              context.ADDSYSTEMUSER.fuelprice
            );

            context.EDITSYSTEMUSER.fueldescription =
              context.ADDSYSTEMUSER.fueldescription;
            context.EDITSYSTEMUSER.vatpercentage =
              context.ADDSYSTEMUSER.vatpercentage;
            context.EDITSYSTEMUSER.fuelprice = context.ADDSYSTEMUSER.fuelprice;
            context.EDITSYSTEMUSER.rebatefeepercentage =
              context.ADDSYSTEMUSER.rebatefeepercentage;
            context.EDITSYSTEMUSER.fuelcode = context.ADDSYSTEMUSER.fuelcode;

            context.$Notify
              .YesNo(false, { context: context, item: context.EDITSYSTEMUSER })
              .then((value) => {
                if (value) {
                  $(".sw-btn-next")
                    .text("")
                    .append('<i class="fas fa-spinner fa-spin"></i>')
                    .attr("disabled", "disabled");

                  value.router = value.context.$router;
                  value.context.$store
                    .dispatch("fuelmanager_dashboard/EditFuelType", {
                      id: value.item.id,
                      AddModel: value.item,
                    })
                    .then((parsedResult) => {
                      if (!parsedResult.status) {
                        $(".sw-btn-next")
                          .text("Submit")
                          .removeAttr("disabled", "disabled");
                        value.context.$Notify.Error(parsedResult.message, "");
                        return;
                      }
                      value.context.hacksmartwizard = true;
                      $("#wizard").smartWizard("next");
                      $(".sw-btn-next")
                        .text("Done")
                        .removeAttr("disabled", "disabled");

                      value.context.EDITMODE = false;
                      value.context.GetALLRoutesById(
                        value.context,
                        value.context.taxi_association_id
                      );
                    })
                    .catch((error) => {
                      $(".sw-btn-next")
                        .text("Submit")
                        .removeAttr("disabled", "disabled");

                      value.context.$Notify.Error(parsedResult.message, "");
                    })
                    .finally(() => {});
                }
              });
          } else {
            context.ADDSYSTEMUSER.vatpercentage = parseFloat(
              context.ADDSYSTEMUSER.vatpercentage
            );
            context.ADDSYSTEMUSER.rebatefeepercentage = parseFloat(
              context.ADDSYSTEMUSER.rebatefeepercentage
            );
            context.ADDSYSTEMUSER.fuelprice = parseFloat(
              context.ADDSYSTEMUSER.fuelprice
            );

            context.$Notify
              .YesNo(false, { context: context, item: context.ADDSYSTEMUSER })
              .then((value) => {
                if (value) {
                  $(".sw-btn-next")
                    .text("")
                    .append('<i class="fas fa-spinner fa-spin"></i>')
                    .attr("disabled", "disabled");

                  value.router = value.context.$router;
                  value.context.$store
                    .dispatch("fuelmanager_dashboard/AddFuelType", value.item)
                    .then((parsedResult) => {
                      if (!parsedResult.status) {
                        $(".sw-btn-next")
                          .text("Submit")
                          .removeAttr("disabled", "disabled");
                        value.context.$Notify.Error(parsedResult.message, "");
                        return;
                      }
                      value.context.hacksmartwizard = true;
                      $("#wizard").smartWizard("next");
                      $(".sw-btn-next")
                        .text("Done")
                        .removeAttr("disabled", "disabled");

                      value.context.GetALLRoutesById(
                        value.context,
                        value.context.taxi_association_id
                      );
                    })
                    .catch((error) => {
                      $(".sw-btn-next")
                        .text("Submit")
                        .removeAttr("disabled", "disabled");

                      value.context.$Notify.Error(parsedResult.message, "");
                    })
                    .finally(() => {});
                }
              });
          }

          return false;
        }

        return res;
      }
    );
  },
};
</script>

<style scoped>
</style>

