
/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';

export default {
  namespaced: true,
  state: {
   taxiassociationbillings:[],
   taxiassociationbillings_receiptstock:[],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
   

   GETASSOCIATIONBILLING: state => state.taxiassociationbillings,
   GETASSOCIATIONRECEIPTSTOCK: state => state.taxiassociationbillings_receiptstock,
  },

  mutations: {
    LOADED: (state, _data) => {
      if (_data !== undefined) {
        state.taxiassociationbillings = _data;
      }
    },

    RECEIPTSTOCKLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.taxiassociationbillings_receiptstock = _data;
      }
    },

    ADDED: (state, _data) => {
      state.taxiassociationbillings.push(_data);
    },
    UPDATED: (state, _data) => {
      if (_data !== undefined) {
        Object.assign(state.taxiassociationbillings.filter(item => item._id === _data._id)[0], _data);
      }
    },
    RESTOREDELETED: (state, { id }) => {
      if (id !== undefined) {
        state.taxiassociationbillings.filter(item => item._id === id)[0]._deleted_by = undefined;
        state.taxiassociationbillings.filter(item => item._id === id)[0]._date_deleted = undefined;
      }
    },
    MARKASDELETE: (state, { id, deletedby }) => {
      if (id !== undefined) {
        state.taxiassociationbillings.filter(item => item._id === id)[0]._deleted_by = deletedby;
        state.taxiassociationbillings.filter(item => item._id === id)[0]._date_deleted = Date.now;
      }
    },


  },
  actions: {
    /* Async */
    getByPaging({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _waitObj }) {
      const { _wait } = _waitObj;
      const _waitname = _waitObj.name;
      const router = _waitObj._router;

      if (_waitname) { _wait.start(_waitname); }
      this._vm.$TAMSFIN_API.getByPaging(router, rootGetters['auth/TOKEN'],
        config.ASSOCIATIONBILLING_URL,
        {
          isactive: true,
          skip: 0,
          length: 1,
        }).then((parsedResult) => {
          console.log(parsedResult);
          if (parsedResult.status) {
            commit('LOADED', parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }

          if (_waitname) { _wait.end(_waitname); }
        }).catch((error) => {
          console.log(error);
          commit('LOGINFAILED', error.data);
          if (_waitname) { _wait.end(_waitname); }
        });
    },
    getAll({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _waitObj }) {
      const { router } = _waitObj;

      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.ASSOCIATIONBILLING_URL,
      };
      this._vm.$TAMSFIN_API.getAll(requestheader).then((parsedResult) => {
        console.log(parsedResult);
        if (parsedResult.status) {
          commit('LOADED', parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    },

    getById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const { id, router } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.ASSOCIATIONBILLING_URL,
        };

        this._vm.$TAMSFIN_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            commit('LOADED', parsedResult.data);
            resolve();
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },

    getReceiptstockById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const { id, router } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_RECEIPTSTOCK_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            commit('RECEIPTSTOCKLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
   
    getAllTransactionsByDate({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { payload, router }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.ASSOCIATIONBILLING_URL,
        };

        this._vm.$TAMSFIN_API.getCustomURL(requestheader,
          payload
        ).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            //commit('LOADED', parsedResultOP.data);
            resolve(parsedResultOP);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          commit('LOGINFAILED', error.data);
        });
      });
    },


    add({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          item, router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.ASSOCIATIONBILLING_URL,
        };
        this._vm.$TAMSFIN_API.add(requestheader,
          item).then((parsedResult) => {

            if (parsedResult.status) {

              commit('ADDED', item);
              resolve(parsedResult.data[0]);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },
  },
};





// ===
// Private helpers
// ===

function navigateTo(router, _userProfile) {
  if (_userProfile.is_system_admin) {
    router.push('sysadmin_dashboard');
  }
}
