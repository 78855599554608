<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TaxiVIP'"
      :ActiveMenu="'ManageVIPCards'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="VIP Commuter Cards"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="TaxiVIP"
        breadcrumbitem3="Manage Commuter Cards"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!--Begin col-lg-12-->
        <div class="col-lg-12">
          <!-- begin panel -->
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">Commuter Cards</h4>
            </div>
            <!-- end panel-heading -->

            <!-- <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <a
                  data-toggle="modal"
                  data-target="#AddModal"
                  class="btn btn-white"
                  href="javascript:;"
                  @click="clearAdded(GETSELECTED)"
                >
                  <i class="fa fa-plus"></i>
                  Add suburbs
                </a>
              </div>
            </div>-->

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-default" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Commuters Name</th>
                    <th class="text-nowrap">Commuters Number</th>
                    <th class="text-nowrap">Card Number</th>
                    <th class="text-nowrap">Balance</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Last Updated By</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETALLCOMUTTERCARDS"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.commuters_name}}</td>
                    <td>{{item.commuters_mobilenumber}}</td>
                    <td>{{item.cardinventory_id}}</td>
                    <td>{{item.balance | currencywithSymbol}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item.deleted_by }"
                        v-if="item.deleted_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.is_active && !item.blocked , 'label-danger':!item.is_active || !item.blocked   }"
                        v-if="!item.deleted_by"
                      >{{item.is_active && !item.blocked ? 'Active' : 'Blocked' }}</span>
                    </td>
                    <td>{{item.updatedby_name || item.addedby_name}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <router-link
                        :to="{ name: 'commuterprofile', params: { id: item.commuters_id }}"
                        class="btn btn-sm btn-success width-100 m-r-2"
                      >Profile</router-link>
                      <button
                        v-show="!item.blocked"
                        type="button"
                        class="btn btn-sm btn-yellow width-80"
                        @click="() => { blockCard(context,item); item.blocked = true; }"
                      >Block</button>
                      <button
                        v-show="item.blocked"
                        type="button"
                        class="btn btn-sm btn-danger btnadded width-80"
                        @click="() => { unblockCard(context,item); item.blocked = false; }"
                      >Blocked</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
          <!--End col-lg-12-->
        </div>

        <!-- end row -->
      </div>
      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("taxivip");

export default {
  name: "Managesuburb",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      skip: 0,
      length: 10000,
      updated_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
      cardResult: false,
      GETALLCOMUTTERCARDS: []
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  beforeMount: function() {
    
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters([""]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "delete",
      "edit",
      "add",
      "markAsdelete",
      "restoreDeleted"
    ]),
    blockCard: (context, payload) => {
      console.log('Block card data: ',payload);
      context.block(context,payload.id);
    },
    unblockCard: (context, payload) => {
      console.log('Unblock card data: ',payload);
      context.unblock(context,payload.id);
    },
    getCommuterCardsByAssId: context => {
      context.$store
        .dispatch("taxivip/getCommuterCardsByAssId", {
          taxiassociationid: context.taxiassociationid,
          skip: context.skip,
          len: context.length,
          router: context.$router
        })
        .then(data => {
          context.GETALLCOMUTTERCARDS = data;
          console.log("conmmuter cards found :", data);
          
        })
        .catch(error => {
          console.log("conmmuter cards errors :", error);
        }).finally(() => {
          $("#data-table-default").DataTable({
            responsive: false
          });
        });
    },
    block: (context, item) => {
      context.$Notify
        .YesNo(false, { context, item })
        .then(value => {
          if (value) {
            value.router = value.context.$router;
            value.context.$store
              .dispatch("taxivip/blockCommuterCard", {
                AddModel: {
                  commutercard_id: value.item,
                  updated_by: value.context.updated_by
                },
                router: value.router
              })
              .then(result => {
                console.log('card blocked successfully: ',result);
                value.context.$Notify.Success("Blocked Successfully!", "");
              })
              .catch(error => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    unblock: (context, item) => {
      context.$Notify
        .YesNo(false, { context, item })
        .then(value => {
          if (value) {
            value.router = value.context.$router;
            value.context.$store
              .dispatch("taxivip/unblockCommuterCard", {
                AddModel: {
                  commutercard_id: value.item,
                  updated_by: value.context.updated_by
                },
                router: value.router
              })
              .then(result => {
                console.log('card blocked successfully: ',result);
                value.context.$Notify.Success("Restored Successfully!", "");
              })
              .catch(error => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },

    
  },
  mounted: function() {

    App.init();

    this.getCommuterCardsByAssId(this.context);

    $(".btnadded")
      .mouseenter(function() {
        $(this)
          .removeClass("btn-danger")
          .addClass("btn-success")
          .text("Unblock");
      })
      .mouseleave(function() {
        $(this)
          .removeClass("btn-success")
          .addClass("btn-danger")
          .text("blocked");
      });
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

