import { render, staticRenderFns } from "./addroute.vue?vue&type=template&id=aeda6cc6&scoped=true&"
import script from "./addroute.vue?vue&type=script&lang=js&"
export * from "./addroute.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aeda6cc6",
  null
  
)

export default component.exports