<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'RankManager'"
      :ActiveMenu="'RealtimeRankActivities'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="All Taxi Ranks"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Ranks"
        breadcrumbitem3="All"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->
      <!-- begin row -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">All Taxi Ranks</h4>
            </div>
            <!-- end panel-heading -->

            <div v-if="!dataLoaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-default" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Rank Name</th>
                    <th class="text-nowrap">Rank Description</th>
                    <th class="text-nowrap hidden-print">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETTAXIRANKS"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.description}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <router-link
                        :to="{ name: 'rankmanager', params: { id: item.id,desc: item.name }}"
                        class="btn btn-sm btn-warning width-80 m-r-2"
                      >Realtime</router-link>
                       <router-link
                        :to="{ name: 'rankmanagerdashboard', params: { id: item.id,desc: item.name }}"
                        class="btn btn-sm btn-primary width-80 m-r-2"
                      >History</router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TA");

export default {
  name: "ManageOperators",
  data: function () {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      skip: 0,
      length: 1000000,
      GETOPERATORSBYASSID: [],
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      taxiassociationid: this.$route.params.id,
      // accountid: this.$store.getters["TaxiDrivers/GETDRIVERACCOUNT"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      dataLoaded: false,
      GETTAXIRANKS: {}
    };
  },
  beforeMount: function () {
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions([
      "getAssociationRanksByAssId",
    ]),
    
    getbyid: (context, taxiassociationid) => {
      
      
      var router = context.router;

      context
        .getAssociationRanksByAssId({ router, id: context.taxiassociationid })
        .then((data) => {
          console.log("operators found: ", data);
          context.GETTAXIRANKS = data;
          context.dataLoaded = true;

          // $("#data-table-default").DataTable({
          //   responsive: true,
          // });

        })
        .catch((error) => {
          console.log("operator errors:", error);
        })
        .finally(() => {
          
        });
    },

  },
  beforeDestroy: function () {},
  mounted: function () {
    this.getbyid(this.context, this.taxiassociationid);
    App.init();

    $(".byrole").addClass("hide");

    // TableManageCombine.init();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

