<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TaxiAssociation'"
      :ActiveMenu="'ManageTaxiAssociationRanks'"
    />

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        :pageheaderTitle="'Rank Summary -> ' + taxiassociation_ranks_desc"
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Association"
        breadcrumbitem3="Rank Summary"
        pageheaderdescription
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">Taxi Association Ranks</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <a
                  data-toggle="modal"
                  data-target="#AddModal"
                  class="btn btn-white"
                  href="javascript:;"
                >
                  <i class="fa fa-plus"></i>
                  Add Rank Route
                </a>
              </div>
            </div>

            <!-- Add Modal HTML START-->
            <div id="AddModal" class="modal fade">
              <div class="modal-dialog">
                <div class="modal-content">
                  <form>
                    <div class="modal-header">
                      <h4 class="modal-title">New Rank Route</h4>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                      >&times;</button>
                    </div>
                    <div class="modal-body">
                      <div class="col-md-12">
                        <!-- begin form-group row -->
                              <div class="form-group row m-b-10">
                                <label class="col-md-3 text-md-right col-form-label">Association Routes
                                  <span class="text-danger">*</span>
                                </label>
                                <div class="col-md-9">
                                  <select
                                    class="form-control"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    data-parsley-required="false"
                                    v-model="ADDRANKROUTE.taxiassociation_routes_id"
                                  >
                                    <option value="0">Select Association Route</option>
                                    <option
                                      v-bind:key="item._id"
                                      v-for="item in ASSOCIATIONROUTES"
                                      :value="item._id"
                                    >{{item._name}}</option>
                                  </select>
                                </div>
                              </div>
                              <!-- end form-group row -->
                      </div>
                    </div>
                    <div class="modal-footer">
                      <input
                        type="button"
                        class="btn btn-default"
                        data-dismiss="modal"
                        value="Cancel"
                      />
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-80"
                        data-dismiss="modal"
                        :disabled="$wait.is('add-')"
                        @click="addRankRoute(context, ADDRANKROUTE)"
                      >
                        <v-wait v-bind:for="'add-'">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Save
                        </v-wait>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- Add Modal HTML START-->

            <!-- Add Modal HTML START-->
            <div id="EditModal" class="modal fade">
              <div class="modal-dialog">
                <div class="modal-content">
                  <form>
                    <div class="modal-header">
                      <h4 class="modal-title">Update Rank</h4>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                      >&times;</button>
                    </div>
                    <div class="modal-body">
                      <div class="col-md-12">
                        <!-- begin form-group row -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 text-md-right col-form-label">Name</label>
                          <div class="col-md-9">
                            <input
                              type="text"
                              name="BillingTypeName"
                              id="billing_type_name"
                              placeholder="Name"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <!-- end form-group row -->
                        <!-- begin form-group row -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 text-md-right col-form-label">Description</label>
                          <div class="col-md-9">
                            <input
                              type="text"
                              name="BillingTypeName"
                              id="billing_type_name"
                              placeholder="Name"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <!-- end form-group row -->
                      </div>
                    </div>
                    <div class="modal-footer">
                      <input
                        type="button"
                        class="btn btn-default"
                        data-dismiss="modal"
                        value="Cancel"
                      />
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-80"
                        data-dismiss="modal"
                        :disabled="$wait.is('update-')"
                      >
                        <v-wait v-bind:for="'update-'">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Save
                        </v-wait>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- Add Modal HTML START-->

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-default" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Association</th>
                    <th class="text-nowrap">Rank</th>
                    <th class="text-nowrap">Route</th>
                    <th class="text-nowrap">Date Added</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in RANKSUMMARY"
                    v-bind:key="item._id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.taxi_association_name}}</td>
                    <td>{{item.taxi_association_rank_name}}</td>
                    <td>{{item.taxi_association_route_name}}</td>
                    <td>{{item.added_by_name}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item.deleted_by }"
                        v-if="item.deleted_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.isactive, 'label-secondary':!item.isactive  }"
                        v-if="!item.deleted_by"
                      >{{item.isactive == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td class="with-btn valign-middle" nowrap>
                      <button
                        class="btn btn-primary btn-sm"
                        type="button"
                        :data-clipboard-text="`${item.fk_taxi_association_id}%${item.fk_taxi_association_ranks_id}%${item.fk_taxi_association_routes_id}%${item.taxi_association_name}%${item.taxi_association_rank_name}%${item.taxi_association_route_name}`"
                      >Copy Route Code</button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Edit Modal HTML START-->
              <div id="ViewModal" class="modal fade">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="text-center">
                        <h4 class="modal-title">Association Details</h4>
                      </div>
                      <div class="modal-body">
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Affiliation
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input type="text" placeholder="N/A" class="form-control" disabled />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            District
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input type="text" placeholder="N/A" class="form-control" disabled />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Abbreviation
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input type="text" placeholder="N/A" class="form-control" disabled />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Telephone
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input type="text" placeholder="N/A" class="form-control" disabled />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Mobile Number
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input type="text" placeholder="N/A" class="form-control" disabled />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Max Number Of Operators
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input type="text" placeholder="N/A" class="form-control" disabled />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Max Number Of Taxis Per Operator
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input type="text" placeholder="N/A" class="form-control" disabled />
                          </div>
                        </div>
                        <!-- end form-group -->
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Close"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Edit Modal HTML START-->
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { setTimeout } from "timers";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TA");

export default {
  name: "TaxiAssociationRankSummary",
  data: function () {
    return {
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      id: this.$route.params.id,
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      rankName: "",
      inrank: null,
      outofrank: null,
      routecount: null,
      RANKSUMMARY: [],
      ASSOCIATIONROUTES: [],
      taxiassociation_ranks_desc: this.$route.params.desc,
      ADDRANKROUTE: {
        taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        taxiassociation_routes_id: 0,
        taxiassociation_ranks_id: this.$route.params.id,
        
        added_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },

    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  beforeMount: function () {
    
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters([]),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions(["getall", "delete", "edit", "add"]),
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    clearAdded: (item) => {
      for (var propName in item) {
        item[propName] = undefined;
      }
    },

    addRankRoute: (context, item) => {
      
      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          value.item.taxiassociation_id = value.item.taxiassociation_id;
          value.item.taxiassociation_ranks_id = value.item.taxiassociation_ranks_id.toString();
          value.context.$wait.start("add-");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TA/addRouteByRankId", {
              AddModel: value.item,
              router: value.router,
            })
            .then((id) => {
              
              value.context.$wait.end("add-");
              value.context.$Notify.Success("Successfully Added!", "");
              context.getbyid(context);
              $("#AddModal").modal("hide");
              
            })
            .catch((error) => {
              value.context.$Notify.Error(error.data.message, "");
              value.context.$wait.end("add-");
            })
            .finally(() => {});
        }
      });
    },

    getbyid: (context) => {
      context.$store
        .dispatch("TA/getRankSummaryById", {
          id: context.id,
          router: context.$router,
        })
        .then((data) => {
          console.log("This is the rank summary: ", data);

          context.RANKSUMMARY = data;

          if (data != null && data != undefined && data.length != 0) {
            context.rankName = data[0].taxi_association_ranks_name;
            context.inrank = data[0].vehicles_in_rank;
            context.outofrank = data[0].vehicle_left_rank;

            context.routecount = data.map((element) => {
              if (element.time_out == null) {
                return (context.count = +1);
              }
            });
          }

          
        })
        .catch((error) => {
          console.log("summary errors: ", error);
        });
    },

    getRoutesByAssId: context => {
      context.$store
        .dispatch("TA/getTaxiAssRoutes", {
          router: context.$router,
          id: context.taxiassociationid
        })
        .then(data => {
          console.log("Association Routes:", data);
          context.ASSOCIATIONROUTES = data;
        })
        .catch(error => {
          console.log("Association Routes errors:", error);
        })
        .finally(() => {
        });
    },
    deletedata: (context, item, deletedby) => {
      context.$Notify
        .YesNo(false, { context, id: item._id, deletedby })
        .then((value) => {
          if (value) {
            value.context.$wait.start("delete-" + value.id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("district/markAsdelete", {
                _dataObj: value,
              })
              .then((id) => {
                value.context.$wait.end("delete-" + id);
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch((error) => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
  },
  mounted: function () {

    App.init();

    this.getbyid(this.context);
    this.getRoutesByAssId(this.context);
    var clipboard = new ClipboardJS(".btn");

    clipboard.on("success", function (e) {
      $(e.trigger).tooltip({
        title: "Copied",
        placement: "top",
      });
      $(e.trigger).tooltip("show");
      setTimeout(function () {
        var bootstrapVersion = handleCheckBootstrapVersion();
        if (bootstrapVersion >= 3 && bootstrapVersion < 4) {
          $(e.trigger).tooltip("destroy");
        } else if (bootstrapVersion >= 4 && bootstrapVersion < 5) {
          $(e.trigger).tooltip("dispose");
        }
      }, 500);
    });
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

