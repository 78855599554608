<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageDrivers'"
      :ActiveMenu="'Update'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Edit Taxi Driver"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Drivers"
        breadcrumbitem3="Update Taxi Driver"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Driver Information
                      <small class="text-ellipsis">Name, Address, ID No and DOB</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-2">
                    <span class="number">2</span>
                    <span class="info text-ellipsis">
                      Capture Photo
                      <small class="text-ellipsis">Driver Photo</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-3">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Upload Supporting Documents
                      <small
                        class="text-ellipsis"
                      >ID document, Proof of Address,License ect.....</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-4">
                    <span class="number">4</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Complete Registration</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <!-- begin row -->
                  <div class="row">
                    <!-- begin col-8 -->

                    <div class="col-md-12">
                      <div class="row panel panel-inverse">
                        <!-- begin col-8 -->
                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Title
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="UPDATETAXIDRIVER.title_id"
                              >
                                <option value="0" selected>Select a Title</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLTITLES"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Gender
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="UPDATETAXIDRIVER.country_id"
                              >
                                <option value="0" selected>Select a Gender</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLGENDERS"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              First Name
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATETAXIDRIVER.firstname"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Surname
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATETAXIDRIVER.surname"
                              />
                            </div>
                          </div>
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Country
                            </label>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="UPDATETAXIDRIVER.country_id"
                              >
                                <option value="0" selected>Select a Country</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLCOUNTRY"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Id Number
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATETAXIDRIVER.id_number"
                              />
                            </div>
                          </div>
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Date Of Birth
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <div
                                class="input-group date datepicker-disabled-past"
                                data-date-format="dd M yyyy"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Select Date"
                                  :value="UPDATETAXIDRIVER.date_of_birth | formatToShortDate"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Passport Number</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="passport"
                                value="910708987461121"
                                data-parsley-group="step-1"
                                class="form-control"
                                v-model="UPDATETAXIDRIVER.passport_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Mobile Number
                            </label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                name="mobilenumber"
                                id="masked-input-phone"
                                value="0819399999"
                                data-parsley-group="step-1"
                                class="form-control"
                                v-model="UPDATETAXIDRIVER.mobilenumber"
                              />
                            </div>
                          </div>
                          <!-- begin form-group -->
                        </div>
                        <!-- end col-8 -->

                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Existing Membership Number
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="membershipnumber"
                                value="INT-3201476"
                                data-parsley-group="step-1"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Driver Joined Date</label>
                            <div class="col-md-6">
                              <div
                                class="input-group date datepicker-driverjoined-date"
                                data-date-format="dd M yyyy"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Select Date"
                                  :value="UPDATETAXIDRIVER.taxi_driver_joined_date | formatToShortDate"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Driver License Number
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="lastname"
                                value="INT-3201476"
                                data-parsley-group="step-1"
                                class="form-control"
                                v-model="UPDATETAXIDRIVER.driver_license_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">License Expiry Date</label>
                            <div class="col-md-6">
                              <div
                                class="input-group date datepicker-licenseExpiry-date"
                                data-date-format="dd M yyyy"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Select Date"
                                  :value="UPDATETAXIDRIVER.drivers_license_expirydate | formatToShortDate"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">PDP Number</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="pdp_number"
                                id="masked-input-phone"
                                value="0829133030"
                                data-parsley-group="step-1"
                                class="form-control"
                                v-model="UPDATETAXIDRIVER.pdp_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">PDP Expiry Date</label>
                            <div class="col-md-6">
                              <div
                                class="input-group date datepicker-PDPexpiry-date"
                                data-date-format="dd M yyyy"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Select Date"
                                  :value="UPDATETAXIDRIVER.pdp_license_expirydate | formatToShortDate"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Telephone Number
                            </label>
                            <div class="col-md-6">
                              <input
                                name="telephone"
                                data-parsley-group="step-1"
                                class="form-control"
                                :value="UPDATETAXIDRIVER.telephone_number"
                              />
                            </div>
                          </div>
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Email</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="lastname"
                                value="siyakolisis@gmail.com"
                                data-parsley-group="step-1"
                                class="form-control"
                                v-model="UPDATETAXIDRIVER.email_address"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Fax Number
                            </label>
                            <div class="col-md-6">
                              <input
                                name="faxnumber"
                                id="masked-input-phone"
                                value="0819399999"
                                data-parsley-group="step-1"
                                class="form-control"
                                v-model="UPDATETAXIDRIVER.fax_number"
                              />
                            </div>
                          </div>
                          <!-- begin form-group -->
                          <!-- end form-group -->
                          <!-- end form-group -->
                        </div>

                        <div class="col-lg-6">
                          <!-- begin col-6 -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Physical Address
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                class="form-control"
                                v-model="UPDATETAXIDRIVER.physical_address"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Physical Address 2
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATETAXIDRIVER.physical_address2"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Suburb
                            </label>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                v-model="UPDATETAXIDRIVER.physical_suburb_id"
                              >
                                <option value="0" selected>Select A Suburb</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLSUBURBS"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>
                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Postal Address
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                class="form-control"
                                v-model="UPDATETAXIDRIVER.postal_address"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Postal Address 2
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                class="form-control"
                                v-model="UPDATETAXIDRIVER.postal_address2"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Suburb
                            </label>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="UPDATETAXIDRIVER.postal_suburb_id"
                              >
                                <option value="0" selected>Select A Suburb</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLSUBURBS"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>
                      </div>
                    </div>
                    <!-- end col-8 -->
                  </div>
                  <!-- end row -->

                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->

                <!-- begin step-2 -->
                <div id="step-2">
                  <CapturePhotosControl
                    :SessionID="CapturedPhotosSessionID"
                    :ShowStopStartWebCamControl="false"
                    :CameraWidth="'40%'"
                    :ShowPreviewControl="true"
                    :CameraHeight="'40%'"
                    :ClearData="true"
                  />
                  <!-- begin table-responsive -->
                  <!-- <div class="table-responsive">
                    <table class="table table-striped m-b-0">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Filename</th>
                          <th>Format</th>
                          <th>Size</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr :key="item" v-for="item in UPDATETAXIDRIVER._images">
                          <td width="1%" class="with-img">
                            <img :src="item.data" class="img-rounded height-30" />
                          </td>
                          <td>{{item.filename}}</td>
                          <td>{{item.mimetype}}</td>
                          <td>{{item.length}}</td>
                          <td class="with-btn" nowrap>
                            <a
                              href="#"
                              @click="deletefile(context,item.fileId, USERPROFILE.system_usersid)"
                              class="btn btn-sm btn-danger width-60"
                            >Delete</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div> -->
                  <!-- end table-responsive -->
                </div>

                <!-- end step-2 -->

                <!-- begin step-3 -->
                <div id="step-3">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->

                    <p class="text-center h4 mb-4">Upload Supporting Documents</p>
                    <!-- <div class="panel panel-inverse" data-sortable-id="form-dropzone-1">
                      <div class="panel-heading">
                        <div class="panel-heading-btn"></div>
                        <h4 class="panel-title">Drop Files In Here</h4>
                      </div>
                      <div class="panel-body text-inverse">
                        <DropZoneControl
                          :ClearData="true"
                          v-on:fileadded="fileadded"
                          :SessionID="DropZoneSessionID"
                        ></DropZoneControl>
                      </div>
                    </div> -->
                    <!--End dropzone!-->

                    <br />
                    <!--Start Table!-->
                    <div class="panel panel-inverse" data-sortable-id="table-basic-7">
                      <!-- begin panel-heading -->
                      <div class="panel-heading ui-sortable-handle">
                        <h4 class="panel-title">Uploaded Files</h4>
                      </div>
                      <!-- end panel-heading -->
                      <!-- begin panel-body -->
                      <div class="panel-body">
                        <div class="panel panel-inverse" data-sortable-id="form-dropzone-1">
                      <div class="panel-heading">
                        <div class="panel-heading-btn"></div>
                        <h4 class="panel-title">Drop Files In Here</h4>
                      </div>
                      <div class="panel-body text-inverse">
                        <DropZoneControl
                          :acceptedFiles="acceptedFiles"
                          :ClearData="true"
                          v-on:fileadded="fileadded"
                          :SessionID="DropZoneSessionID"
                        ></DropZoneControl>
                      </div>
                    </div>
                    <br />
                        <!-- begin table-responsive -->
                        <div class="table-responsive">
                          <table class="table table-striped m-b-0">
                            <thead>
                              <tr>
                                <th width="35%">Filename</th>
                                <th>Format</th>
                                <th width="40%">Document Type</th>
                                <th width="40%">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                :key="item.drivers_supportingdocs_id"
                                v-for="item in GETDRIVERSUPPORTINGDOCS"
                              >
                                <td>{{item._filename}}</td>
                                <td>{{item._mimetype}}</td>
                                <td>
                                  <select
                                    class="form-control col-sm-4"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-3"
                                    data-parsley-required="true"
                                    v-model="item.documentype_id"
                                  >
                                    <option value="0" selected>Select Document Type</option>
                                    <option
                                      v-bind:key="item._id"
                                      v-for="item in GETALLDOCUMENTTYPE"
                                      :value="item._id"
                                    >{{item._name}}</option>
                                  </select>
                                </td>
                                <td class="with-btn">
                                  <a
                                    href="#"
                                    @click.prevent="downloadItem(item._filename,item._document,item._mimetype)"
                                    class="btn btn-sm btn-yellow"
                                  >Download</a>
                                  <a
                                    href="#"
                                    @click="deletefile(context,item.fileId, USERPROFILE.system_usersid)"
                                    class="btn btn-sm btn-danger"
                                  >Delete</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end table-responsive -->
                      </div>
                      <!-- end panel-body -->
                      <!-- begin hljs-wrapper -->
                    </div>
                    <div class="alert alert-secondary fade show">
                      <div class="text-center">
                        <!-- <button type="submit" class="btn btn-outline-secondary">Save To Drafts</button> -->
                      </div>
                    </div>
                    <!-- end row -->
                  </fieldset>
                </div>
                <!-- end step-3 -->
                <!-- begin step-3 -->
                <div id="step-4">
                  <div class="jumbotron m-b-0 text-center">
                    <h2 class="text-inverse">Taxi Driver Update Requested</h2>
                    <p
                      class="m-b-30 f-s-16 hide"
                    >Password has been sent to the New User via SMS, Please check your phone</p>
                    <p>
                      <router-link to="/Driver/All" class="btn btn-primary btn-lg">View Drivers</router-link>
                    </p>
                  </div>
                </div>
                <!-- end step-4 -->
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>

        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TaxiDrivers");
// value.context.$emit("init");

export default {
  name: "UPDATETAXIDRIVER",
  data: function() {
    return {
      acceptedFiles: "application/pdf",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      GETUPLOADEDFILES: this.$store.getters["fileupload/GETALL"],
      GETALLTITLES: [],
      GETALLGENDERS: [],
      GETALLSUBURBS: [],
      GETALLCOUNTRY: [],
      GETALLCITY: [],
      GETALLDOCUMENTTYPE: [],
      GETPHYSICALADDRESS: [],
      GETPOSTALADDRESS: [],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      selectedTitleId: undefined,
      selectedGenderId: undefined,
      selectedUserGroupId: undefined,
      DropZoneSessionID: this.$uuid.v4(),
      CapturedPhotosSessionID: this.$uuid.v4(),
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      dropzoneid: this.DropZoneSessionID,
      dropzone_files: this.fileadded,
      id: this.$route.params.id,
      UPDATETAXIDRIVER: {
        taxidriverid: null,
        title_id: null,
        firstname: "",
        surname: "",
        id_number: null,
        date_of_birth: "",
        passport_number: "",
        driver_license_number: "",
        pdp_number: "",
        mobilenumber: "",
        telephone_number: "",
        fax_number: "",
        physical_address: "",
        physical_address2: "",
        physical_suburb_id: null,
        postal_address: "",
        postal_address2: "",
        postal_suburb_id: null,
        email_address: "",
        drivers_license_expirydate: "",
        pdp_license_expirydate: "",
        updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
        gender_id: "",
        country_id: null,
        photosessionid: null,
        taxi_driver_joined_date: "",
        existing_membership_number: "",
        dropzoneid: null,
        dropzone_files: [
          {
            customType: 0,
            fileId: ""
          }
        ]
      },
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    CapturePhotosControl
  },
  beforeMount() {
    this.getById(this.context);
    this.getDriverDocsById({
      _dataObj: {
        id: this.id,
        router: this.$router
      }
    });
  },
  computed: {
    ...mapGetters({
      DRIVERDETAILS: "GETDRIVERSBYIDNOPIC",
      GETDRIVERSUPPORTINGDOCS: "GETDRIVERSUPPORTINGDOCS"
    }),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "getById",
      "getDriverByIdNoPic",
      "getDriverById",
      "delete",
      "edit",
      "add",
      "clearAll",
      "restoreDeleted",
      "editDriver",
      "getDriverDocsById"
    ]),
    loadLookUps(context) {
      
      context.$store
        .dispatch("lookups/init", {
          router: context.$router,
        })
        .then((data) => {
          console.log("loadLookUps found: ", data);
          
          context.GETALLTITLES = data.LOADEDTITLES;
          context.GETALLGENDERS = data.LOADEDGENDER;
          context.GETALLSUBURBS = data.LOADEDSUBURBS;
          context.GETALLCOUNTRY = data.LOADEDCOUNTRIES;
          context.GETALLCITY = data.LOADEDCITY;
          context.GETALLDOCUMENTTYPE = data.LOADEDDOCUMENTTYPE;
          context.GETPHYSICALADDRESS = data.LOADEDPHYSICALADDRESS;
          context.GETPOSTALADDRESS = data.LOADEDPOSTALADDRESS;


        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
    downloadItem(filename, data, type) {
      const linkSource = `data:application/pdf;base64,${data}`;
      const downloadLink = document.createElement("a");
      const fileName = filename;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    // title: function(context) {
    //   return context.UPDATETAXIDRIVER.title_id === 0
    //     ? ""
    //     : context.GETALLTITLES.filter(
    //         item => item._id === context.UPDATETAXIDRIVER.title_id
    //       )[0]._name;
    // },
    // gender: function(context) {
    //   return context.UPDATETAXIDRIVER.gender_id === 0
    //     ? ""
    //     : context.GETALLGENDERS.filter(
    //         item => item._id === context.UPDATETAXIDRIVER.gender_id
    //       )[0]._name;
    // },
    // country: function(context) {
    //   return context.UPDATETAXIDRIVER.country_id === 0
    //     ? ""
    //     : context.GETALLCOUNTRY.filter(
    //         item => item._id === context.UPDATETAXIDRIVER.country_id
    //       )[0]._name;
    // },
    // physicalsuburb: function(context) {
    //   return context.UPDATETAXIDRIVER.physical_suburb_id === 0
    //     ? ""
    //     : context.GETALLSUBURBS.filter(
    //         item => item._id === context.UPDATETAXIDRIVER.physical_suburb_id
    //       )[0]._name;
    // },
    // postalsuburb: function(context) {
    //   return context.UPDATETAXIDRIVER.postal_suburb_id === 0
    //     ? ""
    //     : context.GETALLSUBURBS.filter(
    //         item => item._id === context.UPDATETAXIDRIVER.postal_suburb_id
    //       )[0]._name;
    // },

    getById: function(context){
      context
        .getDriverByIdNoPic({
          id: context.id,
          router: context.$router
        })
        .then(_rb => {
          console.log("this is the drivers profile ", _rb);
          context.UPDATETAXIDRIVER.title_id = _rb[0].title_id;
          context.UPDATETAXIDRIVER.firstname = _rb[0].firstname;
          context.UPDATETAXIDRIVER.surname = _rb[0].surname;
          context.UPDATETAXIDRIVER.id_number = _rb[0].id_number;
          context.UPDATETAXIDRIVER.date_of_birth = _rb[0].date_of_birth;
          context.UPDATETAXIDRIVER.passport_number = _rb[0].passport_number;
          context.UPDATETAXIDRIVER.driver_license_number = _rb[0].driver_license_number;
          context.UPDATETAXIDRIVER.pdp_number = _rb[0].pdp_number;
          context.UPDATETAXIDRIVER.mobilenumber = _rb[0].mobilenumber;
          context.UPDATETAXIDRIVER.telephone_number = _rb[0].telephone_number;
          context.UPDATETAXIDRIVER.fax_number = _rb[0].fax_number;
          context.UPDATETAXIDRIVER.physical_address = _rb[0].physicaladdress;
          context.UPDATETAXIDRIVER.physical_address2 = _rb[0].physicaladdress2;
          context.UPDATETAXIDRIVER.physical_suburb_id = _rb[0].physical_suburb_id;
          context.UPDATETAXIDRIVER.postal_address = _rb[0].postaladdress;
          context.UPDATETAXIDRIVER.postal_address2 = _rb[0].postaladdress2;
          context.UPDATETAXIDRIVER.postal_suburb_id = _rb[0].postal_suburb_id;
          context.UPDATETAXIDRIVER.email_address = _rb[0].email_address;
          context.UPDATETAXIDRIVER.drivers_license_expirydate = _rb[0].drivers_lisense_expirydate;
          context.UPDATETAXIDRIVER.pdp_license_expirydate = _rb[0].pdp_lisense_expirydate;
          context.UPDATETAXIDRIVER.gender_id = _rb[0].fk_gender_id;
          context.UPDATETAXIDRIVER.country_id = _rb[0].country_id;
          context.UPDATETAXIDRIVER.taxi_driver_joined_date = _rb[0].taxi_driver_joined_date;
          context.UPDATETAXIDRIVER.existing_membership_number = _rb[0].existing_membership_number;
          context.UPDATETAXIDRIVER.taxidriverid = _rb[0].taxi_driversid;

            
        });
    },
    fileadded: function(data) {
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')
      const context = $("#step-3").data("context");

      context.GETDRIVERSUPPORTINGDOCS.push({
        drivers_supportingdocs_id: "",
        _filename: data.name,
        _mimetype: data.type,
        _fileId: data.fileId,
      });
    },
    deletefile: (context, fileId, userID) => {
      context.$Notify.YesNo(false, { context, fileId, userID }).then(value => {
        if (value) {
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("fileupload/delete", {
              id: value.fileId,
              deletedby: value.userID
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");
              value.context.$Notify.Success("Successfully Deleted!", "");
            })
            .catch(error => {
              value.context.$wait.end("add");

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },

    updateRequest: (context, addModel) => {
      
      context.$Notify
        .YesNo(false, { context, addModel })
        .then(value => {
          

          const dropzone_files = context.$store.getters["fileupload/GETALL"];
          const fileuploaded = [];

          console.log('supporting Docs: ', context.GETDRIVERSUPPORTINGDOCS)
          if (context.GETDRIVERSUPPORTINGDOCS.length > 0) {
            var failedvalidation = false;
            context.GETDRIVERSUPPORTINGDOCS.forEach(element => {
              if (element.documentype_id === 0 || element._fileId === "") {
                failedvalidation = true;
                return;
              }
              else{
                fileuploaded.push({
                customType: element.documentype_id,
                fileId: element._fileId
              });

              }
              
            });
            if (failedvalidation) {
              value.context.$Notify.Error("Please Select Document Type.", "");
              return;
            }
          }

          value.addModel.dropzone_files = fileuploaded;
          value.addModel.dropzoneid = context.DropZoneSessionID;
          value.addModel.photosessionid = context.CapturedPhotosSessionID;
          value.addModel.taxidriverid = parseInt(context.id);

          value.addModel.taxi_driver_joined_date = context.$Toolkit.ConvertToServerDate(
            context.UPDATETAXIDRIVER.taxi_driver_joined_date
          );

          value.addModel.drivers_license_expirydate = context.$Toolkit.ConvertToServerDate(
            context.UPDATETAXIDRIVER.drivers_license_expirydate
          );

          value.addModel.pdp_license_expirydate = context.$Toolkit.ConvertToServerDate(
            context.UPDATETAXIDRIVER.pdp_license_expirydate
          );

          value.addModel.date_of_birth = context.$Toolkit.ConvertToServerDate(
            context.UPDATETAXIDRIVER.date_of_birth
          );

          if (value) {
            value.router = value.context.$router;
            value.context.$store
              .dispatch("TaxiDrivers/updateDriverRequest", {
                addModel: value.addModel,
                router: value.router
              })
              .then((data) => {

                $(".sw-btn-next")
                .text("Done")
                .attr("disabled", "disabled");
              $(".sw-btn-prev").attr("disabled", "disabled");
              $("#wizard").data("taxidriverupdated", true);
              $("#wizard").smartWizard("next");
                value.context.$Notify.Success("Update Request Sent!",data);
              })
              .catch(error => {
                value.context.$Notify.Error(error.message, "");
              })
              .finally(() => {});
          }
        });
    },
    setTitle(val) {
      this.selectedTitleId = val;
    },
    setGender(val) {
      this.selectedGenderId = val;
    },
    setCountry(val) {
      this.selectedCountryId = val;
    },
    setAdd(val) {
      this.$store
        .dispatch("TaxiDrivers/add", {
          _dataObj: val
        })
        .then(() => {});
    },
    clearDrivers: context => {
      context.clearAll();
    },
  },
  mounted: function() {
    //this.get(this.context)

    App.init();

    this.loadLookUps(this.context);

    $("#step-3").data("context", this.context);

    $(".datepicker-disabled-past").datepicker({
      todayHighlight: true,
      autoclose: true
    });

    $(".datepicker-driverjoined-date").datepicker({
      todayHighlight: true,
      autoclose: true
    });

    $(".datepicker-licenseExpiry-date").datepicker({
      todayHighlight: true,
      autoclose: true
    });

    $(".datepicker-PDPexpiry-date").datepicker({
      todayHighlight: true,
      autoclose: true
    });

    $("#wizard").data("taxidriverupdated", false);

    //$('.selectpicker1').selectpicker('render');
    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "",
      keyNavigation: false,
      transitionSpeed: 0,
      cycleSteps: true,
      useURLhash: false,
      showStepURLhash: false,
      toolbarSettings: {
        toolbarPosition: "bottom"
      }
    });

    $("#wizard").data("context", this.context);
    $("#wizard").on("leaveStep", function(
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {
      const context = $("#wizard").data("context");
      const currentstep = stepNumber + 1;
      const res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

      if (context.hacksmartwizard) return true;

      $(".sw-btn-next").text("Next");

      if (stepDirection == "backward") return true;

      if (currentstep === 1 && stepDirection == "forward") {
        
      }

      if (currentstep === 2 && stepDirection == "forward") {
        if (context.$store.getters["capturephotos/GETALL"].length > 0) {
          if (context.$store.getters["capturephotos/GETALL"].length < 2) {
          context.$Notify.Error("Please Add More Photos", "");
          return false;
        }

        }

        $(".sw-btn-next").text("Submit");
      }

      if (currentstep === 3 && stepDirection == "forward") {
        const taxidriverupdated = $("#wizard").data("taxidriverupdated");

        if (!taxidriverupdated) {
          context.updateRequest(context,context.UPDATETAXIDRIVER);
        }
        return taxidriverupdated;
      }

      if (currentstep === 4 && stepDirection == "forward") {
        if (!res) return res;

        console.log("we are in step 4");
        return false;
      }

      return res;
    });

    // $("#masked-input-phone").mask("(082) 686-9833");
    // $("#masked-input-tid").mask("99999999999999");

    //FormPlugins.init();
    //Highlight.init();

    //     $(function(){
    //    $(".dropdown-menu").on('click', 'a', function(){
    //        $(this).parents('.dropdown').find('button').text($(this).text());
    //    })
    // });
  },
  beforeDestroy: function(){
    this.clearDrivers(this.context);
  },
};
</script>

	

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>