<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
      v-on:init="init"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Dashboard'"
      :ActiveMenu="'Summary'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <transition name="bounce">
      <div
        id="content"
        class="content"
        v-show="USERPROFILE.taxi_associations_id"
      >
        <!-- begin page-header -->
        <headerpagebreadcrumb
          pageheaderTitle="Taxi Dashboard"
          pageheaderdescription
          breadcrumbitem1="Home"
          breadcrumbitem2="Dashboard"
          breadcrumbitem3="Vehicle"
        />
        <!-- end page-header -->
        <!-- begin #Main Body Template -->

        <!-- begin row -->
        <div class="row">
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-green">
                <i class="fas fa-taxi"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Active Taxis</div>
                <div class="stats-number">
                  {{ GETDASHBOARDCARDS.totalactivetaxis }}
                </div>

                <div class="stats-desc">No Of Active Taxis</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-black">
                <i class="fas fa-taxi"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Pending Taxis</div>
                <div class="stats-number">
                  {{ GETDASHBOARDCARDS.totalpendingtaxis }}
                </div>

                <div class="stats-desc">No Of Pending Taxis</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="fas fa-taxi"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Breakdown</div>
                <div class="stats-number">
                  {{ GETDASHBOARDCARDS.totalbreakdowntaxis }}
                </div>

                <div class="stats-desc">No Of Taxis Under Breakdown</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-red">
                <i class="fas fa-taxi"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Deleted Taxis</div>
                <div class="stats-number">
                  {{ GETDASHBOARDCARDS.totaldeletedtaxis }}
                </div>

                <div class="stats-desc">No Of Deleted Taxis</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          
        </div>

        <!-- end row -->

        <!-- begin row -->
        <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-8">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Active Vs Breakdown</h4>
              </div>
              <div class="panel-body">
                <div id="donut-OperatorByActiveBreakDown" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->

             <!-- begin panel -->
             <div class="panel panel-inverse" data-sortable-id="index-2">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Vehicle By Make</h4>
              </div>
              <div class="panel-body">
                <div id="donut-OperatorByGender" class="height-lg"></div>
              </div>
            </div>
            <!-- end panel -->
            
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-2">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Vehicle By Model</h4>
              </div>
              <div class="panel-body">
                <div id="donut-OperatorByAgeGroup" class="height-lg"></div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-3">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Vehicle By Fuel</h4>
              </div>
              <div class="panel-body">
                <div id="taxibyfuel" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-4">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Taxis By Transmission Type</h4>
              </div>
              <div class="panel-body">
                <div id="taxibytrans" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->

           
          </div>

          <!-- end col-8 -->
          <!-- begin col-8 -->
          <div class="col-xl-4">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-5">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Vehicles With Most Operators</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Make/Model</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item,
                        index) in GETOPERATORFINSTATS.operatorswithmostvehicles"
                        v-bind:key="item.id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td>
                          {{ item.fullname }}
                        </td>
                        <td class="text-center">{{ item.numberoftaxis }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-6">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top 5 Taxis By Make</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Make</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item,
                        index) in GETOPERATORFINSTATS.operatorswithmostarrears"
                        v-bind:key="item.id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td>
                          {{ item.operatorname }}
                        </td>
                        <td class="text-center">
                          {{ item.arrears | newcurrencywithSymbol }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-7">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top 5 Taxis By Model</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Model</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item,
                        index) in GETOPERATORFINSTATS.topgrossingoperators"
                        v-bind:key="item.id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td>
                          {{ item.operatorname }}
                        </td>
                        <td class="text-center">
                          {{ item.totalrevenue | newcurrencywithSymbol }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-6">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top Taxis with Arrears</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Reg No</th>
                        <th>Operator Name</th>
                        <th>Arrears</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item,
                        index) in GETOPERATORFINSTATS.toptaxiswitharrears"
                        v-bind:key="item.id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td>
                          {{ item.regnumber }}
                        </td>
                        <td>
                          {{ item.operatorname }}
                        </td>
                        <td class="text-center">
                          {{ item.arrears | newcurrencywithSymbol }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-6">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top Grossing Taxis</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Reg No</th>
                        <th>Operator</th>
                        <th>Revenue</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item,
                        index) in GETOPERATORFINSTATS.topgrossingtaxis"
                        v-bind:key="item.id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td>
                          {{ item.regnumber }}
                        </td>
                        <td>
                          {{ item.operatorname }}
                        </td>
                        <td class="text-center">
                          {{ item.totalrevenue | newcurrencywithSymbol }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-8 -->
        </div>
        <!-- end row -->

       

        <!-- begin row -->

        <!-- begin #Main Body Template -->
      </div>
    </transition>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import statsCards from "@/components/charts/statsCards.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers(
  "chairmansummarydashboard"
);

export default {
  name: "chairman_dashboard",
  data: function () {
    return {
      message: "Some Message",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      GETDASHBOARDCARDS: {},
      GETOPERATORFINSTATS: {},
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    statsCards,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters(["", ""]),
  },
  methods: {
    ...mapActions(["getvehicledashboardcards", "getvehiclestats"]),
    init: function () {},
  },
  mounted: function () {
    $(document).data("context", this);
    App.init();

    const router = this.$router;

    var payload = {
      taxiassociationsId: this.taxiassociationid,
    };

    this.getvehicledashboardcards({ router, payload })
      .then((parsedResultOP) => {
        const context = $(document).data("context");

        context.GETDASHBOARDCARDS = parsedResultOP;
      })
      .catch((error) => {
        //context.$wait.end("generatestatement");
      });

    this.getvehiclestats({ router, payload })
      .then((parsedResultOP) => {
        const context = $(document).data("context");

        context.GETOPERATORFINSTATS = parsedResultOP;
        
        setTimeout(() => {
          handleOperatorByActiveBreakdown();
        handleOperatorByGender();
        handleOperatorByModel();
        handleOperatorByAgeGroup();
        taxibyfuel();
        taxibytrans();
        }, 1000);
        
      })
      .catch((error) => {
        //context.$wait.end("generatestatement");
      }).finally(() => {
        
      });

    var handleOperatorByAgeGroup = function () {
      "use strict";
      if ($("#donut-OperatorByAgeGroup").length !== 0) {
        const context = $(document).data("context");
        

        var operatorAgeGroupData = [
          {
            label: `Retirement(${context.GETOPERATORFINSTATS.operatorbyagegroup[0].oldage})`,
            data: context.GETOPERATORFINSTATS.operatorbyagegroup[0].oldage,
            color: COLOR_BLUE,
          },
          {
            label: `Middle Age(${context.GETOPERATORFINSTATS.operatorbyagegroup[0].middleage})`,
            data: context.GETOPERATORFINSTATS.operatorbyagegroup[0].middleage,
            color: COLOR_PURPLE_LIGHTER,
          },
          {
            label: `Youth(${context.GETOPERATORFINSTATS.operatorbyagegroup[0].youth})`,
            data: context.GETOPERATORFINSTATS.operatorbyagegroup[0].youth,
            color: COLOR_GREEN,
          },
        ];

        $.plot("#donut-OperatorByAgeGroup", operatorAgeGroupData, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true,
              },
            },
          },
          grid: {
            hoverable: true,
            clickable: true,
          },
          legend: {
            show: true,
          },
        });
      }
    };

    var handleOperatorByActiveBreakdown = function () {
      "use strict";
      if ($("#donut-OperatorByActiveBreakDown").length !== 0) {
        const context = $(document).data("context");

        const colors = [COLOR_GREEN,COLOR_RED];

        var activevsbreakdown = context.GETOPERATORFINSTATS.activevsbreakdown.map(
          (item, index) => {
            return {
              label: item.gendertype + `(${item.gendercount})`,
              data: item.gendercount,
              color: colors[index],
            };
          }
        );

        $.plot("#donut-OperatorByActiveBreakDown", activevsbreakdown, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true,
              },
            },
          },
          grid: {
            hoverable: true,
            clickable: true,
          },
          legend: {
            show: true,
          },
        });
      }
    };

    var handleOperatorByGender = function () {
      "use strict";
      if ($("#donut-OperatorByGender").length !== 0) {
        const context = $(document).data("context");

        const colors = [COLOR_BLUE, COLOR_AQUA,COLOR_GREEN,COLOR_YELLOW,COLOR_ORANGE,COLOR_PURPLE,COLOR_RED,COLOR_GREY,COLOR_SILVER,COLOR_BLACK,COLOR_WHITE
          ,COLOR_BLACK_LIGHTER,COLOR_SILVER_LIGHTER,COLOR_GREY_LIGHTER,COLOR_RED_LIGHTER,COLOR_PURPLE_LIGHTER,COLOR_ORANGE_LIGHTER,COLOR_YELLOW_LIGHTER,COLOR_GREEN_LIGHTER,
          COLOR_AQUA_LIGHTER,COLOR_BLUE_LIGHTER
        ];

        var operatorGenderData = context.GETOPERATORFINSTATS.taxibymake.map(
          (item, index) => {
            return {
              label: item.gendertype + `(${item.gendercount})`,
              data: item.gendercount,
              color: colors[index],
            };
          }
        );

        $.plot("#donut-OperatorByGender", operatorGenderData, {
          series: {
            pie: {
              innerRadius: 0.0,
              show: true,
              label: {
                show: true,
              },
            },
          },
          grid: {
            hoverable: true,
            clickable: true,
          },
          legend: {
            show: true,
          },
        });
      }
    };

    var handleOperatorByModel = function () {
      "use strict";
      if ($("#donut-OperatorByAgeGroup").length !== 0) {
        const context = $(document).data("context");

        const colors = [COLOR_BLUE, COLOR_AQUA,COLOR_GREEN,COLOR_YELLOW,COLOR_ORANGE,COLOR_PURPLE,COLOR_RED,COLOR_GREY,COLOR_SILVER,COLOR_BLACK,COLOR_WHITE
          ,COLOR_BLACK_LIGHTER,COLOR_SILVER_LIGHTER,COLOR_GREY_LIGHTER,COLOR_RED_LIGHTER,COLOR_PURPLE_LIGHTER,COLOR_ORANGE_LIGHTER,COLOR_YELLOW_LIGHTER,COLOR_GREEN_LIGHTER,
          COLOR_AQUA_LIGHTER,COLOR_BLUE_LIGHTER
        ];

        var operatorGenderData = context.GETOPERATORFINSTATS.taxibymodel.map(
          (item, index) => {
            return {
              label: item.gendertype + `(${item.gendercount})`,
              data: item.gendercount,
              color: colors[index],
            };
          }
        );

        $.plot("#donut-OperatorByAgeGroup", operatorGenderData, {
          series: {
            pie: {
              innerRadius: 0.0,
              show: true,
              label: {
                show: true,
              },
            },
          },
          grid: {
            hoverable: true,
            clickable: true,
          },
          legend: {
            show: true,
          },
        });
      }
    };

    var taxibytrans = function () {
      "use strict";
      if ($("#taxibytrans").length !== 0) {
        const context = $(document).data("context");

        const colors = [COLOR_BLUE, COLOR_AQUA,COLOR_GREEN,COLOR_YELLOW,COLOR_ORANGE,COLOR_PURPLE,COLOR_RED,COLOR_GREY,COLOR_SILVER,COLOR_BLACK,COLOR_WHITE
          ,COLOR_BLACK_LIGHTER,COLOR_SILVER_LIGHTER,COLOR_GREY_LIGHTER,COLOR_RED_LIGHTER,COLOR_PURPLE_LIGHTER,COLOR_ORANGE_LIGHTER,COLOR_YELLOW_LIGHTER,COLOR_GREEN_LIGHTER,
          COLOR_AQUA_LIGHTER,COLOR_BLUE_LIGHTER
        ];

        var taxibytransdata = context.GETOPERATORFINSTATS.taxibytrans.map(
          (item, index) => {
            return {
              label: item.gendertype + `(${item.gendercount})`,
              data: item.gendercount,
              color: colors[index],
            };
          }
        );

        $.plot("#taxibytrans", taxibytransdata, {
          series: {
            pie: {
              innerRadius: 0.0,
              show: true,
              label: {
                show: true,
              },
            },
          },
          grid: {
            hoverable: true,
            clickable: true,
          },
          legend: {
            show: true,
          },
        });
      }
    };

    var taxibyfuel = function () {
      "use strict";
      if ($("#taxibyfuel").length !== 0) {
        const context = $(document).data("context");

        const colors = [COLOR_BLUE, COLOR_AQUA,COLOR_GREEN,COLOR_YELLOW,COLOR_ORANGE,COLOR_PURPLE,COLOR_RED,COLOR_GREY,COLOR_SILVER,COLOR_BLACK,COLOR_WHITE
          ,COLOR_BLACK_LIGHTER,COLOR_SILVER_LIGHTER,COLOR_GREY_LIGHTER,COLOR_RED_LIGHTER,COLOR_PURPLE_LIGHTER,COLOR_ORANGE_LIGHTER,COLOR_YELLOW_LIGHTER,COLOR_GREEN_LIGHTER,
          COLOR_AQUA_LIGHTER,COLOR_BLUE_LIGHTER
        ];

        var taxibyfueldata = context.GETOPERATORFINSTATS.taxibyfuel.map(
          (item, index) => {
            return {
              label: item.gendertype + `(${item.gendercount})`,
              data: item.gendercount,
              color: colors[index],
            };
          }
        );

        $.plot("#taxibyfuel", taxibyfueldata, {
          series: {
            pie: {
              innerRadius: 0.0,
              show: true,
              label: {
                show: true,
              },
            },
          },
          grid: {
            hoverable: true,
            clickable: true,
          },
          legend: {
            show: true,
          },
        });
      }
    };

  },
};
</script>

<style>
@import "/assets/plugins/nvd3/build/nv.d3.css";
</style>