<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TaxiAssociation' || 'Office' || 'Chairman'"
      :ActiveMenu="'LetterOfGoodStanding'"
    />

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Association Letter Of Good Standing"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Association"
        breadcrumbitem3="Letter of Good Standing"
      />
      <!-- end page-header -->
      <!-- begin row -->
      <br />

       <div v-if="!dataloaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

      <!-- begin nav-pills -->
      <ul class="nav nav-pills">
        <li class="nav-items">
          <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active">
            <span class="d-sm-none">Pills 1</span>
            <span class="d-sm-block d-none">Add Letter</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">Pills 2</span>
            <span class="d-sm-block d-none">Manage Letters</span>
          </a>
        </li>
      </ul>
      <!-- end nav-pills -->
      <!-- begin tab-content -->
      <div class="tab-content">
        <!-- begin tab-pane -->
        <div class="tab-pane fade active show" id="nav-pills-tab-1">
          <div class="input-group">
            <label class="col-md-2 col-form-label text-md-right">
              Select Operator
              <span class="text-danger">*</span>
            </label>
            <div class="col-md-8">
              <select class="selectpicker selectnewoperatorpicker form-control">
                <option>Select Taxi operator</option>
                <option
                  :key="item.taxi_operator_id"
                  v-for="item in GETOPERATORS"
                  :value="item.taxi_operator_id"
                >
                  {{
                  item.firstname +
                  " " +
                  item.surname +
                  "(" +
                  item.id_number +
                  ")"
                  }}
                </option>
              </select>
            </div>
            <span class="input-group-append">
              <button
                type="button"
                class="btn btn-sm btn-primary width-150 height-35"
                style="border-radius: 10px"
                @click="addGoodStandingLetter(context, ADDLETTEROFGOODSTANDING)"
                :disabled="$wait.is('submit')"
              >
                <i
                  class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
                  v-show="$wait.is('submit')"
                ></i>
                Submit
              </button>
            </span>
          </div>

          <br />

          <h1 class="text-center">
            <span class="m-l-40">
              <!-- <i class="ion-ios-cloud"></i> -->
              <i class="m-r-5">
                <img
                  v-show="LOGO"
                  :src="`${LOGO != undefined ? LOGO : '' }`"
                  class="width-160 height-50 bg-aqua-transparent-1"
                />
              </i>
            </span>
            {{ SELECTEDTAXIASSOCIATION != undefined ? SELECTEDTAXIASSOCIATION._name : ''}}
          </h1>
          <div class="invoice-from">
            <address class="m-t-5 m-b-5 m-l-30">
              <span class="m-l-40">
                <!-- <i class="ion-ios-cloud"></i> -->
                <i class="m-r-5">
                  <img
                    v-show="LOGO"
                    :src="`${LOGO != undefined ? LOGO : '' }`"
                    class="width-160 height-50 bg-aqua-transparent-1"
                  />
                </i>
              </span>
              <br />
              <strong class="text-inverse">{{SELECTEDTAXIASSOCIATION._name}}.</strong>
              <br />
              <span class="m-r-10">
                <i class="fa fa-fw fa-lg fa-map-marker"></i>
                {{SELECTEDTAXIASSOCIATION._physicaladdress}}
              </span>
              <br />
              <span class="m-r-10">
                <i class="fa fa-fw fa-lg fa-map-marker"></i>
                {{SELECTEDTAXIASSOCIATION.physical_suburb || 'Not Provided'}},
                {{SELECTEDTAXIASSOCIATION.physical_suburb_postalcode || '0000'}}
              </span>
              <br />
              <span class="m-r-10">
                <i class="fa fa-fw fa-lg fa-phone-volume"></i>
                {{SELECTEDTAXIASSOCIATION._telephone | HumanizeMobile}}
              </span>
              <br />

              <span class="m-r-10">
                <i class="fa fa-fw fa-lg fa-mobile-alt"></i>
                {{SELECTEDTAXIASSOCIATION._mobilenumber | HumanizeMobile}}
              </span>
            </address>

            <div class="invoice-to m-t-5">
              <address></address>
            </div>
          </div>

          <div class="text-center">
            <h3>
              <u>CONFIRMATION OF MEMBER</u>
            </h3>
            <br />
            <p class="text-left f-s-15 m-t-5 m-b-5 m-l-40 p-l-40">
              This Is To Confirm That
              <strong>
                <em>{{context.OPERATORINFOMATIONBYID.operatortitle || ' No Operator Selected '}} {{context.OPERATORINFOMATIONBYID.operatorname || ' '}}</em>,
              </strong> ID Number:
              <strong>
                <em>{{context.OPERATORINFOMATIONBYID.operator_idnumber || ' No ID Number Found '}}</em>
              </strong>.
              <br />
              <br />Who Resides At
              <strong>
                <em>{{context.OPERATORINFOMATIONBYID.physical_address || ' No Address Provided '}} {{context.OPERATORINFOMATIONBYID.postal_suburb || ''}}</em>.
              </strong>
              <br />We Confirm That This Is A Bonifide Member Of The
              <strong class="text-inverse">
                <em>{{SELECTEDTAXIASSOCIATION._name}},</em>
              </strong> And Is In Good Standing. Hope You Find This Document In Order.
            </p>
            <br />
          </div>
          <p class="text-left m-t-5 m-b-5 m-l-30">
            <strong>Kind Regards</strong>
          </p>

          <!-- begin invoice-footer -->
          <div class="invoice-footer">
            <p class="text-center m-b-5 f-w-600">{{USERPROFILE.taxi_associations_name}}</p>
            <p class="text-center">
              <span class="m-r-10">
                <i class="fa fa-fw fa-lg fa-globe"></i>
                {{SELECTEDTAXIASSOCIATION._abbriviated_name}}.taxiassociations.co.za
              </span>
              <span class="m-r-10">
                <i class="fa fa-fw fa-lg fa-phone-volume"></i>
                T:{{SELECTEDTAXIASSOCIATION._telephone | HumanizeMobile}}
              </span>
            </p>
          </div>
          <!-- end invoice-footer -->
        </div>
        <!-- end tab-pane -->
        <!-- begin tab-pane -->
        <div class="tab-pane fade" id="nav-pills-tab-2">
          <!-- begin #Main Body Template -->
          <div class="row">
            <!-- begin col-12 -->
            <div class="col-lg-12">
              <!-- begin panel -->
              <div class="panel panel-primary">
                <!-- begin panel-heading -->
                <div class="panel-heading">
                  <div class="panel-heading-btn">
                    <a
                      href="javascript:;"
                      class="btn btn-xs btn-icon btn-circle btn-default"
                      data-click="panel-expand"
                    >
                      <i class="fa fa-expand"></i>
                    </a>
                    <a
                      href="javascript:;"
                      class="btn btn-xs btn-icon btn-circle btn-success"
                      data-click="panel-reload"
                    >
                      <i class="fa fa-redo"></i>
                    </a>
                    <a
                      href="javascript:;"
                      class="btn btn-xs btn-icon btn-circle btn-warning"
                      data-click="panel-collapse"
                    >
                      <i class="fa fa-minus"></i>
                    </a>
                  </div>

                  <h4 class="panel-title">Taxi Association Letter of Goodstanding</h4>
                </div>
                <!-- end panel-heading -->

                <!-- begin panel-body -->
                <div class="panel-body">
                  <table
                    id="data-table-default"
                    class="table table-striped table-bordered width-full"
                  >
                    <thead>
                      <tr>
                        <th class="text-nowrap">Requested By</th>
                        <th class="text-nowrap">Mobile Number</th>
                        <th class="text-nowrap">Date Requested</th>
                        <th class="text-nowrap">Verification Number</th>
                        <th class="text-nowrap">Verified By</th>
                        <th class="text-nowrap">Verification Date</th>
                        <th class="text-nowrap">Rejected By</th>
                        <th class="text-nowrap">Date Rejected</th>
                        <th class="text-nowrap">Status</th>
                        <th class="text-nowrap">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(item) in LETTERSOFGOODSTANDING" v-bind:key="item.id">
                        <td>{{item.taxi_operator_name}}</td>
                        <td>{{item.taxi_operators_mobilenumber}}</td>
                        <td>{{item.date_added |formatToShortDate }}</td>
                        <td>{{item.verification_number}}</td>
                        <td>{{item.verified_by_name}}</td>
                        <td>{{item.date_verified | formatToShortDate }}</td>
                        <td>{{item.rejected_by_name}}</td>
                        <td>{{item.date_rejected | formatToShortDate }}</td>
                        <td width="10%">
                          <span
                            class="badge f-s-12"
                            :class="{ 'badge-danger': item.deleted_by }"
                            v-if="item.deleted_by"
                          >Deleted</span>
                          <span
                            class="badge f-s-12"
                            v-bind:class="{ 'badge-success': item.is_active, 'label-secondary':!item.is_active  }"
                            v-if="!item.deleted_by"
                          >{{item.is_active == true ? 'Approved' : 'Awaiting Approval' }}</span>
                        </td>
                        <td class="with-btn valign-middle" nowrap>
                          <button
                            class="btn btn-primary btn-sm m-r-2"
                            type="button"
                            @click="pdfTester(context,item)"
                            v-if="item.date_verified && !item.pdf_path"
                          >Create PDF</button>
                          <a
                            :href="'javascript:;'"
                            class="btn btn-primary btn-sm m-r-2"
                            type="button"
                            v-if="item.pdf_path && item.is_active"
                            @click="viewPDF(context,item.pdf_path)"
                          >View PDF</a>
                          <router-link
                        :to="{ name:  'taxiassociationfraudulentreport',  params: { id: item.verification_number }}"
                        class="btn btn-danger btn-sm m-r-2"
                        v-if="item.attempted_fraud"
                          >Fraud Report</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- end panel-body -->
              </div>
              <!-- end panel -->
            </div>
            <!-- end col-12 -->
          </div>

          <!-- begin #Main Body Template -->
        </div>
        <!-- end tab-pane -->
      </div>
      <!-- end tab-content -->

      <!-- end form-group -->

      <!-- end #content -->
    </div>
    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TA");

export default {
  name: "ManageTaxiAssociation",
  data: function () {
    return {
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      SELECTEDTAXIASSOCIATION: this.$store.getters[
        "auth/SELECTEDTAXIASSOCIATION"
      ],
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      context: this,
      dataloaded: true,
      GETOPERATORS: [],
      OPERATORVEHICLES: [],
      LETTERSOFGOODSTANDING: [],
      OPERATORINFOMATIONBYID: {
        operatorname: "",
        operatortitle: "",
        operator_idnumber: "",
        physical_address: "",
        postal_suburb: "",
        mobile_number: "",
      },
      ADDLETTEROFGOODSTANDING: {
        taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        taxioperator_id: "",
        added_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      rankid: null,
      LOGO: undefined,
      PRS_LOGO: undefined,
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  beforeMount: function () {},
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters(["ASSOCIATIONRANKS", "ASSOCIATIONRANKBYID"]),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions(["getall", "delete", "edit", "add", "getLOGOURL"]),
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    clearAdded: (item) => {
      for (var propName in item) {
        item[propName] = undefined;
      }
    },
    loadnewperators: (context) => {
      
      context.dataloaded = false;
      context.$store
        .dispatch("TO/getAllByTaxiAssID", {
          router: context.$router,
          id: context.taxiassociationid,
          // id: 1
        })
        .then((data) => {
          console.log("operators Found vue: ", data);
          context.GETOPERATORS = data;
        }).catch((error)=>{
          console.log('operator dropdown errors: ',error)
        })
        .finally((error)=>{
          $(".selectnewoperatorpicker").select2({
            multiple: false,
            placeholder: "Select An Operator",
            allowClear: false,
          });
            $(".selectnewoperatorpicker")
            .val(null)
            .trigger("change");
          context.dataloaded = true;
        })
    },

    getbyid: (context) => {
      context.$store
        .dispatch("TA/getLetterOfGoodStandingById", {
          id: context.taxiassociationid,
          router: context.$router,
        })
        .then((data) => {
          console.log("letters of goodstanding: ", data);

          context.LETTERSOFGOODSTANDING = data;
        })
        .catch((error) => {
          console.log("summary errors: ", error);
        })
        .finally(() => {
          // $("#data-table-default").DataTable({
          //       responsive: true,
          //     });
        });
    },

    addGoodStandingLetter: (context, item) => {

      
      
      context.$Notify.YesNo(false, { context, item }).then((value) => {

        value.item.taxioperator_id = $(".selectnewoperatorpicker").val();

        if(value.item.taxioperator_id == null){
          context.$Notify.Error("Please select an operator", "");
          return;
        }

        if (value) {
          
          value.router = value.context.$router;
           value.context.$wait.start("submit");
          value.context.$store
            .dispatch("TA/addLetterOfGoodStanding", {
              AddModel: value.item,
              router: value.router,
            })
            .then((id) => {
              value.context.$Notify.Success(
                "Letter of Good Standing requested Successfully!",
                ""
              );
              context.$wait.end("submit");

              console.log("letter results: ", id);

              context.getbyid(context);
            })
            .catch((error) => {
              context.$wait.end("submit");
              context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {
              
            });
        }
      });
    },

    getOperatorById: (context, id) => {
      context.$store
        .dispatch("TO/getOperatorByIdNopic", {
          id,
          router: context.$router,
        })
        .then((_rb) => {
          console.log("this is the operator:", _rb);

          context.OPERATORINFOMATIONBYID.operatorname =
            _rb[0].firstname + _rb[0].surname;
          context.OPERATORINFOMATIONBYID.operatortitle = _rb[0].title_name;
          context.OPERATORINFOMATIONBYID.operator_idnumber = _rb[0].id_number;
          context.OPERATORINFOMATIONBYID.physical_address = `${_rb[0].physicaladdress}, ${_rb[0].physicaladdress2}`;
          context.OPERATORINFOMATIONBYID.postal_suburb = _rb[0].postal_suburb;

          context.OPERATORINFOMATIONBYID.mobile_number = _rb[0].mobilenumber;
        })
        .catch((error) => {
          console.log("operator errors :", error);
        })
        .finally(() => {
          // $("#data-table-address").DataTable({
          //   responsive: true,
          // });
        });
    },

    viewPDF: (context, item) => {
      window.open(item, "_blank");
    },
  },
  mounted: function () {
    App.init();

    this.loadnewperators(this.context);
    this.getbyid(this.context);
    $(document).data("context",this);

    // $(".selectoperatorvehicle").select2({
    //   multiple: false,
    //   placeholder: "Select A Vehicle",
    //   allowClear: true,
    // });



    $(".selectnewoperatorpicker").on("select2:select", function(e) {
      const context = $(document).data("context");
        var operatorID = parseInt(e.target.value)
        console.log("selected Operator", operatorID);
        if (operatorID != null) {
          context.getOperatorById(context, operatorID);
        } else {
          console.log("no Operator selected!");
        }
      });

    const contexttemp = this;
    this.getLOGOURL({
      id: this.taxi_association_id,
    })
      .then((result) => {
        contexttemp.LOGO = result;
      })
      .catch((err) => {});
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

