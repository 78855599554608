<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageDrivers'"
      :ActiveMenu="'List'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Taxi Driver"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Drivers"
        breadcrumbitem3="All"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">All Drivers</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <router-link :to="{ name: 'addtaxidriver'}" class="btn btn-white">
                  <i class="fa fa-plus"></i>
                  Add Driver
                </router-link>
              </div>
            </div>

            <div v-if="!dataLoaded" class="btn-row">
              <div class="text-center">
                <div class="spinner-border spinner-border-lg text-info" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-default" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">ID Number</th>
                    <th class="text-nowrap">Membership Number</th>
                    <th class="text-nowrap">Assigned Vehicle</th>
                    <th class="text-nowrap">Balance</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap hidden-print">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(item) in GETDRIVERSBYASSID" v-bind:key="item._id">
                    <td>{{item._title_name + " " + item._firstname + " " + item._surname}}</td>
                    <td>{{item._id_number}}</td>
                    <td>{{item._existing_membership_number}}</td>
                    <td>{{item._vehicle_license_plate}}</td>
                    <td>{{item._available_balance}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._deleted_by }"
                        v-if="ISINTERNALSYSTEMUSER && item._deleted_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item._is_active, 'label-secondary':!item._is_active  }"
                        v-if="!item._deleted_by"
                      >{{item._driver_status_name}}</span>
                    </td>
                    <td class="with-btn valign-middle" nowrap>
                   
                      <button
                        type="button"
                        class="btn btn-sm btn-warning width-80 m-r-2"
                        data-role="DTD"
                        @click="Migrate(context,item)"
                      >
                        <v-wait v-bind:for="'delete-' + item._taxi_drivers_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Migrate
                        </v-wait>
                      </button>
                     
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->
 <!-- Add Modal HTML START-->
              <div id="AddModal" class="modal fade" v-if="ADDAGENT">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Add Driver</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">First Name</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                placeholder="Enter First Name"
                                class="form-control"
                                minlength="1"
                                v-model="ADDAGENT.firstname"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->

                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Surname</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                placeholder="Enter Surname"
                                class="form-control"
                                minlength="3"
                                v-model="ADDAGENT.surname"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                           <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">ID Number</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                placeholder="Enter ID Number"
                                class="form-control"
                                minlength="3"
                                v-model="ADDAGENT.idnumber"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                           <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Mobile No</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                placeholder="Enter Mobile No"
                                class="form-control"
                                minlength="3"
                                v-model="ADDAGENT.mobilenumber"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                           <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Bank Name</label>
                            <div class="col-md-9">
                             <select
                                class="default-selectTaxiDrivers default-select form-control"
                                data-size="5"
                                data-live-search="true"
                                v-model="ADDAGENT.BankId"
                              >
                                <option
                                  v-for="item in GETALLBANKS"
                                  :key="item.id"
                                  :value="item.id"
                                >{{item.name}}</option>
                            </select>
                            </div>
                          </div>
                          <!-- end form-group row -->
                           <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Account No</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                placeholder="Enter Account No"
                                class="form-control"
                                minlength="3"
                                v-model="ADDAGENT.AccountNo"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        />
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="DriverAdd(context,ADDAGENT)"
                          :disabled="$wait.is('add')"
                        >
                          <v-wait v-bind:for="'add'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Add
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Add Modal HTML START-->
      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import headerComp from "@/components/common/header.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import pageloader from "@/components/common/pageloader.vue";

import { createNamespacedHelpers } from "vuex";
import { setTimeout } from "timers";
const { mapGetters, mapActions } = createNamespacedHelpers("TaxiDrivers");

export default {
  name: "ManageDrivers",
  data: function () {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      addedsuccess: undefined,
      errormessage: undefined,
      skip: 0,
      len: 1000000,
      GETDRIVERSBYASSID: [],
      GETALLBANKS: [],
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      GETSELECTED: Object.assign({}, this.$store.getters["title/GETALL"])[0],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      accountid: this.$store.getters["TaxiDrivers/GETDRIVERACCOUNT"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      dataLoaded: false,
       ADDAGENT: {
        firstname: "",
        surname: "",
        idnumber: "",
        mobilenumber: "",
       TamsDriverId: "",
       BankId:"",
        AccountNo:"",
       
      },
    };
  },
  beforeMount: function () {
    // this.getbyid(this.context, this.taxiassociationid);
  },

  beforeDestroy: function () {
    this.clearAll();
  },

  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters({}),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions([
      "getall",
      "delete",
      "edit",
      "add",
      "getAllByTaxiAssIdNoPic",
      "markAsDeleted",
      "clearAll",
      "restoreDeleted",
    ]),
    Migrate: (
      context,
      item
    ) => {

       context.ADDAGENT.firstname = item._firstname;
      context.ADDAGENT.surname = item._surname;
      context.ADDAGENT.idnumber = item._id_number;
      context.ADDAGENT.mobilenumber = item._mobilenumber;
      context.ADDAGENT.TamsDriverId = item._taxi_drivers_id;

      $("#AddModal").modal("show");
    },
    GetAllBanks: (context) => {
      context.$store
            .dispatch("systemusers/GetBanks", {
              router: context.$router
            })
            .then((data) => {
            
              context.GETALLBANKS = data
            })
            .catch((error) => {
              value.context.$wait.end("add");

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
    },
    DriverAdd: (context, item) => {

      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("systemusers/DriverAdd", {
              item: value.item,
              router : value.context.$router
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");
              value.context.$Notify.Success("Successfully Added!", "");
            })
            .catch((error) => {
              value.context.$wait.end("add");
              value.context.$Notify.Error(error.Message, "");
            })
            .finally(() => {});
        }
      });
    },
    Delete: (context, id, deletedby) => {
      context.$Notify.YesNo(false, { context, id, deletedby }).then((value) => {
        if (value) {
          value.context.$wait.is("delete-" + value.id);

          value.context
            .markAsDeleted({
              router: value.context.$router,
              id: value.id,
              deletedby: value.deletedby,
            })
            .then(() => {
              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Success("Successfully Deleted!", "");
            })
            .catch((error) => {
              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    Restore: (context, id, restoredby) => {
      context.$Notify
        .YesNo(false, { context, id, restoredby })
        .then((value) => {
          if (value) {
            value.context.$wait.is("restore-" + value.id);

            value.context
              .restoreDeleted({
                router: value.context.$router,
                id: value.id,
                restoredby: value.restoredby,
              })
              .then(() => {
                value.context.$wait.end("restore-" + value.id);
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch((error) => {
                value.context.$wait.end("restore-" + value.id);
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    getbyid: (context, taxiassociationid) => {
      context
        .getAllByTaxiAssIdNoPic({
          id: taxiassociationid,
          router: context.$router,
        })
        .then((data) => {
          console.log("Drivers Found: ", data);
          context.GETDRIVERSBYASSID = data;
          context.dataLoaded = true;
        })
        .catch((error) => {
          console.log("Driver errors: ", error);
        })
        .finally(() => {
          var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: true,
                    colReorder: true,
                    keys: true,
                    rowReorder: false,
                    select: true
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }
          $("#data-table-default").DataTable(options);
        });
    },
    clear: (context) => {
      context.clearAll().then(() => {});
    },

    FILTERBYROLE: (context) => {
      $(".byrole").each(function () {
        if (
          context.ROLES.length > 0 &&
          context.ROLES.filter(
            (item) => item._code === $(this).attr("data-role")
          ).length > 0
        )
          $(this).removeClass("hide");
      });
    },
  },
  mounted: function () {
    document.title = "All Drivers";

    this.getbyid(this.context, this.taxiassociationid);
    this.GetAllBanks(this.context);
    
    App.init();

    // TableManageCombine.init();

    $(".byrole").addClass("hide");
    this.FILTERBYROLE(this.context);
    window.PushManager.listenToIncomingMessages(this.context);
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

