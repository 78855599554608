/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';

export default {
  namespaced: true,
  state: {
    operator_vehicle: [],
    operator_vehicles: [],
    operator_vehiclesbyAff: [],
    vehiclemake: [],
    vehiclemodel: [],
    vehicle_docs: [],
    vehicle_timeline: [],
    vehicle_transactions: [],
    vehicle_billings: [],
    taxi_vehicles: [],
    taxi_vehicle_routes: [],
    vehicle_status: [],
    vehicle_invoiceDisputes: [],
    vehicle_receiptDisputes: [],
    vehicle_pictures: [],
    datacollectionbyAssId: [],
    datacollectionOperatorImage: [],
    vehiclemakes: [],
    vehiclemodels: [],
    vehicle_financeprovider: [],
    vehicle_insuranceprovider: [],
    vehicle_trackerprovider: [],

  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETUPLOADEDFILES: (state, getters, rootState, rootGetters) => {
      return rootGetters['fileupload/GETALL']
    },

    GETOPERATORS: (state, getters, rootState, rootGetters) => {
      return rootGetters['TO/GETOPERATORSNOPIC']
    },
    // GETTAXIVEHICLEEDIT: state => state.taxi_vehicles,
    GETADDVEHICLESTATUS: state => state.vehicle_status,
    GETADDVEHICLETRANSACTIONS: state => state.vehicle_transactions,
    GETVEHICLEINVOICEDISPUTES: state => state.vehicle_invoiceDisputes,
    GETVEHICLERECEIPTSDISPUTES: state => state.vehicle_receiptDisputes,
    GETADDVEHICLETIMELINE: state => state.vehicle_timeline,
    GETADDVEHICLE: state => state.operator_vehicle,
    GETVEHICLEBILLINGS: state => state.vehicle_billings,
    GETFINANCEPROVIDERS: state => state.vehicle_financeprovider,
    GETFINSURANCEPROVIDERS: state => state.vehicle_insuranceprovider,
    GETTRACKERPROVIDERS: state => state.vehicle_trackerprovider,
    GETVEHICLEROUTES: state => state.taxi_vehicle_routes,
    GETVEHICLEBYASSID: state => state.operator_vehicles,
    GETVEHICLESUPPORTINGDOCS: state => state.vehicle_docs,
    GETVEHICLEMAKES: state => state.vehiclemakes,
    GETVEHICLEMODELSBYID: state => state.vehiclemodels,
    GETALLMODEL: (state, getters, rootState, rootGetters) => {
      return rootGetters['vehiclemodel/GETALLMODEL']
    },
    GETMODELBYMAKEID: (state, getters, rootState, rootGetters, id) => {

      return rootGetters['vehiclemodel/GETALLMODEL'];
    },
    GETVEHICLEBYAFFILLIATION: state => state.operator_vehiclesbyAff,
    GETVEHICLEPICTURES: state => state.vehicle_pictures,
    GETDATACOLLECTED: state => state.datacollectionbyAssId,
    GETDATACOLLECTEDOPERATORIMAGE: state => state.datacollectionOperatorImage,

  },
  mutations: {
    LOADED: (state, _data) => {
      
      if (_data !== undefined) {
        state.operator_vehicle = _data;
      }
    },
    VEHICLESLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.operator_vehicles = _data;
      }
    },
    ROUTESLOADED: (state, _data) => {
      if (_data !== undefined) {
        
        state.taxi_vehicle_routes = _data;
      }
    },
    SUPPORTINGDOCSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.vehicle_docs = _data;
      }
    },
    TIMELINELOADED: (state, _data) => {
      if (_data !== undefined) {
        state.vehicle_timeline = _data;
      }
    },
    TRANSACTIONSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.vehicle_transactions = _data;
      }
    },
    INVOICESLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.vehicle_invoiceDisputes = _data;
      }
    },

    RECEIPTSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.vehicle_receiptDisputes = _data;
      }
    },
    VEHICLEBILLINGSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.vehicle_billings = _data;
      }
    },
    FINANCEPROVIDERLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.vehicle_financeprovider = _data;
      }
    },
    INSURANCELOADED: (state, _data) => {
      if (_data !== undefined) {
        state.vehicle_insuranceprovider = _data;
      }
    },
    VEHICLETRACKERPROVIDERLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.vehicle_trackerprovider = _data;
      }
    },
    VEHICLEMAKESLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.vehiclemakes = _data;
      }
    },

    STATUSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.vehicle_status = _data;
      }
    },

    VEHICLEBYAFFLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.operator_vehiclesbyAff = _data;
      }
    },

    DATACOLLECTEDLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.datacollectionbyAssId = _data;
      }
    },

    VEHICLEMODELSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.vehiclemodels = _data;
      }
    },

    DATACOLLECTEDOPERATORIMAGE: (state, _data) => {
      if (_data !== undefined) {
        state.datacollectionOperatorImage = _data;
      }
    },

    VEHICLEPICTURESLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.vehicle_pictures = _data;
      }
    },

    ADDED: (state, _data) => {
      
      state.operator_vehicle.push(_data);
    },
    UPDATED: (state, _data) => {
      if (_data !== undefined) {
        
        Object.assign(state.taxi_vehicles.filter(item => item._taxi_operator_vehicles_id === _data._id)[0], _data);
      }
    },
    VEHICLELOADEDEDIT: (state, id) => {
      if (id !== undefined) {
        state.operator_vehicle = state.operator_vehicle.filter(item => item._taxi_operator_vehicles_id === Number(id))[0];
      }
    },
    OPERATORVEHICLELOADED: (state, id) => {
      if (id !== undefined) {
        state.operator_vehicle = state.operator_vehicle.filter(item => item._taxi_operator_id === Number(id))[0];
      }
    },

    RESTOREDELETED: (state, id) => {
      if (id !== undefined) {
        
        state.operator_vehicle.filter(item => item._id === id)[0]._deleted_by = undefined;
        state.operator_vehicle.filter(item => item._id === id)[0]._date_deleted = undefined;
      }
    },
    MARKASDELETE: (state, id, deletedby) => {
      if (id !== undefined) {
        state.operator_vehicle.filter(item => item._id === id)[0]._deleted_by = deletedby;
        state.operator_vehicle.filter(item => item._id === id)[0]._date_deleted = Date.now;
      }
    },
    DELETEUPLOADFILE: (state, id) => {
      if (id !== undefined) {
        state.vehicle_docs = state.vehicle_docs.filter(item => item.vehicle_supportingdocs_id !== id);
      }
    },
    CLEARALL: (state) => {
      state.operator_vehicle = [];
    },

  },
  actions: {
    /* Async */
    clearAll({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }) {
      /* Auth.js Mutations */
      commit('CLEARALL');
    },
    deletelocalfile({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, id) {
      commit('DELETEUPLOADFILE', id);
    },
    editVehicle({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, id) {
      commit('VEHICLELOADEDEDIT', id);
    },
    /* Async */
    selected({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _id
    }) {
      const _taxiass = state.taxiassociations.filter(t => t._taxi_associations_id == _id)[0];

      /* Auth.js Mutations */
      commit('auth/UPDATETAXIASSOCIATION', {
        id: _taxiass._taxi_associations_id,
        name: _taxiass._name,
        abbr: _taxiass._abbriviated_name,
        code: _taxiass._code,
      }, {
        root: true
      });
    },

    // updateVehicleRequest({
    //   commit,
    //   state,
    //   dispatch,
    //   rootGetters
    // }, {
    //   id,
    //   _updatemodel,
    //   router,
    // }) {
    //   return new Promise((resolve, reject) => {

    //     const requestheader = {
    //       router,
    //       token: rootGetters['auth/TOKEN'],
    //       apiName: config.TAXIVEHICLE_URL,
    //     };

    //     this._vm.$TAMS_API.update(requestheader, id, _updatemodel).then((parsedResult) => {
    //       if (parsedResult.status) {
    //         commit('UPDATED', parsedResult.data)
    //         resolve( parsedResult.data)
    //         console.log('updated succesfully')
    //       } else {
    //         // commit('EXTERNALUPDATED', parsedResult.data);
    //       }
    //     }).catch((error) => {
    //       // commit('LOGINFAILED', error.data);
    //       reject();
    //     });
    //   });
    // },

    getVehicleModels({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router
    }) {
      return new Promise((resolve, reject) => {


        let requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEMODEL_URL,
        };

        this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('auth/UPDATEPROGRESS', { PROGRESS: 5 , MESSAGE: 'Loading Billing' }, { root: true });
            // commit('LOADMODELS', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      })
    },

    updateVehicle({
      commit,
      state,
      dispatch,
      rootGetters
    }, {
      id,
      _updatemodel,
      router,
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEUPDATEREQUEST_URL,
        };

        this._vm.$TAMS_API.update(requestheader, id, _updatemodel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('UPDATED', parsedResult.data)
            resolve(parsedResult.data)
            console.log('updated succesfully')
          } else {
            // commit('EXTERNALUPDATED', parsedResult.data);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },


    getVehicleTimelineById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {

        const {
          id,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLETIMELINE_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('TIMELINELOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    checkDiskById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      return new Promise((resolve, reject) => {


        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEDISKVALIDATORBYID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('TIMELINELOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getdriveItemById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _getPagingModel,
      router
    }) {
      return new Promise((resolve, reject) => {


        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEDRIVEITEMSBYID_URL,
        };

        this._vm.$TAMS_API.getByPaging2(requestheader, _getPagingModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('TIMELINELOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    

    getVehicleDocsById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {

        const {
          id,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLESUPPORTINGDOCSGET_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('SUPPORTINGDOCSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },


    getAllTransactionsByDate({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      payload
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TRANSACTIONSVEHICLES,
        };

        this._vm.$TAMSFIN_API.getCustomURL(requestheader,
          payload
        ).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            resolve(parsedResultOP);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    
    getall({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TAXIVEHICLE_URL,

        };

        this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('LOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    getallVehicleMakes({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEMAKE_URL,

        };

        this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
          if (parsedResult.status) {
            commit('VEHICLEMAKESLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getStatusByAssociation({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLESTATUS_URL,

        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('STATUSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getPicturesById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      side,
      router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETVEHICLEPICTURES_URL,

        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            console.log('pictures found: ', parsedResult.data);
            // commit('VEHICLEPICTURESLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('picture errors: ', error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getbyid({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          id,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TAXIVEHICLE_URL,
        };

        

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            console.log("vehicle found: ", parsedResult.data);
            // commit('LOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log("vehicle errors: ", error);
          reject(error);
        });
      });
    },
    getbyOperatorId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          id,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEOPERATOR_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            //commit('OPERATORVEHICLELOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getVehicleModelsByMakeID({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          id,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEMODELBYMAKEID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            commit('VEHICLEMODELSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log('vehicle models errors: ', error)
          reject(error);
        });
      });
    },


    // },
    ownershipChangeRequest({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      AddModel,
      router
    }) {
      
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLECHANGEOFOWNER_URL,
        };
        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('ADDED', parsedResult.data);
            console.log(parsedResult.data);
            resolve(parsedResult.data);

            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log(error);
          reject(error);
        });
      });
    },

    changeVehicleBilling({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      addModel,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEBILLINGCHANGEREQUEST_URL,
        };

        this._vm.$TAMSFIN_API.add(requestheader, addModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('TRANSACTIONSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });

      });
    },

    excludetriptrack({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      addModel,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.EXCLUDETRIPTRACKER_URL,
        };

        this._vm.$TAMS_API.add(requestheader, addModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('TRANSACTIONSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });

      });
    },

    impoundVehicles({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      addModel,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEIMPOUND_URL,
        };

        this._vm.$TAMS_API.add(requestheader, addModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('TRANSACTIONSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });

      });
    },

    vehicleDriveDocuments({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _getPagingModel,
      router
    }) {
      
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEDRIVEITEMSBYASS_URL,
        };

        this._vm.$TAMS_API.getByPaging2(requestheader, _getPagingModel).then((parsedResult) => {

          console.log('vehicle documents found: ', parsedResult);
          if (parsedResult.status) {
            resolve(parsedResult.data);
          }
          // navigateTo(router, parsed_result.data[0]);

        }).catch((error) => {
          console.log('vehicle documents errors: ', error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    addVehicleMake({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      addModel,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEMAKE_URL,
        };

        this._vm.$TAMS_API.add(requestheader, addModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('TRANSACTIONSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });

      });
    },

    releaseVehicle({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      addModel,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLERELEASE_URL,
        };

        this._vm.$TAMS_API.add(requestheader, addModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('TRANSACTIONSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });

      });
    },


    updateVehicleSupportingDocs({
      commit,
      state,
      dispatch,
      rootGetters
    }, {
      addModel,
      router,
    }) {
      return new Promise((resolve, reject) => {

        

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLESUPPORTINGDOCSADD_URL,
        };

        this._vm.$TAMS_API.add(requestheader, addModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('ADDED', parsedResult.data)
            resolve(parsedResult.data)
            console.log('updated succesfully')
          } else {
            // commit('EXTERNALUPDATED', parsedResult.data);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    add({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          item,
          router,
        } = _dataObj;
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TAXIVEHICLE_URL,
        };
        this._vm.$TAMS_API.add(requestheader,
          _dataObj.item).then((parsedResult) => {

          if (parsedResult.status) {
            console.log('added vehicle: ', parsedResult.data);
            commit('ADDED', parsedResult.data);
            resolve(parsedResult.data);
            //navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('vehicle added errors: ', error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    // add({
    //   state, rootState, rootGetters, commit, dispatch, getters,
    // }, { _dataObj }) {
    //   return new Promise((resolve, reject) => {
    //     const {
    //       item, router,
    //     } = _dataObj;

    //     const requestheader = {
    //       router,
    //       token: rootGetters['auth/TOKEN'],
    //       apiName: config.TAXIVEHICLE_URL,
    //     };
    //     this._vm.$TAMS_API.add(requestheader,
    //       item).then((parsedResult) => {

    //       if (parsedResult.status) {

    //         commit('ADDED', item);
    //         resolve(parsedResult.data[0]);
    //       // navigateTo(router, parsed_result.data[0]);
    //       }
    //     }).catch((error) => {
    //     // commit('LOGINFAILED', error.data);
    //       reject(error);
    //     });
    //   });

    addChangeRequest({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          item,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLESTATUS,
        };
        this._vm.$TAMS_API.add(requestheader,
          item).then((parsedResult) => {

          if (parsedResult.status) {

            commit('ADDED', item);
            resolve(parsedResult.data[0]);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    getAllByTaxiAssID({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TAXIVEHICLE_URL,
        };

        this._vm.$TAMS_API.getByTaxiAssId(requestheader, id).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            //commit('VEHICLESLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getVehiclesByAssId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      taxiassociation_id,
      skip,
      len
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLESBYASSOCIATION_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, {
          status: taxiassociation_id,
          skip,
          len
        }).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            // commit('VEHICLESLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('LOGINFAILED', error.data);
        });
      });
    },
    getTripTracker({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      taxiassociationid,
      vehicleid,
      router
    }) {
      
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETVEHICLEIDTRIPTRACKER_URL,
        };

        this._vm.$TAMS_API.getCustomURL(requestheader, {
          taxiassociationid,
          vehicleid,
        }).then((parsedResultOP) => {
          //console.log(parsedResultOP);
          if (parsedResultOP.status) {
            resolve(parsedResultOP);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('', error.data);
        });
      });
    },
    getVehicleLeftRank({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      vehicleid,
      vehicleleftrank,
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLESUPDATELEFTRANK_URL,
        };

        

        this._vm.$TAMS_API.getByPaging2(requestheader, {
          id:vehicleid,
          text:vehicleleftrank,
        }).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            // commit('VEHICLESLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('LOGINFAILED', error.data);
        });
      });
    },
    getVehiclesByAssIdExpiredLicense({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      taxiassociation_id,
      skip,
      len
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLESBYASSOCIATIONEXPIREDLICENSES_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, {
          status: taxiassociation_id,
          skip,
          len
        }).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            // commit('VEHICLESLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('LOGINFAILED', error.data);
        });
      });
    },
    getVehiclesByAssIdSummary({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      taxiassociation_id,
      skip,
      len
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLESBYASSOCIATIONSUMMARY_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, {
          status: taxiassociation_id,
          skip,
          len
        }).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            // commit('VEHICLESLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('LOGINFAILED', error.data);
        });
      });
    },

    getVehiclesByAssIdClean({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      taxiassociation_id,
      skip,
      len
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLESBYASSOCIATIONCLEAN_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, {
          status: taxiassociation_id,
          skip,
          len
        }).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            // commit('VEHICLESLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('LOGINFAILED', error.data);
        });
      });
    },

    getVehiclesByAssIdDropDowns({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      taxiassociation_id,
      skip,
      len
    }) {
      
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLESBYASSIDDROPDOWN_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, {
          status: taxiassociation_id,
          skip,
          len
        }).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            // commit('VEHICLESLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('LOGINFAILED', error.data);
        });
      });
    },

    getVehicleFinesById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id,
      skip,
      len
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEFINESBYID_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, {
          status: id,
          skip,
          len
        }).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            // commit('VEHICLESLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getAllByTaxiAffilliationID({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETVEHICLESBYAFFILIATION_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            // commit('VEHICLEBYAFFLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('vehicle errors: ', error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    deleteDatacollectionById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id,
      deleted_by
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.DATACOLLECTIONDELETE_URL,
        };

        const addModel = {
          collectionsid: id,
          deletedby: deleted_by
        };

        this._vm.$TAMS_API.add(requestheader, addModel).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            // commit('VEHICLEBYAFFLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('vehicle errors: ', error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    SaveDatacollected({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      payload
    }) {
      return new Promise((resolve, reject) => {
            commit('DATACOLLECTEDLOADED', payload);
            resolve();
      });
    },
    getDatacollectedByAssId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.DATACOLLECTIONBYASSOCIATION_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResultOP) => {
          console.log("datacollected By ass id: ", parsedResultOP.data);
          if (parsedResultOP.status) {
            
            //commit('DATACOLLECTEDLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data[0].data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('datacollected errors: ', error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    getTaxiRoutesByTaxiId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETVEHICLESTAXIROUTESBYTAXIID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResultOP) => {
          console.log("datacollected By ass id: ",parsedResultOP.data);
          if (parsedResultOP.status) {
            //commit('DATACOLLECTEDLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('datacollected errors: ', error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getDatacollectedOperatorImage({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.DATACOLLECTIONOPERATORIMAGE_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResultOP) => {
          console.log("datacollected operator image: ", parsedResultOP.data);
          if (parsedResultOP.status) {
            // commit('DATACOLLECTEDOPERATORIMAGE', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('datacollected operator errors: ', error);
          //commit('LOGINFAILED', error);
          reject(error);
        });
      });
    },

    getDatacollectedVehicleImage({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => {
        
            resolve(getters["GETDATACOLLECTED"]);
  
      });
    },

    getDatacollectedDriverImages({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.DATACOLLECTIONDRIVERIMAGES_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResultOP) => {
          console.log("datacollected driver image: ", parsedResultOP.data);
          if (parsedResultOP.status) {
            // commit('DATACOLLECTEDOPERATORIMAGE', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('datacollected driver errors: ', error);
          // commit('LOGINFAILED', error);
          reject(error);
        });
      });
    },

    getVehicleTripHistoryById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLETRIPHISTORYBYID_URL,
        };

        

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResultOP) => {
          console.log("vehicle trip history: ", parsedResultOP.data);
          if (parsedResultOP.status) {
            // commit('DATACOLLECTEDOPERATORIMAGE', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('vehicle trip errors: ', error);
          reject(error);
          // commit('LOGINFAILED', error);
        });
      });
    },

    

    getVehicleStatus({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      status,
      skip,
      len,
      router
    }) {

      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLESTATUS,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, {
          status: status,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            commit('STATUSLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);

            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          // commit('', error.data);
          reject(error);
        });
      });
    },

    getVehicleTracker({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      status,
      skip,
      len,
      router
    }) {

      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLETRACKER_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, {
          status: status,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            // commit('VEHICLETRACKERPROVIDERLOADED', parsedResultOP.data);
            
            resolve(parsedResultOP.data);

            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('', error.data);
        });
      });
    },

    getVehicleFinance({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      status,
      skip,
      len,
      router
    }) {

      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEFINANCE_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, {
          status: status,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            // commit('FINANCEPROVIDERLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);

            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          // commit('', error.data);
          reject(error);
        });
      });
    },

    searchVehicleVINnumber({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _getPagingModel,
      router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEEXISITS_URL,
        };

        this._vm.$TAMS_API.getByPaging2(requestheader, _getPagingModel).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
            // navigateTo(router, parsed_result.data[0]);
          } else
            reject(parsedResult);
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    searchVehicleRegNumber({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _getPagingModel,
      router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEEXISITSREGNUM_URL,
        };
        
        this._vm.$TAMS_API.getByPaging2(requestheader, _getPagingModel).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
            // navigateTo(router, parsed_result.data[0]);
          } else
            reject(parsedResult);
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },



    getVehicleInsurance({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      status,
      skip,
      len,
      router
    }) {

      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEINSURANCE_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, {
          status: status,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            // commit('INSURANCELOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);

            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          // commit('', error.data);
          reject(error);
        });
      });
    },

    getVehicleBySearch({
            state,
            rootState,
            rootGetters,
            commit,
            dispatch,
            getters,
          }, {
            router,
            searchText,
            taxiassociationsId
          }) {
            return new Promise((resolve, reject) => {
              
              const requestheader = {
                router,
                token: rootGetters['auth/TOKEN'],
                apiName: config.VEHICLESEARCH_URL,
              };
              this._vm.$TAMS_API.getCustomURL(requestheader, 
                {
                  taxiassociationsId,
                  searchText
                }).then((parsedResult) => {
                if (parsedResult.status) {
                  // commit('LOADED', parsedResult.data);
                  resolve(parsedResult.data);
                  // navigateTo(router, parsed_result.data[0]);
                }
              }).catch((error) => {
                // commit('LOGINFAILED', error.data);
                reject(error);
              });
            });
          },

    getVehicleInvoiceDisputesByid({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          id,
          router
        } = _dataObj;
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETTAXIVEHICLEINVOICEDISPUTES_URL,
        };
        this._vm.$TAMSFIN_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('INVOICESLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getVehicleReceiptDisputesByid({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          id,
          router
        } = _dataObj;
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETTAXIVEHICLERECEIPTDISPUTES_URL,
        };
        this._vm.$TAMSFIN_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('RECEIPTSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getVehicleBillingsById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      status,
      skip,
      len,
      router
    }) {
      
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEGETBILLING_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging(requestheader, {
          status: status,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {
          console.log("vehicle billings: (module)", parsedResultOP);
          if (parsedResultOP.status) {
            console.log("this should be populating the getter..", parsedResultOP.data);
            // commit('VEHICLEBILLINGSLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getVehicleTransactionsById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      skip,
      len,
      router
    }) {
      
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLETRANSACTIONSBYID_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging(requestheader, {
          status: id,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {
          if (parsedResultOP.status) {
            console.log("vehicle transactions by id: ", parsedResultOP.data);
            // commit('VEHICLEBILLINGSLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('LOGINFAILED', error.data);
        });
      });
    },

    getAllTransactionsByAccNo({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      associationsid,
      accountnumber,
      skip,
      length,
      router
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETTAXIVEHICLETRANSACTIONS_URL,
        };

        this._vm.$TAMSFIN_API.getbyAccountNumber(requestheader, {
          associationsid: associationsid,
          accountnumber,
          skip,
          length
        }).then((parsedResultOP) => {
          console.log('transactions module page:', parsedResultOP);
          if (parsedResultOP.status) {
            // commit('TRANSACTIONSLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('transactions errors: ', error);
          reject(error);
          // commit('LOGINFAILED', error.data);
        });
      });
    },

    getTaxiTrackerProvider({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      status,
      skip,
      len,
      router
    }) {
      
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TAXITRACKERPROVIDER_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging(requestheader, {
          status: status,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {
          console.log("Tracker Provider: (module)", parsedResultOP);
          if (parsedResultOP.status) {
            console.log("this should be populating the getter..", parsedResultOP.data);
            commit('VEHICLEBILLINGSLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('LOGINFAILED', error.data);
        });
      });
    },



    getTaxinsuranceProviders({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      status,
      skip,
      len,
      router
    }) {
      
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEINSURANCE_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging(requestheader, {
          status: status,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {
          console.log("vehicle billings: (module)", parsedResultOP);
          if (parsedResultOP.status) {
            console.log("this should be populating the getter..", parsedResultOP.data);
            // commit('VEHICLEBILLINGSLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getTaxiVehicleServiceProvider({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      status,
      skip,
      len,
      router
    }) {
      
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEGETBILLING_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging(requestheader, {
          status: status,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {
          console.log("vehicle billings: (module)", parsedResultOP);
          if (parsedResultOP.status) {
            console.log("this should be populating the getter..", parsedResultOP.data);
            // commit('VEHICLEBILLINGSLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getTaxiAssRoutes({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_ROUTESBYID_URL,
        };
        
        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            // commit('ROUTESLOADED', parsedResultOP.data);
            //commit('LOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);

            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          //commit('LOGINFAILED', error.data);
        });
      });
    },

    collectorVehiclesById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.COLLECTORVEHICLESGETBYID_URL,
        };
        
        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            // commit('ROUTESLOADED', parsedResultOP.data);
            //commit('LOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);

            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          //commit('LOGINFAILED', error.data);
        });
      });
    },

    

    changeVehicleStatusRequest({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      addModel
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLESTATUSCHANGE_URL,
        };

        this._vm.$TAMS_API.add(requestheader, addModel).then((parsedResultOP) => {
          console.log("request results: ", parsedResultOP);
          if (parsedResultOP.status) {
            // commit('VEHICLESLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    collectorVehiclesAdd({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      addModel
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.COLLECTORVEHICLEADD_URL,
        };

        this._vm.$TAMS_API.add(requestheader, addModel).then((parsedResultOP) => {
          console.log("request results: ", parsedResultOP);
          if (parsedResultOP.status) {
            // commit('VEHICLESLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('LOGINFAILED', error.data);
        });
      });
    },

    collectorVehiclesDelete({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      addModel
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.COLLECTORVEHICLEDELETE_URL,
        };

        this._vm.$TAMS_API.add(requestheader, addModel).then((parsedResultOP) => {
          console.log("request results: ", parsedResultOP);
          if (parsedResultOP.status) {
            // commit('VEHICLESLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('LOGINFAILED', error.data);
        });
      });
    },


    // getByPaging({
    //   state, rootState, rootGetters, commit, dispatch, getters,
    // }, { _waitObj }) {
    //   const { _wait } = _waitObj;
    //   const _waitname = _waitObj.name;
    //   const router = _waitObj._router;

    //   if (_waitname) { _wait.start(_waitname); }
    //   this._vm.$TAMS_API.getByPaging(router, rootGetters['auth/TOKEN'],
    //     config.OPERATORVEHICLE_URL,
    //     {
    //       is_active: true,
    //       skip: 0,
    //       length: 10,
    //     }).then((parsedResult) => {
    //       console.log(parsedResult);
    //       if (parsedResult.status) {
    //         commit('LOADED', parsedResult.data);
    //         // navigateTo(router, parsed_result.data[0]);
    //       }

    //       if (_waitname) { _wait.end(_waitname); }
    //     }).catch((error) => {
    //       console.log(error);
    //       commit('LOGINFAILED', error.data);
    //       if (_waitname) { _wait.end(_waitname); }
    //     });
    // },

    delete({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.OPERATORVEHICLE_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id, {
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('DELETE', id);
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    markAsDeleted({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      deletedby,
      id,
      router,
    }) {
      return new Promise((resolve, reject) => {
        
   

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.OPERATORVEHICLE_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id, {
            id,
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('MARKASDELETE', {
            //   id,
            //   deletedby
            // });
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    getVehicleRemoveBreakdown({
            state,
            rootState,
            rootGetters,
            commit,
            dispatch,
            getters,
          }, {
            router,
            taxiassociationid,
            taxivehicleid,
            updatedby
          }) {
            return new Promise((resolve, reject) => {
              
              const requestheader = {
                router,
                token: rootGetters['auth/TOKEN'],
                apiName: config.VEHICLEREMOVEUNDERBREAKDOWN_URL,
              };
              this._vm.$TAMS_API.add(requestheader,  
                {
                    taxiassociationid,
                    taxivehicleid,
                    updatedby
                }).then((parsedResult) => {
                if (parsedResult.status) {
                  // commit('LOADED', parsedResult.data);
                  resolve(parsedResult.data);
                  // navigateTo(router, parsed_result.data[0]);
                }
              }).catch((error) => {
                // commit('LOGINFAILED', error.data);
                reject(error);
              });
            });
          },
      getVehiclePlaceUnderBreakdown({
              state,
              rootState,
              rootGetters,
              commit,
              dispatch,
              getters,
            }, {
              router,
              taxiassociationid,
              taxivehicleid,
              updatedby
            }) {
              return new Promise((resolve, reject) => {
                
                const requestheader = {
                  router,
                  token: rootGetters['auth/TOKEN'],
                  apiName: config.VEHICLEPLACEUNDERBREAKDOWN_URL,
                };
                this._vm.$TAMS_API.add(requestheader, 
                   {
                    taxiassociationid,
                    taxivehicleid,
                    updatedby
                  }).then((parsedResult) => {
                  if (parsedResult.status) {
                    // commit('LOADED', parsedResult.data);
                    resolve(parsedResult.data);
                    // navigateTo(router, parsed_result.data[0]);
                  }
                }).catch((error) => {
                  // commit('LOGINFAILED', error.data);
                  reject(error);
                });
              });
            },
    restoreDeleted({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    },  {
      restoredby,
      id,
      router,
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.OPERATORVEHICLE_URL,
        };

        

        this._vm.$TAMS_API.restoreDeleted(requestheader, {
          id,
          restoredby,
        }).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('RESTOREDELETED', {
            //   id
            // });
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

  },
};


// ===
// Private helpers
// ===

function navigateTo(router, _userProfile) {
  if (_userProfile.is_system_admin) {
    router.push('sysadmin_dashboard');
  }
}