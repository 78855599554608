<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageVehicle'"
      :ActiveMenu="'BulkDocumentsUpload'"
    />
    <!-- end #sidebar -->
    <transition name="bounce">
      <div id="content" class="content">
        <!-- begin page-header -->
        <headerpagebreadcrumb
          pageheaderTitle="Upload Taxi Supporting Documents"
          pageheaderdescription
          breadcrumbitem1="Home"
          breadcrumbitem2="Approval"
          breadcrumbitem3="Upload Documents"
        />
        <!-- begin nav-pills -->

        <div class="panel panel-primary">
          <div class="panel-heading">
            <div class="panel-heading-btn">
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-default"
                data-click="panel-expand"
              >
                <i class="fa fa-expand"></i>
              </a>
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-success"
                data-click="panel-reload"
              >
                <i class="fa fa-redo"></i>
              </a>
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-warning"
                data-click="panel-collapse"
              >
                <i class="fa fa-minus"></i>
              </a>
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-danger"
                data-click="panel-remove"
              >
                <i class="fa fa-times"></i>
              </a>
            </div>
            <h4 class="panel-title">Upload Documents</h4>
          </div>
          <div class="panel-body">
            <div class="row panel">
              <!-- begin col-8 -->
              <div class="col-lg-6 hide">
                <!-- begin form-group -->
                <div class="form-group row">
                  <label class="col-md-3 col-form-label text-md-right">
                    Select Document
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-8">
                    <select
                      class="form-control col-md-12"
                      data-size="5"
                      data-live-search="true"
                      id="selectDocument"
                      
                    >
                      <option>Select Taxi Document</option>
                      <option :v-model="document" v-bind:value="item.id" :key="item.id" v-for="item in OPERATORVEHICLESDOCUMENTS" >{{item.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- end col-8 -->

              <div class="col-lg-6">
                <!-- begin form-group -->
                <div class="form-group row">
                  <br />
                  <label class="col-md-2 col-form-label text-md-right">
                    Select Vehicle
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-8">
                    <select
                      class="default-selectTaxi text-center default-select form-control col-md-12"
                      data-size="5"
                      data-live-search="true"
                      id="selectTaxi"
                    >
                      <option>Select Taxi Vehicle</option>
                      <option
                        v-for="item in OPERATORVEHICLES"
                        :key="item._taxi_operator_vehicles_id"
                        :value="item._taxi_operator_vehicles_id"
                      >{{item._licence_plate}} ({{item._taxi_operators_name}})</option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- </div> -->
            </div>
            <!-- end card -->
            <div class="card text-center">
              <div class="card-block">
                <div class="tab-content p-0 m-0">
                  <div class="tab-pane fade active show" id="card-tab-1" v-show="SELECTEDTAXI">
                    <div class="invoice-to">
                      <DropZoneControl
                        :acceptedFiles="acceptedFiles"
                        :ClearData="true"
                        v-on:fileadded="fileadded"
                        :SessionID="DropZoneSessionID"
                      ></DropZoneControl>
                    </div>

                    <!--End dropzone!-->
                  </div>
                </div>
              </div>
            </div>

            <div
              class="panel panel-primary"
              v-if="GETUPLOADEDFILES.length > 0"
              data-sortable-id="table-basic-7"
            >
              <!-- begin panel-heading -->
              <div class="panel-heading ui-sortable-handle">
                <h4 class="panel-title">Uploaded Files</h4>
              </div>
              <!-- end panel-heading -->
              <!-- begin panel-body -->
              <div class="panel-body">
                <!-- begin table-responsive -->
                <div class="table-responsive">
                  <table class="table table-striped m-b-0">
                    <thead>
                      <tr>
                        <th>Filename</th>
                        <th>Type</th>
                        <th>Size</th>
                        <th>Document Type</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr :key="item" v-for="(item) in GETUPLOADEDFILES">
                        <td>{{item.filename}}</td>
                        <td>{{item.mimetype}}</td>
                        <td>{{item.size}}</td>
                        <td>
                          <select
                            class="form-control col-sm-4"
                            data-size="10"
                            data-live-search="true"
                            data-parsley-group="step-1"
                            data-parsley-required="true"
                            v-model="item.customType"
                          >
                            <option value="0" selected>Select Document Type</option>
                            <option
                              v-bind:key="item._id"
                              v-for="item in GETALLDOCUMENTTYPE"
                              :value="item._id"
                            >{{item._name}}</option>
                          </select>
                        </td>
                        <td class="with-btn" nowrap>
                          <a
                            href="#"
                            @click="deletefile(context,item.fileId, USERPROFILE.system_usersid)"
                            class="btn btn-sm btn-danger width-60"
                          >Delete</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- end table-responsive -->
              </div>
              <!-- end panel-body -->
              <!-- begin hljs-wrapper -->
            </div>

            <div class="btn-row">
              <div class="text-center">
                <button
                  type="button"
                  @click="UploadSupportingFiles(context)"
                  class="btn btn-sm btn-primary width-200 height-40 m-5"
                >Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { default as DropZoneControl, default as operatorDropZoneControl } from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import { setTimeout } from "timers";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("approvals");

export default {
  name: "AuthorizeApprovalRequest",
  data: function () {
    return {
      acceptedFiles: "application/pdf",
      acceptedFiles2: "application/pdf",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      context: this,
      SELECTEDTAXI: true,
      SELECTEDOPERATOR: true,
      SELECTEDDRIVER: true,
      addedsuccess: undefined,
      errormessage: undefined,
      vehicleDropZoneSessionID: this.$uuid.v4(),
      operatorDropZoneSessionID: this.$uuid.v4(),
      id: this.$route.params.id,
      affiliation_id: this.$store.getters["auth/USERPROFILE"]
        .selected_taxi_association._taxi_affiliation_id,
      GETALLDOCUMENTTYPE: [],
      GETAllTAXIMODELS: this.$store.getters["TV/GETALLMODEL"],
      GETUPLOADEDFILES: [],
      OPERATORVEHICLES: [],
      GETAllTAXIDRIVERS: [],
      OPERATORSBYAFFILLIATION: [],
      GETOPERATORUPLOADEDFILES: [],
      OPERATORVEHICLESDOCUMENTS: [],
      DOCUMENTID: undefined,
      document: undefined,
      comment: "",
      skip: 0,
      length: 100000000000,
      dataLoaded: false,
      DropZoneSessionID: this.$uuid.v4(),
      SELECTEDTAXIASSOCIATION: this.$store.getters[
        "auth/SELECTEDTAXIASSOCIATION"
      ],
      abbr_name: this.$store.getters[
        "auth/SELECTEDTAXIASSOCIATION"
      ] ._abbriviated_name,
      // canApproveOpperator: this.$store.getters["auth/GETUSERROLES"][13]._code,
      // canApproveDriver: this.$store.getters["auth/GETUSERROLES"][6]._code,
      // canApproveOpperatorVehicle: this.$store.getters["auth/GETUSERROLES"][5]._code,
    };
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    operatorDropZoneControl,
  },
  computed: {
    //...mapGetters(["GETALLPENDING", "GETALLAPPROVED", "GETALLREJECTED"]),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  beforeMount() {},
  watch: {
    DOCUMENTID: (object) =>{
      console.log('selected document: ',object)
    },


  },
  methods: {
    ...mapActions(["getAllApproved"]),

    GETTAXISBYMODELID: function (context, id) {
      return context.OPERATORVEHICLES.filter(
        (item) => item._vehiclemodel_id === id
      );
    },
    loadLookUps(context) {
      
      context.$store
        .dispatch("lookups/init", {
          router: context.$router,
        })
        .then((data) => {
          console.log("loadLookUps found: ", data);
          
          context.GETALLDOCUMENTTYPE = data.LOADEDDOCUMENTTYPE;
        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
    UploadSupportingFiles: (context) => {
      

      context.$Notify
        .YesNo(false, {
          context,
          item: {
            taxi_operator_vehicles_id: $("#selectTaxi").val(),
          },
        })
        .then((value) => {
          

          if (!value) return;

          const dropzone_uploads = context.GETUPLOADEDFILES;
          const fileuploaded = [];

          if (dropzone_uploads.length > 0) {
            var failedvalidation = false;
            dropzone_uploads.forEach((element) => {
              if (element.customType === 0) {
                failedvalidation = true;
                return;
              }
              fileuploaded.push({
                customType: element.customType,
                fileId: element.fileId,
              });
            });
            if (failedvalidation) {
              value.context.$Notify.Error("Please Select Document Type.", "");
              return;
            }
          }

          value.item.dropzoneid = context.DropZoneSessionID;
          value.item.dropzone_files = fileuploaded;
          value.item.addedby = context.USERID;

          

          // 
          context.$store
            .dispatch("OperatorVehicle/updateVehicleSupportingDocs", {
              addModel: value.item,
              router: context.$router,
            })
            .then((data) => {
              context.$Notify.Success("Uploaded Successfully");
              context.GETUPLOADEDFILES = [];
              context.$router.go();
            })
            .catch((err) => {
              console.log("operator Vehicles errors -> ", err);
            })
            .finally(() => {
              // $("#data-table-vehicles").DataTable({
              //   responsive: true,
              // });
            });
          //this.$emit('init')
        });
    },

    operatorVehiclesById: (context) => {
      // 
      context.$store
        .dispatch("OperatorVehicle/getVehiclesByAssIdDropDowns", {
          router: context.$router,
          taxiassociation_id: context.taxiassociationid,
          skip: context.skip,
          len: context.length,
        })
        .then((data) => {
          console.log("operator Vehicles: ", data);
          context.OPERATORVEHICLES = data;

          $(".default-selectTaxi").select2({
            multiple: false,
            placeholder: "Select A Taxi",
            allowClear: false,
          });

          $(".default-selectTaxi").val(null).trigger("change");
        })
        .catch((err) => {
          console.log("operator Vehicles errors -> ", err);
        })
        .finally(() => {
          // $("#data-table-vehicles").DataTable({
          //   responsive: true,
          // });
        });
      //this.$emit('init')
    },

    operatorVehicleDriveDocuments: (context) => {
      
      context.$store
        .dispatch("OperatorVehicle/vehicleDriveDocuments", {
          _getPagingModel: {
            id: context.taxiassociationid,
            text: context.abbr_name,
          },
          router: context.$router,
        })
        .then((data) => {
          console.log("operator Vehicle Documents: ", data);
          context.OPERATORVEHICLESDOCUMENTS = data;

          $("#selectDocument").select2({
            multiple: false,
            placeholder: "Select A Document",
            allowClear: false,
          });

          $("#selectDocument").on("change", function () {
            
            var document_ = $(
              "#selectDocument option:selected"
            ).val('data')[0];

            var documentID = document_._value;

            var documentName = document_.text;

            console.log('document data:', document_);
            console.log('document data_id :', documentID);
            
            if (document_ != null) {
              context.vehicleDriveFileById(context, documentID,documentName);
            } else {
              console.log("no vehicle selected!");
            }
          });

          // $(".default-selectTaxi").val(null).trigger("change");
        })
        .catch((err) => {
          console.log("operator Vehicles Documents errors -> ", err);
        })
        .finally(() => {
          // $("#data-table-vehicles").DataTable({
          //   responsive: true,
          // });
        });
      //this.$emit('init')
    },

    vehicleDriveFileById: (context,id,text) => {
      
      context.$store
        .dispatch("OperatorVehicle/getdriveItemById", {
          _getPagingModel: {
            id,
            text
          },
          router: context.$router,
        })
        .then((data) => {
          console.log("operator Vehicle Documents: ", data);

          // $(".default-selectTaxi").val(null).trigger("change");
        })
        .catch((err) => {
          console.log("operator Vehicles Documents errors -> ", err);
        })
        .finally(() => {
          // $("#data-table-vehicles").DataTable({
          //   responsive: true,
          // });
        });
      //this.$emit('init')
    },


    fileadded: function (data) {
      
      console.log("Header:fileadded: ", data);
      //  this.$emit('init')
      this.GETUPLOADEDFILES.push({
        filename: data.name,
        mimetype: data.type,
        size: data.size,
        customType: data.customType,
        fileId: data.fileId,
      });
    },
  },

  mounted: function () {
    this.operatorVehiclesById(this.context);
    this.operatorVehicleDriveDocuments(this.context);

    App.init();

    this.loadLookUps(this.context);

    $(".nav-link").click(() => {
      setTimeout(() => {
        $(".default-select").select2({
          multiple: true,
          allowClear: true,
        });
        $(".default-selectOperator").val(null).trigger("change");
        $(".default-selectTaxi").val(null).trigger("change");
        $(".default-selectTaxiDrivers").val(null).trigger("change");
      }, 500);
    });

    // $(".nav-pills a").data("context", this.context);

    // $(".nav-pills a").on("shown.bs.tab", function (event) {
    //   const context = $(".nav-pills a").data("context");
    //   var x = $(event.target).text(); // active tab
    //   var y = $(event.relatedTarget).text(); // previous tab

    //   if (x === "ApprovedApproved") {

    //   } else if (x === "AwaitingAwaiting") {

    //   } else if (x === "RejectedRejected") {

    //   }
    // });
  },
};
</script>