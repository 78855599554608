<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TaxiAssociation'"
      :ActiveMenu="'ManageTaxiAssociation'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Taxi Association Edit"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Association"
        breadcrumbitem3="Edit"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Taxi Association Information
                      <small
                        class="text-ellipsis"
                      >District, Taxi Affiliation, Name and Tel</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-2">
                    <span class="number">2</span>
                    <span class="info text-ellipsis">
                      Contact Person And Logo
                      <small
                        class="text-ellipsis"
                      >Logo And Personal Details Of Contact Person.....</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-3">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Complete Registration</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-2 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <!-- begin row -->
                  <div class="row">
                    <!-- begin col-8 -->

                    <div class="col-md-12">
                      <div class="row panel panel-inverse">
                        <!-- begin col-8 -->
                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              District
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <select
                                name="DistrictName"
                                class="form-control"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                              >
                                <option disabled value>Select District</option>
                                <option
                                  v-for="item in GETALLDISTRICTSBYTAXIFILICATION(context,taxi_affiliation_id)"
                                  v-bind:key="item.district_id"
                                  :value="item.district_id"
                                >{{ item.district_name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->

                          
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              RAS Registration Number
                              <span class="text-danger"></span>
                            </label>
                            <div class="col-md-6">
                              <input
                                placeholder="e.g. RASGP817986585/3"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATEMODEL.ras_registration_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Taxi Association Name
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="taxiassociationname"
                                placeholder="e.g. Midrand Taxi Association"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="UPDATEMODEL.name"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Abbreviation
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="Abbreviation"
                                placeholder="e.g. MTA"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="UPDATEMODEL.abbriviatedname"
                              />
                            </div>
                          </div>
                        </div>
                        <!-- end col-8 -->

                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Current Logo:</label>
                            <div class="col-md-6">
                              <img
                                v-show="LOGO"
                                :src="`${LOGO != undefined ? LOGO : '' }`"
                                class="width-80 height-30"
                              />
                            </div>
                          </div>
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Telephone</label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                id="telephone"
                                name="telephone"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATEMODEL.telephone"
                              />
                            </div>
                          </div>
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Mobile Number</label>
                            <div class="col-md-6">
                              <input
                                id="TaxiAssociationMobileNumber"
                                type="number"
                                name="TaxiAssociationMobileNumber"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATEMODEL.mobilenumber"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>

                        <div class="col-lg-6">
                          <!-- begin col-6 -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Physical Address
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="6 Tottenham Ave, Melrose Flats"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATEMODEL.physicaladdress"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Physical Address 2
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="36 Saxon Rd, Sandhurst "
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATEMODEL.physicaladdress2"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Physical Suburb
                            </label>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="UPDATEMODEL.physicalsuburb_id"
                              >
                                <option disabled value>Select a Suburb</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLSUBURS"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>
                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Postal Address
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATEMODEL.postaladdress"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Postal Address 2
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATEMODEL.postaladdress2"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Postal Suburb
                              <span class="text-danger"></span>
                            </label>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="100"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="UPDATEMODEL.postalsuburb_id"
                              >
                                <option disabled value>Select a Suburb</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLSUBURS"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>
                      </div>
                    </div>
                    <!-- end col-8 -->
                  </div>
                  <!-- end row -->
                  <!-- end fieldset -->
                </div>
                <!-- end step-2 -->
                <!-- begin step-2 -->
                <div id="step-2">
                  <!-- begin row -->
                  <div class="row">
                    <!-- begin col-8 -->

                    <div class="col-md-12">
                      <div class="row panel panel-inverse">
                        <!-- begin col-8 -->
                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Full Name
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="ContactPersonFirstName"
                                data-parsley-group="step-2"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATEMODEL.contactperson_name"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Mobile Number
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                id="ContactPersonMobileNumber"
                                type="number"
                                name="ContactPersonMobileNumber"
                                placeholder="(082) 999-9999"
                                data-parsley-group="step-2"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATEMODEL.contactperson_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>
                        <!-- end col-8 -->
                        <div class="col-lg-6">
                          <div class="panel panel-inverse panel-primary">
                            <div class="panel-heading">
                              <div class="panel-heading-btn"></div>
                              <h4 class="panel-title">Drop Logo In Here(Recommended Size: Width:161 x Height:50)</h4>
                            </div>
                            <div class="panel-body text-inverse">
                              <DropZoneControl
                                :ClearData="true"
                                :acceptedFiles="acceptedFiles"
                                :maxFiles="maxFiles"
                                :resizeWidth="DropZoneresizeWidth"
                                :resizeHeight="DropZoneresizeHeight"
                                v-on:fileadded="fileadded"
                                :SessionID="DropZoneSessionID"
                              ></DropZoneControl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end col-8 -->
                  </div>
                  <!-- end row -->
                </div>
                <!-- end step-2 -->
                <!-- begin step-3 -->
                <div id="step-3">
                  <div class="jumbotron m-b-0 text-center">
                    <h2 class="display-4">Taxi Association Successfully Updated</h2>
                    <br />
                    <br />
                    <p>
                      <router-link
                        to="/TaxiAssociation/Manage"
                        v-bind:class="{ 'btn btn-primary btn-lg': true }"
                        tag="button"
                      >Proceed To Association List</router-link>
                    </p>
                  </div>
                </div>
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>

        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->
	


<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TA");

export default {
  name: "AddTaxiAssociation",
  data: function() {
    return {
      currencySymbol: "R ",
      position: "prefix",
      DropZoneresizeWidth: 292,
      DropZoneresizeHeight: 40,
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      SYSTEMUSERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      GETALLDISTRICTS: [],
      GETALLSUBURS: [],
      id: this.$route.params.id,
      LOGO: undefined,
      taxi_affiliation_id: "",
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      selectedTaxiAffiliationId: undefined,
      selectedDistrictId: undefined,
      contactdetails_first_name: undefined,
      contactdetails_surname: undefined,
      DropZoneSessionID: this.$uuid.v4(),
      acceptedFiles: "image/*",
      maxFiles: 1,
      firsttime: 0,
      UPDATEMODEL: {
        districtid: 0,
        name: "",
        abbriviatedname: "",
        code: "",
        telephone: "",
        mobilenumber: "",
        contactperson_name: "",
        contactperson_number: "",
        postaladdress: "",
        postaladdress2: "",
        postalsuburb_id: 0,
        physicaladdress: "",
        physicaladdress2: "",
        physicalsuburb_id: 0,
        maximum_number_vehicles: 0,
        maximum_number_members: 0,
        dropzoneid: "",
        updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
        ras_registration_number: "",
      }
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl
  },
  beforeMount() {
    this.associationById(this.context);
  },
  computed: {
    ...mapGetters({
      //GETALLTITLES: "GETALLTITLES",
      TAXIAFFILIATION: "GETTAXIAFFILIATIONS",
      TAXIASSOCIATION: "ASSOCIATIONBYID"
    }),
    currency() {
      return { [this.position]: this.currencySymbol };
    },
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions(["getById,getAssociationId"]),

    associationById: context => {
      
      context.$store
        .dispatch("TA/getById", {
          id: context.id,
          router: context.$router
        })
        .then(data => {
          console.log("association details: ", data);
          context.UPDATEMODEL.districtid = data._fk_district_id;
          context.UPDATEMODEL.name = data._name;
          context.UPDATEMODEL.abbriviatedname = data._abbriviated_name;
          context.UPDATEMODEL.code = data._code;
          context.UPDATEMODEL.telephone = data._telephone;
          context.UPDATEMODEL.mobilenumber = data._mobilenumber;
          context.UPDATEMODEL.contactperson_name = data._contact_person_name;
          context.UPDATEMODEL.contactperson_number = data._contact_person_number;
          context.UPDATEMODEL.postaladdress = data._postalaaddress;
          context.UPDATEMODEL.postaladdress2 = data._postalaaddress2;
          context.UPDATEMODEL.postalsuburb_id = data._postal_suburb_id;
          context.UPDATEMODEL.physicaladdress = data._physicaladdress;
          context.UPDATEMODEL.physicaladdress2 = data._physicaladdress2;
          context.UPDATEMODEL.physicalsuburb_id = data._physical_suburb_id;
          context.UPDATEMODEL.maximum_number_vehicles =  data._maximum_number_vehicles;
          context.UPDATEMODEL.maximum_number_members = data._maximum_number_members;
          context.taxi_affiliation_id = data._taxi_affiliation_id;
          context.UPDATEMODEL.ras_registration_number = data._ras_registration_number;
          
          

          context.associationSettingsById(context);
        
          
        })
        .catch(error => {
          console.log("association errors: ", error);
        });
    },

    associationSettingsById: context => {
      
      context.$store
        .dispatch("TA/getAssociationId", {
          id: context.id,
          router: context.$router
        })
        .then(data => {
          console.log("association settings: ", data);
          context.UPDATEMODEL.maximum_number_vehicles = data[0].maximum_number_vehicles;
          context.UPDATEMODEL.maximum_number_members = data[0].maximum_number_members 
        })
        .catch(error => {
          console.log("settings errors: ", error);
        });
    },

    update: (context, id, item) => {
      

      context.UPDATEMODEL.dropzoneid = context.DropZoneSessionID;

        context.$Notify.YesNo(false, { context, id, item }).then(value => {
        if (value) {
          $(".sw-btn-next")
            .text("")
            .append('<i class="fas fa-spinner fa-spin"></i>')
            .attr("disabled", "disabled");

          value.router = value.context.$router;
          value.context.$store
            .dispatch("TA/updateAssociation", {
              id: value.context.id,
              updateModel: value.item,
              router: value.router
            })
            .then(() => {
              $(".sw-btn-next")
                .text("Done")
                .attr("disabled", "disabled");
              $(".sw-btn-prev").attr("disabled", "disabled");
              $("#wizard").data("updateTaxiAssociation", true);
              $("#wizard").smartWizard("next");
              value.context.$Notify.Success("Successfully Updated!", "");
            })
            .catch(error => {
              $(".sw-btn-next")
                .text("Submit")
                .removeAttr("disabled");
                console.log("adding association errors: ",error)
              value.context.$Notify.Error(error.data[0].message);
            })
            .finally(() => {});
        }
      });
    },

    // AddBillingItems: context => {
    //   const res = $('form[name="form-wizard"]')
    //     .parsley()
    //     .validate("step-" + 2);

    //   if (!res) {
    //     return res;
    //   }

    //   context.billitems.startdate = context.$Toolkit.ConvertToServerDate(
    //     $("#startdate").datepicker("getDate")
    //   );
    //   context.billitems.enddate = context.$Toolkit.ConvertToServerDate(
    //     $("#enddate").datepicker("getDate")
    //   );
    //   context.billitems.nextbilldate = context.$Toolkit.ConvertToServerDate(
    //     $("#startdate").datepicker("getDate")
    //   );
    //   context.billitems.penaltyfeewarning = $("#default_rangeSlider").data(
    //     "ionRangeSlider"
    //   ).result.from;

    //   context.billitemsadded.push(context.billitems);

    //   context.billitems = {
    //     id: context.$uuid.v4(),
    //     route: {},
    //     typeofclients: {},
    //     collectionsettings: {},
    //     amount: 0,
    //     startdate: "",
    //     enddate: "",
    //     nextbilldate: "",
    //     canbedisputed: false,
    //     canbedeleted: false,
    //     penaltycollectionsettings: {},
    //     penaltyfeewarning: 0,
    //     penaltyfeeamount: 0
    //   };

    //   let table = {};

    //   if ($.fn.dataTable.isDataTable("#datatablebillitems")) {
    //     table = $("#datatablebillitems").DataTable();
    //   } else {
    //     table = $("#datatablebillitems").DataTable({
    //       responsive: true,
    //       paging: false
    //     });
    //   }
    //   $("#datatablebillitems").addClass("bs-select");
    // },
    fileadded: function(data) {
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')
      const context = $("#wizard").data("context");
      //context.GETUPLOADEDFILES = context.$store.getters["fileupload/GETALL"];
    },
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    loadLookUps(context) {
      
      context.$store
        .dispatch("lookups/init", {
          router: context.$router,
        })
        .then((data) => {
          console.log("loadLookUps found: ", data);
          
          context.GETALLDISTRICTS = data.LOADEDDISTRICTS;
          context.GETALLSUBURS = data.LOADEDSUBURBS;

          
        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
    GETALLDISTRICTSBYTAXIFILICATION: (context, id) => {
      return context.GETALLDISTRICTS.filter(
        item => item.taxi_affiliation_id === id
      );
    },
    clearAdded: item => {
      for (var propName in item) {
        item[propName] = undefined;
      }
    }
  },
  mounted: function() {
    //this.get(this.context)

    this.loadLookUps(this.context);

    
    const contexttemp = this;
    this.$store
      .dispatch("TA/getLOGOURL", {
        id: this.id
      })
      .then(result => {
        contexttemp.LOGO = result;
      })
      .catch(err => {});

    $(".btnadded")
      .mouseenter(function() {
        $(this)
          .removeClass("btn-success")
          .addClass("btn-danger")
          .text("Remove");
      })
      .mouseleave(function() {
        $(this)
          .removeClass("btn-danger")
          .addClass("btn-success")
          .text("Added");
      });

    $("#default_rangeSlider").ionRangeSlider({
      min: 0,
      max: 30,
      type: "single",
      postfix: " Days",
      maxPostfix: "+",
      prettify: false,
      hasGrid: true
    });

    $(".datepicker-disabled-past").datepicker({
      todayHighlight: true,
      autoclose: true
    });

    //$('.selectpicker1').selectpicker('render');
    //FormWizardValidation.init();

    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "",
      transitionSpeed: 0,
      backButtonSupport: false,
      keyNavigation: false,
      useURLhash: false,
      showStepURLhash: false,
      backButtonSupport: false,
      toolbarSettings: {
        toolbarPosition: "bottom"
      }
    });
    $("#wizard").data("context", this.context);
    $("#wizard").on("leaveStep", function(
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {
      const context = $("#wizard").data("context");
      const currentstep = stepNumber + 1;
      const res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

      $(".sw-btn-next").text("Next");

      if (stepDirection == "backward") return true;

      if (currentstep === 1 && stepDirection == "forward") {
        // const addonserviceerror = context.ADDONSERVICETYPES.length !== 0;
        // if (!addonserviceerror)
        //   $(".addonserviceerror")
        //     .addClass("show")
        //     .removeClass("hide");
        // return addonserviceerror;
      }
      if (currentstep === 2 && stepDirection == "forward") {
        const updateTaxiAssociation = $("#wizard").data(
          "updateTaxiAssociation"
        );
        if (!updateTaxiAssociation) {
          context.update(context, context.id, context.UPDATEMODEL);
        }
        return updateTaxiAssociation;
        
      }

      return res;
    });

    //     $(function(){
    //    $(".dropdown-menu").on('click', 'a', function(){
    //        $(this).parents('.dropdown').find('button').text($(this).text());
    //    })
    // });

    $(".selectpicker").select2();
    $("#wizard").data("updateTaxiAssociation", false);

    $(".data-table").DataTable({
      responsive: true,
      paging: false
    });
    App.init();
  },
  Updated: function() {}
};
</script>


<style scoped>
</style>

