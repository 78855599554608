<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageVehicle'"
      :ActiveMenu="'Search'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Search Vehicle"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Vehicle"
        breadcrumbitem3="Search"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-md-12">
          <!-- begin result-container -->
          <div class="result-container">
            <!-- begin input-group -->
            <div class="input-group input-group-lg m-b-20">
              <input
                type="text"
                v-on:keydown.enter.prevent="onSeachclick(context)"
                v-model="searchText"
                class="form-control input-white"
                placeholder="Enter keywords here..."
              />
              <div class="input-group-append">
                <button
                  type="button"
                  @click="onSeachclick(context)"
                  class="btn btn-primary"
                  :disabled="$wait.is('search')"
                >
                  <i class="fa fa-search fa-fw" v-show="!$wait.is('search')"></i>
                  <i
                    class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
                    v-show="$wait.is('search')"
                  ></i>
                  Search
                </button>
              </div>
            </div>
            <!-- end input-group -->

            <!-- begin nav-pills -->

            <ul class="result-list">
              <li
                v-for="(item) in SEARCHRESULTS"
                :key="item._taxi_operator_vehicles_id"
                :value="item._taxi_operator_vehicles_id"
              >
                <a
                  href="#"
                  v-if="item._front_image"
                  class="result-image"
                  :style="{ 'background-image': 'url(' + item._front_image[0]['data'] + ')' }"
                ></a>
                <a
                  href="#"
                  v-if="!item._front_image"
                  class="result-image"
                  style="background-image: url(../assets/img/gallery/defaultbackground.jpg)"
                ></a>
                <div class="result-info">
                  <h4 class="title">
                    <router-link
                    class=""
                    :to="{ name: 'taxioperatorprofile', params: { id: item._fk_taxi_operator_id } }"
                  >{{item._taxi_operators_name.toUpperCase()}}</router-link>
                  </h4>
                  <p class="location">
                    <b>Number Plate:</b>
                    {{item._licence_number}}
                  </p>
                  <p class="location">
                    <b>Make Model:</b>
                   {{item._vehicle_model+' '+item._vehicle_make}}
                  </p>
                  <p class="location">
                    <b>Assigned Driver:</b>
                    {{item._taxidrivers_name != null ? item._taxidrivers_name : 'No Driver assigned' }}
                  </p>
                  <p class="location">
                    <b>Status:</b> 
                     <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._deleted_by }"
                        v-if="item._deleted_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item._is_active, 'label-secondary':!item._is_active  }"
                        v-if="!item._deleted_by"
                      >{{item._vehiclestatus_name }}</span>
                  </p>
                  <div class="btn-row">
                    <div class="btn-group">
                    
                      <router-link
                        tag="button"
                        class="btn btn-primary width-100"
                         :to="{ name:  'updatevehicle',  params: { id: item._taxi_operator_vehicles_id }}"
                      >Edit</router-link>
                      <router-link
                        tag="button"
                        class="btn btn-info width-100"
                        :to="{ path: 'capturephotos', params: {id: item._taxi_operator_vehicles_id}}"
                      >Photos</router-link>
                      <button
                        type="button"
                        class="btn btn-danger width-100"
                        v-if="ISINTERNALSYSTEMUSER && !item._deleted_by"
                        @click="Delete(context,item._taxi_operator_vehicles_id,USERID)"
                        :disabled="$wait.is('delete-' + item._taxi_operator_vehicles_id)"
                      >
                        <v-wait v-bind:for="'delete-' + item._taxi_operator_vehicles_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Delete
                        </v-wait>
                      </button>
                      <button
                        type="button"
                        class="bbtn btn-success width-100"
                        v-if="ISINTERNALSYSTEMUSER && item._deleted_by"
                        @click="Restore(context,item._taxi_operator_vehicles_id,USERID)"
                        :disabled="$wait.is('restore-' + item._taxi_operator_vehicles_id)"
                      >
                        <v-wait v-bind:for="'restore-' + item._taxi_operator_vehicles_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Restore
                        </v-wait>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="result-price">
                  {{item._available_balance | newcurrencywithSymbol}}
                  <small>{{item._accountstatus ? item._accountstatus.toUpperCase() : ''}}</small>
                  <router-link
                    tag="button"
                    type="button"
                    class="btn btn-yellow btn-block"
                    :to="{ name: 'taxivehicleprofile', params: { id: item._taxi_operator_vehicles_id } }"
                  >View Profile</router-link>
                </div>
              </li>
            </ul>
          </div>
          <!-- end result-container -->
        </div>
        <!-- end col-12 -->
      </div>
      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("OperatorVehicle");
export default {
  name: "searchVehicle",
  data: function() {
    return {
      searchText: "",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      searchEntityfocused: "Taxi",
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      id: this.$route.query.id,
      SEARCHRESULTS: [],
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters({
    })
  },
  beforeMount() {
    this.clearAll();
  },
  methods: {
    ...mapActions(["getVehicleBySearch", "markAsDeleted","clearAll","restoreDeleted","getLOGOURL"]),
    Delete: (context, id, deletedby) => {
      context.$Notify.YesNo(false, { context, id, deletedby }).then(value => {
        if (value) {
          value.context.$wait.start("delete-" + value.id);
          
          value.context
            .markAsDeleted({
              router: value.context.$router,
              id: value.id,
              deletedby: value.deletedby
            })
            .then(() => {
              value.context.SEARCHRESULTS = value.context.SEARCHRESULTS.filter(item => item._taxi_operator_vehicles_id !== value.id);
              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Success("Successfully Deleted!", "");
            })
            .catch(error => {
              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    Restore: (context, id, restoredby) => {
      context.$Notify.YesNo(false, { context, id, restoredby }).then(value => {
        if (value) {
          value.context.$wait.start("restore-" + value.id);
          
          value.context
            .restoreDeleted({
              router: value.context.$router,
              id: value.id,
              restoredby: value.restoredby
            })
            .then(() => {
              value.context.$wait.end("restore-" + value.id);
              value.context.$Notify.Success("Successfully Deleted!", "");
            })
            .catch(error => {
              value.context.$wait.end("restore-" + value.id);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    onSeachclick: context => {
      
      if (context.searchText.length === 0) return;
      context.$wait.start("search");

      context
        .getVehicleBySearch({
            router: context.$router,
            searchText: context.searchText,
            taxiassociationsId: context.taxi_association_id,
        })
        .then(rb => {
          
          
          console.log('search results: ',rb);

          

          context.SEARCHRESULTS = rb;
          if(rb.length == 0){
            context.$Notify.Error("Vehicle not found!");
            //context.searchText = '';
          }

          context.$wait.end("search");
        })
        .catch(error => {
          console.log('search errors: ',error);
          // value.context.$Notify.Error(error.data[0].message, '');
        })
        .finally(() => {
          context.$wait.end("search");
        });
    }
  },
  mounted: function() {
    //this.get(this.context);
    App.init();

    
    //$('.selectpicker1').selectpicker('render');
  }
};
</script>

<style scoped>
</style>
