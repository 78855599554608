<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Operators'"
      :ActiveMenu="'Search'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Full Search"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Full System"
        breadcrumbitem3="Search"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-md-12">
          <!-- begin result-container -->
          <div class="result-container">
            <!-- begin input-group -->
            <div class="input-group input-group-lg m-b-20">
              <input
                type="text"
                v-on:keydown.enter.prevent="onSeachclick(context)"
                v-model="searchText"
                class="form-control input-white"
                placeholder="Enter keywords here..."
              />
              <div class="input-group-append">
                <button
                  type="button"
                  @click="onSeachclick(context)"
                  class="btn btn-primary"
                  :disabled="$wait.is('search')"
                >
                  <i class="fa fa-search fa-fw" v-show="!$wait.is('search')"></i>
                  <i
                    class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
                    v-show="$wait.is('search')"
                  ></i>
                  Search
                </button>
              </div>
            </div>
            <!-- end input-group -->
          </div>
          <!-- end result-container -->

          <!-- begin nav-pills -->
          <ul class="nav nav-pills">
             <li class="nav-items">
              <a href="#nav-pills-tab-3" data-toggle="tab" class="nav-link active">
                <span class="d-sm-none">TAXIS</span>
                <span class="d-sm-block d-none">TAXIS <span v-if="TAXIRESULTS != null" class="badge badge-success">{{TAXIRESULTS.length}}</span></span>
              </a>
            </li>
            <li class="nav-items">
              <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link">
                <span class="d-sm-none">OPERATORS</span>
                <span class="d-sm-block d-none">OPERATORS <span v-if="OPERATORRESULTS != null" class="badge badge-success">{{OPERATORRESULTS.length}}</span></span>
              </a>
            </li>
            <li class="nav-items">
              <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
                <span class="d-sm-none">DRIVERS</span>
                <span class="d-sm-block d-none">DRIVERS <span v-if="DRIVERRESULTS != null" class="badge badge-success">{{DRIVERRESULTS.length}}</span></span>
              </a>
            </li>
           
          </ul>
          <!-- end nav-pills -->
          <!-- begin tab-content -->
          <div class="tab-content">
             <!-- begin tab-pane -->
            <div class="tab-pane fade active show" id="nav-pills-tab-3">
              <table id="data-table-taxis" class="table table-striped table-bordered width-full">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Association</th>
                    <th class="text-nowrap">Model/Make</th>
                    <th class="text-nowrap">License Plate</th>
                    <th class="text-nowrap">Operator</th>
                    <th class="text-nowrap">Driver</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Last Updated By</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in TAXIRESULTS"
                    v-bind:key="item._taxi_operator_vehicles_id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item._taxi_ass_abbr}}</td>
                    <td>{{item._vehicle_make+' '+item._vehicle_model}}</td>
                    <td>{{item._regnumber}}</td>
                    <td>{{item._taxi_operators_name}}</td>
                    <td>{{item._taxidrivers_name}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._deleted_by }"
                        v-if="item._deleted_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item._is_active, 'label-secondary':!item._is_active  }"
                        v-if="!item._deleted_by"
                      >{{item._is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td>{{item._updateby_name || item._addedby_name}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <router-link
                        :to="{ name: 'taxivehicleprofile', params: { id: item._taxi_operator_vehicles_id }}"
                        class="btn btn-sm btn-white width-60 m-r-2"
                      >View</router-link>
                      <router-link
                        :to="{ name:  'updatevehicle',  params: { id: item._taxi_operator_vehicles_id }}"
                        class="btn btn-sm btn-primary width-60 m-r-2"
                        v-if="item._taxi_operator_vehicles_id"
                      >Edit</router-link>
                      <router-link
                        :to="{ name:  'changeofownership',  params: { id: item._taxi_operator_vehicles_id }}"
                        class="btn btn-sm btn-warning width-120 m-r-2"
                        v-if="item._taxi_operator_vehicles_id"
                      >Change Ownership</router-link>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-60 m-r-2"
                        v-if="ISINTERNALSYSTEMUSER && !item._deleted_by"
                        @click="Delete(context,item._taxi_operator_vehicles_id,USERID)"
                        :disabled="$wait.is('delete-' + item._taxi_operator_vehicles_id)"
                      >
                        <v-wait v-bind:for="'delete-' + item._taxi_operator_vehicles_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Delete
                        </v-wait>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-60 m-r-2"
                        v-if="ISINTERNALSYSTEMUSER && item._deleted_by"
                        @click="Restore(context,item._taxi_operator_vehicles_id,USERID)"
                        :disabled="$wait.is('restore-' + item._taxi_operator_vehicles_id)"
                      >
                        <v-wait v-bind:for="'restore-' + item._taxi_operator_vehicles_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Restore
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end tab-pane -->
            <!-- begin tab-pane -->
            <div class="tab-pane fade" id="nav-pills-tab-1">
              <table id="data-table-operators" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap" width="15%">Name</th>
                    <th class="text-nowrap">ID Number</th>
                    <th class="text-nowrap">Membership Number</th>
                    <th class="text-nowrap">Mobile Number</th>
                    <th class="text-nowrap">Balance</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap hidden-print">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in OPERATORRESULTS"
                    v-bind:key="item._taxi_operator_id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td width="17%">{{item._title_name + " " + item._firstname + " " + item._surname}}</td>
                    <td>{{item._id_number}}</td>
                    <td>{{item._membership_number}}</td>
                    <td>{{item._telephone_number}}</td>
                    <td>{{item._account_balance}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._rejected_by }"
                        v-if="ISINTERNALSYSTEMUSER && item._rejected_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item._taxi_operators_status, 'label-secondary':!item._taxi_operators_status  }"
                        v-if="!item._rejected_by"
                      >{{item._taxi_operators_status != false ? 'Active' : 'Not-Active'}}</span>
                    </td>
                    <td class="with-btn valign-middle" nowrap>
                      <!-- <router-link
                        :to="{ name: 'capturephotos', params: { id: item.taxi_operator_id }}"
                        :disabled="!item._images"
                        class="btn btn-sm btn-primary width-60 m-r-2"
                        data-role="RTO"
                      >Photos</router-link>-->
                      <router-link
                        :to="{ name: 'taxioperatorprofile', params: { id: item._taxi_operator_id }}"
                        class="btn btn-sm btn-warning width-60 m-r-2"
                      >View</router-link>
                      <router-link
                        :to="{ name: 'editoperator', params: { id: item._taxi_operator_id } }"
                        class="btn btn-sm btn-yellow width-60 m-r-2"
                        data-role="UTO"
                      >Edit</router-link>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-60 m-r-2"
                        v-if="!item.deleted_by"
                        data-role="DTO"
                        @click="Delete(context,item.taxi_operator_id,USERID)"
                        :disabled="$wait.is('delete-' + item._taxi_operator_id)"
                      >
                        <v-wait v-bind:for="'delete-' + item._taxi_operator_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Delete
                        </v-wait>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-60 m-r-2"
                        v-if="item.deleted_by"
                        @click="Restore(context,item._taxi_operator_id,USERID)"
                        :disabled="$wait.is('restore-' + item._taxi_operator_id)"
                        data-role="UTO"
                      >
                        <v-wait v-bind:for="'restore-' + item._taxi_operator_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Restore
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end tab-pane -->
            <!-- begin tab-pane -->
            <div class="tab-pane fade" id="nav-pills-tab-2">
              <table id="data-table-drivers" class="table table-striped table-bordered width-full">
                <thead>
                  <tr>
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">ID Number</th>
                    <th class="text-nowrap">Membership Number</th>
                    <th class="text-nowrap">Mobile Number</th>
                    <th class="text-nowrap">Assigned Vehicle</th>
                    <th class="text-nowrap">Balance</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap hidden-print">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(item) in DRIVERRESULTS" v-bind:key="item._id">
                    <td>{{item._title_name + " " + item._firstname + " " + item._surname}}</td>
                    <td>{{item._id_number}}</td>
                    <td>{{item._existing_membership_number}}</td>
                    <td>{{item._mobilenumber}}</td>
                    <td>{{item._vehicle_license_plate}}</td>
                    <td>{{item._available_balance}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._deleted_by }"
                        v-if="ISINTERNALSYSTEMUSER && item._deleted_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item._is_active, 'label-secondary':!item._is_active  }"
                        v-if="!item._deleted_by"
                      >{{item._driver_status_name}}</span>
                    </td>
                    <td class="with-btn valign-middle" nowrap>
                      <!-- <router-link
                        :to="{ name: 'capturephotos', params: { id: item._taxi_drivers_id }}"
                        :disabled="!item._images"
                        class="btn btn-sm btn-primary width-60 m-r-2"
                      >Photos</router-link>-->
                      <router-link
                        tag="button"
                        class="btn btn-sm btn-primary width-120 m-r-2"
                        data-role="UTD"
                        :to="{ name: 'assigndriver', params: {id: item._taxi_drivers_id } }"
                      >Assign Vehicle</router-link>
                      <router-link
                        :to="{ name: 'taxidriverprofile', params: { id: item._taxi_drivers_id }}"
                        class="btn btn-sm btn-warning width-60 m-r-2"
                        data-role="RTD"
                      >View</router-link>
                      <router-link
                        :to="{ name: 'editdriver', params: { id: item._taxi_drivers_id } }"
                        class="btn btn-sm btn-yellow width-60 m-r-2"
                        :disabled="!item._deleted_by"
                        data-role="UTD"
                      >Edit</router-link>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-60 m-r-2"
                        v-if="ISINTERNALSYSTEMUSER && !item._deleted_by"
                        data-role="DTD"
                        @click="Delete(context,item._taxi_drivers_id,USERID)"
                        :disabled="$wait.is('delete-' + item._taxi_drivers_id)"
                      >
                        <v-wait v-bind:for="'delete-' + item._taxi_drivers_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Delete
                        </v-wait>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-60 m-r-2"
                        v-if="ISINTERNALSYSTEMUSER && item._deleted_by"
                        data-role="UTD"
                        @click="Restore(context,item._taxi_drivers_id,USERID)"
                        :disabled="$wait.is('restore-' + item._taxi_drivers_id)"
                      >
                        <v-wait v-bind:for="'restore-' + item._taxi_drivers_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Restore
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end tab-pane -->
           
          </div>
          <!-- end tab-content -->
        </div>
        <!-- end col-12 -->
      </div>
      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("systemusers");
export default {
  name: "FullSystemSearch",
  data: function () {
    return {
      SEARCHRESULTS: [],
      OPERATORRESULTS: [],
      DRIVERRESULTS: [],
      TAXIRESULTS: [],
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,

      searchText: this.$router.app._route.query.text,
      dataloaded: false
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters({}),
  },
  beforeMount() {},
  methods: {
    ...mapActions([
      "searchOperator",
      "searchFullSystem",
      "markAsDeleted",
      "clearAll",
      "restoreDeleted",
      "getLOGOURL",
    ]),

    onSeachclick: (context) => {
      if (context.searchText.length === 0) return;

      context.$wait.start("search");
      

      context
        .searchFullSystem({
          id: context.searchText,
          router: context.$router,
        })
        .then((rb) => {
          console.log("System search results", rb);

          context.OPERATORRESULTS = rb.data[0]._operators;
          context.DRIVERRESULTS = rb.data[0]._drivers;
          context.TAXIRESULTS = rb.data[0]._taxis;

          context.$wait.end("search");
        })
        .catch((error) => {
          console.log("System search errors: ", error);
          context.$Notify.Error(error.data.message, "");
        })
        .finally(() => {

          //if(!context.dataloaded) {

          //   $("#data-table-operators").DataTable({
          //   responsive: true,
          // });

          // $("#data-table-taxis").DataTable({
          //   responsive: true,
          // });

          // $("#data-table-drivers").DataTable({
          //   responsive: true,
          // });

          context.dataloaded = true;
          
          //}

          

          context.$wait.end("search");
        });
    },
  },
  mounted: function () {
    //this.get(this.context);

    if(this.$router.app._route.query.text.length > 0){
      this.onSeachclick(this.context);
    }

    App.init();

    console.log(this.$router.app._route.query.text);

    //$('.selectpicker1').selectpicker('render');
  },
  beforeDestroy: function () {},
};
</script>

<style scoped>
</style>
