<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TaxiVIP'"
      :ActiveMenu="'ViewVIPCommuters'"
    />

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="VIP Commuters"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="TaxiVIP"
        breadcrumbitem3="Manage Commuters"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!--Begin col-lg-12-->
        <div class="col-lg-12">
          <!-- begin panel -->
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">Commuters</h4>
            </div>
            <!-- end panel-heading -->

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-titles" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">Surname</th>
                    <th class="text-nowrap">Mobile No</th>
                    <th class="text-nowrap">No Of Cards</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Last Updated By</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETALLCOMMUTERS"
                    v-bind:key="item.commuters_id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.firstname}}</td>
                    <td>{{item.surname}}</td>
                    <td>{{item.mobile_number}}</td>
                    <td>{{item.numberofcards}}</td>
                    <td>
                        <span
                          class="badge f-s-12"
                          v-bind:class="{ 'badge-success': item.is_active, 'label-secondary':!item.is_active  }"
                        >{{item.is_active == true ? 'Active' : 'Not Active' }}</span>
                      </td>
                    <td>{{item.updated_by_name || item.added_by_name}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <router-link
                        :to="{ name: 'commuterprofile', params: { id: item.commuters_id }}"
                        class="btn btn-sm btn-warning width-60 m-r-2"
                      >View</router-link>
                      <router-link
                        :to="{ name:  'editcommuters',  params: { id: item.commuters_id }}"
                        class="btn btn-sm btn-primary width-60 m-r-2"
                        v-if="item.commuters_id"
                      >Edit</router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
          <!--End col-lg-12-->
        </div>

        <!-- end row -->
      </div>
      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("taxivip");

export default {
  name: "ManageCommuters",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      GETALLCOMMUTERS: []
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["GETALLCOMMUTERS"]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "delete",
      "edit",
      "add",
      "markAsdelete",
      "restoreDeleted"
    ]),
    getall: context => {
      context.$store
        .dispatch("taxivip/getall", {
          _dataObj: {
            router: context.$router
          }
        })
        .then((data) => {
          context.GETALLCOMMUTERS = data;
          App.init();
        });
    },
    edited: (context, item, updatedby) => {
      //item = Object.assign({}, item);
      context.$Notify.YesNo(false, { context, item, updatedby }).then(value => {
        if (value) {
          
          value.context.$wait.start("edited");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("suburb/update", {
              _dataObj: value
            })
            .then(() => {
              $("#EditModal").modal("hide");
              value.context.$wait.end("edited");
              value.context.$Notify.Success("Successfully Updated!", "");
            })
            .catch(error => {
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {
              value.context.$wait.end("edited");
            });
        }
      });
    },
    deletetitle: (context, item, deletedby) => {
      context.$Notify
        .YesNo(false, { context, id: item._id, deletedby })
        .then(value => {
          if (value) {
            value.context.$wait.start("delete-" + value.id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("suburb/markAsdeleted", {
                _dataObj: value
              })
              .then(id => {
                value.context.$wait.end("delete-" + id);
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch(error => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    restoredelete: (context, item, restoredby) => {
      context.$Notify
        .YesNo(false, { context, id: item._id, restoredby })
        .then(value => {
          if (value) {
            value.context.$wait.start("restoreDeleted-" + value.id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("suburb/restoreDeleted", {
                _dataObj: value
              })
              .then(id => {
                value.context.$wait.end("restoreDeleted-" + id);
                value.context.$Notify.Success("Successfully Restored!", "");
              })
              .catch(error => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    }
  },
  mounted: function() {
    
    this.getall(this.context);
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

