<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Dashboard'"
      :ActiveMenu="'Search'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="System User"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="System User"
        breadcrumbitem3="Photos Update"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary" data-sortable-id="form-dropzone-1">
            <div class="panel-body text-inverse">
              <CapturePhotosControl
                :SessionID="CapturedPhotosSessionID"
                :ShowStopStartWebCamControl="false"
                :CameraWidth="'40%'"
                :ShowPreviewControl="true"
                :CameraHeight="'40%'"
                :ClearData="true"
              />
            </div>

            <div class="text-center">
              <button
                type="button"
                v-bind:class="{ 'btn btn-primary btn-lg': true}"
                :disabled="$wait.is('deactivate-' +  '')"
                @click="systemUserPhotoUpdate(context)"
              >
                <v-wait v-bind:for="'deactivate-' + '' ">
                  <template slot="waiting">
                    <i class="fas fa-spinner fa-spin"></i>
                  </template>
                  Submit
                </v-wait>
              </button>
            </div>
          </div>
          <!--End dropzone!-->

          <br />
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("systemusers");

export default {
  name: "ManageDrivers",
  data: function () {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      addedsuccess: undefined,
      errormessage: undefined,
      skip: 0,
      len: 10000,
      id: this.$route.params.id,
      CapturedPhotosSessionID: this.$uuid.v4(),
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      GETSELECTED: Object.assign({}, this.$store.getters["title/GETALL"])[0],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      GETDRIVERSBYASSID: this.$store.getters["TaxiDrivers/GETDRIVERSBYASSID"],
      accountid: this.$store.getters["TaxiDrivers/GETDRIVERACCOUNT"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
    };
  },
  beforeMount: function () {
    this.editDriver(this.id);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    CapturePhotosControl,
  },
  computed: {
    ...mapGetters(["GETTAXIDRIVEREDIT"]),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions([
      "getall",
      "delete",
      "edit",
      "add",
      "markAsDeleted",
      "editDriver",
      "updateSystemUserPictures",
    ]),

    systemUserPhotoUpdate: (context) => {
      context.$Notify.YesNo(false, { context }).then((value) => {
        
        if (value) {
          value.context.$wait.start("deactivate-" + "");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("systemusers/updateSystemUserPictures", {
              _addModel: {
                id: context.id,
                photosessionid: context.CapturedPhotosSessionID,
              },
              router: value.router,
            })
            .then((data) => {
              console.log("user has been activated", data);
              context.$wait.end("deactivate-" + "");
              context.$Notify.Success("Updated Successfully!", "");
              context.get(context);
            })
            .catch((error) => {
              console.log("deactivate user errors: ", error);
              context.$wait.end("deactivate-" + "");
              context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        }
      });
    },
  },

  mounted: function () {
    document.title = "Drivers";
    App.init();
  },
};
</script>

<style scoped>
</style>

