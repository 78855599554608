<template>
 <!-- begin scroll to top btn -->
        <a href="javascript:;" class="btn btn-icon btn-circle btn-success btn-scroll-to-top fade"
            data-click="scroll-top"><i class="fa fa-angle-up"></i></a>
        <!-- end scroll to top btn -->
</template>

<script>
export default {
    name:'pagescroller'
}
</script>


<style scoped>

</style>
