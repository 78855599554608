/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import config from './configbase';

// options...
export default {
  // options...
  AUTH_URL: apiname => `${config.TPAYBASE_URL()}/authentication/${apiname}`,
  COMMUTER_URL: apiname => `${config.TPAYBASE_URL()}/Commuter/${apiname}`,
  ADMIN_URL: apiname => `${config.TPAYBASE_URL()}/admin/${apiname}`,
  REPORTS_URL: apiname => `${config.TPAYBASE_URL()}/reports/dashboard/${apiname}`,
  FIN_COMMUTER_URL: apiname => `${config.TPAYBASEFIN_URL()}/Commuter/${apiname}`,
  FIN_AGENT_URL: apiname => `${config.TPAYBASEFIN_URL()}/Agent/${apiname}`,
  FIN_DEBITORDERS_URL: apiname => `${config.TPAYBASEFIN_URL()}/debitorder/${apiname}`,
  FIN_BANKCARDS_URL: apiname => `${config.TPAYBASEFIN_URL()}/BankCards/${apiname}`,
  FIN_TOPUPTRANS_URL: apiname => `${config.TPAYBASEFIN_URL()}/TransactionManager/TopUp/${apiname}`,
  FIN_TRANS_URL: apiname => `${config.TPAYBASEFIN_URL()}/TransactionManager/${apiname}`,
  LOOKUPS_URL: apiname => `${config.TPAYBASEFIN_URL()}/TPay/RedisSync/${apiname}`,
};

