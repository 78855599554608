/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';

export default {
  namespaced: true,
  state: {
    inbox: [],
    sent: [],
    totalunread: 0,
    system_users: [],
    inbox_details: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter

    GETUPLOADEDFILES: (state, getters, rootState, rootGetters) => {
      return rootGetters['fileupload/GETALL']
    },

    GETDRIVERSBYAFFILIATION: (state, getters, rootState, rootGetters) => {
      return rootGetters['TaxiDrivers/GETDRIVERSBYAFFILIATION']
    },

    GETOPERATORSBYAFFILLIATION: (state, getters, rootState, rootGetters) => {
      return rootGetters['TO/GETOPERATORSBYAFFILLIATION']
    },

    GETVEHICLEBYAFFILLIATION: (state, getters, rootState, rootGetters) => {
      return rootGetters['OperatorVehicle/GETVEHICLEBYAFFILLIATION']
    },
    GETSYSTEMUSERS: (state, getters, rootState, rootGetters) => {
      return rootGetters['systemusers/GETALL']
    },
    GETALLINBOX: state => state.inbox,
    GETALLSENT: state => state.sent,
    GETALLTRASH: state => state.inbox,
    GETTOTALUNREAD: state => state.totalunread,
    // GETSYSTEMUSERS: state => state.system_users,
    GETINBOXBYID: state => state.inbox_details,

  },
  mutations: {
    INBOXLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.inbox = _data;
      } else {
        state.inbox = [];
      }
    },
    SENTLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.sent = _data;
      }
    },
    INBOXDETAILSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.inbox_details = _data;
      }
    },
    USERSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.system_users = _data;
      }
    },
    TRASHLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.inbox = _data;
      }
    },
    ADDED: (state, _data) => {
      state.inbox.push(_data);
    },
    UPDATED: (state, _data) => {
      if (_data !== undefined) {
        Object.assign(state.inbox.filter(item => item._id === _data._id)[0], _data);
      }
    },
    DELETE: (state, id) => {
      if (id !== undefined) {
        state.trash = state.trash.filter(item => item._id !== id);
        state.deleted = true;
      }
    },
    RESTOREDELETED: (state, id) => {
      if (id !== undefined) {
        state.trash.filter(item => item._id === id)[0]._deleted_by = undefined;
        state.trash.filter(item => item._id === id)[0]._date_deleted = undefined;
      }
    },

    MARKASDELETE: (state, id, deletedby) => {
      if (id !== undefined) {
        state.inbox.filter(item => item._id === id)[0]._deleted_by = deletedby;
        state.inbox.filter(item => item._id === id)[0]._date_deleted = Date.now;
      }
    },
    MARKASREAD: (state, id) => {
      if (id !== undefined) {
        state.inbox.filter(item => item._id === id)[0]._date_read = Date.now;
        state.totalunread -= state.totalunread;
      }
    },
    UPDATETOTALUNREAD: (state, count) => {
      state.totalunread = count;
    },
  },
  actions: {
    /* Async */

    markAsRead({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      addModel
    }) {

      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.READEMAIL_URL,
        };

        const id = addModel.id;
        this._vm.$TAMS_API.add(requestheader, id).then((parsedResultOP) => {
          console.log('message read: ',parsedResultOP.data);
          if (parsedResultOP.status) {
            //commit('MARKASREAD', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('message read errors: ', error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    /* Auth.js Mutations */
    getall({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {

      return new Promise((resolve, reject) => {

        const {
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.EMAILS_URL,
        };

        this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('INBOXLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {

          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    selected({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _id
    }) {
      const _taxiass = state.taxiassociations.filter(t => t._taxi_associations_id == _id)[0];

      /* Auth.js Mutations */
      commit('auth/UPDATETAXIASSOCIATION', {
        id: _taxiass._taxi_associations_id,
        name: _taxiass._name,
        abbr: _taxiass._abbriviated_name,
        code: _taxiass._code,
      }, {
        root: true
      });
    },
    getAllInbox({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      status,
      skip,
      len,
      router
    }) {

      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.EMAILSINBOX_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, {
          status: status,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            // commit('INBOXLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);

            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          //commit('LOGINFAILED', error.data);
        });
      });
    },

    downloadfile({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      messageid,
      fileid,
      router
    }) {

      return new Promise((resolve, reject) => {



        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.EMAILSDOWNLOAD_URL,
        };

        this._vm.$TAMS_API.getByPaging2(requestheader, {
          id: messageid,
          text: fileid
        }).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            resolve(parsedResultOP.data[0].fn_message_inbox_get_attachmentcontent[0]);

            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          //commit('LOGINFAILED', error.data);
        });
      });
    },

    getSentById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          id,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SENTEMAILS_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('SENTLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },

    getdeletedbyId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          id,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETDELETEDEMAILSBYID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            commit('TRASHLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },

    getinboxById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {

        const {
          id,
          userid,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETEMAILBYID_URL,
        };

        this._vm.$TAMS_API.getByPaging2(requestheader, {
          id,
          text: userid
        }).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('INBOXDETAILSLOADED', parsedResult.data[0]);
            resolve(parsedResult.data[0]);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },



    add({
      commit,
      state,
      dispatch,
      rootGetters
    }, {
      addModel,
      router,
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.EMAILS_URL,
        };

        this._vm.$TAMS_API.add(requestheader, addModel).then((parsedResult) => {
          if (parsedResult.status) {
            commit('ADDED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    searchSystemUsers({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id,
      text
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SEARCHSYSTEMUSERS_URL,
        };
        this._vm.$TAMS_API.getByPaging2(requestheader, {
          id,
          text
        }).then((parsedResult) => {

          if (parsedResult.status) {
            commit('USERSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    replyInboxMessage({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      updateModel,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.EMAILREPLY_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            commit('ADDED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    update({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      updateModel,
      router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.CALENDAREVENTS_URL,
        };

        this._vm.$TAMS_API.update(requestheader, id,
          updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            commit('ADDED', parsedResult.data);
            resolve(updateModel.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    delete({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {

        const {
          deletedby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.DELETEEMAILS_URL,
        };

        this._vm.$TAMS_API.add(requestheader, {
          id,
          deletedby,
        }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('MARKASDELETE', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    markAsdelete({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.CALENDAREVENTS_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id, {
            id,
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('MARKASDELETE', {
              id,
              deletedby
            });
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    restoreDeleted({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          restoredby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.CALENDAREVENTS_URL,
        };

        this._vm.$TAMS_API.restoreDeleted(requestheader, {
          id,
          restoredby,
        }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('RESTOREDELETED', {
              id
            });
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    gettotalunreadmessage({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router,
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.EMAILSINBOXTOTALUNREAD_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            const totalunread = parsedResult.data[0].fn_message_inbox_get_totalunread;
            //commit('UPDATETOTALUNREAD', totalunread);

            if (totalunread > 0) {
              PushManager.showMessage({
                title: "Unread Messages",
                body: `You have ${totalunread} Unread Messages`,
                issticky: false,
                navigate:true,
                routerfunction:() =>{
                  router.push({ name: 'inbox',params: {id } })
                },
              });
            }
            resolve(totalunread);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
  },
};