<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageVehicle'"
      :ActiveMenu="'changeofownership'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Request Taxi Vehicle Change Of Ownership"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Vehicles"
        breadcrumbitem3="Request Taxi Vehicle Change Of Ownership"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-2 col-sm-3 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Vehicle Information
                      <small class="text-ellipsis">Name, Address, ID No and DOB</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-2 col-sm-3 col-6">
                  <a href="#step-2">
                    <span class="number">2</span>
                    <span class="info text-ellipsis">
                      Upload Supporting Documents
                      <small
                        class="text-ellipsis"
                      >ID document, Proof of Address,License ect.....</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-2 col-sm-3 col-6">
                  <a href="#step-3">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Preview</small>
                    </span>
                  </a>
                </li>
                <!-- <li class="col-md-2 col-sm-3 col-6">
                  <a href="#step-4">
                    <span class="number">4</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Complete Registration</small>
                    </span>
                  </a>
                </li>-->
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->
                    <div class="row">
                      <!-- begin col-6 -->
                      <div class="col-lg-6">
                        <!-- <div class="form-group row">
                          <label class="col-md-4 col-form-label text-md-right">
                            Taxi Owner
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="selectpicker selectoperatorpicker form-control"
                              v-show="GETADDVEHICLE._taxi_operator_id" disabled
                            
                            >
                              <option
                                :key="item.taxi_operator_id"
                                v-for="item in GETOPERATORS"
                                :value="item.taxi_operator_id"
                                >{{
                                  item.firstname +
                                    " " +
                                    item.surname +
                                    "(" +
                                    item.id_number +
                                    ")"
                                }}</option
                              >
                            </select>
                          </div>
                        </div>-->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Old Taxi Operator
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              id="displayname"
                              name="displayname"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              class="form-control"
                              disabled
                              v-model="oldoperator_name"
                            />
                          </div>
                        </div>

                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Display Name
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              id="displayname"
                              name="displayname"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              class="form-control"
                              disabled
                              v-model="CHANGEVEHICLEOWNER.displayname"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Vin Number
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              id="vin_number"
                              name="vin_number"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              class="form-control"
                              disabled
                              v-model="CHANGEVEHICLEOWNER.vin_number"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Tara
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              id="tara"
                              name="tara"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              class="form-control"
                              disabled
                              v-model="CHANGEVEHICLEOWNER.tara"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Registration Number
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              id="reg_number"
                              name="reg_number"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              data-parsley-type="alphanum"
                              class="form-control"
                              v-model="CHANGEVEHICLEOWNER.vehicle_register_number"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">License Expiry Date</label>
                          <div class="col-md-6">
                            <div
                              class="input-group date datepicker-disabled-past"
                              id="expirydate"
                              data-date-format="dd M yyyy"
                            >
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Select Date"
                                data-parsley-group="step-1"
                                :value="CHANGEVEHICLEOWNER.licence_expiry_date | formatToShortDate"
                              />
                              <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- begin form-group -->

                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            License Plate Number
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="54544441454"
                              id="reg_number"
                              name="reg_number"
                              data-parsley-group="step-1"
                              class="form-control"
                              v-model="CHANGEVEHICLEOWNER.licence_number"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                      </div>
                      <div class="col-lg-6">
                        <!-- begin form-group -->
                        <div class="form-group row">
                          <label class="col-md-4 col-form-label text-md-right">
                            New Owner
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="selectpicker selectnewoperatorpicker form-control"
                              v-model="CHANGEVEHICLEOWNER.newoperator_id"
                            >
                              <option>Select Taxi operator</option>
                              <option
                                :key="item.taxi_operator_id"
                                v-for="item in GETOPERATORS"
                                :value="item.taxi_operator_id"
                              >
                                {{
                                item.firstname +
                                " " +
                                item.surname +
                                "(" +
                                item.id_number +
                                ")"
                                }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Engine Number
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              id="reg_number"
                              name="reg_number"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              class="form-control"
                              disabled
                              v-model="CHANGEVEHICLEOWNER.engine_number"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Engine Type
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              data-size="10"
                              data-live-search="true"
                              data-parsley-group="step-1"
                              disabled
                              v-model="CHANGEVEHICLEOWNER.vehicle_engine_id"
                            >
                              <option value="0" selected>Enter Engine Type</option>
                              <option
                                v-bind:key="item._id"
                                v-for="item in GETALLENGINETYPES"
                                :value="item._id"
                              >{{ item._name }}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Transmision Type
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              data-size="10"
                              data-live-search="true"
                              data-parsley-group="step-1"
                              disabled
                              v-model="CHANGEVEHICLEOWNER.vehicle_transmission_type_id"
                            >
                              <option value="0" selected>Select Transmision Type</option>
                              <option
                                v-bind:key="item._id"
                                v-for="item in GETALLTRANSMISIONTYPES"
                                :value="item._id"
                              >{{ item._name }}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Color
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              data-size="10"
                              data-live-search="true"
                              data-parsley-group="step-1"
                              disabled
                              v-model="CHANGEVEHICLEOWNER.vehicle_color_id"
                            >
                              <option value="0" selected>Select Color</option>
                              <option
                                v-bind:key="item._id"
                                v-for="item in GETALLCOLORS"
                                :value="item._id"
                              >{{ item._name }}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Make/Model
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              data-size="10"
                              data-live-search="true"
                              data-parsley-group="step-1"
                              disabled
                              v-model="CHANGEVEHICLEOWNER.vehiclemodel_id"
                            >
                              <option value="0" selected>Select Make/Madel</option>
                              <option
                                v-bind:key="item._vehiclemodel_id"
                                v-for="item in GETALLMODEL"
                                :value="item._vehiclemodel_id"
                              >
                                {{
                                item._vehiclemake_name + " " + item._name
                                }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">Vehicle Joined Date</label>
                          <div class="col-md-6">
                            <div
                              class="input-group date"
                              id="vehicle_joined_date"
                              data-date-format="dd M yyyy"
                              disabled
                            >
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Select Date"
                                data-parsley-group="step-1"
                                :value="CHANGEVEHICLEOWNER.taxi_operator_vehicle_joined_date | formatToShortDate"
                              />
                              <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>

                        <!-- end form-group -->
                        <!-- <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Has Taxi Overdraft?
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <div class="switcher switcher-success">
                              <input
                                type="checkbox"
                                name="switcher_checkbox_hasoverdraft"
                                id="switcher_checkbox_hasoverdraft"
                                data-parsley-checkmin="1"
                                required
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                checked
                              />
                              <label for="switcher_checkbox_hasoverdraft"></label>
                            </div>
                          </div>
                        </div>-->
                      </div>
                    </div>
                    <br />
                    <!--Start Table!-->
                  </fieldset>

                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->

                <!-- begin step-2 -->
                <div id="step-2">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->

                    <p class="text-center h4 mb-4">Upload Supporting Documents</p>
                    <div class="panel panel-inverse" data-sortable-id="form-dropzone-1">
                      <div class="panel-heading">
                        <div class="panel-heading-btn"></div>
                        <h4 class="panel-title">Drop Files In Here</h4>
                      </div>
                      <div class="panel-body text-inverse">
                        <DropZoneControl
                          :ClearData="true"
                          v-on:fileadded="fileadded"
                          :SessionID="DropZoneSessionID"
                        ></DropZoneControl>
                      </div>
                    </div>
                    <br />
                    <!--End dropzone!-->

                    <br />
                    <!--Start Table!-->
                    <div class="panel panel-inverse" data-sortable-id="table-basic-7">
                      <!-- begin panel-heading -->
                      <div class="panel-heading ui-sortable-handle">
                        <h4 class="panel-title">Uploaded Files</h4>
                      </div>
                      <!-- end panel-heading -->
                      <!-- begin panel-body -->
                      <div class="panel-body">
                        <!-- begin table-responsive -->
                        <div class="table-responsive">
                          <table class="table table-striped m-b-0">
                            <thead>
                              <tr>
                                <th>Filename</th>
                                <th>Format</th>
                                <th>Size</th>
                                <th width="40%">Document Type</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr :key="item" v-for="(item) in GETUPLOADEDFILES">
                                <td>{{item.upload.filename}}</td>
                                <td>{{item.fileId}}</td>
                                <td>{{item.upload.total}}</td>
                                <td>
                                  <select
                                    class="form-control col-sm-4"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    data-parsley-required="true"
                                    v-model="item.customType"
                                  >
                                    <option value="0" selected>Select Document Type</option>
                                    <option
                                      v-bind:key="item._id"
                                      v-for="item in GETALLDOCUMENTTYPE"
                                      :value="item._id"
                                    >{{item._name}}</option>
                                  </select>
                                </td>
                                <td class="with-btn" nowrap>
                                  <a
                                    href="#"
                                    @click="deletefile(context,item.fileId, USERPROFILE.system_usersid)"
                                    class="btn btn-sm btn-danger width-60"
                                  >Delete</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end table-responsive -->
                      </div>
                      <!-- end panel-body -->
                      <!-- begin hljs-wrapper -->
                    </div>
                    <div class="alert alert-secondary fade show">
                      <div class="text-center">
                        <!-- <button type="submit" class="btn btn-outline-secondary">Save To Drafts</button> -->
                      </div>
                    </div>
                    <!-- end row -->
                  </fieldset>
                </div>

                <!-- begin step-3 -->
                <div id="step-3">
                  <div class="jumbotron m-b-0 text-center">
                    <div class="jumbotron m-b-0 text-center">
                      <h2 class="display-4">Update Request has been sent</h2>
                      <p v-show="false" class="lead mb-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Mauris consequat commodo porttitor.
                        <br />Vivamus
                        eleifend, arcu in tincidunt semper, lorem odio molestie
                        lacus, sed malesuada est lacus ac ligula. Aliquam
                        bibendum felis id purus ullamcorper, quis luctus leo
                        sollicitudin.
                      </p>
                      <br />
                      <br />
                      <p>
                        <router-link
                          to="/Vehicles/List"
                          v-bind:class="{ 'btn btn-success btn-lg': true }"
                          tag="button"
                        >Proceed Manage Taxi Vehicles</router-link>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end step-3 -->
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>

        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";
// import DropZoneControl2 from "@/components/common/DropZoneControl.vue";
import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("OperatorVehicle");

export default {
  name: "updatevehicle",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      GETALLENGINETYPES: this.$store.getters["lookups/GETALLENGINETYPES"],
      GETALLTRANSMISIONTYPES: this.$store.getters[
        "lookups/GETALLTRANSMISIONTYPES"
      ],
      GETALLTAXIROUTES: this.$store.getters["lookups/GETALLTAXIROUTES"],
      GETALLCOLORS: this.$store.getters["lookups/GETALLCOLORS"],
      GETVEHICLESUPPORTINGDOCS: this.$store.getters["fileupload/GETALL"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      GETALLDOCUMENTTYPE: this.$store.getters["lookups/GETALLDOCUMENTTYPE"],
      // GETALLVEHICLES:this.$store.getters["OperatorVehicle/GETADDVEHICLE"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      GETOPERATORS: [],
      dropzone_files: this.$store.getters["fileupload/GETALL"],
      selectVehicleMakeId: 1,
      id: this.$route.params.id,
      oldoperator_name: "",
      displayName: "",
      vin_number: "",
      tara: "",
      vehicle_register_number: "",
      license_expirydate: "",
      engineNumber: "",
      engine_id: 0,
      transmission_id: 0,
      color_id: 0,
      make_model: "",
      vehicle_joineddate: "",
      hasoverdraft: false,
      // _new_operator:0,
      DropZoneSessionID: this.$uuid.v4(),
      CapturedPhotosSessionID: this.$uuid.v4(),
      selectedTitleId: undefined,
      selectedGenderId: undefined,
      selectedUserGroupId: undefined,
      SELECTEDOPERATORS: {},
      CHANGEVEHICLEOWNER: {
        newoperator_id: 0,
        oldoperator_id: 0,
        taxi_operator_vehicles_id: 0,
        taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        vehiclemodel_id: 0,
        displayname: "",
        vin_number: "",
        licence_number: "",
        vehicle_register_number: "",
        vehiclestatus_id: 0,
        tara: "",
        engine_number: "",
        taxi_operator_vehicle_joined_date: "",
        vehicle_color_id: 0,
        vehicle_engine_id: 0,
        vehicle_transmission_type_id: 0,
        licence_expiry_date: "",
        taxi_association_routes_id: 0,
        operatinglicense_issuedate: "",
        operatinglicense_expirydate: "",
        operatinglicense_number: "",
        cross_border: false,
        vehicle_finance_institution_id: 0,
        vehicle_insurance_institution_id: 0,
        vehicle_tracker_institution_id: 0,
        added_by: this.$store.getters["auth/USERPROFILE"].system_usersid
      },
      dropzoneid: "",
      dropzone_files: [
        {
          customType: 0,
          fileId: ""
        }
      ]
    };
  },

  beforeMount: function() {
    // this.loadoperators(this.context);
    this.getbyid(this.context, this.id);
    this.loadvehiclemodels(this.context);
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    // DropZoneControl2,
    CapturePhotosControl
  },

  computed: {
    ...mapGetters([
      "GETADDVEHICLE",
      "GETALLMODEL",
      "GETUPLOADEDFILES",
      "GETTAXIVEHICLEEDIT"
    ]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "getbyid",
      "getAllByTaxiAssID",
      "delete",
      "edit",
      "add",
      "editVehicle",
      "restoreDeleted",
      "deletelocalfile",
      "updateVehicle",
      "ownershipChangeRequest"
    ]),
    fileadded: function(data) {
      console.log("Header:fileadded");
      console.log(data);
      //  this.$emit('init')
      const context = $("#wizard").data("context");
      // context.GETUPLOADEDFILES = context.$store.getters["fileupload/GETALL"];
    },
    deletefile: (context, fileId, userID) => {
      context.$Notify.YesNo(false, { context, fileId, userID }).then(value => {
        if (value) {
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("fileupload/delete", {
              id: value.fileId,
              deletedby: value.userID
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");
              value.context.$Notify.Success("Successfully Deleted!", "");
            })
            .catch(error => {
              value.context.$wait.end("add");

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    DeleteDocument: (context, item) => {
      value.context.$store
        .dispatch("fileupload/delete", {
          id: value.fileId,
          deletedby: value.userID
        })
        .then(() => {
          $("#AddModal").modal("hide");
          value.context.$wait.end("add");
          value.context.$Notify.Success("Successfully Deleted!", "");
        })
        .catch(error => {
          value.context.$wait.end("add");

          value.context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },

    getbyid: (context, id) => {
      context.$store
        .dispatch("OperatorVehicle/getbyid", {
          _dataObj: {
            id
          }
        })
        .then(data => {
          console.log("this is the vehicle: ", data);
          context.oldoperator_name = data[0]._taxi_operator_name;

          context.CHANGEVEHICLEOWNER.oldoperator_id = data[0]._taxi_operator_id;
          context.CHANGEVEHICLEOWNER.taxi_operator_vehicles_id =
            data[0]._taxi_operator_vehicles_id;
          context.CHANGEVEHICLEOWNER.vehiclemodel_id = data[0]._vehiclemodel_id;
          context.CHANGEVEHICLEOWNER.displayname = data[0]._displayname;
          context.CHANGEVEHICLEOWNER.vin_number = data[0]._vin_number;
          context.CHANGEVEHICLEOWNER.licence_number = data[0]._licence_number;
          context.CHANGEVEHICLEOWNER.vehicle_register_number =
            data[0]._vehicle_register_number;
          context.CHANGEVEHICLEOWNER.vehiclestatus_id =
            data[0]._vehiclestatus_id;
          context.CHANGEVEHICLEOWNER.tara = data[0]._tara;
          context.CHANGEVEHICLEOWNER.engine_number = data[0]._engine_number;
          context.CHANGEVEHICLEOWNER.taxi_operator_vehicle_joined_date =
            data[0]._taxi_operator_vehicle_joined_date;
          context.CHANGEVEHICLEOWNER.vehicle_color_id =
            data[0]._vehicle_color_id;
          context.CHANGEVEHICLEOWNER.vehicle_engine_id =
            data[0]._vehicle_engine_id;
          context.CHANGEVEHICLEOWNER.vehicle_transmission_type_id =
            data[0]._vehicle_transmission_type_id;
          context.CHANGEVEHICLEOWNER.licence_expiry_date =
            data[0]._licence_expiry_date;
          context.CHANGEVEHICLEOWNER.taxi_association_routes_id =
            data[0]._fk_taxi_association_routes_id;
          context.CHANGEVEHICLEOWNER.operatinglicense_issuedate =
            data[0]._operatinglicense_issuedate;
          context.CHANGEVEHICLEOWNER.operatinglicense_expirydate =
            data[0]._operatinglicense_expirydate;
          context.CHANGEVEHICLEOWNER.operatinglicense_number =
            data[0]._operatinglicense_number;
          if (data[0]._cross_border != null) {
            context.CHANGEVEHICLEOWNER.cross_border = data[0]._cross_border;
          } else {
            context.CHANGEVEHICLEOWNER.cross_border = false;
          }

          context.CHANGEVEHICLEOWNER.vehicle_finance_institution_id =
            data[0]._vehicle_finance_institution_id;
          context.CHANGEVEHICLEOWNER.vehicle_insurance_institution_id =
            data[0]._vehicle_insurance_institution_id;
          context.CHANGEVEHICLEOWNER.vehicle_tracker_institution_id =
            data[0]._vehicle_tracker_institution_id;
        })
        .catch(err => {
          console.log("vehicle details error: ", err);
        });
    },

    getVehicleJoinedDate: () => {
      const _converteddate = context.$Toolkit.ConvertToServerDate(
        $("#vehicle_joined_date").datepicker("getDate")
      );
    },
    getLicenseExpiryDate: () => {
      const _converteddate = context.$Toolkit.ConvertToServerDate(
        $("#expirydate").datepicker("getDate")
      );
      return;
    },

    loadvehiclemodels: context => {
      context.$store
        .dispatch("vehiclemodel/getall", {
          router: null
        })
        .then(() => {});
    },
    // loadoperators: context => {
    //   $(".selectoperatorpicker").data("context", context);

    //   context.$store
    //     .dispatch("TO/getAllByTaxiAssID", {
    //       router: null,
    //       id: context.taxiassociationid
    //       // id: 1
    //     })
    //     .then(() => {

    //       $(".selectoperatorpicker")
    //         .select2()
    //         .on("select2:select", function(e) {

    //           var context = $(".selectoperatorpicker").data("context");
    //           context.changeoperator(context, e.params.data.id);
    //         });
    //     });
    // },
    loadonewperators: context => {
      context.$store
        .dispatch("TO/getAllByTaxiAssID", {
          router: context.$router,
          id: context.taxiassociationid
          // id: 1
        })
        .then(data => {
          console.log("operators Found vue: ", data);
          context.GETOPERATORS = data;
        });
    },
    changeOwner: (context, item) => {
      context.$Notify
        .YesNo(false, { context, item: context.CHANGEVEHICLEOWNER })
        .then(value => {
          
          // const dropzone_uploads = context.$store.getters["fileupload/GETALL"];
          // const fileuploaded = [];

          // if (dropzone_uploads.length > 0) {
          //   var failedvalidation = false;
          //   dropzone_uploads.forEach(element => {
          //     if (element.customType === 0) {
          //       failedvalidation = true;
          //       return;
          //     }
          //     fileuploaded.push({
          //       customType: Number(element.customType),
          //       fileId: element.fileId
          //     });
          //   });
          //   if (failedvalidation) {
          //     value.context.$Notify.Error("Please Select Document Type.", "");
          //     return;
          //   }
          // }

          // value.item.dropzone_files = fileuploaded;
          // // value.item.photosessionid = context.CapturedPhotosSessionID;
          // value.item.dropzoneid = context.DropZoneSessionID;

          value.item.newoperator_id = parseInt(
            $(".selectnewoperatorpicker").val()
          );

          value.item.taxi_operator_vehicle_joined_date = context.$Toolkit.ConvertToServerDate(
            context.CHANGEVEHICLEOWNER.taxi_operator_vehicle_joined_date
          );

          value.item.operatinglicense_expirydate = context.$Toolkit.ConvertToServerDate(
            context.CHANGEVEHICLEOWNER.operatinglicense_expirydate
          );

          value.item.operatinglicense_issuedate = context.$Toolkit.ConvertToServerDate(
            context.CHANGEVEHICLEOWNER.operatinglicense_issuedate
          );
          

          value.item.licence_expiry_date = context.$Toolkit.ConvertToServerDate(
            context.CHANGEVEHICLEOWNER.licence_expiry_date
          );

          if(value.item.vehicle_finance_institution_id == null ){
              value.item.vehicle_finance_institution_id = 0;
          }
          else{
             value.item.vehicle_finance_institution_id = parseInt(context.CHANGEVEHICLEOWNER.vehicle_finance_institution_id);
          } 

          if(value.item.vehicle_insurance_institution_id == null ){
              value.item.vehicle_insurance_institution_id= 0;
          }
          else{
             value.item.vehicle_insurance_institution_id = parseInt(context.CHANGEVEHICLEOWNER.vehicle_insurance_institution_id);
          }
          if(value.item.vehicle_tracker_institution_id == null ){
              value.item.vehicle_tracker_institution_id = 0;
          }
          else{
             value.item.vehicle_tracker_institution_id = parseInt(context.CHANGEVEHICLEOWNER.vehicle_tracker_institution_id);
          }

          if (value) {
            
            // value.context.$wait.start("add");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("OperatorVehicle/ownershipChangeRequest", {
                AddModel: value.item,
                router: value.router
              })
              .then(data => {
                // value.context.$wait.end("add");
                console.log("change Requested: ", data);
                value.context.$Notify.Success(
                  "Change of Ownership Request Has Been Sent!"
                );
                $(".sw-btn-next")
                  .text("Save")
                  .attr("disabled", "disabled");
                $("#wizard").data("taxivehicleadded", true);
                $("#wizard").smartWizard("next");
              })
              .catch(error => {
                $(".sw-btn-next")
                  .text("Save")
                  .removeAttr("disabled");
                $("#wizard").data("taxivehicleadded", false);

                value.context.$Notify.Error(error.data.message, "");
              })
              .finally(() => {});
          }
        });
    },

    setTitle(val) {
      this.selectedTitleId = val;
    },
    setGender(val) {
      this.selectedGenderId = val;
    },
    setCountry(val) {
      this.selectedCountryId = val;
    },
    setAdd(val) {
      this.$store
        .dispatch("OperatorVehicle/add", {
          _dataObj: val
        })
        .then(() => {});
    },

    onclick: function() {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(handleSuccess)
        .catch(handleError);
    },

    handleSuccess(stream) {
      screenshotButton.disabled = false;
      video.srcObject = stream;
    }
  },

  mounted: function() {
    // this.loadoperators(this.context);
    this.loadonewperators(this.context);
    // this.getVehicleById(this.context,this.id);

    App.init();

    $("#step-2").data("context", this.context);

    $("#wizard").data("taxivehicleadded", false);

    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "",
      transitionSpeed: 0,
      useURLhash: false,
      showStepURLhash: false,
      toolbarSettings: {
        toolbarPosition: "bottom"
      }
    });
    $("#wizard").data("context", this.context);
    $("#wizard").on("leaveStep", function(
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {
      const currentstep = stepNumber + 1;
      const res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

      $(".sw-btn-next").text("Next");

      if (stepDirection == "backward") return true;

      if (currentstep === 1 && stepDirection == "forward") {
        $(".sw-btn-next").text("Save");
      }

      if (currentstep === 2 && stepDirection == "forward") {
        const taxivehicleadded = $("#wizard").data("taxivehicleadded");
        if (!taxivehicleadded) {
          const context = $("#wizard").data("context");
          context.changeOwner(context, context.item);
        }
        return taxivehicleadded;
      }

      return res;
    });
    //   	$('#wizard').on('leaveStep', function(e, anchorObject, stepNumber, stepDirection) {
    // 	var res = $('form[name="form-wizard"]').parsley().validate('step-' + (stepNumber + 1));
    // 	return res;
    // });

    // $('#wizard').keypress(function( event ) {
    // 	if (event.which == 2 ) {
    // 		$('#wizard').smartWizard('next');
    // 	}
    // });

    $(".selectnewoperatorpicker").select2({
      multiple: false,
      placeholder: "Select An Operator",
      allowClear: true
    });
    //$(".datepicker-disabled-past").datepicker("update", new Date());
    //disable
    $("#vehicle_joined_date").datepicker({
      todayHighlight: true,
      autoclose: true,
      startDate: date
    });

    var date = new Date();
    date.setDate(date.getDate() - 31);
    $("#expirydate").datepicker({
      todayHighlight: true,
      autoclose: true,
      startDate: date
    });
    this.GETADDVEHICLE._taxi_operator_vehicle_joined_date = this.$options.filters.formatToShortDate(
      this.GETADDVEHICLE._taxi_operator_vehicle_joined_date
    );
    this.GETADDVEHICLE._licence_expiry_date = this.$options.filters.formatToShortDate(
      this.GETADDVEHICLE._licence_expiry_date
    );

    //$("#expirydate").datepicker("update", new Date());
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
