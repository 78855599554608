/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../../../configurations/configbase';


export default {
  namespaced: true,
  state: {
    dashboard_cards: [],
    dashboardfinancestats: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETDASHBOARDCARDS: state => state.dashboard_cards,
    GETDASHBOARDFINSTATS: state => state.dashboardfinancestats,
  },
  mutations: {
    LOADEDDASHBOARDFINSTATS: (state, _data) => {
      if (_data !== undefined) {
        state.dashboardfinancestats = _data;
      }
    },
    LOADEDDASHBOARDCARDS: (state, _data) => {
        if (_data !== undefined) {
          state.dashboard_cards = _data;
        }
      },
      LOADEDFINANCE: (state, _data) => {
        if (_data !== undefined) {
          state.dashboardfinance = _data;
        }
      },
  },
  actions: {
    /* Async */

    getallfueltransactions({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.FUELMANAGERALLTRANS_URL,
      };

      
  
      this._vm.$TAMS_API.getCustomURL(requestheader,
        payload
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          //commit('LOADEDDASHBOARDCARDS', parsedResultOP.data[0]);
          resolve(parsedResultOP.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    });
    },
    getdashboardcards({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.FUELMANAGERDASHBOARDCARDS_URL,
      };

      
  
      this._vm.$TAMS_API.getCustomURL(requestheader,
        payload
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          //commit('LOADEDDASHBOARDCARDS', parsedResultOP.data[0]);
          resolve(parsedResultOP.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    });
    },
    getoperatorfinstats({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.FUELMANAGERDASHBOARDSTATS_URL,
      };
  
      this._vm.$TAMS_API.getCustomURL(requestheader,
        payload
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          
          //commit('LOADEDDASHBOARDFINSTATS', parsedResultOP.data[0]);
          resolve(parsedResultOP.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    });
    },
    AddFuelType({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, AddModel) {
      return new Promise((resolve, reject) => {
  
        

        const requestheader = {
          token: rootGetters['auth/TOKEN'],
          apiName: config.FUELTYPE_URL,
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    EditFuelType({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, {id,AddModel}) {
      return new Promise((resolve, reject) => {
  
        

        const requestheader = {
          token: rootGetters['auth/TOKEN'],
          apiName: config.FUELTYPE_URL,
        };

        this._vm.$TAMSFIN_API.update(requestheader,
          id,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    getFuelTypeByTaxiAssId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { id,getbyid, router } ) {
      return new Promise((resolve, reject) => {

        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FUELTYPESBYTAXIASSID_URL,
        };

        
       
        this._vm.$TAMSFIN_API.getCustomURL(requestheader,
          {
            taxiassociationsId: id,
            userid: getbyid
          }
        ).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    DeleteFuelTransactions({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { id,deletedby,router } ) {
      return new Promise((resolve, reject) => {

        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FUELMANAGERDELETE_URL,
        };

        debugger

      
        this._vm.$TAMSFIN_API.getCustomURL(requestheader,
          {
            transid: id,
            deletedby
          }
        ).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
}
};
