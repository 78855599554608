<template>

    <!-- begin row -->
    <div class="row" >
      <!-- begin col-3 -->
      <div class="col-lg-3 col-md-6" v-for="item in items" v-bind:key="item.id">
        <div class="widget widget-stats bg-white text-inverse">
          <div class="stats-icon stats-icon-square bg-gradient-blue">
            <i :class="item.iconclass"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">{{item.statstitle}}</div>
            <div class="stats-number">{{item.statsnumber}}</div>
            <div class="stats-progress progress" v-if="item.progressbar">
              <div class="progress-bar" :style="'width:' + item.progressbar + '%;'"></div>
            </div>
            <div class="stats-desc">{{item.statsdesc}}</div>
          </div>
        </div>
      </div>
      <!-- end col-3 -->

	  

    </div>

</template>

<script>
//import listsdata from '../../data.js'

export default {
  name: "statsCards",
  data() {
    //console.log(JSON.parse(listsdata));

    return {
      items: [
        {
		  id: 1,
		  statstitle:'TAXI ASSOCIATION',
		  statsnumber: 7000,
		  progressbar:30,
		  statsdesc:'Taxi Association In The System',
		  iconclass:'ion-ios-book'

		},
		{
		  id: 2,
		  statstitle:'TAXI VEHICLE',
		  statsnumber: 100000,
		  progressbar:20,
		  statsdesc:'Taxi vehicle in a system',
		  iconclass:'fas fa-taxi'

		},
		{
		  id: 3,
		  statstitle:'TAXI OPERATORS',
		  statsnumber: 13010,
		  progressbar:50,
		  statsdesc:'Taxi Operators in the system',
		  iconclass:'fas fa-user'

		},
		{
		  id: 4,
		  statstitle:'TAXI DRIVERS',
		  statsnumber: 1000,
		  progressbar:90,
		  statsdesc:'Tax drivers in the system',
		  iconclass:'ion-ios-book'
		},
		
      ]
    };
  }
};
</script>

<style scoped>
</style>

