/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';


export default {
  namespaced: true,
  state: {
    filesuploaded: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETALL: state => state.filesuploaded,
  },
  mutations: {
    LOADED: (state, _data) => {
      if (_data !== undefined) {
        state.filesuploaded = _data;
      }
    },
    ADDED: (state, _data) => {
      state.filesuploaded.push(_data);
    },
    UPDATED: (state, _data) => {
      if (_data !== undefined) {
        Object.assign(state.titles.filter(item => item._id === _data._id)[0], _data);
      }
    },
    RESTOREDELETED: (state, { id }) => {
      if (id !== undefined) {
        state.titles.filter(item => item._id === id)[0]._deleted_by = undefined;
        state.titles.filter(item => item._id === id)[0]._date_deleted = undefined;
      }
    },
    MARKASDELETE: (state, _fileId) => {
      if (_fileId !== undefined) {
        state.filesuploaded = state.filesuploaded.filter(item => item.fileId != _fileId);
      }
    },
    CLEARALL: (state) => {
      state.filesuploaded = []
    },
  },
  actions: {
    /* Async */

    /* Auth.js Mutations */
    getall({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const { router } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FILEUPLOAD_URL,
        };

        this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
          if (parsedResult.status) {
            commit('LOADED', parsedResult.data);
            resolve();
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },

    added({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { filecontent }) {
      return new Promise((resolve, reject) => {
        //
      commit('ADDED',filecontent);
      console.log('file uploaded: ',filecontent );
      // if(filecontent != undefined)resolve(JSON.parse(filecontent));
      if(filecontent != undefined)resolve();
      
    })
    .then(()=>{})
    .catch((error) =>{
      console.log('dropzone errors: ', error);
      reject(error);
    })
    },

    delete({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { id,deletedby, router }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FILEUPLOAD_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id,
          {
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('MARKASDELETE', id);
            resolve(id);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    clearall({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router }) {
      commit('CLEARALL');
    },
    

    upload({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { filecontent, photoid,sessionId, router, addedBy, mimeType }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FILEUPLOAD_URL,
        };

        const AddModel = {
          fileId: photoid,
          addedBy,
          sessionId,
          fileName: photoid,
          mimeType,
          data: filecontent,
          length: filecontent.length,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {

            commit('ADDED',{ 
              filecontent,
              sessionId,
              mimetype:'image/jpeg',
              length: filecontent.length,
              photoid,
              customTypeId:0,
            });

            resolve(filecontent);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });

      
    });
    },
  },

};
