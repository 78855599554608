<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageVehicle'"
      :ActiveMenu="'Collector Vehicles'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Collector Vehicles"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Vehicles"
        breadcrumbitem3="Collector Vehicles"
      />
      <!-- end page-header -->

      <!-- begin nav-pills -->
      <ul class="nav nav-pills">
        <li class="nav-items">
          <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active">
            <span class="d-sm-none">Pills 1</span>
            <span class="d-sm-block d-none">Add Collector Vehicles</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">Pills 2</span>
            <span class="d-sm-block d-none">View Collector Vehicles</span>
          </a>
        </li>
      </ul>
      <!-- end nav-pills -->
      <!-- begin tab-content -->
      <div class="tab-content">
        <!-- begin tab-pane -->
        <div class="tab-pane fade active show" id="nav-pills-tab-1">
          <!-- begin invoice -->
          <div class="invoice">
            <!-- begin invoice-Logo -->
            <span class="pull-right">
              <img :src="encodedImage" class="width-160 height-40" />
            </span>
            <!-- begin invoice-Logo -->
            <!-- begin invoice-company -->
            <div class="invoice-company text-inverse f-w-600">
              <span class="pull-right hidden-print">
                <a
                  href="javascript:;"
                  :disabled="$wait.is('addVehicles')"
                  @click="addVehicles(context,COLLECTORVEHICLESMODEL)"
                  :class="{'btn btn-lg btn-success m-r-20 m-b-10 p-l-5': !$wait.is('addVehicles'),
            'btn btn-lg btn-grey m-b-10 p-l-5': $wait.is('addVehicles')
            }"
                >
                  <i
                    class="fa fa-registered t-plus-1 text-white fa-fw fa-lg"
                    v-show="!$wait.is('addVehicles')"
                  ></i>

                  <i
                    class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
                    v-show="$wait.is('addVehicles')"
                  ></i>

                  <span>Add Vehicles</span>
                </a>
                <a
                  v-show="printReady"
                  href="#modal-dialog"
                  data-toggle="modal"
                  class="btn m-r-20 btn-lg btn-white m-b-10 p-l-5"
                >
                  <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print Receipt
                </a>
              </span>
              {{USERPROFILE.taxi_associations_name}}
            </div>
            <!-- end invoice-company -->

            <!-- begin invoice-header -->
            <div class="invoice-header">
              <div v-show="paymentCreated" class="alert alert-success fade show">
                <span class="close" data-dismiss="alert">×</span>
                <strong>Success!</strong>
                Payment Successfully.
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div class="invoice-to">
                    <label class="p-t-20 date text-inverse">Select Collector:</label>
                    <select
                      class="form-control"
                      data-live-search="true"
                      data-parsley-required="true"
                      id="selectcapturedby"
                      v-model="COLLECTORVEHICLESMODEL.system_users_id"
                    >
                      <option value="0">Select Captured By</option>
                      <option
                        v-bind:key="item.id"
                        v-for="item in CAPTUREDBY"
                        :value="item.id"
                      >{{item.firstname}} {{item.surname}}</option>
                    </select>

                    <address class="m-t-5 m-b-5">
                      <strong class="text-inverse"></strong>
                      <br />
                      <br />
                      <br />
                      <br />
                    </address>
                  </div>
                </div>
              </div>
            </div>
            <!-- end invoice-header -->

            <!-- begin invoice-content -->
            <div class="invoice-content">
              <!-- begin table-responsive -->
              <div class="panel-body">
                <table id="data-table-default" class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th width="7%"></th>
                      <th class="text-center">CAR REGISTRATION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in VEHICELMODEL" :key="item.id">
                      <td class>
                        <div class="row p-t-10">
                          <div class="col-md-4">
                            <a
                              href="javascript:;"
                              @click.stop.prevent="AddNewVehicle(context, item)"
                              class="btn btn-danger btn-icon btn-circle btn-sm"
                            >
                              <i class="fa fa-plus"></i>
                            </a>
                          </div>
                          <div class="col-md-4">
                            <a
                              href="javascript:;"
                              @click.stop.prevent="VEHICELMODEL.splice(index--, 1)"
                              v-show="index > 0"
                              class="btn btn-warning btn-icon btn-circle btn-sm"
                            >
                              <i class="fa fa-minus"></i>
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="col-md-12 text-center">
                          <select
                            class="text-center form-control col-md-12 selectTaxi"
                            data-size="5"
                            data-live-search="true"
                            v-model="item.vehicle_id"
                            v-select2
                          >
                            <option
                              v-for="_items in OPERATORVEHICLES"
                              :key="_items._taxi_operator_vehicles_id"
                              :value="_items._taxi_operator_vehicles_id"
                            >
                              {{ _items._licence_plate }} 
                              ({{
                              _items._taxi_operators_name
                              }})
                              - Collector Is  
                              {{
                              _items._collector_name ? _items._collector_name : 'NOT ASSIGNED'
                              }}
                            </option>
                          </select>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- end table-responsive -->
            </div>
            <!-- end invoice-content -->

            <!-- begin invoice-footer -->
            <div class="invoice-footer">
              <p class="text-center m-b-5 f-w-600">{{USERPROFILE.taxi_associations_name}}</p>
              <p class="text-center">
                <span class="m-r-10">
                  <i class="fa fa-fw fa-lg fa-globe"></i>
                  {{SELECTEDTAXIASSOCIATION._abbriviated_name}}.TAMS.co.za
                </span>
                <span class="m-r-10">
                  <i class="fa fa-fw fa-lg fa-phone-volume"></i>
                  T:{{SELECTEDTAXIASSOCIATION._telephone}}
                </span>
              </p>
            </div>
            <!-- end invoice-footer -->
          </div>
          <!-- end invoice -->
        </div>
        <!-- end tab-pane -->
        <!-- begin tab-pane -->
        <div class="tab-pane fade" id="nav-pills-tab-2">
          <!-- begin invoice -->
          <div class="invoice">
            <!-- begin invoice-Logo -->
            <span class="pull-right">
              <img :src="encodedImage" class="width-160 height-40" />
            </span>
            <!-- begin invoice-Logo -->
            <!-- begin invoice-company -->
            <div class="invoice-company text-inverse f-w-600">
              
              {{USERPROFILE.taxi_associations_name}}
            </div>
            <!-- end invoice-company -->

            <!-- begin invoice-header -->
            <div class="invoice-header">
              <div v-show="paymentCreated" class="alert alert-success fade show">
                <span class="close" data-dismiss="alert">×</span>
                <strong>Success!</strong>
                Payment Successfully.
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div class="invoice-to">
                    <label class="p-t-20 date text-inverse">Select Collector:</label>
                    <select
                      class="form-control m-10 width-600"
                      data-live-search="true"
                      data-parsley-required="true"
                      id="selectCollector"
                    >
                      <option value="0">Select Captured By</option>
                      <option
                        v-bind:key="item.id"
                        v-for="item in CAPTUREDBY"
                        :value="item.id"
                      >{{item.firstname}} {{item.surname}}</option>
                    </select>

                    <address class="m-t-5 m-b-5">
                      <strong class="text-inverse"></strong>
                      <br />
                      <br />
                      <br />
                      <br />
                    </address>
                  </div>
                </div>
              </div>
            </div>
            <!-- end invoice-header -->

            <!-- begin invoice-content -->
            <div class="invoice-content">
              <!-- begin table-responsive -->
              <div class="panel-body" v-if="COLLECTORVEHICLES.length > 0">
                <table id="data-table-defaultcollectorv" class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th class="text-center">CAR REGISTRATION</th>
                      <th class="text-center">OPERATOR NAME</th>
                      <th class="text-center">LICENSE DISK EXPIRY DATE</th>
                      <th class="text-center">DISPLAY NAME</th>
                      <th class="text-center" ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item) in COLLECTORVEHICLES" :key="item.id">
                      <td>{{item.taxi_vehicle_license_number}}</td>
                      <td>{{item.operator_name}}</td>
                      <td>{{item.vehicle_license_expiry_date | formatToShortDate}}</td>
                      <td>{{item.taxi_vehicle_name}}</td>
                      <td>
                        <button
                            type="button"
                            class="btn btn-sm btn-danger width-60 m-r-2"
                            @click="deleteCollectorVehicle(context, item)"
                          >Delete</button>
                          </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- end table-responsive -->
            </div>
            <!-- end invoice-content -->

            <!-- begin invoice-footer -->
            <div class="invoice-footer">
              <p class="text-center m-b-5 f-w-600">{{USERPROFILE.taxi_associations_name}}</p>
              <p class="text-center">
                <span class="m-r-10">
                  <i class="fa fa-fw fa-lg fa-globe"></i>
                  {{SELECTEDTAXIASSOCIATION._abbriviated_name}}.TAMS.co.za
                </span>
                <span class="m-r-10">
                  <i class="fa fa-fw fa-lg fa-phone-volume"></i>
                  T:{{SELECTEDTAXIASSOCIATION._telephone}}
                </span>
              </p>
            </div>
            <!-- end invoice-footer -->
          </div>
          <!-- end invoice -->
        </div>
        <!-- end tab-pane -->
      </div>
      <!-- end tab-content -->
    </div>
    <!-- end #content -->
    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { setTimeout } from "timers";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("OperatorVehicle");
// value.context.$emit("init");

export default {
  name: "Collector Vehicles",
  data: function () {
    return {
      currencySymbol: "R ",
      position: "prefix",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],

      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      getSmses: this.$store.getters["sms/GETALL"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      numbers: "",
      numberAdded: false,
      IsAllOperatorsSelected: false,
      IsDriversSelected: false,
      added_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
      context: this,
      skip: 0,
      len: 10000,
      encodedImage: undefined,
      SELECTEDTAXIASSOCIATION: this.$store.getters[
        "auth/SELECTEDTAXIASSOCIATION"
      ],
      VEHICELMODEL: [
        {
          vehicle_id: 0,
        },
      ],
      COLLECTORVEHICLESMODEL: {
        taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        system_users_id: 0,
        vehicle_ids: [],
        added_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      CAPTUREDBY: [],
      printReady: false,
      paymentCreated: false,
      OPERATORVEHICLES: [],
      COLLECTORVEHICLES: [],
      ID: 0,
    };
  },
  beforeMount: function () {
    this.getCapturedBy(this.context);
    this.operatorVehiclesById(this.context);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    CapturePhotosControl,
  },

  computed: {
    // ...mapGetters(["GETALL", "GETSENT"]),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
    currency() {
      return { [this.position]: this.currencySymbol };
    },
  },
  watch: {
    IsAllOperatorsSelected: function (val) {
      $(".default-selectOperator").select2({ disabled: val });
    },
    IsDriversSelected: function (val) {
      $(".default-selectDriver").select2({ disabled: val });
    },
  },
  methods: {
    ...mapActions([
      "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "getSMSsByAssociation",
    ]),

    AddNewVehicle: function (context, item) {
      

      context.VEHICELMODEL.push({
        vehicle_id: 0,
      });

      setTimeout(() => {
        $(".selectTaxi", ".table-striped tr:last").select2({
          multiple: false,
          placeholder: "Select A Taxi",
          allowClear: false,
        });

        // $(".selectTaxi").select2({
        //   multiple: false,
        //   placeholder: "Select A Taxi",
        //   allowClear: false,
        // });
      }, 100);
    },

    getCapturedBy: (context) => {
      
      context.$store
        .dispatch("systemusers/getSystemUsersCapturedBy", {
          associationid: context.taxiassociation_id,
          userid: context.USERID,
          router: context.$router,
        })
        .then((data) => {
          console.log("captured By results: ", data);
          context.CAPTUREDBY = data;
        })
        .catch((error) => {
          console.log("captured page: ", error);
          // context.$Notify.Error(error.message, "");
        })
        .finally(() => {});
    },

    getcollectorVehiclesByID: (context, collectorID) => {
      
      context.$store
        .dispatch("OperatorVehicle/collectorVehiclesById", {
          router: context.$router,
          id: collectorID,
        })
        .then((data) => {
          console.log("Collector Vehicles results: ", data);
          context.COLLECTORVEHICLES = data;
          context.$wait.end("getvehicles");
        })
        .catch((error) => {
          console.log("Collector vehicles errors: ", error);
          context.$wait.end("getvehicles");
          context.$Notify.Error(error.data.message, "");
          context.COLLECTORVEHICLES = [];
        })
        .finally(() => {
          var options = {
                dom: "lBfrtip",
                buttons: [
                  { extend: "copy", className: "btn-sm" },
                  { extend: "csv", className: "btn-sm" },
                  { extend: "excel", className: "btn-sm" },
                  { extend: "pdf", className: "btn-sm" },
                  { extend: "print", className: "btn-sm" }
                ],
                responsive: false,
                autoFill: true,
                colReorder: true,
                keys: true,
                rowReorder: true,
                select: true
              };

              if ($(window).width() <= 767) {
                options.rowReorder = false;
                options.colReorder = false;
                options.autoFill = false;
              }

              $("#data-table-defaultcollectorv").DataTable(options);
        });
    },

    deleteCollectorVehicle: (context, item)=>{
      

      var collector_id = '';
      context.$Notify
        .YesNoMore("Delete Vehicle", "Are you sure?", false, {
          context,
          item,
        }).then((value)=>{

          const deleteModel = {
            taxiassociation_id: value.item.taxi_association_id,
            system_users_id: value.item.system_users_id,
            vehicle_id: value.item.taxi_vehicle_id,
            deleted_by: value.context.added_by
          };

          collector_id = value.item.system_users_id;

          value.context.$store
        .dispatch("OperatorVehicle/collectorVehiclesDelete", {
          router: context.$router,
          addModel: deleteModel,
        })
        .then((data) => {
          console.log("Collector Vehicles results: ", data);
          value.context.$Notify.Success('Collector Vehicle Deleted Successfully',"");

          
          value.context.getcollectorVehiclesByID(context, collector_id);
          
        })
        .catch((error) => {
          console.log("Collector vehicles errors: ", error);
          context.$Notify.Error(error.data.message,'');
        })
        .finally(() => {});

        })
    },

    operatorVehiclesById: (context) => {
      // 
      context.$store
        .dispatch("OperatorVehicle/getVehiclesByAssIdDropDowns", {
          router: context.$router,
          taxiassociation_id: context.taxiassociation_id,
          skip: context.skip,
          len: context.len,
        })
        .then((data) => {
          
          console.log("operator Vehicles: ", data);
          context.OPERATORVEHICLES = data;
        })
        .catch((err) => {
          console.log("operator Vehicles errors -> ", err);
        })
        .finally(() => {
          

        });
      //this.$emit('init')
    },

    addVehicles: (context, item) => {

      context.VEHICELMODEL.map((data) => {      
        item.vehicle_ids.push(data.vehicle_id);
            });

            item.system_users_id = parseInt(
                $("#selectcapturedby").val()
              );

            if (item.system_users_id == 0) {
              context.$wait.end("addVehicles");
              context.$Notify.Error("Please select Collector");
              return;
            } else {
              item.system_users_id = parseInt(
                $("#selectcapturedby").val()
              );
            }


      context.$Notify
        .YesNoMore("Add Vehicles", "Are you sure?", false, {
          context,
          item,
        })
        .then((value) => {
          if (value) {
            
            value.context.$wait.start("addVehicles");
            value.router = value.context.$router;

            

            value.context.$store
              .dispatch("OperatorVehicle/collectorVehiclesAdd", {
                router: context.$router,
                addModel: item,
              })
              .then((data) => {
                context.$wait.end("addVehicles");
                context.$Notify.Success("Vehicle(s) Added Successfully!", "");
                context.VEHICELMODEL = [
                  {
                    vehicle_id: 0,
                  },
                ];
              })
              .catch((error) => {
                context.$wait.end("addVehicles");
                context.$Notify.Error(error.data.message, "");
              })
              .finally(() => {});
          }
        });
    },

    getvehicles: (context, id) => {
      

      context.$Notify
        .YesNoMore("Get Vehicles", "Are you sure?", false, {
          context,
          id,
        })
        .then((value) => {
          if (value) {
            
            value.context.$wait.start("getvehicles");
            value.router = value.context.$router;

            value.id = parseInt($("#selectCollector").select2("data")[0].id);

            if (value.id == 0) {
              value.context.$wait.end("getvehicles");
              value.context.$Notify.Error("Please select Collector");
              return;
            } else {
              value.id = parseInt($("#selectCollector").val());
            }

            value.context.getcollectorVehiclesByID(value.context, value.id);
          }
        });
    },
  },
  mounted: function () {
    App.init();
    // const context = this;

    $("#selectcapturedby").data("context", this.context);
    $(".selectTaxi").data("context", this.context);

    this.$store
      .dispatch("TA/getLOGOURL", {
        id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id,
      })
      .then((result) => {
        this.context.encodedImage = result;
      })
      .catch((err) => {});

    $("#selectcapturedby").select2({
      multiple: false,
      placeholder: "Select Collector",
      allowClear: false,
    }).on("change", function(e){

      const context = $("#selectcapturedby").data("context");
       context.COLLECTORVEHICLESMODEL.system_users_id = $("#selectcapturedby").val();
    })

    $("#selectCollector")
      .select2({
        multiple: false,
        placeholder: "Select Collector",
        allowClear: false,
      })
      .on("change", function (e) {
        console.log("change", $("#selectCollector").val());

        const context = $(".selectTaxi").data("context");
        context.getcollectorVehiclesByID(context, $("#selectCollector").val());

        
      });

    $(".selectTaxi").select2({
      multiple: false,
      placeholder: "Select A Taxi",
      allowClear: false,
    }).on("change", function (e){
      const context = $(".selectTaxi").data("context");
      
      //  context.AddNewVehicle(context, $(".selectTaxi").val());
    });

    $("#advance-daterange").daterangepicker(
      {
        format: "MM/DD/YYYY",
        startDate: moment().subtract(29, "days"),
        endDate: moment(),
        minDate: "01/01/2010",
        maxDate: "12/31/2030",
        dateLimit: { days: 60 },
        showDropdowns: true,
        showWeekNumbers: true,
        timePicker: false,
        timePickerIncrement: 1,
        timePicker12Hour: true,
        ranges: {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days"),
          ],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ],
        },
        opens: "right",
        drops: "down",
        buttonClasses: ["btn", "btn-sm"],
        applyClass: "btn-primary",
        cancelClass: "btn-default",
        separator: " to ",
        locale: {
          applyLabel: "Submit",
          cancelLabel: "Cancel",
          fromLabel: "From",
          toLabel: "To",
          customRangeLabel: "Custom",
          daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
          monthNames: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          firstDay: 1,
        },
      },
      function (start, end, label) {
        const context = $("#advance-daterange span").data("context");

        context.GETMODEL.startdate = start.format("D MMMM YYYY");
        context.GETMODEL.enddate = end.format("D MMMM YYYY");
        $("#advance-daterange span").html(
          start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
        );
      }
    );
  },
};
</script>

	

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>