import { render, staticRenderFns } from "./trash.vue?vue&type=template&id=984ccb7c&scoped=true&"
import script from "./trash.vue?vue&type=script&lang=js&"
export * from "./trash.vue?vue&type=script&lang=js&"
import style0 from "./trash.vue?vue&type=style&index=0&id=984ccb7c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "984ccb7c",
  null
  
)

export default component.exports