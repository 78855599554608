<template>
  <div class="home; page-content-full-height page-sidebar-fixed page-header-fixed">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Emails'"
      :ActiveMenu="'Inbox'"
    />
    <!-- end #sidebar -->
    <!-- begin page-header -->
    <headerpagebreadcrumb
      pageheaderTitle="Inbox"
      pageheaderdescription
      breadcrumbitem1="Home"
      breadcrumbitem2="Emails"
      breadcrumbitem3="Sent"
    />
    <!-- end page-header -->

    <!-- begin #content -->
    <div id="content" class="content content-full-width inbox">
      <!-- begin vertical-box -->
      <div class="vertical-box with-grid">
        <!-- begin vertical-box-column -->
        <div class="vertical-box-column width-200 bg-silver hidden-xs">
          <!-- begin vertical-box -->
          <div class="vertical-box">
            <!-- begin wrapper -->
            <div class="wrapper bg-silver text-center">
              <router-link to="/Home/Compose" class="btn btn-inverse p-l-40 p-r-40 btn-sm">Compose</router-link>
            </div>
            <!-- end wrapper -->
            <!-- begin vertical-box-row -->
            <div class="vertical-box-row">
              <!-- begin vertical-box-cell -->
              <div class="vertical-box-cell">
                <!-- begin vertical-box-inner-cell -->
                <div class="vertical-box-inner-cell">
                  <!-- begin scrollbar -->
                  <div data-scrollbar="true" data-height="100%">
                    <!-- begin wrapper -->
                    <div class="wrapper p-0">
                      <div class="nav-title">
                        <b>FOLDERS</b>
                      </div>
                      <ul class="nav nav-inbox">
                        <li class="active">
                          <router-link :to="{ name: 'inbox', params: {id: USERID } }">
                            <i class="fa fa-inbox fa-fw m-r-5"></i> Inbox
                            <span class="badge pull-right">{{GETTOTALUNREAD}}</span>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/sent">
                            <i class="fa fa-envelope fa-fw m-r-5"></i> Sent
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/trash">
                            <i class="fa fa-trash fa-fw m-r-5"></i> Trash
                          </router-link>
                        </li>
                      </ul>
                    </div>
                    <!-- end wrapper -->
                  </div>
                  <!-- end scrollbar -->
                </div>
                <!-- end vertical-box-inner-cell -->
              </div>
              <!-- end vertical-box-cell -->
            </div>
            <!-- end vertical-box-row -->
          </div>
          <!-- end vertical-box -->
        </div>
        <!-- end vertical-box-column -->
        <!-- begin vertical-box-column -->
        <div class="vertical-box-column bg-white">
          <!-- begin vertical-box -->
          <div class="vertical-box">
            <!-- begin wrapper -->
            <div class="wrapper bg-silver-lighter">
            </div>
            <!-- end wrapper -->
            <!-- begin vertical-box-row -->
            <div class="vertical-box-row">
              <!-- begin vertical-box-cell -->
              <div class="vertical-box-cell">
                <!-- begin vertical-box-inner-cell -->
                <div class="vertical-box-inner-cell">
                  <!-- begin scrollbar -->
                  <div data-scrollbar="true" data-height="100%">
                    <!-- begin list-email -->
                    <ul class="list-group list-group-lg no-radius list-email">
                      <li class="list-group-item" v-for="item in GETALLSENT" :key="item._id">
                        <div class="email-checkbox">
                          <label>
                            <i class="far fa-square"></i>
                            <input type="checkbox" data-checked="email-checkbox" />
                          </label>
                        </div>
                        <router-link
                          :to="{ path: '/emaildetails', query: { id: item._id }}"
                          class="email-user bg-gradient-blue"
                          :class="colorPicker(item._received_by_names !== null ? item._received_by_names.slice(0,1) : item._message_content.slice(0,1))"
                        >
                          <span class="text-white">{{item._received_by_names.slice(0,1) !== null ? item._received_by_names.slice(0,1) : item._message_content.slice(0,1)}}</span>
                        </router-link>
                        <div class="email-info">
                          <router-link :to="{ path: '/emaildetails', query: { id: item._id }}">
                            <span class="email-time">{{item._date_sent | formatToHumanize}}</span>
                            <span class="email-sender">{{item._received_by_names}}</span>
                            <span class="email-title">{{item._subject}}</span>
                            <span class="email-desc">{{item._message_content | striphtml}}</span>
                          </router-link>
                        </div>
                      </li>
                    </ul>
                    <!-- end list-email -->
                  </div>
                  <!-- end scrollbar -->
                </div>
                <!-- end vertical-box-inner-cell -->
              </div>
              <!-- end vertical-box-cell -->
            </div>
            <!-- end vertical-box-row -->
            <!-- begin wrapper -->
            <div class="wrapper bg-silver-lighter clearfix">
              <div class="btn-group pull-right">
                <button class="btn btn-white btn-sm">
                  <i class="fa fa-chevron-left f-s-14 t-plus-1"></i>
                </button>
                <button class="btn btn-white btn-sm">
                  <i class="fa fa-chevron-right f-s-14 t-plus-1"></i>
                </button>
              </div>
              <div class="m-t-5 text-inverse f-w-600">{{GETALLSENT.length}} messages</div>
            </div>
            <!-- end wrapper -->
          </div>
          <!-- end vertical-box -->
        </div>
        <!-- end vertical-box-column -->
      </div>
      <!-- end vertical-box -->
    </div>
    <!-- end #content -->
    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import headerComp from "@/components/common/header.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import pageloader from "@/components/common/pageloader.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";

import { createNamespacedHelpers } from "vuex";
import { setTimeout } from "timers";
const { mapGetters, mapActions } = createNamespacedHelpers("messageinbox");
// value.context.$emit("init");

export default {
  name: "Sent",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      skip: 0,
      len: 10000,
      isactive: true,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      GETALLSENT: [],
    };
  },
  beforeMount: function() {
    this.getSentEmails(this.context);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    CapturePhotosControl
  },

  computed: {
    ...mapGetters([ "GETTOTALUNREAD"]),
    // LoggedInUserFullName: function() {
    //   return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    // }
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  watch: {},
  methods: {
    ...mapActions(["getall", "getSentById", "delete", "edit", "add"]),

    getSentEmails: context => {
      
      context
        .getSentById({
          _dataObj: {
            id: context.USERID,
            router: context.router
          }
        })
        .then((data) => {
            console.log('sent emails found: ',data);
            context.GETALLSENT = data;
        }).catch((error) =>{
          console.log('sent emails found: ',error);
        })
    },

    colorPicker: firstletter => {
      let csscolorpicker = "email-user ";

      switch (firstletter.toUpperCase()) {
        case "A":
          csscolorpicker += "bg-gradient-aqua";
          break;
        case "B":
          csscolorpicker += "bg-gradient-purple";
          break;
        case "C":
          csscolorpicker += "bg-gradient-green";
          break;
        case "D":
          csscolorpicker += "bg-gradient-blue";
          break;
        case "E":
          csscolorpicker += "bg-gradient-orange";
          break;
        case "F":
          csscolorpicker += "bg-gradient-yellow";
          break;
        case "G":
          csscolorpicker += "bg-gradient-red";
          break;
        case "H":
          csscolorpicker += "bg-gradient-black";
          break;
        case "I":
          csscolorpicker += "bg-gradient-blue";
          break;
        case "J":
          csscolorpicker += "bg-gradient-purple";
          break;
        case "K":
          csscolorpicker += "bg-gradient-yellow";
          break;
        case "L":
          csscolorpicker += "bg-gradient-red";
          break;
        case "M":
          csscolorpicker += "bg-gradient-green";
          break;
        case "N":
          csscolorpicker += "bg-gradient-aqua";
          break;
        case "O":
          csscolorpicker += "bg-gradient-blue";
          break;
        case "P":
          csscolorpicker += "bg-gradient-orange";
          break;
        case "Q":
          csscolorpicker += "bg-gradient-yellow";
          break;
        case "R":
          csscolorpicker += "bg-gradient-red";
          break;
        case "S":
          csscolorpicker += "bg-gradient-orange";
          break;
        case "T":
          csscolorpicker += "bg-gradient-purple";
          break;
        case "U":
          csscolorpicker += "bg-gradient-green";
          break;
        case "V":
          csscolorpicker += "bg-gradient-orange";
          break;
        case "W":
          csscolorpicker += "bg-gradient-yellow";
          break;
        case "X":
          csscolorpicker += "bg-gradient-red";
          break;
        case "Y":
          csscolorpicker += "bg-gradient-black";
          break;
        case "Z":
          csscolorpicker += "bg-gradient-aqua";
          break;
      }

      return csscolorpicker;
    }
  },
  mounted: function() {
    this.getSentEmails(this.context);

    App.init();
    
    handleEmailCheckboxChecked();
    handleEmailAction();
    handleEmailSelectAll();
  }
};

var handleEmailActionButtonStatus = function() {
  if ($("[data-checked=email-checkbox]:checked").length !== 0) {
    $("[data-email-action]").removeClass("hide");
  } else {
    $("[data-email-action]").addClass("hide");
  }
};

var handleEmailCheckboxChecked = function() {
  $(document).on("change", "[data-checked=email-checkbox]", function() {
    var targetLabel = $(this).closest("label");
    var targetEmailList = $(this).closest("li");
    if ($(this).prop("checked")) {
      $(targetLabel).addClass("active");
      $(targetEmailList).addClass("selected");
    } else {
      $(targetLabel).removeClass("active");
      $(targetEmailList).removeClass("selected");
    }
    handleEmailActionButtonStatus();
  });
};

var handleEmailAction = function() {
  $(document).on("click", "[data-email-action]", function() {
    var targetEmailList = "[data-checked=email-checkbox]:checked";
    if ($(targetEmailList).length !== 0) {
      $(targetEmailList)
        .closest("li")
        .slideToggle(function() {
          $(this).remove();
          handleEmailActionButtonStatus();
          if ($(".list-email > li").length === 0) {
            $(".list-email").html(
              '<li class="p-15 text-center"><div class="p-20"><i class="fa fa-trash fa-5x text-silver"></i></div> This folder is empty</li>'
            );
          }
        });
    }
  });
};

var handleEmailSelectAll = function() {
  "use strict";
  $("[data-click=email-select-all]").click(function(e) {
    e.preventDefault();

    var targetIcon = $(this).find("i");
    if ($(targetIcon).hasClass("fa-check-square")) {
      $(targetIcon)
        .removeClass("fa-check-square text-inverse")
        .addClass("fa-square text-muted");
      $('.list-email .email-checkbox input[type="checkbox"]').prop(
        "checked",
        false
      );
    } else {
      $(targetIcon)
        .addClass("fa-check-square text-inverse")
        .removeClass("fa-square text-muted");
      $('.list-email .email-checkbox input[type="checkbox"]').prop(
        "checked",
        true
      );
    }
    $('.list-email .email-checkbox input[type="checkbox"]').trigger("change");
  });
};
</script>

	

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>