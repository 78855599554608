/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import config from './configbase';

// options...
export default {
  // options...
  LOGIN_URL: () => `${config.BASE_URL()}/authentication/login`,
  URL: apiname => `${config.BASE_URL()}/${apiname}/`,
  URLBYID: apiname => `${config.BASE_URL()}/${apiname}/{id}`,
  URLBYTAXIID: apiname => `${config.BASE_URL()}/${apiname}/TaxiAssId/{id}`,
  URL_PAGING: apiname => `${config.BASE_URL()}/${apiname}/{status}/{skip}/{len}`,
  URL_PAGING2: apiname => `${config.BASE_URL()}/${apiname}/{id}/{text}`,
  URL_RESTOREDELETED: apiname => `${config.BASE_URL()}/${apiname}/{id}/{restoredby}`,
  URL_CUSTOM: apiname => `${config.BASE_URL()}/${apiname}`,
  URL_CUSTOM2: apiname => `${config.BASE_URL()}/${apiname}/{taxiassociationid}/{userid}/{startdate}/{enddate}/{skip}/{len}`,
  URL_CUSTOM2: apiname => `${config.BASE_URL()}/${apiname}/{taxiassociationid}/{userid}/{startdate}/{enddate}/{skip}/{len}`,
  URL_DIALER: (agentno,mobileno) => `${config.DIALER_URL()}&agent_user=${agentno}&function=external_dial&value=${mobileno}&phone_code=27&search=YES&preview=NO&focus=false`,
};


