<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->

    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'BookASeat'"
      :ActiveMenu="'Bookings'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
	 <transition name="bounce">
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        :pageheaderTitle="`Bookings`" 
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="BookASeat"
        breadcrumbitem3="Bookings"
      />
      <!-- end page-header -->

	  		


      <!-- begin #Main Body Template -->

     <!-- begin row -->
        <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-12">

                <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Upcoming Booking

				
                 
                   <select
                    id="affiliateFilter1"
                    class="ml-2 btn btn-success btn-xs"
                    @change="onChange($event)"
                  >
                   <option value="0" selected>Select Rank</option>
                                <option
                                  v-bind:key="item.id"
                                  v-for="item in GETALLRANKS"
                                  :value="item.id"
                                >{{item.rankname}}</option>
                  </select>
                  
                </h4>
              </div>
              <div class="panel-body">

<!-- BEGIN pos-booking -->
			<div class="pos pos-booking" id="pos-booking">
			
				
				<!-- BEGIN pos-booking-body -->
				<div class="pos-booking-body">
					<!-- BEGIN pos-booking-content -->
					<div class="pos-booking-content">

						<div id="advance-daterange" class="btn btn-default btn-block text-left m-b-10 width-400">
				<i class="fa fa-caret-down pull-right m-t-2"></i>
				<span></span>
				<div v-show="$wait.is('loading')" class="spinner-border spinner-border-lg text-info"  role="status">
		<span class="sr-only">Loading...</span>
</div>
			</div>

						<div class="pos-booking-content-container">
							<div class="d-md-flex align-items-center mb-4">
								<div class="pos-booking-title flex-1">
									<div class="fs-24px mb-1"></div>
									<div class="mb-2 mb-md-0 d-flex">
										<div class="d-flex align-items-center me-3">
											<i class="fa fa-circle fa-fw text-gray-700 fs-9px me-1"></i> No Bookings
										</div>
										<div class="d-flex align-items-center me-3">
											<i class="fa fa-circle fa-fw text-warning fs-9px me-1"></i> Waiting List
										</div>
										<div class="d-flex align-items-center me-3">
											<i class="fa fa-circle fa-fw text-success fs-9px me-1"></i> Fully Booked
										</div>
									</div>
								</div>
								<div>
								</div>
							</div>
							<div class="row">
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">Marabastad</div>
														<div class="no">Randburg</div>
														<div class="desc">18 Of 100 Total Bookings</div>
													</div>
													<div class="pe-1 text-success">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
															<path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">- 18 Seats Available</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">- 18 Seats Available</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
													<div class="info">Reserved by Irene Wong (4pax)</div>
													<div class="status upcoming"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">Reserved by Irene Wong (4pax)</div>
													<div class="status upcoming"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">02</div>
														<div class="desc">max 8 pax</div>
													</div>
													<div class="pe-1 text-danger">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-dash-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">Reserved by John (8pax)</div>
													<div class="status in-progress"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">Reserved by Terry (6pax)</div>
													<div class="status upcoming"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">03</div>
														<div class="desc">max 8 pax</div>
													</div>
													<div class="text-gray-800">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-dash-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">Walk in lunch</div>
													<div class="status in-progress"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">Reserved by Lisa (8pax)</div>
													<div class="status upcoming"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">Reserved by Lisa (8pax)</div>
													<div class="status upcoming"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">Reserved by Terry</div>
													<div class="status upcoming"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">04</div>
														<div class="desc">max 4 pax</div>
													</div>
													<div class="text-gray-800">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-dash-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">Walk in lunch</div>
													<div class="status in-progress"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">Reserved by Richard (4pax)</div>
													<div class="status upcoming"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">Reserved by Richard (4pax)</div>
													<div class="status upcoming"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">Reserved by Paul (3pax)</div>
													<div class="status upcoming"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">Reserved by Paul (3pax)</div>
													<div class="status upcoming"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">Reserved by Paul (3pax)</div>
													<div class="status upcoming"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">05</div>
														<div class="desc">max 4 pax</div>
													</div>
													<div class="text-gray-800">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-dash-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">Walk in lunch</div>
													<div class="status in-progress"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">06</div>
														<div class="desc">max 4 pax</div>
													</div>
													<div class="text-success">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
															<path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">07</div>
														<div class="desc">max 6 pax</div>
													</div>
													<div class="text-gray-800">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-dash-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">Walk in lunch</div>
													<div class="status in-progress"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">08</div>
														<div class="desc">max 4 pax</div>
													</div>
													<div class="text-gray-800">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-dash-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">Walk in lunch</div>
													<div class="status in-progress"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">09</div>
														<div class="desc">max 4 pax</div>
													</div>
													<div class="text-success">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
															<path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">10</div>
														<div class="desc">max 4 pax</div>
													</div>
													<div class="text-gray-800">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-dash-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">Walk in lunch</div>
													<div class="status in-progress"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">11</div>
														<div class="desc">max 4 pax</div>
													</div>
													<div class="text-success">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
															<path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">12</div>
														<div class="desc">max 4 pax</div>
													</div>
													<div class="text-success">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
															<path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">13</div>
														<div class="desc">max 4 pax</div>
													</div>
													<div class="text-success">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
															<path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">14</div>
														<div class="desc">max 6 pax</div>
													</div>
													<div class="text-gray-800">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-dash-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">Walk in lunch</div>
													<div class="status in-progress"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">15</div>
														<div class="desc">max 6 pax</div>
													</div>
													<div class="text-gray-800">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-dash-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">Walk in lunch</div>
													<div class="status in-progress"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">16</div>
														<div class="desc">max 4 pax</div>
													</div>
													<div class="text-success">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
															<path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">17</div>
														<div class="desc">max 4 pax</div>
													</div>
													<div class="text-success">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
															<path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">18</div>
														<div class="desc">max 6 pax</div>
													</div>
													<div class="text-gray-800">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-dash-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">Walk in lunch</div>
													<div class="status in-progress"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">19</div>
														<div class="desc">max 6 pax</div>
													</div>
													<div class="text-gray-800">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-dash-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">Walk in lunch</div>
													<div class="status in-progress"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
									<a href="#" data-bs-toggle="modal" data-bs-target="#modalPosBooking" class="table-booking">
										<div class="table-booking-container">
											<div class="table-booking-header">
												<div class="d-flex align-items-center">
													<div class="flex-1">
														<div class="title">TABLE</div>
														<div class="no">20</div>
														<div class="desc">max 6 pax</div>
													</div>
													<div class="text-gray-800">
														<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-dash-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
														</svg>
													</div>
												</div>
											</div>
											<div class="table-booking-body">
												<div class="booking">
													<div class="time">08:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">09:00am</div>
													<div class="info">Walk in breakfast</div>
													<div class="status completed"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">10:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">11:00am</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking highlight">
													<div class="time">12:00pm</div>
													<div class="info">Walk in lunch</div>
													<div class="status in-progress"><i class="fa fa-circle"></i></div>
												</div>
												<div class="booking">
													<div class="time">01:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">02:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">03:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">04:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">05:00pm</div>
														<div class="info-title">-</div>
														<div class="info-desc"></div>
												</div>
												<div class="booking">
													<div class="time">06:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">07:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">08:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">09:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
												<div class="booking">
													<div class="time">10:00pm</div>
													<div class="info">-</div>
													<div class="status"></div>
												</div>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
					<!-- END pos-counter-content -->
				</div>
				<!-- END pos-booking-body -->
			</div>
			<!-- END pos-booking -->

              </div>
            </div>
            <!-- end panel -->
          </div>
         
        </div>
        <!-- end row -->

      <div class="btn-row">
        <div class="text-center">
          

           <button
            type="button"
            class="btn btn-sm btn-yellow width-200 m-5"
             v-show="PROFILE.is_active"
            @click="placeunderBreakDown(context, VEHICLEPROFILE.operator_vehicle_id)"
          >
                        <v-wait :for="'underBreakDown-' + VEHICLEPROFILE.operator_vehicle_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Revoke
                        </v-wait>
          
          </button>
          
        </div>

          <button
            type="button"
            class="btn btn-sm btn-success width-200 m-5"
            @click="OpenapproveModal(context, VEHICLEPROFILE)"
            v-show="approvalid"
          >Approve</button>

          <button
            type="button"
            class="btn btn-sm btn-danger width-200 m-5"
            v-show="approvalid"
            @click="OpenrejectModal(context, VEHICLEPROFILE)"
          >Reject</button>
         
      </div>
      <br />

      <!-- begin panel -->
      <div class="panel panel-inverse panel-with-tabs" data-sortable-id="ui-unlimited-tabs-1">
        <!-- begin panel-heading -->
        <div class="panel-heading p-0">
          <div class="panel-heading-btn m-r-10 m-t-10">
            <a
              href="javascript:;"
              class="btn btn-xs btn-icon btn-circle btn-success"
              data-click="panel-expand"
            >
              <i class="fa fa-expand"></i>
            </a>
          </div>
          <!-- begin nav-tabs -->
          <div class="tab-overflow">
            <ul class="nav nav-tabs nav-tabs-inverse">
              <li class="nav-item prev-button">
                <a href="javascript:;" data-click="prev-tab" class="nav-link text-success">
                  <i class="fa fa-arrow-left"></i>
                </a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab-1" data-toggle="tab" class="nav-link">Transactions</a>
              </li>
            </ul>
          </div>
          <!-- end nav-tabs -->
        </div>
        <!-- end panel-heading -->
        <!-- begin tab-content -->
        <div class="tab-content">
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab-1">
            <div class="panel-body">
              <table
                id="data-table-transactions"
                class="table table-striped table-bordered width-full"
              >
                <thead>
                  <tr>
                    <th class="text-nowrap">DATE</th>
                    <th class="text-nowrap">REFERENCE</th>
                    <th class="text-nowrap">AMOUNT</th>
                     <th class="text-nowrap">OUTSTANDING AMOUNT</th>
                    <th class="text-nowrap">ACTION</th>
                  </tr>
                </thead>
                <tbody v-if="VEHICLETRANSACTIONS.length > 0">
                  <tr v-for="(item) in VEHICLETRANSACTIONS" :key="item._id" :value="item">
                    <td>{{item.date_added }}</td>
                    <td>{{item.reference }}</td>
                    <td
                      class="text-left text-green-darker"
                    >{{ item.amount | currency}}</td>
                      <td
                      class="text-left text-green-darker"
                    >{{ item.outstanding_amount | currency}}</td>
                    <td>
                      <button
                       v-if="item.is_active"
                        type="button"
                        class="btn btn-sm btn-danger width-80"
                        @click="selectedReceipt(context,item)"
                      >
                        <v-wait :for="'dispute-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Dispute
                        </v-wait>
                      </button>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end tab-pane -->
        </div>
        <!-- end tab-content -->
      </div>
      <!-- end panel -->

      <div class="modal fade" id="approveModal" tabindex="-1" role="dialog">
        <div class="modal-dialog width-full" role="document">
          <div class="modal-content">
            <form>
              <div class="modal-header">
                <h4 class="text-center">Approve</h4>
              </div>
              <div class="modal-body width-full">
                <div class="col-md-12">
                  <!-- begin form-group row -->

                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Comment</label>
                    <div class="col-md-9">
                      <textarea
                        class="textarea form-control"
                        v-model="comment"
                        placeholder="Enter comment..."
                        rows="10"
                      ></textarea>
                    </div>
                  </div>
                  <!-- end form-group row -->
                </div>
              </div>
              <div class="modal-footer">
                <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
                <button
                  type="button"
                  class="btn btn-sm btn-success width-80"
                  @click="approve(context,GETSELECTED,USERID,comment)"
                  :disabled="$wait.is('executeapproval')"
                >
                  <v-wait v-bind:for="'executeapproval'">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Approve
                  </v-wait>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Edit Modal HTML START-->

      <!-- end #content -->
      <div class="modal fade" id="rejectModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <form>
              <div class="modal-header">
                <h4 class="text-center">Reject</h4>
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <!-- begin form-group row -->

                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Comment</label>
                    <div class="col-md-9">
                      <textarea
                        class="textarea form-control"
                        v-model="comment"
                        placeholder="Enter comment..."
                        rows="12"
                      ></textarea>
                    </div>
                  </div>
                  <!-- end form-group row -->
                </div>
              </div>
              <div class="modal-footer">
                <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
                <button
                  type="button"
                  class="btn btn-sm btn-danger width-80"
                  @click="reject(context,GETSELECTED,USERID,comment)"
                  :disabled="$wait.is('executereject')"
                >
                  <v-wait v-bind:for="'executereject'">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Reject
                  </v-wait>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Add Modal HTML START-->
      <div id="AddModal" class="modal fade">
        <div class="modal-dialog">
          <div class="modal-content">
            <form>
              <div class="modal-header">
                <h4 class="modal-title">Pay Traffic fine</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <!-- begin form-group row -->

                  <!-- begin card -->
                  <div class="card text-center">
                    <div class="card-header">
                      <ul class="nav nav-tabs card-header-tabs">
                        <li class="nav-item" v-show="TAMS">
                          <a class="nav-link active" data-toggle="tab" href="#card-tab-1">TAMS</a>
                        </li>
                        <li class="nav-item" v-show="EXTERNAL">
                          <a class="nav-link" data-toggle="tab" href="#card-tab-2">External Payment</a>
                        </li>
                        <li class="nav-item" v-show="REDIRECT">
                          <a class="nav-link" data-toggle="tab" href="#card-tab-3">Redirect Payment</a>
                        </li>
                      </ul>
                    </div>
                    <div class="card-block">
                      <div class="tab-content p-0 m-0">
                        <div class="tab-pane fade active show" id="card-tab-1" v-show="TAMS">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              BANK NAME:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="FIRST NATIONAL BANK"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              ACCOUNT TYPE:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="BUSINESS ACCOUNT"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              ACCOUNT NUMBER:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="62840241848"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              BRANCH CODE:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="255355"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              BRANCH NAME:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="MY BRANCH"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              SWIFT CODE:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="FIRNZAJJ"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              DATE OPENED:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="2020-01-23"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>
                        <div class="tab-pane fade" id="card-tab-2" v-show="EXTERNAL"></div>
                        <div class="tab-pane fade" id="card-tab-3" v-show="REDIRECT"></div>
                      </div>
                    </div>
                  </div>
                  <!-- end card -->
                </div>
              </div>
              <div class="modal-footer">
                <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Add Modal HTML START-->

      <!-- #modal-message start -->
      <div class="modal fade" id="disputesModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="text-center">
              <h4 v-if="isInvoice" class="modal-title">Dispute Invoice</h4>
              <h4 v-if="isReceipt" class="modal-title">Dispute Receipt</h4>
            </div>
            <div class="modal-body">
              <div v-if="isInvoice" class="form-group row">
                <label class="col-md-2 col-form-label">Comment:</label>
                <div class="col-md-10">
                  <textarea
                    placeholder="Type your comments"
                    class="form-control m-b-10"
                    id="clip-textarea"
                    v-model="INVOICEDISPUTEADD.invoiceComments"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div v-if="isReceipt" class="form-group row">
                <label class="col-md-2 col-form-label">Comment:</label>
                <div class="col-md-10">
                  <textarea
                    placeholder="Type your comments"
                    class="form-control m-b-10"
                    id="clipboard-textarea"
                    v-model="RECEIPTDISPUTEADD.receiptComments"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <br />
              <div class="height-450" v-if="isInvoice">
                <!-- begin nav-tabs -->
                <ul class="nav nav-tabs">
                  <li class="nav-items">
                    <a href="#default-tab-1" data-toggle="tab" class="nav-link active">
                      <span class="d-sm-none">Tab 1</span>
                      <span class="d-sm-block d-none">Media</span>
                    </a>
                  </li>
                  <li class="nav-items">
                    <a href="#default-tab-2" data-toggle="tab" class="nav-link">
                      <span class="d-sm-none">Tab 2</span>
                      <span class="d-sm-block d-none">Voicenotes</span>
                    </a>
                  </li>
                </ul>
                <!-- end nav-tabs -->
                <!-- begin tab-content -->
                <div class="tab-content">
                  <!-- begin tab-pane -->
                  <div class="tab-pane fade active show" id="default-tab-1">
                    <h3 class="m-t-10">Upload Documents</h3>
                    <DropZoneControl
                      :SessionID="invoiceDropZoneSessionID"
                      :acceptedFiles="acceptedFiles"
                      :ClearData="true"
                      v-on:fileadded="fileadded"
                    ></DropZoneControl>
                  </div>
                  <!-- end tab-pane -->
                  <!-- begin tab-pane -->
                  <div class="tab-pane fade" id="default-tab-2">
                    <AudioVideoControl
                      :SessionID="invoiceVoiceNoteSessionID"
                      :ClearData="true"
                      v-on:fileadded="fileadded"
                    />
                  </div>
                  <!-- end tab-pane -->
                </div>
                <!-- end tab-content -->
              </div>
              <div class="height-450" v-if="isReceipt">
                <!-- begin nav-pills -->
                <ul class="nav nav-pills">
                  <li class="nav-items">
                    <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active">
                      <span class="d-sm-none">Pills 1</span>
                      <span class="d-sm-block d-none">Media</span>
                    </a>
                  </li>
                  <li class="nav-items">
                    <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
                      <span class="d-sm-none">Pills 2</span>
                      <span class="d-sm-block d-none">Voicenotes</span>
                    </a>
                  </li>
                </ul>
                <!-- end nav-pills -->
                <!-- begin tab-content -->
                <div class="tab-content">
                  <!-- begin tab-pane -->
                  <div class="tab-pane fade active show" id="nav-pills-tab-1">
                    <h3 class="m-t-10">Attach files</h3>
                    <DropZoneControl
                      :SessionID="receiptDropZoneSessionID"
                      :acceptedFiles="acceptedFiles"
                      :ClearData="true"
                      v-on:fileadded="fileadded"
                    ></DropZoneControl>
                  </div>
                  <!-- end tab-pane -->
                  <!-- begin tab-pane -->
                  <div class="tab-pane fade" id="nav-pills-tab-2">
                    <AudioVideoControl
                      :SessionID="invoiceVoiceNoteSessionID"
                      :ClearData="true"
                      v-on:fileadded="fileadded"
                    />
                  </div>
                  <!-- end tab-pane -->
                </div>
                <!-- end tab-content -->
              </div>
            </div>
            <div class="text-center m-b-5">
              <a href="javascript:;" class="btn btn-danger m-5 btn-lg" data-dismiss="modal">Close</a>
              <a
                href="javascript:;"
                class="btn btn-primary m-5 btn-lg"
                data-dismiss="modal"
                v-if="isReceipt"
                @click="disputeReceipt(context,RECEIPTDISPUTEADD)"
              >Dispute</a>
              <a
                href="javascript:;"
                class="btn btn-primary m-5 btn-lg"
                data-dismiss="modal"
                v-if="isInvoice"
                @click="disputeInvoice(context, INVOICEDISPUTEADD)"
              >Dispute</a>
            </div>
          </div>
        </div>
      </div>
      <!-- #modal-message end -->

      <!-- #modal-dialog -->
      <div class="modal fade" id="changeBilling">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="text-center">
              <h4 class="modal-title">Change Vehicle billing</h4>
            </div>
            <div class="modal-body">
              <!-- begin form-group -->
              <div class="form-group row m-b-10">
                <label class="col-md-3 col-form-label text-md-right">Amount</label>
                <div class="col-md-6">
                  <input
                    type="Number"
                    id="amount"
                    class="form-control"
                    v-model="CHANGEBILLING.amount"
                    placeholder="R0.00"
                  />
                </div>
              </div>
              <!-- End form-group -->
              <!-- begin form-group -->
              <div class="form-group row m-b-10">
                <label class="col-md-3 text-nowrap col-form-label text-md-right">Billing End Date:</label>
                <div class="date text-inverse col-md-7 pull-center">
                  <div
                    class="input-group date"
                    id="datepicker-billingEnd"
                    data-date-format="dd M yyyy"
                  >
                    <input type="text" class="form-control" placeholder="Billing End Date" />
                    <span class="input-group-addon">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <!-- end form group -->
              <!-- begin form-group -->
              <div class="form-group row m-b-10">
                <label class="col-md-3 text-nowrap col-form-label text-md-right">Next Billing Date:</label>
                <div class="date text-inverse col-md-7 pull-center">
                  <div
                    class="input-group date"
                    id="datepicker-nextBilling"
                    data-date-format="dd M yyyy"
                  >
                    <input type="text" class="form-control" placeholder="Next Billing  Date" />
                    <span class="input-group-addon">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <!-- end form group -->
            </div>
            <div class="modal-footer">
              <div class="text-center">
                <button href="javascript:;" class="btn btn-white m-5" data-dismiss="modal">Close</button>
                <button
                  @click="changeVehicleBillingType(context,CHANGEBILLING)"
                  class="btn btn-primary m-5"
                >Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- #modal-dialog end -->
    </div>
	</transition>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import AudioVideoControl from "@/components/common/AudioVideoControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { setTimeout } from "timers";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("tpaycommuter");

export default {
  name: "TAXIVEHICLEPROFILE",
  data: function () {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      id: this.$route.params.id,
      comment: "",
      approvalid: this.$route.params.approvalid,
      BOOKINGS:[],
      PROFILE:{},
      VEHICLETIMELINE: [],
      VEHICLESUPPORTINGDOCS: [],
      TRAFFICFINES: [],
      VEHICLEBILLINGS: [],
      VEHICLEINVOICEDISPUTES: [],
      VEHICLERECEIPTSDISPUTES: [],
      VEHICLETAXIROUTES: [],
      GETADDVEHICLE: [],
      VEHICLEPROFILE: {
        accountNumber: "",
        vehicle_status: "",
        available_balance: 0,
        operator_name: "",
        vin_number: "",
        tara: "",
        vehicle_registration_number: "",
        license_expiry_date: "",
        license_number: "",
        operatinglicense_issuedate: "",
        operatinglicense_expirydate: "",
        operatinglicense_number: "",
        cross_border: "",
        engine_number: "",
        engine_type: "",
        transmission_type: "",
        color: "",
        make_model: "",
        vehicle_joined_date: "",
        routes: "",
        vehicle_finance: "",
        vehicle_tracker: "",
        vehicle_insurance: "",
        operator_vehicle_id: 0,
      },
      VEHICLETRANSACTIONS: [],
      INVOICEDISPUTEADD: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        invoiceid: "",
        invoiceComments: "",
        voicenotesessionid: "",
        dropzoneid: "",
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      RECEIPTDISPUTEADD: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        receiptid: "",
        receiptComments: "",
        voicenotesessionid: "",
        dropzoneid: "",
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      invoiceDropZoneSessionID: this.$uuid.v4(),
      invoiceVoiceNoteSessionID: this.$uuid.v4(),
      receiptDropZoneSessionID: this.$uuid.v4(),
      receiptVoiceNoteSessionID: this.$uuid.v4(),
      //id: this.$route.query._taxi_operator_vehicles_id,
      GETSELECTED: this.$store.getters["OperatorVehicle/GETADDVEHICLE"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      GETUPLOADEDFILES: [],
      updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      isReceipt: false,
      isInvoice: false,
      acceptedFiles: "application/pdf",

      diskExpired: false,
      hasInvoiceVoiceNote: false,
      hasInvoiceMedia: true,
      DropZoneSessionID: this.$uuid.v4(),

      CHANGEBILLING: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        vehiclebillingid: 0,
        vehiclebillingtypeid: 0,
        updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
        amount: null,
        billingstartdate: "",
        billingenddate: "",
        nextbillingdate: "",
        lastbilldate: "",
        collectionfrequencynumofdays: "",
        isactive: true,
      },
      // ADDSTATUSCHANGEREQUEST: {
      //   taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
      //     .id,
      //   taxi_vehicle_id: 0,
      //   taxi_operator_id: GETSELECTED._taxi_operator_id,
      //   taxi_vehicle_status_id: this.$route.query.id,
      //   updated_by: this.$store.getters["auth/USERPROFILE"].id
      // },
      skip: 0,
      len: 1000,
      selectedTitleId: undefined,
      selectedGenderId: undefined,
      selectedUserGroupId: undefined,
      VEHICLETRIPHISTORY: [],
      REDIRECT: true,
      EXTERNAL: true,
      TAMS: true,

      // GETADDTAXIDRIVER: this.$store.getters["TaxiDrivers/GETADDTAXIDRIVER"],
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    AudioVideoControl,
  },
  computed: {
    ...mapGetters(["GETVEHICLEROUTES"]),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions([
      'getvoucherprofile',
      'getvoucherTransactions',
      'getBookingsUpcoming',
      'getbookingscards',
      "getall",
      "getbyid",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted",
      "getVehicleTimelineById",
      "getAllTransactionsByAccNo",
      "getVehicleDocsById",
      "getallStatus",
      "addChangeRequest",
      "getVehicleBillingsById",
      "changeVehicleStatusRequest",
      "changeVehicleBilling",
      "executeapproval",
      "executereject",
      "getTaxiRoutesByTaxiId",
      "getVehiclePlaceUnderBreakdown",
      "getVehicleRemoveBreakdown"
    ]),
    setViewDocs: (context, item) => {
      window.open(`https://storage.googleapis.com/tams-fileuploads/${item.filename}`, '_blank');
    },
    reject: (context, item, rejected_by, comment) => {
      
      item._id = context.approvalid;
      context.$Notify
        .YesNo(false, {
          context,
          item,
          rejected_by,
          comment,
          taxiassociation_id: context.taxiassociationid,
          approval_id: item._id,
        })
        .then((value) => {
          if (value) {
            value.context.$wait.start("executereject");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("approvals/executereject", {
                _dataObj: value,
              })
              .then(() => {
                context.comment = "";
                $("#rejectModal").modal("hide");
                value.context.$wait.end("executereject");
                value.context.$Notify.Success("Rejected!", "");
                value.context.approvalid = undefined;
              })
              .catch((error) => {
                value.context.$wait.end("executereject");
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {
                value.context.$wait.end("executereject");
              });
          }
        });
    },
    getvehicleTrans: (context) => {
      
      context
        .getvoucherTransactions({
          id: context.id,
          router: context.$router,
        })
        .then((data) => {
          context.VEHICLETRANSACTIONS = data;
          console.log("these are the vehicle transactions: ", data);
        })
        .catch((err) => {
          
          console.log("vehicle transactions error: ", err);
        })
        .finally(() => {
          // $("#data-table-transactions").DataTable({
          //   responsive: true,
         // });
        });
    },
    getallupcomingbookings: (context) => {

      context
        .getBookingsUpcoming({
          id: context.id,
          router: context.$router,
        })
        .then((data) => {
          context.BOOKINGS = data;
          console.log("these are the vehicle transactions: ", data);
        })
        .catch((err) => {
          
          console.log("vehicle transactions error: ", err);
        })
        .finally(() => {
          // $("#data-table-transactions").DataTable({
          //   responsive: true,
         // });
        });
    },
    getallbookingscards: (context) => {
      
      
      context
        .getbookingscards({
          router: context.$router,
        })
        .then((data) => {
          context.PROFILE = data;
          console.log("these are the vehicle transactions: ", data);
        })
        .catch((err) => {
          
          console.log("vehicle transactions error: ", err);
        })
        .finally(() => {
          // $("#data-table-transactions").DataTable({
          //   responsive: true,
         // });
        });
    },
    approve: (context, item, approved_by, comment) => {
      

      context.$Notify
        .YesNo(false, {
          context,
          item,
          approved_by,
          comment,
          taxiassociation_id: context.taxiassociationid,
          approval_id: context.approvalid,
        })
        .then((value) => {
          if (value) {
            
            value.context.$wait.start("executeapproval");
            value.router = value.context.$router;
            value.item._id = value.context.approvalid;
            value.context.$store
              .dispatch("approvals/executeapproval", {
                _dataObj: value,
              })
              .then(() => {
                
                context.comment = "";
                value.context.$wait.end("executeapproval");
                value.context.$Notify.Success("Approved Successfully!", "");
                value.context.approvalid = undefined;
              })
              .catch((error) => {
                
                value.context.$Notify.Error(error.data.message, "");
              })
              .finally(() => {
                $("#approveModal").modal("hide");
                value.context.$wait.end("executeapproval");
              });
          }
        });
    },
    billingInfo: (context, item) => {
      console.log(item);
      $("#changeBilling").modal("show");
      context.CHANGEBILLING.vehiclebillingid = item._id;
      context.CHANGEBILLING.vehiclebillingtypeid = item._fk_billingtype_id;
      context.CHANGEBILLING.billingstartdate = item._billing_start_date;
      context.CHANGEBILLING.lastbilldate = item._last_bill_date;
      context.CHANGEBILLING.collectionfrequencynumofdays =
        item._collection_frequency_num_of_days;
    },
    OpenapproveModal: (context, item) => {
      context.GETSELECTED = item;
      $("#approveModal").modal();
    },
    OpenrejectModal: (context, item) => {
      context.GETSELECTED = item;
      $("#rejectModal").modal("show");
    },
    selectedReceipt: (context, payload) => {
      console.log("this is the selected receipt: ", payload);
      context.transid = payload._id;
      if (
        payload._receipts_id != null &&
        payload._receipts_id != undefined &&
        payload._receipts_id != ""
      ) {
        let receipt = payload._receipts_id;
        context.RECEIPTDISPUTEADD.receiptid = receipt;
        console.log("selectedreceipt ", context.RECEIPTDISPUTEADD.receiptid);
        context.isReceipt = true;
        context.isInvoice = false;
        $("#disputesModal").modal("show");
      } else if (
        payload._invoice_id != null &&
        payload._invoice_id != undefined &&
        payload._invoice_id != ""
      ) {
        let invoice = payload._invoice_id;
        context.INVOICEDISPUTEADD.invoiceid = invoice;
        console.log("selectedinvoice ", context.INVOICEDISPUTEADD.invoiceid);
        context.isInvoice = true;
        context.isReceipt = false;
        $("#disputesModal").modal("show");
      } else {
        alert("no receipt or invoice found");
      }
    },
    placeunderBreakDown: (context, vehicleid) => {
      

      context.$Notify.YesNo(false, { context, vehicleid }).then((value) => {
        if (value) {
          value.context.$wait.start("underBreakDown-" + value.vehicleid);
          value.router = value.context.$router;
          value.context.getVehiclePlaceUnderBreakdown({
              router: value.router,
              taxiassociationid:context.taxiassociationid,
              taxivehicleid:value.vehicleid,
              updatedby: context.USERID
            }).then(() => {
              value.context.VEHICLEPROFILE.vehicle_status = 'Breakdown'
              value.context.$wait.end("underBreakDown-" + value.vehicleid);
              value.context.$Notify.Success("Taxi Status Is Breakdown!", "");
            })
            .catch((error) => {
              value.context.$wait.end("underBreakDown-" + value.vehicleid);

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });

    },
    removeBreakDown: (context, vehicleid) => {
      

      context.$Notify.YesNo(false, { context, vehicleid }).then((value) => {
        if (value) {
          value.context.$wait.start("removeBreakDown-" + value.vehicleid);
          value.router = value.context.$router;
          value.context.getVehicleRemoveBreakdown({
              router: value.router,
              taxiassociationid:context.taxiassociationid,
              taxivehicleid:value.vehicleid,
              updatedby: context.USERID
            })
            .then(() => {
              value.context.VEHICLEPROFILE.vehicle_status = 'Active'
              value.context.$wait.end("removeBreakDown-" + value.vehicleid);
              value.context.$Notify.Success("Taxi Status Is Active!", "");
            })
            .catch((error) => {
              value.context.$wait.end("removeBreakDown-" + value.vehicleid);

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });

    },
    disputeInvoice: (context, payload) => {
      
      context.INVOICEDISPUTEADD.dropzoneid = context.invoiceDropZoneSessionID;
      context.INVOICEDISPUTEADD.voicenotesessionid =
        context.invoiceVoiceNoteSessionID;

      context.INVOICEDISPUTEADD.invoiceComments = document.getElementById(
        "clip-textarea"
      ).value;

      context.$Notify.YesNo(false, { context, payload }).then((value) => {
        if (value) {
          value.context.$wait.start("dispute-" + context.transid);
          value.router = value.context.$router;
          value.context.$store
            .dispatch("invoices/createInvoiceDisputeRequest", {
              router: value.router,
              _addModel: {
                taxi_association_id:
                  context.INVOICEDISPUTEADD.taxiassociationid,
                invoice_id: context.INVOICEDISPUTEADD.invoiceid,
                comments: context.INVOICEDISPUTEADD.invoiceComments,
                voicenotesessionid:
                  context.INVOICEDISPUTEADD.voicenotesessionid,
                dropzoneid: context.INVOICEDISPUTEADD.dropzoneid,
                addedby: context.INVOICEDISPUTEADD.addedby,
              },
            })
            .then(() => {
              context.INVOICEDISPUTEADD.invoiceComments = "";
              //context.invoiceDropZoneSessionID = "";
              value.context.$wait.end("dispute-" + context.transid);
              value.context.$Notify.Success("Dispute Request added!", "");
            })
            .catch((error) => {
              value.context.$wait.end("dispute-" + context.transid);

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    disputeReceipt: (context, payload) => {
      context.RECEIPTDISPUTEADD.dropzoneid = context.receiptDropZoneSessionID;
      context.RECEIPTDISPUTEADD.voicenotesessionid =
        context.receiptVoiceNoteSessionID;

      context.RECEIPTDISPUTEADD.receiptComments = document.getElementById(
        "clipboard-textarea"
      ).value;

      context.$Notify.YesNo(false, { context, payload }).then((value) => {
        if (value) {
          
          value.context.$wait.start("dispute-" + context.transid);
          value.router = value.context.$router;
          value.context.$store
            .dispatch("invoices/createReceiptDisputeRequest", {
              router: value.router,
              _addModel: {
                taxi_association_id:
                  value.context.RECEIPTDISPUTEADD.taxiassociationid,
                receipt_id: value.context.RECEIPTDISPUTEADD.receiptid,
                comments: value.context.RECEIPTDISPUTEADD.receiptComments,
                voicenotesessionid:
                  value.context.RECEIPTDISPUTEADD.voicenotesessionid,
                dropzoneid: value.context.receiptDropZoneSessionID,
                addedby: value.context.RECEIPTDISPUTEADD.addedby,
              },
            })
            .then(() => {
              context.RECEIPTDISPUTEADD.receiptComments = "";
              context.RECEIPTDISPUTEADD.voicenotesessionid = "";
              value.context.$wait.end("dispute-" + context.transid);
              value.context.$Notify.Success("Dispute Request added!", "");
            })
            .catch((error) => {
              value.context.$wait.end("dispute-" + context.transid);

              value.context.$Notify.Error(error.message, "");
            })
            .finally(() => {});
        }
      });
    },
    changeVehicleBillingType: (context, _addModel) => {
      
      context.CHANGEBILLING.billingstartdate = context.$Toolkit.ConvertToServerDate(
        context.CHANGEBILLING.billingstartdate
      );

      context.CHANGEBILLING.lastbilldate = context.$Toolkit.ConvertToServerDate(
        context.CHANGEBILLING.lastbilldate
      );

      const billingEndDate = context.$Toolkit.ConvertToServerDate(
        $("#datepicker-billingEnd").find("input").val()
      );

      const nextBillingDate = context.$Toolkit.ConvertToServerDate(
        $("#datepicker-nextBilling").find("input").val()
      );

      context.CHANGEBILLING.billingenddate = billingEndDate;
      context.CHANGEBILLING.nextbillingdate = nextBillingDate;

      context.$Notify.YesNo(false, { context, _addModel }).then((value) => {
        
        if (value) {
          value.router = value.context.$router;
          value.context.$store
            .dispatch("OperatorVehicle/changeVehicleBilling", {
              addModel: value._addModel,
              router: value.router,
            })
            .then((data) => {
              value.context.$Notify.Success("Billing change requested", data);
            })
            .catch((error) => {
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },

    downloadReceiptMedia: (context, receiptsdisputesid, fileid) => {
      context.$store
        .dispatch("TaxiDrivers/downloadMedia", {
          receiptsdisputesid,
          fileid,
          router: context.$router,
        })
        .then((_rb) => {
          console.log(_rb[0].fn_receipts_disputes_get_attached[0].filename);
          context.downloadItem(
            _rb[0].fn_receipts_disputes_get_attached[0].filename,
            _rb[0].fn_receipts_disputes_get_attached[0].data,
            _rb[0].fn_receipts_disputes_get_attached[0].mimetype
          );
        })
        .catch((err) => {
          console.log(err);
        });
      //this.$emit('init')
    },
    downloadInvoiceMedia: (context, invoicedisputesid, fileid) => {
      context.$store
        .dispatch("invoices/downloadMedia", {
          invoicedisputesid,
          fileid,
          router: context.$router,
        })
        .then((_rb) => {
          console.log(_rb[0].fn_invoice_disputes_get_attached[0].filename);
          context.downloadItem(
            _rb[0].fn_invoice_disputes_get_attached[0].filename,
            _rb[0].fn_invoice_disputes_get_attached[0].data,
            _rb[0].fn_invoice_disputes_get_attached[0].mimetype
          );
        })
        .catch((err) => {
          console.log(err);
        });
      //this.$emit('init')
    },

    downloadItem(filename, data, type) {
      const linkSource = `data:application/pdf;base64,${data}`;
      const downloadLink = document.createElement("a");
      const fileName = filename;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    fileadded: function (data) {
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')

      const context = $("#content").data("context");
      context.GETUPLOADEDFILES = context.$store.getters["fileupload/GETALL"];
    },
    addChange: (context, item) => {
      item = Object.assign({}, item);

      const drivernum = $(".default-select").select2("data").val();

      alert(drivernum);

      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("OperatorVehicle/addChangeRequest", {
              _dataObj: value,
            })
            .then((_data) => {
              value.context.ADDEDVEHICLEID = _data.taxi_operator_vehicles_id;
              $("#wizard").data("taxiadded", true);
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");
              value.context.$Notify.Success("Successfully Added!", "");
            })
            .catch((error) => {
              value.context.$wait.end("add");

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },

    // show: (context, increment) => {
    //   if (increment) context.skip += 0;
    //   else {
    //     if (context.skip > 0) context.skip -= 30;
    //   }

    //   context
    //     .getVehicleTransactions({
    //       status: context.id,
    //       skip: context.skip,
    //       len: context.len,
    //       router: context.$router
    //     })
    //     .then(data => {
    //       console.log("These are the transactions: ", data);
    //     });
    // },

    getbyid: (context, id) => {
      context.$store
        .dispatch("OperatorVehicle/getbyid", {
          _dataObj: {
            id,
          },
        })
        .then((data) => {
          
          console.log("this is the vehicle: ", data);

          context.VEHICLEPROFILE.vehicle_status = data[0]._vehiclestatus_name;
          context.VEHICLEPROFILE.accountNumber = data[0]._account_number;
          context.VEHICLEPROFILE.available_balance = data[0]._available_balance;
          context.VEHICLEPROFILE.operator_name = data[0]._taxi_operator_name;
          context.VEHICLEPROFILE.vin_number = data[0]._vin_number;
          context.VEHICLEPROFILE.tara = data[0]._tara;
          context.VEHICLEPROFILE.vehicle_registration_number =
            data[0]._vehicle_register_number;
          context.VEHICLEPROFILE.license_expiry_date =
            data[0]._licence_expiry_date;
          context.VEHICLEPROFILE.license_number = data[0]._licence_number;
          context.VEHICLEPROFILE.operatinglicense_issuedate =
            data[0]._operatinglicense_issuedate;
          context.VEHICLEPROFILE.operatinglicense_expirydate =
            data[0]._operatinglicense_expirydate;
          context.VEHICLEPROFILE.operatinglicense_number =
            data[0]._operatinglicense_number;
          context.VEHICLEPROFILE.cross_border = data[0]._cross_border;
          context.VEHICLEPROFILE.engine_number = data[0]._engine_number;
          context.VEHICLEPROFILE.engine_type = data[0]._vehicle_engine;
          context.VEHICLEPROFILE.transmission_type =
            data[0]._vehicle_transmission_type;
          context.VEHICLEPROFILE.color = data[0]._vehicle_color;
          context.VEHICLEPROFILE.make_model =
            data[0]._vehicle_make + " " + data[0]._vehicle_model;
          context.VEHICLEPROFILE.vehicle_joined_date =
            data[0]._taxi_operator_vehicle_joined_date;
          context.VEHICLEPROFILE.routes = data[0]._taxi_route;
          context.VEHICLEPROFILE.vehicle_finance =
            data[0]._vehicle_finance_institution_name;
          context.VEHICLEPROFILE.vehicle_tracker =
            data[0]._vehicle_tracker_institution_name;
          context.VEHICLEPROFILE.vehicle_insurance =
            data[0]._vehicle_insurance_institution_name;
          context.VEHICLEPROFILE.operator_vehicle_id =
            data[0]._taxi_operator_vehicles_id;

            if(data[0]._account_number != null){
              context.getTransactionsByAcc(context, data[0]._account_number);
            }
        })
        .catch((err) => {
          console.log("vehicle details error: ", err);
        });
    },

    getTransactionsByAcc: (context, accountNumber) => {
      
      context.$store
        .dispatch("OperatorVehicle/getAllTransactionsByAccNo", {
          associationsid: context.taxiassociationid,
          accountnumber: accountNumber,
          skip: context.skip,
          length: context.len,
        })
        .then((data) => {
          context.VEHICLETRANSACTIONS = data;
          console.log("these are the vehicle transactions: ", data);
        })
        .catch((err) => {
          
          console.log("vehicle transactions error: ", err);
        })
        .finally(() => {
          // $("#data-table-transactions").DataTable({
          //   responsive: true,
         // });
        });
    },

    vehicleTimeline: (context, id) => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleTimelineById", {
          _dataObj: {
            id,
            router: context.$router,
          },
        })
        .then((data) => {
          context.VEHICLETIMELINE = data;
          console.log("This is the vehicle timeline: ", data);
        })
        .catch((err) => {
          console.log("vehicle timeline error: ", err);
        });
      //this.$emit('init')
    },

    vehicleDocs: (context, id) => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleDocsById", {
          _dataObj: {
            id,
            router: context.$router,
          },
        })
        .then((data) => {
          console.log("These are the supporting docs..", data);
          context.VEHICLESUPPORTINGDOCS = data;
        })
        .catch((err) => {
          console.log("vehicle docs error: ", err);
        })
        .finally(() => {
          $("#data-table-supportingDocs").DataTable({
            responsive: true,
          });
        });
    },

    vehicleFinesById: (context, id) => {
      
      context.$store
        .dispatch("OperatorVehicle/getVehicleFinesById", {
          router: context.$router,
          id,
          skip: context.skip,
          len: context.len,
        })
        .then((data) => {
          console.log("operator Vehicle fines: ", data);

          context.TRAFFICFINES = data;
        })
        .catch((err) => {
          console.log("operator Vehicle fines errors -> ", err);
        })
        .finally(() => {
          var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: true,
                    autoFill: true,
                    colReorder: true,
                    keys: true,
                    rowReorder: true,
                    select: true
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }
          $("#data-table-trafficFines").DataTable(options);
        });
      //this.$emit('init')
    },

    getVehilcleBillings: (context, id) => {
      
      context
        .getVehicleBillingsById({
          status: context.id,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          context.VEHICLEBILLINGS = data;
          console.log("These are the billings: ", data);
        });
    },

    getInvoiceDisputes: (context, id) => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleInvoiceDisputesByid", {
          _dataObj: {
            id,
            router: context.$router,
          },
        })
        .then((data) => {
          context.VEHICLEINVOICEDISPUTES = data;
          console.log("These are the InvoiceDisputes.. ", data);
        })
        .catch((err) => {
          console.log("vehicle InvoiceDisputes error: ", err);
        });
      //this.$emit('init')
    },

    getReceiptDisputes: (context, id) => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleReceiptDisputesByid", {
          _dataObj: {
            id,
            router: context.$router,
          },
        })
        .then((data) => {
          context.VEHICLERECEIPTSDISPUTES = data;
          console.log("These are the ReceiptDisputes.. ", data);
        })
        .catch((err) => {
          console.log("vehicle ReceiptDisputes error: ", err);
        });
      //this.$emit('init')
    },
    getTaxiRoutesByTaxiId: (context, id) => {
      context.$store
        .dispatch("OperatorVehicle/getTaxiRoutesByTaxiId", {
          id,
          router: context.$router,
        })
        .then((data) => {
          context.VEHICLETAXIROUTES = data;
          console.log("These are the Routes.. ", data);
           
        })
        .catch((err) => {
          console.log("vehicle routes errors: ", err);
        })
        .finally(() => {
           var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: true,
                    autoFill: true,
                    colReorder: true,
                    keys: true,
                    rowReorder: true,
                    select: true
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }
         $("#data-table-taxiRoutes").DataTable(options);
        });
      //this.$emit('init')
    },

    getTripHistoryById: (context, id) => {
      
      context.$store
        .dispatch("OperatorVehicle/getVehicleTripHistoryById", {
          router: context.$router,
          id,
        })
        .then((data) => {
          context.VEHICLETRIPHISTORY = data;
          console.log("trip History: ", data);
           
        })
        .catch((err) => {
          console.log("trip History errors: ", err);
        })
        .finally(() => {
           var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: true,
                    autoFill: true,
                    colReorder: true,
                    keys: true,
                    rowReorder: true,
                    select: true
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }
         $("#data-table-tripHistory").DataTable(options);
        });
    },

    // vehicleStatusByid: (context) => {
    //   context.$store
    //     .dispatch("OperatorVehicle/getStatusByAssociation", {
    //       id: context.taxiassociationid,
    //       router: context.$router,
    //     })
    //     .then((data) => {
    //       console.log("vehicle status: ", data);

    //       const vehiclestatussource = data.map((element) => {
    //         return {
    //           value: element._vehiclestatus_id,
    //           text: element._name,
    //         };
    //       });
    //       console.log(
    //         "vehiclestatussource: " + JSON.stringify(vehiclestatussource)
    //       );
    //       initiateVehicleStatus(vehiclestatussource);
    //     })
    //     .catch((err) => {
    //       console.log("vehicle status:", err);
    //     });
    //   //this.$emit('init')
    // },

    // checkDiskExpiryById: (context) => {
    //   context.$store
    //     .dispatch("OperatorVehicle/checkDiskById", {
    //       id: context.id,
    //       router: context.$router,
    //     })
    //     .then((data) => {
    //       console.log("vehicle disk expired: ", data[0].status);

    //       context.diskExpired = data[0].status;
    //       if (data[0].status) {
    //         context.$Notify.Error("License Disk Has Expired!");
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("vehicle disk expiry errors:", err);
    //     });
    //   //this.$emit('init')
    // },

    onclick: function () {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(handleSuccess)
        .catch(handleError);
    },
    videoonclick: function () {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext("2d").drawImage(video, 0, 0);
      // Other browsers will fall back to image/png
      img.src = canvas.toDataURL("image/webp");
    },
    handleSuccess(stream) {
      screenshotButton.disabled = false;
      video.srcObject = stream;
    },
  },
  beforeMount() {
    // this.show(this.context, true);
    // this.getbyid(this.context, this.id);
  },
  mounted: function () {
    //this.get(this.context
    App.init();

    var handleCheckTime = function(i) {
	"use strict";
	
	if (i < 10) {i = "0" + i};
	return i;
};

//  $("#advance-daterange span").html(
    //   moment()
    //     .subtract("days", 29)
    //     .format("MMMM D, YYYY") +
    //     " - " +
    //     moment().format("MMMM D, YYYY")
    // );

    $("#advance-daterange").daterangepicker(
      {
        format: "MM/DD/YYYY",
        startDate: moment().subtract(1, "days"),
        endDate: moment(),
        minDate: "01/01/2015",
        maxDate: "12/31/2050",
        dateLimit: { days: 60 },
        showDropdowns: true,
        showWeekNumbers: true,
        timePicker: false,
        timePickerIncrement: 1,
        timePicker12Hour: true,
        ranges: {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days")
          ],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment()
              .subtract(1, "month")
              .startOf("month"),
            moment()
              .subtract(1, "month")
              .endOf("month")
          ]
        },
        opens: "right",
        drops: "down",
        buttonClasses: ["btn", "btn-sm"],
        applyClass: "btn-primary",
        cancelClass: "btn-default",
        separator: " to ",
        locale: {
          applyLabel: "Submit",
          cancelLabel: "Cancel",
          fromLabel: "From",
          toLabel: "To",
          customRangeLabel: "Custom",
          daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
          monthNames: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ],
          firstDay: 1
        }
      },
      function(start, end, label) {
        

        const context = $(document).data("context");

        context.startDate = start.format("D MMMM YYYY");
        context.endDate = end.format("D MMMM YYYY");

        $("#advance-daterange span").html(
          start.format("D MMMM YYYY") + " - " + end.format("D MMMM YYYY")
        );

        

        context.loadData();
        
      }
    );
		
var handleStartTime = function() {
	"use strict";
	
	var today = new Date();
	var h = today.getHours();
	var m = today.getMinutes();
	var s = today.getSeconds();
	var a;
	m = handleCheckTime(m);
	s = handleCheckTime(s);
	a = (h > 11) ? 'pm' : 'am';
	h = (h > 12) ? h - 12 : h;
	document.getElementById('time').innerHTML = h + ":" + m + a;
	var t = setTimeout(handleStartTime, 500);
};

var handleDatepicker = function() {
	"use strict";
	
	$('[data-render="datepicker"]').datepicker({
		todayHighlight: true,
		autoclose: true
	});
};

    // handleStartTime();
	  // handleDatepicker();


    //this.getallbookingscards(this.context);
    //this.getallupcomingbookings(this.context);
    
    //this.getvehicleTrans(this.context);

    // this.getbyid(this.context, this.id);
    // this.getTripHistoryById(this.context, this.id);
    // this.vehicleTimeline(this.context, this.id);
    // this.vehicleDocs(this.context, this.id);
    // this.vehicleFinesById(this.context, this.id);
    // this.getVehilcleBillings(this.context, this.id);
    // this.getInvoiceDisputes(this.context, this.id);
    // this.getReceiptDisputes(this.context, this.id);
    // this.getTaxiRoutesByTaxiId(this.context, this.id);
    

    // this.vehicleStatusByid(this.context);
    // this.checkDiskExpiryById(this.context);

    $("#vehicleStatus").data("context", this.context);

    $("#datepicker-billingEnd").datepicker({
      todayHighlight: true,
      autoclose: true,
    });

    $("#datepicker-nextBilling").datepicker({
      todayHighlight: true,
      autoclose: true,
    });

    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="tooltip-invoice-media"]').tooltip();
    $('[data-toggle="tooltip-invoice-voicenote"]').tooltip();
    $('[data-toggle="tooltip-receipt-media"]').tooltip();
    $('[data-toggle="tooltip-receipt-voicenote"]').tooltip();

    $(".default-select").data("context", this.context);

    //$('.selectpicker1').selectpicker('render');
    // FormWizardValidation.init();
    // $("#masked-input-phone").mask("(082) 686-9833");
    // $("#masked-input-tid").mask("99999999999999");

    $(".default-select2")
      .select2()
      .on("change", (e) => {
        const context = $(".default-select").data("context");
      });

    //     $(function(){
    //    $(".dropdown-menu").on('click', 'a', function(){
    //        $(this).parents('.dropdown').find('button').text($(this).text());
    //    })
    // });

    // function update(stream) {
    //   document.querySelector("video").src = stream.url;
    // }

	
	
  },
};
var initiateVehicleStatus = function (vehiclestatussource) {
  const context = $("#vehicleStatus").data("context");
  $("#vehicleStatus").editable({
    prepend: "Active",
    source: vehiclestatussource,
    url: null,
    mode: "inline",
    success: function (response, newValue) {
      console.log(this.prepend);
      const context = $("#vehicleStatus").data("context");
      context.$Notify.YesNo(false, { context, newValue }).then((value) => {
        
        if (value) {
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context
            .changeVehicleStatusRequest({
              router: value.router,
              addModel: {
                taxiassociationid: context.taxiassociationid,
                taxivehicleid: context.id,
                taxivehiclestatusid: newValue,
                updatedby: context.updatedby,
              },
            })
            .then((_rb) => {
              console.log(_rb); //update backbone model
              this.prepend = _rb[0]._vehiclestatus_name;
              console.log(this.prepend);
              $("#AddModal").modal("hide");
              $("#vehicleStatus").editable("setValue", this.prepend);
              value.context.$wait.end("add");
              value.context.$Notify.Success("Status Change Requested!", "");
            })
            .catch((error) => {
              value.context.$wait.end("add");

              value.context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        }
      });
    },
  });
};
</script>

	

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>