/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import config from './configbase';

// options...
export default {
  // options...
  LOGIN_URL: () => `${config.BASEFIN_URL()}/authentication/login`,
  URL: apiname => `${config.BASEFIN_URL()}/${apiname}/`,
  URLBYID: apiname => `${config.BASEFIN_URL()}/${apiname}/{id}`,
  URLBYTAXIID: apiname => `${config.BASEFIN_URL()}/${apiname}/TaxiAssId/{id}`,
  URL_PAGING: apiname => `${config.BASEFIN_URL()}/${apiname}/{status}/{skip}/{len}`,
  URL_PAGING2: apiname => `${config.BASEFIN_URL()}/${apiname}/{skip}/{len}`,
  URLBYACCOUNT: apiname => `${config.BASEFIN_URL()}/${apiname}/{associationsid}/{accountnumber}/{skip}/{length}`,
  URL_RESTOREDELETED: apiname => `${config.BASEFIN_URL()}/${apiname}/{id}/{restoredby}`,
  URL_CUSTOM: apiname => `${config.BASEFIN_URL()}/${apiname}`,
  

};


