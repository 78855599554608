<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Collection Report'"
      :ActiveMenu="'collectionreport'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Collection Report"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Money"
        breadcrumbitem3="Collection Report"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">Collection Statistics</h4>
            </div>
            <!-- end panel-heading -->

            <!-- begin panel-body -->
            <div class="panel-body">
              <!-- begin nav-pills -->
              <ul class="nav nav-pills">
                <li class="nav-items">
                  <a href="#nav-pills-tab-1" data-toggle="tab" id="byP" class="nav-link active">
                    <span class="d-sm-none">Payments</span>
                    <span class="d-sm-block d-none">Payments</span>
                  </a>
                </li>
                <li class="nav-items">
                  <a href="#nav-pills-tab-2" data-toggle="tab" id="byV" class="nav-link">
                    <span class="d-sm-none">By Vehicles</span>
                    <span class="d-sm-block d-none">Arrears By Vehicles</span>
                  </a>
                </li>
                <li class="nav-items">
                  <a href="#nav-pills-tab-3" data-toggle="tab" id="byO" class="nav-link">
                    <span class="d-sm-none">By Operators</span>
                    <span class="d-sm-block d-none">Arrears By Operators</span>
                  </a>
                </li>
              </ul>
              <!-- end nav-pills -->
              <!-- begin tab-content -->
              <div class="tab-content">
                <!-- begin tab-pane -->
                <div class="tab-pane fade active show" id="nav-pills-tab-1">
                  <h3 class="m-t-10"></h3>
                  <div class="form-group row">
                    <label class="col-lg-2 col-form-label">Enter Payment Date</label>
                    <div class="col-lg-3">
                      <div id="advance-daterange" class="btn btn-default btn-block text-left">
                        <i class="fa fa-caret-down pull-right m-t-2"></i>
                        <span></span>
                      </div>
                    </div>
                  </div>
                  <table id="data-table-combine-payments" class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th width="1%" data-orderable="false"></th>
                        <th class="text-nowrap">Operator/Taxi</th>
                        <th class="text-nowrap">Amount</th>
                        <th class="text-nowrap">Captured By</th>
                        <th class="text-nowrap">Captured Date</th>
                        <th class="text-nowrap">Status</th>
                        <th class="text-nowrap hidden-print">Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item,index) in GETPAYMENTHISTORY"
                        v-bind:key="item._id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                        <td>{{item.entityname}}</td>
                        <td>{{item._amount | currency}}</td>
                        <td>{{item.captured_name}}</td>
                        <td>{{item.captureddate | formatToShortDateWithTime }}</td>
                        <td>{{item.receiptstatus}}</td>
                        <td class="with-btn valign-middle" nowrap>
                          <router-link
                            :to="{ name:  'updatevehicle',  params: { id: item.geolocation }}"
                            class="btn btn-sm btn-primary width-120 m-r-2"
                            v-if="item.geolocation"
                          >View Location</router-link>
                          <router-link
                            :to="{ name:  'changeofownership',  params: { id: item.hasvoicenotes }}"
                            class="btn btn-sm btn-warning width-120 m-r-2"
                            v-if="item.hasvoicenotes"
                          >Listen</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- end tab-pane -->
                <!-- begin tab-pane -->
                <div class="tab-pane fade" id="nav-pills-tab-2">
                  <h3 class="m-t-10"></h3>
                  <table id="data-table-combine-vehicles" class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th width="1%" data-orderable="false"></th>
                        <th class="text-nowrap">Taxi Reg No</th>
                        <th class="text-nowrap">Operator Name</th>
                        <th class="text-nowrap">Id Number</th>
                        <th class="text-nowrap">Operator Mobile</th>
                        <th class="text-nowrap">Outstanding Balance</th>
                        <th class="text-nowrap">Status</th>
                        <th class="text-nowrap hidden-print">Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item,index) in GETARREARSBYVEHICLES"
                        v-bind:key="item._id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                        <td>{{item.regnumber}}</td>
                        <td>{{item.taxioperator_name}}</td>
                        <td>{{item.idnumber}}</td>
                        <td>{{item.mobilenumber}}</td>
                        <td>{{item.availablebalance | currency}}</td>
                        <td>{{item.accountstatus}}</td>
                        <td class="with-btn valign-middle" nowrap>
                          <router-link
                            :to="{ name: 'taxivehicleprofile', params: { id: item._taxi_vehicle_id }}"
                            class="btn btn-sm btn-primary width-120 m-r-2"
                          >View Profile</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- end tab-pane -->
                <!-- begin tab-pane -->
                <div class="tab-pane fade" id="nav-pills-tab-3">
                  <h3 class="m-t-10"></h3>
                  <table id="data-table-combine-operators" class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th width="1%" data-orderable="false"></th>
                        <th class="text-nowrap">Operator Name</th>
                        <th class="text-nowrap">Id Number</th>
                        <th class="text-nowrap">Mobile No</th>
                        <th class="text-nowrap">Outstanding Amount</th>
                        <th class="text-nowrap">Number Of Taxis</th>
                        <th class="text-nowrap hidden-print">Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item,index) in GETARREARSBYOPERATORS"
                        v-bind:key="item._id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                        <td>{{item.taxioperator_name}}</td>
                        <td>{{item.idnumber}}</td>
                        <td>{{item.mobilenumber}}</td>
                        <td>{{item.outstandingamount | currency}}</td>
                        <td>{{item.current_number_vehicles}}</td>
                        <td class="with-btn valign-middle" nowrap>
                          <router-link
                            :to="{ name: 'taxioperatorprofile', params: { id: item._id }}"
                            class="btn btn-sm btn-primary width-120 m-r-2"
                          >View Profile</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- end tab-pane -->
              </div>
              <!-- end tab-content -->
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("payments");

export default {
  name: "ManageVehicles",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      context: this,
      addedsuccess: undefined,
      errormessage: undefined,
      id: this.$route.query.id,
      skip: 0,
      length: 10000,
      startDate: {},
      endDate: {},
      paymentsloaded: false,
      arearsvehiclesloaded: false,
      arrearsoperatorsloaded: false,
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters({
      GETPAYMENTHISTORY: "GETPAYMENTHISTORY",
      GETARREARSBYOPERATORS: "GETARREARSBYOPERATORS",
      GETARREARSBYVEHICLES: "GETARREARSBYVEHICLES",
    }),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions([
      "generatepaymentHistory",
      "generateArrearsByVehicles",
      "generateArrearsByOperators"
    ])
  },
  beforeMount: function() {},
  mounted: function() {
    //this.getbyid(this.context, this.taxiassociationid);

    App.init();

    $(".nav-link").on("click", function() {
      const context = $("#advance-daterange span").data("context");

      const router = context.$router;

      var payload = {
        taxiassociationsId: context.taxiassociationid,
        skip: context.skip,
        length: context.length
      };
      

      if ($(this).attr("id") === "byV") {
        context
          .generateArrearsByVehicles({ router, payload })
          .then(parsedResultOP => {
            if (parsedResultOP.status) {
              if (parsedResultOP.data.length > 0) {
                if ($("#data-table-combine-vehicles").length !== 0) {
                  var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: true,
                    autoFill: true,
                    colReorder: true,
                    keys: true,
                    rowReorder: true,
                    select: true
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }

                  if(!context.arearsvehiclesloaded)
                  $("#data-table-combine-vehicles").DataTable(options);
                 
                  context.context.arearsvehiclesloaded = true;

                }
              } else {
              }
            }
            //context.$wait.end("generatestatement");
          })
          .catch(error => {
            //context.$wait.end("generatestatement");
          });
      } else if ($(this).attr("id") === "byO") {
        const router = context.$router;

        context
          .generateArrearsByOperators({ router, payload })
          .then(parsedResultOP => {
            if (parsedResultOP.status) {
              if (parsedResultOP.data.length > 0) {
                if ($("#data-table-combine-operators").length !== 0) {
                  var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: true,
                    autoFill: true,
                    colReorder: true,
                    keys: true,
                    rowReorder: true,
                    select: true
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }

                  if(!context.arrearsoperatorsloaded)
                  $("#data-table-combine-operators").DataTable(options);

                  context.context.arrearsoperatorsloaded = true;
                }
              } else {
              }
            }
            //context.$wait.end("generatestatement");
          })
          .catch(error => {
            //context.$wait.end("generatestatement");
          });
      }
    });

    $("#advance-daterange span").data("context", this.context);

    $("#advance-daterange span").html(
      moment()
        .subtract("days", 29)
        .format("MMMM D, YYYY") +
        " - " +
        moment().format("MMMM D, YYYY")
    );

    $("#advance-daterange").daterangepicker(
      {
        format: "MM/DD/YYYY",
        startDate: moment().subtract(29, "days"),
        endDate: moment(),
        minDate: "01/01/2015",
        maxDate: "12/31/2050",
        dateLimit: { days: 60 },
        showDropdowns: true,
        showWeekNumbers: true,
        timePicker: false,
        timePickerIncrement: 1,
        timePicker12Hour: true,
        ranges: {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days")
          ],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment()
              .subtract(1, "month")
              .startOf("month"),
            moment()
              .subtract(1, "month")
              .endOf("month")
          ]
        },
        opens: "right",
        drops: "down",
        buttonClasses: ["btn", "btn-sm"],
        applyClass: "btn-primary",
        cancelClass: "btn-default",
        separator: " to ",
        locale: {
          applyLabel: "Submit",
          cancelLabel: "Cancel",
          fromLabel: "From",
          toLabel: "To",
          customRangeLabel: "Custom",
          daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
          monthNames: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ],
          firstDay: 1
        }
      },
      function(start, end, label) {
        const context = $("#advance-daterange span").data("context");

        
        context.startDate = start.format("D MMMM YYYY");
        context.endDate = end.format("D MMMM YYYY");

        $("#advance-daterange span").html(
          start.format("D MMMM YYYY") + " - " + end.format("D MMMM YYYY")
        );

        

        var payload = {
          taxiassociationsId: context.taxiassociationid,
          startDate: context.$Toolkit.ConvertToServerDate(context.startDate),
          endDate: context.$Toolkit.ConvertToServerDate(context.endDate),
          skip: context.skip,
          length: context.length
        };

        const router = context.$router;

        context
          .generatepaymentHistory({ router, payload })
          .then(parsedResultOP => {
            if (parsedResultOP.status) {
              console.log('Payment results: ',parsedResultOP.data);
              if (parsedResultOP.data.length > 0) {
               if ($("#data-table-combine-payments").length !== 0) {
                  var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: true,
                    autoFill: true,
                    colReorder: true,
                    keys: true,
                    rowReorder: true,
                    select: true
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }

                  if(!context.paymentsloaded)
                  $("#data-table-combine-payments").DataTable(options);
                  
                  context.context.paymentsloaded = true;
                }
              } else {
              }
            }
            //context.$wait.end("generatestatement");
          })
          .catch(error => {
            //context.$wait.end("generatestatement");
          });
      }
    );

    //  this.getByTaxiAssId(this.context);
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

