/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';


export default {
  namespaced: true,
  state: {
    cashup: [],
    approved_cashup: [],
    pending_cashup: [],
    execute_approve: [],
    execute_verify: [],
    execute_reject: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETACTIVECASHUP: state => state.cashup,
    GETAPPROVEDCASHUP: state => state.approved_cashup,
    GETPENDINGCASHUP: state => state.pending_cashup,
    APPROVECASHUP: state => state.execute_approve,
    VERIFYCASHUP: state => state.execute_verify,
    REJECTCASHUP: state => state.execute_reject,

  },

  mutations: {
    LOADED: (state, _data) => {
      if (_data !== undefined) {
        state.cashup = _data;
      }
    },
    APPROVEDLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.approved_cashup = _data;
      }
    },
    PENDINGLOADED: (state, _data) => {

      if (_data !== undefined) {
        state.pending_cashup = _data;
      }
    },
    ADDED: (state, _data) => {
      state.cashup.push(_data);
    },
    CASHUPAPPROVEADDED: (state, _data) => {
      state.execute_approve.push(_data);
    },
    CASHUPVERIFYADDED: (state, _data) => {
      state.execute_verify.push(_data);
    },
    CASHUPREJECTEDADDED: (state, _data) => {
      state.execute_reject.push(_data);
    },

    UPDATED: (state, _data) => {
      if (_data !== undefined) {
        Object.assign(state.cashup.filter(item => item._id === _data._id)[0], _data);
      }
    },
    RESTOREDELETED: (state, {
      id
    }) => {
      if (id !== undefined) {
        state.city.filter(item => item._id === id)[0]._deleted_by = undefined;
        state.city.filter(item => item._id === id)[0]._date_deleted = undefined;
      }
    },
    MARKASDELETE: (state, {
      id,
      deletedby
    }) => {
      if (id !== undefined) {
        state.cashup.filter(item => item._id === id)[0]._deleted_by = deletedby;
        state.cashup.filter(item => item._id === id)[0]._date_deleted = Date.now;
      }
    },
    MARKASDELETEVERIFIED: (state, id) => {
      
      if (id !== undefined) {
        state.pending_cashup = state.pending_cashup.filter(item => item.collection_session_id !== id);
      }
    },
    DELETE: (state, id) => {
      if (id !== undefined) {
        state.cashup = state.cashup.filter(item => item._id !== id);
        state.cashup = true;
      }
    },
  },
  actions: {
    /* Async */


    /* Auth.js Mutations */
    // getall({
    //   state, rootState, rootGetters, commit, dispatch, getters,
    // }, { _dataObj }) {
    //   return new Promise((resolve, reject) => {
    //     const { router } = _dataObj;

    //     const requestheader = {
    //       router,
    //       token: rootGetters['auth/TOKEN'],
    //       apiName: config.CASHUP_URL,
    //     };

    //     this._vm.$TAMSFIN_API.getAll(requestheader).then((parsedResult) => {
    //       if (parsedResult.status) {
    //         commit('LOADED', parsedResult.data);
    //         resolve();
    //       // navigateTo(router, parsed_result.data[0]);
    //       }
    //     }).catch((error) => {
    //     // commit('LOGINFAILED', error.data);
    //       reject();
    //     });
    //   });
    // },
    // getActiveCashup({
    //   state,
    //   rootState,
    //   rootGetters,
    //   commit,
    //   dispatch,
    //   getters,
    // }, {
    //   taxiassociation_id,userid,router
    // }) {

    //   return new Promise((resolve, reject) => {

    //     const requestheader = {
    //       router,
    //       token: rootGetters['auth/TOKEN'],
    //       apiName: config.ACTIVECASHUP_URL,
    //     };

    //     this._vm.$TAMSFIN_API.getByPaging2(requestheader, { skip: taxiassociation_id, len: userid }
    //       ).then((parsedResultOP) => {
    //         console.log(parsedResultOP);
    //         if (parsedResultOP.status) {
    //           commit('LOADED', parsedResultOP.data);
    //           resolve(parsedResultOP.data);
    //           // navigateTo(router, parsed_result.data[0]);
    //         }
    //       }).catch((error) => {
    //         console.log(error);
    //         commit('', error.data);
    //       });
    //   });
    // },
    getApprovedCashup({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      status,
      skip,
      len,
      router
    }) {

      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.APPROVEDCASHUP_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging(requestheader, {
          status: status,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {
          console.log('approved cashup: ', parsedResultOP);
          if (parsedResultOP.status) {
            // commit('APPROVEDLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);

            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('', error.data);
        });
      });
    },

    getCashupByTaxiAssId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      taxiassociationid,
      skip,
      len,
      router
    }) {

      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETCASHUPBYASS_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging(requestheader, {
          status: taxiassociationid,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {
          if (parsedResultOP.status) {
            // commit('LOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);

            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('', error.data);
        });
      });
    },

    getPendingCashup({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      status,
      skip,
      len,
      router
    }) {

      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.PENDINGCASHUP_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging(requestheader, {
          status: status,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {
          if (parsedResultOP.status) {
            // commit('PENDINGLOADED', parsedResultOP.data);
            console.log('pending cashup results: ', parsedResultOP.data);
            resolve(parsedResultOP.data);

            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('pending cashup errors: ', error);
          reject(error);
        });
      });
    },

    getPendingApproval({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      status,
      skip,
      len,
      router
    }) {

      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.CASHUPPENDINGAPPROVAL_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging(requestheader, {
          status: status,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {

          if (parsedResultOP.status) {
            // commit('PENDINGLOADED', parsedResultOP.data);
            console.log("pending approval data found: ", parsedResultOP.data);
            resolve(parsedResultOP.data);

            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log("pending approval data errors: ", error);
          reject(error);

        });
      });
    },

    ApproveVerifiedCashUp({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      AddModel
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.APPROVECASHUP_URL,
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('STARTED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },


    startCashup({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          item,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.STARTCASHUP_URL,
        };

        const AddModel = {
          taxiassociationid: item.taxiassociationid,
          collectors_officer_id: item.collector_id,
          addedby: item.addedby,
          geolocation: item.geolocation
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('STARTED', parsedResult.data);
            console.log("Cash up Started: ", parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log("Cash up statr errors: ", error);
          reject(error);
        });
      });
    },
    executeVerify({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      AddModel
    }) {
      return new Promise((resolve, reject) => {


        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VERIFYCASHUP_URL,
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('CASHUPVERIFYADDED', item);
            // 
            // commit('MARKASDELETEVERIFIED', item.collection_session_id);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    executeReject({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          item,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.REJECTCASHUP_URL,
        };

        const AddModel = {
          taxi_association_id: item.taxiassociation_id,
          cashup_id: item.collection_session_id,
          amount_collected: item.amount,
          comments: item.comments,
          rejected_by: item.rejectedby,
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            commit('CASHUPREJECTEDADDED', item);
            resolve();
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },



    endCashup({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      
      return new Promise((resolve, reject) => {
        const {
          item,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.ENDCASHUP_URL,
        };

        const AddModel = {
          taxiassociationid: item.taxiassociation_id,
          cashup_id: item.collection_session_id,
          geolocation: item.geolocation,
          endeddby: item.updatedby
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('ADDED', item);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    update({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          item,
          router,
          updatedby,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.CASHUP_URL,
        };

        const updateModel = {
          province_id: item._province_id,
          name: item._name,
          description: item._description,
          code: item._code,
          isactive: item._is_active,
          updatedby,

        };

        this._vm.$TAMSFIN_API.update(requestheader,
          item._id,
          updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            commit('UPDATED', item);
            resolve();
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    delete({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.CASHUP_URL,
        };

        this._vm.$TAMSFIN_API.delete(requestheader,
          id, {
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('DELETE', id);
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    markAsdelete({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.CASHUP_URL,
        };

        this._vm.$TAMSFIN_API.delete(requestheader,
          id, {
            id,
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('MARKASDELETE', {
              id,
              deletedby
            });
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    restoreDeleted({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          restoredby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.CASHUP_URL,
        };

        this._vm.$TAMSFIN_API.restoreDeleted(requestheader, {
          id,
          restoredby,
        }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('RESTOREDELETED', {
              id
            });
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

  },

};