<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TaxiAssociation'"
      :ActiveMenu="'AddTaxiAssociationRoute'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Taxi Association Routes"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Association"
        breadcrumbitem3="Manage Routes"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin row -->
          <div class="row">
            <!-- begin col-12 -->
            <div class="col-lg-12">
              <!-- begin panel -->
              <div class="panel panel-inverse panel-primary">
                <!-- begin panel-heading -->
                <div class="panel-heading">
                  <div class="panel-heading-btn">
                    <a
                      href="javascript:;"
                      class="btn btn-xs btn-icon btn-circle btn-default"
                      data-click="panel-expand"
                    >
                      <i class="fa fa-expand"></i>
                    </a>
                    <a
                      href="javascript:;"
                      class="btn btn-xs btn-icon btn-circle btn-success"
                      data-click="panel-reload"
                    >
                      <i class="fa fa-redo"></i>
                    </a>
                    <a
                      href="javascript:;"
                      class="btn btn-xs btn-icon btn-circle btn-warning"
                      data-click="panel-collapse"
                    >
                      <i class="fa fa-minus"></i>
                    </a>
                  </div>
                  <h4 class="panel-title">Taxi Association Routes</h4>
                </div>
                <!-- end panel-heading -->
                <div class="panel-toolbar">
                  <div class="btn-group m-r-5">
                    <a
                      data-toggle="modal"
                      data-target="#AddModal"
                      class="btn btn-white"
                      href="javascript:;"
                    >
                      <i class="fa fa-plus"></i>
                      Add Route
                    </a>
                  </div>
                </div>
             
              <!-- begin panel-body -->
              <div class="panel-body">
                <table id="data-table-default" class="table table-striped table-bordered width-full">
                  <thead>
                    <tr>
                      <th width="1%" data-orderable="false"></th>
                      <th class="text-nowrap">ID</th>
                      <th class="text-nowrap">Name</th>
                      <th class="text-nowrap">Description</th>
                      <th class="text-nowrap">Route Fare</th>
                      <th class="text-nowrap">Code</th>
                      <th class="text-nowrap">Status</th>
                      <th class="text-nowrap">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(item,index) in ASSOCIATIONROUTES"
                      v-bind:key="item._id"
                      :class="index & 1 ? 'odd gradeA' : 'even'"
                    >
                      <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                      <td>{{item._id}}</td>
                      <td>{{item._name}}</td>
                      <td>{{item._description}}</td>
                      <td>{{item._taxifare | currencywithSymbol}}</td>
                      <td>{{item._code}}</td>
                      <td>
                        <span
                          class="badge f-s-12"
                          v-bind:class="{ 'badge-success': item._is_active, 'label-secondary':!item._is_active  }"
                          v-if="!item._deleted_by"
                        >{{item._is_active == true ? 'Active' : 'Disabled' }}</span>
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-sm btn-primary width-60"
                          data-toggle="modal"
                          data-target="#EditModal"
                          @click="getRoute(context,item)"
                        >Edit</button>
                        <button
                          type="button"
                          class="btn btn-sm btn-danger width-60"
                          :disabled="$wait.is('del-')"
                          @click="delTaxiRoute(context,item._id)"
                        > <v-wait v-bind:for="'del-'">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Delete
                  </v-wait>
                </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- end panel-body -->
               </div>
              <!-- end panel -->
            </div>
            <!-- end col-12 -->
          </div>
          <!-- end row -->
        </div>

        <!-- end col-12 -->
      </div>

      <!-- Add Modal HTML START-->
      <div id="AddModal" class="modal fade">
        <div class="modal-dialog">
          <div class="modal-content">
            <form>
              <div class="modal-header">
                <h4 class="modal-title">Add Route</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Name</label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        name="BillingTypeName"
                        id="billing_type_name"
                        placeholder="Name"
                        class="form-control"
                        v-model="ADDROUTE._name"
                      />
                    </div>
                  </div>
                  <!-- end form-group row -->
                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Description</label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        name="BillingTypeName"
                        id="billing_type_name"
                        placeholder="Name"
                        class="form-control"
                        v-model="ADDROUTE._description"
                      />
                    </div>
                  </div>
                  <!-- end form-group row -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 col-form-label text-md-right">
                      Taxi Fare
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-9">
                      <CurrencyInput
                        :auto-decimal-mode="true"
                        :currency="currency"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control text-left demo__currency-input"
                        v-model="ADDROUTE._taxifare"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->
                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">code</label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        name="BillingTypeName"
                        id="billing_type_name"
                        placeholder="Name"
                        class="form-control"
                        v-model="ADDROUTE._code"
                      />
                    </div>
                  </div>
                  <!-- end form-group row -->
                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">National Route Code</label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        name="BillingTypeName"
                        id="billing_type_name"
                        placeholder="Name"
                        class="form-control"
                        v-model="ADDROUTE._national_route_code"
                      />
                    </div>
                  </div>
                  <!-- end form-group row -->
                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Board Route Code</label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        name="BillingTypeName"
                        id="billing_type_name"
                        placeholder="Name"
                        class="form-control"
                        v-model="ADDROUTE._board_route_code"
                      />
                    </div>
                  </div>
                  <!-- end form-group row -->
                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">
                      Origin
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        name="BillingTypeName"
                        id="billing_type_name"
                        placeholder="Name"
                        class="form-control"
                        v-model="ADDROUTE._origin"
                      />
                    </div>
                  </div>
                  <!-- end form-group row -->
                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">
                      Destination
                      <span class="text-danger">*</span>
                      </label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        name="BillingTypeName"
                        id="billing_type_name"
                        placeholder="Name"
                        class="form-control"
                        v-model="ADDROUTE._destination"
                      />
                    </div>
                  </div>
                  <!-- end form-group row -->
                    <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">
                      Has Load Fee
                      <span class="text-danger">*</span>
                      </label>
                    <div class="col-md-9">
                      <input
                        type="checkbox"
                        name="BillingTypeName"
                        id="billing_type_name"
                        placeholder="Name"
                        class="form-control"
                        v-model="ADDROUTE._has_load_fee"
                      />
                    </div>
                  </div>
                  <!-- end form-group row -->
                    <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">
                      Load Fee Billing
                      <span class="text-danger">*</span>
                      </label>
                    <div class="col-md-9">
                      <select
                                      class="form-control"
                                      v-model="ADDROUTE._load_fee_billing_id"
                                    >
                                      <option value="0" selected>Select Billing</option>
                                      <option
                                        v-for="item in BILLINGITEMS"
                                        v-bind:key="item._billingtype_id"
                                        :value="item._billingtype_id"
                                      >{{item._billingtype_name + '(' + item._collection_frequency_name + ' R' + item._billing_items_amount + ')'}}
                                    </option>
                                    </select>
                    </div>
                  </div>
                  <!-- end form-group row -->
                </div>
              </div>
              <div class="modal-footer">
                <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
                <button
                  type="button"
                  class="btn btn-sm btn-success width-80"
                  data-dismiss="modal"
                  :disabled="$wait.is('add-')"
                  @click="addRoute(context, ADDROUTE)"
                >
                  <v-wait v-bind:for="'add-'">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Add
                  </v-wait>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Add Modal HTML START-->

      <!-- Add Modal HTML START-->
      <div id="EditModal" class="modal fade">
        <div class="modal-dialog">
          <div class="modal-content">
            <form>
              <div class="modal-header">
                <h4 class="modal-title">Update Route</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Name</label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        name="BillingTypeName"
                        id="billing_type_name"
                        placeholder="Name"
                        class="form-control"
                        v-model="UPDATEROUTE._name"
                      />
                    </div>
                  </div>
                  <!-- end form-group row -->
                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Description</label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        name="BillingTypeName"
                        id="billing_type_name"
                        placeholder="Name"
                        class="form-control"
                        v-model="UPDATEROUTE._description"
                      />
                    </div>
                  </div>
                  <!-- end form-group row -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 col-form-label text-md-right">
                      Taxi Fare
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-9">
                      <CurrencyInput
                        :auto-decimal-mode="true"
                        :currency="currency"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control text-left demo__currency-input"
                        v-model="UPDATEROUTE._taxifare"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->
                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">code</label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        name="BillingTypeName"
                        id="billing_type_name"
                        placeholder="Name"
                        class="form-control"
                        v-model="UPDATEROUTE._code"
                      />
                    </div>
                  </div>
                  <!-- end form-group row -->
                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">National Route Code</label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        name="BillingTypeName"
                        id="billing_type_name"
                        placeholder="Name"
                        class="form-control"
                        v-model="UPDATEROUTE._national_route_code"
                      />
                    </div>
                  </div>
                  <!-- end form-group row -->
                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Board Route Code</label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        name="BillingTypeName"
                        id="billing_type_name"
                        placeholder="Name"
                        class="form-control"
                        v-model="UPDATEROUTE._board_route_code"
                      />
                    </div>
                  </div>
                  <!-- end form-group row -->
                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">
                      Origin
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        name="BillingTypeName"
                        id="billing_type_name"
                        placeholder="Name"
                        class="form-control"
                        v-model="UPDATEROUTE._origin"
                      />
                    </div>
                  </div>
                  <!-- end form-group row -->
                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">
                      Destination
                      <span class="text-danger">*</span>
                      </label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        name="BillingTypeName"
                        id="billing_type_name"
                        placeholder="Name"
                        class="form-control"
                        v-model="UPDATEROUTE._destination"
                      />
                    </div>
                  </div>
                  <!-- end form-group row -->
                    <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">
                      Has Load Fee
                      <span class="text-danger">*</span>
                      </label>
                    <div class="col-md-9">
                      <input
                        type="checkbox"
                        name="BillingTypeName"
                        id="billing_type_name"
                        placeholder="Name"
                        class="form-control"
                        v-model="UPDATEROUTE._has_load_fee"
                      />
                    </div>
                  </div>
                  <!-- end form-group row -->
                    <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">
                      Load Fee Billing
                      <span class="text-danger">*</span>
                      </label>
                    <div class="col-md-9">
                      <select
                                      class="form-control"
                                      v-model="UPDATEROUTE._load_fee_billing_id"
                                    >
                                      <option value="0" selected>Select Billing</option>
                                      <option
                                        v-for="item in BILLINGITEMS"
                                        v-bind:key="item._billingtype_id"
                                        :value="item._billingtype_id"
                                      >{{item._billingtype_name + '(' + item._collection_frequency_name + ' R' + item._billing_items_amount + ')'}}
                                    </option>
                                    </select>
                    </div>
                  </div>
                  <!-- end form-group row -->
                </div>
              </div>
              <div class="modal-footer">
                <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
                <button
                  type="button"
                  class="btn btn-sm btn-success width-80"
                  data-dismiss="modal"
                  :disabled="$wait.is('update-')"
                  @click="updateRoute(context, UPDATEROUTE)"
                >
                  <v-wait v-bind:for="'update-'">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Save
                  </v-wait>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Add Modal HTML START-->

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->
	
<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TA");

export default {
  name: "AddTaxiAssociation",
  data: function() {
    return {
      currencySymbol: "R ",
      position: "prefix",
      LOADED: false,
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      BILLINGITEMS: [],
      ASSOCIATIONROUTES: [],
      SYSTEMUSERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      id: this.$route.params.id,
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      ADDROUTE: {
        _taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
                _name: "",
                _description: "",
                _taxifare: 0,
                _code: "",
                _origin: "",
                _destination: "",
                _has_load_fee: false,
                _load_fee_billing_id: 0,
                _national_route_code: "",
                _board_route_code: "",
                _addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      UPDATEROUTE: {
        _taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
            _id: 0,
                _name: "",
                _description: "",
                _taxifare: 0,
                _code: "",
                _origin: "",
                _destination: "",
                _has_load_fee: false,
                _load_fee_billing_id: 0,
                _national_route_code: "",
                _board_route_code: "",
                _updated_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
    },
      routeid: null
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl
  },
  beforeMount() {
    
  },
  computed: {
    ...mapGetters({
      
    }),
    currency() {
      return { [this.position]: this.currencySymbol };
    },
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions(["addtaxiassociation"]),

    delTaxiRoute: (context,id) => {
      context.$Notify.YesNo(false, { context, id }).then(value => {
        if (value) {
          value.context.$wait.start("del-");
          value.context.$store
        .dispatch("TA/deleteTaxiRoute", {
          router: value.context.$router,
          id: value.id,
          deletedby: value.context.SYSTEMUSERID
        })
        .then(data => {
          value.context.$Notify.Success("Successfully Deleted!", "");
          value.context.getRoutesByAssId(value.context);
        })
        .catch(error => {
          console.log("Association Routes errors:", error);
        })
        .finally(() => {
          value.context.$wait.end("del-");
        });
      }
    });
    },
    getBillingByAssId: context => {
      context.$store
        .dispatch("billingitems/getNewAllByTaxiAssID", {
          router: context.$router,
          id: context.taxi_association_id
        })
        .then(data => {
          context.BILLINGITEMS = data;
        })
        .catch(error => {
          console.log("Association Routes errors:", error);
        })
        .finally(() => {
         
        });
    },
    getRoutesByAssId: context => {
      context.$store
        .dispatch("TA/getTaxiAssRoutes", {
          router: context.$router,
          id: context.taxi_association_id
        })
        .then(data => {
          console.log("Association Routes:", data);
          context.ASSOCIATIONROUTES = data;
        })
        .catch(error => {
          console.log("Association Routes errors:", error);
        })
        .finally(() => {
          if(context.LOADED == false){
            context.LOADED = true;
            $("#data-table-default").DataTable({
            responsive: true
          });
          }
         
        });
    },

    getRoute: (context, item) => {
      console.log("this is the route id: ", item);
      //context.getRouteById(context, item);
          context.UPDATEROUTE = item;
    },

    getRouteById: (context, id) => {
      context.$store
        .dispatch("TA/getRouteById", {
          router: context.$router,
          id
        })
        .then(data => {
          console.log("Association Route by id: ", data);
          context.UPDATEROUTE.name = data[0]._name;
          context.UPDATEROUTE.description = data[0]._description;
          context.UPDATEROUTE.code = data[0]._code;
          context.UPDATEROUTE._nrc = data[0]._nrc;
          context.UPDATEROUTE._brc = data[0]._brc;
          context.UPDATEROUTE._origin = data[0]._origin;
          context.UPDATEROUTE._destination = data[0]._destination;
          context.UPDATEROUTE.taxifare = data[0]._taxifare;
          context.UPDATEROUTE.taxifare = data[0]._taxifare;
          context.routeid = data[0]._id;

        })
        .catch(error => {
          console.log("Association Route by id errors: ", error);
        });
    },

    addRoute: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          value.context.$wait.start("add-");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TA/addAssociationRoute", {
              router: value.router,
              AddModel: value.context.ADDROUTE
            })
            .then(result => {
              value.context.$wait.end("add-");
              value.context.$Notify.Success("Successfully Added!", "");
              $("#AddModal").modal("hide");
              value.context.ADDROUTE._name= "";
              value.context.ADDROUTE._description = "";
              value.context.ADDROUTE._taxifare = 0;
              value.context.ADDROUTE._code = "";
              value.context.ADDROUTE._origin = "";
              value.context.ADDROUTE._destination ="";
              value.context.ADDROUTE._has_load_fee = false;
              value.context.ADDROUTE._load_fee_billing_id = 0;
              value.context.ADDROUTE._national_route_code = "";
              value.context.ADDROUTE._board_route_code = "";
              value.context.getRoutesByAssId(value.context);
            })
            .catch(error => {
              value.context.$wait.end("add-");
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    updateRoute: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {

          value.item._updated_by = value.context.SYSTEMUSERID;
          
          value.context.$wait.start("update-");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TA/updateAssociationRoute", {
              router: value.router,
              id:value.item._id,
              updateModel: {
                _id: value.item._id,
                _name: value.item._name,
                _description: value.item._description,
                _taxifare: value.item._taxifare,
                _code: value.item._code,
                _origin: value.item._origin,
                _destination: value.item._destination,
                _has_load_fee: value.item._has_load_fee,
                _load_fee_billing_id: value.item._load_fee_billing_id,
                _national_route_code: value.item._national_route_code,
                _board_route_code: value.item._board_route_code,
                _updated_by: value.item._updated_by,
              }
            })
            .then(result => {
              value.context.$wait.end("update-");
              value.context.$Notify.Success("Updated Successfully!", "");
              $("#EditModal").modal("hide");
              value.context.getRoutesByAssId(value.context);
            })
            .catch(error => {
              value.context.$wait.end("update-");
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },

    deleteRoute: (context, id) => {
      context.TaxiAssociationRoutes = context.TaxiAssociationRoutes.filter(
        item => item.id !== id
      );
    }
  },
  mounted: function() {

    this.getRoutesByAssId(this.context);
    this.getBillingByAssId(this.context);
    
    App.init();
    //this.get(this.context)

    

    $(".btnadded")
      .mouseenter(function() {
        $(this)
          .removeClass("btn-success")
          .addClass("btn-danger")
          .text("Remove");
      })
      .mouseleave(function() {
        $(this)
          .removeClass("btn-danger")
          .addClass("btn-success")
          .text("Added");
      });
  }
};
</script>


<style scoped>
</style>

