<template>
  <div class="home; page-content-full-height page-sidebar-fixed page-header-fixed">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Emails'"
      :ActiveMenu="'Compose'"
    />
    <!-- end #sidebar -->
    <!-- begin page-header -->
    <headerpagebreadcrumb
      pageheaderTitle="Inbox"
      pageheaderdescription
      breadcrumbitem1="Home"
      breadcrumbitem2="Emails"
      breadcrumbitem3="Inbox"
    />
    <!-- end page-header -->

    <!-- begin #content -->
    <div id="content" class="content content-full-width content-full-height inbox">
      <!-- begin vertical-box -->
      <div class="vertical-box with-grid">
        <!-- begin vertical-box-column -->
        <div class="vertical-box-column width-200 bg-silver hidden-xs">
          <!-- begin vertical-box -->
          <div class="vertical-box">
            <!-- begin wrapper -->
            <div class="wrapper bg-silver text-center">
              <router-link to="/Home/Compose" class="btn btn-inverse p-l-40 p-r-40 btn-sm">Compose</router-link>
            </div>
            <!-- end wrapper -->
            <!-- begin vertical-box-row -->
            <div class="vertical-box-row">
              <!-- begin vertical-box-cell -->
              <div class="vertical-box-cell">
                <!-- begin vertical-box-inner-cell -->
                <div class="vertical-box-inner-cell">
                  <!-- begin scrollbar -->
                  <div data-scrollbar="true" data-height="100%">
                    <!-- begin wrapper -->
                    <div class="wrapper p-0">
                      <div class="nav-title">
                        <b>FOLDERS</b>
                      </div>
                      <ul class="nav nav-inbox">
                        <li class="active">
                          <router-link :to="{ name: 'inbox', params: {id: USERID } }">
                            <i class="fa fa-inbox fa-fw m-r-5"></i> Inbox
                            <span class="badge pull-right">{{GETTOTALUNREAD}}</span>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/sent">
                            <i class="fa fa-envelope fa-fw m-r-5"></i> Sent
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/trash">
                            <i class="fa fa-trash fa-fw m-r-5"></i> Trash
                          </router-link>
                        </li>
                      </ul>
                    </div>
                    <!-- end wrapper -->
                  </div>
                  <!-- end scrollbar -->
                </div>
                <!-- end vertical-box-inner-cell -->
              </div>
              <!-- end vertical-box-cell -->
            </div>
            <!-- end vertical-box-row -->
          </div>
          <!-- end vertical-box -->
        </div>
        <!-- end vertical-box-column -->
        <!-- begin vertical-box-column -->
        <div class="vertical-box-column bg-white">
          <!-- begin vertical-box -->
          <div class="vertical-box">
            <!-- begin wrapper -->
            <div class="wrapper bg-silver">
              <span class="btn-group m-r-5">
                <a href="javascript:;" class="btn btn-white btn-sm">
                  <i class="fa fa-envelope f-s-14 m-r-3 m-r-xs-0 t-plus-1"></i>
                  <span class="hidden-xs">Send</span>
                </a>
                <a href="#modal-message" class="btn btn-sm btn-primary" data-toggle="modal">
                  <i class="fa fa-paperclip f-s-14 m-r-3 m-r-xs-0 t-plus-1"></i>
                  <span class="hidden-xs">Attach</span>
                </a>
              </span>
              <span class="pull-right">
                <router-link to="/inbox" class="btn btn-white btn-sm">
                  <i class="fa fa-times f-s-14 m-r-3 m-r-xs-0 t-plus-1"></i>
                  <span class="hidden-xs">Discard</span>
                </router-link>
              </span>
            </div>
            <!-- end wrapper -->
            <!-- begin vertical-box-row -->
            <div class="vertical-box-row bg-white">
              <!-- begin vertical-box-cell -->
              <div class="vertical-box-cell">
                <!-- begin vertical-box-inner-cell -->
                <div class="vertical-box-inner-cell">
                  <!-- begin scrollbar -->
                  <div data-scrollbar="true" data-height="100%" class="p-15">
                    <!-- begin email form -->
                    <form action="/" method="POST" name="email_to_form">
                      <!-- begin email to -->
                      <div class>
                        <label class="control-label">To:</label>
                        <!-- begin form-group -->
                        <div class="date text-inverse col-md-12">
                          <select
                            class="default-selectUser width-full default-select form-control col-md-20"
                            data-live-search="true"
                            data-size="5"
                            multiple="multiple"
                          >
                            <option
                              v-for="item in GETSYSTEMUSERS"
                              :key="item._id"
                              :value="item._id"
                            >{{item._firstname}} {{item._surname}}</option>
                          </select>
                        </div>
                        <!-- end form-group -->
                      </div>
                      <!-- end email to -->

                      <!-- begin email subject -->
                      <div class="email-subject">
                        <input
                          type="text"
                          class="form-control form-control-lg"
                          placeholder="Subject"
                          id="subject"
                          v-model="COMPOSEMODEL.subject"
                        />
                      </div>
                      <!-- end email subject -->
                      <br />
                      <!-- begin row -->
                      <div class="row">
                        <!-- begin col-8 -->

                        <div class="col-md-12">
                          <div class="row panel panel-inverse">
                            <!-- begin col-8 -->
                            <div class="col-lg-6">
                              <!-- begin form-group -->
                              <div class="form-group row m-b-10">
                                <label class="col-md-0 col-form-label text-md-right">Select Drivers:</label>
                                <div class="date text-inverse col-md-8 pull-center">
                                  <select
                                    class="default-selectDriver default-select form-control col-md-7"
                                    data-size="5"
                                    data-live-search="true"
                                  >
                                    <option
                                      v-for="item in GETDRIVERSBYAFFILIATION"
                                      :key="item._taxi_drivers_id"
                                      :value="item._taxi_drivers_id"
                                    >{{item._firstname}} {{item._surname}} ({{item._id_number}})</option>
                                  </select>
                                </div>
                              </div>
                              <!-- end form-group -->
                            </div>
                            <!-- end col-8 -->

                            <div class="col-lg-6">
                              <!-- begin form-group -->
                              <div class="form-group row m-b-10">
                                <label
                                  class="col-md-0 col-form-label text-md-right"
                                >Select Operators:</label>
                                <div class="date text-inverse col-md-7 pull-up">
                                  <select
                                    class="default-selectOperator default-select form-control col-md-7"
                                    data-size="5"
                                    data-live-search="true"
                                  >
                                    <option
                                      v-for="item in GETOPERATORSBYAFFILLIATION"
                                      :key="item.taxi_operator_id"
                                      :value="item.taxi_operator_id"
                                    >{{item.firstname}} {{item.surname}} ({{item.id_number}}) ({{item.taxi_associations_abbr}})</option>
                                  </select>
                                </div>
                              </div>
                              <!-- end form-group -->
                            </div>
                            <div class="col-lg-6">
                              <!-- begin form-group -->
                              <div class="form-group row m-b-10">
                                <label
                                  class="col-md-0 col-form-label text-md-right"
                                >Select Vehicles:</label>
                                <div class="date text-inverse col-md-7 pull-up">
                                  <select
                                    class="default-selectTaxi default-select width-full form-control col-md-7"
                                    data-size="5"
                                    data-live-search="true"
                                  >
                                      <option
                                        :key="item._taxi_operator_vehicles_id"
                                        v-for="item in GETVEHICLESBYAFFILLIATION"
                                        :value="item._taxi_operator_vehicles_id"
                                      >{{item._licence_number}} ({{item._taxi_operators_name}})</option>
                                    
                                  </select>
                                </div>
                              </div>
                              <!-- end form-group -->
                            </div>
                            <!-- </div> -->
                          </div>
                        </div>
                        <!-- end col-8 -->
                      </div>
                      <!-- end row -->

                      <!-- begin email content -->
                      <div class="email-content p-t-15">
                        <textarea
                          id="wysihtml5"
                          class="textarea form-control"
                          placeholder="Enter text ..."
                          onfocus="this.innerHTML=''"
                          rows="15"
                        ></textarea>
                        <br />
                        <ul id="dropzonefiles" class="primary line-mode"></ul>
                      </div>
                      <!-- end email content -->
                    </form>
                    <!-- end email form -->
                    <div id="events_container"></div>
                  </div>
                  <!-- end scrollbar -->
                </div>
                <!-- end vertical-box-inner-cell -->
              </div>
              <!-- end vertical-box-cell -->
            </div>
            <!-- end vertical-box-row -->
            <!-- begin wrapper -->
            <div class="wrapper bg-silver text-right">
              <button type="submit" class="btn btn-white p-l-40 p-r-40 m-r-5">Discard</button>
              <button
                @click="composeEmail(context,COMPOSEMODEL)"
                class="btn btn-primary p-l-40 p-r-40"
              >Send</button>
            </div>
            
            <!-- end wrapper -->
          </div>
          <!-- end vertical-box -->
        </div>
        <!-- end vertical-box-column -->
      </div>
      <!-- end vertical-box -->

      <!-- begin scroll to top btn -->
      
      <!-- end scroll to top btn -->
    </div>
    <!-- end #content -->
    <!-- #modal-message -->
    <div class="modal modal-message fade" id="modal-message">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="text-center">
                    <h4 class="modal-title">Attach files</h4>
                  </div>
                  <div class="modal-body">
                    <p>Upload Documents</p>
                    <DropZoneControl
                      :acceptedFiles="acceptedFiles"
                      :ClearData="true"
                      v-on:fileadded="fileadded"
                      :SessionID="DropZoneSessionID"
                    ></DropZoneControl>
                    <br />
                    <div class="modal-footer">
                      <a
                        href="javascript:;"
                        class="btn btn-primary"
                        data-dismiss="modal"
                      >Save Changes</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <pagescroller />
  </div>
</template>


	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("messageinbox");
// value.context.$emit("init");

export default {
  name: "compose",
  data: function() {
    return {
      acceptedFiles: "application/pdf",
      GETSYSTEMUSERS: [],
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      GETDRIVERSBYAFFILIATION: [],
      GETOPERATORSBYAFFILLIATION: [],
      GETVEHICLESBYAFFILLIATION: [],
      affiliation_id: this.$store.getters["auth/USERPROFILE"].selected_taxi_association._taxi_affiliation_id,
      taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      GETALLDOCUMENTTYPE: this.$store.getters["lookups/GETALLDOCUMENTTYPE"],
      GETAllTAXIMODELS: this.$store.getters["TV/GETALLMODEL"],
      context: this,
      skip: 0,
      len: 10000,
      manualTrigger: false,
      DropZoneSessionID: this.$uuid.v4(),
      COMPOSEMODEL: {
        taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        taxi_driver_id: null,
        taxi_operator_id: null,
        taxi_vehicle_id: null,
        subject: "",
        messagecontent: "",
        sentby: this.$store.getters["auth/USERPROFILE"].system_usersid,
        receivedby: [],
        dropzonesessionid: ""
      },
      driver: {},
      taxi: {},
      operator: {}
    };
  },

  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    CapturePhotosControl
  },
  computed: {
    ...mapGetters([
      "GETTOTALUNREAD",
      "GETVEHICLEBYAFFILLIATION"
    ]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  beforeMount: function() {
    // this.clearDrivers(this.context);
    this.getSystemUsers(this.context);
  },

  watch: {},
  methods: {
    ...mapActions([
      "getall",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "searchSystemUsers"
    ]),

    getDriversbyid: context => {
      context.$store
        .dispatch("TaxiDrivers/getDriversByAffiliation", {
          id: context.affiliation_id,
          router: context.$router
        })
        .then(data => {
          console.log("these are the drivers: ", data);
          context.GETDRIVERSBYAFFILIATION = data;
          $(".default-selectDriver")
            .select2({
              allowClear: true,
              placeholder: "Select A Driver"
            })
            .on("select2:close", function(e) {
              $(".default-selectOperator")
                .val(null)
                .trigger("change");

              $(".default-selectTaxi")
                .val(null)
                .trigger("change");
            });
        })
        .catch(error => {
          console.log("drivers errors: ", error);
        });
    },
    getAllOperatorByTaxiAssId: context => {
      context.$store
        .dispatch("TO/getAllByTaxiAffiliationId", {
          router: context.$router,
          id: context.affiliation_id
        })
        .then(data => {
          console.log("operators found: ", data);
          context.GETOPERATORSBYAFFILLIATION = data;
          $(".default-selectOperator")
            .select2({
              placeholder: "Select A Operator",
              allowClear: true
            })
            .on("select2:close", function(e) {
              $(".default-selectDriver")
                .val(null)
                .trigger("change");

              $(".default-selectTaxi")
                .val(null)
                .trigger("change");
            });
        })
        .catch(error => {
          console.log("operator errors: ", error);
          context.$Notify.Error(error.data.message, "");
        });
    },

    getAllTaxiByTaxiAssId: context => {
      context.$store
        .dispatch("OperatorVehicle/getAllByTaxiAffilliationID", {
          router: context.$router,
          id: context.affiliation_id
        })
        .then(data => {
          console.log("These are the vehicles: ", data);
          context.GETVEHICLESBYAFFILLIATION = data;

          const taxi = $(".default-selectTaxi")
            .select2({
              multiple: false,
              placeholder: "Select A Taxi",
              allowClear: true
            })
            .on("select2:close", function(e) {
              $(".default-selectOperator")
                .val(null)
                .trigger("change");

              $(".default-selectDriver")
                .val(null)
                .trigger("change");
            });

          console.log(taxi);
        })
        .catch(error => {
          console.log("Vehicle errors: ", error);
          value.context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },

    getSystemUsers: context => {
      context.$store
        .dispatch("systemusers/getall", {
          router: context.$router
        })
        .then(data => {
          console.log("System users Loaded: ", data);

          context.GETSYSTEMUSERS = data;
        })
        .catch(error => {
          console.log(error);
          context.$Notify.Error(error, "");
        });
    },

    GETTAXISBYMODELID: function(context, id) {
      return context.GETVEHICLEBYAFFILLIATION.filter(
        item => item._vehiclemodel_id === id
      );
    },

    composeEmail: (context, addModel) => {
      
      if (
        $(".default-selectOperator").select2("data") != undefined &&
        $(".default-selectOperator").select2("data") != null &&
        $(".default-selectOperator").select2("data").length > 0
      ) {
        context.operator = $(".default-selectOperator").select2("data")[0];
      } else {
        context.operator = 0;
      }

      if (
        $(".default-selectDriver").select2("data") != undefined &&
        $(".default-selectDriver").select2("data") != null &&
        $(".default-selectDriver").select2("data").length > 0
      ) {
        context.driver = $(".default-selectDriver").select2("data")[0];
      } else {
        context.driver = 0;
      }

      if (
        $(".default-selectTaxi").select2("data") != undefined &&
        $(".default-selectTaxi").select2("data") != null &&
        $(".default-selectTaxi").select2("data").length > 0
      ) {
        context.taxi = $(".default-selectTaxi").select2("data")[0];
      } else {
        context.taxi = 0;
      }

      const systemusers = $(".default-selectUser")
        .select2("data")
        .map(item => {
          console.log("users: ", item);
          return item.id;
        });

      console.log(systemusers);

      context.COMPOSEMODEL.receivedby = [...systemusers];

      // context.COMPOSEMODEL.subject = document.getElementById("subject");

      context.COMPOSEMODEL.messagecontent = $("#wysihtml5").val();
      context.COMPOSEMODEL.taxi_operator_id = parseInt(context.operator.id);
      context.COMPOSEMODEL.taxi_driver_id = parseInt(context.driver.id);
      context.COMPOSEMODEL.taxi_vehicle_id = parseInt(context.taxi.id);

      addModel.dropzonesessionid = context.DropZoneSessionID;

      context.$Notify.YesNo(false, { context, addModel }).then(value => {
        
        if (value) {
          // value.context.$wait.start("add-" + value.id);
          value.router = value.context.$router;
          value.context
            .add({
              router: context.$router,
              addModel
            })
            .then(data => {
              console.log("these are the results: ", data);
              // value.context.$wait.end("add-" + id);
              value.context.$Notify.Success("Sent Successfully!", "");
            })
            .catch(error => {
              console.log("these are the errors: ", error);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    fileadded: function(data) {
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')
    },

    clearDrivers: function(context) {
      context.$store.dispatch("TaxiDrivers/clearDrivers", {}).then(() => {
        console.log("drivers cleared!");
      });
    }
    //   deletefile: (context, fileId, userID) => {
    //   context.$Notify.YesNo(false, { context, fileId, userID }).then(value => {
    //     if (value) {
    //       value.context.$wait.start("add");
    //       value.router = value.context.$router;
    //       value.context.$store
    //         .dispatch("fileupload/delete", {
    //           id: value.fileId,
    //           deletedby: value.userID
    //         })
    //         .then(() => {
    //           $("#AddModal").modal("hide");
    //           value.context.$wait.end("add");
    //           value.context.$Notify.Success("Successfully Deleted!", "");
    //         })
    //         .catch(error => {
    //           value.context.$wait.end("add");

    //           value.context.$Notify.Error(error.data[0].message, "");
    //         })
    //         .finally(() => {});
    //     }
    //   });
    // },
  },

  mounted: function() {
    $(".default-selectUser").select2({
      multiple: true,
      placeholder: "Select A System User",
      allowClear: true
    });

    $("#wysihtml5").wysihtml5();

    this.getDriversbyid(this.context);
    this.getAllOperatorByTaxiAssId(this.context);
    this.getAllTaxiByTaxiAssId(this.context);

    $(".default-selectDriver").select2({
      placeholder: "Select A Driver",
      allowClear: true
    });
    $(".default-selectOperator").select2({
      placeholder: "Select A Operator",
      allowClear: true
    });

    $(".default-selectTaxi").select2({
      placeholder: "Select A Vehicle",
      allowClear: true
    });

    App.init();
  },
  beforeDestroy: function() {
    this.clearDrivers(this.context);
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>