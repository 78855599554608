<template>
  <!-- begin invoice -->
  <div class="invoice">
    <!-- begin invoice-Logo -->
    <span class="pull-right">
      <img :src="encodedImage" class="width-160 height-40" />
    </span>
    <!-- begin invoice-Logo -->
    <!-- begin invoice-company -->
    <div class="invoice-company text-inverse f-w-600">
      <span class="pull-right hidden-print">
        <a
          href="javascript:;"
          @click="$router.back()"
          class="btn btn-lg btn-primary m-r-20 m-b-10 p-l-5"
        >
          <i class="fa fa-arrow-left t-plus-1 text-white fa-fw fa-lg"></i> Back
        </a>
        <a
          href="javascript:;"
          :disabled="$wait.is('generatestatement')"
          @click="generatetatement(context)"
          :class="{'btn btn-lg btn-success m-r-20 m-b-10 p-l-5': !$wait.is('generatestatement'),
            'btn btn-lg btn-grey m-b-10 p-l-5': $wait.is('generatestatement')
            }"
        >
          <i
            class="fa fa-history t-plus-1 text-white fa-fw fa-lg"
            v-show="!$wait.is('generatestatement')"
          ></i>

          <i
            class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
            v-show="$wait.is('generatestatement')"
          ></i>

          <span>Generate Statement</span>
        </a>
        <a
          href="javascript:;"
          v-if="statementGenerated"
          @click="print(context)"
          class="btn btn-lg btn-white m-r-20 m-b-10 p-l-5"
        >
          <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print Statement
        </a>
      </span>
      Statement Of Account
    </div>

      <div v-if="!dataloaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

    <!-- end invoice-company -->
    <!-- begin invoice-header -->
    <div class="invoice-header">
      <div class="invoice-from" :class="{showduringprintCss: showDuringPrint}">
        <small>Company</small>
        <address class="m-t-5 m-b-5">
          <strong class="text-inverse">{{SELECTEDTAXIASSOCIATION._name}}.</strong>
          <br />
          {{SELECTEDTAXIASSOCIATION._physicaladdress}}
          <br />
          {{SELECTEDTAXIASSOCIATION.physical_suburb}},
          {{SELECTEDTAXIASSOCIATION.physical_suburb_postalcode}}
          <br />
          Phone: {{SELECTEDTAXIASSOCIATION._telephone | HumanizeMobile}}
          <br />
          Cel: {{SELECTEDTAXIASSOCIATION._mobilenumber | HumanizeMobile}}
        </address>
      </div>
      <div class="invoice-to" :class="{showduringprintCss: showDuringPrint}">
        <small>To</small>
        <address class="m-t-5 m-b-5">
          <strong class="text-inverse">{{statement.name}}</strong>
          <br v-if="statement.name2" />
          {{statement.name2}}
          <br />
          {{statement.address}}
          <br />
          {{statement.suburb_name}},
          {{statement.postalcode}}
          <br />
          Cel: {{statement.mobilenumer | HumanizeMobile}}
        </address>
      </div>

      <div class="invoice-date" :class="{showduringprintCss: showDuringPrint}">
        <small>Statement Period</small>
        <div class="invoice-detail">
          From: {{ startDate }}
          <br />
          To: {{endDate}}
        </div>
      </div>

      <div class="row hidden-print">
        <div class="col-lg-6">
          <!-- begin card -->
          <div class="card text-center">
            <div class="card-header">
              <ul class="nav nav-tabs card-header-tabs">
                <li class="nav-item" v-show="SELECTEDTAXIASSOCIATION._create_vehicle_account">
                  <a class="nav-link active" data-toggle="tab" href="#card-tab-1">Taxis</a>
                </li>
                <li class="nav-item" v-show="SELECTEDTAXIASSOCIATION._create_operator_account">
                  <a class="nav-link" data-toggle="tab" href="#card-tab-2">Operators</a>
                </li>
                <li class="nav-item" v-show="SELECTEDTAXIASSOCIATION._create_driver_account">
                  <a class="nav-link" data-toggle="tab" href="#card-tab-3">Drivers</a>
                </li>
              </ul>
            </div>
            <div class="card-block">
              <div class="tab-content p-0 m-0">
                <div
                  class="tab-pane fade active show"
                  id="card-tab-1"
                  v-show="SELECTEDTAXIASSOCIATION._create_vehicle_account"
                >
                  <div class="invoice-to">
                    <small>
                      <select
                        class="default-selectTaxi default-select form-control"
                        data-size="5"
                        data-live-search="true"
                      >
                          <option
                            :key="item._taxi_operator_vehicles_id"
                            v-for="item in GETALLTAXIS"
                            :value="item._taxi_operator_vehicles_id"
                          >{{item._licence_plate}} ({{item._taxi_operators_name}}) Bal: R{{item._available_balance || 0 | currency}}</option>
                       
                      </select>
                    </small>
                    <address class="m-t-5 m-b-5">
                      <strong class="text-inverse"></strong>
                      <br />
                      <br />
                      <br />
                      <br />
                    </address>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="card-tab-2"
                  v-show="SELECTEDTAXIASSOCIATION._create_operator_account"
                >
                  <div class="invoice-to">
                    <small>
                      <select
                        class="default-selectOperator default-select form-control"
                        data-size="5"
                        data-live-search="true"
                      >
                        <option
                          v-for="item in GETALLOPERATORS"
                          :key="item.taxi_operator_id"
                          :value="item.taxi_operator_id"
                        >{{item.firstname}} {{item.surname}}({{item.id_number}}) Bal: R{{item._available_balance || 0 | currency}}</option>
                      </select>
                    </small>
                    <address class="m-t-5 m-b-5">
                      <strong class="text-inverse"></strong>
                      <br />
                      <br />
                      <br />
                      <br />
                    </address>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="card-tab-3"
                  v-show="SELECTEDTAXIASSOCIATION._create_driver_account"
                >
                  <div class="invoice-to">
                    <small>
                      <select
                        class="default-selectTaxiDrivers default-select form-control"
                        data-size="5"
                        data-live-search="true"
                      >
                        <option
                          v-for="item in GETAllTAXIDRIVERS"
                          :key="item._taxi_drivers_id"
                          :value="item._taxi_drivers_id"
                        >{{item._firstname}} {{item._surname}}({{item._id_number}}) Bal: R{{item._available_balance || 0 | currency}}</option>
                      </select>
                    </small>
                    <address class="m-t-5 m-b-5">
                      <strong class="text-inverse"></strong>
                      <br />
                      <br />
                      <br />
                      <br />
                    </address>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end card -->
        </div>
        <div class="col-lg-6">
          <div class="invoice-date">
            <small></small>
            <div class="date text-inverse m-t-10 width-full pull-right">
              Select Date:
              <div
                id="advance-daterange"
                class="width-full pull-right btn btn-default btn-block text-left f-s-12"
              >
                <i class="fa fa-caret-down pull-right m-t-2"></i>
                <span></span>
              </div>
            </div>

            <div class="invoice-detail">
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end invoice-header -->
    <!-- begin invoice-content -->
    <div class="invoice-content">
      <!-- begin table-responsive -->
      <div class="table-responsive">
        <table class="table table-invoice">
          <thead>
            <tr>
              <th width="10%">Date</th>
              <th width="10%">DESCRIPTION</th>
              <th>Ref</th>
              <th class="text-center" width="10%">CHARGE</th>
              <th class="text-center" width="10%">PAYMENT</th>
              <th class="text-right" width="15%">BALANCE</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="GENERATEDSTATEMENT.length > 0">
              <td>
                <span
                  class="text-inverse"
                >{{ GENERATEDSTATEMENT.length > 0 ? GENERATEDSTATEMENT[0]._transaction_date : '' | formatToShortDate }}</span>
                <br />
              </td>
              <td>
                <span class="text-inverse">Opening Balance</span>
                <br />
              </td>
              <td>
                <span class="text-inverse"></span>
                <br />
              </td>

              <td class="text-center"></td>
              <td class="text-center"></td>
              <td class="text-right">R{{GENERATEDSTATEMENT[0]._remaning_balance | currency}}</td>
            </tr>
            <tr v-for="(item) in GENERATEDSTATEMENT" v-bind:key="item._id">
              <td>
                <span class="text-inverse">{{item._transaction_date | formatToShortDate }}</span>
                <br />
              </td>
              <td>
                <span :style="{color: item._fk_receipts_id ? '#4CD964!important' : '#d62728!important'}" class="text-inverse">{{item.transaction_desc}}</span>
              </td>
              <td>
                <span class="text-inverse">
                  <a
                    href="#"
                    @click="printPayment(context,item._fk_receipts_id)"
                    v-if="item._fk_receipts_id"
                    data-toggle="tooltip"
                    :title="item._transaction_reference"
                  >{{item._transaction_reference}}</a>
                  <a
                    href="#"
                    @click="printInvoice(context,item._fk_invoice_id)"
                    v-if="item._fk_invoice_id"
                    data-toggle="tooltip"
                    :title="item._transaction_reference"
                  >{{item._transaction_reference}}</a>
                </span>
                <br />
              </td>

              <td class="text-center">
                {{ item._fk_invoice_id ? 'R': '' }}
                {{ item._fk_invoice_id ? item._transaction_amount : '' | currency}}
              </td>
              <td class="text-center">
                {{ item._fk_receipts_id ? 'R': '' }}
                {{ item._fk_receipts_id ? item._transaction_amount : '' | currency}}
              </td>
              <td class="text-right">R{{item._remaning_balance | currency}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- end table-responsive -->
      <!-- begin invoice-price -->
      <div class="invoice-price">
        <div class="invoice-price-left">
          <div class="invoice-price-row">
            <div class="sub-price">
              <small></small>
              <span class="text-inverse"></span>
            </div>

            <div class="sub-price">
              <small></small>
              <span class="text-inverse"></span>
            </div>
          </div>
        </div>
        <div class="invoice-price-right" style="white-space: pre">
          <small class>
            <b>AMOUNT DUE</b>
          </small>
          <span
            class="f-w-600"
          >R{{ GENERATEDSTATEMENT.length > 0 ? GENERATEDSTATEMENT[0]._latest_available_balance : '' | currency}}</span>
        </div>
      </div>
      <!-- end invoice-price -->
    </div>
    <!-- end invoice-content -->
    <!-- begin invoice-note -->
    <div class="invoice-note">
      * Make all cheques payable to {{SELECTEDTAXIASSOCIATION._name}}
      <br />* Payment is due within 30 days
      <br />
    </div>
    <!-- end invoice-note -->
    <!-- begin invoice-footer -->
    <div class="invoice-footer">
      <p class="text-center m-b-5 f-w-600">THANK YOU FOR YOUR BUSINESS</p>
      <p class="text-center">
        <span class="m-r-10">
          <i class="fa fa-fw fa-lg fa-phone-volume"></i>
          T:{{SELECTEDTAXIASSOCIATION._telephone | HumanizeMobile}}
        </span>
        <span class="m-r-10">
          <i class="fa fa-fw fa-lg fa-phone-volume"></i>
          M:{{SELECTEDTAXIASSOCIATION._mobilenumber | HumanizeMobile}}
        </span>
      </p>
    </div>
    <!-- end invoice-footer -->
  </div>
  <!-- end invoice -->
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("transactions");

export default {
  name: "StatementOfAccount",
  data: function() {
    return {
      context: this,
      id: this.$route.params.id,
      GETALLTAXIS: [],
      GETALLOPERATORS: this.$store.getters["TO/GETOPERATORSBYACCOUNTNO"],
      GETAllTAXIDRIVERS: this.$store.getters["TaxiDrivers/GETDRIVERSBYASSIDNOPIC"],
      GETAllTAXIMODELS: this.$store.getters["TV/GETALLMODEL"],
      taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      SELECTEDTAXIASSOCIATION: this.$store.getters[
        "auth/SELECTEDTAXIASSOCIATION"
      ],
      dataloaded: true,
      showDuringPrint: true,
      statementGenerated: false,
      encodedImage: undefined,
      startDate: "",
      endDate: "",
      statement: {
        name: "",
        name2: "",
        address: "",
        suburb_name: "",
        postalcode: "",
        mobilenumer: ""
      },
      statementInfo: [],
      GENERATEDSTATEMENT: [],
    };
  },
  beforeCreate: function() {},
  beforeMount: function() {
    this.clear(this.context);
    this.getAllTaxiByTaxiAssId(this.context, this.taxiassociation_id);
    this.getAllOperatorByTaxiAssId(this.context, this.taxiassociation_id);
    this.getAllTaxiDriversByTaxiAssId(this.context, this.taxiassociation_id);
  },
  components: {},

  computed: {
    ...mapGetters([""])
  },
  watch: {},
  methods: {
    ...mapActions([
      "generateDriverStatement",
      "generateOperatorStatement",
      "generateTaxiStatement",
      "clearData"
    ]),
    printPayment: function(context, receiptid) {
      let routeData = context.$router.resolve({
        name: "printpayment",
        params: { id: receiptid, preview: true }
      });
      window.open(routeData.href, "_blank");
    },
    printInvoice: function(context, invoice_id) {
      let routeData = context.$router.resolve({
        name: "printinvoice",
        params: { id: invoice_id, preview: true }
      });
      window.open(routeData.href, "_blank");
    },
    clear: context => {
      context.clearData();
    },
    generatetatement: context => {
      let _Operator = [];
      try {
        _Operator = $(".default-selectOperator").select2("data");
      } catch (e) {}
      let _Taxis = [];
      try {
        _Taxis = $(".default-selectTaxi").select2("data");
      } catch (e) {}
      let _Drivers = [];
      try {
        _Drivers = $(".default-selectTaxiDrivers").select2("data");
      } catch (e) {}

      if (
        _Operator.length === 0 &&
        _Taxis.length === 0 &&
        _Drivers.length === 0
      )
        return context.$Notify.Error(
          "Warning",
          "Please choose Operator, Taxi And Driver"
        );

      const Operators = [];
      const Taxis = [];
      const Drivers = [];

      _Drivers.forEach(function(_item, index) {
        Drivers.push(_item.id);
      });

      _Taxis.forEach(function(_item, index) {
        Taxis.push(_item.id);
      });

      _Operator.forEach(function(_item, index) {
        Operators.push(_item.id);
      });

      let payload = {};

      const router = context.$router;

      context.$wait.start("generatestatement");

      if (_Operator.length > 0) {
        if(context.startDate !== null && context.startDate !== "" && context.startDate !== undefined && context.endDate !== null && context.endDate !== "" && context.endDate !== undefined){
          payload = {
          taxiassociationsId: context.taxiassociation_id,
          taxioperatorId: parseInt(Operators[0]),
          startDate: context.$Toolkit.ConvertToServerDate(context.startDate),
          endDate: context.$Toolkit.ConvertToServerDate(context.endDate)
        };
        context
          .generateOperatorStatement({ router, payload })
          .then(parsedResultOP => {
            console.log("statements found: ",parsedResultOP);
            if (parsedResultOP.status) {
              if (parsedResultOP.data.length === 0) {
                context.$Notify.Error("No Information Found", "");
                context.statementGenerated = false;
              } else {
                context.GENERATEDSTATEMENT = parsedResultOP.data;
                context.statementGenerated = true;
                
              }
            }
            context.$wait.end("generatestatement");
          })
          .catch(error => {
            
            console.log("statement errors: ",error);
            context.$wait.end("generatestatement");
          });
        } else {
          context.$wait.end("generatestatement");
          return context.$Notify.Error("Please select a date range");
        }
        
      } else if (_Taxis.length > 0) {


        if(context.startDate !== null && context.startDate !== "" && context.startDate !== undefined && context.endDate !== null && context.endDate !== "" && context.endDate !== undefined){
        payload = {
          taxiassociationsId: context.taxiassociation_id,
          taxioperatorId: parseInt(Taxis[0]),
          startDate: context.$Toolkit.ConvertToServerDate(context.startDate),
          endDate: context.$Toolkit.ConvertToServerDate(context.endDate)
        };
        context
          .generateTaxiStatement({ router, payload })
          .then(parsedResultOP => {
            if (parsedResultOP.status) {
              if (parsedResultOP.data.length === 0) {
                context.$Notify.Error("No Information Found", "");
                context.statementGenerated = false;
              } else {
                
                context.GENERATEDSTATEMENT = parsedResultOP.data;
                context.statementGenerated = true;
              }
            }
            context.$wait.end("generatestatement");
          })
          .catch(error => {
            context.$wait.end("generatestatement");
          });
          } else {
          context.$wait.end("generatestatement");
          return context.$Notify.Error("Please select a date range");
        }
      } else if (_Drivers.length > 0) {
        if(context.startDate !== null && context.startDate !== "" && context.startDate !== undefined && context.endDate !== null && context.endDate !== "" && context.endDate !== undefined){
        payload = {
          taxiassociationsId: context.taxiassociation_id,
          taxioperatorId: parseInt(Drivers[0]),
          startDate: context.$Toolkit.ConvertToServerDate(context.startDate),
          endDate: context.$Toolkit.ConvertToServerDate(context.endDate)
        };
        context
          .generateDriverStatement({ router, payload })
          .then(parsedResultOP => {
            if (parsedResultOP.status) {
              if (parsedResultOP.data.length === 0) {
                context.$Notify.Error("No Information Found", "");
                context.statementGenerated = false;
              } else {
                context.GENERATEDSTATEMENT = parsedResultOP.data;
                context.statementGenerated = true;
              }
            }
            context.$wait.end("generatestatement");
          })
          .catch(error => {
            context.$wait.end("generatestatement");
          });
          } else {
          context.$wait.end("generatestatement");
          return context.$Notify.Error("Please select a date range");
        }
      }
    },
    print: context => {
      var randomnumber = Math.floor(Math.random() * 100 + 1);

      context.showDuringPrint = false;
      document.title = randomnumber;
      window.print();
      context.showDuringPrint = true;
      document.title = SELECTEDTAXIASSOCIATION._name;
    },
    updateClientInfo: (
      context,
      { name, name2, address, suburb_name, postalcode, mobilenumer }
    ) => {
      context.statement = {
        name,
        name2,
        address,
        suburb_name,
        postalcode,
        mobilenumer
      };
    },
    selectOperatorById: (context, id) => {
      const chosenOp = context.GETALLOPERATORS.filter(
        item => item.taxi_operator_id === id
      )[0];
      const selectedOp = {
        name: chosenOp.firstname + "" + chosenOp.surname,
        name2: undefined,
        address: chosenOp.physicaladdress,
        suburb_name: chosenOp.physical_suburb,
        postalcode: chosenOp.physical_suburb_postalcode,
        mobilenumer: chosenOp.mobilenumber
      };
      context.updateClientInfo(context, selectedOp);
    },
    selectDriverById: (context, id) => {
      const chosenOp = context.GETAllTAXIDRIVERS.filter(
        item => item._taxi_drivers_id === id
      )[0];
      const selectedOp = {
        name: chosenOp._firstname + "" + chosenOp._surname,
        name2: undefined,
        address: chosenOp._physicaladdress,
        suburb_name: chosenOp._physical_suburb,
        postalcode: chosenOp.physical_suburb_postalcode,
        mobilenumer: chosenOp._mobilenumber
      };
      context.updateClientInfo(context, selectedOp);
    },
    selectTaxiById: (context, id) => {
      const chosenOp = context.GETALLTAXIS.filter(
        item => item._taxi_operator_vehicles_id === id
      )[0];
      
      const selectedOp = {
        name: chosenOp._licence_plate,
        name2: chosenOp._taxi_operators_name,
        address: chosenOp.physicaladdress,
        suburb_name: chosenOp.physical_suburb,
        postalcode: chosenOp.physical_suburb_postalcode,
        mobilenumer: chosenOp._taxi_operators_mobilenumber
      };
      context.updateClientInfo(context, selectedOp);
    },
    getInvoiceDetailsId: (context, id) => {
      context.$store
        .dispatch("invoices/getById", {
          router: context.$router,
          id
        })
        .then(() => {})
        .catch(error => {
          value.context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },
    GETTAXISBYMODELID: function(context, id) {
      return context.GETALLTAXIS.filter(item => item._vehiclemodel_id === id);
    },
    getAllTaxiDriversByTaxiAssId: (context, id) => {
      context.$store
        .dispatch("TaxiDrivers/getAllByTaxiAssIdNoPic", {
          router: context.$router,
          id
        })
        .then(() => {
          $(".default-selectTaxiDrivers").select2({
            multiple: false,
            placeholder: "Select A Taxi",
            allowClear: false
          });

          $(".default-selectTaxiDrivers")
            .val(null)
            .trigger("change");
        })
        .catch(error => {
          value.context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },
    getAllTaxiByTaxiAssId: (context, id) => {
      context.dataloaded = false;
      context.$store
        .dispatch("OperatorVehicle/getAllByTaxiAssID", {
          router: context.$router,
          id
        })
        .then((data) => {
          context.GETALLTAXIS = data
          
        })
        .catch(error => {
          value.context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {
          $(".default-selectTaxi").select2({
            multiple: false,
            placeholder: "Select A Taxi",
            allowClear: false
          });

          $(".default-selectTaxi")
            .val(null)
            .trigger("change");

            context.dataloaded = true;
        });
    },
    getAllOperatorByTaxiAssId: (context, id) => {
      context.$store
        .dispatch("TO/getOperatorByAccountNo", {
          router: context.$router,
          id
        })
        .then((data) => {
          $(".default-selectOperator").select2({
            multiple: false,
            placeholder: "Select A Operator",
            allowClear: false
          });

          $(".default-selectOperator")
            .val(null)
            .trigger("change");
        })
        .catch(error => {
          context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    }
  },
  mounted: function() {
    App.init();
    $("#advance-daterange span").data("context", this.context);
    $("#advance-daterange span").html(
      moment()
        .subtract("days", 29)
        .format("MMMM D, YYYY") +
        " - " +
        moment().format("MMMM D, YYYY")
    );

    $("#advance-daterange").daterangepicker(
      {
        format: "MM/DD/YYYY",
        startDate: moment().subtract(29, "days"),
        endDate: moment(),
        minDate: "01/01/2010",
        maxDate: "12/31/2030",
        dateLimit: { days: 60 },
        showDropdowns: true,
        showWeekNumbers: true,
        timePicker: false,
        timePickerIncrement: 1,
        timePicker12Hour: true,
        ranges: {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days")
          ],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment()
              .subtract(1, "month")
              .startOf("month"),
            moment()
              .subtract(1, "month")
              .endOf("month")
          ]
        },
        opens: "right",
        drops: "down",
        buttonClasses: ["btn", "btn-sm"],
        applyClass: "btn-primary",
        cancelClass: "btn-default",
        separator: " to ",
        locale: {
          applyLabel: "Submit",
          cancelLabel: "Cancel",
          fromLabel: "From",
          toLabel: "To",
          customRangeLabel: "Custom",
          daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
          monthNames: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ],
          firstDay: 1
        }
      },
      function(start, end, label) {
        const context = $("#advance-daterange span").data("context");

        context.startDate = start.format("D MMMM YYYY");
        context.endDate = end.format("D MMMM YYYY");

        $("#advance-daterange span").html(
          start.format("D MMMM YYYY") + " - " + end.format("D MMMM YYYY")
        );
      }
    );

    

    this.startDate = $('#advance-daterange').data('daterangepicker').startDate.format("D MMMM YYYY");
    this.endDate = $('#advance-daterange').data('daterangepicker').endDate.format("D MMMM YYYY");

    $(".default-selectOperator").on("select2:select", function(e) {
      const context = $("#advance-daterange span").data("context");
      context.selectOperatorById(context, parseInt(e.target.value));
    });

    $(".default-selectTaxi").on("select2:select", function(e) {
      
      const context = $("#advance-daterange span").data("context");
      context.selectTaxiById(context, parseInt(e.target.value));
    });

    $(".default-selectTaxiDrivers").on("select2:select", function(e) {
      const context = $("#advance-daterange span").data("context");
      context.selectDriverById(context, parseInt(e.target.value));
    });

    $(".nav-link").click(() => {
      setTimeout(() => {
        $(".default-select").select2({
          multiple: false,
          allowClear: false
        });

        $(".default-selectOperator")
          .val(null)
          .trigger("change");
        $(".default-selectTaxi")
          .val(null)
          .trigger("change");
        $(".default-selectTaxiDrivers")
          .val(null)
          .trigger("change");
      }, 500);
    });

    $('[data-toggle="tooltip"]').tooltip();

    this.$store.dispatch("TA/getLOGOURL", {
      id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id
    }).then((result) => {
      this.context.encodedImage = result;
    }).catch((err) => {
    });
  }
};
</script>

<style scoped>
.showduringprintCss {
  display: none;
}
</style>

