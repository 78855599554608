<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Operators'"
      :ActiveMenu="'AddTaxiOperator'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Add Taxi Operator"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Quick Setup"
        breadcrumbitem3="Add Taxi Operator"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <div class="card text-center">
        <div
          v-if="isIdNumberValid == undefined"
          class="alert alert-primary fade show"
        >WAITING TO VERIFY ID NUMBER</div>
      </div>

      <div class="card text-center">
        <div
          v-if="!isIdNumberValid && isIdNumberValid != undefined "
          class="alert alert-danger fade show"
        >INVALID ID NUMBER!</div>
      </div>

      <div class="card text-center">
        <div v-if="isIdNumberValid" class="alert alert-success fade show">ID NUMBER VERFIED</div>
      </div>

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Operator Information
                      <small class="text-ellipsis">Name, Address, ID No and DOB</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-2">
                    <span class="number">2</span>
                    <span class="info text-ellipsis">
                      Capture Photo
                      <small class="text-ellipsis">Driver Photo</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-3">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Upload Supporting Documents
                      <small
                        class="text-ellipsis"
                      >ID document, Proof of Address,License ect.....</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-4">
                    <span class="number">4</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Complete Registration</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <!-- begin row -->
                  <div class="row">
                    <!-- begin col-8 -->

                    <div class="col-md-12">
                      <div class="row panel panel-primary">
                        <!-- begin col-8 -->
                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">ID Number</label>
                            <span class="text-danger">*</span>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-required="true"
                                data-parsley-maxlength="13"
                                id="usernameId"
                                placeholder="Enter ID Number"
                                :class="['form-control',{'is-invalid': isIdNumberExists , 'is-valid': !isIdNumberExists  }]"
                                v-model.lazy="IDNUMBER"
                              />
                              <div class="invalid-tooltip">Operator Exists.</div>
                              <div class="valid-tooltip">New Operator!</div>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <br />
                          <br />
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Foreign ID</label>
                            <div class="col-md-6">
                              <div class="switcher switcher-success">
                                <input
                                  type="checkbox"
                                  name="switcher_checkbox_hasoverdraft"
                                  id="switcher_checkbox_hasoverdraft"
                                  data-parsley-group="step-1"
                                  data-parsley-required="false"
                                  v-model="IsForeign"
                                />
                                <label for="switcher_checkbox_hasoverdraft"></label>
                              </div>
                            </div>
                          </div>
                          <br />
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">
                              RAS Reg No.
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                data-live-search="true"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDOPERATOR.ras_registration_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">
                              Title
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-min="1"
                                data-parsley-required="true"
                                v-model="ADDOPERATOR.title_id "
                              >
                                <option value="0" selected>Select a Title</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLTITLES"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">
                              Gender
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-min="1"
                                data-parsley-required="true"
                                v-model="ADDOPERATOR.gender_id"
                              >
                                <option value="0" selected>Select a Gender</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLGENDERS"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">
                              First Name
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                data-parsley-group="step-1"
                                data-live-search="true"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDOPERATOR.firstname"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">
                              Surname
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="surname"
                                data-parsley-group="step-1"
                                data-live-search="true"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDOPERATOR.surname"
                              />
                            </div>
                          </div>

                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Date Of Birth</label>
                            <span class="text-danger">*</span>
                            <div class="col-md-6">
                              <div
                                class="input-group date"
                                id="date_of_birth"
                                data-date-format="dd M yyyy"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Select Date"
                                  data-parsley-group="step-1"
                                  data-parsley-required="true"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Passport Number</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDOPERATOR.passport_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>
                        <!-- end col-8 -->

                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Country</label>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                data-parsley-min="1"
                                v-model="ADDOPERATOR.country_id"
                              >
                                <option value="0" selected>Select a Country</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLCOUNTRY"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Membership Number</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="lastname"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDOPERATOR.existing_membership_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Joined Date
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <div
                                class="input-group date"
                                id="taxi_operator_joined_date"
                                data-date-format="dd M yyyy"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Select Date"
                                  data-parsley-group="step-1"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Mobile Number
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                name="lastname"
                                id="masked-input-phone"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDOPERATOR.mobilenumber"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Telephone Number</label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                data-parsley-group="step-1"
                                class="form-control"
                                v-model="ADDOPERATOR.telephone_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Email</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="siyakolisis@gmail.com"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDOPERATOR.email_address"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- end form-group -->
                        </div>

                        <div class="col-lg-6">
                          <!-- begin col-6 -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Physical Address</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                class="form-control"
                                v-model="ADDOPERATOR.physical_address"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Physical Address 2</label>

                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                class="form-control"
                                v-model="ADDOPERATOR.physical_address2"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Suburb</label>
                            <div class="col-md-6">
                              <div class="input-group">
                                <span class="input-group-append">
                                  <select
                                    class="form-control width-250"
                                    data-size="20"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    id="selectPhysicalSuburb"
                                  >
                                    <option value="0">Select A Suburb</option>
                                    <option
                                      v-bind:key="item._id"
                                      v-for="item in GETALLSUBURBS"
                                      :value="item._id"
                                    >{{item._name}}</option>
                                  </select>
                                  <a
                                    href="javascript:;"
                                    data-toggle="modal"
                                    data-target="#AddModal"
                                    class="btn btn-sm btn-grey"
                                  >
                                    <i class="fa fa-plus fa-md m-t-5"></i>
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>

                        <div class="col-lg-6">
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Bank Name</label>
                            <span class="text-danger"></span>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="ADDOPERATOR.bankname_id"
                              >
                                <option value="0" selected>Select Bank Name</option>
                                <option
                                  v-bind:key="item.id"
                                  v-for="item in GETBANKS"
                                  :value="item.id"
                                >{{item.name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Account Type</label>
                            <span class="text-danger"></span>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="ADDOPERATOR.accounttype_id"
                              >
                                <option value="0" selected>Select Account Type</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETACCOUNTTYPE"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Bank Account Name</label>
                            <span class="text-danger"></span>
                            <div class="col-md-6">
                              <input
                                type="text"
                                class="form-control"
                                :value="ADDOPERATOR.firstname + ' ' + ADDOPERATOR.surname"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Bank Account Number</label>
                            <span class="text-danger"></span>
                            <div class="col-md-6">
                              <input
                                type="text"
                                class="form-control"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="ADDOPERATOR.account_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>
                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Postal Address</label>
                            <span class="text-danger"></span>
                            <div class="col-md-6">
                              <input
                                type="text"
                                class="form-control"
                                v-model="ADDOPERATOR.postal_address"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Postal Address 2</label>
                            <span class="text-danger"></span>
                            <div class="col-md-6">
                              <input
                                type="text"
                                class="form-control"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="ADDOPERATOR.postal_address2"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Suburb</label>
                            <div class="col-md-6">
                              <div class="input-group">
                                <span class="input-group-append">
                                  <select
                                    class="form-control width-250"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    id="selectPostalSuburb"
                                  >
                                    <option value="0">Select A Suburb</option>
                                    <option
                                      v-bind:key="item._id"
                                      v-for="item in GETALLSUBURBS"
                                      :value="item._id"
                                    >{{item._name}}</option>
                                  </select>
                                  <a
                                    href="javascript:;"
                                    data-toggle="modal"
                                    data-target="#AddModal"
                                    class="btn btn-sm btn-grey"
                                  >
                                    <i class="fa fa-plus fa-md m-t-5"></i>
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>
                        <br />
                        <div class="col-lg-9 p-t-20" v-if="id!=':id'">
                          <div id="gallery" class="gallery m-r-40 pull-right">
                            <!-- begin image -->
                            <div class="image width-full height-full">
                              <div class="image-inner">
                                
                                  <img :src="OPERATORIMAGE"
                                  class="width-full height-full">
                                
                              </div>
                            </div>
                            <!-- end image -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end col-8 -->
                  </div>
                  <!-- end row -->
                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->
                

                <!-- begin step-2 -->
                <div id="step-2">
                  <CapturePhotosControl
                    :SessionID="ADDOPERATOR.capturephotosessionid"
                    :ShowStopStartWebCamControl="false"
                    :CameraWidth="'40%'"
                    :ShowPreviewControl="true"
                    :CameraHeight="'40%'"
                    :ClearData="true"
                  />
                </div>
                <!-- end step-3 -->

                <!-- begin step-3 -->
                <div id="step-3">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->

                    <p class="text-center h4 mb-4">Upload Supporting Documents</p>
                    <div class="panel panel-primary" data-sortable-id="form-dropzone-1">
                      <div class="panel-heading">
                        <div class="panel-heading-btn"></div>
                        <h4 class="panel-title">Drop Files In Here</h4>
                      </div>
                      <div class="panel-body text-inverse">
                        <DropZoneControl
                          :acceptedFiles="acceptedFiles"
                          :ClearData="true"
                          v-on:fileadded="fileadded"
                          :SessionID="ADDOPERATOR.documentsessionid"
                        ></DropZoneControl>
                      </div>
                    </div>
                    <br />
                    <div class="panel panel-primary" data-sortable-id="table-basic-7">
                      <!-- begin panel-heading -->
                      <div class="panel-heading ui-sortable-handle">
                        <h4 class="panel-title">Uploaded Files</h4>
                      </div>
                      <!-- end panel-heading -->
                      <!-- begin panel-body -->
                      <div class="panel-body">
                        <!-- begin table-responsive -->
                        <div class="table-responsive">
                          <table class="table table-striped m-b-0">
                            <thead>
                              <tr>
                                <th>Filename</th>
                                <th>Mimetype</th>
                                <th>File ID</th>
                                <th width="40%">Document Type</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr :key="'docs-'+ index" v-for="(item,index) in GETUPLOADEDFILES">
                                <td>{{item.filename}}</td>
                                <td>{{item.mimetype}}</td>
                                <td>{{item.fileId}}</td>
                                <td>
                                  <select
                                    class="form-control col-sm-4"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-3"
                                    data-parsley-min="1"
                                    data-parsley-required="true"
                                    v-model="item.customType"
                                  >
                                    <option value="0" selected>Select Document Type</option>
                                    <option
                                      v-bind:key="item._id"
                                      v-for="item in GETALLDOCUMENTTYPE"
                                      :value="item._id"
                                    >{{item._name}}</option>
                                  </select>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end table-responsive -->
                      </div>
                      <!-- end panel-body -->
                      <!-- begin hljs-wrapper -->
                    </div>
                    <!--End dropzone!-->

                    <br />
                    <!--Start Table!-->

                    <!-- end row -->
                  </fieldset>
                </div>
                <!-- end step-3 -->

                <!-- begin step-4 -->
                <div id="step-4">
                  <div class="jumbotron m-b-0 text-center">
                    <h2 class="text-inverse">Taxi Operator Registered Successfully</h2>
                    <p
                      class="m-b-30 f-s-16 hide"
                    >Password has been sent to the New User via SMS, Please check your phone</p>
                    <p>
                      <router-link to="/Vehicle/Add/:id" class="btn btn-primary btn-lg">Add Vehicle</router-link>
                    </p>
                  </div>
                </div>
                <!-- end step-4 -->
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>

        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- Add Modal HTML START-->
                <div id="AddModal" class="modal fade">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <form>
                        <div class="modal-header">
                          <h4 class="modal-title">Add Suburb</h4>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                          >&times;</button>
                        </div>
                        <div class="modal-body">
                          <div class="col-md-12">
                            <!-- begin form-group row -->
                            <!-- begin form-group row -->
                            <div class="form-group row m-b-10">
                              <label class="col-md-3 text-md-right col-form-label">City</label>
                              <div class="col-md-9">
                                <select
                                  class="form-control"
                                  data-size="10"
                                  data-live-search="true"
                                  data-parsley-group="step-1"
                                  data-parsley-required="false"
                                  v-model="ADDSUBURB._city_id"
                                >
                                  <option value="0" selected>Select City</option>
                                  <option
                                    v-bind:key="item._id"
                                    v-for="item in CITIES"
                                    :value="item._id"
                                  >{{item._name}}</option>
                                </select>
                              </div>
                            </div>
                            <!-- end form-group row -->
                            <div class="form-group row m-b-10">
                              <label class="col-md-3 text-md-right col-form-label">Name</label>
                              <div class="col-md-9">
                                <input
                                  type="text"
                                  placeholder="Enter Name"
                                  class="form-control"
                                  v-model="ADDSUBURB._cityname"
                                />
                              </div>
                            </div>
                            <!-- end form-group row -->

                            <!-- begin form-group row -->
                            <div class="form-group row m-b-10">
                              <label class="col-md-3 text-md-right col-form-label">Description</label>
                              <div class="col-md-9">
                                <input
                                  type="text"
                                  placeholder="Enter Description"
                                  class="form-control"
                                  v-model="ADDSUBURB._description"
                                />
                              </div>
                            </div>
                            <!-- end form-group row -->
                            <!-- begin form-group row -->
                            <div class="form-group row m-b-10">
                              <label class="col-md-3 text-md-right col-form-label">Code</label>
                              <div class="col-md-9">
                                <input
                                  type="text"
                                  placeholder="Enter Code"
                                  class="form-control"
                                  v-model="ADDSUBURB._code"
                                />
                              </div>
                            </div>
                            <!-- end form-group row -->
                            <!-- begin form-group row -->
                            <div class="form-group row m-b-10">
                              <label class="col-md-3 text-md-right col-form-label">Postal Code</label>
                              <div class="col-md-9">
                                <input
                                  type="text"
                                  placeholder="Enter Postal Code"
                                  class="form-control"
                                  v-model="ADDSUBURB._postalcode"
                                />
                              </div>
                            </div>
                            <!-- end form-group row -->
                          </div>
                        </div>
                        <div class="modal-footer">
                          <input
                            type="button"
                            class="btn btn-default"
                            data-dismiss="modal"
                            value="Cancel"
                          />
                          <button
                            type="button"
                            class="btn btn-sm btn-success width-80"
                            @click="addNewSuburb(context,ADDSUBURB)"
                            :disabled="$wait.is('add+')"
                          >
                            <v-wait v-bind:for="'add+'">
                              <template slot="waiting">
                                <i class="fas fa-spinner fa-spin"></i>
                              </template>
                              Add
                            </v-wait>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <!-- Add Modal HTML START-->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import draggable from "vuedraggable";
const saIdParser = require("south-african-id-parser");

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TO");

export default {
  name: "ADDOPERATOR",
  data: function () {
    return {
      acceptedFiles: "application/pdf",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      GETALLTITLES:[],
      GETALLGENDERS: [],
      GETALLSUBURBS: [],
      status: true,
      skip: 0,
      len: 1000,
      GETALLCOUNTRY: [],
      GETALLCITY:[],
      GETUPLOADEDFILES: [],
      GETALLDOCUMENTTYPE: [],
      GETALLPROVINCES: [],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      id: this.$route.params.id,
      selectedTitleId: undefined,
      selectedGenderId: undefined,
      selectedUserGroupId: undefined,
      CITIES: [],
      IsForeign: false,
      ADDSUBURB: {
        _city_id: null,
        _cityname: "",
        _description: "",
        _code: "",
        _postalcode: "",
        _added_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      isIdNumberExists: true,
      isIdNumberValid: undefined,
      IDNUMBER: "",
      ADDOPERATOR: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        title_id: null,
        firstname: "",
        surname: "",
        id_number: "",
        date_of_birth: "",
        passport_number: "",
        physical_address: "",
        physical_address2: "",
        physical_suburb_id: null,
        postal_address: "",
        postal_address2: "",
        postal_suburb_id: null,
        country_id: null,
        mobilenumber: "",
        telephone_number: "",
        email_address: "",
        fax_number: null,
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
        gender_id: null,
        existing_membership_number: "",
        taxi_operator_joined_date: null,
        capturephotosessionid: this.$uuid.v4(),
        documentsessionid: this.$uuid.v4(),
        dropzonefiles: [
          {
            customType: 0,
            fileId: "",
          },
        ],
        bankname_id: null,
        accounttype_id: null,
        account_name: "",
        account_number: "",
        ras_registration_number: "",
       
      },
      GETBANKS: [], 
      GETACCOUNTTYPE: [],
      hacksmartwizard: false,
      OPERATORIMAGE: null,
      DATACOLLECTION: this.$store.getters["OperatorVehicle/GETDATACOLLECTED"],
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  beforeMount: () => {
    // this.loadCities(this.context);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    CapturePhotosControl,
    DropZoneControl,
    draggable,
  },
  computed: {
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  watch: {
    IDNUMBER: function (val) {
      if (this.IsForeign == true) {
        if (val.toString().length > 11) {
          this.context.$store
              .dispatch("TO/getSearchOperatorIDnumber", {
                _getPagingModel: {
                  id: this.taxiassociationid,
                  text: val,
                },
                router: this.$router,
              })
              .then((rb) => {
                this.isIdNumberExists = false;
              })
            .catch((err) => {
              console.log("validation err: ", err);
              this.isIdNumberExists = false
            });
        }
      } else {
        if (val.toString().length > 11) {
          if (saIdParser.validate(val)) {
            this.isIdNumberValid = true;
            //console.log(val)
            //this.context.$Notify.Success("ID Verified Successfully", "");
            this.context.$store
              .dispatch("TO/getSearchOperatorIDnumber", {
                _getPagingModel: {
                  id: this.taxiassociationid,
                  text: val,
                },
                router: this.$router,
              })
              .then((rb) => {
                this.isIdNumberExists = rb.data[0].fn_taxi_operatorexists;
              });
          } else {
            this.isIdNumberValid = false;
            //this.isIdNumberExists = true;
            this.context.$Notify.Error("Not A Valid SA ID.");
          }
        }
      }
    },
  },
  methods: {
    ...mapActions(["loadcitybyprovince", "add"]),
    addTaxiOperator: (context, item) => {
      const dropzone_uploads = context.GETUPLOADEDFILES;
      const fileuploaded = [];

      if (dropzone_uploads.length > 0) {
        var failedvalidation = false;
        dropzone_uploads.forEach((element) => {
          if (element.customType === 0) {
            failedvalidation = true;
            return;
          }
          fileuploaded.push({
            customType: Number(element.customType),
            fileId: element.fileId,
          });
        });
        if (failedvalidation) {
          value.context.$Notify.Error("Please Select Document Type.", "");
          return;
        }
      }

      item.dropzonefiles = fileuploaded;

      item.account_name =
        context.ADDOPERATOR.firstname + " " + context.ADDOPERATOR.surname;

      item.postal_suburb_id = parseInt($("#selectPostalSuburb").val());
      item.physical_suburb_id = parseInt($("#selectPhysicalSuburb").val());

      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          $(".sw-btn-next")
            .text("")
            .append('<i class="fas fa-spinner fa-spin"></i>')
            .attr("disabled", "disabled");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TO/add", {
              _dataObj: value,
            })
            .then(() => {
              console.log("added results vue page: ");

              // value.context.hacksmartwizard = true;
              // $("#wizard").smartWizard("next");
              // $(".sw-btn-next")
              //   .text("Done")
              //   .attr("disabled", "disabled");

              $(".sw-btn-next").text("Done").attr("disabled", "disabled");
              $(".sw-btn-prev").attr("disabled", "disabled");
              $("#wizard").data("taxioperatoradded", true);
              $("#wizard").smartWizard("next");
              value.context.$Notify.Success("Successfully Added!", "");
            })
            .catch((error) => {
              value.context.$wait.end("add");
              $(".sw-btn-next").removeAttr("disabled");
              console.log("these are the add operator errors: ", error);
              value.context.$Notify.Error(error.data.message);
              if (Array.isArray(error.data))
                value.context.$Notify.Error(error.data[0].message, "");
              else value.context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        }
      });
    },
    fileadded: (data) => {
      console.log("Header:fileadded");
      console.log(data);
      // this.$emit('init')
      const context = $("#wizard").data("context");
      context.GETUPLOADEDFILES.push({
        filename: data.name,
        mimetype: data.type,
        customType: data.customType,
        fileId: data.fileId,
      });
    },
    deletefile: (context, fileId, userID) => {
      context.$Notify
        .YesNo(false, { context, fileId, userID })
        .then((value) => {
          if (value) {
            value.context.$wait.start("add");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("fileupload/delete", {
                id: value.fileId,
                deletedby: value.userID,
              })
              .then(() => {
                $("#AddModal").modal("hide");
                value.context.$wait.end("add");
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch((error) => {
                value.context.$wait.end("add");

                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },

    addNewSuburb: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          value.context.$wait.start("add+");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("suburb/add", {
              _dataObj: {
                item: value.item,
                router: value.router,
              },
            })
            .then((data) => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add+");
              console.log("suburb added: ", data);
              value.context.$Notify.Success("Successfully Added!", "");
              context.loadSuburbs(context);
            })
            .catch((error) => {
              value.context.$wait.end("add+");

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    loadOperatorImage(context) {
      if (context.id != ":id")
      {
        
        context.OPERATORIMAGE = context.DATACOLLECTION.filter(el => el.datacollection_id == context.id)[0].operatorcarddisk_image[0].data;
      }
    },

    loadCities(context) {
      context.$store
        .dispatch("city/getall", {
          _dataObj: {
            router: context.$router,
          },
        })
        .then((data) => {
          console.log("cities found:", data);
          context.CITIES = data;
        })
        .catch((error) => {
          console.log("cities errors:", error);
        })
        .finally(() => {});
    },

    loadBanks(context) {
      context.$store
        .dispatch("TO/getAllBanks", {
          router: context.$router,
        })
        .then((data) => {
          console.log("banks found: ", data);
          context.GETBANKS = data; 
        })
        .catch((error) => {
          console.log("load banks error:", error);
        })
        .finally(() => {});
    },

    loadAccountType(context) {
      context.$store
        .dispatch("TO/getAccountTypes", {
          router: context.$router,
        })
        .then((data) => {
          console.log("account type found: ", data);
          context.GETACCOUNTTYPE = data;
        })
        .catch((error) => {
          console.log("load account types error:", error);
          
        })
        .finally(() => {});
    },

    loadSuburbs(context) {
      context.$store
        .dispatch("TO/getSuburbs", {
          router: context.$router,
          status: context.status,
          skip: context.skip,
          len: context.len,
        })
        .then((data) => {
          console.log("suburbs found: ", data);

          context.GETALLSUBURBS = data;
        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
    loadLookUps(context) {
      
      context.$store
        .dispatch("lookups/init", {
          router: context.$router,
        })
        .then((data) => {
          console.log("loadLookUps found: ", data);
          
          context.GETALLDOCUMENTTYPE = data.LOADEDDOCUMENTTYPE;
          context.GETALLCOUNTRY = data.LOADEDCOUNTRIES;
          context.GETALLGENDERS = data.LOADEDGENDER;
          context.GETALLTITLES = data.LOADEDTITLES;
        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
  },
  mounted: function () {
    // $("#selectCity").select2({
    //   multiple: false,
    //   placeholder: "Select a City",
    //   allowClear: true
    // });

    this.loadLookUps(this.context);


    $("#selectPhysicalSuburb").select2({
      multiple: false,
      placeholder: "Select a suburb",
      allowClear: false,
    });

    $("#selectPostalSuburb").select2({
      multiple: false,
      placeholder: "Select a suburb",
      allowClear: false,
    });

    App.init();
    

    this.loadOperatorImage(this.context);
    this.loadCities(this.context);
    this.loadBanks(this.context);
    this.loadAccountType(this.context);
    this.loadSuburbs(this.context);

    $("#wizard").data("taxioperatoradded", false);

    //$('.selectpicker1').selectpicker('render');
    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "",
      keyNavigation: false,
      transitionSpeed: 0,
      cycleSteps: true,
      useURLhash: false,
      showStepURLhash: false,
      toolbarSettings: {
        toolbarPosition: "bottom",
      },
    });

    $("#wizard").data("context", this.context);
    $("#wizard").on("leaveStep", function (
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {
      const context = $("#wizard").data("context");
      const currentstep = stepNumber + 1;
      const res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

      if (context.hacksmartwizard) return true;

      $(".sw-btn-next").text("Next");

      if (stepDirection == "backward") return true;

      if (currentstep === 1 && stepDirection == "forward") {
        if (context.isIdNumberExists) return false;
        else {
          context.ADDOPERATOR.taxi_operator_joined_date = context.$Toolkit.ConvertToServerDate(
            $("#taxi_operator_joined_date").datepicker("getDate")
          );

          context.ADDOPERATOR.date_of_birth = context.$Toolkit.ConvertToServerDate(
            $("#date_of_birth").datepicker("getDate")
          );

          if (!context.ADDOPERATOR.taxi_operator_joined_date) {
            context.$Notify.Error("Please Enter Joined Date", "");
            return false;
          }

          if (!context.ADDOPERATOR.date_of_birth) {
            context.$Notify.Error("Please Enter Date Of Birth Date", "");
            return false;
          }
        }
      }

      if (currentstep === 2 && stepDirection == "forward") {
        if (context.$store.getters["capturephotos/GETALL"].length > 0) {
          if (context.$store.getters["capturephotos/GETALL"].length < 2) {
            context.$Notify.Error("Please Add More Photos", "");
            return false;
          }
        }

        $(".sw-btn-next").text("Submit");
      }

      if (currentstep === 3 && stepDirection == "forward") {
        const taxioperatoradded = $("#wizard").data("taxioperatoradded");

        context.ADDOPERATOR.id_number = context.IDNUMBER;

        if (!taxioperatoradded) {
          context.addTaxiOperator(context, context.ADDOPERATOR);
        }
        return taxioperatoradded;
      }

      if (currentstep === 4 && stepDirection == "forward") {
        if (!res) return res;

        console.log("we are in step 4");
        return false;
      }

      return res;
    });

    var date = new Date();
    date.setDate(date.getDate() - 43000);
    console.log(date);

    $("#taxi_operator_joined_date").datepicker({
      todayHighlight: true,
      autoclose: true,
      startDate: date,
    });

    $("#date_of_birth").datepicker({
      todayHighlight: false,
      autoclose: true,
      startDate: date,
    });

    function calculateDivider() {
      var dividerValue = 4;
      if ($(this).width() <= 480) {
        dividerValue = 1;
      } else if ($(this).width() <= 767) {
        dividerValue = 2;
      } else if ($(this).width() <= 980) {
        dividerValue = 3;
      }
      return dividerValue;
    }

    var handleIsotopesGallery = function () {
      var container = $("#gallery");
      var dividerValue = calculateDivider();
      var containerWidth = $(container).width();
      var columnWidth = containerWidth / dividerValue;
      $(container).isotope({
        resizable: true,
        masonry: {
          columnWidth: columnWidth,
        },
      });

      $(window).smartresize(function () {
        var dividerValue = calculateDivider();
        var containerWidth = $(container).width();
        var columnWidth = containerWidth / dividerValue;
        $(container).isotope({
          masonry: {
            columnWidth: columnWidth,
          },
        });
      });

      var $optionSets = $("#options .gallery-option-set"),
        $optionLinks = $optionSets.find("a");

      $optionLinks.click(function () {
        var $this = $(this);
        if ($this.hasClass("active")) {
          return false;
        }
        var $optionSet = $this.parents(".gallery-option-set");
        $optionSet.find(".active").removeClass("active");
        $this.addClass("active");

        var options = {};
        var key = $optionSet.attr("data-option-key");
        var value = $this.attr("data-option-value");
        value = value === "false" ? false : value;
        options[key] = value;
        $(container).isotope(options);
        return false;
      });
    };

    // $("#masked-input-phone").mask("(082) 686-9833");

    //FormPlugins.init();
    //Highlight.init();

    //     $(function(){
    //    $(".dropdown-menu").on('click', 'a', function(){
    //        $(this).parents('.dropdown').find('button').text($(this).text());
    //    })
    // });
  },
};
</script>

	

<style scoped>
</style>