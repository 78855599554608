/* eslint-disable no- */
export default {
  // options...
  verifytoken: (router, response) => {
    if (response.data.auth !== undefined && !response.data.auth) {
      router.push('/');
      return false;
    } return true;
  },
};
