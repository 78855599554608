<template>
  <div class="home">
    <!-- begin #page-container -->

    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageDrivers'"
      :ActiveMenu="'Profile'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Taxi Driver Profile"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Driver"
        breadcrumbitem3="Disputes"
      />
      <!-- end page-header -->
      <!-- begin invoice-header -->
      <div class="invoice-header">
        <div class="invoice-from">
          <h1 class="text-center">Invoice details</h1>
          <address class="m-t-5 m-b-5">
            <span class="navbar-logo pull-right">
              <i>
                <img
                  :src="`data:image/png;base64,${associationimage != undefined ? associationimage.logo.data : '' }`"
                  class="width-170 height-50 m-l-40"
                />
              </i>
              <h6>
                <b>{{ associationimage != undefined ? associationimage._abbriviated_name : ''}}</b>
              </h6>
            </span>

            <small>Added by:</small>
            <br />
            <strong class="text-inverse">{{DRIVERRECEIPT[0]._added_by_name}}</strong>
            <br />Transaction date:
            <strong
              class="text-inverse"
            >{{DRIVERRECEIPT[0]._transaction_date | formatToHumanize}}</strong>
            <br />
          </address>
          <div class="text-center">
            <small>&nbsp;</small>
            <address class="m-t-5 m-b-5">
              <strong class="text-inverse">reference:</strong>
              <br />
              {{DRIVERRECEIPT[0]._transaction_reference}}
              <br />

              <br />
            </address>
          </div>
          <!-- begin invoice-price -->
          <div class="invoice-price">
            <div class="invoice-price-left">
              <div class="invoice-price-row">
                <div class="sub-price">
                  <small>TRANSACTION AMOUNT</small>
                  <span class="text-inverse">R{{DRIVERRECEIPT[0]._transaction_amount | currency}}</span>
                </div>
              </div>
            </div>
            <div class="invoice-price-right">
              <small>BALANCE</small>
              <span class="f-w-600">R{{DRIVERRECEIPT[0]._remaning_balance | currency}}</span>
            </div>
          </div>
          <!-- end invoice-price -->
          <!-- begin invoice-note -->
          <div class="invoice-note">
            <div class="form-group row">
              <label class="col-md-1 col-form-label">Comment:</label>
              <div class="col-md-5">
                <textarea class="form-control m-b-10" id="clipboard-textarea" rows="5"></textarea>
              </div>
            </div>
            <br />
          </div>
          <div class="alert alert-white fade show">
            <div class="text-center">
              <button
                type="button"
                class="btn btn-outline-success width-120 m-r-2"
                data-toggle="modal"
                data-target="#modal-message"
              >Upload</button>
              ​
              <button
                type="button"
                class="btn btn-outline-primary width-120 m-r-2"
              >Submit</button>
            </div>
          </div>
          <!-- #modal-message -->
          <div class="modal modal-message fade" id="modal-message">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="text-center">
                  <h4 class="modal-title">Supporting Documents</h4>
                </div>
                <div class="modal-body">
                  <div
                    class="panel panel-inverse panel-with-tabs"
                    data-sortable-id="ui-unlimited-tabs-1"
                  >
                    <!-- begin panel-heading -->
                    <div class="panel-heading p-0">
                      <div class="panel-heading-btn m-r-10 m-t-10">
                        <a
                          href="javascript:;"
                          class="btn btn-xs btn-icon btn-circle btn-success"
                          data-click="panel-expand"
                        >
                          <i class="fa fa-expand"></i>
                        </a>
                      </div>
                      <!-- begin nav-tabs -->
                      <div class="tab-overflow">
                        <ul class="nav nav-tabs nav-tabs-inverse">
                          <li class="nav-item">
                            <a
                              href="#documents"
                              data-toggle="tab"
                              class="nav-link active"
                            >Supporting Documents</a>
                          </li>
                          <li class="nav-item">
                            <a href="#Voice Recordings" data-toggle="tab" class="nav-link">Voice Recordings</a>
                          </li>
                        </ul>
                      </div>
                      <!-- end nav-tabs -->
                    </div>
                    <!-- end panel-heading -->
                    <!-- begin tab-content -->
                    <div class="tab-content">
                      <!-- begin tab-pane -->
                      <div class="tab-pane fade active show" id="documents">
                        <h3 class="m-t-10">Upload Documents</h3>
                        <!-- dropzone div -->
                        <DropZoneControl
                          :acceptedFiles="acceptedFiles"
                          :ClearData="true"
                          v-on:fileadded="fileadded"
                          :SessionID="DropZoneSessionID"
                        ></DropZoneControl>
                        <br />
                        <!-- dropzone div -->
                      </div>
                      <!-- end tab-pane -->
                      <!-- begin tab-pane -->
                      <div class="tab-pane fade" id="Voice Recordings">
                        <h3 class="m-t-10">Nav Tab 2</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                          Integer ac dui eu felis hendrerit lobortis. Phasellus elementum, nibh eget adipiscing porttitor,
                          est diam sagittis orci, a ornare nisi quam elementum tortor.
                          Proin interdum ante porta est convallis dapibus dictum in nibh.
                          Aenean quis massa congue metus mollis fermentum eget et tellus.
                          Aenean tincidunt, mauris ut dignissim lacinia, nisi urna consectetur sapien,
                          nec eleifend orci eros id lectus.
                        </p>
                        <p>
                          Aenean eget odio eu justo mollis consectetur non quis enim.
                          Vivamus interdum quam tortor, et sollicitudin quam pulvinar sit amet.
                          Donec facilisis auctor lorem, quis mollis metus dapibus nec. Donec interdum tellus vel mauris vehicula,
                          at ultrices ex gravida. Maecenas at elit tincidunt, vulputate augue vitae, vulputate neque.
                          Aenean vel quam ligula. Etiam faucibus aliquam odio eget condimentum.
                          Cras lobortis, orci nec eleifend ultrices, orci elit pellentesque ex, eu sodales felis urna nec erat.
                          Fusce lacus est, congue quis nisi quis, sodales volutpat lorem.
                        </p>
                      </div>
                      <!-- end tab-pane -->
                    </div>
                    <!-- end tab-content -->
                  </div>
                  <!-- end panel -->
                </div>
                <div class="modal-footer">
                  <a href="javascript:;" class="btn btn-primary" data-dismiss="modal">Save Changes</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end invoice-header -->
    </div>
  </div>
</template>


	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import headerComp from "@/components/common/header.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import pageloader from "@/components/common/pageloader.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";

import { createNamespacedHelpers } from "vuex";
import { setTimeout } from "timers";
const { mapGetters, mapActions } = createNamespacedHelpers("TaxiDrivers");

export default {
  name: "disputes",
  data: function() {
    return {
      context: this,
      detailsid: this.$route.params.id,
      acceptedFiles: "application/pdf",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      associationimage: this.$store.getters["auth/SELECTEDTAXIASSOCIATION"],
      DropZoneSessionID: this.$uuid.v4(),
      dropzone_files: [
        {
          customType: 0,
          fileId: ""
        }
      ]
    };
  },
  beforeMount: function() {
    this.getbyid(this.context, this.taxiassociationid, this.detailsid);
    //this.driverAccount(this.context, this.id);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    DropZoneControl,
    pageloader
  },

  computed: {
    ...mapGetters({
      DRIVERRECEIPT: "GETDRIVERRECIEPT"
    }),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions([
      "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted",
      "editDriver"
    ]),

    getbyid: (context, taxiassociationid, detailsid) => {
      
      context.$store
        .dispatch("TaxiDrivers/getDriverReceiptById", {
          taxiassociationid: taxiassociationid,
          receiptsid: detailsid,
          router: context.$router
        })
        .then(() => {
          App.init();
        });
    },
    // getall: context => {
    //   context.$store
    //     .dispatch("TV/getAllByTaxiAssID", {
    //       _dataObj: {
    //         router: context.$router
    //       }
    //     })
    //     .then(() => {});
    // },
    // changevehicle: (context, id) => {
    //   context.SELECTEDVEHICLES = context.GETVEHICLE.filter(
    //     item => item.taxi_operator_vehicles_id === Number(id)
    //   )[0];
    // },
    fileadded: function(data) {
      
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')

      const context = $("#content").data("context");
      context.GETUPLOADEDFILES = context.$store.getters["fileupload/GETALL"];
    },
    addReceiptDispute: (
      context,
      invoiceid,
      invoicestatus,
      comments,
      voicenote,
      media
    ) => {
      context.$Notify
        .YesNo(false, {
          context,
          invoiceid,
          invoicestatus,
          comments,
          voicenote,
          media
        })
        .then(value => {
          

          if (value) {
            
            value.context.$wait.start("add");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("TaxiDrivers/updateDriverSupportingDocs", {
                router: value.router,
                _addDispute: {
                  taxi_association_id: context.taxiassociationid,
                  invoiceid,
                  invoicestatus,
                  comments,
                  voicenote,
                  media,
                  addedby: context.updatedby
                }
              })
              .then(() => {
                value.context.$wait.end("add");
                value.context.$Notify.Success("Documents Uploaded!", "");
              })
              .catch(error => {
                value.context.$wait.end("add");
                
                value.context.$Notify.Error(error.data[0].message, "");
                
              })
              .finally(() => {});
          }
        });
    },

    onclick: function() {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(handleSuccess)
        .catch(handleError);
    },
    videoonclick: function() {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext("2d").drawImage(video, 0, 0);
      // Other browsers will fall back to image/png
      img.src = canvas.toDataURL("image/webp");
    },
    handleSuccess(stream) {
      screenshotButton.disabled = false;
      video.srcObject = stream;
    }
  },
  mounted: function() {}
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>