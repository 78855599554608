<template>
    <li class="nav-profile">
            <a href="javascript:;" data-toggle="nav-profile">
              <div class="cover with-shadow"></div>
              <div class="image">
                <img v-if="PHOTOURL" :src="PHOTOURL" alt>
              </div>
              <div class="info">
                <b class="caret"></b>
                {{USERPROFILE.first_name + ' ' + USERPROFILE.surname}} 
                <small>{{USERPROFILE.usergroups_name}} </small>
              </div>
            </a>
          </li>
</template>

<script>

import configbase from '../../configurations/configbase';
import config from '../../configurations/config';
import format from 'string-format';
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("systemusers");

export default {
   name: "navprofileComp",
   props:['USERPROFILE'],
   data: function() {
    return {
      message: "Some Message",
      PHOTOURL: undefined,
    };
  },
  components: {
  },
  computed: {
    
  },
  methods: {
    ...mapActions(["getProfilePicture"]),
  },
  mounted() {
    
    const context = this;
    
    this.getProfilePicture({
      id: this.$store.getters["auth/USERPROFILE"].system_usersid, 
      router: this.$router,
    }).then((result) => {
       context.PHOTOURL = result.data[0].fn_system_users_get_profilepicture ? result.data[0].fn_system_users_get_profilepicture.data : undefined;
    }).catch((err) => {
      
    });

   
  }, 

}
</script>
