<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Office'"
      :ActiveMenu="'RealtimeMonitor'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Send Bulk SMSes"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Office"
        breadcrumbitem3="SendSMS"
      />
      <!-- end page-header -->

<!-- begin #content -->
		<div id="content" class="content content-full-width">
			<!-- begin breadcrumb -->
			<ol class="breadcrumb pull-right">
				<li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
				<li class="breadcrumb-item"><a href="javascript:;">Map</a></li>
				<li class="breadcrumb-item active">Google Map</li>
			</ol>
			<!-- end breadcrumb -->
			<!-- begin page-header -->
			<h1 class="page-header">Google Map <small>header small text goes here...</small></h1>
			<!-- end page-header -->
			<div class="map-content">
				<div class="btn-group map-btn pull-right">
					<button type="button" class="btn btn-inverse" id="map-theme-text">Default Theme</button>
					<button type="button" class="btn btn-inverse dropdown-toggle" data-toggle="dropdown">
					</button>
					<ul class="dropdown-menu" id="map-theme-selection">
						<li class="active"><a href="javascript:;" data-map-theme="default">Default</a></li>
						<li><a href="javascript:;" data-map-theme="flat">Flat</a></li>
						<li><a href="javascript:;" data-map-theme="turquoise-water">Turquoise Water</a></li>
						<li><a href="javascript:;" data-map-theme="icy-blue">Icy Blue</a></li>
						<li><a href="javascript:;" data-map-theme="cobalt">Cobalt</a></li>
						<li><a href="javascript:;" data-map-theme="old-dry-mud">Old Dry Mud</a></li>
						<li><a href="javascript:;" data-map-theme="dark-red">Dark Red</a></li>
					</ul>
				</div>
			</div>
			<div class="map">
				<div id="google-map-default" class="height-full width-full"></div>
			</div>
		</div>
		<!-- end #content -->

          

    </div>
    <!-- end #content -->
    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import headerComp from "@/components/common/header.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import pageloader from "@/components/common/pageloader.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";

import { createNamespacedHelpers } from "vuex";
import { setTimeout } from "timers";
const { mapGetters, mapActions } = createNamespacedHelpers("sms");
// value.context.$emit("init");

export default {
  name: "bulkSmses",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      getSmses: this.$store.getters["sms/GETALL"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      DRIVERS: [],
      totalmessages: 0,
      delivered: 0,
      notsent: 0,
      failed: 0,
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      numbers: "",
      EXTERNALNUMBERS: [],
      driverResults: [],
      operatorResults: [],
      numberAdded: false,
      IsAllOperatorsSelected: false,
      IsDriversSelected: false,
      context: this,
      skip: 0,
      len: 10000,
      SENDMODEL: {
        taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        taxi_operator_id: [],
        taxi_driver_id: [],
        sms_number: "",
        message: "",
        batch_name: this.$uuid.v4(),
        added_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
        future_date: new Date(),
        failedurl: "",
        deliveredurl: ""
      },
      eventNumbers: [],
      eventMessage: "",
      statementGenerated: false,
      GETMODEL: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        userid: this.$store.getters["auth/USERPROFILE"].system_usersid,
        startdate: "",
        enddate: "",
        skip: 0,
        len: 20
      },
      RESENDMODEL: {}
    };
  },
  beforeMount: function() {
    this.getDriversbyid(this.context);
    
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    CapturePhotosControl
  },

  computed: {
    //...mapGetters(["GETALL", "GETSENT", "GETALLDRIVERS", "GETALLOPERATOR"]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  watch: {
    
  },
  methods: {
  },
  mounted: function() {

  },
};
</script>

	

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>