<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'BookASeat'"
      :ActiveMenu="'Bookings'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        :pageheaderTitle="`` + DESC" 
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Book-A-Seat"
        breadcrumbitem3="Bookings"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">Bookings</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <a
                  data-toggle="modal"
                  data-target="#AddModal"
                  class="btn btn-white"
                  href="javascript:;"
                >
                  <i class="fa fa-plus"></i>

                  <router-link to="/Vehicle/Add/:id">Add Booking</router-link>
                </a>
              </div>
            </div>

            <!-- <div v-if="!dataLoaded" class="btn-row">
              <div class="text-center">
                <i class="fas fa-circle-notch fa-spin fa-10x fa-blue"></i>
              </div>
            </div> -->

            <br />

            <div v-if="!dataLoaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-default" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">BOOKING NO</th>
                    <th class="text-nowrap">COMMUTER</th>
                    <th class="text-nowrap">MOBILE</th>
                    <th class="text-nowrap">WALLET BAL</th>
                    <th class="text-nowrap">STATUS</th>
                    <th class="text-nowrap">DEPARTURE</th>
                    <th class="text-nowrap">AMOUNT</th>
                    <th class="text-nowrap">CHANGED</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETVEHICLEBYASSID"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.bookingnumber}}</td>
                    <td>{{item.firstname+' '+item.surname}}</td>
                    <td>{{item.mobileno | HumanizeMobile}}</td>
                    <td>{{item.commuter_avl_bal | currencywithSymbol}}</td>
                    <td width="10%">{{item.bookingstatus_name}}</td>
                    <td>{{item.description}}</td>
                    <td>{{item.booking_amount | currencywithSymbol}}</td>
                    <td>{{item.is_switched_booking}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      
                      <div class="btn-group">
                        <button
                        type="button"
                        class="btn btn-sm btn-success width-100 m-r-2"
                        v-if="item.bookingstatus_code=='PYL'"
                        @click="Delete(context,item._taxi_operator_vehicles_id,USERID)"
                        :disabled="$wait.is('delete-' + item._taxi_operator_vehicles_id)"
                      >
                        <v-wait v-bind:for="'delete-' + item._taxi_operator_vehicles_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Send EasyPay
                        </v-wait>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-80 m-r-2"
                        v-if="item.bookingstatus_code=='PYL' && item.commuter_avl_bal > item.booking_amount"
                        @click="PayViaWallet(context,item)"
                        :disabled="$wait.is('pay-' + item.id) "
                      >
                        <v-wait v-bind:for="'pay-' + item.id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Pay
                        </v-wait>
                      </button>
                      
                      <button
                        type="button"
                        class="btn btn-sm btn-info width-70 m-r-2"
                        v-if="item.bookingstatus_code==='ACT'"
                        @click="CheckIn(context,item)"
                        :disabled="$wait.is('checkin-' + item.id)"
                      >
                        <v-wait v-bind:for="'checkin-' + item.id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Check-In
                        </v-wait>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-60 m-r-2"
                        v-if="item.bookingstatus_code!=='CND' && item.bookingstatus_code!=='CKI'"
                        @click="Cancel(context,item)"
                        :disabled="$wait.is('cancel-' + item.id)"
                      >
                        <v-wait v-bind:for="'cancel-' + item.id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Cancel
                        </v-wait>
                      </button>
                    </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("tpaybookings_dashboard");

export default {
  name: "ManageVehicles",
  data: function () {
    return {
      id: this.$route.params.id,
      DESC: this.$route.params.desc,
      TPAYPROFILE: this.$store.getters["auth/TPAYPROFILE"],
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$route.params.id || this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      USERGROUPCODE: this.$store.getters["auth/USERPROFILE"].usergroups_code,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      context: this,
      GETVEHICLEBYASSID: [],
      addedsuccess: undefined,
      errormessage: undefined,
      approvalid: this.$route.query.approvalid,
      skip: 0,
      length: 10000,
      dataLoaded: false,
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters({}),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions([
      "GetBookingBySession",
      "CancelBooking",
      "CheckInBooking",
      "Pay"
    ]),
    getallbookings: (context) => {
      const router = context.$router;

      var payload = {
        BookingSessionId:context.id,
      };

      context.GetBookingBySession({ router, payload })
      .then(parsedResultOP => {
        context.GETVEHICLEBYASSID = parsedResultOP;
        context.dataLoaded = true;
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      }).finally(() => {
        var options = {
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };

              $("#data-table-default").DataTable(options);
      })
  },
  CheckIn: (context,item) => {

  context.$Notify.YesNo(false, { context, id:item.id,item }).then((value) => {
    if (value) {
      value.context.$wait.start("checkin-" + value.id);

      const router = context.$router;

      var payload = {
        BookingNo:value.item.bookingnumber,
        CheckedInBy:value.context.TPAYPROFILE.SystemUsersId,
        GeoLocation:"",
      };

      value.context.CheckInBooking({ router, payload })
      .then(parsedResultOP => {

        if(parsedResultOP.Status){
          value.item.bookingstatus_code = "CKI"
          value.item.bookingstatus_name = "CheckIn"

          value.context.$Notify.Success("Successfully!", "");
        } else {

          

          value.context.$Notify.Error(parsedResultOP.Message, "");
        }

       
        //context.GETVEHICLEBYASSID = parsedResultOP;
        //context.dataLoaded = true;
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      }).finally(() => {
        value.context.$wait.end("checkin-" + value.id);
      })

     
    }
  });

 
},
  Cancel: (context,item) => {

    context.$Notify.YesNo(false, { context, id:item.id,item }).then((value) => {
        if (value) {
          value.context.$wait.start("cancel-" + value.id);

          const router = context.$router;

          var payload = {
            SystemUserId:value.item.commuter_id,
            MobileNumber:value.item.mobileno,
            BookaSeatId:value.item.id,
            BookingNo:value.item.bookingnumber,
            CancelledBy:value.context.TPAYPROFILE.SystemUsersId,
            GeoLocation:"",
          };

          value.context.CancelBooking({ router, payload })
          .then(parsedResultOP => {

            if(parsedResultOP.Status){
              value.item.bookingstatus_code = "CND"
            value.item.bookingstatus_name = "Cancelled"

            value.context.$Notify.Success("Successfully Cancelled!", "");
            } else {

              value.context.$Notify.Error(parsedResultOP.Message, "");
            }
            //context.GETVEHICLEBYASSID = parsedResultOP;
            //context.dataLoaded = true;
          })
          .catch(error => {
            //context.$wait.end("generatestatement");
          }).finally(() => {
            value.context.$wait.end("cancel-" + value.id);
          })

         
        }
      });

     
  },
  PayViaWallet: (context,item) => {

context.$Notify.YesNo(false, { context, id:item.id,item }).then((value) => {
    if (value) {
      value.context.$wait.start("pay-" + value.id);

      const router = context.$router;

      var payload = {
        TransSourceCode:"TPW",
        BookingNo:value.item.bookingnumber,
        AcceptedBy:value.context.TPAYPROFILE.SystemUsersId,
        GeoLocation:"",
      };

      value.context.Pay({ router, payload })
      .then(parsedResultOP => {

        if(parsedResultOP.Status){
          value.item.bookingstatus_code = "ACT"
        value.item.bookingstatus_name = "Accepted"

        value.context.$Notify.Success("Successfully!", "");
        } else {

          value.context.$Notify.Error(parsedResultOP.Message, "");
        }
        //context.GETVEHICLEBYASSID = parsedResultOP;
        //context.dataLoaded = true;
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      }).finally(() => {
        value.context.$wait.end("pay-" + value.id);
      })

     
    }
  });

 
}
},
  beforeMount: function () {
    // this.getbyid(this.context, this.taxiassociationid);
    // $("#data-table-default").DataTable({
    //   responsive: true
    // });
  },
  mounted: function () {
    App.init();
    this.getallbookings(this.context);

    //  this.getByTaxiAssId(this.context);
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

