/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';


export default {
  namespaced: true,
  state: {
    sms: [],
    sent_sms: [],
    sentSMSs: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETALL: state => state.sms,
    GETSENT: state => state.sent_sms,
    GETSENTSMSES: state => state.sentSMSs,
    GETALLDRIVERS: (state, getters, rootState, rootGetters) => {
      return rootGetters['TaxiDrivers/GETDRIVERSBYASSIDNOPIC']
    },
    GETALLOPERATOR: (state, getters, rootState, rootGetters) => {
      return rootGetters['TO/GETOPERATORSNOPIC']
    },
  },
  mutations: {
    LOADED: (state, _data) => {
      if (_data !== undefined) {
        state.sms = _data;
      }
    },
    ADDED: (state, _data) => {
      state.sms.push(_data);
    },
    UPDATED: (state, _data) => {
      if (_data !== undefined) {
        Object.assign(state.sms.filter(item => item._id === _data._id)[0], _data);
      }
    },
    RESTOREDELETED: (state, {
      id
    }) => {
      if (id !== undefined) {
        state.sms.filter(item => item._id === id)[0]._deleted_by = undefined;
        state.sms.filter(item => item._id === id)[0]._date_deleted = undefined;
      }
    },
    SENTLOADED: (state, {
      id
    }) => {
      if (id !== undefined) {
        state.sent_sms.filter(item => item._id === id)[0]._date_send != undefined;
        state.sent_sms.filter(item => item._id === id)[0]._date_send != undefined;
      }
    },
    MARKASDELETE: (state, {
      id,
      deletedby
    }) => {
      if (id !== undefined) {
        state.sms.filter(item => item._id === id)[0]._deleted_by = deletedby;
        state.sms.filter(item => item._id === id)[0]._date_deleted = Date.now;
      }
    },
    DELETE: (state, id) => {
      if (id !== undefined) {
        state.sms = state.sms.filter(item => item._id !== id);
        state.deleted = true;
      }
    },

    LOADSENTSMSES: (state, _data) => {
      
      _data.map((_dataObject)=>{
        if(_dataObject.id != null){
          state.sentSMSs.push(_dataObject);
        }
      })
      
    },

    CLEARALL: (state) => {
      state.sentSMSs = [];
    },
  },
  actions: {
    /* Async */

    /* Auth.js Mutations */
    getall({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SMS_URL,
        };

        this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
          if (parsedResult.status) {
            commit('LOADED', parsedResult.data);
            resolve();
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },

    getSMSsByAssociation({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      payload,
      router
    }) {
      
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SMSBYASSOCIATION_URL,
        };

        this._vm.$TAMS_API.getCustomURL2(requestheader, payload).then((parsedResult) => {
          if (parsedResult.status) {
            console.log("these are the results", parsedResult.data)
            // commit('LOADED', parsedResult.data, 'SENTLOADED');
            // commit('SENTLOADED', parsedResult.data);

            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log("Errors found", error)
          reject(error);
        });
      });
    },

    add({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      addModel
    }) {
      return new Promise((resolve, reject) => {

        

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.BULKSMS_URL,
        };
        this._vm.$TAMS_API.add(requestheader,
          addModel).then((parsedResult) => {
          if (parsedResult.status) {
            // addModel._id = parsedResult.data;
            // commit('ADDED', addModel);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    update({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          item,
          router,
          updatedby,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SMS_URL,
        };

        const updateModel = {
          name: item._name,
          description: item._description,
          code: item._code,
          isactive: item._is_active,
          updatedby,
        };

        this._vm.$TAMS_API.update(requestheader,
          item._id,
          updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            commit('UPDATED', item);
            resolve();
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    delete({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SMS_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id, {
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('DELETE', id);
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    markAsdelete({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SMS_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id, {
            id,
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('MARKASDELETE', {
              id,
              deletedby
            });
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    restoreDeleted({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          restoredby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SMS_URL,
        };

        this._vm.$TAMS_API.restoreDeleted(requestheader, {
          id,
          restoredby,
        }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('RESTOREDELETED', {
              id
            });
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getAssociationSmsesById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_GETBULKSMSESBYID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            //commit('CLEARALL');
            //commit('LOADSENTSMSES', parsedResultOP.data);
            resolve(parsedResultOP);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('', error.data);
        });
      });
    },
    getSMSes({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      taxiassociationid,
      vehicleid,
      router
    }) {
      
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETSMSBYVEHICLEID_URL,
        };

        this._vm.$TAMS_API.getCustomURL(requestheader, {
          taxiassociationid,
          vehicleid,
        }).then((parsedResultOP) => {
          //console.log(parsedResultOP);
          if (parsedResultOP.status) {
            resolve(parsedResultOP);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('', error.data);
        });
      });
    },
    getBulkSmsByBatch({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.BULKSMSGETBYBATCH_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            // commit('CLEARALL');
            // commit('LOADSENTSMSES', parsedResultOP.data);
            resolve(parsedResultOP);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('', error.data);
        });
      });
    },

  },

  



};