<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
 <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TaxiAssociation'"
      :ActiveMenu="'AddOnBillings'"
    />
    <!-- end #sidebar -->

      <!-- begin #sidebar -->
  
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Edit Taxi Association Billing Items"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Association"
        breadcrumbitem3="Add On Billings"
      />
      <!-- end page-header -->
      <!-- end page-header -->
          <fieldset>
                    <!-- begin row -->
                  <div class="row">
                      <!-- begin col-12 -->
                      <div class="col-lg-12">
                        <!-- begin panel -->
                        <div class="panel panel-inverse panel-primary">
                          <!-- begin panel-heading -->
                          <div class="panel-heading">
                            <div class="panel-heading-btn">
                              <a
                                href="javascript:;"
                                class="btn btn-xs btn-icon btn-circle btn-default"
                                data-click="panel-expand"
                              >
                                <i class="fa fa-expand"></i>
                              </a>
                              <a
                                href="javascript:;"
                                class="btn btn-xs btn-icon btn-circle btn-success"
                                data-click="panel-reload"
                              >
                                <i class="fa fa-redo"></i>
                              </a>
                              <a
                                href="javascript:;"
                                class="btn btn-xs btn-icon btn-circle btn-warning"
                                data-click="panel-collapse"
                              >
                                <i class="fa fa-minus"></i>
                              </a>
                            </div>

                            <h4 class="panel-title">Taxi Association Routes</h4>
                          </div>
                          <!-- end panel-heading -->

                          <div class="panel-toolbar">
                            <div class="btn-group m-r-5">
                              <a
                                data-toggle="modal"
                                data-target="#AddModal"
                                class="btn btn-white"
                                href="javascript:;"
                                @click="addRoute(context)"
                              >
                                <i class="fa fa-plus"></i>
                                Add Route
                              </a>
                            </div>
                          </div>

                          <!-- begin panel-body -->
                          <div class="panel-body">
                            <table id="data-table-titles" class="table">
                              <thead>
                                <tr>
                                  <th width="1%" data-orderable="false"></th>
                                  <th class="text-nowrap">Name</th>
                                  <th class="text-nowrap">Description</th>
                                  <th class="text-nowrap">Code</th>
                                  <th class="text-nowrap"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr
                                  v-for="(item,index) in TaxiAssociationRoutes"
                                  :key="item.id"
                                  :value="item"
                                >
                                  <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                                  <td class="with-form-control">
                                    <input
                                      type="text"
                                      data-parsley-group="step-2"
                                      data-parsley-required="true"
                                      placeholder="e.g. Local To Town"
                                      class="form-control"
                                      v-model="item.name"
                                    />
                                  </td>
                                  <td class="with-form-control">
                                    <input
                                      type="text"
                                      data-parsley-group="step-2"
                                      data-parsley-required="true"
                                      placeholder="e.g. Local To Town"
                                      class="form-control"
                                      v-model="item.description"
                                    />
                                  </td>
                                  <td class="with-form-control">
                                    <input
                                      ref="code"
                                      type="text"
                                      data-parsley-group="step-2"
                                      data-parsley-required="true"
                                      placeholder="e.g. LTT"
                                      class="form-control"
                                      v-model="item.code"
                                    />
                                  </td>
                                  <td class="with-btn" nowrap>
                                    <button
                                      :disabled="!item.candelete"
                                      type="button"
                                      class="btn btn-sm btn-danger width-80"
                                      @click="TaxiAssociationRoutes.splice(--index,1)"
                                    >Delete</button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <!-- end panel-body -->
                        </div>
                        <!-- end panel -->
                      </div>
                      <!-- end col-12 -->

                      <!-- begin col-12 -->
                      <div class="col-lg-12">
                        <!-- begin panel -->
                        <div class="panel panel-inverse panel-primary">
                          <!-- begin panel-heading -->
                          <div class="panel-heading">
                            <div class="panel-heading-btn">
                              <a
                                href="javascript:;"
                                class="btn btn-xs btn-icon btn-circle btn-default"
                                data-click="panel-expand"
                              >
                                <i class="fa fa-expand"></i>
                              </a>
                              <a
                                href="javascript:;"
                                class="btn btn-xs btn-icon btn-circle btn-success"
                                data-click="panel-reload"
                              >
                                <i class="fa fa-redo"></i>
                              </a>
                              <a
                                href="javascript:;"
                                class="btn btn-xs btn-icon btn-circle btn-warning"
                                data-click="panel-collapse"
                              >
                                <i class="fa fa-minus"></i>
                              </a>
                            </div>

                            <h4 class="panel-title">Billing Items</h4>
                          </div>
                          <!-- end panel-heading -->

                          <div class="panel-toolbar">
                            <div class="btn-group m-r-5">
                              <a
                                data-toggle="modal"
                                data-target="#AddModal"
                                class="btn btn-white"
                                href="javascript:;"
                                @click="AddBillingItems(context)"
                              >
                                <i class="fa fa-plus"></i>
                                Add Bill Item
                              </a>
                            </div>
                          </div>

                          <!-- begin panel-body -->
                          <div class="panel-body panel-body">
                            <div class="row panel panel-inverse">
                              <!-- begin col-8 -->
                              <div class="col-lg-6">
                                <!-- begin form-group -->
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">Route</label>
                                  <div class="col-md-6">
                                    <select
                                      class="form-control"
                                      data-parsley-group="step-2"
                                      v-model="billitems.route"
                                    >
                                      <option value="0" selected>Select Route</option>
                                      <option
                                        v-for="item in TaxiAssociationRoutes"
                                        v-bind:key="item.id"
                                        :value="item"
                                      >{{item.name}}</option>
                                    </select>
                                  </div>
                                </div>
                                <!-- end form-group -->
                                <!-- begin form-group -->
                                <div class="form-group row m-b-10">
                                  <label
                                    class="col-md-4 col-form-label text-md-right"
                                  >Type Of Client</label>
                                  <div class="col-md-6">
                                    <select
                                      class="form-control"
                                      data-parsley-group="step-2"
                                      v-model="billitems.typeofclients"
                                    >
                                      <option value="0" selected>Select Type Of Client</option>
                                      <option
                                        v-for="item in GETTYPEOFBILLINGCLIENT"
                                        v-bind:key="item._typeofbillingclien_id"
                                        :value="item"
                                      >{{item._name}}</option>
                                    </select>
                                  </div>
                                </div>
                                <!-- end form-group -->
                                <!-- begin form-group -->
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">
                                    Collection Settings
                                    <span class="text-danger">*</span>
                                  </label>
                                  <div class="col-md-6">
                                    <select
                                      class="form-control"
                                      data-parsley-group="step-2"
                                      v-model="billitems.collectionsettings"
                                    >
                                      <option value="0" selected>Select A Settings</option>
                                      <option
                                        v-for="item in GETCOLLECTIONSETTINGS"
                                        v-bind:key="item._collection_settings_id"
                                        :value="item"
                                      >{{item._collection_settings_name + '(' + item._collection_frequency_num_of_days + ' Days' + ')'}}</option>
                                    </select>
                                  </div>
                                </div>
                                <!-- end form-group -->
                                <!-- begin form-group -->
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">Amount</label>
                                  <div class="col-md-6">
                                    <CurrencyInput
                                      v-model="billitems.amount"
                                      :auto-decimal-mode="true"
                                      :currency="currency"
                                      class="form-control text-left demo__currency-input"
                                    />
                                  </div>
                                </div>
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">Start Date</label>
                                  <div class="col-md-6">
                                    <div
                                      id="startdate"
                                      class="input-group date datepicker-disabled-past"
                                      data-date-format="dd-mm-yyyy"
                                      data-date-start-date="Date.default"
                                    >
                                      <input
                                        type="text"
                                        data-parsley-group="step-2"
                                        class="form-control"
                                        placeholder="Select Date"
                                      />
                                      <span class="input-group-addon">
                                        <i class="fa fa-calendar"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">
                                    End Date
                                    <span class="text-danger"></span>
                                  </label>
                                  <div class="col-md-6">
                                    <div
                                      id="enddate"
                                      class="input-group date datepicker-disabled-past"
                                      data-date-format="dd-mm-yyyy"
                                      data-date-start-date="Date.default"
                                    >
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Select Date"
                                      />
                                      <span class="input-group-addon">
                                        <i class="fa fa-calendar"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                
                                <!-- end form-group -->
                              </div>
                              <!-- end col-8 -->

                              <div class="col-lg-6">
                                <!-- begin form-group -->
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">
                                    Can Be Disputed
                                    <span class="text-danger">*</span>
                                  </label>
                                  <div class="col-md-6">
                                    <div class="switcher switcher-success">
                                      <input
                                        type="checkbox"
                                        name="switcher_checkbox_canbedisputed"
                                        id="switcher_checkbox_canbedisputed"
                                        true-value="true"
                                        false-value="false"
                                        disabled
                                    
                                        v-model="billitems.canbedisputed"
                                      />
                                      <label for="switcher_checkbox_canbedisputed"></label>
                                    </div>
                                  </div>
                                </div>
                                <!-- end form-group -->
                                <!-- begin form-group -->
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">
                                    Can Be Deleted
                                    <span class="text-danger">*</span>
                                  </label>
                                  <div class="col-md-6">
                                    <div class="switcher switcher-success">
                                      <input
                                        type="checkbox"
                                        name="switcher_checkbox_canbedeleted"
                                        id="switcher_checkbox_canbedeleted"
                                        true-value="true"
                                        false-value="false"
                                           disabled
                                        v-model="billitems.canbedeleted"
                                      />
                                      <label for="switcher_checkbox_canbedeleted"></label>
                                    </div>
                                  </div>
                                </div>
                                <!-- end form-group -->
                                <!-- begin form-group -->
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">
                                    Penalty Collection Setting
                                    <span class="text-danger"></span>
                                  </label>
                                  <div class="col-md-6">
                                    <select
                                      class="form-control"
                                      v-model="billitems.penaltycollectionsettings"
                                    >
                                      <option value="0" selected>Select Type Of Client</option>
                                      <option
                                        v-for="item in GETCOLLECTIONSETTINGS"
                                        v-bind:key="item._collection_settings_id"
                                        :value="item"
                                      >{{item._collection_settings_name + '(' + item._collection_frequency_num_of_days + ' Days' + ')'}}</option>
                                    </select>
                                  </div>
                                </div>
                                <!-- end form-group -->
                                <!-- begin form-group -->
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">
                                    Penalty Fee Warning
                                    <span class="text-danger">*</span>
                                  </label>
                                  <div class="col-md-6">
                                    <input
                                      type="text"
                                      v-model="billitems.penaltyfeewarning"
                                      id="default_rangeSlider"
                                      name="default_rangeSlider"
                                      value
                                    />
                                  </div>
                                </div>
                                <!-- end form-group -->
                                <!-- begin form-group -->
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">
                                    Penalty Fee Amount
                                    <span class="text-danger">*</span>
                                  </label>
                                  <div class="col-md-6">
                                    <CurrencyInput
                                      v-model="billitems.penaltyfeeamount"
                                      :auto-decimal-mode="true"
                                      :currency="currency"
                                      data-parsley-group="step-2"
                                      data-parsley-required="false"
                                      class="form-control text-left demo__currency-input"
                                    />
                                  </div>
                                </div>
                                <!-- end form-group -->
                              </div>
                            </div>

                            <table
                              id="datatablebillitems"
                              class="table table-striped table-bordered"
                            >
                              <thead>
                                <tr>
                                  <th class="text-nowrap"></th>
                                  <th class="text-nowrap">Route</th>
                                  <th class="text-nowrap">Type Of Client</th>
                                  <th class="text-nowrap">Collection Settings</th>
                                  <th class="text-nowrap">Amount</th>
                                  <th class="text-nowrap">Start Date</th>
                                  <th class="text-nowrap">End Date</th>
                                  <th class="text-nowrap">Next Bill Date</th>
                                  <th class="text-nowrap">Can Be Disputed</th>
                                  <th class="text-nowrap">Can Be Disputed</th>
                                  <th class="text-nowrap">Penalty Settings</th>
                                  <th class="text-nowrap">Penalty Warning</th>
                                  <th class="text-nowrap">Penalty Amount</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr
                                  v-for="item in GETTAXIASSOCIATIONBILLING"
                                  v-bind:key="item._billing_items_id"
                                  :value="item"
                                >
                                  <td class="with-btn" nowrap>
                                    <button
                                      type="button"
                                      class="btn btn-sm btn-danger width-80"
                                      @click="billitemsadded.splice(--index,1)"
                                    >Delete</button>
                                   
                                  </td>
                                  <td>{{item._taxi_association_routes_id_desc}}</td>
                                  <td>{{item._typeofbillingclient_name}}</td>
                                  <td>{{item._collection_settings_name}}</td>
                                  <td>{{item._billing_items_amount}}</td>
                                  <td>{{item._billing_start_date}}</td>
                                  <td>{{item._billing_end_date}}</td>
                                  <td>{{item._next_billing_date}}</td>
                                  <td class="with-checkbox">
                                    <div class="checkbox checkbox-css">
                                      <input
                                        type="checkbox"
                                     
                                        id="table_checkbox_2"
                                        checked
                                      />
                                      <label for="table_checkbox_2">&nbsp;</label>
                                    </div>
                                  </td>
                                  <td class="with-checkbox">
                                    <div class="checkbox checkbox-css">
                                      <input
                                        type="checkbox"
                                      
                                        disabled
                                        id="table_checkbox_2"
                                        checked
                                      />
                                      <label for="table_checkbox_2">&nbsp;</label>
                                    </div>
                                  </td>
                                  <td>{{item._penaltyfee_collection_settings_name}}</td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>

                            <div class="alert alert-danger fade hide routebillingerror">
                              <span class="close" data-dismiss="alert">×</span>
                              <i class="fa fa-exclamation-triangle fa-2x pull-left m-r-10"></i>
                              <p class="m-b-0">Please choose at least one Add On Billing Item</p>
                            </div>
                          </div>
                          <!-- end panel-body -->
                        </div>
                        <!-- end panel -->
                      </div>
                      <!-- end col-12 -->
                    </div>
                    <!-- end row -->
                  </fieldset>


      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->
	


<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import headerComp from "@/components/common/header.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import pageloader from "@/components/common/pageloader.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";

import { createNamespacedHelpers } from "vuex";
import { setTimeout } from "timers";
import Inputmask from "inputmask";
const { mapGetters, mapActions } = createNamespacedHelpers("TA");

export default {
  name: "AddTaxiAssociation",
  data: function() {
    return {
      currencySymbol: "R ",
      position: "prefix",
      DropZoneresizeWidth: 292,
      DropZoneresizeHeight: 40,
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      SYSTEMUSERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      GETALLDISTRICTS: this.$store.getters["lookups/GETALLDISTRICTS"],
      GETALLSUBURS: this.$store.getters["lookups/GETALLSUBURBS"],
      GETBILLINGTYPES: this.$store.getters["lookups/GETBILLINGTYPES"],
      GETADDONSERVICETYPES: JSON.parse(
        JSON.stringify(
          Object.assign({}, this.$store.getters["lookups/GETADDONSERVICETYPES"])
        )
      ),
      GETTYPEOFBILLINGCLIENT: this.$store.getters[
        "lookups/GETTYPEOFBILLINGCLIENT"
      ],
      GETCOLLECTIONSETTINGS: this.$store.getters[
        "lookups/GETCOLLECTIONSETTINGS"
      ],
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      selectedTaxiAffiliationId: undefined,
      selectedDistrictId: undefined,
      contactdetails_first_name: undefined,
      contactdetails_surname: undefined,
      BILLINGTYPE: {},
      ADDONSERVICETYPES: [],
      DropZoneSessionID: this.$uuid.v4(),
      acceptedFiles: "image/*",
      maxFiles: 1,
      firsttime: 0,
      TaxiAssociationRoutes: [
        {
          id: this.$uuid.v4(),
          name: "Default Route",
          description: "Default Route",
          code: "DRE",
          candelete: false
        }
      ],
      billitems: {
        id: this.$uuid.v4(),
        route: {},
        typeofclients: {},
        collectionsettings: {},
        amount: 0,
        startdate: "",
        enddate: "",
        nextbilldate: "",
        canbedisputed: false,
        canbedeleted: false,
        penaltycollectionsettings: {},
        penaltyfeewarning: 0,
        penaltyfeeamount: 0
      },
      billitemsadded: []
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl
  },
  beforeMount() {
    this.GETALLTAXIAFFILIATIONS(this.context);
  },
  computed: {
    ...mapGetters({
      //GETALLTITLES: "GETALLTITLES",
      TAXIAFFILIATION: "GETTAXIAFFILIATIONS", GETTAXIASSOCIATIONBILLING:"TAXIASSOCIATIONBILLING"
    }),
    currency() {
      return { [this.position]: this.currencySymbol };
    },
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions(["addtaxiassociation,getBillingByAss"]),

    AddBillingItems: context => {
      // const res = $('form[name="form-wizard"]')
      //   .parsley()
      //   .validate("step-" + 2);

      // if (!res) {
      //   return res;
      // }

      context.billitems.startdate = context.$Toolkit.ConvertToServerDate(
        $("#startdate").datepicker("getDate")
      );
      context.billitems.enddate = context.$Toolkit.ConvertToServerDate(
        $("#enddate").datepicker("getDate")
      );
      context.billitems.nextbilldate = context.$Toolkit.ConvertToServerDate(
        $("#startdate").datepicker("getDate")
      );
      context.billitems.penaltyfeewarning = $("#default_rangeSlider").data(
        "ionRangeSlider"
      ).result.from;

      context.billitemsadded.push(context.billitems);

      context.billitems = {
        id: taxi_association_id,
        route: {},
        typeofclients: {},
        collectionsettings: {},
        amount: 0,
        startdate: "",
        enddate: "",
        nextbilldate: "",
        canbedisputed: false,
        canbedeleted: false,
        penaltycollectionsettings: {},
        penaltyfeewarning: 0,
        penaltyfeeamount: 0
      };

      let table = {};

      if ($.fn.dataTable.isDataTable("#datatablebillitems")) {
        table = $("#datatablebillitems").DataTable();
      } else {
        table = $("#datatablebillitems").DataTable({
          responsive: true,
          paging: false
        });
      }
      $("#datatablebillitems").addClass("bs-select");
    },
     getBilllingById: (context, taxi_association_id) => {
      context
        .getBillingByAss({
          id: taxi_association_id,
          router: context.$router
        })
        .then(() => {
          App.init();
        });
    },
    
    deleteRoute: (context, id) => {
      context.TaxiAssociationRoutes = context.TaxiAssociationRoutes.filter(
        item => item.id !== id
      );
    },
    fileadded: function(data) {
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')
      const context = $("#wizard").data("context");
      //context.GETUPLOADEDFILES = context.$store.getters["fileupload/GETALL"];
    },
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    clearAdded: item => {
      for (var propName in item) {
        item[propName] = undefined;
      }
    },
   
  },
  mounted: function() {
    //this.get(this.context)
    thisgetBilllingById(context, taxi_association_id)

    this.ADDONSERVICETYPES = [];

    $(".btnadded")
      .mouseenter(function() {
        $(this)
          .removeClass("btn-success")
          .addClass("btn-danger")
          .text("Remove");
      })
      .mouseleave(function() {
        $(this)
          .removeClass("btn-danger")
          .addClass("btn-success")
          .text("Added");
      });

    App.init();

    this.ADDONSERVICETYPES = [];

    $(".btnadded")
      .mouseenter(function() {
        $(this)
          .removeClass("btn-success")
          .addClass("btn-danger")
          .text("Remove");
      })
      .mouseleave(function() {
        $(this)
          .removeClass("btn-danger")
          .addClass("btn-success")
          .text("Added");
      });

    App.init();

    $("#default_rangeSlider").ionRangeSlider({
      min: 0,
      max: 30,
      type: "single",
      postfix: " Days",
      maxPostfix: "+",
      prettify: false,
      hasGrid: true
    });

    $(".datepicker-disabled-past").datepicker({
      todayHighlight: true,
      autoclose: true
    });

    //$('.selectpicker1').selectpicker('render');
    //FormWizardValidation.init();

    $("#wizard").smartWizard({
      selected: 1,
      theme: "default",
      transitionEffect: "",
      transitionSpeed: 0,
      backButtonSupport: false,
      keyNavigation: false,
      useURLhash: false,
      showStepURLhash: false,
      backButtonSupport: false,
      toolbarSettings: {
        toolbarPosition: "bottom"
      }
    });
    $("#wizard").data("context", this.context);
    $("#wizard").on("leaveStep", function(
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {
      const context = $("#wizard").data("context");
      const currentstep = stepNumber + 1;
      const res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

      $(".sw-btn-next").text("Next");

      if (stepDirection == "backward") return true;

      if (currentstep === 1 && stepDirection == "forward") {
        const addonserviceerror = context.ADDONSERVICETYPES.length !== 0;

        if (!addonserviceerror)
          $(".addonserviceerror")
            .addClass("show")
            .removeClass("hide");

        return addonserviceerror;
      }
      if (currentstep === 2 && stepDirection == "forward") {
        const routebilling = context.billitemsadded.length !== 0;

        if (!routebilling) {
          $(".routebillingerror")
            .addClass("show")
            .removeClass("hide");
        } else {
          $(".sw-btn-next").text("Submit");
        }

        return routebilling;
      }

      if (currentstep === 3 && stepDirection == "forward") {
        const taxiassociationaddedd = $("#wizard").data(
          "taxiassociationaddedd"
        );
        if (!taxiassociationaddedd) {
          context.add(
            context,
            context.SYSTEMUSERID
          );
        }
        return taxiassociationaddedd;
      }

      return res;
    });

    //     $(function(){
    //    $(".dropdown-menu").on('click', 'a', function(){
    //        $(this).parents('.dropdown').find('button').text($(this).text());
    //    })
    // });

    $(".selectpicker").select2();
    $("#wizard").data("taxiassociationaddedd", false);

    $(".data-table").DataTable({
      responsive: true,
      paging: false
    });
  },
  Updated: function() {}
};
</script>


<style scoped>
</style>



