<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'FleetManagement'"
      :ActiveMenu="'Arrears'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="All Clients With Arrears"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Fleet Management"
        breadcrumbitem3="List Of Clients"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->
      <!-- begin row -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">All Clients</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <router-link to="/FleetManagment/AddClient/:id" class="btn btn-white">
                  <i class="fa fa-plus"></i>
                  Add Client
                </router-link>
              </div>
            </div>

            <br />

            <div v-if="!dataLoaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-default" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">ID Number</th>
                    <th class="text-nowrap">Mobile No</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">No Of Vehicles</th>
                    <th class="text-nowrap">Outstanding Balance</th>
                    <th class="text-nowrap">Bank Name</th>
                    <th class="text-nowrap">Account Type</th>
                    <th class="text-nowrap">Account No</th>
                    <th class="text-nowrap">Bad Debt</th>
                    <th class="text-nowrap">Debit Amount</th>
                    <th class="text-nowrap">Current Contract Amount</th>
                    <th>Need Attention</th>
                    <th class="text-nowrap hidden-print">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETOPERATORSBYASSID"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                  <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.firstname + " " + item.surname}}</td>
                    <td>{{item.id_number}}</td>
                    <td>{{item.mobilenumber}}</td>
                    <td>{{item.is_active}}</td>
                    <td>{{item.numberofvehicles}}</td>
                    <td>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-danger': item.outstanding_balance > 0, 'label-secondary':item.outstanding_balance <=0  }"
                      > {{item.outstanding_balance | currencywithSymbol}}</span>
                    </td>
                    <td>{{item.bankname_name}}</td>
                    <td>{{item.accounttype_name}}</td>
                    <td>{{item.account_number_anon}}</td>
                    <td>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-danger': item.is_baddebt, 'label-secondary':!item.is_baddebt  }"
                      >{{item.is_baddebt}}</span>
                    </td>
                    <td>{{item.debitamount | currencywithSymbol}}</td>
                    <td>{{item.nupaycontractamount | currencywithSymbol}}</td>
                    <td>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-danger': item.debitamount !== item.nupaycontractamount, 'label-secondary':item.debitamount === item.nupaycontractamount  }"
                      >{{item.debitamount !== item.nupaycontractamount}}</span>
                  </td>
                    <td class="with-btn valign-middle" nowrap>
                      <router-link
                        :to="{ name: 'clientwitharrears_trans', params: { id: item.id }}"
                        class="btn btn-sm btn-yellow width-60 m-r-2"
                        v-if="item.is_active"
                      >Details</router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("fleetmanagement");

export default {
  name: "ManageOperators",
  data: function () {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      skip: 0,
      length: 1000000,
      GETOPERATORSBYASSID: [],
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      taxiassociationid: this.$route.params.id || this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      // accountid: this.$store.getters["TaxiDrivers/GETDRIVERACCOUNT"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      dataLoaded: false,
    };
  },
  beforeMount: function () {
    this.FILTERBYROLE(this.context);
    window.PushManager.listenToIncomingMessages(this.context);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters({}),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions([
      "getAllClientsArrearsyTaxiAssId",
    ]),
    FILTERBYROLE: (context) => {
      $(".byrole").each(function () {
        if (
          context.USERROLES.length > 0 &&
          context.USERROLES.filter(
            (item) => item._code === $(this).attr("data-role")
          ).length > 0
        )
          $(this).removeClass("hide");
      });
    },

    getbyid: (context, taxiassociationid) => {
      context
        .getAllClientsArrearsyTaxiAssId({
          router: context.$router,
          id: taxiassociationid,
        })
        .then((results) => {
          console.log("operators found: ", results);
          debugger;
          context.GETOPERATORSBYASSID = results.data[0].data;
          context.dataLoaded = true;
        })
        .catch((error) => {
          console.log("operator errors:", error);
        })
        .finally(() => {
          var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: true,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };


          $("#data-table-default").DataTable(options);
        });
    },
    Delete: (context, id, deletedby) => {
      context.$Notify.YesNo(false, { context, id, deletedby }).then((value) => {
        
        if (value) {
          value.context.$wait.start("delete-" + value.id);

          value.context
            .markAsDeleted({
              router: value.context.$router,
              id: value.id,
              deletedby: value.deletedby,
            })
            .then((data) => {
              value.context.GETOPERATORSBYASSID.filter(item => item.taxi_operator_id === value.id)[0].deleted_by = value.deletedby;
              value.context.GETOPERATORSBYASSID.filter(item => item.taxi_operator_id === value.id)[0].is_active = false;

              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Success("Successfully Deleted!", value.id);
            })
            .catch((error) => {
              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    Restore: (context, id, restoredby) => {
      context.$Notify
        .YesNo(false, { context, id, restoredby })
        .then((value) => {
          
          if (value) {
            value.context.$wait.is("restore-" + value.id);

            value.context
              .restoreDeleted({
                router: value.context.$router,
                id: value.id,
                restoredby: value.restoredby,
              })
              .then(() => {
                value.context.GETOPERATORSBYASSID.filter(item => item.taxi_operator_id === value.id)[0].deleted_by = null;
                value.context.GETOPERATORSBYASSID.filter(item => item.taxi_operator_id === value.id)[0].is_active = true;
                value.context.$wait.end("restore-" + value.id);
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch((error) => {
                value.context.$wait.end("restore-" + value.id);
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
  },
  beforeDestroy: function () {},
  mounted: function () {

    this.context = this; 

    this.getbyid(this.context, this.taxiassociationid);
    App.init();

    

    $(".byrole").addClass("hide");

    // TableManageCombine.init();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

