<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'PrepaidServices'"
      :ActiveMenu="'List'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Merchants"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Merchants"
        breadcrumbitem3="All"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

         <!-- Modal for verification -->
      <div class="modal fade" id="modal-verify">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Verify Cashup Session</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
              <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
                <div class="form-group">
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Amount</label>
                    <div class="col-md-9">
                      <CurrencyInput
                        v-model="VERIFYCASHUP.amount_collected"
                        :currency="currency"
                        :auto-decimal-mode="true"
                        data-parsley-required="true"
                        data-parsley-group="verifysession"
                        class="form-control form-control-lg"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="form-group row m-b-10">
                      <label class="col-md-3 text-md-right col-form-label">Comments</label>
                      <div class="col-md-9">
                        <textarea
                        v-model="VERIFYCASHUP.comments"
                          class="form-control"
                          data-parsley-required="false"
                          data-parsley-group="verifysession"
                          id="clipboard-textarea2"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
              <button
                type="button"
                class="btn btn-sm btn-success width-80"
                @click="verifySession(context,VERIFYCASHUP)"
                :disabled="$wait.is('add')"
              >
                <v-wait v-bind:for="'add'">
                  <template slot="waiting">
                    <i class="fas fa-spinner fa-spin"></i>
                  </template>
                  Verify
                </v-wait>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal for verification -->

            <div class="row">
              <!-- begin col-3 -->
              <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-ios-card"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">TOTAL CASH-ON-HAND BALANCE</div>
                    <div class="stats-number">{{ TOTALCASHUPBAL | newcurrencywithSymbol}}</div>
                    <div class="stats-desc"></div>
                  </div>
                </div>
              </div>

               <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-ios-card"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">CASHBACK BALANCE</div>
                    <div class="stats-number">{{ TOTALCASHBACKBALANCE | newcurrencywithSymbol}}</div>
                    <div class="stats-desc"></div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-ios-card"></i>
                  </div>
                  <div class="stats-content" v-if="ISINTERNALSYSTEMUSER">
                    <div class="stats-title">MERCHANT AVAILABLE BAL</div>
                    <div class="stats-number">{{ EasyPayBalance | newcurrencywithSymbol}}</div>
                    <div class="stats-desc">Easy Pay No: {{EasyPayNo}}</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6" v-if="ISINTERNALSYSTEMUSER">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-ios-card"></i>
                  </div>
                  <div class="stats-content"  v-if="ISINTERNALSYSTEMUSER">
                    <div class="stats-title">BLD AVAILABLE BALANCE</div>
                    <div class="stats-number">{{ BLDAVLBALANCE | newcurrencywithSymbol}}</div>
                    <div class="stats-desc">Account Number: EEE231256</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6" v-if="ISINTERNALSYSTEMUSER">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-ios-card"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">TOTAL REVENUE</div>
                    <div class="stats-number">{{ (BLDAVLBALANCE + TOTALCASHUPBAL + TOTALCASHBACKBALANCE) | newcurrencywithSymbol}}</div>
                    <div class="stats-desc"></div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">

                  <div class="stats-content">
                    <div class="stats-title">EASYPAY NO</div>
                    <div class="stats-number">{{EasyPayNo}}</div>
                    <div class="stats-desc"></div>
                  </div>
                </div>
              </div>
               </div>


 <!-- begin nav-pills -->
      <ul class="nav nav-pills">
        <li class="nav-items">
          <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active">
            <span class="d-sm-none">Sessions</span>
            <span class="d-sm-block d-none">Sessions</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">Awaiting Verification</span>
            <span class="d-sm-block d-none">Awaiting Verification</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-3" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">Awaiting Approval</span>
            <span class="d-sm-block d-none">Awaiting Approval</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-4" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">Approved</span>
            <span class="d-sm-block d-none">Approved</span>
          </a>
        </li>
      </ul>
      <!-- end nav-pills -->

      <!-- begin tab-content -->
      <div class="tab-content">
        <!-- begin tab-pane -->
        <div class="tab-pane fade active show" id="nav-pills-tab-1">
          <!-- begin widget-table -->

          <!-- begin row -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">All Merchants</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <router-link to="/prepaidServices/Add" class="btn btn-white">
                  <i class="fa fa-plus"></i>
                  Add Merchant
                </router-link>
              </div>
            </div>


            <br />

            <div v-if="!dataLoaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-default" class="table table-striped table-bordered width-full">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">Mobile Number</th>
                    <th class="text-nowrap">Account Number</th>
                    <th class="text-nowrap">Remaining Balance</th>
                    <th class="text-nowrap">CashUp Session</th>
                    <th class="text-nowrap">Start Time</th>
                    <th class="text-nowrap">Cash On Hand</th>
                    <th class="text-nowrap">CashBack Balance</th>
                    <th class="text-nowrap hidden-print">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETMERCHANTSBYASSID"
                    v-bind:key="item._id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.merchant_name}}</td>
                    <td>{{item.merchant_mobile_number}}</td>
                    <td>{{item.account_number}}</td>
                    <td>{{item.available_balance | newcurrencywithSymbol}}</td>
                    <td width="10%">
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-60 m-r-2"
                        v-if="!item.cashup_sessionid_id"
                        data-role="DTO"
                        @click="startcashupsession(context,item,item.fk_system_users_id,USERID)"
                        :disabled="$wait.is('startcashup-' + item.fk_system_users_id)"
                      >
                        <v-wait v-bind:for="'startcashup-' + item.fk_system_users_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Start
                        </v-wait>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-60 m-r-2"
                        v-if="item.cashup_sessionid_id"
                        data-role="DTO"
                        @click="endcashupsession(context,item,item.cashup_sessionid_id,USERID)"
                        :disabled="$wait.is('endcashup-' + item.cashup_sessionid_id)"
                      >
                        <v-wait v-bind:for="'endcashup-' + item.cashup_sessionid_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          End
                        </v-wait>
                      </button>
                    </td>
                    <td>{{item.cashup_start_date}}</td>
                    <td>{{item.cashup_balance | newcurrencywithSymbol}}</td>
                    <td>{{item.cashback_balance | newcurrencywithSymbol}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <!-- <router-link
                        :to="{ name: 'capturephotos', params: { id: item.taxi_operator_id }}"
                        :disabled="!item._images"
                        class="btn btn-sm btn-primary width-60 m-r-2"
                        data-role="RTO"
                      >Photos</router-link>-->
                      <router-link
                        :to="{ name: 'merchantprofile', params: { id: item.fk_system_users_id }}"
                        class="btn btn-sm btn-warning width-60 m-r-2"
                        v-if="item.is_active"
                      >View</router-link>
                      <!-- <router-link
                        :to="{ name: 'editoperator', params: { id: item.taxi_operator_id } }"
                        class="btn btn-sm btn-yellow width-60 m-r-2"
                        :disabled="!item._deleted_by"
                        v-if="item.is_active"
                        data-role="UTO"
                      >Edit</router-link>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-60 m-r-2"
                        v-if="!item.deleted_by"
                        data-role="DTO"
                        @click="Delete(context,item.taxi_operator_id,USERID)"
                        :disabled="$wait.is('delete-' + item.taxi_operator_id)"
                      >
                        <v-wait v-bind:for="'delete-' + item.taxi_operator_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Delete
                        </v-wait>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-60 m-r-2"
                        v-if="item.deleted_by"
                        @click="Restore(context,item.taxi_operator_id,USERID)"
                        :disabled="$wait.is('restore-' + item.taxi_operator_id)"
                        data-role="UTO"
                      >
                        <v-wait v-bind:for="'restore-' + item.taxi_operator_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Restore
                        </v-wait>
                      </button> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>
      <!-- end row -->

        </div>
        <!-- end tab-pane -->

        <!-- begin tab-pane -->
        <div class="tab-pane fade" id="nav-pills-tab-2">
          <div class="table-responsive">
            <table class="table table-invoice">
              <thead>
                <tr>
                  <th width="10%">Collector</th>
                  <th>Conatct No</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Status</th>
                  <th>CashUp Payments</th>
                   <th>CashBack Payments</th>
                  <th>Session Started By</th>
                  <th>Session Ended By</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :class="{'success': item.verifieddate != null}"
                  v-for="(item) in PENDINGCASHUP"
                  v-bind:key="item.id"
                >
                  <td>{{item.assigned_collectors_officer_firstname +' '+item.assigned_collectors_officer_surname}}</td>
                  <td>
                    <div>{{item.collector_mobile_number}}</div>
                  </td>
                  <td>{{item.collection_start_date | formatToShortDateWithTime}}</td>
                  <td>{{item.collection_end_date | formatToShortDateWithTime}}</td>

                  <td>
                    <div class="clearfix f-s-10">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item.deletedby }"
                        v-if="item.deletedby"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.collection_is_active, 'label-secondary':!item.collection_is_active}"
                        v-if="!item._deleted_by"
                      >{{item.collection_is_active == true ? 'Active' : 'Disabled' }}</span>
                    </div>
                  </td>

                  <td>{{item.collection_total_receipts_amount | newcurrencywithSymbol}}</td>
                  <td>{{item.collection_total_cashback_amount | newcurrencywithSymbol}}</td>
                  <td>{{item.added_firstname == null ? ' N/A' : item.added_firstname+' '+item.added_surname == null ? '' : item.added_surname}}</td>
                  <td>{{item.updated_firstname == null ? ' N/A' : item.updated_firstname +' '+item.updated_surname == null ? '' : item.updated_surname}}</td>
                  <td>
                    <a
                      class="btn btn-success btn-sm width-100 rounded-corner"
                      @click="verifyCashUp(context, item)"
                      v-if="item.verifieddate == null"
                    >Verify</a>
                    <button
                      v-if="item.verifieddate != null"
                      type="button"
                      class="btn btn-sm btn-danger width-100 rounded-corner"
                      disabled
                    >Verified</button>

                    <button
                      class="btn btn-success btn-sm width-100 rounded-corner"
                      href="#modal-approve"
                      data-toggle="modal"
                      v-if="item.verified_date != null"
                    >Approve</button>
                    <a
                      href="#modal-decline"
                      class="btn btn-danger btn-sm width-100 rounded-corner"
                      data-toggle="modal"
                      v-if="item.verified_date != null"
                    >Reject</a>
                    <router-link
                      :to="{ name: 'prepaidservicescashupdetails', params: { collectorid: item.assigned_collectors_officer_id,startdate: item.collection_start_date, enddate: item.collection_end_date,activesession:false }}"
                      class="btn btn-info btn-sm width-100 rounded-corner"
                    >View Details</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- end tab-pane -->
        <!-- begin tab-pane -->
        <div class="tab-pane fade" id="nav-pills-tab-3">
          <div class="table-responsive">
            <table class="table table-invoice">
              <thead>
                <tr>
                  <th width="10%">Collected By</th>
                  <th>Conatct No</th>
                  <th>Verified By</th>
                  <th>Verified Date</th>
                  <th>Status</th>
                  <th>Verified Amount</th>
                  <th>Session Started By</th>
                  <th>Session Ended By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item) in PENDINGAPPROVAL" v-bind:key="item.id">
                  <td>{{item.assigned_collectors_officer_firstname +' '+item.assigned_collectors_officer_surname}}</td>
                  <td>
                    <div>{{item.collector_mobile_number}}</div>
                  </td>
                  <td>{{item.verified_firstname}} {{item.verified_surname}}</td>
                  <td>{{item.verifieddate | formatToShortDateWithTime}}</td>

                  <td>
                    <div class="clearfix f-s-10">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item.deletedby }"
                        v-if="item.deletedby"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.collection_is_verified, 'label-secondary':!item.collection_is_verified}"
                        v-if="!item._deleted_by"
                      >{{item.collection_is_verified == true ? 'Verified' : 'Disabled' }}</span>
                    </div>
                  </td>

                  <td>{{item.collection_total_receipts_amount | newcurrencywithSymbol}}</td>
                  <td>{{item.added_firstname == null ? ' N/A' : item.added_firstname+' '+item.added_surname == null ? '' : item.added_surname}}</td>
                  <td>{{item.updated_firstname == null ? ' N/A' : item.updated_firstname +' '+item.updated_surname == null ? '' : item.updated_surname}}</td>
                  <td>
                    <!-- <button
                        v-if="item.verifieddate != null"
                        type="button"
                        class="btn btn-sm btn-danger width-100 rounded-corner"
                        disabled
                    >Verified</button>-->
                    <button
                      class="btn btn-warning btn-sm width-100 rounded-corner"
                      @click="getCashup(context,item)"
                      v-if="item.verifieddate != null"
                    >Approve</button>
                    <!-- <a
                      class="btn btn-danger btn-sm width-100 rounded-corner"
                      data-toggle="modal"
                      v-if="item.verifieddate != null"
                    >Reject</a>-->
                    <router-link
                      :to="{ name: 'cashupdetails', params: { collectorid: item.assigned_collectors_officer_id,startdate: item.collection_start_date, enddate: item.collection_end_date,activesession:false }}"
                      class="btn btn-info btn-sm width-100 rounded-corner"
                    >View Details</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- end tab-pane -->

        <!-- begin tab-pane -->
        <div class="tab-pane fade" id="nav-pills-tab-4">
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-invoice" id="dtApproved">
              <thead>
                <tr>
                  <th width="10%">Collector</th>
                  <th>Conatct No</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Started By</th>
                  <th>Ended By</th>
                  <th>Verified By</th>
                  <th>Verified Date</th>
                  <th>Approved By</th>
                  <th>Approved Date</th>
                  <th>Total Payments</th>
                  <th>Total Payments Verified</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in APPROVEDCASHUP"
                  v-bind:key="item.id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                  <td>{{item.collector_firstname == null ? ' N/A' : item.collector_firstname +' '+item.collector_surname == null ? ' N/A' : item.collector_surname}}</td>
                  <td>
                    <div>{{item.collector_mobile_number}}</div>
                  </td>
                  <td>{{item.collection_start_date | formatToShortDateWithTime}}</td>
                  <td>{{item.collection_end_date | formatToShortDateWithTime}}</td>
                  <td>{{item.added_firstname == null ? ' N/A' : item.added_firstname+' '+item.added_surname == null ? '' : item.added_surname}}</td>
                  <td>{{item.updated_firstname == null ? ' N/A' : item.updated_firstname +' '+item.updated_surname == null ? '' : item.updated_surname}}</td>
                  <td>{{item.verified_firstname == null ? ' N/A' : item.verified_firstname +' '+item.verified_surname == null ? '' : item.verified_surname}}</td>
                  <td>{{item.verifieddate}}</td>
                  <td>{{item.approved_firstname == null ? ' N/A' : item.approved_firstname +' '+item.approved_surname == null ? '' : item.approved_surname}}</td>
                  <td>{{item.approvaldate}}</td>
                  <td>{{item.collection_total_receipts_amount*-1 | newcurrencywithSymbol}}</td>
                  <td>{{item.collection_total_amount_received | newcurrencywithSymbol}}</td>
  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- end tab-pane -->
        <!-- begin tab-pane -->
      </div>
      <!-- end tab-content -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
       <!-- Add Modal HTML START-->
      <div id="ApproveModal" class="modal fade">
        <div class="modal-dialog">
          <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Approve Cash Up</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <!-- begin form-group row -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-2 col-form-label text-md-right">
                      Collected By:
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-7">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        v-model="collected_by"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-2 col-form-label text-md-right">
                      Date Verified:
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-7">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        :value="verifieddate | formatToShortDate"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-2 col-form-label text-md-right">
                      Amount Collected:
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-7">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        v-model="amount_collected"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-2 col-form-label text-md-right">
                      Comments:
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-7">
                      <textarea
                        placeholder="Type your comments"
                        class="form-control m-b-10"
                        id="clipboard-textarea"
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                  <!-- end form-group -->
                </div>
              </div>
              <div class="modal-footer">
                <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
                <button
                  class="btn btn-primary m-5"
                  @click="ApproveVerified(context,APPROVECASHUP)"
                    :disabled="$wait.is('Approve-' + APPROVECASHUP.cashup_id)"
                      >
                        <v-wait v-bind:for="'Approve-' + APPROVECASHUP.cashup_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Approve
                        </v-wait>
                  </button>
              </div>
          </div>
        </div>
      </div>
      <!-- Add Modal HTML START-->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("prepaidservices");

export default {
  name: "ManageOperators",
  data: function () {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      skip: 0,
      length: 100000,
      geolocation: {
        latitude: null,
        longitude: null,
      },
      EasyPayBalance:0.00,
      EasyPayNo:0,
      PENDINGCASHUP: [],
      APPROVEDCASHUP: [],
      PENDINGAPPROVAL: [],
      GETSELECTED: Object.assign({}, this.PENDINGAPPROVAL),
      collected_by: "",
      verifieddate: "",
      amount_collected: 0,
      VERIFYCASHUP:{
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
          cashup_id: "",
          amount_collected: 0,
          comments: "",
          verifiedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      APPROVECASHUP: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        cashup_id: "",
        comments: "",
        approvedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      GETMERCHANTSBYASSID: [],
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      // accountid: this.$store.getters["TaxiDrivers/GETDRIVERACCOUNT"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      dataLoaded: false,
      BLDBALANCE:0,
      BLDAVLBALANCE:0,
    };
  },
  beforeMount: function () {
    this.FILTERBYROLE(this.context);
    window.PushManager.listenToIncomingMessages(this.context);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters({}),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
    currency() {
      return { [this.position]: this.currencySymbol };
    },
    TOTALCASHUPBAL: function(){

      let sum = 0;
      this.GETMERCHANTSBYASSID.forEach(function(item) {
         sum += (parseFloat(item.cashup_balance));
      });

     return sum;
   },
   TOTALCASHBACKBALANCE: function(){

      let sum = 0;
      this.GETMERCHANTSBYASSID.forEach(function(item) {
         sum += (parseFloat(item.cashback_balance));
      });

     return sum;
   }
  },
  methods: {
    ...mapActions([
      "getMerchantsByAssId",
      "startCashupSession",
      "endCashupSession",
      "getPendingCashup",
      "executeVerify",
      "getPendingApproval",
      "getApprovedCashup",
      "ApproveVerifiedCashUp",
      "getprepaidservicebldbalance",
    ]),
     getCashup: (context, item) => {
      console.log("cash up session: ", item);
      context.collected_by = `${item.collector_firstname} ${item.collector_surname}`;
      context.verifieddate = item.verifieddate;
      context.APPROVECASHUP.cashup_id = item.collection_session_id;
      context.amount_collected =
        item.collection_total_receipts_amount*-1;

      $("#ApproveModal").modal("show");
    },

    verifyCashUp: (context, item) => {
      
      console.log("cash up details: ",item);
      context.VERIFYCASHUP.cashup_id =  item.collection_session_id
      // context.GETSELECTED = item;
      $("#modal-verify").modal("show");
    },
    verifySession: (context, item) => {
      
      var res = $('form[name="form-wizard"]')
        .parsley()
        .validate("verifysession");
      if (!res) return res;
        context.$Notify.YesNo(false, { context, item }).then((value) => {
          if (value) {
            value.item.comments = document.getElementById(
        "clipboard-textarea2"
      ).value;
            value.context.$wait.start("add");
            value.router = value.context.$router;
            value.context.executeVerify({
                router: value.router,
                AddModel: value.item
              })
              .then(() => {
                value.context.pendingCashup(value.context, true);
                value.context.pendingApprovalCashup(value.context);

                $("#modal-verify").modal("hide");
                value.context.$Notify.Success("Session Has Been Verified!", "");
                value.context.$wait.end("add");

              })
              .catch((error) => {
                value.context.$wait.end("add");

                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    FILTERBYROLE: (context) => {
      $(".byrole").each(function () {
        if (
          context.USERROLES.length > 0 &&
          context.USERROLES.filter(
            (item) => item._code === $(this).attr("data-role")
          ).length > 0
        )
          $(this).removeClass("hide");
      });
    },
    getBLDBALANCE: (context, taxiassociationid) => {
      
      context
        .getprepaidservicebldbalance({
          router: context.$router,
          AddModel:{
            taxiassociations_id: taxiassociationid,
            systemusers_id: context.USERID,
            reference: 'Balance Enquiry By ' + context.LoggedInUserFullName
          }
        })
        .then((rb) => {
          context.BLDBALANCE = parseFloat(rb.data[0].data.AccountInfo.Account.TotalBalance);
          context.BLDAVLBALANCE = parseFloat(rb.data[0].data.AccountInfo.Account.AvailableBalance);
        })
        .catch((error) => {
          console.log("operator errors:", error);
        })
        .finally(() => {
        });
    },
    getbyid: (context, taxiassociationid) => {
      context
        .getMerchantsByAssId({
          router: context.$router,
          id: taxiassociationid,
        })
        .then((data) => {
          console.log("operators found: ", data);
          context.GETMERCHANTSBYASSID = data;
          context.EasyPayBalance = data[0].taxiassmerchaccount_bal;
          context.EasyPayNo  = data[0].taxiassmerch_acc;
          context.dataLoaded = true;
        })
        .catch((error) => {
          console.log("operator errors:", error);
        })
        .finally(() => {
          $("#data-table-default").DataTable({
            responsive: true,
          });
        });
    },
    startcashupsession: (context,item, agent_id, startedby) => {
      
    context.$Notify.YesNo(false, { context,item, agent_id, startedby }).then((value) => {
        
        if (value) {
          value.context.$wait.start("startcashup-" + value.agent_id);

          value.context
            .startCashupSession({
              router: value.context.$router,
              taxiassociationid: value.context.taxiassociationid,
              agent_id: value.agent_id,
              addedby: value.startedby,
              geolocation: context.geolocation,
            })
            .then((data) => {
              
              value.item.cashup_sessionid_id = data[0].collection_session_id
              value.item.cashup_start_date = data[0].collection_start_date
              value.item.cashup_balance = data[0].collection_total_receipts_amount
              value.context.$wait.end("startcashup-" + value.agent_id);
              value.context.$Notify.Success("CashUp Started Successfully...!", data.agent_id);
            })
            .catch((error) => {
              value.context.$wait.end("startcashup-" + value.agent_id);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    endcashupsession: (context, item,id, endedby) => {
      context.$Notify.YesNo(false, { context, item,id, endedby }).then((value) => {
        
        if (value) {
          value.context.$wait.start("endcashup-" + value.id);

          value.context
            .endCashupSession({
              router: value.context.$router,
              taxiassociationid: value.context.taxiassociationid,
              cashup_session_id: value.id,
              endedby: value.endedby,
              geolocation: context.geolocation,
            })
            .then((data) => {
              value.context.pendingCashup(value.context, true);
              value.item.cashup_sessionid_id = null
              value.item.cashup_start_date = ''
              value.item.cashup_balance = ''
              value.context.$wait.end("endcashup-" + value.id);
              value.context.$Notify.Success("CashUp Ended Successfully...", data.id);

            })
            .catch((error) => {
              value.context.$wait.end("endcashup-" + value.id);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    pendingCashup: (context, increment) => {
      if (increment) context.skip += 0;
      else {
        if (context.skip > 0) context.skip -= 10;
      }

      context
        .getPendingCashup({
          taxiassociationid: context.taxiassociationid,
          skip: context.skip,
          len: context.length,
          router: context.$router,
        })
        .then((data) => {
          
          console.log("pending verification: ", data);
          context.PENDINGCASHUP = data;
        })
        .catch((error) => {
          console.log("collectors errors: ", error);
        });
    },
    pendingApprovalCashup: (context) => {
      context
        .getPendingApproval({
          status: context.taxiassociationid,
          skip: context.skip,
          len: context.length,
          router: context.$router,
        })
        .then((data) => {
          context.PENDINGAPPROVAL = data;
        });
    },
    approvedCashup: (context, increment) => {
      if (increment) context.skip += 0;
      else {
        if (context.skip > 0) context.skip -= 10;
      }

      context
        .getApprovedCashup({
          status: context.taxiassociationid,
          skip: context.skip,
          len: context.length,
          router: context.$router,
        })
        .then((data) => {
          context.APPROVEDCASHUP = data;
        })
        .catch((error) => {});
    },
    ApproveVerified: (context, item) => {
      
      

      context.$Notify.YesNo(false, { context, item }).then((value) => {
        
        if (value) {
          // value.context.$wait.start("approve-");

          value.context.$wait.start("Approve-" + value.item.cashup_id);

          value.item.comments = document.getElementById(
        "clipboard-textarea"
      ).value;
          value.router = value.context.$router;
          value.context.ApproveVerifiedCashUp({
              router: value.router,
              AddModel: item,
            })
            .then((data) => {

            value.context.approvedCashup(value.context, true);


              $("#ApproveModal").modal("hide");
              value.context.$wait.end("Approve-" + value.item.cashup_id);
              value.context.pendingApprovalCashup(value.context);
              // value.context.$wait.end("approve-");
              value.context.$Notify.Success("Approved Successfully!", "");
            })
            .catch((error) => {
              value.context.$wait.end("Approve-" + value.item.cashup_id);
              console.log("start session errors: ", error);
              // value.context.$wait.end("approve-");

              value.context.$Notify.Error(error.data.data.message, "");
              $("#ApproveModal").modal("hide");
            })
            .finally(() => {});
        }
      });
    },
    Delete: (context, id, deletedby) => {
      context.$Notify.YesNo(false, { context, id, deletedby }).then((value) => {
        
        if (value) {
          value.context.$wait.start("delete-" + value.id);

          value.context
            .markAsDeleted({
              router: value.context.$router,
              id: value.id,
              deletedby: value.deletedby,
            })
            .then((data) => {
              value.context.$wait.end("delete-" + data.id);
              value.context.$Notify.Success("Successfully Deleted!", data.id);
            })
            .catch((error) => {
              value.context.$wait.end("delete-", error.data);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    Restore: (context, id, restoredby) => {
      context.$Notify
        .YesNo(false, { context, id, restoredby })
        .then((value) => {
          
          if (value) {
            value.context.$wait.start("restore-" + value.id);

            value.context
              .restoreDeleted({
                router: value.context.$router,
                id: value.id,
                restoredby: value.restoredby,
              })
              .then(() => {
                value.context.$wait.end("restore-" + value.id);
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch((error) => {
                value.context.$wait.end("restore-" + value.id);
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
  },
  beforeDestroy: function () {},
  mounted: function () {
    this.getbyid(this.context, this.taxiassociationid);
    this.getBLDBALANCE(this.context, this.taxiassociationid);
    this.pendingCashup(this.context, true);
    this.pendingApprovalCashup(this.context);
    this.approvedCashup(this.context, true);
    App.init();

    $(".byrole").addClass("hide");

    // TableManageCombine.init();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

