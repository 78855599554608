<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Approvals'"
      :ActiveMenu="'DataCollection'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Data Collection"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Captured"
        breadcrumbitem3="Manage"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!--Begin col-lg-12-->
        <div class="col-lg-12">
          <!-- begin panel -->
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">Data Collected</h4>
            </div>
            <!-- end panel-heading -->

            <!-- <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <a
                  data-toggle="modal"
                  data-target="#AddModal"
                  class="btn btn-white"
                  href="javascript:;"
                  @click="clearAdded(GETSELECTED)"
                >
                  <i class="fa fa-plus"></i>
                  Add getalls
                </a>
              </div>
            </div>-->

            <!-- begin panel-body -->
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="text-nowrap">Username</th>
                    <th class="text-nowrap">Approved</th>
                    <th class="text-nowrap">Operators Added</th>
                    <th class="text-nowrap">Approved Operators</th>
                    <th class="text-nowrap">Rejected Operators</th>
                    <th class="text-nowrap">Vehicles Added</th>
                    <th class="text-nowrap">Approved Vehicles</th>
                    <th class="text-nowrap">Month</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item) in DATACAPTURED" v-bind:key="item.added_by">
                    <td>{{item.username}}</td>
                    <td class="alert alert-primary fade show">{{item.approved_total}}</td>
                    <td class="alert alert-primary fade show">{{item.number_of_operators}}</td>
                    <td class="alert alert-success fade show">{{item.approved_operators}}</td>
                    <td class="alert alert-danger fade show">{{item.rejected_operators}}</td>
                    <td class="alert alert-primary fade show">{{item.number_of_vehicles}}</td>
                    <td class="alert alert-success fade show">{{item.approved_vehicles}}</td>
                    <td>{{item.month}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
          <!--End col-lg-12-->
        </div>

        <!-- end row -->
      </div>
      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("approvals");

export default {
  name: "Manageapprovals",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      context: this,
      addedsuccess: undefined,
      errormessage: undefined,
      id: this.$route.params.id,
      DATACAPTURED: [],
      totalAdded: null,
      GETSELECTED: Object.assign(
        {},
        this.$store.getters["approvals/GETALL"]
      )[0],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid
      //  GETSELECTED: this.$store.getters["approvals/GETALL"],
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["GETALL"]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "getAllToBeApproved",
      "getByPaging",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "markAsdelete",
      "restoreDeleted",
      "executeapproval"
    ]),

    getCapturedById: context => {
      
      context.$store
        .dispatch("approvals/getCaputedById", {
          router: context.$router,
          id: context.id
        })
        .then(data => {
          console.log("captured Found", data);

          context.DATACAPTURED = data;
        })
        .catch(error => {
          console.log("Çaputred errors: ", error);
        });
    }
  },
  beforeMount: function() {},
  mounted: function() {
    App.init();
    this.getCapturedById(this.context);
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

