/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../../../configurations/configbasetpay';


export default {
  namespaced: true,
  state: {
    dashboard_cards: [],
    dashboardfinancestats: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETDASHBOARDCARDS: state => state.dashboard_cards,
    GETDASHBOARDFINSTATS: state => state.dashboardfinancestats,
  },
  mutations: {
    LOADEDDASHBOARDFINSTATS: (state, _data) => {
      if (_data !== undefined) {
        state.dashboardfinancestats = _data;
      }
    },
    LOADEDDASHBOARDCARDS: (state, _data) => {
        if (_data !== undefined) {
          state.dashboard_cards = _data;
        }
      },
      LOADEDFINANCE: (state, _data) => {
        if (_data !== undefined) {
          state.dashboardfinance = _data;
        }
      },
  },
  actions: {
    /* Async */
    Pay({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = config.FIN_COMMUTER_URL("TakeBookingPayment");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(payload),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    CheckInBooking({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = config.FIN_AGENT_URL("CheckInCommuters");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(payload),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    CancelBooking({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = config.FIN_COMMUTER_URL("CancelBooking");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(payload),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetBookingBySession({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = config.FIN_COMMUTER_URL("GetBookingBySession");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(payload),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    AssignVehicleToBooking({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = config.FIN_COMMUTER_URL("AssignVehicleToBooking");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(payload),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetAllVehiclesById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {

      
      
      const url = config.ADMIN_URL("GetAllVehiclesById");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(payload),
          }).then(response => response.json()).then((parsedResult) => {
            
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    getAllVehicles({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {

      
      
      const url = config.ADMIN_URL("GetAllVehicles");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
          }).then(response => response.json()).then((parsedResult) => {
            
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    getallbookingslotsInternal({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = config.REPORTS_URL("GetAllBookingSlotsInternal");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(payload),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    getallbookingslots({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = config.REPORTS_URL("GetAllBookingSlots");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'post',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(payload),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetRoutesByRankId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = config.FIN_COMMUTER_URL("GetRoutesByRankId");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(payload),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetAllRanksByTaxiAssId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = config.FIN_COMMUTER_URL("GetAllRanksByTaxiAssId");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(payload),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    getAllRanks({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = config.FIN_COMMUTER_URL("GetAllRanks");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    getbookingsotsdashboardcards({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = config.REPORTS_URL("bookingsslotscards");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(payload),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    getbookingsotsdashboardcardsInternal({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = config.REPORTS_URL("bookingsslotscardsInternal");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    getoperatorfinstatsbyId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {

      
      
      const url = config.REPORTS_URL("bookingsstatsbyid");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(payload),
          }).then(response => response.json()).then((parsedResult) => {
            
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },

    getoperatorfinstats({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = config.REPORTS_URL("bookingsstats");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },


    getdashboardcards({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = config.REPORTS_URL("bookings");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },

    getbookingscards({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
        apiName: config.TPAYDASHBOARDBOOKINGSTATS_URL,
      };
  
      this._vm.$TPAY_API.getCustomURL(requestheader
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.Status) {
          //commit('LOADEDDASHBOARDFINSTATS', parsedResultOP.data[0]);
          resolve(parsedResultOP.Data[0]);
        }
      }).catch((error) => {
        console.log(error);
        reject(error);
      });
    });
    },

    getoperatorfinstatstop5({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      const url = config.REPORTS_URL("BookingsStatsTop5");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },

}
};
