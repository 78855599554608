<template>
<div>
 <!-- begin breadcrumb -->
			<ol class="breadcrumb pull-right">
				<li v-if="breadcrumbitem1" class="breadcrumb-item">
					<a href="javascript:;">
						{{breadcrumbitem1}}
						</a>
					</li>
				<li v-if="breadcrumbitem2"  class="breadcrumb-item"><a href="javascript:;">{{breadcrumbitem2}}</a></li>
				<li v-if="breadcrumbitem3" class="breadcrumb-item active">{{breadcrumbitem3}}</li>
			</ol>
			<!-- end breadcrumb -->

      <!-- begin page-header -->
      <h1 class="page-header">
        {{pageheaderTitle}}
        <small>{{pageheaderdescription}}</small>
      </h1>
      <!-- end page-header -->

</div>
</template>

<script>
export default {
	name:'headerpagebreadcrumb',
	props: ['pageheaderTitle','pageheaderdescription','breadcrumbitem1','breadcrumbitem2','breadcrumbitem3'],
}
</script>

<style scoped>

</style>
