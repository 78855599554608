<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'FleetManagement'"
      :ActiveMenu="'Inventory'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="All Inventory"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Fleet Management"
        breadcrumbitem3="Inventory"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->
      <!-- begin row -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">All Inventory</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                
                <button type="button" data-toggle="modal" data-target="#addInventory" class="btn btn-secondary">Add Inventory</button>

              </div>
            </div>

            <br />

            <div v-if="!dataLoaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-default" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Type</th>
                    <th class="text-nowrap">Serial No</th>
                    <th class="text-nowrap">ID</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Reg No</th>
                    <th class="text-nowrap">Added By</th>
                    <th class="text-nowrap">Added Date</th>
                    <th class="text-nowrap">Updated By</th>
                    <th class="text-nowrap">Updated Date</th>
                    <th class="text-nowrap hidden-print">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETOPERATORSBYASSID"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.inventory_type}}</td>
                    <td>{{item.serialno}}</td>
                    <td>{{item.identity2}}</td>
                    <td> <span
                  class="badge f-s-12 badge-success"
                >{{item.inventory_status_name}}</span></td>
                    <td>{{item.licence_number}}</td>
                    <td>{{item.addedbyname}}</td>
                    <td>{{item.date_added}}</td>
                    <td>{{item.updatedbyname}}</td>
                    <td>{{item.date_updated}}</td>
                    <td>
                      <div class="btn-group" role="group" aria-label="Basic example">
                            <button :disabled="$wait.is('UpdateInventory-'+item.id)" @click="UpdateInventory(context,item,'SHDP')" type="button" v-if="ISINTERNALSYSTEMUSER && item.inventory_status==='ADD' 
                            && item.fk_taxiassociation_id===null" class="btn btn-primary">
                            <v-wait v-bind:for="'UpdateInventory-'+ item.id">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Shipped To Client
                          </v-wait>
                          </button>
                            <button :disabled="$wait.is('UpdateInventory-'+item.id)" @click="UpdateInventory(context,item,'RCD')" type="button" v-if="!ISINTERNALSYSTEMUSER && item.inventory_status==='SHDP' 
                            && item.fk_taxiassociation_id!==null" class="btn btn-warning">
                            <v-wait v-bind:for="'UpdateInventory-'+ item.id">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Received
                          </v-wait>
                          </button>
                            <button :disabled="$wait.is('UpdateInventory-'+item.id)" @click="UpdateInventory(context,item,'RCDB')" type="button" v-if="!ISINTERNALSYSTEMUSER && item.inventory_status==='UID' 
                            && item.fk_taxiassociation_id!==null" class="btn btn-danger">
                            <v-wait v-bind:for="'UpdateInventory-'+ item.id">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Received From Technician
                          </v-wait>
                          </button>
                            <button :disabled="$wait.is('UpdateInventory-'+item.id)" @click="UpdateInventory(context,item,'SHDPB')" type="button" v-if="!ISINTERNALSYSTEMUSER && item.inventory_status==='RCDB' 
                            && item.fk_taxiassociation_id!==null" class="btn btn-primary">
                            <v-wait v-bind:for="'UpdateInventory-'+ item.id">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Returned To Supplier
                          </v-wait>
                          </button>
                            <button :disabled="$wait.is('UpdateInventory-'+item.id)" @click="UpdateInventory(context,item,'RTND')" type="button" v-if="ISINTERNALSYSTEMUSER && item.inventory_status==='SHDPB' 
                            && item.fk_taxiassociation_id!==null" class="btn btn-warning">
                            <v-wait v-bind:for="'UpdateInventory-'+ item.id">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Returned
                          </v-wait>
                          </button>
                        </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      

      <!-- begin #Main Body Template -->
         <!-- Add Modal HTML START-->
         <div id="addInventory" class="modal fade">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Add Inventory</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Type</label>
                            <div class="col-md-9">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="ADDINVENTORY.Type"
                              >
                                <option value="0" selected>Type</option>
                                <option
                                  v-bind:key="item.id"
                                  v-for="item in GETALLInventoryTypes"
                                  :value="item.id"
                                >{{item.name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group row -->
                           <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Serial No</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                placeholder="Enter Account Number"
                                class="form-control"
                                minlength="3"
                                v-model="ADDINVENTORY.Serial"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                           <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Identity</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                placeholder="Enter Account Number"
                                class="form-control"
                                minlength="3"
                                v-model="ADDINVENTORY.Identity"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        />
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="AddInventory(context,ADDINVENTORY)"
                          :disabled="$wait.is('AddInventory')"
                        >
                          <v-wait v-bind:for="'AddInventory'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Add
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Add Modal HTML START-->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("fleetmanagement");

export default {
  name: "ManageOperators",
  data: function () {
    return {
      ADDINVENTORY:{
        taxiassociation_id: this.$route.params.id || this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
        Type:0,
        Serial:"",
        Identity:"",
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      skip: 0,
      length: 1000000,
      GETOPERATORSBYASSID: [],
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      taxiassociationid: this.$route.params.id || this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      // accountid: this.$store.getters["TaxiDrivers/GETDRIVERACCOUNT"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      dataLoaded: false,
      CANCELCONTRACT: {
        SystemUserId: this.$store.getters["auth/USERPROFILE"].system_usersid,
        DebtorPhoneNumber: "27826869834",
        contractReference: "",
        clientReference: "",
        mandateID: "",
      },
      CREATECONTRACT: {
        TaxiAssociationId : this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
        TaxiOperatorId :1,
        SystemUserId : this.$store.getters["auth/USERPROFILE"].system_usersid,
        Frequency: "MNTH",
        CollectionDay: "01",
        ClientReference: "MobifyTracker",
        DebtorName: "Manyowa Nong",
        DebtorId: "8107055561083",
        DebtorIdType: "2", //* ID NUMBER */
        DebtorAccountNumber: "62358375155",
        DebtorAccountType: "01",
        DebtorBankId: "3",
        DebtorBranchNumber: "250805",
        DebtorPhoneNumber: "27826869834",
        CollectionAmount: 5,
        StartDate: "2023-02-06"
      },
      GETALLInventoryTypes:[],
    };
  },
  beforeMount: function () {
    this.FILTERBYROLE(this.context);
    window.PushManager.listenToIncomingMessages(this.context);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters({}),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions([
      "getAllInventoryTaxiAssId","GetInventoryTypes"
    ]),
    FILTERBYROLE: (context) => {
      $(".byrole").each(function () {
        if (
          context.USERROLES.length > 0 &&
          context.USERROLES.filter(
            (item) => item._code === $(this).attr("data-role")
          ).length > 0
        )
          $(this).removeClass("hide");
      });
    },
    UpdateInventory: (context, item,Code) => {
      
      context.$Notify.YesNo(false, { context, item, Code }).then((value) => {
        
        if (value) {
          value.context.$wait.start("UpdateInventory-"+value.item.id);

            value.context.$store
            .dispatch("fleetmanagement/UpdateInventory", {
              taxiassociation_id: value.context.taxiassociationid,
              inventoryid:  value.item.id,
              statuscode: value.Code,
              updatedby: value.context.USERID,
            }
            )
            .then((results) => {
              if(results.status){
                value.context.context.$Notify.Success("Updated","");
                value.context.getbyid(value.context);
              } else {
                value.context.context.$Notify.Error("FAILED","");
              }
             
            }).finally(()=>{
            value.context.$wait.end("UpdateInventory-"+value.item.id);
        });
        }
      });
    },
    AddInventory: (context, item) => {
      
      context.$Notify.YesNo(false, { context, item }).then((value) => {
        
        if (value) {
          value.context.$wait.start("AddInventory");

            value.context.$store
            .dispatch("fleetmanagement/AddInventory", value.item)
            .then((results) => {
              if(results.status){
                value.context.context.$Notify.Success("ADDED","");
                value.context.getbyid(value.context);
                $("#addInventory").modal("hide")
              } else {
                value.context.context.$Notify.Error("FAILED","");
              }
             
            }).finally(()=>{
            value.context.$wait.end("AddInventory");
        });
        }
      });
    },
    CaptureNuPayContract: (context, item) => {
      
      context.$Notify.YesNo(false, { context, item }).then((value) => {
        
        if (value) {
          value.context.$wait.start("CaptureNuPayContract-" + value.item.id);

          value.context.$store
        .dispatch("fleetmanagement/CaptureNuPayContract", value.context.CREATECONTRACT)
        .then((results) => {
          debugger;
          if(!results.Status){
            value.context.context.$Notify.Error(results.Data.Status,
            results.Data.ErrorMessage.ErrorMessage);
            return;
          } else {

            value.context.CANCELCONTRACT.DebtorPhoneNumber = value.context.CREATECONTRACT.DebtorPhoneNumber;
            value.context.CANCELCONTRACT.contractReference = value.item.nupaycontract_ref;
            value.context.CANCELCONTRACT.ClientReference = value.item.id.toString();
            value.context.CANCELCONTRACT.mandateID = value.item.mandateid;

            if(value.context.CANCELCONTRACT.mandateID !== ""){
              value.context.$store
            .dispatch("fleetmanagement/CancelNuPayContract", value.context.CANCELCONTRACT)
            .then((results) => {
              if(results.Status){
                value.context.context.$Notify.Success("CONTRACT UPDATED","");
              } else {
                value.context.context.$Notify.Error("FAILED TO UPDATE CONTRACT","");
              }
             
            })
            } else {
              value.context.context.$Notify.Success("CONTRACT CAPTURED","");
            }

          }
        })
        .catch((error) => {
          value.context.context.$Notify.Error(error.Data.Status,
          error.Data.ErrorMessage.ErrorMessage);
        }).finally(()=>{
          value.context.$wait.end("CaptureNuPayContract-" + value.item.id);
        });
        }
      });
    },
    getbyid: (context) => {
      context
        .getAllInventoryTaxiAssId({
          taxiassid: context.taxiassociationid,
          queryby: context.USERID
        })
        .then((results) => {
          console.log("operators found: ", results);
          context.GETOPERATORSBYASSID = results.data[0].data;
        })
        .catch((error) => {
          console.log("operator errors:", error);
        })
        .finally(() => {
          var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };

                  if(!context.dataLoaded){
                    $("#data-table-default").DataTable(options);
                    context.dataLoaded = true;
                  }
        });
    },
    Get_InventoryTypes: (context) => {
      context
        .GetInventoryTypes({
          queryby: context.USERID
        })
        .then((results) => {
          console.log("operators found: ", results);
          context.GETALLInventoryTypes = results.data[0].data;;
        })
        .catch((error) => {
          console.log("operator errors:", error);
        })
    },
    Delete: (context, id, deletedby) => {
      context.$Notify.YesNo(false, { context, id, deletedby }).then((value) => {
        
        if (value) {
          value.context.$wait.start("delete-" + value.id);

          value.context
            .markAsDeleted({
              router: value.context.$router,
              id: value.id,
              deletedby: value.deletedby,
            })
            .then((data) => {
              value.context.GETOPERATORSBYASSID.filter(item => item.taxi_operator_id === value.id)[0].deleted_by = value.deletedby;
              value.context.GETOPERATORSBYASSID.filter(item => item.taxi_operator_id === value.id)[0].is_active = false;

              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Success("Successfully Deleted!", value.id);
            })
            .catch((error) => {
              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    Restore: (context, id, restoredby) => {
      context.$Notify
        .YesNo(false, { context, id, restoredby })
        .then((value) => {
          
          if (value) {
            value.context.$wait.is("restore-" + value.id);

            value.context
              .restoreDeleted({
                router: value.context.$router,
                id: value.id,
                restoredby: value.restoredby,
              })
              .then(() => {
                value.context.GETOPERATORSBYASSID.filter(item => item.taxi_operator_id === value.id)[0].deleted_by = null;
                value.context.GETOPERATORSBYASSID.filter(item => item.taxi_operator_id === value.id)[0].is_active = true;
                value.context.$wait.end("restore-" + value.id);
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch((error) => {
                value.context.$wait.end("restore-" + value.id);
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
  },
  beforeDestroy: function () {},
  mounted: function () {

    this.context = this; 

    this.getbyid(this.context);
    this.Get_InventoryTypes(this.context);
    
    App.init();

    

    $(".byrole").addClass("hide");

    // TableManageCombine.init();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

