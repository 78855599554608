<template>
  <div class="home; page-content-full-height page-sidebar-fixed page-header-fixed">
      <!-- begin #page-container -->
      <!-- begin #header -->
      <headerComp
        :AddTaxiOperatorDropDownControl="true"
        :AddTopSearchControl="true"
        :AddTopNotificationControl="true"
        :isAdminUser="USERPROFILE.is_system_admin"
      />
      <!-- begin #header -->
      <!-- end page container -->

      <!-- begin #sidebar -->
      <sidebarComp
        :USERROLES="ROLES"
        :USERPROFILE="USERPROFILE"
        :ActiveSubMenu="'Emails'"
        :ActiveMenu="'Inbox'"
      />
      <!-- end #sidebar -->
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Inbox"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Emails"
        breadcrumbitem3="Inbox"
      />
      <!-- end page-header -->

      <!-- begin #content -->
      <div id="content" class="content content-full-width inbox">
        <!-- begin vertical-box -->
        <div class="vertical-box with-grid">
          <!-- begin vertical-box-column -->
          <div class="vertical-box-column width-200 bg-silver hidden-xs">
            <!-- begin vertical-box -->
            <div class="vertical-box">
              <!-- begin wrapper -->
              <div class="wrapper bg-silver text-center">
                <router-link to="/Home/Compose" class="btn btn-inverse p-l-40 p-r-40 btn-sm">Compose</router-link>
              </div>
              <!-- end wrapper -->
              <!-- begin vertical-box-row -->
              <div class="vertical-box-row">
                <!-- begin vertical-box-cell -->
                <div class="vertical-box-cell">
                  <!-- begin vertical-box-inner-cell -->
                  <div class="vertical-box-inner-cell">
                    <!-- begin scrollbar -->
                    <div data-scrollbar="true" data-height="100%">
                      <!-- begin wrapper -->
                      <div class="wrapper p-0">
                        <div class="nav-title">
                          <b>FOLDERS</b>
                        </div>
                        <ul class="nav nav-inbox">
                          <li class="active">
                            <router-link :to="{ name: 'inbox', params: {id: USERID } }">
                              <i class="fa fa-inbox fa-fw m-r-5"></i> Inbox
                              <span class="badge pull-right">{{GETTOTALUNREAD}}</span>
                            </router-link>
                          </li>
                          <li>
                            <router-link to="/sent ">
                              <i class="fa fa-envelope fa-fw m-r-5"></i> Sent
                            </router-link>
                          </li>
                          <li>
                            <router-link to="/trash">
                              <i class="fa fa-trash fa-fw m-r-5"></i> Trash
                            </router-link>
                          </li>
                        </ul>
                      </div>
                      <!-- end wrapper -->
                    </div>
                    <!-- end scrollbar -->
                  </div>
                  <!-- end vertical-box-inner-cell -->
                </div>
                <!-- end vertical-box-cell -->
              </div>
              <!-- end vertical-box-row -->
            </div>
            <!-- end vertical-box -->
          </div>
          <!-- end vertical-box-column -->
          <!-- begin vertical-box-column -->
          <div class="vertical-box-column bg-white">
            <!-- begin vertical-box -->
            <div class="vertical-box">
              <!-- begin wrapper -->
              <div class="wrapper bg-silver-lighter">
                <!-- begin btn-toolbar -->
                <div class="btn-toolbar">
                  <div class="btn-group m-r-5">
                    <a
                      href="javascript:;"
                      class="p-t-5 pull-left m-r-3 m-t-2"
                      data-click="email-select-all"
                    >
                      <i class="far fa-square fa-fw text-muted f-s-16 l-minus-2"></i>
                    </a>
                  </div>
                  <!-- begin btn-group -->
                  <div class="btn-group dropdown m-r-5">
                    <button class="btn btn-white btn-sm" data-toggle="dropdown">
                      View All
                      <span class="caret m-l-3"></span>
                    </button>
                    <ul class="dropdown-menu text-left text-sm">
                      <li class="active">
                        <a>
                          <i class="fa fa-circle f-s-10 fa-fw m-r-5"></i> All
                        </a>
                      </li>
                      <li>
                        <a href="javascript:;">
                          <i class="fa fa-circle f-s-10 fa-fw m-r-5"></i> Unread
                        </a>
                      </li>
                      <li>
                        <a href="javascript:;">
                          <i class="fa fa-circle f-s-10 fa-fw m-r-5"></i> Contacts
                        </a>
                      </li>
                      <li>
                        <a href="javascript:;">
                          <i class="fa fa-circle f-s-10 fa-fw m-r-5"></i> Groups
                        </a>
                      </li>
                      <li>
                        <a href="javascript:;">
                          <i class="fa fa-circle f-s-10 fa-fw m-r-5"></i> Newsletters
                        </a>
                      </li>
                      <li>
                        <a href="javascript:;">
                          <i class="fa fa-circle f-s-10 fa-fw m-r-5"></i> Social updates
                        </a>
                      </li>
                      <li>
                        <a href="javascript:;">
                          <i class="fa fa-circle f-s-10 fa-fw m-r-5"></i> Everything else
                        </a>
                      </li>
                    </ul>
                  </div>
                  <!-- end btn-group -->
                  <!-- begin btn-group -->
                  <div class="btn-group m-r-5">
                    <button @click="getall(context)" class="btn btn-sm btn-white">
                      <i class="fa fa-redo f-s-14 t-plus-1"></i>
                    </button>
                  </div>
                  <!-- end btn-group -->
                  <!-- begin btn-group -->
                  <div class="btn-group">
                    <button 
                    @click="deleteNow(context, selectedmessages, USERID)"
                    class="btn btn-sm btn-white hide" data-email-action="delete">
                      <i class="fa t-plus-1 fa-times f-s-14 m-r-3"></i>
                      <span class="hidden-xs">Delete</span>
                    </button>
                  </div>
                  <!-- end btn-group -->
                  <!-- begin btn-group -->
                  <div class="btn-group ml-auto">
                    <button @click="show(context,false);" class="btn btn-white btn-sm">
                      <i class="fa fa-chevron-left f-s-14 t-plus-1"></i>
                    </button>
                    <button @click="show(context,true);" class="btn btn-white btn-sm">
                      <i class="fa fa-chevron-right f-s-14 t-plus-1"></i>
                    </button>
                  </div>
                  <!-- end btn-group -->
                </div>
                <!-- end btn-toolbar -->
              </div>
              <!-- end wrapper -->
              <!-- begin vertical-box-row -->
              <div class="vertical-box-row">
                <!-- begin vertical-box-cell -->
                <div class="vertical-box-cell">
                  <!-- begin vertical-box-inner-cell -->
                  <div class="vertical-box-inner-cell">
                    <!-- begin scrollbar -->
                    <div data-scrollbar="true" data-height="100%">
                      <!-- begin list-email -->
                      <ul class="list-group list-group-lg no-radius list-email">
                        <li
                          :class="{'list-group-item unread': !item._date_read, 'list-group-item': item._date_read}"
                          v-for="item in GETALLINBOX"
                          :key="item._id"
                        >
                          <div class="email-checkbox">
                            <label>
                              <i class="far fa-square"></i>
                              <input type="checkbox" 
                              v-model="selectedmessages"
                              id="email-checkbox"
                              :value="item._id"
                              data-checked="email-checkbox" />
                            </label>
                          </div>
                          <router-link
                            :to="{ name: 'emaildetails', params: { id: item._id }}"
                            :class="colorPicker(item._sent_by_names.slice(0,1))"
                          >
                            <span class="text-white">{{item._sent_by_names.slice(0,1)}}</span>
                          </router-link>
                          <div class="email-info">
                            <router-link :to="{ name: 'emaildetails', params: { id: item._id }}">
                              <span class="email-time">{{item._date_sent | formatToHumanize}}</span>
                              <span class="email-sender">{{item._sent_by_names}}</span>
                              <span class="email-title">{{item._subject}}</span>
                              <span class="email-desc">{{item._message_content | striphtml}}</span>
                            </router-link>
                          </div>
                        </li>
                      </ul>
                      <!-- end list-email -->
                    </div>
                    <!-- end scrollbar -->
                  </div>
                  <!-- end vertical-box-inner-cell -->
                </div>
                <!-- end vertical-box-cell -->
              </div>
              <!-- end vertical-box-row -->
              <!-- begin wrapper -->
              <div class="wrapper bg-silver-lighter clearfix">
                <div class="btn-group pull-right">
                  <button @click="show(context,false);" class="btn btn-white btn-sm">
                    <i class="fa fa-chevron-left f-s-14 t-plus-1"></i>
                  </button>
                  <button @click="show(context,true);" class="btn btn-white btn-sm">
                    <i class="fa fa-chevron-right f-s-14 t-plus-1"></i>
                  </button>
                </div>
                <div
                  class="m-t-5 text-inverse f-w-600"
                > {{GETALLINBOX.length}} \  {{GETTOTALUNREAD}}  messages</div>
              </div>
              <!-- end wrapper -->
            </div>
            <!-- end vertical-box -->
          </div>
          <!-- end vertical-box-column -->
        </div>
        <!-- end vertical-box -->
      </div>
      <!-- end #content -->
      <!-- begin scroll to top btn -->
      <pagescroller />
      <!-- end scroll to top btn -->
    </div>
</template>

	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import headerComp from "@/components/common/header.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import pageloader from "@/components/common/pageloader.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";

import { createNamespacedHelpers } from "vuex";
import { setTimeout } from "timers";
const { mapGetters, mapActions } = createNamespacedHelpers("messageinbox");
// value.context.$emit("init");

export default {
  name: "Inbox",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      GETDRIVERSBYASSID: this.$store.getters["TaxiDrivers/GETDRIVERSBYASSID"],
      GETALLOPERATORS: this.$store.getters["TO/GETADDOPERATOR"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      selectedmessages: [],
      id: this.$route.params.id,
      context: this,
      skip: -30,
      len: 30,
      isactive: true,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      totalmessages: 0,
      GETALLINBOX: [],
    };
  },
  beforeMount: function() {
    // this.getall(this.context);
    this.show(this.context, true);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    CapturePhotosControl
  },

  computed: {
    ...mapGetters(["GETALLSENT", "GETTOTALUNREAD"]),
    // LoggedInUserFullName: function() {
    //   return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    // }
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  watch: {},
  methods: {
    ...mapActions([
      "getAllInbox",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "markAsRead",
      "deletemessage"
    ]),
    deleteNow(context, messageid, userid) {
        $("data-checked=email-checkbox").data
        context.$Notify.YesNo(false, { context, id:[messageid], deletedby:userid, }).then(value => {
        
        if (value) {
          //value.context.$wait.start("add-" + value.id);
          value.router = value.context.$router;
          value.context
            .deletemessage({ _dataObj: value })
            .then(id => {
              //value.context.$wait.end("add-" + id);
              value.context.$Notify.Success("Deleted Successfully!", "");
              id.context.$router.go(-1)
            })
            .catch(error => {
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    show: function(context, increment) {
      
      if (increment) context.skip += 30;
      else {
        if (context.skip > 0) context.skip -= 30;
      }

      context
        .getAllInbox({
          status: context.id,
          skip: context.skip,
          len: context.len,
          router: context.$router
        })
        .then((data) => {
          
          context.GETALLINBOX = data;
          
        });
    },

    gettotalMessages: context =>{
      if(context.GETALLINBOX._totalmessages > 0){
        context.totalmessages = context.GETALLINBOX[0]._totalmessages;
      }
    },
    getall: function(context){
      context
        .getAllInbox({
          status: context.USERID,
          skip: context.skip,
          len: context.len,
          router: context.$router
        })
        .then((data) => {
          console.log("These are the  get all inboxes:",data);
          context.GETALLINBOX = data;

        });
    },
    getSentEmails: context => {
      
      context.$store
        .dispatch("messageinbox/getSentById", {
          _dataObj: {
            id: context.USERID,
            router: context.router
          }
        })
        .then(() => {});
    },

    colorPicker: firstletter => {
      let csscolorpicker = "email-user ";

      switch (firstletter.toUpperCase()) {
        case "A":
          csscolorpicker += "bg-gradient-aqua";
          break;
        case "B":
          csscolorpicker += "bg-gradient-purple";
          break;
        case "C":
          csscolorpicker += "bg-gradient-green";
          break;
        case "D":
          csscolorpicker += "bg-gradient-blue";
          break;
        case "E":
          csscolorpicker += "bg-gradient-orange";
          break;
        case "F":
          csscolorpicker += "bg-gradient-yellow";
          break;
        case "G":
          csscolorpicker += "bg-gradient-red";
          break;
        case "H":
          csscolorpicker += "bg-gradient-black";
          break;
        case "I":
          csscolorpicker += "bg-gradient-blue";
          break;
        case "J":
          csscolorpicker += "bg-gradient-purple";
          break;
        case "K":
          csscolorpicker += "bg-gradient-yellow";
          break;
        case "L":
          csscolorpicker += "bg-gradient-red";
          break;
        case "M":
          csscolorpicker += "bg-gradient-green";
          break;
        case "N":
          csscolorpicker += "bg-gradient-aqua";
          break;
        case "O":
          csscolorpicker += "bg-gradient-blue";
          break;
        case "P":
          csscolorpicker += "bg-gradient-orange";
          break;
        case "Q":
          csscolorpicker += "bg-gradient-yellow";
          break;
        case "R":
          csscolorpicker += "bg-gradient-red";
          break;
        case "S":
          csscolorpicker += "bg-gradient-orange";
          break;
        case "T":
          csscolorpicker += "bg-gradient-purple";
          break;
        case "U":
          csscolorpicker += "bg-gradient-green";
          break;
        case "V":
          csscolorpicker += "bg-gradient-orange";
          break;
        case "W":
          csscolorpicker += "bg-gradient-yellow";
          break;
        case "X":
          csscolorpicker += "bg-gradient-red";
          break;
        case "Y":
          csscolorpicker += "bg-gradient-black";
          break;
        case "Z":
          csscolorpicker += "bg-gradient-aqua";
          break;
      }

      return csscolorpicker;
    }
  },
  mounted: function() {
    
    // this.getSentEmails(this.context);
    this.getall(this.context);
    this.gettotalMessages(this.context);

    App.init();

    $("[data-checked=email-checkbox]").data("context");
    $("[data-email-action]").data("context");
    

    window.PushManager.pushManagerHooks.addEventListener(
      "IncomingMessage",
      e => {
        console.log('Instance fired "something".', e);
        alert(e);
      }
    );

    handleEmailCheckboxChecked();
    
    handleEmailSelectAll();
  }
};



var handleEmailCheckboxChecked = function() {
  $(document).on("change", "[data-checked=email-checkbox]", function() {
    var context = $("[data-checked=email-checkbox]").data("context")
    var targetLabel = $(this).closest("label");
    var targetEmailList = $(this).closest("li");
    if ($(this).prop("checked")) {
      $(targetLabel).addClass("active");
      $(targetEmailList).addClass("selected");
      context.selectedmessages.push($(this).val()) 
    } else {
      $(targetLabel).removeClass("active");
      $(targetEmailList).removeClass("selected");
      context.selectedmessages.push($(this).val())
    }
  });
};

var handleEmailAction = function() {
  $(document).on("click", "[data-email-action]", function() {
    var context = $("[data-email-action]").data("context")
    var targetEmailList = "[data-checked=email-checkbox]:checked";
    if ($(targetEmailList).length !== 0) {
      $(targetEmailList)
        .closest("li")
        .slideToggle(function() {
          this.deleteNow(this.context, $(this).val(), this.userid)
          handleEmailActionButtonStatus();
          if ($(".list-email > li").length === 0) {
            $(".list-email").html(
              '<li class="p-15 text-center"><div class="p-20"><i class="fa fa-trash fa-5x text-silver"></i></div> This folder is empty</li>'
            );
          }
        });
    }
  });
};

var handleEmailSelectAll = function() {
  "use strict";
  $("[data-click=email-select-all]").click(function(e) {
    e.preventDefault();

    var targetIcon = $(this).find("i");
    if ($(targetIcon).hasClass("fa-check-square")) {
      $(targetIcon)
        .removeClass("fa-check-square text-inverse")
        .addClass("fa-square text-muted");
      $('.list-email .email-checkbox input[type="checkbox"]').prop(
        "checked",
        false
      );
    } else {
      $(targetIcon)
        .addClass("fa-check-square text-inverse")
        .removeClass("fa-square text-muted");
      $('.list-email .email-checkbox input[type="checkbox"]').prop(
        "checked",
        true
      );
    }
    $('.list-email .email-checkbox input[type="checkbox"]').trigger("change");
  });
};
</script>

	

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>