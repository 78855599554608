<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TaxiAssociation'"
      :ActiveMenu="'ManageTaxiAssociation'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Taxi Association Profile"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Association"
        breadcrumbitem3="Profile"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <div class="panel panel-inverse">
            <!-- begin fieldset -->
            <fieldset>
              <p class="text-center h4 mb-4">Association Details</p>
              <div class="row">
                <!-- begin col-6 -->
                <div class="col-lg-6">
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Taxi Affiliation
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        id="associationame"
                        name="associationame"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        :value="TAXIASSOCIATION._taxi_affiliation_name"
                      />
                    </div>
                  </div>
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      RAS Registration No.
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        id="associationame"
                        name="associationame"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        :value="TAXIASSOCIATION._ras_registration_number"
                      />
                    </div>
                  </div>

                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Taxi Association Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        id="associationame"
                        name="associationame"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        :value="TAXIASSOCIATION._name"
                      />
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Abbreviation
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        name="Abbreviation"
                        disabled
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        :value="TAXIASSOCIATION._abbriviated_name"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Telephone</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        id="telephone"
                        name="telephone"
                        class="form-control"
                        disabled
                        :value="TAXIASSOCIATION._telephone"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Mobile Number
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        id="mobile"
                        name="mobile"
                        class="form-control"
                        disabled
                        :value="TAXIASSOCIATION._mobilenumber"
                      />
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Contact Person Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        id="mobile"
                        name="mobile"
                        class="form-control"
                        disabled
                        :value="TAXIASSOCIATION._contact_person_name"
                      />
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Contact Person Number
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        id="mobile"
                        name="mobile"
                        class="form-control"
                        disabled
                        :value="TAXIASSOCIATION._contact_person_number"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Physical Address</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        id="physicalAddress"
                        name="physicalAddress"
                        class="form-control"
                        disabled
                        :value="TAXIASSOCIATION._physicaladdress"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Physical Address 2</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        id="physicalAddress2"
                        name="physicalAddress2"
                        class="form-control"
                        disabled
                        :value="TAXIASSOCIATION._physicaladdress2"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Physcal Suburb
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <select
                        class="getSelectedSuburb form-control"
                        data-size="10"
                        data-live-search="true"
                        data-parsley-group="step-1"
                        data-parsley-required="false"
                        disabled
                        :value="TAXIASSOCIATION._physicaladdress_id"
                      >
                        <option disabled value>Select a Suburb</option>
                        <option
                          v-bind:key="item._id"
                          v-for="item in GETALLSUBURS"
                          :value="item._id"
                        >{{ item._name }}</option>
                      </select>
                    </div>
                  </div>
                  <!-- End form-group -->
                </div>
                <!-- End 1st Row -->

                <!-- Begin Second Row -->
                <div class="col-lg-6">
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Current Number Operators
                      <span class="text-danger"></span>
                    </label>
                    <div class="col-md-6">
                      <td class="bg-silver-lighter">
                        <span class="text-inverse"></span>
                      </td>
                      <input
                        type="number"
                        id="currentOperators"
                        name="currentOperators"
                        data-parsley-group="step-1"
                        data-parsley-required="false"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Max Number Of Operators
                      <span class="text-danger"></span>
                    </label>
                    <div class="col-md-6">
                      <td class="bg-silver-lighter">
                        <span class="text-inverse"></span>
                      </td>
                      <input
                        type="number"
                        id="maxOperators"
                        name="maxOperators"
                        data-parsley-group="step-1"
                        data-parsley-required="false"
                        class="form-control"
                        :disabled="isDisabled"
                        :value="TAXIASSOCIATIONSETTINGS.maximum_number_members"
                      />
                    </div>
                  </div>

                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Max Number Of Taxis Per Operator
                      <span class="text-danger"></span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="number"
                        id="maxTaxiOperators"
                        name="maxTaxiOperators"
                        placeholder="Enter Number Or Leave Empty"
                        data-parsley-group="step-1"
                        data-parsley-required="false"
                        :disabled="inputDisabled"
                        class="form-control"
                        :value="TAXIASSOCIATIONSETTINGS.maximum_number_vehicles"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Has Taxi Operator Billing?
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <div class="switcher switcher-success">
                        <input
                          type="checkbox"
                          name="switcher_checkbox_operatorbilling"
                          id="switcher_checkbox_operatorbilling"
                          data-parsley-checkmin="1"
                          required
                          data-parsley-group="step-1"
                          data-parsley-required="true"
                          checked
                          true-value="true"
                          false-value="false"
                          :value="TAXIASSOCIATIONSETTINGS.create_operator_account"
                          disabled
                        />
                        <label for="switcher_checkbox_operatorbilling"></label>
                      </div>
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Has Taxi Vehicle Billing?
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <div class="switcher switcher-success">
                        <input
                          type="checkbox"
                          name="switcher_checkbox_Vehiclebilling"
                          id="switcher_checkbox_Vehiclebilling"
                          data-parsley-checkmin="1"
                          required
                          data-parsley-group="step-1"
                          data-parsley-required="true"
                          checked
                          true-value="true"
                          false-value="false"
                          :value="TAXIASSOCIATIONSETTINGS.create_vehicle_account"
                          disabled
                        />
                        <label for="switcher_checkbox_Vehiclebilling"></label>
                      </div>
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Has Driver Billing?
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <div class="switcher switcher-success">
                        <input
                          type="checkbox"
                          name="switcher_checkbox_Driverbilling"
                          id="switcher_checkbox_Driverbilling"
                          checked
                          true-value="true"
                          false-value="false"
                          disabled
                          :value="TAXIASSOCIATIONSETTINGS.create_driver_account"
                        />
                        <label for="switcher_checkbox_Driverbilling"></label>
                      </div>
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Postal Address
                      <span class="text-danger"></span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        placeholder
                        data-parsley-group="step-2"
                        data-parsley-required="false"
                        class="form-control"
                        disabled
                        :value="TAXIASSOCIATION._postalaaddress"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Postal Address 2
                      <span class="text-danger"></span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        placeholder
                        data-parsley-group="step-2"
                        data-parsley-required="false"
                        class="form-control"
                        :value="TAXIASSOCIATION._postalaaddress2"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Postal Suburb
                      <span class="text-danger"></span>
                    </label>
                    <div class="col-md-6">
                      <select
                        class="form-control"
                        data-size="100"
                        data-live-search="true"
                        data-parsley-group="step-2"
                        data-parsley-required="false"
                        disabled
                      >
                        <option disabled value>Select a Suburb</option>
                        <option
                          v-bind:key="item._id"
                          v-for="item in GETALLSUBURS"
                          :value="item._id"
                        >{{ item._name }}</option>
                      </select>
                    </div>
                  </div>
                  <!-- End form-group -->
                </div>
              </div>
              <div class="btn-row">
                <!-- <div class="text-center">
                  <button
                    class="btn btn-info btn-default m-r-5"
                    data-toggle="modal"
                    data-target="#moadal-editMoratorium"
                    name="btnTaxiSettings"
                    v-show="GETSELECTED._hastaxis_moratorium||GETSELECTED._hasmembers_moratorium"
                  >
                    <i class="fa fa-cogs"></i>Edit Moratorium
                  </button>
                </div>-->
              </div>
              <br />
            </fieldset>
            <!-- end fieldset -->
          </div>
        </div>
        <!-- end col-12 -->
      </div>
      <div class="modal fade" id="moadal-editMoratorium">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Moratorium</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
              <!-- begin form-group row -->
              <div class="form-group row m-b-10">
                <label class="col-md-3 text-md-right col-form-label">Max Operators</label>
                <div class="col-md-9">
                  <input
                    type="number"
                    data-parsley-group="step-1"
                    data-parsley-required="true"
                    class="form-control"
                  />
                </div>
              </div>
              <!-- end form-group row -->
              <!-- begin form-group row -->
              <div class="form-group row m-b-10">
                <label class="col-md-3 text-md-right col-form-label">Max Taxis Per Operator</label>
                <div class="col-md-9">
                  <input
                    type="number"
                    data-parsley-group="step-1"
                    data-parsley-required="true"
                    class="form-control"
                  />
                </div>
              </div>
              <!-- end form-group row -->
              <!-- begin form-group row -->
            </div>
            <div class="modal-footer">
              <a href="javascript:;" class="btn btn-white" data-dismiss="modal">Cancel</a>
              <button
                type="button"
                class="btn btn-sm btn-success width-80"
                @click="associationMoratoriumUpdate(context,GETSELECTED,USERPROFILE.system_usersid)"
                :disabled="$wait.is('add')"
              >
                <v-wait v-bind:for="'add'">
                  <template slot="waiting">
                    <i class="fas fa-spinner fa-spin"></i>
                  </template>
                  Edit
                </v-wait>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- begin tab-pane -->

      <div class="panel panel-inverse">
        <!-- begin nav-tabs -->
        <fieldset>
          <div class="col-lg-12">
            <!-- begin nav-pills -->
            <ul class="nav nav-pills">
              <li class="nav-items">
                <a href="#association-billings-tab" data-toggle="tab" class="nav-link active">
                  <span class="d-sm-none">Association Billings</span>
                  <span class="d-sm-block d-none">Association Billings</span>
                </a>
              </li>
              <li class="surporting-documents-tab">
                <a href="#addonbilling-tab" data-toggle="tab" class="nav-link">
                  <span class="d-sm-none">Subscription Information</span>
                  <span class="d-sm-block d-none">Add On Billing</span>
                </a>
              </li>

              <li class="nav-items">
                <a href="#association-route" data-toggle="tab" class="nav-link">
                  <span class="d-sm-none">Routes</span>
                  <span class="d-sm-block d-none">Routes</span>
                </a>
              </li>
              <!-- <li class="nav-items">
                <a href="#association-bank" data-toggle="tab" class="nav-link">
                  <span class="d-sm-none">Bank Details</span>
                  <span class="d-sm-block d-none">Bank Details</span>
                </a>
              </li> -->
              <!-- <li class="nav-items">
                <a href="#profile-picture" data-toggle="tab" class="nav-link">
                  <span class="d-sm-none">Profile Picture</span>
                  <span class="d-sm-block d-none">Profile Picture</span>
                </a>
              </li>-->
            </ul>
            <!-- end nav-pills -->

            <!-- begin tab-content -->
            <div class="tab-content">
              <!-- begin tab-pane -->
              <div class="tab-pane fade active show" id="association-billings-tab">
                <!-- begin panel-body -->
                <div class="table-responsive">
                  <table class="table table-invoice" id="dtbillings">
                    <thead>
                      <tr>
                        <th width="1%" data-orderable="false"></th>

                        <th class="text-nowrap">Taxi Route</th>
                        <th class="text-nowrap">Type Of Client</th>
                        <th class="text-nowrap">Collection Settings</th>
                        <th class="text-nowrap">Amount</th>
                        <th class="text-nowrap">Start Date</th>
                        <th class="text-nowrap">End Date</th>
                        <th class="text-nowrap">Next Bill Date</th>
                        <th class="text-nowrap">Penalty Settings</th>
                        <th class="text-nowrap">Penalty Warning</th>
                        <th class="text-nowrap">Penalty Amount</th>
                        <th class="text-nowrap"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in GETASSOCIATIONBILLING"
                        v-bind:key="item._billing_items_id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td class="f-s-600 text-inverse">{{ ++index }}</td>
                        <td>{{ item._taxi_association_routes_id_name }}</td>
                        <td>{{ item._typeofbillingclient_name }}</td>
                        <td>{{ item._collection_frequency_name }}</td>
                        <td>{{ item._billing_items_amount | currencywithSymbol }}</td>
                        <td>{{ item._billing_start_date | formatToShortDate}}</td>
                        <td>{{ item._billing_end_date | formatToShortDate}}</td>
                        <td>{{ item._next_billing_date | formatToShortDate}}</td>
                        <td>{{ item._has_penality_fee | currencywithSymbol }}</td>
                        <td>{{ item._penaltyfee_collection_settings_name }}</td>
                        <td>{{ item._penaltyfee_billing_items_amount | currencywithSymbol }}</td>

                        <!-- <td>	<a href="#modal-editbilling" class="btn btn-success btn-sm width-60 rounded-corner" data-toggle="modal">Edit</a></td> -->
                        <td>
                          <button
                            type="button"
                            class="btn btn-success btn-sm width-60 rounded-corner"
                            data-toggle="modal"
                            data-target="#modal-editbilling"
                            @click="setViewDetail(item)"
                          >Edit</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- end tab-pane -->

              <div id="modal-editbilling" class="modal fade">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Edit Billing Item</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->

                          <!-- end form-group row -->

                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Amount</label>

                            <div class="col-md-9">
                              <CurrencyInput
                                v-model.lazy="GETSELECTEDBILLING._billing_items_amount"
                                :auto-decimal-mode="true"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control text-left demo__currency-input"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">End Billing Date</label>

                            <div class="col-md-9">
                              <div
                                class="input-group date datepicker-disabled-past"
                                id="end_billing_date"
                                data-date-format="dd M yyyy"
                                data-date-start-date="Date.default"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  data-date-format="dd M yyyy"
                                  v-model.lazy="GETSELECTEDBILLING._billing_end_date"
                                />
                                <span class="input-group date">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- end form-group -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Panalty Fee Ammount</label>

                            <div class="col-md-9">
                              <CurrencyInput
                                v-model.lazy="GETSELECTEDBILLING._penaltyfee_billing_items_amount"
                                :auto-decimal-mode="true"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control text-left demo__currency-input"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Status</label>
                            <div class="checkbox checkbox-css">
                              <input
                                type="checkbox"
                                id="cssCheckbox1edit"
                                v-model.lazy="GETSELECTEDBILLING._is_active"
                                :checked="GETSELECTEDBILLING._is_active"
                              />
                              <label
                                v-if="!GETSELECTEDBILLING._deleted_by"
                                for="cssCheckbox1edit"
                              >{{GETSELECTEDBILLING._is_active ? 'Active' : 'Disabled' }}</label>
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        />
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="associationBillingUpdate(context,GETSELECTEDBILLING,USERPROFILE.system_usersid)"
                          :disabled="$wait.is('associationBillingUpdate')"
                        >
                          <v-wait v-bind:for="'associationBillingUpdate'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Save
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <!-- begin tab-pane -->
              <div class="tab-pane fade" id="addonbilling-tab">
                <!-- begin panel-body -->
                <div class="table-responsive">
                  <table class="table table-invoice" id="dtaddonbilling">
                    <thead>
                      <tr>
                        <th width="1%" data-orderable="false"></th>
                        <th class="text-nowrap">Name</th>
                        <th class="text-nowrap">Description</th>
                        <th class="text-nowrap">Price Per Unit</th>
                        <th class="text-nowrap">Balance Amount</th>
                        <th class="text-nowrap">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item, index) in GETADDONBILLING"
                        v-bind:key="item._id"
                        :class="{'success': item._addon_service_billing_id != null}"
                      >
                        <td class="f-s-600 text-inverse">{{ ++index }}</td>
                        <td>{{ item._name }}</td>
                        <td>{{ item._description }}</td>

                        <td>{{ item._unit_price | currencywithSymbol }}</td>
                        <td>{{ item._unit_price | currencywithSymbol }}</td>
                        <td class="with-btn valign-middle" nowrap>
                          <button
                            v-if="item._addon_service_billing_id == null"
                            type="button"
                            class="btn btn-sm btn-yellow width-80 rounded-corner"
                            @click="() => { getAddOn(context,item); item._addon_service_billing_id = item._id}"
                          >Add</button>
                          <button
                            v-if="item._addon_service_billing_id != null"
                            type="button" 
                            :class="'btn btn-success btn-sm btnadded width-80 rounded-corner'"
                            @click="() => { removeAddOn(context,item); item._addon_service_billing_id = null  }"
                          >Remove</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- end tab-pane -->
              <!-- begin tab-pane -->

              <!-- begin tab-pane -->
              <!-- end tab-pane -->

              <!-- begin tab-pane -->
              <div class="tab-pane fade" id="association-route">
                <!-- <div class="btn-group m-r-5">
                  <a
                    data-toggle="modal"
                    data-target="#addRoute"
                    class="btn btn-white"
                    href="javascript:;"
                  >
                    <i class="fa fa-plus"></i>
                    Add Route
                  </a>
                </div> -->
                <div class="panel-body">
                  <table id="data-table-default" class="table table-striped table-bordered width-full">
                    <thead>
                      <tr>
                        <th width="1%" data-orderable="false"></th>
                        <th class="text-nowrap">Name</th>
                        <th class="text-nowrap">Description</th>
                        <th class="text-nowrap">Code</th>
                        <th class="text-nowrap">Price</th>
                        <th class="text-nowrap">Status</th>
                        <th class="text-nowrap">Last Updated By</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item, index) in GETTAXIASSROUTES"
                        v-bind:key="item._id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td class="f-s-600 text-inverse">{{ ++index }}</td>
                        <td>{{ item._name }}</td>
                        <td>{{ item._description }}</td>
                        <td>{{ item._code }}</td>
                        <td>{{ item._taxifare | currencywithSymbol }}</td>
                        <td>
                          <span
                            class="badge f-s-12"
                            :class="{ 'badge-danger': item._deleted_by }"
                            v-if="item._deleted_by"
                          >Deleted</span>
                          <span
                            class="badge f-s-12"
                            v-bind:class="{
                              'badge-success': item._is_active,
                              'label-secondary': !item._is_active
                            }"
                            v-if="!item._deleted_by"
                          >
                            {{
                            item._is_active == true ? "Active" : "Disabled"
                            }}
                          </span>
                        </td>
                        <td>{{ item._addedby_name || item._updateby_name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- end tab-pane -->
              <div class="modal fade" id="addRoute">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title">Add Route</h4>
                      <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body">
                      <!-- begin form-group row -->
                      <div class="form-group row m-b-10">
                        <label class="col-md-3 text-md-right col-form-label">Name</label>
                        <div class="col-md-9">
                          <input
                            type="text"
                            placeholder="Name"
                            class="form-control"
                            v-model.lazy="GETSELECTEDROUTES._name"
                          />
                        </div>
                      </div>
                      <!-- end form-group row -->
                      <!-- begin form-group row -->
                      <div class="form-group row m-b-10">
                        <label class="col-md-3 text-md-right col-form-label">Description</label>
                        <div class="col-md-9">
                          <input
                            type="text"
                            class="form-control"
                            v-model.lazy="GETSELECTEDROUTES._description"
                          />
                        </div>
                      </div>
                      <!-- end form-group row -->
                      <!-- begin form-group row -->
                      <div class="form-group row m-b-10">
                        <label class="col-md-3 text-md-right col-form-label">Code</label>
                        <div class="col-md-9">
                          <input
                            type="text"
                            placeholder="Name"
                            class="form-control"
                            v-model.lazy="GETSELECTEDROUTES._code"
                          />
                        </div>
                      </div>
                      <!-- end form-group row -->
                      <!-- begin form-group row -->
                    </div>
                    <div class="modal-footer">
                      <a href="javascript:;" class="btn btn-white" data-dismiss="modal">Cancel</a>
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-80"
                        @click="addRoute(context,GETSELECTEDROUTES,USERPROFILE.system_usersid)"
                        :disabled="$wait.is('add')"
                      >
                        <v-wait v-bind:for="'add'">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Add
                        </v-wait>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- begin tab-pane -->
<!-- 
              <div class="tab-pane fade" id="association-bank">

              </div> -->

            </div>
            <!-- end tab-content -->
          </div>
        </fieldset>
      </div>

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TA");

export default {
  name: "TAXIASSOCIATIONPROFILE",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      // GETSELECTED: this.$store.getters["auth/SELECTEDTAXIASSOCIATION"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      SYSTEMUSERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      id: this.$route.params.id,
      TAXIASSOCIATION: {},
      TAXIASSOCIATIONSETTINGS: [],
      GETALLDISTRICTS: this.$store.getters["lookups/GETALLDISTRICTS"],
      GETALLSUBURS: this.$store.getters["lookups/GETALLSUBURBS"],
      REMOVEADDONSERVICES: {
        taxiassociation_id: this.$route.params.id,
        addon_servicetype_id: null,
        deleted_by: this.$store.getters["auth/USERPROFILE"].system_usersid
      },
      GETSELECTEDBILLING: {},
      GETSELECTEDROUTES: Object.assign(
        {},
        this.$store.getters["TA/TAXIASSOCIATIONSROUTES"]
      ),
      GETSELECTBANK: Object.assign(
        {},
        this.$store.getters["TA/TAXIASSOCIATIONBANKS"]
      ),
      GETBILLINGTYPES: this.$store.getters["lookups/GETBILLINGTYPES"],
      isDisabled: true,
      inputDisabled: true,
      isHidden: true,
      buttonIsHidden: true,
      skip: 0,
      len: 1000,
      GETALLDOCUMENTTYPE: this.$store.getters["lookups/GETALLDOCUMENTTYPE"],
      GETADDONSERVICETYPES: JSON.parse(
        JSON.stringify(
          Object.assign({}, this.$store.getters["lookups/GETADDONSERVICETYPES"])
        )
      ),
      GETTYPEOFBILLINGCLIENT: this.$store.getters[
        "lookups/GETTYPEOFBILLINGCLIENT"
      ],
      GETCOLLECTIONSETTINGS: this.$store.getters[
        "lookups/GETCOLLECTIONSETTINGS"
      ],
      taxi_association_id: this.$route.params.id,
      membership_max: this.$store.getters["auth/SELECTEDTAXIASSOCIATION"]
        ._maximum_number_members,
      max_vehicles: this.$store.getters["auth/SELECTEDTAXIASSOCIATION"]
        ._maximum_number_vehicles,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      GETASSOCIATIONBILLING: [],
      GETADDONBILLING: [],
      GETTAXIASSROUTES: [],
      selectedTaxiAffiliationId: undefined,
      selectedDistrictId: undefined,
      contactdetails_first_name: undefined,
      contactdetails_surname: undefined,
      ADDONSERVICES: {
        _taxi_association_id: this.$route.params.id,
        _id: null,
        _added_by: this.$store.getters["auth/USERPROFILE"].system_usersid
      },
      
      TAXIASSOCIATIONS: this.$store.getters["TA/TAXIASSOCIATIONS"],
      selectedTitleId: undefined,
      selectedGenderId: undefined,
      selectedUserGroupId: undefined,
      DropZoneresizeWidth: 292,
      DropZoneresizeHeight: 40,
      CapturedPhotosSessionID: this.$uuid.v4(),
      // dropzone_files: this.$store.getters["fileupload/GETALL"],
      DropZoneSessionID: this.$uuid.v4(),
      CHANGEPROFILE: {
        dropzoneid: "",
        dropzone_files: [
          {
            customType: 0,
            fileId: ""
          }
        ]
      },
      acceptedFiles: "image/*",
      maxFiles: 1,
      firsttime: 0,
      BILLINGTYPE: {},
      ADDONSERVICETYPES: [],
      LOGO: undefined
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
    // DropZoneControl,
  },

  computed: {
    ...mapGetters({
      TAXIAFFILIATION: "GETTAXIAFFILIATIONS",
      // GETASSOCIATIONBYID: "TAXIASSOCIATIONGETBYID",
      // GETASSOCIATION: "TAXIASSOCIATIONS",
      GETASSBANKING: "TAXIASSOCIATIONBANKS"
    }),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "getById",
      "getByAssId",
      "getAssociationId",
      "getBillingByTaxiAssID",
      "getTaxiAssRoutes",
      "getAssBilling",
      "getBankingDetails",
      "getTaxiAssAddOn",
      "changeDefault",
      "addAddOns",
      "addMaxVehicles",
      "addMaxMembers",
      "getLOGOURL",
      "updateBillingItems",
      "addAssociationRoute",
      "updateMoratorium"
    ]),

    associationBills: (context, increment) => {
      if (increment) context.skip += 0;
      else {
        if (context.skip > 0) context.skip -= 10;
      }
      context
        .getAssBilling({
          status: context.taxi_association_id,
          skip: context.skip,
          len: context.len,
          router: context.$router
        })
        .then(data => {
          console.log("association billings: ", data);
          context.GETASSOCIATIONBILLING = data;
        }).catch(error => {
          
          console.log("association billings errors: ", error);
        });
    },

    addonbilling: context => {
      context
        .getTaxiAssAddOn({
          id: context.id,
          router: context.$router
        })
        .then(data => {
          console.log("these are the add on billings: ", data);
          context.GETADDONBILLING = data;
        });
    },

    getRouteById: (context, taxi_association_id) => {
      context
        .getTaxiAssRoutes({
          id: taxi_association_id,
          router: context.$router
        })
        .then(data => {
          console.log("these are the operator routes: ", data);
          context.GETTAXIASSROUTES = data;
        })
        .catch(error => {})
        .finally(() => {
          $("#data-table-default").DataTable({
            responsive: true
          });
        });
    },

    associationById: context => {
      
      context
        .getById({
          id: context.id,
          router: context.$router
        })
        .then(data => {
          console.log("association details: ", data);

          context.TAXIASSOCIATION = data;

          context.associationSettingsById(context);
        })
        .catch(error => {
          console.log("association errors: ", error);
        });
    },

    associationSettingsById: context => {
      
      context
        .getAssociationId({
          id: context.id,
          router: context.$router
        })
        .then(data => {
          console.log("association settings: ", data);
          context.TAXIASSOCIATIONSETTINGS = data;
        })
        .catch(error => {
          console.log("settings errors: ", error);
        });
    },

    // bankingDetails: (context, taxi_association_id) => {
    //   context
    //     .getBankingDetails({
    //       id: taxi_association_id,
    //       router: context.$router
    //     })
    //     .then(data => {
    //       console.log("banking details: ", data);
    //     });
    // },

    removeAddOn: (context, item) => {
      console.log("found", item._id);

      context.REMOVEADDONSERVICES.addon_servicetype_id = item._id;

      context.remove(context,context.REMOVEADDONSERVICES);
    },

    remove: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then(value => {
        
        if (value) {
          value.context.$wait.start("remove");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TA/removeAddOns", {
              AddModel: value.item,
              router: value.router
            })
            .then(() => {
              value.context.$wait.end("remove");
              value.context.$Notify.Success("Successfully Removed!", "");
              value.context.added = true;
              context.addonbilling(context);
            })
            .catch(error => {
              value.context.$wait.end("remove");
              value.context.added = false;
              value.context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        }
      });
    },

    getAddOn: (context, item) => {
      
      context.ADDONSERVICES._id = item._id;

      context.add(context, context.ADDONSERVICES);
    },



    add: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then(value => {
        
        if (value) {
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TA/addAddOns", {
              _dataObj: value
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");
              value.context.$Notify.Success("Successfully Added!", "");
              value.context.added = true;
              context.addonbilling(context);
            })
            .catch(error => {
              value.context.$wait.end("add");
              value.context.added = false;
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },

    setViewDetail(val) {
      this.GETSELECTEDBILLING = Object.assign({}, val);
      $("#end_billing_date").datepicker({
        todayHighlight: true,
        autoclose: true
      });
    },

    associationBillingUpdate: (context, item, updated_by) => {
      item._billing_end_date = context.$Toolkit.ConvertToServerDate(
        $("#end_billing_date").datepicker("getDate")
      );
      item._updated_by = updated_by;
      context.$Notify
        .YesNo(false, { context, item, updated_by })
        .then(value => {
          if (value) {
            value.context.$wait.start("edited");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("TA/updateBillingItems", {
                _dataObj: value
              })
              .then(() => {
                $("#EditModal").modal("hide");
                value.context.$wait.end("edited");
                value.context.$Notify.Success("Successfully Updated!", "");
              })
              .catch(error => {
                value.context.$Notify.Error(error.data.message, "");
              })
              .finally(() => {
                value.context.$wait.end("edited");
              });
          }
        });
    },

    associationMoratoriumUpdate: (context, item, updated_by) => {
      item._taxi_association_id = context.taxi_association_id;
      context.$Notify
        .YesNo(false, { context, item, updated_by })
        .then(value => {
          if (value) {
            value.context.$wait.start("edited");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("TA/updateMoratorium", {
                _dataObj: value
              })
              .then(() => {
                $("#EditModal").modal("hide");
                value.context.$wait.end("edited");
                value.context.$Notify.Success("Successfully Updated!", "");
              })
              .catch(error => {
                value.context.$Notify.Error(error.data.message, "");
              })
              .finally(() => {
                value.context.$wait.end("edited");
              });
          }
        });
    },

    // associationMoratoriumUpdate:  (context, item, updated_by) => {

    //   context.$Notify.YesNo(false,{ context, item, updated_by })
    //   if (value && context.membership_max < item._maximum_number_members ) {
    //       value.context.$wait.start("edited");
    //       value.router = value.context.$router;
    //       value.context.$store
    //         .dispatch("TA/updateMoratorium", {
    //           _dataObj: value
    //         })
    //         .then(() => {
    //           $("#EditModal").modal("hide");
    //           value.context.$wait.end("edited");
    //           value.context.$Notify.Success("Successfully Updated!", "");
    //         })
    //         .catch(error => {
    //           value.context.$Notify.Error(error.data.message,"Not a valid Number! \n Please enter a 10-digit number",
    //       "We cannot accept duplicate numbers.");
    //         })
    //         .finally(() => {
    //           value.context.$wait.end("edited");
    //          });
    //     }
    //   });
    // },

    addTaxiAssAddOns: (context, item, updated_by) => {
      (item._taxi_association_id = context.taxi_association_id),
        (item._updated_by = updated_by);
      
      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TA/addAddOns", {
              _dataObj: value
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");

              value.context.$Notify.Success("Successfully Added!", "");
            })
            .catch(error => {
              value.context.$wait.end("add");

              value.context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        }
      });
    },

    addRoute: (context, item, added_by) => {
      (item._taxiassociation_id = context.taxi_association_id),
        (item._added_by = added_by);
      
      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TA/addAssociationRoute", {
              _dataObj: value
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");

              value.context.$Notify.Success("Route Succesfully Added!", "");
            })
            .catch(error => {
              value.context.$wait.end("add");

              value.context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        }
      });
    },

    setAccAsDefault: (context, item, updated_by) => {
      (item._taxiassociation_id = context.taxi_association_id),
        (item._updated_by = updated_by);
      
      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TA/changeDefault", {
              _dataObj: value
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");

              value.context.$Notify.Success(
                "Morotorium Succesfully Changed!",
                ""
              );
            })
            .catch(error => {
              value.context.$wait.end("add");

              value.context.$Notify.Error(
                error.data.message || error.data.data.message,
                ""
              );
            })
            .finally(() => {});
        }
      });
    },

    setAdd(val) {
      this.$store
        .dispatch("TA/addAssociationRoute", {
          _dataObj: val
        })
        .then(() => {});
    },

    // chengeProfile: (context, item, taxiassociation_id, addedby) => {
    //   context.$Notify
    //     .YesNo(false, { context, item: context.CHANGEPROFILE })
    //     .then(value => {
    //       const dropzone_uploads = context.$store.getters["fileupload/GETALL"];
    //       const fileuploaded = [];

    //       if (dropzone_uploads.length > 0) {
    //         var failedvalidation = false;
    //         dropzone_uploads.forEach(element => {
    //           if (element.customType === 0) {
    //             failedvalidation = true;
    //             return;
    //           }
    //           fileuploaded.push({
    //             customType: Number(element.customType),
    //             fileId: element.fileId
    //           });
    //         });
    //         if (failedvalidation) {
    //           value.context.$Notify.Error("Please Select Document Type.", "");
    //           return;
    //         }
    //       }

    //       value.item.dropzone_files = fileuploaded;
    //       value.item.photosessionid = context.CapturedPhotosSessionID;
    //       value.item.dropzoneid = context.DropZoneSessionID;
    //      if (value) {
    //       value.context.$wait.start("add");
    //       value.router = value.context.$router;
    //       value.context.$store
    //         .dispatch("TA/changepProfile", {
    //           _dataObj: value
    //         })
    //         .then(() => {
    //           $("#AddModal").modal("hide");
    //           value.context.$wait.end("add");

    //           value.context.$Notify.Success("Morotorium Succesfully Changed!", "");
    //         })
    //         .catch(error => {
    //           value.context.$wait.end("add");

    //           value.context.$Notify.Error(error.data.message, "");
    //         })
    //         .finally(() => {});
    //     }
    //   });
    // },

    // updateAssociationBilling: (context, id, _updatemodel) => {
    //     context.$Notify
    //       .YesNo(false, { context, id, _updatemodel })
    //       .then(value => {
    //         if (value) {

    //           value.context.$wait.start("add");
    //           value.router = value.context.$router;
    //           value.context.$store
    //             .dispatch("TA/editBilling", {
    //               router: value.router,
    //               id: value.id,
    //               _updatemodel: value._updatemodel
    //             })
    //             .then(() => {
    //               // $("#AddModal").modal("hide");
    //               value.context.$wait.end("add");
    //               value.context.$Notify.Success("Update Request Sent!");
    //             })
    //             .catch(error => {
    //               value.context.$wait.end("add");
    //               value.context.$Notify.Error(error.data[0].message, "");
    //             })
    //             .finally(() => {});
    //         }
    //       });
    //   },

    changeMemberMorotorium: (context, item, added_by) => {
      (item.taxi_assocation_id = context.taxi_association_id),
        (item._added_by = added_by);
      
      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TA/addMaxVehicles", {
              _dataObj: value
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");

              value.context.$Notify.Success("Session Succesfully Started!", "");
            })
            .catch(error => {
              value.context.$wait.end("add");

              value.context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        }
      });
    },
    changeVehicleMorotorium: (context, item, added_by) => {
      (item.taxi_assocation_id = context.taxi_association_id),
        (item._added_by = added_by);
      
      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TA/addMaxVehicles", {
              _dataObj: value
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");

              value.context.$Notify.Success("Session Succesfully Started!", "");
            })
            .catch(error => {
              value.context.$wait.end("add");

              value.context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        }
      });
    },

    fileadded: function(data) {
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')
      const context = $("#wizard").data("context");
      //context.GETUPLOADEDFILES = context.$store.getters["fileupload/GETALL"];
    }
    // GETALLTAXIAFFILIATIONS: context => {
    //   context.$store
    //     .dispatch("taxiAffiliation/getall", {
    //       _dataObj: {
    //         router: context.$router
    //       }
    //     })
    //     .then(() => {})
    //     .catch(error => {})
    //     .finally(() => {});
    // }
    // GETALLDISTRICTSBYTAXIFILICATION: (context, id) => {
    //   return context.GETALLDISTRICTS.filter(
    //     item => item.taxi_affiliation_id === id
    //   );
    // },

    // GETSELECTEDASSOCIATION: (context, id) => {
    //     return context.TAXIASSOCIATIONS.filter(
    //       item => item.taxi_association_id === ids
    //     );
    //   },
  },
  beforeMount() {
    // this.GETSELECTED.dropzoneid = this.DropZoneSessionID;
    
    this.associationBills(this.context, true);
    this.addonbilling(this.context, this.taxi_association_id);
    this.getRouteById(this.context, this.taxi_association_id);
    this.associationById(this.context);
    // this.bankingDetails(this.context, this.taxi_association_id);
  },
  mounted: function() {
    // this.GETALLTAXIAFFILIATIONS(this.context);

    App.init();

    $(".btnadded")
      .mouseenter(function() {
        $(this)
          .removeClass("btn-success")
          .addClass("btn-danger")
          .text("Remove");
      })
      .mouseleave(function() {
        $(this)
          .removeClass("btn-danger")
          .addClass("btn-success")
          .text("Added");
      });

    // this.ADDONSERVICETYPES = [];

    $(
      'input[name="maxOperators"], input[name="maxTaxiOperators"],input[name="currentOperators"]'
    ).change(function() {
      if ($(this).val()) {
        $("input[name='btnSaveChanges']").removeAttr("disabled");
      }
    });

    

    const contexttemp = this;
    this.getLOGOURL({
      id: this.taxi_association_id
    })
      .then(result => {
        contexttemp.LOGO = result;
      })
      .catch(err => {});

      
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
