<template>
  <div id="page-container" class="fade page-sidebar-fixed page-header-fixed">
    <!-- begin #header -->
    <div id="header" class="header navbar-default">
      <!-- begin navbar-header -->
      <div class="navbar-header">
        <a href="javascript:void(0)" class="navbar-brand">
          <span class="">
           <img src="/assets/img/logo/logoheader.png"/>
            <!-- <i><img src="/assets/img/logo/logo-admin-apple.png" class="width-160 height-40" /></i> -->
          </span>
        </a>
  
        <button type="button" class="navbar-toggle" data-click="sidebar-toggled">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <!-- end navbar-header -->
      
      <!-- begin navbar-header -->
      <div class="navbar-header taxissociationbranding" style="">
        <a href="/" class="navbar-brand">
          <span class="navbar-logo">
            <!-- <i class="ion-ios-cloud"></i> -->
            <i><img v-show="LOGO" :src="`${LOGO != undefined ? LOGO : '' }`" class="width-160 height-40" /></i>
          </span>
          <b >{{ SELECTEDTAXIASSOCIATION != undefined ? SELECTEDTAXIASSOCIATION._name : ''}}</b> 
        </a>
  
        <button type="button" class="navbar-toggle" data-click="sidebar-toggled">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <!-- end navbar-header -->
      <!-- begin header-nav -->
      <ul class="navbar-nav navbar-right">
				<TaxiOperatorDropDownControl v-if="AddTaxiOperatorDropDownControl" :isAdminUser="isAdminUser" v-on:init="init"/>
				

				<TopSearchControl v-show="USERPROFILE.taxi_associations_id" v-if="AddTopSearchControl"/>


				<TopNotificationControl v-if="AddTopNotificationControl" />
        

      </ul>
      <!-- end header navigation right -->
    </div>
    <!-- end #header -->
  </div>
</template>

<script>

import TaxiOperatorDropDownControl from "@/components/common/taxioperationdropdowncontrol.vue";
import TopNotificationControl from "@/components/common/TopNotificationControl.vue";
import TopSearchControl from "@/components/common/TopSearchControl.vue";

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('auth');

export default {
	 name: "headerComp",
	 props:['AddTaxiOperatorDropDownControl','AddTopSearchControl','AddTopNotificationControl','isAdminUser'],
  data: function() {
    return {
      LOGO: undefined,
      SELECTEDTAXIASSOCIATION: undefined,
    };
  },
  computed: {
    ...mapGetters(["USERPROFILE"]),
  },
  components: {
		TaxiOperatorDropDownControl,
		TopSearchControl,
		TopNotificationControl
  },
  methods: {
    init: function ()
    {
      this.$emit('init')
    }
  },
   mounted: function() {

    this.SELECTEDTAXIASSOCIATION = this.USERPROFILE.selected_taxi_association;
     
     const contexttemp = this;
     this.$store.dispatch("TA/getLOGOURL", {
      id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id
    }).then((result) => {

      //
      
      contexttemp.LOGO = result;
    }).catch((err) => {
    });
  },
}
   
</script>

<style scoped>
.taxissociationbranding
{
  width: 100px;
	position: absolute;
	left: 0;
	right: 0;margin: auto;
}
</style>

