<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'QuickSetup'"
      :ActiveMenu="'AddTaxi'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Request Taxi Vehicle Edit"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Vehicles"
        breadcrumbitem3="Request Taxi Vehicle Edit"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- Add Modal HTML START-->
      <div id="AddModal" class="modal fade">
        <div class="modal-dialog">
          <div class="modal-content">
            <form>
              <div class="modal-header">
                <h4 class="modal-title">Add Route</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <!-- begin form-group row -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-live-search="true"
                        data-parsley-required="true"
                        class="form-control"
                        v-model="ADDROUTE._name"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Description
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-live-search="true"
                        data-parsley-required="true"
                        class="form-control"
                        v-model="ADDROUTE._description"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Code
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-live-search="true"
                        data-parsley-required="true"
                        class="form-control"
                        v-model="ADDROUTE._code"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      National Route Code
                      <span class="text-danger"></span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-live-search="true"
                        data-parsley-required="true"
                        class="form-control"
                        v-model="ADDROUTE._code"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Board Route Code
                      <span class="text-danger"></span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-live-search="true"
                        data-parsley-required="true"
                        class="form-control"
                        v-model="ADDROUTE._code"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Origin
                      <span class="text-danger"></span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-live-search="true"
                        data-parsley-required="true"
                        class="form-control"
                        v-model="ADDROUTE._code"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Destination
                      <span class="text-danger"></span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-live-search="true"
                        data-parsley-required="true"
                        class="form-control"
                        v-model="ADDROUTE._code"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Taxi Fare
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <CurrencyInput
                        :auto-decimal-mode="true"
                        :currency="currency"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control text-left demo__currency-input"
                        v-model="ADDROUTE._taxifare"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->
                </div>
              </div>
              <div class="modal-footer">
                <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
                <button
                  type="button"
                  class="btn btn-sm btn-success width-80"
                  :disabled="$wait.is('add-')"
                  @click="addRoute(context,ADDROUTE)"
                >
                  <v-wait v-bind:for="'add-'">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Save
                  </v-wait>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Add Modal HTML START-->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-2 col-sm-3 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Vehicle Information
                      <small class="text-ellipsis">Name, Address, ID No and DOB</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-2 col-sm-3 col-6">
                  <a href="#step-2">
                    <span class="number">2</span>
                    <span class="info text-ellipsis">
                      Upload Supporting Documents
                      <small
                        class="text-ellipsis"
                      >ID document, Proof of Address,License ect.....</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-2 col-sm-3 col-6">
                  <a href="#step-3">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Complete Registration</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->
                    <div class="row">
                      <!-- begin col-6 -->
                      <div class="col-lg-6">
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">Taxi Owner</label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              disabled
                              class="form-control"
                              v-model="operatorsname"
                            />
                          </div>
                        </div>

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Display Name
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="Sipho's Quantum"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              class="form-control"
                              v-model="UPDATEVEHICLE.displayname"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Vin Number
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="AASSIUZIUHJNSDS585ASD"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              data-parsley-type="alphanum"
                              class="form-control"
                              v-model="UPDATEVEHICLE.vin_number"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Tara
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="1500g"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              data-parsley-type="alphanum"
                              class="form-control"
                              v-model="UPDATEVEHICLE.tara"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Registration Number
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="BHY789D"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              data-parsley-type="alphanum"
                              class="form-control"
                              v-model="UPDATEVEHICLE.vehicle_register_number"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row">
                          <label
                            class="col-md-4 col-form-label text-md-right"
                          >Licence Disc Expiry Date</label>
                          <div class="col-md-6">
                            <div
                              class="input-group date"
                              id="licence_expiry_date"
                              data-date-format="dd M yyyy"
                            >
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Select Date"
                                data-parsley-group="step-1"
                                :value="UPDATEVEHICLE.licence_expiry_date | formatToShortDate"
                              />
                              <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            License Number
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="GB57BBGP"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              class="form-control"
                              v-model="UPDATEVEHICLE.licence_number"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label
                            class="col-md-4 col-form-label text-md-right"
                          >Operating License Number</label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholer="EN5444TGDV54C"
                              data-parsley-group="step-1"
                              data-parsley-required="false"
                              class="form-control"
                              v-model="UPDATEVEHICLE.operatinglicense_number"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label
                            class="col-md-4 col-form-label text-md-right"
                          >Vehicle Finance Provider</label>
                          <div class="col-md-6">
                            <select
                              class="form-control selectFinance"
                              v-model="UPDATEVEHICLE.vehicle_finance_institution_id"
                            >
                              <option value="0" selected>Enter Vehicle Finance Provider</option>
                              <option
                                v-bind:key="item._id"
                                v-for="item in GETFINANCEPROVIDERS"
                                :value="item._id"
                              >{{ item._name }}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">Insurance Provider</label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              id="selectInsurance"
                              data-parsley-group="step-1"
                              data-parsley-required="false"
                              v-model="UPDATEVEHICLE.vehicle_insurance_institution_id"
                            >
                              <option
                                v-bind:key="item._id"
                                v-for="item in GETFINSURANCEPROVIDERS"
                                :value="item._id"
                              >{{ item._name }}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Tracker Provider
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              id="selectTracker"
                              data-parsley-group="step-1"
                              data-parsley-required="false"
                              v-model="UPDATEVEHICLE.vehicle_tracker_institution_id"
                            >
                              <option
                                v-bind:key="item._id"
                                v-for="item in GETTRACKERPROVIDERS"
                                :value="item._id"
                              >{{ item._name }}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->
                      </div>
                      <div class="col-lg-6">
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Engine Number
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="JSA648151S"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              class="form-control"
                              v-model="UPDATEVEHICLE.engine_number"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Engine Type
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              data-size="10"
                              data-live-search="true"
                              data-parsley-group="step-1"
                              v-model="UPDATEVEHICLE.vehicle_engine_id"
                            >
                              <option value="0" selected>Enter Engine Type</option>
                              <option
                                v-bind:key="item._id"
                                v-for="item in GETALLENGINETYPES"
                                :value="item._id"
                              >{{ item._name }}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Transmision Type
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              data-size="10"
                              data-live-search="true"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              data-parsley-min="1"
                              data-parsley-notequalto-message="You must select at least one option."
                              v-model="UPDATEVEHICLE.vehicle_transmission_type_id"
                            >
                              <option value="0" selected>Select Transmision Type</option>
                              <option
                                v-bind:key="item._id"
                                v-for="item in GETALLTRANSMISIONTYPES"
                                :value="item._id"
                              >{{ item._name }}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Color
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              data-size="10"
                              data-live-search="true"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              data-parsley-min="1"
                              data-parsley-notequalto-message="You must select at least one option."
                              v-model="UPDATEVEHICLE.vehicle_color_id"
                            >
                              <option value="0" selected>Select Color</option>
                              <option
                                v-bind:key="item._id"
                                v-for="item in GETALLCOLORS"
                                :value="item._id"
                              >{{ item._name }}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Make/Model
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              data-size="10"
                              data-live-search="true"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              id="selectModel"
                              v-model="context.UPDATEVEHICLE.vehiclemodel_id"
                            >
                              <option value="0" selected>Select Make/Madel</option>
                              <option
                                v-bind:key="item._vehiclemodel_id"
                                v-for="item in GETALLMODEL"
                                :value="item._vehiclemodel_id"
                              >
                                {{
                                item._vehiclemake_name + " " + item._name
                                }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Vehicle Joined Date
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <div
                              class="input-group date datepicker-disabled-past"
                              id="vehicle_joined_date"
                              data-date-format="dd M yyyy"
                            >
                              <input
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                type="text"
                                class="form-control"
                                placeholder="Select Date"
                                :value="UPDATEVEHICLE.taxi_vehicle_joined_date | formatToShortDate "
                              />
                              <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Routes
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              v-model="UPDATEVEHICLE.taxi_association_routes_id"
                              id="selecttaxiroute"
                              data-live-search="true"
                              data-parsley-group="step-1"
                            >
                              <option value="0" selected>Select Route</option>
                              <option
                                v-bind:key="item._id"
                                v-for="item in ASSOCIATIONROUTES"
                                :value="item._id"
                              >{{item._name}}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">Operating Routes</label>
                          <div class="col-md-8">
                            <div class="input-group">
                              <div class="col-md-7 ">
                                <span class="input-group-prepend m-r-30">
                                  <select
                                    class="form-control "
                                    id="selectoperatingroute"
                                    data-parsley-required="true"
                                    data-parsley-group="step-1"
                                  >
                                    <option
                                      v-bind:key="item._id"
                                      v-for="item in ASSOCIATIONOPERATINGROUTES"
                                      :value="item._id"
                                    >{{item._name}}</option>
                                  </select>
                                  <a
                                    href="javascript:;"
                                    data-toggle="modal"
                                    data-target="#AddModal"
                                    class="btn btn-sm btn-grey"
                                  >
                                    <i class="fa fa-plus fa-md m-t-5"></i>
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <div class="form-group row m-b-10">
                          <label
                            class="col-md-4 col-form-label text-md-right"
                          >Operator License Issue Date</label>
                          <span class="text-danger"></span>
                          <div class="col-md-6">
                            <div
                              class="input-group date"
                              id="date_of_birth"
                              data-date-format="dd M yyyy"
                            >
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Select Date"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                :value="UPDATEVEHICLE.operatinglicense_issuedate | formatToShortDate"
                              />
                              <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>

                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Operator License Expiry Date
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <div
                              class="input-group date"
                              id="operatorlicense_expiry_date"
                              data-date-format="dd M yyyy"
                            >
                              <input
                                data-parsley-group="step-1"
                                type="text"
                                class="form-control"
                                placeholder="Select Date"
                                :value="UPDATEVEHICLE.operatinglicense_expirydate | formatToShortDate"
                              />
                              <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Cross Border
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <div class="switcher switcher-success">
                              <input
                                type="checkbox"
                                name="switcher_checkbox_hasoverdraft"
                                id="switcher_checkbox_hasoverdraft"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="UPDATEVEHICLE.cross_border"
                              />
                              <label for="switcher_checkbox_hasoverdraft"></label>
                            </div>
                          </div>
                        </div>

                        <!-- end form-group -->
                      </div>
                    </div>
                  </fieldset>

                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->

                <!-- begin step-2 -->
                <div id="step-2">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->

                    <p class="text-center h4 mb-4">Upload Supporting Documents</p>
                    <div class="panel panel-primary" data-sortable-id="form-dropzone-1">
                      <div class="panel-heading">
                        <div class="panel-heading-btn"></div>
                        <h4 class="panel-title">Drop Files In Here</h4>
                      </div>
                      <div class="panel-body text-primary">
                        <DropZoneControl
                          :acceptedFiles="acceptedFiles"
                          :ClearData="true"
                          v-on:fileadded="fileadded"
                          :SessionID="DropZoneSessionID"
                        ></DropZoneControl>
                      </div>
                    </div>
                    <br />
                    <!--End dropzone!-->

                    <!--Start Table!-->
                    <div class="panel panel-primary" data-sortable-id="table-basic-7">
                      <!-- begin panel-heading -->
                      <div class="panel-heading ui-sortable-handle">
                        <h4 class="panel-title">Vehicle previous Uploads</h4>
                      </div>
                      <!-- end panel-heading -->
                      <!-- begin panel-body -->
                      <div class="panel-body">
                        <!-- begin table-responsive -->
                        <div class="table-responsive">
                          <table class="table table-striped m-b-0">
                            <thead>
                              <tr>
                                <th>Filename</th>
                                <th>Format</th>
                                <th width="40%">Document Type</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                :key="item.supportingdocs_id"
                                v-for="(item) in GETVEHICLESUPPORTINGDOCS"
                              >
                                <td>{{item.filename}}</td>
                                <td>{{item.mimetype}}</td>
                                <td>
                                  <select
                                    class="form-control col-sm-4"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    data-parsley-required="true"
                                    disabled
                                    v-model="item.fk_documenttype_id"
                                  >
                                    <option selected>Select Document Type</option>
                                    <option
                                      v-bind:key="item._id"
                                      v-for="item in GETALLDOCUMENTTYPE"
                                      :value="item._id"
                                    >{{item._name}}</option>
                                  </select>
                                </td>
                                <td class="with-btn" nowrap>
                                  <a
                                    href="#"
                                    @click="deletefile(context,item.fileId, USERPROFILE.system_usersid)"
                                    class="btn btn-sm btn-danger width-60"
                                  >Delete</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end table-responsive -->
                      </div>
                      <!-- end panel-body -->
                      <!-- begin hljs-wrapper -->
                    </div>

                    <br />
                    <!--Start Table!-->
                    <div class="panel panel-inverse" data-sortable-id="table-basic-7">
                      <!-- begin panel-heading -->
                      <div class="panel-heading ui-sortable-handle">
                        <h4 class="panel-title">Uploaded Files</h4>
                      </div>
                      <!-- end panel-heading -->
                      <!-- begin panel-body -->
                      <div class="panel-body">
                        <!-- begin table-responsive -->
                        <div class="table-responsive">
                          <table class="table table-striped m-b-0">
                            <thead>
                              <tr>
                                <th>Filename</th>
                                <th>Format</th>
                                <th>File ID</th>
                                <th width="40%">Document Type</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                :key="item.vehicle_supportingdocs_id"
                                v-for="(item) in GETVEHICLENEWUPLOADS"
                              >
                                <td>{{item._filename}}</td>
                                <td>{{item._mimetype}}</td>
                                <td>{{item.vehicle_supportingdocs_id}}</td>
                                <td>
                                  <select
                                    class="form-control col-sm-4"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    data-parsley-required="true"
                                    v-model="item.customType"
                                  >
                                    <option selected>Select Document Type</option>
                                    <option
                                      v-bind:key="item._id"
                                      v-for="item in GETALLDOCUMENTTYPE"
                                      :value="item._id"
                                    >{{item._name}}</option>
                                  </select>
                                </td>
                                <td class="with-btn" nowrap>
                                  <a
                                    href="#"
                                    @click="deletefile(context,item.fileId, USERPROFILE.system_usersid)"
                                    class="btn btn-sm btn-danger width-60"
                                  >Delete</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end table-responsive -->
                      </div>
                      <!-- end panel-body -->
                      <!-- begin hljs-wrapper -->
                    </div>
                    <div class="alert alert-secondary fade show">
                      <div class="text-center">
                        <!-- <button type="submit" class="btn btn-outline-secondary">Save To Drafts</button> -->
                      </div>
                    </div>
                    <!-- end row -->
                  </fieldset>
                </div>
                <!-- end step-3 -->
                <!-- end step-2 -->

                <!-- begin step-4 -->

                <div id="step-3">
                  <div class="jumbotron m-b-0 text-center">
                    <h2 class="display-4">Taxi Vehicle Has been Succesfully Added</h2>
                    <p v-show="false" class="lead mb-4">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Mauris consequat commodo porttitor.
                      <br />Vivamus eleifend, arcu in tincidunt semper, lorem
                      odio molestie lacus, sed malesuada est lacus ac ligula.
                      Aliquam bibendum felis id purus ullamcorper, quis luctus
                      leo sollicitudin.
                    </p>
                    <br />
                    <br />

                    <p>
                      <router-link
                        to="/Vehicles/List"
                        tag="button"
                        v-bind:class="{ 'btn btn-primary btn-lg': true }"
                      >Proceed To Taxi Operator Vehicles</router-link>
                    </p>
                  </div>
                </div>
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>

        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import { default as DropZoneControl, default as DropZoneControl2 } from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("OperatorVehicle");

export default {
  name: "updatevehicle",
  data: function () {
    return {
      acceptedFiles: "application/pdf",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      GETUPLOADEDFILES: this.$store.getters["fileupload/GETALL"],
      GETALLENGINETYPES: [],
      GETALLTRANSMISIONTYPES: [],
      GETALLTAXIROUTES: [],
      GETALLCOLORS: [],
      GETVEHICLENEWUPLOADS: [],
      ASSOCIATIONROUTES: [],
      ASSOCIATIONOPERATINGROUTES: [],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      GETALLDOCUMENTTYPE: [],
      // GETALLVEHICLES:this.$store.getters["OperatorVehicle/GETADDVEHICLE"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      status: true,
      len: 100000,
      skip: 0,
      GETALLMODEL: [],
      GETFINANCEPROVIDERS: [],
      GETFINSURANCEPROVIDERS: [],
      GETTRACKERPROVIDERS: [],
      GETOPERATORS: this.$store.getters["TO/GETADDOPERATOR"],
      operatorsname: "",
      dropzone_files: this.$store.getters["fileupload/GETALL"],
      selectVehicleMakeId: 1,
      id: this.$route.params.id,
      status: true,
      DropZoneSessionID: this.$uuid.v4(),
      CapturedPhotosSessionID: this.$uuid.v4(),
      selectedTitleId: undefined,
      selectedGenderId: undefined,
      selectedUserGroupId: undefined,
      SELECTEDOPERATORS: {},
      vehicleupdaterequested: false,
      ADDROUTE: {
        _taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        _name: "",
        _description: "",
        _taxifare: 0,
        _code: "",
        _added_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      UPDATEVEHICLE: {
        taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        vehiclemodel_id: 0,
        displayname: "",
        vin_number: "",
        licence_number: "",
        taxi_vehicle_joined_date: "",
        licence_expiry_date: "",
        vehicle_register_number: "",
        tara: "",
        engine_number: "",
        vehicle_transmission_type_id: 0,
        vehicle_engine_id: 0,
        vehicle_color_id: 0,
        taxi_association_routes_id: 0,
        updated_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
        dropzoneid: "",
        dropzone_files: [
          {
            customType: 0,
            fileId: "",
          },
        ],
        operatinglicense_issuedate: "",
        operatinglicense_expirydate: "",
        operatinglicense_number: "",
        cross_border: false,
        vehicle_finance_institution_id: 0,
        vehicle_insurance_institution_id: 0,
        vehicle_tracker_institution_id: 0,
        operating_route_ids: [],
      },
      PREVIOUSUPLOADS: [],
    };
  },

  beforeMount: function () {},
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    DropZoneControl2,
    CapturePhotosControl,
  },
  beforeMount: function() {
  },
  computed: {
    ...mapGetters([
      "GETADDVEHICLE",
      "GETVEHICLEROUTES",
      "GETVEHICLESUPPORTINGDOCS",
    ]),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },

  methods: {
    ...mapActions([
      "getall",
      "getbyid",
      "getAllByTaxiAssID",
      "delete",
      "edit",
      "add",
      "editVehicle",
      "restoreDeleted",
      "deletelocalfile",
      "updateVehicle",
    ]),
    loadLookUps(context) {
      
      context.$store
        .dispatch("lookups/init", {
          router: context.$router,
        })
        .then((data) => {
          console.log("loadLookUps found: ", data);
          
          context.GETALLENGINETYPES = data.LOADEDENGINE;
          context.GETALLCOLORS = data.LOADEDCOLORS;
          context.GETALLDOCUMENTTYPE = data.LOADEDDOCUMENTTYPE;
          context.GETALLTRANSMISIONTYPES = data.LOADEDTRANSMISSION;
          context.GETALLTAXIROUTES = data.LOADEDTAXIROUTES;
          
        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
    getbyid: (context, id) => {
      context.$store
        .dispatch("OperatorVehicle/getbyid", {
          _dataObj: {
            id,
            router: context.$router,
          },
        })
        .then((data) => {
          console.log("this is the vehicle: ", data);
          context.UPDATEVEHICLE.vehiclemodel_id = data[0]._vehiclemodel_id;
          context.UPDATEVEHICLE.displayname = data[0]._displayname;
          context.UPDATEVEHICLE.vin_number = data[0]._vin_number;
          context.UPDATEVEHICLE.licence_number = data[0]._licence_number;
          context.UPDATEVEHICLE.taxi_vehicle_joined_date =
            data[0]._taxi_operator_vehicle_joined_date;
          context.UPDATEVEHICLE.licence_expiry_date =
            data[0]._licence_expiry_date;
          context.UPDATEVEHICLE.vehicle_register_number =
            data[0]._vehicle_register_number;
          context.UPDATEVEHICLE.tara = data[0]._tara;
          context.UPDATEVEHICLE.vehicle_transmission_type_id =
            data[0]._vehicle_transmission_type_id;
          context.UPDATEVEHICLE.vehicle_engine_id = data[0]._vehicle_engine_id;
          context.UPDATEVEHICLE.vehicle_color_id = data[0]._vehicle_color_id;
          context.UPDATEVEHICLE.taxi_association_routes_id =
            data[0]._fk_taxi_association_routes_id;
          context.operatorsname = data[0]._taxi_operator_name;
          context.UPDATEVEHICLE.engine_number = data[0]._engine_number;
          context.UPDATEVEHICLE.operatinglicense_issuedate =
            data[0]._operatinglicense_issuedate;
          context.UPDATEVEHICLE.operatinglicense_expirydate =
            data[0]._operatinglicense_expirydate;
          context.UPDATEVEHICLE.operatinglicense_number =
            data[0]._operatinglicense_number;
          context.UPDATEVEHICLE.vehicle_finance_institution_id =
            data[0]._vehicle_finance_institution_id;
          context.UPDATEVEHICLE.vehicle_insurance_institution_id =
            data[0]._vehicle_insurance_institution_id;
          context.UPDATEVEHICLE.vehicle_tracker_institution_id =
            data[0]._vehicle_tracker_institution_id;
          context.UPDATEVEHICLE.operating_route_ids =
            data[0]._operating_route_ids;

          $("#selectModel").select2(
            "val",
            context.UPDATEVEHICLE.vehiclemodel_id
          );

          if (data[0]._operating_route_ids != []) {
            context.UPDATEVEHICLE.operating_route_ids =
              data[0]._operating_route_ids;

            $("#selectoperatingroute").select2(
              "val",
              data[0]._operating_route_ids
            );
          } else {
            context.UPDATEVEHICLE.operating_route_ids = [];
          }

          if (data[0]._cross_border != null) {
            context.UPDATEVEHICLE.cross_border = data[0]._cross_border;
          } else {
            context.UPDATEVEHICLE.cross_border = false;
          }
        })
        .catch((err) => {
          console.log("vehicle details error: ", err);
        });
    },
    getAllVehicleTracker: (context) => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleTracker", {
          status: context.status,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          console.log("vehicle trackers: ", data);

         
          
          context.GETTRACKERPROVIDERS = data;
          
          $(".selecttrackerpicker").select2({
            multiple: false,
            placeholder: "Select A Tracker",
            allowClear: false,
          });
        })
        .catch((error) => {
          console.log("vehicle trackers errors: ", error);
        });
    },

    getVehicleFinance: (context) => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleFinance", {
          status: context.status,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          console.log("vehicle finance: ", data);
          context.GETFINANCEPROVIDERS = data;
          $(".selectfinancepicker").select2({
            multiple: false,
            placeholder: "Select A Vehicle Finance",
            allowClear: false,
          });
        })
        .catch((error) => {
          console.log("vehicle finance errors: ", error);
        });
    },

    getVehicleInsurance: (context) => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleInsurance", {
          status: context.status,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          console.log("vehicle insurance: ", data);
          context.GETFINSURANCEPROVIDERS = data;

          $(".selectinsurancepicker").select2({
            multiple: false,
            placeholder: "Select A Insurance",
            allowClear: false,
          });
        })
        .catch((error) => {
          console.log("vehicle insurance errors: ", error);
        });
    },

    getroutesbyid: (context, taxiassociationid) => {
      context.$store
        .dispatch("TA/getTaxiAssRoutes", {
          router: context.$router,
          id: taxiassociationid,
        })
        .then((data) => {
          console.log("Routes found: ", data);

          context.ASSOCIATIONROUTES = data;
          context.ASSOCIATIONOPERATINGROUTES = data;
        })
        .catch((err) => {
          console.log("vehicle details error: ", err);
        });
    },

    getAllVehiclemodels: (context) => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleModels", {
          router: context.$router,
        })
        .then((data) => {
          console.log("vehicle models: ", data);

          context.GETALLMODEL = data;
        })
        .catch((error) => {
          console.log("vehicle models errors: ", error);
        });
    },

    vehicleDocs: (context, id) => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleDocsById", {
          _dataObj: {
            id,
            router: context.$router,
          },
        })
        .then((data) => {
          console.log("These are the supporting docs..", data);

          var olddocs = [];
          olddocs = data.map((item) => {
            context.PREVIOUSUPLOADS.push(item);
            console.log("doc type", item.fk_documenttype_id);
          });
        })
        .catch((err) => {
          console.log("vehicle docs error: ", err);
        });
      //this.$emit('init')
    },

    downloadItem(filename, data, type) {
      const linkSource = `data:application/pdf;base64,${data}`;
      const downloadLink = document.createElement("a");
      const fileName = filename;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },

    addRoute: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TA/addAssociationRoute", {
              _dataObj: value,
            })
            .then((result) => {
              console.log("Route Added successfully: ", result);
              value.context.$Notify.Success("Successfully Added!", "");
              $("#AddModal").modal("hide");
              context.ADDROUTE._name = "";
              context.ADDROUTE._description = "";
              context.ADDROUTE._taxifare = 0;
              context.ADDROUTE._code = "";
              context.getRoutesByAssId(context);
            })
            .catch((error) => {
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },

    updateVehicleRequest: (context, id, _updatemodel) => {
      context.$Notify
        .YesNo(false, { context, id, _updatemodel })
        .then((value) => {
          

          const previous_uploads = context.PREVIOUSUPLOADS;
          var uploads = [];

          // if (previous_uploads.length > 0) {
          //   previous_uploads.forEach(element => {
          //     uploads.push({
          //       customType: element.fk_documenttype_id,
          //       fileId: element.vehicle_supportingdocs_id
          //     });
          //   });
          // }

          for (var i = 0; i < previous_uploads.length; i++) {
            uploads.push(previous_uploads[i]);
          }

          const dropzone_files = context.GETVEHICLENEWUPLOADS;
          const fileuploaded = [];

          if (dropzone_files.length > 0) {
            var failedvalidation = false;
            dropzone_files.forEach((element) => {
              if (element.customType === 0) {
                failedvalidation = true;
                return;
              }
              fileuploaded.push({
                customType: element.customType,
                fileId: element.vehicle_supportingdocs_id,
              });
            });
            if (failedvalidation) {
              value.context.$Notify.Error("Please Select Document Type.", "");
              return;
            }
          }

          value._updatemodel.dropzone_files = fileuploaded;
          value._updatemodel.dropzoneid = context.DropZoneSessionID;

          if ($("#vehicle_joined_date").find("input").val() != "") {
            value._updatemodel.taxi_vehicle_joined_date = context.$Toolkit.ConvertToServerDate(
              $("#vehicle_joined_date").find("input").val()
            );
          } else {
            value._updatemodel.taxi_vehicle_joined_date = context.$Toolkit.ConvertToServerDate(
              value.context.UPDATEVEHICLE.taxi_vehicle_joined_date
            );
          }

          if ($("#licence_expiry_date").find("input").val() != "") {
            value._updatemodel.licence_expiry_date = context.$Toolkit.ConvertToServerDate(
              $("#licence_expiry_date").find("input").val()
            );
          } else {
            value._updatemodel.licence_expiry_date = context.$Toolkit.ConvertToServerDate(
              value.context.UPDATEVEHICLE.licence_expiry_date
            );
          }

          if ($("#date_of_birth").find("input").val() != "") {
            value._updatemodel.operatinglicense_issuedate = context.$Toolkit.ConvertToServerDate(
              $("#date_of_birth").find("input").val()
            );
          } else {
            value._updatemodel.operatinglicense_issuedate = context.$Toolkit.ConvertToServerDate(
              value.context.UPDATEVEHICLE.operatinglicense_issuedate
            );
          }

          if ($("#operatorlicense_expiry_date").find("input").val() != "") {
            value._updatemodel.operatinglicense_expirydate = context.$Toolkit.ConvertToServerDate(
              $("#operatorlicense_expiry_date").find("input").val()
            );
          } else {
            value._updatemodel.operatinglicense_expirydate = context.$Toolkit.ConvertToServerDate(
              value.context.UPDATEVEHICLE.operatinglicense_expirydate
            );
          }

          const operatingroutes = $("#selectoperatingroute")
            .select2("data")
            .map((item) => {
              console.log("users: ", item);
              return item.id;
            });

          console.log("", operatingroutes);

          value._updatemodel.vehiclemodel_id = parseInt(
            $("#selectModel").val()
          );

          value._updatemodel.operating_route_ids = [...operatingroutes];

          // value._updatemodel.vehicle_insurance_institution_id = parseInt(
          //   $(".selectInsurance").val()
          // );
          // value._updatemodel.vehicle_tracker_institution_id = parseInt(
          //   $(".selectTracker").val()
          // );
          // value._updatemodel.vehicle_finance_institution_id = parseInt(
          //   $(".selectFinance").val()
          // );

          if (value) {
            value.context.$wait.start("add");
            value.router = value.context.$router;
            value.vehicleupdaterequested = value.context.vehicleupdaterequested;
            value.context.$store
              .dispatch("OperatorVehicle/updateVehicle", {
                id: value.id,
                _updatemodel: value._updatemodel,
                router: value.router,
              })
              .then((result) => {
                console.log("update results: ", result);

                $(".sw-btn-next").text("Done").attr("disabled", "disabled");
                $(".sw-btn-prev").attr("disabled", "disabled");
                $("#wizard").data("taxivehicleupdated", true);
                $("#wizard").smartWizard("next");
                value.context.$Notify.Success("Update Request Sent!", result);
              })
              .catch((error) => {
                $(".sw-btn-next").text("Save").removeAttr("disabled");
                $("#wizard").data("vehicleupdaterequested", false);
                context.$Notify.Error(
                  error.data.message || error.data.data.message,
                  ""
                );
              })
              .finally(() => {});
          }
        });
    },

    fileadded: function (data) {
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')
      const context = $("#step-2").data("context");

      context.GETVEHICLENEWUPLOADS.push({
        vehicle_supportingdocs_id: data.fileId,
        _filename: data.name,
        _mimetype: data.type,
        customType: data.customType,
      });
    },

    removedfile: function (data) {
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')
      const context = $("#step-2").data("context");

      context.deletelocalfile(data.fileId);
    },
  },

  mounted: function () {
    // this.loadoperators(this.context);
    // this.getVehicleById(this.context,this.id);

    this.loadLookUps(this.context);

    this.getbyid(this.context, this.id);

    this.getAllVehicleTracker(this.context);
    this.getVehicleFinance(this.context);
    this.getVehicleInsurance(this.context);
    this.getroutesbyid(this.context, this.taxiassociationid);
    this.getAllVehiclemodels(this.context);

    $("#selectModel").select2({
      multiple: false,
      placeholder: "Select A Model",
      allowClear: true,
    });

    $("#step-2").data("context", this.context);

    $("#wizard").data("taxivehicleupdated", false);

    //$('.selectpicker1').selectpicker('render');
    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "",
      keyNavigation: false,
      transitionSpeed: 0,
      cycleSteps: true,
      useURLhash: false,
      showStepURLhash: false,
      toolbarSettings: {
        toolbarPosition: "bottom",
      },
    });

    $("#wizard").data("context", this.context);
    $("#wizard").on("leaveStep", function (
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {
      const context = $("#wizard").data("context");
      const currentstep = stepNumber + 1;
      const res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

      if (context.hacksmartwizard) return true;

      $(".sw-btn-next").text("Next");

      if (stepDirection == "backward") return true;

      if (currentstep === 1 && stepDirection == "forward") {
        $(".sw-btn-next").text("Submit");
      }

      if (currentstep === 2 && stepDirection == "forward") {
        const taxivehicleupdated = $("#wizard").data("taxivehicleupdated");

        if (!taxivehicleupdated) {
          context.updateVehicleRequest(
            context,
            context.id,
            context.UPDATEVEHICLE
          );
        }
        return taxivehicleupdated;
      }

      if (currentstep === 3 && stepDirection == "forward") {
        if (!res) return res;

        console.log("we are in step 4");
        return false;
      }

      return res;
    });

    //   	$('#wizard').on('leaveStep', function(e, anchorObject, stepNumber, stepDirection) {
    // 	var res = $('form[name="form-wizard"]').parsley().validate('step-' + (stepNumber + 1));
    // 	return res;
    // });

    // $('#wizard').keypress(function( event ) {
    // 	if (event.which == 2 ) {
    // 		$('#wizard').smartWizard('next');
    // 	}
    // });

    //$(".datepicker-disabled-past").datepicker("update", new Date());
    //disable

    $("#vehicle_joined_date").datepicker({
      todayHighlight: true,
      autoclose: true,
    });

    $("#licence_expiry_date").datepicker({
      todayHighlight: true,
      autoclose: true,
    });

    $("#selecttaxiroute").select2({
      multiple: false,
      placeholder: "Select A Taxi Route",
      allowClear: true,
    });

    $("#selectoperatingroute")
      .select2({
        multiple: true,
        placeholder: "Select Operating Route",
        allowClear: true,
      })
      .select2("val", this.UPDATEVEHICLE.operating_route_ids);

    $("#date_of_birth").datepicker({
      todayHighlight: false,
      autoclose: true,
      startDate: date,
    });

    $("#operatorlicense_expiry_date").datepicker({
      todayHighlight: true,
      autoclose: true,
      startDate: date,
    });

    var date = new Date();
    date.setDate(date.getDate() - 31);
    $("#expirydate").datepicker({
      todayHighlight: true,
      autoclose: true,
      startDate: date,
    });
    this.GETADDVEHICLE._taxi_operator_vehicle_joined_date = this.$options.filters.formatToShortDate(
      this.GETADDVEHICLE._taxi_operator_vehicle_joined_date
    );
    this.GETADDVEHICLE._licence_expiry_date = this.$options.filters.formatToShortDate(
      this.GETADDVEHICLE._licence_expiry_date
    );
    this.vehicleDocs(this.context, this.id);
    App.init();
    //$("#expirydate").datepicker("update", new Date());
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
