<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Operators'"
      :ActiveMenu="'List'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Taxi Operator Profile"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Operators"
        breadcrumbitem3="Taxi Operator Profile"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <div class="row">
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-card"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">NO OF TAXIS</div>
              <div class="stats-number">{{nooftaxis || 'N/A'}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-hourglass"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">STATUS</div>
              <div class="stats-number">{{taxi_operators_status || 'Pending'}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-logo-usd"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">OUTSTANDING BALANCE</div>
              <div class="stats-number">{{accBalance | currencywithSymbol }}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
      </div>

      <!-- begin #Main Body Template -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>
              <h4 class="panel-title">Operator Profile</h4>
            </div>
            <!-- end panel-heading -->
            <!-- begin panel-body -->
            <div class="panel-body">
              <div class="row">
                <!-- begin col-6 -->

                <div class="col-lg-6">
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Title</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        :value="title_name != undefined ? title_name : 'Male / Female' "
                      />
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Gender</label>
                    <div class="col-md-6">
                      <input type="text" disabled class="form-control" v-model="gender_name" />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Firstname</label>
                    <div class="col-md-6">
                      <input type="text" disabled class="form-control" v-model="firstname" />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Surname</label>
                    <div class="col-md-6">
                      <input type="text" disabled class="form-control" v-model="surname" />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">ID Number</label>
                    <div class="col-md-6">
                      <input type="text" disabled class="form-control" v-model="id_number" />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Passport Number</label>
                    <div class="col-md-6">
                      <input type="text" disabled class="form-control" v-model="passport_number" />
                    </div>
                  </div>
                  <!-- End form-group -->

                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Bank Name</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        placeholder="eg. FNB"
                        v-model="bank_account_name"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Account Type</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        placeholder="eg. Savings"
                        v-model="accounttype"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Account Number</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        placeholder="eg. 123456458745"
                        v-model="bank_account_number"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                </div>

                <!-- Begin Second Row -->
                <div class="col-lg-6">
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Date of Birth</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        :value="date_of_birth | formatToShortDate"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Existing Membership Number</label>
                    <div class="col-md-6">
                      <input type="text" disabled class="form-control" v-model="membership_number" />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Telephone Number</label>
                    <div class="col-md-6">
                      <input type="text" disabled class="form-control" v-model="telephone_number" />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Fax Number</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        placeholder="N/A"
                        v-model="fax_number"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Email</label>
                    <div class="col-md-6">
                      <input type="text" disabled class="form-control" v-model="email_address" />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Mobile Number</label>
                    <div class="col-md-6">
                      <input type="text" disabled class="form-control" v-model="mobilenumber" />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">RAS Registration Number</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="ras_registration_number"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Operator Status</label>
                    <div class="col-md-6">
                      <a
                        href="javascript:;"
                        id="operatorStatus"
                        data-type="select"
                        data-pk="1"
                        data-value
                        data-title="Select oeprator status"
                      ></a>
                    </div>
                  </div>
                  <!-- End form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">WhatsApp Notifications</label>
                    <div class="col-md-6">
                      <!-- <a href="#" class="btn btn-default btn-icon  btn-circle btn-lg">
                        <i class="fab fa-whatsapp-square fa-3x text-success"></i>
                      </a>-->

                      <div class="switcher switcher-success">
                        <a
                          href="javascript:;"
                          v-if="service_type_id == 4"
                          class="btn btn-default btn-icon btn-circle btn-lg"
                        >
                          <i class="fab fa-whatsapp-square fa-3x"></i>
                        </a>
                        <a
                          href="javascript:;"
                          v-if="service_type_id == 3"
                          class="btn btn-default btn-icon btn-circle btn-lg"
                        >
                          <i class="fab fa-whatsapp-square fa-3x text-success"></i>
                        </a>
                        <br />
                        <br />
                        <input
                          @click="activateNotifications(context,{addedby:updatedby,taxiassociationid: taxiassociationid, taxi_operator_id: [taxi_operator_id],operator_name: firstname + ' ' + surname,operator_number: mobilenumber}, isActive)"
                          type="checkbox"
                          name="switcher_checkbox_hasoverdraft"
                          id="switcher_checkbox_hasoverdraft"
                          data-parsley-group="step-1"
                          data-parsley-required="false"
                          v-model="isActive"
                        />
                        <label class="m-t-2" for="switcher_checkbox_hasoverdraft"></label>
                      </div>
                    </div>
                  </div>
                  <!-- End form-group -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col-12 -->

      <div class="btn-row">
        <div class="text-center">
          <button
                  type="button"
                  class="btn btn-default width-100 m-5"
                  v-show="USERPROFILE.dialeragentuserid"
                  @click="CALL(context)"
                  :disabled="$wait.is('Call')"
                >
                  <v-wait v-bind:for="'Call'">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Call
                  </v-wait>
                </button>
          <router-link
            tag="button"
            class="btn btn-primary width-100 m-5"
            data-role="UTO"
            v-show="!approvalid"
            :to="{ name: 'editoperator', params: {id: taxi_operator_id } }"
          >Edit</router-link>
          <router-link
            tag="button"
            class="btn btn-info width-100 m-5"
            data-role="UTO"
            v-show="!approvalid"
            :to="{ name: 'capturephotos', params: {id: taxi_operator_id } }"
          >Photos</router-link>
          <button
            type="button"
            class="btn btn-sm btn-success width-200 m-5"
            v-show="approvalid"
            @click="OpenapproveModal(context, GETOPERATORSBYIDNOPIC)"
          >Approve</button>

          <button
            type="button"
            class="btn btn-sm btn-danger width-200 m-5"
            v-show="approvalid"
            @click="OpenrejectModal(context, GETOPERATORSBYIDNOPIC)"
          >Reject</button>
        </div>
      </div>
      <br />

      <!-- begin panel -->
      <div class="panel panel-inverse panel-with-tabs" data-sortable-id="ui-unlimited-tabs-2">
        <!-- begin panel-heading -->
        <div class="panel-heading p-0">
          <div class="panel-heading-btn m-r-10 m-t-10">
            <a
              href="javascript:;"
              class="btn btn-xs btn-icon btn-circle btn-inverse"
              data-click="panel-expand"
            >
              <i class="fa fa-expand"></i>
            </a>
          </div>
          <!-- begin nav-tabs -->
          <div class="tab-overflow">
            <ul class="nav nav-tabs-inverse">
              <li class="nav-item prev-button">
                <a href="javascript:;" data-click="prev-tab" class="text-inverse nav-link">
                  <i class="fa fa-arrow-left"></i>
                </a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab2-1" data-toggle="tab" class="nav-link">Vehicles</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab2-2" data-toggle="tab" class="nav-link">Timeline</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab2-3" data-toggle="tab" class="nav-link">Address</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab2-4" data-toggle="tab" class="nav-link">Mobile Numbers</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab2-5" data-toggle="tab" class="nav-link">Traffic Fines</a>
              </li>
               <li class="nav-item">
                <a href="#nav-tab2-11" data-toggle="tab" class="nav-link">SMS</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab2-6" data-toggle="tab" class="nav-link">Supporting Documents</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab2-7" data-toggle="tab" class="nav-link">Operator Billngs</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab2-8" data-toggle="tab" class="nav-link">Operator Transactions</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab2-9" data-toggle="tab" class="nav-link">Receipt Disputes</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab2-10" data-toggle="tab" class="nav-link">Invoice Disputes</a>
              </li>

              <li class="nav-item next-button">
                <a href="javascript:;" data-click="next-tab" class="text-inverse nav-link">
                  <i class="fa fa-arrow-right"></i>
                </a>
              </li>
            </ul>
          </div>
          <!-- end nav-tabs -->
        </div>
        <!-- end panel-heading -->
        <!-- begin tab-content -->
        <div class="tab-content">
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab2-1">
            <!-- Start panel-body -->
            <div class="panel-body">
              <table id="data-table-vehicles" class="table table-striped table-bordered width-full">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Display Name</th>
                    <th class="text-nowrap">License Number</th>
                    <th class="text-nowrap">VIN Number</th>
                    <th class="text-nowrap">Vehicle Make</th>
                    <th class="text-nowrap">Vehicle Model</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>
                <tbody v-if="OPERATORVEHICLES != []">
                  <tr
                    v-for="(item,index) in OPERATORVEHICLES"
                    v-bind:key="index"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                    class="odd gradeX"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item._displayname}}</td>
                    <td>{{item._licence_number}}</td>
                    <td>{{item._vin_number}}</td>
                    <td>{{item._vehicle_make}}</td>
                    <td>{{item._vehicle_model}}</td>
                    <td>{{item._vehiclestatus_name}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <router-link
                        :to="{ name: 'taxivehicleprofile', params: { id: item._taxi_operator_vehicles_id }}"
                        class="btn btn-sm btn-white width-60 m-r-2"
                      >View</router-link>
                      <router-link
                        :to="{ name:  'updatevehicle',  params: { id: item._taxi_operator_vehicles_id }}"
                        class="btn btn-sm btn-primary width-60 m-r-2"
                        v-if="item._taxi_operator_vehicles_id"
                      >Edit</router-link>
                      <router-link
                        :to="{ name:  'changeofownership',  params: { id: item._taxi_operator_vehicles_id }}"
                        class="btn btn-sm btn-warning width-120 m-r-2"
                        v-if="item._taxi_operator_vehicles_id"
                      >Change Ownership</router-link>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-60 m-r-2"
                        v-if="!item._deleted_by"
                        @click="Delete(context,item._taxi_operator_vehicles_id,USERID)"
                        :disabled="$wait.is('delete-' + item._taxi_operator_vehicles_id)"
                      >
                        <v-wait v-bind:for="'delete-' + item._taxi_operator_vehicles_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Delete
                        </v-wait>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-60 m-r-2"
                        v-if="item._deleted_by"
                        @click="Restore(context,item._taxi_operator_vehicles_id,USERID)"
                        :disabled="$wait.is('restore-' + item._taxi_operator_vehicles_id)"
                      >
                        <v-wait v-bind:for="'restore-' + item._taxi_operator_vehicles_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Restore
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end tab-pane -->
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab2-2">
            <!-- begin timeline -->
            <h1 v-if="GETOPERATORTIMELINE.length == 0">NO History in Timeline</h1>
            <ul class="timeline" data-role="RTO">
              <li v-for="item in GETOPERATORTIMELINE" v-bind:key="item._id">
                <!-- begin timeline-time -->
                <div class="timeline-time">
                  <span class="date">{{item._date_added | formatToShortDate}}</span>
                  <span class="time">{{item._date_added | formatToTime}}</span>
                </div>
                <!-- end timeline-time -->
                <!-- begin timeline-icon -->
                <div class="timeline-icon">
                  <a href="javascript:;">&nbsp;</a>
                </div>
                <!-- end timeline-icon -->
                <!-- begin timeline-body -->
                <div class="timeline-body">
                  <div class="timeline-header">
                    <span class="fa-stack fa-lg text-primary">
                      <i class="fas fa-address-card fa-2x"></i>
                    </span>
                    <span class="username">
                      <a href="javascript:;">{{item._added_by_names}}</a>
                      <small></small>
                    </span>
                    <span class="pull-right text-muted"></span>
                  </div>
                  <div class="timeline-content">
                    <p>{{item._comment}}</p>
                  </div>
                </div>
                <!-- end timeline-body -->
              </li>
            </ul>
            <!-- end timeline -->
          </div>
          <!-- end tab-pane -->
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab2-3">
            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-address" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Physical Address</th>
                    <th class="text-nowrap">Physical Address line 2</th>
                    <th class="text-nowrap">suburb</th>
                    <th class="text-nowrap">Postal Address</th>
                    <th class="text-nowrap">Postal Address line 2</th>
                    <th class="text-nowrap">suburb</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETOPERATORSBYIDNOPIC"
                    v-bind:key="item.taxi_operator_id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.physicaladdress}}</td>
                    <td>{{item.physicaladdress2}}</td>
                    <td>{{item.physical_suburb}}</td>
                    <td>{{item.postaladdress}}</td>
                    <td>{{item.postaladdress2}}</td>
                    <td>{{item.postal_suburb}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <button
                        type="button"
                        class="btn btn-sm btn-warning width-60 m-r-2"
                        href="#addressModal"
                        data-toggle="modal"
                        data-role="RTO"
                      >View</button>
                      <router-link
                        type="button"
                        class="btn btn-sm btn-primary width-60 m-r-2"
                        data-role="UTO"
                        :to="{ name: 'editoperator', params: {id: taxi_operator_id } }"
                      >Edit</router-link>
                      <!-- <button
                            type="button"
                            class="btn btn-sm btn-success width-80"
                            v-if="item._deleted_by"
                          >Restore</button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger width-80"
                            v-if="!item._deleted_by"
                            data-role="DTO"
                      >Delete</button>-->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- End panel-body -->
          </div>
          <!-- end tab-pane -->
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab2-4">
            <div class="panel-toolbar">
                            <div class="btn-group m-r-5">
                              <a
                                data-toggle="modal"
                                data-target="#AddMobileNumberModal"
                                class="btn btn-white"
                                href="javascript:;"
                                
                              >
                                <i class="fa fa-plus"></i>
                                Add
                              </a>
                            </div>
                          </div>
            <!-- begin panel-body -->
            <div class="panel-body">
              <table
                id="data-table-mubilenumbers"
                class="table table-striped table-bordered width-full"
              >
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">Number</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Last Updated By</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETOPERATORNUMBERS"
                    v-bind:key="item._taxi_operator_mobilenumbers_id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item._taxi_operator_name}}</td>
                    <td>{{item._mobilenumber}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._deleted_by }"
                        v-if="item._deleted_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item._is_active, 'label-secondary':!item._is_active  }"
                        v-if="!item._deleted_by"
                      >{{item._is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td>{{item._updateby_name || item._addedby_name}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <button
                        type="button"
                        class="btn btn-sm btn-warning width-60 m-r-2"
                        data-toggle="modal"
                        data-target="#ViewNumbersModal"
                        data-role="RTO"
                        @click="setViewDetail(item)"
                      >View</button>
                      <router-link
                        type="button"
                        class="btn btn-sm btn-primary width-60 m-r-2"
                        data-role="UTO"
                        :to="{ name: 'editoperator', params: {id: taxi_operator_id } }"
                      >Edit</router-link>
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-80"
                        v-if="item._deleted_by"
                      >Restore</button>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-80"
                        v-if="!item._deleted_by"
                        data-role="DTO"
                      >Delete</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- End panel-body -->
          </div>
          <!-- end tab-pane -->
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab2-5">
            <!-- begin panel-body -->
            <div class="panel-body">
              <table
                id="data-table-trafficFines"
                class="table table-striped width-full table-bordered"
              >
                <thead>
                  <tr>
                    <th class="text-nowrap">local Authority Name</th>
                    <th class="text-nowrap">Notice Number</th>
                    <th width="1%" class="text-nowrap">Vehicle Registration</th>
                    <th class="text-nowrap">Offence Date</th>
                    <th class="text-nowrap">Offence Description</th>
                    <th class="text-nowrap">Offence Location</th>
                    <th class="text-nowrap">Amount Due</th>
                    <th class="text-nowrap">Payment</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="item in OPERATORTRAFFICFINES" v-bind:key="item.id">
                    <td>{{item.local_authority_name}}</td>
                    <td>{{item.notice_number}}</td>
                    <td width="1%">{{item.vehicle_registration_number}}</td>
                    <td>{{item.offence_date | formatToShortDate}}</td>
                    <td>{{item.offence_description}}</td>
                    <td>{{item.offence_location}}</td>
                    <td>{{item.amount_due | currencywithSymbol}}</td>
                    <td>{{item.payment_not_allowed_reason}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <!-- Example single danger button -->
                      <div class="btn-group m-r-5 m-b-5">
                        <a
                          data-toggle="modal"
                          data-target="#AddModal"
                          class="btn btn-warning width-80"
                          href="javascript:;"
                        >Pay</a>
                      </div>

                      <!-- dropdown -->
                      <!-- <div class="btn-group">
                        <a href="#" class="btn btn-warning width-80">Pay</a>
                        <a href="#" class="btn btn-default dropdown-toggle" data-toggle="dropdown"></a>
                        <ul class="dropdown-menu pull-right">...</ul>
                      </div>-->
                      <!-- <a
                        href="javascript:;"
                        id="pay"
                        data-type="select"
                        data-pk="1"
                        data-value
                        data-title="Select Payment Method"
                      ></a>-->
                    </td>
                  </tr>
                </tbody>
              </table>

               <!-- Add Modal HTML START-->
               <div id="AddMobile" class="modal fade">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Add Mobile Number</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->

                          <!-- begin card -->
                          <div class="card text-center">
                            <div class="card-header">
                              <ul class="nav nav-tabs card-header-tabs">
                                <li class="nav-item" v-show="TAMS">
                                  <a
                                    class="nav-link active"
                                    data-toggle="tab"
                                    href="#card-tab-1"
                                  >TAMS</a>
                                </li>
                                <li class="nav-item" v-show="EXTERNAL">
                                  <a
                                    class="nav-link"
                                    data-toggle="tab"
                                    href="#card-tab-2"
                                  >Upload Documents</a>
                                </li>
                                <!-- <li class="nav-item" v-show="REDIRECT">
                                  <a
                                    class="nav-link"
                                    data-toggle="tab"
                                    href="#card-tab-3"
                                  >Redirect Payment</a>
                                </li>-->
                              </ul>
                            </div>
                            <div class="card-block">
                              <div class="tab-content p-0 m-0">
                                <div
                                  class="tab-pane fade active show"
                                  id="card-tab-1"
                                  v-show="TAMS"
                                >
                                  <!-- begin form-group -->
                                  <div class="form-group row m-b-10">
                                    <label class="col-md-4 col-form-label text-md-right">
                                      BANK NAME:
                                      <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-md-6">
                                      <input
                                        type="text"
                                        value="FIRST NATIONAL BANK"
                                        data-parsley-group="step-1"
                                        data-parsley-required="true"
                                        class="form-control"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <!-- end form-group -->
                                  <!-- begin form-group -->
                                  <div class="form-group row m-b-10">
                                    <label class="col-md-4 col-form-label text-md-right">
                                      ACCOUNT TYPE:
                                      <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-md-6">
                                      <input
                                        type="text"
                                        value="BUSINESS ACCOUNT"
                                        data-parsley-group="step-1"
                                        data-parsley-required="true"
                                        class="form-control"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <!-- end form-group -->
                                  <!-- begin form-group -->
                                  <div class="form-group row m-b-10">
                                    <label class="col-md-4 col-form-label text-md-right">
                                      ACCOUNT NUMBER:
                                      <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-md-6">
                                      <input
                                        type="text"
                                        value="62840241848"
                                        data-parsley-group="step-1"
                                        data-parsley-required="true"
                                        class="form-control"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <!-- end form-group -->

                                  <!-- begin form-group -->
                                  <div class="form-group row m-b-10">
                                    <label class="col-md-4 col-form-label text-md-right">
                                      BRANCH CODE:
                                      <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-md-6">
                                      <input
                                        type="text"
                                        value="255355"
                                        data-parsley-group="step-1"
                                        data-parsley-required="true"
                                        class="form-control"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <!-- end form-group -->

                                  <!-- begin form-group -->
                                  <div class="form-group row m-b-10">
                                    <label class="col-md-4 col-form-label text-md-right">
                                      BRANCH NAME:
                                      <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-md-6">
                                      <input
                                        type="text"
                                        value="MY BRANCH"
                                        data-parsley-group="step-1"
                                        data-parsley-required="true"
                                        class="form-control"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <!-- end form-group -->

                                  <!-- begin form-group -->
                                  <div class="form-group row m-b-10">
                                    <label class="col-md-4 col-form-label text-md-right">
                                      SWIFT CODE:
                                      <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-md-6">
                                      <input
                                        type="text"
                                        value="FIRNZAJJ"
                                        data-parsley-group="step-1"
                                        data-parsley-required="true"
                                        class="form-control"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <!-- end form-group -->

                                  <!-- begin form-group -->
                                  <div class="form-group row m-b-10">
                                    <label class="col-md-4 col-form-label text-md-right">
                                      DATE OPENED:
                                      <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-md-6">
                                      <input
                                        type="text"
                                        value="2020-01-23"
                                        data-parsley-group="step-1"
                                        data-parsley-required="true"
                                        class="form-control"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <!-- end form-group -->
                                </div>
                                <div class="tab-pane fade" id="card-tab-2" v-show="EXTERNAL">
                                  <div class="form-group row">
                                    <label class="col-md-0 col-form-label text-md-right">
                                      Select Taxi Driver
                                      <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-md-9">
                                      <select
                                        class="form-control"
                                        id="selectoperatorpicker"
                                        data-size="10"
                                        data-live-search="true"
                                        data-parsley-required="true"
                                      >
                                        <option value="0" selected>Select Driver</option>
                                        <option
                                          :key="item._taxi_drivers_id"
                                          v-for="item in DRIVERSBYASSID"
                                          :value="item._taxi_drivers_id"
                                        >
                                          {{
                                          item._firstname +
                                          " " +
                                          item._surname +
                                          "(" +
                                          item._id_number +
                                          ")"
                                          }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <label class="col-md-0 col-form-label text-md-right">
                                      1. Upload aarto07(Signed and stamped by a commissioner of oath).
                                      <span
                                        class="text-danger"
                                      >*</span>
                                    </label>
                                  </div>

                                  <div class="form-group row">
                                    <label class="col-md-0 col-form-label text-md-right">
                                      2. Upload Driver ID.
                                      <span class="text-danger">*</span>
                                    </label>
                                  </div>

                                  <div class="panel-body text-inverse">
                                    <DropZoneControl
                                      :acceptedFiles="acceptedFiles"
                                      :ClearData="true"
                                      v-on:fileadded="fileadded"
                                      :SessionID="uploadPayDropZoneSessionID"
                                    ></DropZoneControl>
                                  </div>
                                  <div class="text-center">
                                    <button
                                      type="button"
                                      class="btn btn-sm btn-primary width-200 m-5"
                                    >Submit</button>
                                  </div>
                                </div>
                                <!-- <div class="tab-pane fade" id="card-tab-3" v-show="REDIRECT"></div> -->
                              </div>
                            </div>
                          </div>
                          <!-- end card -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Add Modal HTML START-->

             
            </div>
            <!-- End panel-body -->
          </div>
          <!-- end tab-pane -->
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab2-6">
            <!-- Start panel-body -->
            <div class="panel-body">
              <table
                id="data-table-supportingDocs"
                class="table table-striped table-bordered width-full"
              >
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Filename</th>
                    <th class="text-nowrap">document Type</th>
                    <th class="text-nowrap">Active</th>
                    <th class="text-nowrap">Last Updated By</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>
                <tbody v-if="GETOPERATORSUPPORTINGDOCS.length > 0">
                  <tr
                    v-for="(item,index) in GETOPERATORSUPPORTINGDOCS"
                    v-bind:key="index"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item._filename}}</td>
                    <td>{{item.documenttype_name || 'N/A'}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._deleted_by }"
                        v-if="item._deleted_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item._is_active, 'label-secondary':!item._is_active  }"
                        v-if="!item._deleted_by"
                      >{{item._is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td>{{item._updateby_name || item._addedby_name}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <button
                        type="button"
                        class="btn btn-sm btn-warning width-60 m-r-2"
                        data-toggle="modal"
                        data-target="#ViewDocsModal"
                        @click="setViewDocs(item)"
                        :disabled="item._deleted_by"
                        data-role="RTO"
                      >View</button>
                      <!-- <router-link
                        type="button"
                        class="btn btn-sm btn-primary width-60 m-r-2"
                        data-role="UTO"
                        :to="{ name: 'editoperator', params: {id: taxi_operator_id } }"
                      >Edit</router-link>
                      -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- End panel-body -->
          </div>
          <!-- end tab-pane -->
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab2-7">
            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-billings" class="table table-striped table-bordered width-full">
                <thead>
                  <tr>
                    <th>Billing Date</th>
                    <th>Billing item</th>
                    <th>Amount</th>
                    <th>Collection Type</th>
                    <th>Last Billing Date</th>
                    <th>Next Billing Date</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for=" item in GETOPERATORBILLINGS" v-bind:key="item._id">
                    <td>{{item._billing_start_date | formatToShortDate}}</td>
                    <td>{{item._fk_billing_items_name}}</td>
                    <td>R {{item._amount | currency}}</td>
                    <td>{{item._fk_collection_frequency_name}}</td>
                    <td>{{item._last_bill_date | formatToShortDate}}</td>
                    <td>{{item._next_billing_date | formatToShortDate}}</td>
                    <td class="with-btn">
                      <button
                        type="button"
                        class="btn btn-sm btn-primary width-80"
                        @click="billingInfo(context,item)"
                      >Change</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end tab-pane -->
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab2-8">
            <!-- begin table-responsive -->
            <div class="panel-body">
              <table
                id="data-table-transactions"
                class="table table-striped table-bordered width-full"
              >
                <thead>
                  <tr>
                    <th nowrap>DATE</th>
                    <th nowrap>REFERENCE</th>
                    <th nowrap>CHARGE</th>
                    <th nowrap>PAYMENT</th>
                    <th nowrap>BALANCE</th>
                    <th nowrap>ACTION</th>
                  </tr>
                </thead>
                <tbody v-if="OPERATORTRANSACTIONS.length >= 0">
                  <tr v-for="(item) in OPERATORTRANSACTIONS" v-bind:key="item._id">
                    <td>{{item._transaction_date | formatToShortDate }}</td>
                    <td>{{item._transaction_reference}}</td>
                    <td class="text-left text-green-darker">
                      {{ item._invoice_id ? 'R': '' }}
                      {{ item._invoice_id ? item._transaction_amount : '' | currency}}
                    </td>
                    <td class="text-left text-red-darker">
                      {{ item._receipts_id ? 'R': '' }}
                      {{ item._receipts_id ? item._transaction_amount : '' | currency}}
                    </td>
                    <td class="text-left">R{{item._remaning_balance | currency}}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-sm btn-primary width-80"
                        :disabled="$wait.is('dispute-' + item._id)"
                        @click="selectedReceipt(context, item)"
                      >
                        <v-wait v-bind:for="'dispute-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Dispute
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <!-- <ul class="pagination" style="margin-left:125%;">
                  <li class="page-item disabled">
                    <a href="#" class="page-link">«</a>
                  </li>
                  <li class="page-item active">
                    <a href="#" class="page-link">1</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">2</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">3</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">4</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">5</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">»</a>
                  </li>
                </ul>-->
              </table>
            </div>
            <!-- end table-responsive -->

            <!-- #modal-message start -->
            <div class="modal fade" id="modal-dialog">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="text-center">
                    <h4 v-if="isInvoice" class="modal-title">Dispute Invoice</h4>
                    <h4 v-if="isReceipt" class="modal-title">Dispute Receipt</h4>
                  </div>
                  <div class="modal-body">
                    <div v-if="isInvoice" class="form-group row">
                      <label class="col-md-2 col-form-label">Comment:</label>
                      <div class="col-md-10">
                        <textarea
                          placeholder="Type your comments"
                          class="form-control m-b-10"
                          id="clip-textarea"
                          v-model="INVOICEDISPUTEADD.invoiceComments"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                    <div v-if="isReceipt" class="form-group row">
                      <label class="col-md-2 col-form-label">Comment:</label>
                      <div class="col-md-10">
                        <textarea
                          placeholder="Type your comments"
                          class="form-control m-b-10"
                          id="clipboard-textarea"
                          v-model="RECEIPTDISPUTEADD.receiptComments"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                    <br />
                    <div class="height-450" v-if="isInvoice">
                      <!-- begin nav-tabs -->
                      <ul class="nav nav-tabs">
                        <li class="nav-items">
                          <a href="#default-tab-1" data-toggle="tab" class="nav-link active">
                            <span class="d-sm-none">Tab 1</span>
                            <span class="d-sm-block d-none">Media</span>
                          </a>
                        </li>
                        <li class="nav-items">
                          <a href="#default-tab-2" data-toggle="tab" class="nav-link">
                            <span class="d-sm-none">Tab 2</span>
                            <span class="d-sm-block d-none">Voicenotes</span>
                          </a>
                        </li>
                      </ul>
                      <!-- end nav-tabs -->
                      <!-- begin tab-content -->
                      <div class="tab-content">
                        <!-- begin tab-pane -->
                        <div class="tab-pane fade active show" id="default-tab-1">
                          <h3 class="m-t-10">Upload Documents</h3>
                          <DropZoneControl
                            :acceptedFiles="acceptedFiles"
                            :ClearData="true"
                            v-on:fileadded="fileadded"
                            :SessionID="invoiceDropZoneSessionID"
                          ></DropZoneControl>
                        </div>
                        <!-- end tab-pane -->
                        <!-- begin tab-pane -->
                        <div class="tab-pane fade" id="default-tab-2">
                          <AudioVideoControl
                            :SessionID="invoiceVoiceNoteSessionID"
                            :ClearData="true"
                            v-on:fileadded="fileadded"
                          />
                        </div>
                        <!-- end tab-pane -->
                      </div>
                      <!-- end tab-content -->
                    </div>
                    <div class="height-450" v-if="isReceipt">
                      <!-- begin nav-pills -->
                      <ul class="nav nav-pills">
                        <li class="nav-items">
                          <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active">
                            <span class="d-sm-none">Pills 1</span>
                            <span class="d-sm-block d-none">Media</span>
                          </a>
                        </li>
                        <li class="nav-items">
                          <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
                            <span class="d-sm-none">Pills 2</span>
                            <span class="d-sm-block d-none">Voicenotes</span>
                          </a>
                        </li>
                      </ul>
                      <!-- end nav-pills -->
                      <!-- begin tab-content -->
                      <div class="tab-content">
                        <!-- begin tab-pane -->
                        <div class="tab-pane fade active show" id="nav-pills-tab-1">
                          <h3 class="m-t-10">Attach files</h3>
                          <DropZoneControl
                            :acceptedFiles="acceptedFiles"
                            :ClearData="true"
                            v-on:fileadded="fileadded"
                            :SessionID="receiptDropZoneSessionID"
                          ></DropZoneControl>
                        </div>
                        <!-- end tab-pane -->
                        <!-- begin tab-pane -->
                        <div class="tab-pane fade" id="nav-pills-tab-2">
                          <AudioVideoControl
                            :SessionID="invoiceVoiceNoteSessionID"
                            :ClearData="true"
                            v-on:fileadded="fileadded"
                          />
                        </div>
                        <!-- end tab-pane -->
                      </div>
                      <!-- end tab-content -->
                    </div>
                  </div>
                  <div class="text-center m-b-5">
                    <a
                      href="javascript:;"
                      class="btn btn-danger m-5 btn-lg"
                      data-dismiss="modal"
                    >Close</a>
                    <a
                      href="javascript:;"
                      class="btn btn-primary m-5 btn-lg"
                      data-dismiss="modal"
                      v-if="isReceipt"
                      @click="disputeReceipt(context,RECEIPTDISPUTEADD)"
                    >Dispute</a>
                    <a
                      href="javascript:;"
                      class="btn btn-primary m-5 btn-lg"
                      data-dismiss="modal"
                      v-if="isInvoice"
                      @click="disputeInvoice(context, INVOICEDISPUTEADD)"
                    >Dispute</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- #modal-message end -->
          </div>
          <!-- end tab-pane -->
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab2-9">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Date Actioned</th>
                    <th>Added By</th>
                    <th>Comments</th>
                    <th>Dispute Status</th>
                    <th>Media</th>
                    <th>Voicenotes</th>
                  </tr>
                </thead>
                <tbody v-if="OPERATORRECEIPTSDISPUTES.length > 0">
                  <tr
                    v-for="(item) in OPERATORRECEIPTSDISPUTES"
                    v-bind:key=" 'disputedreceipts-' + item._id"
                  >
                    <td>{{item._actioned_date | formatToShortDate}}</td>
                    <td>{{item._added_by_name}}</td>
                    <td>{{item._receiptdisputes_feedback_comments}}</td>
                    <td>
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-primary': item._fk_dispute_status_names == 'Active' }"
                        v-if="item._fk_dispute_status_names == 'Active'"
                      >{{item._fk_dispute_status_names}}</span>
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-grey': item._fk_dispute_status_names == 'Pending' }"
                        v-if="item._fk_dispute_status_names == 'Pending'"
                      >{{item._fk_dispute_status_names}}</span>
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._fk_dispute_status_names == 'Declined' }"
                        v-if="item._fk_dispute_status_names == 'Declined'"
                      >{{item._fk_dispute_status_names}}</span>
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-success': item._fk_dispute_status_names == 'Resolved' }"
                        v-if="item._fk_dispute_status_names == 'Resolved'"
                      >{{item._fk_dispute_status_names}}</span>
                    </td>
                    <td
                      v-for=" media in item._receiptdisputes_feedback_media"
                      v-bind:key="'disputedmdiea-' + media.fileid"
                    >
                      <span
                        style="color: Dodgerblue; cursor: pointer;"
                        data-toggle1="tooltip-receipt-media"
                        :title="media.filename"
                        @click="downloadReceiptMedia(context, item._id, media.fileid)"
                      >
                        <i class="fas fa-file-pdf fa-fw fa-2x"></i>
                      </span>
                    </td>
                    <td
                      v-for=" item in item._receiptdisputes_feedback_voicenote"
                      v-bind:key="'disputedreceipt-' + item.fileid"
                    >
                      <span
                        style="color: red; cursor: pointer;"
                        data-toggle2="tooltip-receipt-voicenote"
                        :title="item.filename"
                      >
                        <i class="fas fa-microphone fa-fw fa-2x"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end tab-pane -->
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab2-10">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>INVOICE NUMBER</th>
                    <th>ADDED BY</th>
                    <th>DISPUTE STATUS</th>
                    <th>MEDIA</th>
                    <th>VOICENOTES</th>
                  </tr>
                </thead>
                <tbody v-if="OPERATORINVOICEDISPUTES.length > 0">
                  <tr v-for="item in OPERATORINVOICEDISPUTES" v-bind:key="item._id">
                    <td>{{item._date_added | formatToShortDate}}</td>
                    <td>{{item._fk_invoice_number}}</td>
                    <td>{{item._added_by_names}}</td>
                    <td>
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-primary': item._fk_dispute_status_names == 'Active' }"
                        v-if="item._fk_dispute_status_names == 'Active'"
                      >{{item._fk_dispute_status_names}}</span>
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-grey': item._fk_dispute_status_names == 'Pending' }"
                        v-if="item._fk_dispute_status_names == 'Pending'"
                      >{{item._fk_dispute_status_names}}</span>
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._fk_dispute_status_names == 'Declined' }"
                        v-if="item._fk_dispute_status_names == 'Declined'"
                      >{{item._fk_dispute_status_names}}</span>
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-success': item._fk_dispute_status_names == 'Resolved' }"
                        v-if="item._fk_dispute_status_names == 'Resolved'"
                      >{{item._fk_dispute_status_names}}</span>
                    </td>
                    <td
                      v-for=" media in item._invoicedisputes_feedback_media"
                      v-bind:key="media.fileid"
                    >
                      <span
                        style="color: Dodgerblue; cursor: pointer;"
                        data-toggle3="tooltip-invoice-media"
                        :title="media.filename"
                        @click="downloadInvoiceMedia(context, item._id, media.fileid)"
                      >
                        <i style="cursor: pointer;" class="fas fa-file-pdf fa-fw fa-2x"></i>
                      </span>
                    </td>
                    <td
                      v-for="(item) in item._invoicedisputes_feedback_voicenotes"
                      v-bind:key=" 'disputedinvoice-' +item.fileid"
                    >
                      <span
                        style="color: red; cursor: pointer;"
                        data-toggle4="tooltip-invoice-voicenote"
                        :title="item.filename"
                      >
                        <i style="cursor: pointer;" class="fas fa-microphone fa-fw fa-2x"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end tab-pane -->

           <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab2-11">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>MOBILE</th>
                    <th>MESSAGES</th>
                    <th>DATE SENT</th>
                    <th>DATE DELIVERED</th>
                    <th>DATE PENDING</th>
                    <th>DATE FAILED</th>
                    <th>RESPONSE</th>
                  </tr>
                </thead>
                <tbody v-if="SMSES.length > 0">
                  <tr v-for="item in SMSES" v-bind:key="item._id">
                    <td>{{item._mobilenumber}}</td>
                    <td>{{item._messagecontent}}</td>
                    <td>{{item._datesent}}</td>
                    <td>{{item._datedelivered}}</td>
                    <td>{{item._datedpending}}</td>
                    <td>{{item._datefailed}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <button
                        class="btn btn-primary btn-sm clipboard"
                        type="button"
                        :data-clipboard-text="`${item._responsepayload}`"
                      >Copy Response</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end tab-pane -->
        </div>
        <!-- end tab-content -->
      </div>
      <!-- end panel -->

      <div class="modal fade" id="approveModal" tabindex="-1" role="dialog">
        <div class="modal-dialog width-full" role="document">
          <div class="modal-content">
            <form>
              <div class="modal-header">
                <h4 class="text-center">Approve</h4>
              </div>
              <div class="modal-body width-full">
                <div class="col-md-12">
                  <!-- begin form-group row -->

                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Comment</label>
                    <div class="col-md-9">
                      <textarea
                        class="textarea form-control"
                        v-model="comment"
                        placeholder="Enter comment..."
                        rows="10"
                      ></textarea>
                    </div>
                  </div>
                  <!-- end form-group row -->
                </div>
              </div>
              <div class="modal-footer">
                <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
                <button
                  type="button"
                  class="btn btn-sm btn-success width-80"
                  @click="approve(context,GETSELECTED,USERID,comment)"
                  :disabled="$wait.is('executeapproval')"
                >
                  <v-wait v-bind:for="'executeapproval'">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Approve
                  </v-wait>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Edit Modal HTML START-->

      <!-- #modal-dialog -->
      <div class="modal fade" id="rejectModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <form>
              <div class="modal-header">
                <h4 class="text-center">Reject</h4>
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <!-- begin form-group row -->

                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Comment</label>
                    <div class="col-md-9">
                      <textarea
                        class="textarea form-control"
                        v-model="comment"
                        placeholder="Enter comment..."
                        rows="12"
                      ></textarea>
                    </div>
                  </div>
                  <!-- end form-group row -->
                </div>
              </div>
              <div class="modal-footer">
                <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
                <button
                  type="button"
                  class="btn btn-sm btn-danger width-80"
                  @click="reject(context,GETSELECTED,USERID,comment)"
                  :disabled="$wait.is('executereject')"
                >
                  <v-wait v-bind:for="'executereject'">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Reject
                  </v-wait>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="modal fade" id="changeBilling">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="text-center">
              <h4 class="modal-title">Change Operator billing</h4>
            </div>
            <div class="modal-body">
              <!-- begin form-group -->
              <div class="form-group row m-b-10">
                <label class="col-md-3 col-form-label text-md-right">Amount</label>
                <div class="col-md-6">
                  <input
                    type="number"
                    id="amount"
                    name="amount"
                    class="form-control"
                    v-model="CHANGEBILLING.amount"
                    placeholder="R0.00"
                  />
                </div>
              </div>
              <!-- End form-group -->
              <!-- begin form-group -->
              <div class="form-group row m-b-10">
                <label class="col-md-3 text-nowrap col-form-label text-md-right">Billing End Date:</label>
                <div class="date text-inverse col-md-7 pull-center">
                  <div
                    class="input-group date"
                    id="datepicker-billingEnd"
                    data-date-format="dd M yyyy"
                  >
                    <input type="text" class="form-control" placeholder="Billing End Date" />
                    <span class="input-group-addon">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <!-- end form group -->
              <!-- begin form-group -->
              <div class="form-group row m-b-10">
                <label class="col-md-3 text-nowrap col-form-label text-md-right">Next Billing Date:</label>
                <div class="date text-inverse col-md-7 pull-center">
                  <div
                    class="input-group date"
                    id="datepicker-nextBilling"
                    data-date-format="dd M yyyy"
                  >
                    <input type="text" class="form-control" placeholder="Next Billing  Date" />
                    <span class="input-group-addon">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <!-- end form group -->
            </div>
            <div class="modal-footer">
              <div class="text-center">
                <button href="javascript:;" class="btn btn-white m-5" data-dismiss="modal">Close</button>
                <button
                  class="btn btn-primary m-5"
                  @click="changeOperatorBillingType(context,CHANGEBILLING)"
                >Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- #modal-dialog end -->
      <!-- end #content -->

       <!-- Add Modal HTML START-->
       <div id="AddMobileNumberModal" class="modal fade">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Add Mobie Number</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->

                          <div class="form-group row m-b-10">
                                    <label class="col-md-4 col-form-label text-md-right">
                                      Mobile:
                                      <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-md-6">
                                      <input
                                        type="tel"
                                        placeholder="e.g 082 123 4567"
                                        data-parsley-group="step-1"
                                        data-parsley-required="true"
                                        v-model="NEWMOBILENO"
                                        class="form-control"
                                      />
                                    </div>
                                  </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-success"
                          value="Submit"
                          @click="addMobileNo(context)"
                        />
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Add Modal HTML START-->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->
	


<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import AudioVideoControl from "@/components/common/AudioVideoControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TO");

export default {
  name: "taxiOperatorProfile",
  data: function () {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      id: this.$route.params.id,
      OPERATORVEHICLES: [],
      GETOPERATORSBYIDNOPIC: [],
      GETOPERATORTIMELINE: [],
      GETOPERATORNUMBERS: [],
      OPERATORTRAFFICFINES: [],
      DRIVERSBYASSID: [],
      GETOPERATORSUPPORTINGDOCS: [],
      GETOPERATORBILLINGS: [],
      OPERATORTRANSACTIONS: [],
      OPERATORINVOICEDISPUTES: [],
      OPERATORRECEIPTSDISPUTES: [],
      SMSES: [],
      Pay: "Pay",
      approvalid: this.$route.params.approvalid,
      transid: "",
      isReceipt: false,
      isInvoice: false,
      acceptedFiles: "application/pdf",
      accountNumber: "",
      nooftaxis: "",
      accStatus: "",
      accBalance: 0,
      accounttype: null,
      accounttype_id: 0,
      bank_account_name: "",
      bank_account_number: "",
      bankname: "",
      date_of_birth: "",
      email_address: "",
      fax_number: "",
      firstname: "",
      gender_name: "",
      id_number: "",
      joined_date: "",
      membership_number: "",
      mobilenumber: "",
      passport_number: "",
      physical_suburb: "",
      physicaladdress: "",
      physicaladdress2: "",
      postal_suburb: "",
      postaladdress: "",
      postaladdress2: "",
      ras_registration_number: "",
      surname: "",
      taxi_operator_id: "",
      taxi_operators_status: "",
      taxi_operators_status_id: "",
      telephone_number: "",
      title_name: "",
      service_type_id: 0,
      skip: 0,
      length: 1000,
      comment: "",
      updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      isActive: false,
      ACTIVATEWHATSAPP: {},
      NEWMOBILENO: "",
      CHANGEBILLING: {
        operatorbillingid: null,
        operatorid: null,
        billingitemsid: null,
        amount: null,
        billingstartdate: "",
        billingenddate: "",
        nextbillingdate: "",
        lastbilldate: "",
        collectionfrequencyid: null,
        collectionfrequencynumofdays: "",
        billingtypeid: null,
        billingtypecode: "",
        collectionsettingsid: null,

        updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      GETUPLOADEDFILES: [],
      REDIRECT: true,
      EXTERNAL: true,
      TAMS: true,
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      GETSELECTED: {},
      invoiceDropZoneSessionID: this.$uuid.v4(),
      invoiceVoiceNoteSessionID: this.$uuid.v4(),
      receiptDropZoneSessionID: this.$uuid.v4(),
      receiptVoiceNoteSessionID: this.$uuid.v4(),
      DropZoneSessionID: this.$uuid.v4(),
      uploadPayDropZoneSessionID: this.$uuid.v4(),
      INVOICEDISPUTEADD: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        invoiceid: "",
        invoiceComments: "",
        voicenotesessionid: "",
        dropzoneid: "",
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      RECEIPTDISPUTEADD: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        receiptid: "",
        receiptComments: "",
        voicenotesessionid: "",
        dropzoneid: "",
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      GETSELECTEDDOCS: Object.assign(
        {},
        this.$store.getters["TO/GETOPERATORSUPPORTINGDOCS"][0]
      ),
      canEditOperator: "",
      canDeleteOperator: "",
      canChangeOperatorStatus: "",
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    AudioVideoControl,
  },
  computed: {
    ...mapGetters([]),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  beforeMount: function () {
    this.getById(this.context, this.id);
  },
  watch: {
    
  },
  methods: {
    ...mapActions([
      "getall",
      // "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted",
      "getOperatorById",
      "getOperatorBillingsById",
      "updateOperatorStatusRequest",
      "getOperatorTrafficFinesById",
    ]),

    setViewDocs(val) {
      window.open(val._document, '_blank');
    },
    addMobileNo: (context) => {
      if(context.NEWMOBILENO.length < 10)
      {
        context.$Notify.Error("Mobile Number must be 10 digits", "");
        return;
      }

      context.$Notify
        .YesNo(false, {
          context,
        })
        .then((value) => {
          if (value) {
            
            $("#AddMobileNumberModal").modal("hide");
            
              value.router = value.context.$router;
              value.context.$store
                .dispatch("TO/AddMobileNo", {
                  _addModel: {
                    taxioperatorid: parseInt(value.context.id),
                    mobileno: value.context.NEWMOBILENO,
                    addedby: parseInt(value.context.USERID)
                  },
                  router: value.router,
                }).then((data) => {
                  if(data.status)
                    value.context.$Notify.Success("Added Successfully", "");
                    value.context.GETOPERATORNUMBERS.push({
                      _mobilenumber: value.context.NEWMOBILENO,
                      _deleted_by: null,
                      _is_active: true,
                      _addedby_name: value.context.LoggedInUserFullName,
                      _taxi_operator_name: value.context.firstname + " " + value.context.surname,
                    })
                })
                .catch((error) => {
                  context.$Notify.Error(error.data[0].message, "");
                });
          }
        });
    },
    activateNotifications: (context, OPERATORDETAILS, status) => {
      context.$Notify
        .YesNo(false, {
          context,
          OPERATORDETAILS,
          status,
        })
        .then((value) => {
          if (value) {
            console.log(typeof $("#switcher_checkbox_hasoverdraft").val());

            if ($("#switcher_checkbox_hasoverdraft").val() == "on") {
              value.router = value.context.$router;
              value.context.$store
                .dispatch("TO/notificationsRequest", {
                  _addModel: value.OPERATORDETAILS,
                  router: value.router,
                })
                .then((data) => {
                  console.log("notifications requested: ", data);

                  value.context.$Notify.Success("SMS Sent");

                    fetch("https://tams-whatsapp-bc6zqxje4a-uc.a.run.app/api/v1/TAMS/WhatsAppService/Refresh")
                    .then((response) => {
                      console.log(response);
                    })
                    .catch((error) => {
                      console.log(error);
                    });

                  // window.RealtimeWeb.emit(
                  //   "operatorNotificationsmonitor",
                  //   value.context.mobilenumber /**/
                  // );
                  // window.RealtimeWeb.on("notificationsActivated", (data) => {
                  //   
                  //   console.log(data);
                  //   value.context.$Notify.Success(
                  //     `What's App Notifications Activated Successfully!`
                  //   );
                  //   value.context.service_type_id == 3;
                  //   window.RealtimeWeb.emit(
                  //     "operatorNotificationsunmonitor",
                  //     value.context.mobilenumber
                  //   );
                  // });

                  
                })
                .catch((error) => {
                  context.$Notify.Error(error.data[0].message, "");
                });
            } else {
              console.log("switcher is off");
            }
          }
        });
    },

    OpenapproveModal: (context, item) => {
      context.GETSELECTED = item;
      $("#approveModal").modal();
    },
    OpenrejectModal: (context, item) => {
      context.GETSELECTED = item;
      $("#rejectModal").modal();
    },
    CALL: (context) => {
      context.$Notify
        .YesNo(false, {
          context
        })
        .then((value) => {
          if (value) {
            value.context.$wait.start("Call");
            value.router = value.context.$router;
            value.context.$store
            .dispatch("TO/callOperator", {
              router: context.$router,
              agentno: value.context.USERPROFILE.dialeragentuserid,
              //mobileno: "0826869834",
              mobileno: value.context.mobilenumber,
            })
              .then(() => {
                value.context.$wait.end("Call");
                value.context.$Notify.Success("Successfull", "");
              })
              .catch((error) => {
                //value.context.$wait.end("Call");
                //value.context.$Notify.Error(error.data[0].message, "");
                value.context.$wait.end("Call");
                value.context.$Notify.Success("Successfull", "");
              })
              .finally(() => {
                value.context.$wait.end("Call");
              });
          }
        });
    },
    reject: (context, item, rejected_by, comment) => {
      item._id = context.approvalid;
      context.$Notify
        .YesNo(false, {
          context,
          item,
          rejected_by,
          comment,
          taxiassociation_id: context.taxiassociationid,
          approval_id: item._id,
        })
        .then((value) => {
          if (value) {
            value.context.$wait.start("executereject");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("approvals/executereject", {
                _dataObj: value,
              })
              .then(() => {
                context.comment = "";
                $("#rejectModal").modal("hide");
                value.context.$wait.end("executereject");
                value.context.$Notify.Success("Rejected!", "");
                vvalue.context.approvalid = undefined;
              })
              .catch((error) => {
                value.context.$wait.end("executereject");
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {
                value.context.$wait.end("executereject");
              });
          }
        });
    },
    approve: (context, item, approved_by, comment) => {
      context.$Notify
        .YesNo(false, {
          context,
          item,
          approved_by,
          comment,
          taxiassociation_id: context.taxiassociationid,
          approval_id: context.approvalid,
        })
        .then((value) => {
          if (value) {
            value.context.$wait.start("executeapproval");
            value.router = value.context.$router;
            value.item._id = value.context.approvalid;
            value.context.$store
              .dispatch("approvals/executeapproval", {
                _dataObj: value,
              })
              .then(() => {
                context.comment = "";
                value.context.$wait.end("executeapproval");
                value.context.$Notify.Success("Approved Successfully!", "");
                value.context.approvalid = undefined;
              })
              .catch((error) => {
                value.context.$Notify.Error(error.data.message, "");
              })
              .finally(() => {
                $("#approveModal").modal("hide");
                value.context.$wait.end("executeapproval");
              });
          }
        });
    },

    getById: (context, id) => {
      context.$store
        .dispatch("TO/getOperatorByIdNopic", {
          id,
          router: context.$router,
        })
        .then((_rb) => {
          console.log("this is the operator:", _rb);

          context.GETOPERATORSBYIDNOPIC = _rb;

          context.accountNumber = _rb[0].account_number;
          context.accStatus = _rb[0].account_status;
          context.accBalance = _rb[0].account_available_balance;
          context.title_name = _rb[0].title_name;
          context.accounttype = _rb[0].accounttype;
          context.accounttype_id = _rb[0].accounttype_id;
          context.bank_account_name = _rb[0].bank_account_name;
          context.bank_account_number = _rb[0].bank_account_number;
          context.bankname = _rb[0].bankname;
          context.date_of_birth = _rb[0].date_of_birth;
          context.email_address = _rb[0].email_address;
          context.fax_number = _rb[0].fax_number;
          context.firstname = _rb[0].firstname;
          context.gender_name = _rb[0].gender_name;
          context.id_number = _rb[0].id_number;
          context.joined_date = _rb[0].joined_date;
          context.membership_number = _rb[0].membership_number;
          context.mobilenumber = _rb[0].mobilenumber;
          context.passport_number = _rb[0].passport_number;
          context.physical_suburb = _rb[0].physical_suburb;
          context.physicaladdress = _rb[0].physicaladdress;
          context.physicaladdress2 = _rb[0].physicaladdress2;
          context.postal_suburb = _rb[0].postal_suburb;
          context.postaladdress = _rb[0].postaladdress;
          context.postaladdress2 = _rb[0].postaladdress;
          context.postaladdress_id = _rb[0].postaladdress_id;
          context.ras_registration_number = _rb[0].ras_registration_number;
          context.surname = _rb[0].surname;
          context.taxi_operator_id = _rb[0].taxi_operator_id;
          context.taxi_operators_status = _rb[0].taxi_operators_status;
          context.taxi_operators_status_id = _rb[0].taxi_operators_status_id;
          context.telephone_number = _rb[0].telephone_number;
          context.title_name = _rb[0].title_name;
          context.service_type_id = _rb[0].message_service_type_id;

          if (_rb[0].message_service_type_id == 3 /* Whats App servicetype */) {
            context.isActive = true;
          }

          context.operatorTransactions(context, _rb[0].account_number);

          if (context.ROLES) {
            context.ROLES.map((element) => {
              if (element._code == "UTO") {
                context.canEditOperator = element._code;
              }
              if (element._code == "DTO") {
                context.canDeleteOperator = element._code;
              }
              if (element._code == "COB") {
                context.canChangeOperatorStatus = element._code;
              }
            });
          }
        })
        .catch((error) => {
          console.log("operator errors :", error);
        })
        .finally(() => {
          $("#data-table-address").DataTable({
            responsive: true,
          });
        });
    },

    operatorVehiclesById: (context) => {
      // ;
      context.$store
        .dispatch("TO/getTaxiOperatorVehiclesById", {
          router: context.$router,
          operator_id: context.id,
          skip: context.skip,
          len: context.length,
        })
        .then((data) => {
          console.log("operator Vehicles: ", data);
          context.OPERATORVEHICLES = data;
          context.nooftaxis = data.length;
        })
        .catch((err) => {
          console.log("operator Vehicles errors -> ", err);
        })
        .finally(() => {
          $("#data-table-vehicles").DataTable({
            responsive: true,
          });
        });
      //this.$emit('init')
    },

    operatorTimeline: (context) => {
      // ;
      context.$store
        .dispatch("TO/getOperatorTimelineById", {
          _dataObj: {
            id: context.id,
            router: context.$router,
          },
        })
        .then((data) => {
          console.log("timeline: ", data);
          context.GETOPERATORTIMELINE = data;
        })
        .catch((err) => {
          console.log("timeline errors -> ", err);
        });
      //this.$emit('init')
    },

    operatorMobileNumbers: (context) => {
      // ;
      context.$store
        .dispatch("TO/getOperatorNumbersById", {
          _dataObj: {
            id: context.id,
            router: context.$router,
          },
        })
        .then((data) => {
          console.log("mobile numbers: ", data);
          context.GETOPERATORNUMBERS = data;
        })
        .catch((err) => {
          console.log("mobile number errors -> ", err);
        })
        .finally(() => {
          $("#data-table-mubilenumbers").DataTable({
            responsive: true,
          });
        });
      //this.$emit('init')
    },

    getOperatorTrafficfines: (context) => {
      context
        .getOperatorTrafficFinesById({
          id: context.id,
          skip: context.skip,
          len: context.length,
          router: context.$router,
        })
        .then((data) => {
          console.log("operator traffic fines: ", data);
          context.OPERATORTRAFFICFINES = data;
        })
        .catch((err) => {
          console.log("traffic fines errors: ", err);
        })
        .finally(() => {
           var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: true,
                    autoFill: true,
                    colReorder: true,
                    keys: true,
                    rowReorder: true,
                    select: true
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }

          $("#data-table-trafficFines").DataTable(options);
        });
    },

    getDriversByID: (context) => {
      context.$store
        .dispatch("TaxiDrivers/getAllByTaxiAssIdNoPic", {
          id: context.taxiassociationid,
          router: context.$router,
        })
        .then((data) => {
          console.log("Drivers Found: ", data);
          context.DRIVERSBYASSID = data;
        })
        .catch((error) => {
          console.log("Driver errors: ", error);
        })
        .finally(() => {});
    },

    operatorSupportingDocs: (context) => {
      // ;
      context.$store
        .dispatch("TO/getOperatorSupportingDocsById", {
          _dataObj: {
            id: context.id,
            router: context.$router,
          },
        })
        .then((data) => {
          console.log("supporting Docs: ", data);
          context.GETOPERATORSUPPORTINGDOCS = data;
        })
        .catch((err) => {
          console.log("supporting Docs errors -> ", err);
        })
        .finally(() => {
          $("#data-table-supportingDocs").DataTable({
            responsive: true,
          });
        });
      //this.$emit('init')
    },

    operatorTransactions: (context, accountnumber) => {
      context.$store
        .dispatch("TO/getOperatorTransactionsById", {
          taxiassociationid: context.taxi_association_id,
          accountNumber: accountnumber,
          skip: context.skip,
          length: context.length,
          router: context.$router,
        })
        .then((data) => {
          console.log("transactions: ", data);
          context.OPERATORTRANSACTIONS = data;
        })
        .catch((err) => {
          console.log("transaction errors: ", err);
        })
        .finally(() => {
          $("#data-table-transactions").DataTable({
            responsive: true,
          });
        });
      //this.$emit('init')
    },

    operatorInvoiceDisputes: (context) => {
      // ;
      context.$store
        .dispatch("TO/getOperatorInvoiceDisputesById", {
          _dataObj: {
            id: context.id,
            router: context.$router,
          },
        })
        .then((data) => {
          console.log("invoiceDisputes: ", data);
          context.OPERATORINVOICEDISPUTES = data;
        })
        .catch((err) => {
          console.log("invoiceDisputes errors -> ", err);
        });
      //this.$emit('init')
    },

    operatorsSMSes: (context) => {
      // ;

      
      context.$store
        .dispatch("TO/getoperatorsSMSes", {
            id: context.id,
            router: context.$router,
        })
        .then((data) => {
          console.log("SMSESEs: ", data);
          context.SMSES = data;
        })
        .catch((err) => {
          console.log("invoiceDisputes errors -> ", err);
        });
      //this.$emit('init')
    },

    operatorReceiptsDisputes: (context) => {
      //  ;
      context.$store
        .dispatch("TO/getOperatorReceiptsDisputesById", {
          _dataObj: {
            id: context.id,
            router: context.$router,
          },
        })
        .then((data) => {
          console.log("receiptsDisputes: ", data);
          context.OPERATORRECEIPTSDISPUTES = data;
        })
        .catch((err) => {
          console.log("receiptsDisputes errors -> ", err);
        });
      //this.$emit('init')
    },

    operatorStatus: (context) => {
      // ;
      context.$store
        .dispatch("TO/getOperatorStatusByAssociationId", {
          _dataObj: {
            id: context.taxi_association_id,
            router: context.$router,
          },
        })
        .then((data) => {
          console.log("status: ", data);

          const operatorstatussource = data.map((element) => {
            return {
              value: element.taxi_operators_status_id,
              text: element._name,
            };
          });
          console.log(
            "operatorstatussource: " + JSON.stringify(operatorstatussource)
          );

          const operatorstatus = context.taxi_operators_status;
          initiateOperatorStatus(operatorstatussource, operatorstatus);
        })
        .catch((err) => {
          console.log("status errors -> ", err);
        });
      //this.$emit('init')
    },

    getOperatorBillings: (context) => {
      // ;
      context
        .getOperatorBillingsById({
          id: context.id,
          skip: context.skip,
          len: context.length,
          router: context.$router,
        })
        .then((data) => {
          console.log("operator billings: ", data);
          context.GETOPERATORBILLINGS = data;
        })
        .catch((err) => {
          console.log("billings errors: ", err);
        })
        .finally(() => {
          $("#data-table-billings").DataTable({
            responsive: true,
          });
        });
    },

    FILTERBYROLE: (context) => {
      $(".byrole").each(function () {
        if (
          context.ROLES.length > 0 &&
          context.ROLES.filter(
            (item) => item._code === $(this).attr("data-role")
          ).length > 0
        )
          $(this).removeClass("hide");
      });
    },

    billingInfo: (context, payload) => {
      console.log("change billing item", payload);
      $("#changeBilling").modal("show");
      if (payload != 0 && payload != null && payload != {}) {
        context.CHANGEBILLING.operatorbillingid = payload._id;
        context.CHANGEBILLING.operatorid = payload._fk_operator_id;
        context.CHANGEBILLING.billingitemsid = payload._fk_billing_items_id;
        context.CHANGEBILLING.billingstartdate = payload._billing_start_date;
        context.CHANGEBILLING.billingenddate = payload._billing_end_date;
        context.CHANGEBILLING.collectionfrequencyid =
          payload._fk_collection_frequency_id;
        context.CHANGEBILLING.collectionfrequencynumofdays =
          payload._collection_frequency_num_of_days;
        context.CHANGEBILLING.billingtypeid = payload._fk_billingtype_id;
        context.CHANGEBILLING.billingtypecode = payload._billingtype_code;
        context.CHANGEBILLING.collectionsettingsid =
          payload._fk_collection_settings_id;
      }
    },

    selectedReceipt: (context, payload) => {
      console.log("this is the selected receipt: ");
      context.transid = payload._id;
      if (
        payload._receipts_id != null &&
        payload._receipts_id != undefined &&
        payload._receipts_id != ""
      ) {
        let receipt = payload._receipts_id;
        context.RECEIPTDISPUTEADD.receiptid = receipt;
        console.log("selectedreceipt ", context.RECEIPTDISPUTEADD.receiptid);
        context.isReceipt = true;
        context.isInvoice = false;
        $("#modal-dialog").modal("show");
      } else if (
        payload._invoice_id != null &&
        payload._invoice_id != undefined &&
        payload._invoice_id != ""
      ) {
        let invoice = payload._invoice_id;
        context.INVOICEDISPUTEADD.invoiceid = invoice;
        console.log("selectedinvoice ", context.INVOICEDISPUTEADD.invoiceid);
        context.isInvoice = true;
        context.isReceipt = false;
        $("#modal-dialog").modal("show");
      } else {
        alert("no receipt or invoice found");
      }
    },

    fileadded: function (data) {
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')

      const context = $("#content").data("context");
      context.GETUPLOADEDFILES = context.$store.getters["fileupload/GETALL"];
    },

    disputeReceipt: (context, payload) => {
      context.RECEIPTDISPUTEADD.dropzoneid = context.receiptDropZoneSessionID;
      context.RECEIPTDISPUTEADD.voicenotesessionid =
        context.receiptVoiceNoteSessionID;

      context.RECEIPTDISPUTEADD.receiptComments = document.getElementById(
        "clipboard-textarea"
      ).value;

      context.$Notify.YesNo(false, { context, payload }).then((value) => {
        if (value) {
          value.router = value.context.$router;
          value.context.$store
            .dispatch("invoices/createReceiptDisputeRequest", {
              router: value.router,
              _addModel: {
                taxi_association_id:
                  value.context.RECEIPTDISPUTEADD.taxiassociationid,
                receipt_id: value.context.RECEIPTDISPUTEADD.receiptid,
                comments: value.context.RECEIPTDISPUTEADD.receiptComments,
                voicenotesessionid:
                  value.context.RECEIPTDISPUTEADD.voicenotesessionid,
                dropzoneid: value.context.receiptDropZoneSessionID,
                addedby: value.context.updatedby,
              },
            })
            .then(() => {
              context.RECEIPTDISPUTEADD.receiptComments = "";
              context.RECEIPTDISPUTEADD.voicenotesessionid = "";
              value.context.$Notify.Success("Dispute Request added!", "");
            })
            .catch((error) => {
              value.context.$Notify.Error(error.message, "");
            })
            .finally(() => {});
        }
      });
    },

    disputeInvoice: (context, payload) => {
      context.INVOICEDISPUTEADD.dropzoneid = context.DropZoneSessionID;
      context.INVOICEDISPUTEADD.voicenotesessionid =
        context.invoiceDropZoneSessionID;

      context.INVOICEDISPUTEADD.invoiceComments = document.getElementById(
        "clip-textarea"
      ).value;

      context.$Notify.YesNo(false, { context, payload }).then((value) => {
        if (value) {
          value.router = value.context.$router;
          value.context.$store
            .dispatch("invoices/createInvoiceDisputeRequest", {
              router: value.router,
              _addModel: {
                taxi_association_id:
                  context.INVOICEDISPUTEADD.taxiassociationid,
                invoice_id: context.INVOICEDISPUTEADD.invoiceid,
                comments: context.INVOICEDISPUTEADD.invoiceComments,
                voicenotesessionid:
                  context.INVOICEDISPUTEADD.voicenotesessionid,
                dropzoneid: context.INVOICEDISPUTEADD.dropzoneid,
                addedby: context.updatedby,
              },
            })
            .then(() => {
              context.INVOICEDISPUTEADD.invoiceComments = "";
              context.DropZoneSessionID = "";
              value.context.$Notify.Success("Dispute Request added!", "");
            })
            .catch((error) => {
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },

    changeOperatorBillingType: (context, _addModel) => {
      context.CHANGEBILLING.billingstartdate = context.$Toolkit.ConvertToServerDate(
        context.CHANGEBILLING.billingstartdate
      );

      context.CHANGEBILLING.lastbilldate = context.$Toolkit.ConvertToServerDate(
        context.CHANGEBILLING.lastbilldate
      );

      const billingEndDate = context.$Toolkit.ConvertToServerDate(
        $("#datepicker-billingEnd").find("input").val()
      );

      const nextBillingDate = context.$Toolkit.ConvertToServerDate(
        $("#datepicker-nextBilling").find("input").val()
      );

      context.CHANGEBILLING.lastbilldate = billingEndDate;
      context.CHANGEBILLING.nextbillingdate = nextBillingDate;

      context.$Notify.YesNo(false, { context, _addModel }).then((value) => {
        if (value) {
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TO/changeOperatorBilling", {
              addModel: value._addModel,
              router: value.router,
            })
            .then((data) => {
              value.context.$Notify.Success("Billing change requested", data);
              $("#changeBilling").modal("hide");
            })
            .catch((error) => {
              console.log("billing change error", error);
              value.context.$Notify.Error(error.message, "");
              $("#changeBilling").modal("hide");
            })
            .finally(() => {});
        }
      });
    },
  },
  mounted: function () {
    App.init();

    var clipboard = new ClipboardJS(".clipboard");

    clipboard.on("success", function (e) {
      $(e.trigger).tooltip({
        title: "Copied",
        placement: "top",
      });
      $(e.trigger).tooltip("show");
      setTimeout(function () {
        var bootstrapVersion = handleCheckBootstrapVersion();
        if (bootstrapVersion >= 3 && bootstrapVersion < 4) {
          $(e.trigger).tooltip("destroy");
        } else if (bootstrapVersion >= 4 && bootstrapVersion < 5) {
          $(e.trigger).tooltip("dispose");
        }
      }, 500);
    });

    // window.RealtimeWeb.on("notificationsActivated", (data) => {

    //   console.log("notifications data: ", data);
    //   context.$Notify.success(
    //     `What's App Notifications Activated Successfully!`
    //   );
    //   this.isActive = true;
    //   window.RealtimeWeb.emit(
    //     "operatorNotificationsunmonitor",
    //     this.mobilenumber
    //   );
    // });

    // window.RealtimeWeb.on("notificationsActivated", (data) => {
    //   console.log(data);
    //   this.$Notify.Success(
    //     `What's App Notifications Activated Successfully!`
    //   );
    //   this.service_type_id == 3;
    //   window.RealtimeWeb.emit(
    //     "operatorNotificationsunmonitor",
    //     this.mobilenumber
    //   );
    // });


    // $("#selectoperatorpicker").select2({
    //   multiple: false,
    //   placeholder: "Select A Taxi Operator",
    //   allowClear: false,
    // });

    //  window.RealtimeWeb.on('rankmonitorOut', (data) => {

    //   console.log(data);
    // });

    $(".byrole").addClass("hide");
    this.FILTERBYROLE(this.context);
    window.PushManager.listenToIncomingMessages(this.context);

    // $(".dropdown-toggle").dropdown();

    this.operatorVehiclesById(this.context);
    this.operatorTimeline(this.context);
    this.operatorMobileNumbers(this.context);
    this.getOperatorTrafficfines(this.context);
    this.getDriversByID(this.context);
    this.operatorSupportingDocs(this.context);
    this.operatorInvoiceDisputes(this.context);
    this.operatorReceiptsDisputes(this.context);
    this.getOperatorBillings(this.context);
    this.operatorsSMSes(this.context);

    $("#datepicker-billingEnd").datepicker({
      todayHighlight: true,
      autoclose: true,
    });

    $('[data-toggle="tooltip"]').tooltip();

    $("#datepicker-nextBilling").datepicker({
      todayHighlight: true,
      autoclose: true,
    });

    // $("#data-table-address").DataTable({
    //   responsive: true
    // });

    // $("#data-table-trafficFines").DataTable({
    //   responsive: true
    // });21

    // $("#data-table-vehicles").DataTable({
    //   responsive: true
    // });

    $('[data-toggle1="tooltip-receipt-media"]').tooltip();

    $('[data-toggle2="tooltip-receipt-voicenote"]').tooltip();

    $('[data-toggle3="tooltip-invoice-media"]').tooltip();

    $('[data-toggle4="tooltip-invoice-voicenote"]').tooltip();

    $("#operatorStatus").data("context", this.context);
    this.operatorStatus(this.context);

    $("#pay").data("context", this.context);
    var paymentMethod = function (value) {
      console.log("payment value", value);
      const context = $("#pay").data("context");
      $("#pay").editable({
        prepend: value,
        source: [
          { value: 1, text: "Male" },
          { value: 2, text: "Female" },
        ],
      });
    };
  },
};

var initiateOperatorStatus = function (operatorstatussource, operatorstatus) {
  const context = $("#operatorStatus").data("context");
  $("#operatorStatus").editable({
    prepend: operatorstatus,
    source: operatorstatussource,
    url: null,
    mode: "inline",
    success: function (response, newValue) {
      console.log(this.prepend);

      const context = $("#operatorStatus").data("context");

      context.$Notify.YesNo(false, { context, newValue }).then((value) => {
        if (value) {
          if (context.canChangeOperatorStatus !== "COB") {
            return value.context.$Notify.Error(
              "Sorry, You Do Not Have Permission For This Action."
            );
          } else {
            value.context.$wait.start("add");
            value.router = value.context.$router;
            value.context
              .updateOperatorStatusRequest({
                router: value.router,
                addModel: {
                  taxiassociationid: context.taxi_association_id,
                  taxioperatorid: context.id,
                  taxioperatorstatusid: newValue,
                  updatedby: context.updatedby,
                },
              })
              .then((_rb) => {
                console.log(_rb[0].taxi_operators_status);
                this.prepend = _rb[0].taxi_operators_status; //update backbone model
                $("#AddModal").modal("hide");
                $("#operatorStatus").editable("setValue", this.prepend);
                value.context.$wait.end("add");
                value.context.$Notify.Success("Status Change Requested!", "");
              })
              .catch((error) => {
                value.context.$wait.end("add");

                value.context.$Notify.Error(error.data.message, "");
              })
              .finally(() => {});
          }
        }
      });
    },
  });
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

