<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
      v-on:init="init"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'RankManager'"
      :ActiveMenu="'RealtimeRankActivities'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <transition name="bounce">
      <div id="content" class="content" v-show="USERPROFILE.taxi_associations_id">
        <!-- begin page-header -->
        <headerpagebreadcrumb
          pageheaderTitle="Rank Manager Dashboard"
          pageheaderdescription=""
          breadcrumbitem1="Home"
          breadcrumbitem2="Rank Manager"
          breadcrumbitem3="Details"
        />
        <!-- end page-header -->
        <!-- begin #Main Body Template -->

        <!-- begin row -->
        <div class="row">
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="fas fa-bus"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Trips</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totaltrips | numberwith}}</div>

                <div class="stats-desc">Total Number Of Trips</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="fas fa-bus"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Passengers</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalpassangers | numberwith}}</div>

                <div class="stats-desc">Total Number Of Passengers</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="far fa-hourglass"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Fares</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totaltripsfares | newcurrencywithSymbol}}</div>
                <div class="stats-desc">Total Fares</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-green">
                <i class="fas fa-arrow-circle-down"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Average Passagers Per Trip</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.avgpassangersperhour}}</div>
                <div class="stats-desc">Average Passagers Per Trip</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->

           <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-green">
                <i class="fas fa-arrow-circle-down"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Average Fare Per Trip</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.avgpassangersperhour}}</div>
                <div class="stats-desc">Average Fare Per Trip</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->

           <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-green">
                <i class="fas fa-arrow-circle-down"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Average Trips(Out) Per Day</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.avgpassangersperhour}}</div>
                <div class="stats-desc">Average Trips(Out) Per Day</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->

           <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-green">
                <i class="fas fa-arrow-circle-down"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Average Gross Per Trip.</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.avgpassangersperhour}}</div>
                <div class="stats-desc">Average Gross Per Trip.</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->

        </div>

        <!-- end row -->

         <!-- begin row -->
        <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-12">

                <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">
                  Total Queueing, Trips And Passangers Per Route
                  
                  </h4>

              </div>
              <div class="panel-body">
                <div id="interactive-chartqueueingtripspassages" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>

         
        </div>
        <!-- end row -->


        <!-- begin row -->
        <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-8">

           <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-2">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Taxis Queueing
                  <select
                    id="affiliateFilter1"
                    class="ml-2 btn btn-success btn-xs"
                    @change="onChange($event)"
                  >
                   <option value="0" selected>Select Route</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLROUTES"
                                  :value="item._id"
                                >{{item._name}}</option>
                  </select>
                </h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered" id="queuedetails">
                    <thead>
                      <tr>
                        <th>Queue No</th>
                        <th>Reg No</th>
                        <th>Make Model</th>
                        <th>Operator Name</th>
                        <th>Marshal Name</th>
                        <th>Time In</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					          v-for="(item,index) in GETQEUEUEDDETAILS"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
                      <td>{{item.numberinqueue}}</td>
                       <td>
                          {{item.licence_number}}
                        </td>
                        <td>{{item.makemodel}}</td>
                        <td>{{item.operatorname}}</td>
                        <td>{{item.marshalname}}</td>
                        <td>{{item.time_in}}</td>
                        
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-3">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Taxis Trips
                </h4>
              </div>
               <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered" id="tripsdetails">
                    <thead>
                      <tr>
                        <th>Reg No</th>
                        <th>Make Model</th>
                        <th>Operator Name</th>
                        <th>Marshal Name</th>
                        <th>No. Of Passangers</th>
                        <th>Time In</th>
                        <th>Time Out</th>
                        <th>Waiting Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETTRIPSDETAILS"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
                        <td>
                          {{item.licence_number}}
                        </td>
                        <td>{{item.makemodel}}</td>
                        <td>{{item.operatorname}}</td>
                        <td>{{item.marshalname}}</td>
                        <td>{{item.numberofpassagers}}</td>
                        <td>{{item.timein}}</td>
                        <td>{{item.timeout}}</td>
                        <td>{{item.waitingtime}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->


          </div>

         
          <!-- end col-8 -->
          <!-- begin col-8 -->
          <div class="col-xl-4">

              <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-6">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top 5 Taxis With Most Trips</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Reg No</th>
                        <th>Operator Name</th>
                        <th>Trips</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETMOSTTRIPSTOP5DETAILS"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
                        <td>{{item.licence_number}}</td>
                        <td>{{item.operatorname}}</td>
                        <td>{{item.count}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

             <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-10">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top 5 Taxis With Least Trips</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Reg No</th>
                        <th>Operator Name</th>
                        <th>Trips</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETMOSTLEASTTOP5DETAILS"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
						            <td>{{item.licence_number}}</td>
                        <td>{{item.operatorname}}</td>
                        <td>{{item.count}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->


          </div>
          <!-- end col-8 -->
         
        </div>
        <!-- end row -->

      
        <!-- begin row -->

        <!-- begin #Main Body Template -->
      </div>
    </transition>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import statsCards from "@/components/charts/statsCards.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers(
  "rankmanagerrealtime"
);

export default {
  name: "realtimerankmanager_dashboard",
  data: function() {
    return {
      message: "Some Message",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      rankid: this.$route.params.id,
      rankname: this.$route.params.desc,
      GETDASHBOARDCARDS: {},
      GETROUTESTATS: {},
      GETOPERATORFINSTATSTOP5: {},
      GETQEUEUEDDETAILS: {},
      GETTRIPSDETAILS: {},
      GETMOSTTRIPSTOP5DETAILS: {},
      GETMOSTLEASTTOP5DETAILS: {},
      GETALLROUTES: {},
      ROUTEID: {}
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    statsCards,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["", ""])
  },
  methods: {
    ...mapActions(["getrealtimedashboardcards", "getrankmanagerqueuetripsdetails","getrankmanagertop5","getroutesbyrankid"]),
    init: function() {},
    onChange(event) {

      const context = $(document).data("context");

      context.ROUTEID = event.target.value;

      // setInterval(function() {
    // your code goes here...

      var payload = {
      routeid: context.ROUTEID
    };

    var router = context.router;

      context.getrankmanagerqueuetripsdetails({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        context.GETQEUEUEDDETAILS = parsedResultOP.queueing;
        context.GETTRIPSDETAILS = parsedResultOP.trips;
        
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

    // }, 60 * 1000); // 60 * 1000 milsec

    }
  },
  mounted: function() {
    $(document).data("context", this);

    

    App.init();

    const router = this.$router;

     var payload = {
      rankid: this.rankid
    };
    
    

      this.getroutesbyrankid({ router, payload })
      .then(parsedResultOP => {
        
        const context = $(document).data("context");

        context.GETALLROUTES = parsedResultOP;
        
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      }).finally(() => {
        // $("#queuedetails").DataTable({
        //     responsive: true,
        //   });

        //   $("#tripsdetails").DataTable({
        //     responsive: true,
        //   });
      });


    var payload = {
      rankid: this.rankid
    };

    this.getrealtimedashboardcards({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        context.GETDASHBOARDCARDS = parsedResultOP.totals[0];
        context.GETROUTESTATS = parsedResultOP.totalsroutedetails;


        handleTotalRouteDetails();

        
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

    

      payload = {
        rankid: this.rankid
      };

      this.getrankmanagertop5({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        

        context.GETMOSTTRIPSTOP5DETAILS = parsedResultOP.top5mosttrips;
        context.GETMOSTLEASTTOP5DETAILS = parsedResultOP.top5leasttrips;
 
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

    // this.getoperatorfinstats({ router, payload })
    //   .then(parsedResultOP => {
    //     const context = $(document).data("context");

    //     context.GETOPERATORFINSTATS = parsedResultOP;

    //     handleOperatorByGender();
    // handleOperatorByAgeGroup();
		// handleVehicleWithNoOperatingLicense();
    // handleTotalOpPerTaxiAssBarChart();
    // handleVehicleWithfinancechartdisk();

    
    //   })
    //   .catch(error => {
    //     //context.$wait.end("generatestatement");
    //   });

      // this.getoperatorfinstatstop5({ router, payload }).then(parsedResultOP => {
      //   const context = $(document).data("context");
      //   
      //   context.GETOPERATORFINSTATSTOP5 = parsedResultOP;
      // })
      // .catch(error => {
      //   //context.$wait.end("generatestatement");
      // });

    // var handleOperatorByAgeGroup = function() {
    //   "use strict";
    //   if ($("#donut-OperatorByAgeGroup").length !== 0) {
    //     const context = $(document).data("context");

    //     var operatorAgeGroupData = [
    //       {
    //         label: `Retirement(${context.GETOPERATORFINSTATS.operatorbyagegroup[0].oldage})`,
    //         data: context.GETOPERATORFINSTATS.operatorbyagegroup[0].oldage,
    //         color: COLOR_BLUE
    //       },
    //       {
    //         label: `Middle Age(${context.GETOPERATORFINSTATS.operatorbyagegroup[0].middleage})`,
    //         data: context.GETOPERATORFINSTATS.operatorbyagegroup[0].middleage,
    //         color: COLOR_PURPLE_LIGHTER
    //       },
    //       {
    //         label: `Youth(${context.GETOPERATORFINSTATS.operatorbyagegroup[0].youth})`,
    //         data: context.GETOPERATORFINSTATS.operatorbyagegroup[0].youth,
    //         color: COLOR_GREEN
    //       }
    //     ];

    //     $.plot("#donut-OperatorByAgeGroup", operatorAgeGroupData, {
    //       series: {
    //         pie: {
    //           innerRadius: 0.5,
    //           show: true,
    //           label: {
    //             show: true
    //           }
    //         }
		//   },
		//   grid: {
    //         hoverable: true,
    //         clickable: true
    //       },
    //       legend: {
    //         show: true
    //       }
    //     });
    //   }
    // };

  //   var handleOperatorByGender = function() {
  //     "use strict";
  //     if ($("#donut-OperatorByGender").length !== 0) {
  //       const context = $(document).data("context");

  //       const colors = [COLOR_PURPLE_LIGHTER, COLOR_BLUE];

  //       var operatorGenderData = context.GETOPERATORFINSTATS.operatorsbygender.map(
  //         (item, index) => {
  //           return {
  //             label: item.gendertype + `(${item.gendercount})`,
  //             data: item.gendercount,
  //             color: colors[index]
  //           };
  //         }
  //       );

  //       $.plot("#donut-OperatorByGender", operatorGenderData, {
  //         series: {
  //           pie: {
  //             innerRadius: 0.5,
  //             show: true,
  //             label: {
  //               show: true
  //             }
  //           }
  //         },
  //         grid: {
  //           hoverable: true,
  //           clickable: true
  //         },
  //         legend: {
  //           show: true
  //         }
  //       });
  //     }
	// };
	
	//  var handleVehicleWithNoOperatingLicense = function() {
  //     "use strict";
  //     if ($("#VehicleWithNoOperatingLicense").length !== 0) {
  //       const context = $(document).data("context");

	// 	const colors = [COLOR_PURPLE_LIGHTER, COLOR_BLUE];
		

  //       var nooperatinglicense = [
	// 		{
  //           label: `Yes(${context.GETOPERATORFINSTATS.operatinglicense[0].hasoperatinglicense})`,
  //           data: context.GETOPERATORFINSTATS.operatinglicense[0].hasoperatinglicense,
  //           color: COLOR_GREEN
  //         },
  //         {
  //           label: `No(${context.GETOPERATORFINSTATS.operatinglicense[0].nooperatinglicense})`,
  //           data: context.GETOPERATORFINSTATS.operatinglicense[0].nooperatinglicense,
  //           color: COLOR_RED
  //         },
  //         {
  //           label: `Expired(${context.GETOPERATORFINSTATS.operatinglicense[0].expiredoperatinglicense})`,
  //           data: context.GETOPERATORFINSTATS.operatinglicense[0].expiredoperatinglicense,
  //           color: COLOR_PURPLE_LIGHTER
  //         },
  //       ];

  //       $.plot("#VehicleWithNoOperatingLicense", nooperatinglicense, {
  //         series: {
  //           pie: {
  //             innerRadius: 0.5,
  //             show: true,
  //             label: {
  //               show: true
  //             }
  //           }
  //         },
  //         grid: {
  //           hoverable: true,
  //           clickable: true
  //         },
  //         legend: {
  //           show: true
  //         }
  //       });
  //     }
	// };

var handleTotalRouteDetails = function () {
'use strict';
	
	if ($('#interactive-chartqueueingtripspassages').length !== 0) {

    const context = $(document).data("context");

    

    var data_trips =  context.GETROUTESTATS.map((item, index) => {
          return item.trips
        });
    
     var data_queues =  context.GETROUTESTATS.map((item, index) => {
          return item.queues
        });

        var data_passangers =  context.GETROUTESTATS.map((item, index) => {
          return item.passangers
        });

        var data_routename =  context.GETROUTESTATS.map((item, index) => {
          return item.name
        });
	
	var options = {
		chart: {
			height: 350,
			type: 'bar'
		},
		title: {
			text: 'Realtime Queues, Trips Chart',
			align: 'center'
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '55%',
				endingShape: 'rounded'	
			},
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		},
		colors: [COLOR_YELLOW,COLOR_GREEN, COLOR_BLUE],
		series: [{
			name: 'Queues',
			data: data_queues
		}, {
			name: 'Trips',
			data: data_trips
    }
    ],
		xaxis: {
			categories: data_routename,
			axisBorder: {
				show: true,
				color: COLOR_SILVER_TRANSPARENT_5,
				height: 1,
				width: '100%',
				offsetX: 0,
				offsetY: -1
			},
			axisTicks: {
				show: true,
				borderType: 'solid',
				color: COLOR_SILVER,
				height: 6,
				offsetX: 0,
				offsetY: 0
			}
		},
		yaxis: {
			title: {
				text: ''
			}
		},
		fill: {
			opacity: 1
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return + val
				}
			}
		}
	};
	
	var chart = new ApexCharts(
		document.querySelector('#interactive-chartqueueingtripspassages'),
		options
	);

	chart.render();
	
	}
};

// var handleVehicleWithfinancechartdisk = function () {
// 'use strict';
	
// 	if ($('#Vehiclefinancechart').length !== 0) {

//     const context = $(document).data("context");

//     

//     var data_ =  context.GETOPERATORFINSTATS.vehiclefinchat.map((item, index) => {
//           return item.numberofvehicles
//         });
    
//      var data_fdi =  context.GETOPERATORFINSTATS.vehiclefinchat.map((item, index) => {
//           return item.fdi
//         });
	
// 	var options = {
// 		chart: {
// 			height: 350,
// 			type: 'bar'
// 		},
// 		title: {
// 			text: 'Taxi Finance Chart',
// 			align: 'center'
// 		},
// 		plotOptions: {
// 			bar: {
// 				horizontal: false,
// 				columnWidth: '55%',
// 				endingShape: 'rounded'	
// 			},
// 		},
// 		dataLabels: {
// 			enabled: false
// 		},
// 		stroke: {
// 			show: true,
// 			width: 2,
// 			colors: ['transparent']
// 		},
// 		colors: [COLOR_BLUE, COLOR_GREEN],
// 		series: [{
// 			name: 'Operators',
// 			data: data_
// 		}],
// 		xaxis: {
// 			categories: data_fdi,
// 			axisBorder: {
// 				show: true,
// 				color: COLOR_SILVER_TRANSPARENT_5,
// 				height: 1,
// 				width: '100%',
// 				offsetX: 0,
// 				offsetY: -1
// 			},
// 			axisTicks: {
// 				show: true,
// 				borderType: 'solid',
// 				color: COLOR_SILVER,
// 				height: 6,
// 				offsetX: 0,
// 				offsetY: 0
// 			}
// 		},
// 		yaxis: {
// 			title: {
// 				text: ''
// 			}
// 		},
// 		fill: {
// 			opacity: 1
// 		},
// 		tooltip: {
// 			y: {
// 				formatter: function (val) {
// 					return + val
// 				}
// 			}
// 		}
// 	};
	
// 	var chart = new ApexCharts(
// 		document.querySelector('#Vehiclefinancechart'),
// 		options
// 	);

// 	chart.render();
	
// 	}
// };
  
   
  }
};
</script>

<style>
@import "/assets/plugins/nvd3/build/nv.d3.css";
</style>