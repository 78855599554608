<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TaxiAssociation'"
      :ActiveMenu="'ManageTaxiAssociationRanks'"
    />

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Manage Association Ranks"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Association"
        breadcrumbitem3="Ranks"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">Taxi Association Ranks</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <a
                  data-toggle="modal"
                  data-target="#AddModal"
                  class="btn btn-white"
                  href="javascript:;"
                >
                  <i class="fa fa-plus"></i>
                  Add Rank
                </a>
              </div>
            </div>

            <!-- Add Modal HTML START-->
              <div id="AddModal" class="modal fade">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Add Rank</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Name</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                v-model.lazy="ADDRANK.name"
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->
                           <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Description</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                v-model.lazy="ADDRANK.description"
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        >
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="addRank(context,ADDRANK)"
                          data-dismiss="modal"
                          :disabled="$wait.is('add-')"
                        >
                          <v-wait v-bind:for="'add-'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Save
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Add Modal HTML START-->

              <!-- Add Modal HTML START-->
              <div id="EditModal" class="modal fade">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Update Rank</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Name</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                v-model.lazy="UPDATERANK.name"
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->
                           <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Description</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                v-model.lazy="UPDATERANK.description"
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        >
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          data-dismiss="modal"
                          :disabled="$wait.is('update-')"
                          @click="updateRank(context, rankid, UPDATERANK)"
                        >
                          <v-wait v-bind:for="'update-'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Save
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Add Modal HTML START-->

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-default" class="table table-striped table-bordered width-full">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">Description</th>
                    <th class="text-nowrap">Added By</th>
                    <th class="text-nowrap">Date Added</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in ASSOCIATIONRANKS"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.description}}</td>
                    <td>{{item.added_by_name}}</td>
                    <td>{{item.date_added | formatToShortDate}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item.deleted_by }"
                        v-if="item.deleted_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.isactive, 'label-secondary':!item.isactive  }"
                        v-if="!item.deleted_by"
                      >{{item.isactive == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td class="with-btn valign-middle" nowrap>
                      <router-link
                        type="button"
                        class="btn btn-sm btn-warning width-60 m-r-2"
                        :to="{ name: 'ranksummary', params: {id: item.id,desc: item.name } }"
                      >View</router-link>
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-60"
                        data-toggle="modal"
                        data-target="#EditModal"
                        @click="getRank(context,item.id)"
                      >Edit
                      </button>
                      <router-link
                        type="button"
                        class="btn btn-sm btn-primary width-60 m-r-2"
                        :to="{ name: 'taxiassociationRoutes', params: {id: item.id }}"
                      >Routes</router-link>
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-60"
                        data-toggle="modal"
                        data-target="#deletedModal"
                        v-if="item.deleted_by"
                        @click="restoredelete(context,item,USERPROFILE.system_usersid)"
                        :disabled="$wait.is('restoreDeleted-' + item.id)"
                      >
                        <v-wait v-bind:for="'restoreDeleted-' + item.id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Restore
                        </v-wait>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-60"
                        v-if="!item.deleted_by"
                        @click="deletedata(context,item,USERPROFILE.system_usersid)"
                        :disabled="$wait.is('delete-' + item.id)"
                      >
                        <v-wait v-bind:for="'delete-' + item.id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Delete
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Edit Modal HTML START-->
              <div id="ViewModal" class="modal fade">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="text-center">
                        <h4 class="modal-title">Association Details</h4>
                      </div>
                      <div class="modal-body">
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Affiliation
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="N/A"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            District
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="N/A"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Abbreviation
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="N/A"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Telephone
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="N/A"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Mobile Number
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="N/A"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Max Number Of Operators
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="N/A"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Max Number Of Taxis Per Operator
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="N/A"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Close"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Edit Modal HTML START-->
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TA");

export default {
  name: "ManageTaxiAssociation",
  data: function() {
    return {
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      ASSOCIATIONRANKS: [],
      context: this,
      ADDRANK: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
          name: "",
          description: "",
          addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      UPDATERANK: {
        taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
          name: "",
          description: "",
          updated_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      rankid: null
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  beforeMount: function() {
    this.getbyAssId(this.context);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["ASSOCIATIONRANKBYID"]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions(["getall", "delete", "edit", "add"]),
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    clearAdded: item => {
      for (var propName in item) {
        item[propName] = undefined;
      }
    },
    getbyAssId: context => {
      context.$store
        .dispatch("TA/getAssociationRanksByAssId", {
          id: context.taxiassociationid,
          router: context.$router
        })
        .then((data) => {
          context.ASSOCIATIONRANKS = data;
          console.log("These are the association ranks: ",data) 
          
        }).catch((error)=>{
          console.log("these are the getter errors:",error);
        })
        .finally((error)=>{
           $('#data-table-default').DataTable({
			responsive: true
		});
        })
    },
    getbyId: (context,id) => {
      context.$store
        .dispatch("TA/getAssociationRanksById", {
          id,
          router: context.$router
        })
        .then((data) => {
          console.log("rank by id: ",data);
          context.rankid = data[0].id;
          context.UPDATERANK.name = data[0].name;
          context.UPDATERANK.description = data[0].description;
        });
    },

    getRank:(context,id) =>{
      console.log("rank ID:",id);
      context.getbyId(context,id);
    },
    addRank: (context,item) => {
        context.$Notify
        .YesNo(false, { context,item })
        .then(value => {
          if (value) {
            value.context.$wait.start("add-");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("TA/addAssociationRank", {
                _dataObj:{
                  item,
                  router: context.$router
                } 
              })
              .then(id => {
                value.context.$wait.end("add-");
                value.context.$Notify.Success("Successfully Added!", "");
                context.getbyAssId(context);
                $('#AddModal').modal('hide');
              })
              .catch(error => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },

    updateRank: (context, id, item) => {
        context.$Notify
        .YesNo(false, { context, id, item })
        .then(value => {

          
          if (value) {
            value.context.$wait.start("update-");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("TA/updateAssociationRank", {
                  router: context.$router,
                  id,
                  updateModel: item
              })
              .then(id => {
                value.context.$wait.end("update-");
                value.context.$Notify.Success("Successfully Updated!", "");
                context.getbyAssId(context);
                $('#EditModal').modal('hide');
              })
              .catch(error => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },


    deletedata: (context, item, deletedby) => {
      context.$Notify
        .YesNo(false, { context, id: item.id, deletedby })
        .then(value => {
          if (value) {
            value.context.$wait.start("delete-" + value.id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("district/markAsdelete", {
                _dataObj: value
              })
              .then(id => {
                value.context.$wait.end("delete-" + id);
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch(error => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    }
  },
  mounted: function() {

    App.init();

  
    
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

