<template>
  <div class="home; page-content-full-height page-sidebar-fixed page-header-fixed">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Emails'"
      :ActiveMenu="'Inbox'"
    />
    <!-- end #sidebar -->
    <!-- begin page-header -->
    <headerpagebreadcrumb
      pageheaderTitle="Inbox"
      pageheaderdescription
      breadcrumbitem1="Home"
      breadcrumbitem2="Emails"
      breadcrumbitem3="Inbox"
    />
    <!-- end page-header -->

    <!-- begin #content -->
    <div id="content" class="content content-full-width inbox">
      <!-- begin vertical-box -->
      <div class="vertical-box with-grid">
        <!-- begin vertical-box-column -->
        <div class="vertical-box-column width-200 bg-silver hidden-xs">
          <!-- begin vertical-box -->
          <div class="vertical-box">
            <!-- begin wrapper -->
            <div class="wrapper bg-silver text-center">
              <router-link to="/Home/Compose" class="btn btn-inverse p-l-40 p-r-40 btn-sm">Compose</router-link>
            </div>
            <!-- end wrapper -->
            <!-- begin vertical-box-row -->
            <div class="vertical-box-row">
              <!-- begin vertical-box-cell -->
              <div class="vertical-box-cell">
                <!-- begin vertical-box-inner-cell -->
                <div class="vertical-box-inner-cell">
                  <!-- begin scrollbar -->
                  <div data-scrollbar="true" data-height="100%">
                    <!-- begin wrapper -->
                    <div class="wrapper p-0">
                      <div class="nav-title">
                        <b>FOLDERS</b>
                      </div>
                      <ul class="nav nav-inbox">
                        <li class="active">
                          <router-link :to="{ name: 'inbox', params: {id: USERID } }">
                            <i class="fa fa-inbox fa-fw m-r-5"></i> Inbox
                            <span class="badge pull-right">{{GETTOTALUNREAD}}</span>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/sent">
                            <i class="fa fa-envelope fa-fw m-r-5"></i> Sent
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/trash">
                            <i class="fa fa-trash fa-fw m-r-5"></i> Trash
                          </router-link>
                        </li>
                      </ul>
                    </div>
                    <!-- end wrapper -->
                  </div>
                  <!-- end scrollbar -->
                </div>
                <!-- end vertical-box-inner-cell -->
              </div>
              <!-- end vertical-box-cell -->
            </div>
            <!-- end vertical-box-row -->
          </div>
          <!-- end vertical-box -->
        </div>
        <!-- end vertical-box-column -->
        <!-- begin vertical-box-column -->
        <div class="vertical-box-column bg-white">
          <!-- begin vertical-box -->
          <div class="vertical-box">
            <!-- begin wrapper -->
            <div class="wrapper bg-silver-lighter clearfix">
              <div class="pull-left">
                <div class="btn-group m-r-5">
                  <router-link
                    :to="{ name: 'reply', params: {id: GETINBOXBYID._id}}"
                    class="btn btn-white btn-sm"
                  >
                    <i class="fa fa-reply f-s-14 m-r-3 m-r-xs-0 t-plus-1"></i>
                    <span class="hidden-xs">Reply</span>
                  </router-link>
                </div>
                <div class="btn-group m-r-5">
                  <a
                    href="javascript:;"
                    class="btn btn-white btn-sm"
                    @click="deleteNow(context, GETINBOXBYID._id, USERID)"
                  >
                    <i class="fa fa-trash f-s-14 m-r-3 m-r-xs-0 t-plus-1"></i>
                    <span class="hidden-xs">Delete</span>
                  </a>
                </div>
              </div>
              <div class="pull-right">
                <div class="btn-group">
                  <a href="email_inbox.html" class="btn btn-white btn-sm disabled">
                    <i class="fa fa-arrow-up f-s-14 t-plus-1"></i>
                  </a>
                  <a href="email_inbox.html" class="btn btn-white btn-sm">
                    <i class="fa fa-arrow-down f-s-14 t-plus-1"></i>
                  </a>
                </div>
                <div class="btn-group m-l-5">
                  <a href="email_inbox.html" class="btn btn-white btn-sm">
                    <i class="fa fa-times f-s-14 t-plus-1"></i>
                  </a>
                </div>
              </div>
            </div>
            <!-- end wrapper -->
            <!-- begin vertical-box-row -->
            <div class="vertical-box-row">
              <!-- begin vertical-box-cell -->
              <div class="vertical-box-cell">
                <!-- begin vertical-box-inner-cell -->
                <div class="vertical-box-inner-cell">
                  <!-- begin scrollbar -->
                  <div data-scrollbar="true" data-height="100%">
                    <!-- begin wrapper -->
                    <div class="wrapper">
                      <h3 class="m-t-0 m-b-15 f-w-500">{{GETINBOXBYID._subject}}</h3>
                      <ul class="media-list underline m-b-15 p-b-15">
                        <li class="media media-sm clearfix">
                          <a href="javascript:;" class="pull-left">
                            <img
                              class="media-object rounded-corner"
                              alt
                              src="/assets/img/user/user-12.jpg"
                            />
                          </a>

                          <div v-if="recipientsArray" class="media-body">
                            <div
                              class="email-from text-inverse f-s-14 f-w-600 m-b-3"
                              style="display:inline-block"
                            >
                              <p>{{context.GETINBOXBYID._sentby_name}}</p>
                            </div>
                            <div class="m-b-3">
                              <i class="fa fa-clock fa-fw"></i>
                              {{GETINBOXBYID._date_sent | formatToLongDateTime}}
                            </div>
                            <br />
                            <div
                              v-for="item in RECIPIENTS"
                              :key="item._id"
                              style="display:inline-block"
                              class="email-to"
                            >
                              <p>{{` ${item.username }, ` }}</p>
                            </div>
                          </div>

                          <div v-if="!recipientsArray" class="media-body">
                            <div
                              class="email-from text-inverse f-s-14 f-w-600 m-b-3"
                              style="display:inline-block"
                            >
                              <p>{{context.GETINBOXBYID._sentby_name}}</p>
                            </div>
                            <div class="m-b-3">
                              <i class="fa fa-clock fa-fw"></i>
                              {{GETINBOXBYID._date_sent | formatToLongDateTime}}
                            </div>
                            <div class="email-to">
                              To:
                              <p>{{GETINBOXBYID._received_by_name}}</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <ul class="attached-document clearfix">
                        <li
                          class="fa-file"
                          v-if="GETINBOXBYID._fk_taxi_operator_id"
                          @click="showOperatorProfile(context,GETINBOXBYID._fk_taxi_operator_id)"
                        >
                          <div class="document-file">
                            <a href="javascript:;">
                              <i class="fa fa-user"></i>
                            </a>
                          </div>
                          <div class="document-name">
                            <a href="javascript:;">{{GETINBOXBYID._fk_taxi_operator_names}}</a>
                          </div>
                        </li>
                        <li
                          class="fa-file"
                          v-if="GETINBOXBYID._fk_taxi_vehicle_id"
                          @click="showTaxiProfile(context,GETINBOXBYID._fk_taxi_vehicle_id)"
                        >
                          <div class="document-file">
                            <a href="javascript:;">
                              <i class="fa fa-truck"></i>
                            </a>
                          </div>
                          <div class="document-name">
                            <a href="javascript:;">{{GETINBOXBYID._fk_taxi_vehicle_names}}</a>
                          </div>
                        </li>

                        <li
                          class="fa-file"
                          v-if="GETINBOXBYID._fk_taxi_driver_id"
                          @click="showDriverProfile(context,GETINBOXBYID._fk_taxi_driver_id)"
                        >
                          <div class="document-file">
                            <a style="cursor: pointer;" href="javascript:;">
                              <i class="fa fa-male"></i>
                            </a>
                          </div>
                          <div class="document-name">
                            <a
                              href="javascript:;"
                              style="cursor: pointer;"
                            >{{GETINBOXBYID._fk_taxi_driver_names}}</a>
                          </div>
                        </li>
                        <li
                          class="fa-file"
                          v-for="item in GETINBOXBYID._attachments"
                          :key="item.fileid"
                          :value="item.fileid"
                          @click="downloadNow(context,GETINBOXBYID._id,item.fileid)"
                        >
                          <div class="document-file">
                            <a href="javascript:;">
                              <i class="fa fa-file-pdf"></i>
                            </a>
                          </div>
                          <div class="document-name">
                            <a href="javascript:;">{{item.filename}}</a>
                          </div>
                        </li>
                      </ul>
                      <p class="f-s-12 text-inverse p-t-10">
                        <span v-html="GETINBOXBYID._message_content"></span>
                      </p>
                    </div>
                    <!-- end wrapper -->
                  </div>
                  <!-- end scrollbar -->
                </div>
                <!-- end vertical-box-inner-cell -->
              </div>
              <!-- end vertical-box-cell -->
            </div>
            <!-- end vertical-box-row -->
            <!-- begin wrapper -->
            <div class="wrapper bg-silver-lighter text-right clearfix">
              <div class="btn-group">
                <a href="email_inbox.html" class="btn btn-white btn-sm disabled">
                  <i class="fa fa-arrow-up"></i>
                </a>
                <a href="email_inbox.html" class="btn btn-white btn-sm">
                  <i class="fa fa-arrow-down"></i>
                </a>
              </div>
              <div class="btn-group m-l-5">
                <a href="email_inbox.html" class="btn btn-white btn-sm">
                  <i class="fa fa-times"></i>
                </a>
              </div>
            </div>
            <!-- end wrapper -->
          </div>
          <!-- end vertical-box -->
        </div>
        <!-- end vertical-box-column -->
      </div>
      <!-- end vertical-box -->
    </div>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("messageinbox");
// value.context.$emit("init");

export default {
  name: "emaildetails",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      GETDRIVERSBYASSID: this.$store.getters["TaxiDrivers/GETDRIVERSBYASSID"],
      GETALLOPERATORS: this.$store.getters["TO/GETADDOPERATOR"],
      GETALLTAXIS: this.$store.getters["TV/GETVEHICLE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      context: this,
      skip: 0,
      len: 10000,
      id: this.$route.params.id,
      RECIPIENTS: {},
      GETATTACHMENTS: {},
      recipientsArray: false,
      GETINBOXBYID: []
    };
  },
  beforeMount: function() {
    this.getbyid(this.context, this.id, this.USERID);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    CapturePhotosControl
  },

  computed: {
    ...mapGetters(["GETTOTALUNREAD"]),
    // LoggedInUserFullName: function() {
    //   return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    // }
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  watch: {},
  methods: {
    ...mapActions([
      "getall",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "getinboxById",
      "downloadfile",
      "delete",
      "markAsRead"
    ]),
    deleteNow(context, messageid, userid) {
      context.messageid = parseInt(context.messageid);
      context.$Notify
        .YesNo(false, { context, id: [messageid], deletedby: userid })
        .then(value => {
          if (value) {
            //value.context.$wait.start("add-" + value.id);
            value.router = value.context.$router;
            value.context
              .delete({ _dataObj: value })
              .then(id => {
                //value.context.$wait.end("add-" + id);
                value.context.$Notify.Success("Deleted Successfully!", "");
                value.context.$router.go(-1);
              })
              .catch(error => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    showOperatorProfile(context, _id) {
      if (
        context.GETINBOXBYID._fk_taxi_association_id ===
        context.taxiassociationid
      ) {
        //Show Internal Profile
        context.$router.push({
          name: "taxioperatorprofile",
          params: { id: _id }
        });
        //router.push({ path: 'register', query: { plan: 'private' } })
        //router.push({ name: 'user', params: { userId: '123' } })
      } else {
        //Show External Profile
        context.$router.push({
          name: "taxioperatorprofile",
          params: { id: _id }
        });
      }
    },
    showTaxiProfile(context, _id) {
      if (
        context.GETINBOXBYID._fk_taxi_association_id ===
        context.taxiassociationid
      ) {
        //Show Internal Profile
        context.$router.push({
          name: "taxivehicleprofile",
          params: { id: _id }
        });
      } else {
        //Show External Profile
        context.$router.push({
          name: "taxivehicleprofile",
          params: { id: _id }
        });
      }
    },
    showDriverProfile(context, _id) {
      if (
        context.GETINBOXBYID._fk_taxi_association_id ===
        context.taxiassociationid
      ) {
        //Show Internal Profile
        context.$router.push({
          name: "taxidriverprofile",
          params: { id: _id }
        });
      } else {
        //Show External Profile
        context.$router.push({
          name: "taxidriverprofile",
          params: { id: _id }
        });
      }
    },
    downloadNow(context, messageid, fileid) {
      context
        .downloadfile({
          messageid,
          fileid,
          router: context.$router
        })
        .then(_rb => {
          context.downloadItem(_rb.filename, _rb.data, _rb.mimetype);
        });
    },

    downloadItem(filename, data, type) {
      const linkSource = `data:application/pdf;base64,${data}`;
      const downloadLink = document.createElement("a");
      const fileName = filename;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },

    getRecepients: context => {
      if (context.GETINBOXBYID._recipients != null) {
        context.RECIPIENTS = context.GETINBOXBYID._recipients;
        context.recipientsArray = true;
      } else {
        context.recipientsArray = false;
      }
    },

    getAttachments: context => {
      if (
        context.GETINBOXBYID._attachments != null &&
        context.GETINBOXBYID._attachments != undefined
      ) {
        context.GETATTACHMENTS = context.GETINBOXBYID._attachments;
      }
    },
    messageRead: (context,id) => {
      
      context
        .markAsRead({
          router: context.$router,
          addModel: { id }
        })
        .then(data => {
          console.log("message read: ", data);
        })
        .catch(error => {
          console.log("message read errors: ", error);
        });
    },

    getbyid: (context, id, userid) => {
      context
        .getinboxById({
          _dataObj: {
            id,
            userid,
            router: context.$router
          }
        })
        .then(data => {
          console.log("this is the email: ", data);
          context.GETINBOXBYID = data;

          context.getRecepients(context);
        });
    }
  },
  mounted: function() {
    //  InboxV2.init();

    App.init();
    
    
    this.getAttachments(this.context);
    this.messageRead(this.context,this.id);
    this.getbyid(this.context, this.id, this.USERID);
  }
};

$("[data-click=email-select-all]");
</script>

	

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>