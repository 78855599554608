/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import format from 'string-format';
import conf from '../../configurations/config';
import config from '../../configurations/configbase';
import tpayconfig from '../../configurations/configbasetpay';


export default {
  namespaced: true,
  state: {
    taxiassociations_billings: [],
    taxiassociations: [],
    taxiassociation: [],
    taxiassociationsettings: [],
    taxiassociationranks: [],
    taxiassociationrank: [],
    taxiassociationranksummary: [],
    taxiassociationspotfines: [],
    addtaxiassociations: {
      _district_id: 0,
      _fk_taxi_affiliation_id: 0,
      _name: '',
      _abbriviated_name: '',
      _code: '',
      _telephone: '',
      _mobilenumber: '',
      _contact_person_name: '',
      _contact_person_number: '',
      _lon: '',
      _lat: '',
      _physical_address: '',
      _physical_address2: '',
      _physical_suburb_id: '',
      _postal_address: '',
      _postal_address2: '',
      _postal_suburb_id: '',
      _maximum_number_vehicles: '',
      _maximum_number_members: '',
      _create_driver_account: false,
      _create_operator_account: false,
      _create_vehicle_account: false,
      _addedby: 0,
      _dropzoneid: '',
      _billtypeid:0,
      _billtypeamount:0,
      _billtypetransactionfee:0,
      _addonbillingservices:[],
      _taxiassociation_routes:{},
      _taxiassociation_billingitems:{},
      _ras_registration_number: '',
    },
    addon_billing:[],
    associationroutes:[],
    associationroute: [],
    account_details:[],
    association_moratorium:[],
    

  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    TAXIASSOCIATIONBILLING: state => state.taxiassociations_billings,
    TAXIASSOCIATIONBANKS:  state => state.account_details,
    ADDTAXIASSOCIATIONS: state => state.addtaxiassociations,
    TAXIASSOCIATIONS: state => state.taxiassociations,
    ADDONBILLING:state => state.addon_billing,
    TAXIASSOCIATIONSROUTES: state => state.associationroutes,
    TAXIASSOCIATIONSROUTEBYID: state => state.associationroute,
    TAXIASSOCIATIONBANKS: state => state.account_details,
    TAXIASSOCIATIONMORATORIUM: state =>state.association_moratorium,
    ASSOCIATIONBYID: state => state.taxiassociation,
    ASSOCIATIONSETTINGS: state => state.taxiassociationsettings,
    ASSOCIATIONRANKS: state => state.taxiassociationranks,
    ASSOCIATIONRANKBYID: state => state.taxiassociationrank,
    RANKSUMMARY: state => state.taxiassociationranksummary,
    
    TAXIASSOCIATIONSPOTFINES: state => state.taxiassociationspotfines,
    GETTAXIAFFILIATIONS: (state, getters, rootState, rootGetters) => {
      return rootGetters['taxiAffiliation/GETALLACTIVE']
      },
    TAXIASSOCIATIONSBYAFFILLIATIONIID: state => (id) => {
      try {
        return state.taxiassociations.filter(t => t._taxi_affiliation_id == id);
      } catch (error) {
      }
    },
    TAXIASSOCIATIONBYID: state => (id) => {
      try {
        return state.taxiassociations.filter(t => t._taxi_associations_id == id);
      } catch (error) {
      }
    },
 
  },
  mutations: {
    BILLINGLOADED: (state, _data) => {
      if (_data !== undefined) {

        state.taxiassociations_billings = _data;
      }
    },
    UPDATEBILLINGS: (state, _data) => {
      if (_data !== undefined) {

        Object.assign(state.taxiassociations_billings.filter(item => item._billing_items_id === _data._billing_items_id)[0], _data);
      }
    },
    UPDATEMORATORIUM: (state, _data) => {
      if (_data !== undefined) {

        Object.assign(state.association_moratorium.filter(item => item._taxi_association_id === _data.id)[0], _data);
      }
    },
    // UPDATED: (state, id) => {
    //   if (id !== undefined) {
    //     state.taxiassociations_billings = state.taxiassociations_billings.filter(item => item._billing_items_id === Number(id))[0];
    //   }
    // },
    ADDONLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.addon_billing = _data;
      }
    },
    ROUTESLOADED: (state, _data) => {
      if (_data !== undefined) {
   
        state.associationroutes = _data;
      }
    },

    ROUTELOADED: (state, _data) => {
      if (_data !== undefined) {
   
        state.associationroute = _data;
      }
    },
    
   BANKINGLOADED: (state, _data) => {
      if (_data !== undefined) {
       
        state.account_details = _data;
      }
    },
    TAXIASSLOADED: (state, _taxiassociations) => {
      if (_taxiassociations !== undefined) {
        state.taxiassociations = _taxiassociations;
      }
    },

    ASSOCIATIONBYID: (state, data) => {
      if (data !== undefined) {
        state.taxiassociation = data;
      }
    },

    ASSOCIATIONSETTINGSBYID: (state, data) => {
      if (data !== undefined) {
        state.taxiassociationsettings = data;
      }
    },

    ASSOCIATIONRANKSLOADED: (state, data) => {
      if (data !== undefined) {
        state.taxiassociationranks = data;
      }
    },

    ASSOCIATIONRANKBYIDLOADED: (state, data) => {
      if (data !== undefined) {
        state.taxiassociationrank = data;
      }
    },


    RANKSUMMARYLOADED: (state, data) => {
      if (data !== undefined) {
        state.taxiassociationranksummary = data;
      }
    },

    TAXIASSAROUTESADDED: (state, _data) => {
      state.associationroutes.push(_data);
    },
    TAXIASSADDED: (state, _data) => {
      state.taxiassociations.push(_data);
    },
    TAXIASSACCOUNTADDED: (state, _data) => {
      state.account_details.push(_data);
    },
    TAXIASSOCIATIONSPOTFINES: (state, _data) => {
      state.taxiassociationspotfines.push(_data);
    },

    

  TAXIASSADDONLOADED: (state, _id) => {   
    console.log('this is the id: ',_id);
    console.log('this is the state: ', state.addon_billing);
    state.addon_billing = state.addon_billing.filter(item => item._addon_service_billing_id === _id);
  },
  },
  actions: {
    /* Async */
    AddOrganisation({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      item
    }) {
      return new Promise((resolve, reject) => {


        let requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: tpayconfig.COMMISSIONTYPES_URL,
        };

        this._vm.$TPAY_API.add(requestheader,item
          ).then((parsedResultOP) => {
          if (parsedResultOP.Status) {
            resolve(parsedResultOP.Data);
          }
        }).catch((error) => {
          reject(error);
        });
      })
    },
    /* Async */
    getcommissiontypes({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router
    }) {
      return new Promise((resolve, reject) => {


        let requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: tpayconfig.COMMISSIONTYPES_URL,
        };

        this._vm.$TPAY_API.getCustomURL(requestheader
          ).then((parsedResultOP) => {
          if (parsedResultOP.Status) {
            resolve(parsedResultOP.Data);
          }
        }).catch((error) => {
          reject(error);
        });
      })
    },
    deleteTaxiRoute({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { deletedby, id, router }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TAXIASSOCIATIONROUTES_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id,
          {
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('DELETE', id);
            resolve(id);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    add({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          AddModel, router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TAXIASSOCIATION_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(AddModel);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    addBankAcc({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          item, router,
        } = _dataObj;
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_BANK_ACC_ADD,
        };
        const AddModel = {
          taxi_association_id: item._taxi_association_id,
          bank_account_name_id: item._bank_account_name_id,
          account_type_id: item._account_type_id ,
          billing_date_type_id: item._billing_date_type_id,
          bank_account_number: item._bank_account_number,
          added_by : item._added_by

        };
        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              commit('TAXIASSACCOUNTADDED', item);
              resolve();
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },

    
    
    addAssociationRoute({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel, router }) {
      return new Promise((resolve, reject) => {
      
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_ROUTES_ADD_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              //commit('TAXIASSAROUTESADDED', item);
              resolve(parsedResult.data);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },
    changeDefault({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          item, router,
        } = _dataObj;
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_CHANGEDEFAULT_BANK,
        };
        const AddModel = {
          taxi_association_id: item._taxi_association_id,
          bank_account_name_id: item._id,
          updated_by: item._updated_by,
        };
        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              commit('TAXIASSACCOUNTADDED', item);
              resolve();
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },
    updateBillingItems({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          item, router, updated_by,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_BILLING_ITEMS_UPDATE,
        };

        const updateModel = {
        
          amount:item._billing_items_amount,
          billing_end_date:item._billing_end_date,
          penalty_fee_amount:item._penaltyfee_billing_items_amount,
          is_active:item._is_active,
          updated_by
        };

        this._vm.$TAMSFIN_API.update(requestheader,
          item._billing_items_id,
          updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            commit('UPDATEBILLINGS', item);
            resolve();
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    updateMoratorium({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          item, router, updated_by,
        } = _dataObj;
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_MEMBER_MORATORIUM_UPDATE,
        };

        const updateModel = {
          maximum_number_members: item._maximum_number_members,
          maximum_number_vehicles: item._maximum_number_vehicles,      
          updated_by
        };

        this._vm.$TAMS_API.update(requestheader,
          item._taxi_association_id,
          updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            commit('UPDATEMORATORIUM', item);
            resolve();
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    updateAssociationRank({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, id, updateModel  }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_RANKUPDATE_URL,
        };


        this._vm.$TAMS_API.update(requestheader,id,
          updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('UPDATEMORATORIUM', item);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    updateAssociationRoute({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, id, updateModel  }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_ROUTEUPDATE_URL,
        };


        this._vm.$TAMS_API.update(requestheader,id,
          updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('UPDATEMORATORIUM', item);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },


    updateAssociation({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { id, updateModel, router }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_UPDATE_URL,
        }

        this._vm.$TAMS_API.update(requestheader,id,
          updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('UPDATEMORATORIUM', item);
            resolve(parsedResult);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    
    // editBilling({
    //   commit,
    //   state,
    //   dispatch,
    //   rootGetters
    // }, {
    //   id,
    //   _updatemodel,
    //   router,
    // }) {
    //   return new Promise((resolve, reject) => {
        
    //     const requestheader = {
    //       router,
    //       token: rootGetters['auth/TOKEN'],
    //       apiName: config.TA_BILLINGUPDATE_URL,
    //     };

    //     this._vm.$TAMS_API.update(requestheader, id, _updatemodel).then((parsedResult) => {
    //       if (parsedResult.status) {
    //         commit('UPDATED', parsedResult.data)
    //         resolve( parsedResult.data)
    //         console.log('updated succesfully')
    //       } else {
    //         // commit('EXTERNALUPDATED', parsedResult.data);
    //       }
    //     }).catch((error) => {
    //       // commit('LOGINFAILED', error.data);
    //       reject();
    //     });
    //   });
    // },

    getById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id, router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_URL,
        };

        

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('ASSOCIATIONBYID', parsedResult.data);
            resolve(parsedResult.data[0]);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getAssociationId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id, router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_GETSETTINGS_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            console.log("association settings: ",parsedResult.data);
            // commit('ASSOCIATIONSETTINGSBYID', parsedResult.data);
            resolve(parsedResult.data[0]);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log("association errors: ",parsedResult.data);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getAssociationRanksByAssId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id, router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_RANKS_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            console.log("association ranks Loaded: ",parsedResult.data);
            //commit('ASSOCIATIONRANKSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log("rank errors: ",parsedResult.data);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getAssociationRanksById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id, router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_RANKBYID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            console.log("association rank by id Loaded: ",parsedResult.data);
            commit('ASSOCIATIONRANKBYIDLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log("rank errors: ",parsedResult.data);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getRankSummaryById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id, router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_RANK_SUMMARY_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            console.log("association ranks Loaded: ",parsedResult.data);
            // commit('RANKSUMMARYLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log("rank errors: ",parsedResult.data);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getLetterOfGoodStandingById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id, router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_GETLETTEROFGOODSTANDINGBYASSID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            console.log("association letters of goodstanding Loaded: ",parsedResult.data);
            // commit('RANKSUMMARYLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log("rank errors: ",parsedResult.data);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getOperatorLeadsByAssId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id, router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_GETOPERATORSALESLEADS_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            console.log("association operator sales leads: ",parsedResult.data);
            // commit('RANKSUMMARYLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log("rank errors: ",parsedResult.data);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getFraudulentLetterByNumber({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id, router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_GETFRAUDULENTLETTERSBYNUMBER_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {

            resolve(parsedResult.data);

          }
        }).catch((error) => {
          console.log("rank errors: ",error);
          reject(error);
        });
      });
    },

    
    addMaxMembers({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          item, router,
        } = _dataObj;
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_MAX_MEMBERS_URL,
        };

        const AddModel = {
          taxi_association_id: item.taxi_assocation_id ,
          addon_service_type_id: item._id ,
          added_by: item._added_by,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              commit('TAXIASSADDED', item);
              resolve();
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },
    addMaxVehicles({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          item, router,
        } = _dataObj;
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_MAX_TAXIS_URL,
        };

        const AddModel = {
          taxi_association_id: item.taxi_assocation_id ,
          addon_service_type_id: item._id ,
          added_by: item._added_by,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              commit('TAXIASSADDED', item);
              resolve();
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },
    addAddOns({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          item, router,
        } = _dataObj;
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_ADD_ONS_URL,
        };

        const AddModel = {
          taxi_association_id: item._taxi_association_id,
          addon_service_type_id: item._id,
          added_by: item._added_by,
        };
        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              console.log("add on added: ",parsedResult.data);
              console.log("add on item data: ",item);
              // commit('TAXIASSADDONLOADED', parsedResult.data[0].fn_taxi_association_addonservice_subscription_add);
              resolve(parsedResult.data);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },

    removeAddOns({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel, router }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_ADD_ON_REMOVE_URL,
        };
        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              // commit('TAXIASSADDONLOADED', parsedResult.data[0].fn_taxi_association_addonservice_subscription_add);
              resolve(parsedResult.data);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },

    

    addAssociationRank({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          item, router,
        } = _dataObj;
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_RANKADD_URL,
        };

        const AddModel = {
          taxiassociationid: item.taxiassociationid,
          name: item.name,
          description: item.description,
          addedby: item.addedby,
        };
        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              // commit('ASSOCIATIONRANKSLOADED',parsedResult.data );
              resolve(parsedResult.data);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },

    addRouteByNationalCode({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel,router }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_ADDROUTEBYNATIONALCODE_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              // commit('ASSOCIATIONRANKSLOADED',parsedResult.data );
              resolve(parsedResult.data);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },

    addRouteByNationalCode({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel,router }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_ADDROUTEBYNATIONALCODE_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              // commit('ASSOCIATIONRANKSLOADED',parsedResult.data );
              resolve(parsedResult.data);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },

    addRouteByRankId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel,router }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_ADDROUTEBYRANKID_URL,
        };

        

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              // commit('ASSOCIATIONRANKSLOADED',parsedResult.data );
              resolve(parsedResult.data);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },

    addLetterOfGoodStanding({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel,router }) {
      return new Promise((resolve, reject) => {
        // const requestheader = {
        //   router,
        //   token: rootGetters['auth/TOKEN'],
        //   apiName: config.TA_ADDLETTEROFGOODSTANDING_URL,
        // };
        
        //'https://tams-api.herokuapp.com/api/v1/taxiassociations/addLetterofGoodStanding/'

        fetch(config.BASE_HEROKU_URL() + '/taxiassociations/addLetterofGoodStanding/', {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
            'x-access-token': rootGetters['auth/TOKEN'],
          }),
          body: JSON.stringify(AddModel),
        }).then(response => response.json()).then((parsedResult) => {
            if (parsedResult.status) { resolve(parsedResult.data); } else { reject(parsedResult.data); }
        }).catch((error) => {
          reject(error);
        })
          .finally(() => {

          });


        // this._vm.$TAMS_API.add(requestheader,
        //   AddModel).then((parsedResult) => {
        //     if (parsedResult.status) {
        //       // commit('ASSOCIATIONRANKSLOADED',parsedResult.data );
        //       resolve(parsedResult.data);
        //       // navigateTo(router, parsed_result.data[0]);
        //     }
        //   }).catch((error) => {
        //     // commit('LOGINFAILED', error.data);
        //     reject(error);
        //   });

      });
    },

    generateLetterOfGoodStandingPdf({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel,router }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_CREATELETTEROFGOODSTANDINGPDF_URL,
        };
        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              // commit('ASSOCIATIONRANKSLOADED',parsedResult.data );
              resolve(parsedResult.data);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },

    reportletterfraud({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel,router }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_REPORTFRAUDULENTLETTER_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              // commit('ASSOCIATIONRANKSLOADED',parsedResult.data );
              resolve(parsedResult.data);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },

    verifyLetterOFGoodStanding({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel,router }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_VERIFYLETTEROFGOODSTANDING_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              // commit('ASSOCIATIONRANKSLOADED',parsedResult.data );
              resolve(parsedResult.data);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },


    getPathByVerificationCode({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel,router }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_GETLETTERBYVERIFICATIONCODE_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              // commit('ASSOCIATIONRANKSLOADED',parsedResult.data );
              resolve(parsedResult.data);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },


    selected({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _id }) {

      const _taxiass = state.taxiassociations.filter(t => t._taxi_associations_id == _id)[0];

      /* Auth.js Mutations */
      commit('auth/UPDATETAXIASSOCIATION', {
        
        id: _taxiass._taxi_associations_id,
        name: _taxiass._name,
        abbr: _taxiass._abbriviated_name,
        code: _taxiass._code,
        taxiass: _taxiass,
      }, { root: true });
    },
    
  
    
    getByPaging({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _waitObj }) {
      const { _wait } = _waitObj;
      const _waitname = _waitObj.name;
      const router = _waitObj._router;

      if (_waitname) { _wait.start(_waitname); }
      this._vm.$TAMS_API.getByPaging(router, rootGetters['auth/TOKEN'],
        config.TA_URL,
        {
          isactive: true,
          skip: 0,
          length: 1,
        }).then((parsedResult) => {
        if (parsedResult.status) {
          commit('TAXIASSLOADED', parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
        }

        if (_waitname) { _wait.end(_waitname); }
      }).catch((error) => {
        
        commit('LOGINFAILED', error.data);
        if (_waitname) { _wait.end(_waitname); }
      });
    },
    getByRegionId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, id }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_REGION_URL,
        };

        
    
        this._vm.$TAMS_API.getCustomURL(requestheader,
          {id}
        ).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            //commit('TAXIASSLOADED', parsedResultOP.data);
            //commit('LOADEDDASHBOARDFINSTATS', parsedResultOP.data[0]);
            resolve(parsedResultOP.data);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
        });
      });
    },
    getByAssId2({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
     return new Promise((resolve, reject) => {

      

      this._vm.$TAMS_API.getById(
        {router, 
          token:rootGetters['auth/TOKEN'], 
          apiName: config.TA_URL
        },
          id
        ).then((parsedResult) => {
        if (parsedResult.status) {
        
          resolve(parsedResult.data[0]);

        }
        
      }).catch((error) => {
        reject(error);
      });

    });
    },
    getByAssId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _waitObj }) {
      const { _wait } = _waitObj;
      const _waitname = _waitObj.name;
      const router = _waitObj._router;

      if (_waitname) { _wait.start(_waitname); }
      this._vm.$TAMS_API.getByTaxiAssId(router, rootGetters['auth/TOKEN'],
        config.TA_URL,
        {
          isactive: true,
          skip: 0,
          length: 1000,
        }).then((parsedResult) => {
        if (parsedResult.status) {
          commit('TAXIASSLOADED', parsedResult.data[0]);
          // navigateTo(router, parsed_result.data[0]);
        }

        if (_waitname) { _wait.end(_waitname); }
      }).catch((error) => {
        commit('LOGINFAILED', error.data);
        if (_waitname) { _wait.end(_waitname); }
      });

    },
    getAll({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _waitObj }) {
      const { router } = _waitObj;

      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TA_URL,
      };
      this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
        
        if (parsedResult.status) {
          
          if(getters["TAXIASSOCIATIONS"] != null || getters["TAXIASSOCIATIONS"].length === 0){
            commit('TAXIASSLOADED', parsedResult.data);
          }
          
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
       
        commit('LOGINFAILED', error.data);
      });
    },
    
    getLOGOURL({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { id }) {
      return new Promise((resolve, reject) => {


        resolve(format(conf.URLBYID(config.TA_LOGO_URL),{ id }));

    });
   
  },

    getBankingDetails({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, {  id, router }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TA_BANKING_URL,
      };
     this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
      
      if (parsedResult.status) {
        console.log("these are the banking details: ",parsedResult.data )
        commit('BANKINGLOADED', parsedResult.data);
        resolve(parsedResult.data);
        // navigateTo(router, parsed_result.data[0]);
      }
    }).catch((error) => {
     
      reject(error);
    });
    });
  },

  getSpotFinesById({
    state, rootState, rootGetters, commit, dispatch, getters,
  }, {  id, router }) {
    return new Promise((resolve, reject) => {
    const requestheader = {
      router,
      token: rootGetters['auth/TOKEN'],
      apiName: config.TA_SPOTFINES_URL,
    };
   this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
    
    if (parsedResult.status) {
     
      commit('TAXIASSOCIATIONSPOTFINES', parsedResult.data);
      resolve(parsedResult.data);
      // navigateTo(router, parsed_result.data[0]);
    }
  }).catch((error) => {
    
    reject(error);
  });
  });
},

    // getById({
    //   state, rootState, rootGetters, commit, dispatch, getters,
    // }, { id, router }) {
    //   return new Promise((resolve, reject) => {
    //   const requestheader = {
    //     router,
    //     token: rootGetters['auth/TOKEN'],
    //     apiName: config.TA_URL,
    //   };
    //   this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
    //     console.log(parsedResult);
    //     if (parsedResult.status) {
    //       resolve(parsedResult.data[0]);
    //       commit('TAXIASSLOADED', parsedResult.data);
    //       // navigateTo(router, parsed_result.data[0]);
    //     }
    //   }).catch((error) => {
    //     console.log(error);
    //     reject(error);
    //   });
    //   });
    // },
    getByBillingId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { id, router }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TA_BILLING_URL,
      };
      this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
        
        if (parsedResult.status) {
          resolve(parsedResult.data[0]);
          //commit('TAXIASSLOADED', parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
        } else {
          reject(error);
        }
      }).catch((error) => {
        
        reject(error);
      });
      });
    },

    editTaxiAssRoutes({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,AddModel }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TA_ROUTES_EDIT_URL,
      };

      this._vm.$TAMS_API.add(requestheader, AddModel).then((parsedResultOP) => {
        
        if (parsedResultOP.status) {
          // commit('ROUTESLOADED', parsedResultOP.data);
          //commit('LOADED', parsedResultOP.data);
          resolve(parsedResultOP.data);
          
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
    });
    },

    getTaxiAssRoutes({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TA_ROUTESBYASSID_URL,
      };
      this._vm.$TAMS_API.getById(requestheader, id).then((parsedResultOP) => {
        
        if (parsedResultOP.status) {
          // commit('ROUTESLOADED', parsedResultOP.data);
          //commit('LOADED', parsedResultOP.data);
          resolve(parsedResultOP.data);
          
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
    });
    },

    getRouteById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TA_ROUTESBYID_URL,
      };
      this._vm.$TAMS_API.getById(requestheader, id).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('ROUTELOADED', parsedResultOP.data);
          //commit('LOADED', parsedResultOP.data);
          resolve(parsedResultOP.data);
          
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        reject(error);
        // commit('LOGINFAILED', error.data);
      });
    });
    },
    
    getTaxiAssAddOn({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TA_SERVICETYPE_URL,
      };
      this._vm.$TAMS_API.getById(requestheader, id).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('ADDONLOADED', parsedResultOP.data);
          resolve(parsedResultOP.data);
          
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    });
    },
    
    getAssBilling({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      status, skip,len,router
    }) {
      return new Promise((resolve, reject) => {
       
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TASS_BILLING_URL,
        };


        this._vm.$TAMSFIN_API.getByPaging(requestheader, { status: status, skip: skip, len: len }
          ).then((parsedResultOP) => {
            console.log(parsedResultOP);
            if (parsedResultOP.status) {
              // commit('BILLINGLOADED', parsedResultOP.data);
              resolve(parsedResultOP.data);
              
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            console.log(error);
            commit('', error.data);
          });
      });
    },

    getAccountDetailsByServiceTypeId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      taxi_assocation_id,service_type_id,router
    }) {
      return new Promise((resolve, reject) => {
       
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_GETSERVICEACCOUNTBYID_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging2(requestheader, { skip: taxi_assocation_id, len: service_type_id }
          ).then((parsedResultOP) => {
            console.log(parsedResultOP);
            if (parsedResultOP.status) {

              resolve(parsedResultOP.data);
              
            }
          }).catch((error) => {
            console.log(error);

            reject(error);
            // commit('', error.data);
          });
      });
    },

    getAccountTransactionsByServiceTypeId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      taxi_assocation_id,service_type_id,router
    }) {
      return new Promise((resolve, reject) => {
       
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_GETACCOUNTTRANSACIONSBYID_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging2(requestheader, { skip: taxi_assocation_id, len: service_type_id }
          ).then((parsedResultOP) => {
            console.log(parsedResultOP);
            if (parsedResultOP.status) {

              resolve(parsedResultOP.data);
              
            }
          }).catch((error) => {
            console.log(error);

            reject(error);
            // commit('', error.data);
          });
      });
    },
    addTaxiAssociationAccount({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel,router }) {
      
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_ADDSERVICETYPEACCOUNT_URL,
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              resolve(parsedResult.data);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },

    associationAccountTopUp({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel, router }) {
      
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_ACCOUNTTOPUP_URL,
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              resolve(parsedResult.data);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },

    getBillingByAss({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      status, skip,len,router
    }) {
  
      return new Promise((resolve, reject) => {
       
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TA_BILLINGUPDATE_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging(requestheader, { status: status, skip: skip, len: len }
          ).then((parsedResultOP) => {
            console.log(parsedResultOP);
            if (parsedResultOP.status) {
              commit('BILLINGLOADED', parsedResultOP.data);
              resolve(parsedResultOP.data);
              
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            console.log(error);
            commit('', error.data);
          });
      });
    },
   
   
   
  },

};
