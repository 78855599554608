/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import configbase from '../../../../configurations/configbasetpay';


export default {
  namespaced: true,
  state: {
    userprofile: [],
    lookup: [],
    association_roles: [],
    usergroups: [],
    userRoleGroup: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETALL: state => state.lookup,
    GETUSERPROFILE: state => state.userprofile,
    GETROLESBYASSOCIATION: state => state.association_roles,
    GETUSERGROUPSBYASSOCIATION: state => state.usergroups,
    GETALLCAPTUREPHOTOS: (state, getters, rootState, rootGetters) => {
      return rootGetters['capturephotos/GETALL']
    },

  },
  mutations: {
    LOADED: (state, _data) => {
      if (_data !== undefined) {
        state.lookup = _data;
      }
    },

    PROFILELOADED: (state, _data) => {
      if (_data !== undefined) {
        state.userprofile = _data;
      }
    },
    ASSOCIATIONROLESLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.association_roles = _data;
      }
    },
    ASSOCIATIONUSERGROUPSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.usergroups = _data;
      }
    },
    ADDED: (state, _data) => {
      state.lookup.push(_data);
    },
    UPDATED: (state, _data) => {
      if (_data !== undefined) {
        Object.assign(state.userprofile.filter(item => item._id === _data._id)[0], _data);
      }
    },
    RESTOREDELETED: (state, {
      id
    }) => {
      if (id !== undefined) {
        state.lookup.filter(item => item._id === id)[0]._deleted_by = undefined;
        state.lookup.filter(item => item._id === id)[0]._date_deleted = undefined;
      }
    },
    MARKASDELETE: (state, {
      id,
      deletedby
    }) => {
      if (id !== undefined) {
        state.lookup.filter(item => item._id === id)[0]._deleted_by = deletedby;
        state.lookup.filter(item => item._id === id)[0]._date_deleted = Date.now;
      }
    },
    DELETE: (state, id) => {
      if (id !== undefined) {
        state.lookup = state.lookup.filter(item => item._id !== id);
        state.deleted = true;
      }
    },
    CLEARALL: (state) => {
      state.userprofile = [];
      state.lookup = [];
      state.association_roles = [];
      state.usergroups = [];
    },
  },
  actions: {
    /* Async */ 

     /* Auth.js Mutations */
     GetDayOfWeek({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }) {
      const url = configbase.ADMIN_URL("GetDayOfWeek");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
     EnableDisableDepartureTime({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("EnableDisableDepartureTime");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
     /* Auth.js Mutations */
     DeleteUndeleteDepartureTime({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("DeleteUndeleteDepartureTime");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    /* Auth.js Mutations */
    GetDepartureTimeByTaxiAssId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("GetDepartureTimeByTaxiAssId");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    AddDepartureTime({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("AddDepartureTime");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetAllRoutesByTaxiAssId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("GetAllRoutesByTaxiAssId");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetALLRANKSById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("GetAllRanksById");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    AddTaxiRoute({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("AddTaxiRoute");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    AddTaxiRank({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("AddTaxiRank");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetSurburbById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("GetSurburbById");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetCitiesById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("GetCitiesById");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetProvincesById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("GetProvincesById");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetCountries({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("GetCountries");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    RemovePublicHoliday({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("RemovePublicHoliday");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    AddPublicHoliday({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("AddPublicHoliday");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetHolidays({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("GetAllPublicHolidays");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    /* Auth.js Mutations */
    GetCitiesByProviceId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.FIN_COMMUTER_URL("GetCitiesByProviceId");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetProvincesByCountryId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.FIN_COMMUTER_URL("GetProvincesByCountryId");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    RegisterVehicle({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("RegisterVehicle");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    RegisterAgents({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("RegisterAgents");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    RegisterOperators({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("RegisterOperators");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    RegisterDriver({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.ADMIN_URL("RegisterDrivers");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    /* Auth.js Mutations */
    GETBANKS({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }) {
      const url = configbase.ADMIN_URL("GetBanks");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    /* Auth.js Mutations */
    EnableDisableAccountAction({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.COMMUTER_URL("EnDisableAccount");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    UpdateNextOfKindAction({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, PayLoad) {
      const url = configbase.COMMUTER_URL("AddNextOfKin");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(PayLoad),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    clearAll({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }) {
      /* Auth.js Mutations */
      commit('CLEARALL');
      console.log("users cleared!");
    },

    getById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERSGETBYID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            console.log('user fonund: ',parsedResult.data )
            // commit('PROFILELOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {

          console.log('user errors:', error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getall({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router
    }) {
      return new Promise((resolve, reject) => {


        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERS_URL,
        };

        this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
          if (parsedResult.status) {
            console.log('system users: ', parsedResult.data);
            //commit('LOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log("this is the error(module): ", error.data)
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    getotherall({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERSOTHER_URL,
        };

        this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
          if (parsedResult.status) {
            console.log('system users: ', parsedResult.data);
            //commit('LOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log("this is the error(module): ", error.data)
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    getSearchUsername({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERSSEACHUSERNAME_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
            // navigateTo(router, parsed_result.data[0]);
          } else
            reject(parsedResult);
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
    searchVehicleFullSystem({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FULLSYSTEMVEHICLESEARCH_URL,
        };

        this._vm.$TPAY_API.add(requestheader,
          {
            SearchText:id,
            GeoLocation:"",
          }
          ).then((parsedResultOP) => {
          if (parsedResultOP.Status) {
            
            resolve(parsedResultOP.Data);
            // navigateTo(router, parsed_result.data[0]);
          } else
            reject(parsedResult);
        }).catch((error) => {
          
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
    searchFullSystem({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      SearchText,
    }) {
      const url = configbase.ADMIN_URL("search");
      
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify({
              "SearchText": SearchText,
              "GeoLocation": "",
            }),
          }).then(response => response.json()).then((parsedResult) => {

            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    searchVoucherFullSystem({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FULLSYSTEMVOUCHERSEARCH_URL,
        };

        this._vm.$TPAY_API.add(requestheader,
          {
            SearchText:id,
            GeoLocation:"",
          }
          ).then((parsedResultOP) => {
          if (parsedResultOP.Status) {
            resolve(parsedResultOP.Data);
            // navigateTo(router, parsed_result.data[0]);
          } else
            reject(parsedResult);
        }).catch((error) => {
          
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
    getProfilePicture({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      return new Promise((resolve, reject) => {


        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.PROFILEPICTUREURL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          resolve(parsedResult);
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    getByTaxiAssId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
        id,
        router,
        userid
    }) {
      return new Promise((resolve, reject) => {

        
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERSGETBYTAXIASSIBYID_URL,
        };

        this._vm.$TAMS_API.getCustomURL(requestheader, 
                    {
                        id,
                        userid
                    }).then((parsedResult) => {
          if (parsedResult.status) {
            //commit('LOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
    

    getSystemUsersCapturedBy({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      associationid,
      userid,
      router
    }) {
      
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERCAPTUREDBY_URL,
        };

        this._vm.$TAMS_API.getByPaging2(requestheader, {
          id: associationid,
          text: userid
        }).then((parsedResult) => {
          if (parsedResult.status) {
            console.log("captured By: ", parsedResult.data)
            // commit('ASSOCIATIONROLESLOADED', parsedResult.data);
            resolve(parsedResult.data);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log("associations errors", error.data)
          reject(error);
        })


      });
    },
    getRolesByAssociation({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      associationid,
      userid,
      router
    }) {
      
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMROLESBYASSOCIATION_URL,
        };

        this._vm.$TAMS_API.getByPaging2(requestheader, {
          id: associationid,
          text: userid
        }).then((parsedResult) => {
          if (parsedResult.status) {
            // console.log("associations", parsedResult.data)
            // commit('ASSOCIATIONROLESLOADED', parsedResult.data);
            resolve(parsedResult.data);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log("associations errors", error.data)
          reject(error);
        })


      });
    },

    getUserGroupsByAssociation({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERSUSERGROUPS_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            console.log("usersgroups", parsedResult.data)
            commit('ASSOCIATIONUSERGROUPSLOADED', parsedResult.data);
            resolve(parsedResult.data);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log("usersgroups errors", error)
          reject(error);
        })
      });
    },

    updateUserPassword({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _updateModel,
      router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERSUPDATEPASSWORD_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          _updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    updateUserGroup({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _updateModel,
      router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERSUPDATEUSERGROUP_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          _updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    updateUserGeneratedPassword({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _updateModel,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERSUPDATEGENERATEDPASSWORD_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          _updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    forgotUserPassword({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERSFORGOTPASSWORD_URL,
        };

        this._vm.$TAMS_API.getById(requestheader,
          id).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    addSystemuserUserGroupRole({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _addModel,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.ADDSYSTEMUSERROLES_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          _addModel).then((parsedResult) => {
          if (parsedResult.status) {
            //  commit('ADDED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    addSystemuserAccount({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      addModel,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.ADDSYSTEMUSERACCOUNT_URL,
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          addModel).then((parsedResult) => {
          if (parsedResult.status) {
            //  commit('ADDED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    activateSystemuserUser({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _addModel,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.ACTIVATESYSTEMUSER_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          _addModel).then((parsedResult) => {
          if (parsedResult.status) {
            console.log('system user activated: ', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {

          console.log('system user errors: ', error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    deactivateSystemuserUser({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _addModel,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.DEACTIVATESYSTEMUSER_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          _addModel).then((parsedResult) => {
          if (parsedResult.status) {
            console.log('system user deactivated: ', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {

          console.log('system user deactivate errors: ', error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    
    removeSystemuserUserGroupRole({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _addModel,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.REMOVESYSTEMUSERROLES_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          _addModel).then((parsedResult) => {
          if (parsedResult.status) {
            //  commit('ADDED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    updateSystemUser({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _addModel,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERUPDATE_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          _addModel).then((parsedResult) => {
          if (parsedResult.status) {
            //  commit('ADDED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },  

    updateSystemUserPictures({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _addModel,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERSUPDATEPICTURES_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          _addModel).then((parsedResult) => {
          if (parsedResult.status) {
            //  commit('ADDED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },  


    

    add({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          item,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERS_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          item).then((parsedResult) => {
          if (parsedResult.status) {
            commit('ADDED', item);
            resolve();
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    addOther({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          item,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERSOTHER_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          item).then((parsedResult) => {
          if (parsedResult.status) {
            commit('ADDED', item);
            resolve();
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    update({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      updateModel,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERS_URL,
        };

        this._vm.$TAMS_API.update(requestheader,
          id, updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            commit('UPDATED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    delete({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERS_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id, {
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('DELETE', id);
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    markAsdelete({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERS_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id, {
            id,
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('MARKASDELETE', {
              id,
              deletedby
            });
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    restoreDeleted({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          restoredby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.USERGROUPS_URL,
        };

        this._vm.$TAMS_API.restoreDeleted(requestheader, {
          id,
          restoredby,
        }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('RESTOREDELETED', {
              id
            });
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

  },

};