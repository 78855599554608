<template>
  <div class="panel panel-inverse" data-sortable-id="ui-buttons-6">
    <!-- begin panel-heading -->
    <div class="panel-heading">
      <div class="panel-heading-btn">
        <a
          @click="clearData(context)"
          class="btn btn-xs btn-icon btn-circle btn-success"
          data-click="panel-reload"
        >
          <i class="fa fa-redo"></i>
        </a>
      </div>
      <h4 class="panel-title">Record Video or Audio</h4>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="col-md-6">
          <div class="btn-group">
            <a
              @click="DisplayControl(context,'Audio')"
              :class="['btn btn-lg',{'btn-white':!DisplayAudio,'btn-yellow':DisplayAudio}]"
            >
              <i class="fas fa-microphone fa-2x pull-left text-center m-l-10 m-r-10 text-black"></i>
              <br />
            </a>
          </div>
        </div>
        <div class="col-md-6">
          <div class="btn-group">
            <a
              @click="DisplayControl(context,'Video')"
              :class="['btn btn-lg',{'btn-white':!DisplayVideo,'btn-yellow':DisplayVideo}]"
            >
              <i class="fas fa-video fa-2x pull-left text-center m-l-10 m-r-10 text-black"></i>
              <br />
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <vue-record-audio :mode="'press'" v-show="DisplayAudio" @result="onResultAudio" />
          <a
            v-show="AudioTaken"
            @click="DeleteAudio(context)"
            class="btn btn-default btn-icon btn-circle btn-lg"
          >
            <i class="fa fa-trash-alt"></i>
          </a>
        </div>
        <div class="col-md-6">
          <vue-record-video :mode="'press'" v-show="DisplayVideo" @result="onResultVideo" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" v-show="AudioTaken">

         <audio controls :src="AudioTaken"></audio>

          
         
        </div>
        <div class="col-md-10" v-show="VideoTaken">

         <video width="220" controls height="140" :src="VideoTaken" ></video>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("fileupload");

export default {
  name: "AudioVideoControl",
  props: {
    SessionID: {
      type: String,
      required: true
    },
    ClearData: {
      type: Boolean,
      required: true
    }
  },
  beforeMount() {
    if (this.ClearData) {
      this.$store
        .dispatch("fileupload/clearall", {
          router: this.$router
        })
        .finally(() => {});
    }
  },
  data: function() {
    return {
      DisplayAudio: false,
      DisplayVideo: false,
      VideoTaken: false,
      AudioTaken: false,
      VideoTakenId: 0,
      AudioTakenId: 0,
      context: this,
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      context: this,
      CAPTUREPHOT0S: [],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      addedBy: this.$store.getters["auth/USERPROFILE"].system_usersid,
      croppedphoto: ""
    };
  },
  components: {},
  computed: {
    ...mapGetters(["GETALL"])
  },
  methods: {
    ...mapActions(["upload", "delete", "clearall"]),
    clearData: context => {
      context
        .clearall({
          router: context.$router
        })
        .finally(() => {
          context.DisplayAudio = false;
          context.DisplayVideo = false;
          context.VideoTaken = false;
          context.AudioTaken = false;
        });
    },
    DisplayControl: (context, controltype) => {
      if (controltype === "Audio") {
        context.DisplayAudio = true;
        context.DisplayVideo = false;
        context.VideoTaken = false;
        context.AudioTaken = false;
      } else {
        context.DisplayAudio = false;
        context.DisplayVideo = true;
        context.VideoTaken = false;
        context.AudioTaken = false;
      }
    },
    DeleteAudio: context => {
      context.Delete(context, context.AudioTakenId);
      context.AudioTakenId = 0;
      context.AudioTaken = false;
    },
    DeleteVideo: context => {
      context.Delete(context, context.VideoTakenId);
      context.VideoTakenId = 0;
      context.VideoTaken = false;
    },
    onResultVideo(data) {
      const context = $(".panel-body").data("context");
      context.VideoTaken = window.URL.createObjectURL(data);
      context.AudioTaken = false;
      //console.log("The blob data:", data);
      //console.log("Downloadable audio", window.URL.createObjectURL(data));
      var reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = function() {
        const fileid = context.$uuid.v1();
        context.VideoTakenId = fileid;
        context.Save(
          context,
          fileid,
          base64data,
          reader,
          context.getMIMETYPE(base64data)
        );
      };
    },

    onResultAudio(data) {
      
      const context = $(".panel-body").data("context");
      context.VideoTaken = false;
      context.AudioTaken = window.URL.createObjectURL(data);
      var reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = function() {
        var base64data = reader.result;
        const fileid = context.$uuid.v1();
        context.AudioTakenId = fileid;
        context.Save(
          context,
          fileid,
          base64data,
          reader,
          context.getMIMETYPE(base64data)
        );
      };
    },
    Save(context,fileid,filecontent, file, mimeType) {
      context
        .upload({
          router: context.$router,
          filecontent,
          sessionId: context.SessionID,
          photoid: fileid,
          addedBy: context.addedBy,
          mimeType
        })
        .finally(() => {
          const sessionid = context.SessionID;
          context.$emit("fileadded", {file,fileid,mimeType,sessionid });
        });
    },
    getMIMETYPE(base64Content) {
      // base64 encoded data doesn't contain commas
      let base64ContentArray = base64Content.split(",");

      // base64 content cannot contain whitespaces but nevertheless skip if there are!
      let mimeType = base64ContentArray[0].match(
        /[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/
      )[0];
      return mimeType;
    },
    change({ coordinates, canvas }) {
      this.croppedphoto = canvas.toDataURL();
    },
    Delete: (context, id) => {
      context.$store.dispatch("capturephotos/deletephoto", {
        id,
        deletedby: context.addedBy,
        router: context.$router
      });
    },
    onCapture() {
      
      this.CAPTUREPHOT0S.push(this.$refs.webcam.capture());
      const _photo = this.$refs.webcam.capture();
      this.$store.dispatch("capturephotos/added", {
        router: this.$router,
        filecontent: _photo,
        sessionId: this.SessionID,
        photoid: this.$uuid.v1(),
        addedBy: this.addedBy
      });
      //this.$emit("onCapture",_photo);
    },
    onStarted(stream) {
      console.log("On Started Event", stream);
      this.$emit("onStarted", stream);
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
      this.$emit("onStopped", stream);
    },
    onStop() {
      this.$refs.webcam.stop();
      this.$emit("onStopped");
    },
    onStart() {
      this.$refs.webcam.start();
      this.$emit("onStopped");
    },
    onError(error) {
      console.log("On Error Event", error);
      this.$emit("onStopped", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log("On Cameras Event", cameras);
      this.$emit("onCameras", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      console.log("On Camera Change Event", deviceId);
      this.$emit("onCameraChange", deviceId);
    }
  },
  mounted() {
    $(".panel-body").data("context", this);
  }
};
</script>

<style scoped>
.cropper {
  background: #ddd;
}
</style>

