/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */


export default {
  namespaced: true,
  state: {
    drivers_transactions: [],
    vehicles_transactions: [],
    taxis_transactions: [],
    statement:[],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETSTATEMENT: state => state.statement,
  },
  mutations: {
    LOADSTATEMENT: (state, _data) => {
        state.statement = _data;
    },
    CLEAR: (state) => {
      state.statement = [];
  },
  },
  actions: {
    /* Async */
    clearData({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }) {
      commit('CLEAR');
    },

    /* Auth.js Mutations */
    generateOperatorStatement({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
        payload, router
    }) {
      return new Promise((resolve, reject) => {

        dispatch('TO/getAllTransactionsByDate', {
            payload, router
          }, {
            root: true
          }).then((parsedResult) => {
            if (parsedResult.status) {
              // commit('LOADSTATEMENT', parsedResult.data);
              resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
          // commit('LOGINFAILED', error.data);
            reject();
          });

        });
    },
    generateTaxiStatement({
        state,
        rootState,
        rootGetters,
        commit,
        dispatch,
        getters,
      }, {
          payload, router
      }) {


        return new Promise((resolve, reject) => {

          dispatch('TV/getAllTransactionsByDate', {
              payload, router
            }, {
              root: true
            }).then((parsedResult) => {
              if (parsedResult.status) {
                //commit('LOADSTATEMENT', parsedResult.data);
                resolve(parsedResult);
              // navigateTo(router, parsed_result.data[0]);
              }
            }).catch((error) => {
            // commit('LOGINFAILED', error.data);
              reject();
            });
  
          });

      },
      generateDriverStatement({
        state,
        rootState,
        rootGetters,
        commit,
        dispatch,
        getters,
      }, {
          payload, router
      }) {

        return new Promise((resolve, reject) => {

          dispatch('TaxiDrivers/getAllTransactionsByDate', {
              payload, router
            }, {
              root: true
            }).then((parsedResult) => {
              if (parsedResult.status) {
                commit('LOADSTATEMENT', parsedResult.data);
                resolve(parsedResult);
              // navigateTo(router, parsed_result.data[0]);
              }
            }).catch((error) => {
            // commit('LOGINFAILED', error.data);
              reject();
            });
  
          });

      },



  },

};