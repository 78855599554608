/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import configbase from '../../../configurations/configbasetpay';

export default {
  namespaced: true,
  state: {
    isloggegon:false,
    fullname:'',
    uid:'',
    userprofile: {},
    walletprofile: {},
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    WALLETPROFILE: state => state.walletprofile,
    USERPROFILE: state => state.userprofile,
    ISLOGGEDON: state => state.isloggegon,
    TOKEN: state => state.userprofile.Token,
    FULLNAME: state => state.fullname,
  },

  mutations: {
    LOGINSUCCESS: (state, _userprofile) => {
      if (_userprofile !== undefined) {
        state.userprofile = _userprofile;
        state.isloggegon = true;
      }
    },
    UPDATEWALLET: (state, _profile) => {
      if (_profile !== undefined) {
        state.walletprofile = _profile;
      }
    },
    SIGNOFF: (state,_userprofile) => {
        state.userprofile = _userprofile;
        state.isloggegon = false;
    },
    SAVEFULLNAME: (state,{_fullname,uid}) => {
      state.fullname = _fullname;
      state.uid = uid;
  },
  },
  actions: {

    /* Async */
    LicenseNoExists({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      licencenumber,
    }) {
      const url = configbase.AUTH_URL("LicenseNoExists");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              "licencenumber": licencenumber,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    MobileNumberExists({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      mobilenumber,
    }) {
      const url = configbase.AUTH_URL("MobileNumberExists");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              "mobilenumber": mobilenumber,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    SAVEFULLNAME({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      fullname,
      uid
    }) {
      commit('SAVEFULLNAME', {fullname,uid});
    },
    RequestOTPAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      mobilenumber,
    }) {
      const url = configbase.AUTH_URL("RequestOTP");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              "mobilenumber": mobilenumber,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    UpdateCommuterAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      firstname,
      surname,
      idnumber,
      password,
    }) {
      const url = configbase.COMMUTER_URL("UpdateProfile");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              "SystemUserId": rootGetters['auth/USERPROFILE'].SystemUsersId,
              "FirstName": firstname,
              "LastName": surname,
              "IDNumber": idnumber,
              "MobileNumber": rootGetters['auth/USERPROFILE'].MobileNumber,
              "Password": password
            }),
          }).then(response => response.json()).then((parsedResult) => {
            
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    RegisterCommuterAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      firstname,
      surname,
      mobilenumber,
      password,
    }) {
      const url = configbase.AUTH_URL("RegisterCommuter");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              "firstname": firstname,
              "surname": surname,
              "mobilenumber": mobilenumber,
              "password": password,
              "platformtype_code": "Web",
            }),
          }).then(response => response.json()).then((parsedResult) => {
            
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetOPT({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      mobilenumber,
    }) {
      const url = configbase.AUTH_URL("RequestOTP");

        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              "mobilenumber": mobilenumber,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    RegisterDriver({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      firstname,
      surname,
      mobilenumber,
      email,
      idnumber,
      uid,
    }) {
      const url = configbase.AUTH_URL("RegisterCommuterInternal");

        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              "firstname": firstname,
              "surname": surname,
              "mobilenumber": mobilenumber,
              "email": email,
              "idnumber": idnumber,
              "uid": uid,
              "userlocation": "",
              "platformtype_code": "Web",
            }),
          }).then(response => response.json()).then((parsedResult) => {
            
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    RegisterCommuterInternal({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      firstname,
      surname,
      mobilenumber,
      email,
      idnumber,
      uid,
    }) {
      const url = configbase.AUTH_URL("RegisterCommuterInternal");

        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              "firstname": firstname,
              "surname": surname,
              "mobilenumber": mobilenumber,
              "email": email,
              "idnumber": idnumber,
              "uid": uid,
              "userlocation": "",
              "platformtype_code": "Web",
            }),
          }).then(response => response.json()).then((parsedResult) => {
            
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    SignInAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      username,
      password
    }) {
      const url = configbase.AUTH_URL("LoginCommuter");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              "mobilenumber": username,
              "password": password,
              "platformtype_code": "Web",
            }),
          }).then(response => response.json()).then((parsedResult) => {
            if (parsedResult.Status) {
              commit('LOGINSUCCESS', parsedResult.Data);
            }
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    SignIn3rdPartyAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      uuid,
      authjson
    }) {
      const url = configbase.AUTH_URL("LoginCommuter3rdParty");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              "uid": uuid,
              "authjson": authjson,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            if (parsedResult.Status) {
              commit('LOGINSUCCESS', parsedResult.Data);
            }
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetProfile({
      commit,
      state,
      dispatch,
    }) {
      const url = configbase.COMMUTER_URL("GetProfileByRequestId");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': state.userprofile.token,
            }),
            body: JSON.stringify({
              "RequestId": state.userprofile.MobileNumber,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            if (parsedResult.Status) {
              commit('UPDATEWALLET', parsedResult.Data);

              dispatch("commuter/GetBankCardsAction",{},{root:true});
              
            }
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    SignOffAction({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }) {
        return new Promise((resolve, reject) => {
          commit('SIGNOFF',null);
          resolve();
        });
    },
  },

};