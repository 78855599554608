<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'CashUp Details'"
      :ActiveMenu="'CashUp Details'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="SMS Details"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Office"
        breadcrumbitem3="SMS Details"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">SMS Details</h4>
            </div>
            <!-- end panel-heading -->

            <!-- begin panel-body -->
            <div class="panel-body">
              <!-- begin tab-content -->
              <div class="tab-content">
                <!-- begin tab-pane -->
                <table id="data-table-combine-payments" class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th ></th>
                      <th >Mobile No</th>
                      <th >SMS</th>
                      <th >Date Sent</th>
                      <!-- <th >Date Delivered</th>
                      <th >Date Failed</th> -->
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(item,index) in SMSES"
                      v-bind:key="item._id"
                      :class="index & 1 ? 'odd gradeA' : 'even'"
                    >
                      <td>{{++index}}</td>
                      <td>{{item._sms_number}}</td>
                      <td>{{item._message}}</td>
                      <td>{{item._date_added}}</td>
                      <!-- <td>{{item._date_delivered }}</td>
                      <td>{{item._date_failed}}</td> -->
                     
                    </tr>
                  </tbody>
                </table>
                <!-- end tab-pane -->
              </div>
              <!-- end tab-content -->
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("sms");

export default {
  name: "ManageVehicles",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      context: this,
      addedsuccess: undefined,
      errormessage: undefined,
      batchname: this.$route.params.batchname,
      skip: 0,
      length: 10000,
      startDate: {},
      SMSES:[],
      endDate: {},
      paymentsloaded: false,
      arearsvehiclesloaded: false,
      arrearsoperatorsloaded: false
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters({
    }),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions(["getBulkSmsByBatch"])
  },
  beforeMount: function() {},
  mounted: function() {
    //this.getbyid(this.context, this.taxiassociationid);

    App.init();

    

    const context = this;

    const router = context.$router;

    context
      .getBulkSmsByBatch({ router, id: context.batchname })
      .then(parsedResultOP => {
        
        if (parsedResultOP.status) {
          if (parsedResultOP.data.length > 0) {
            if ($("#data-table-combine-payments").length !== 0) {
                context.SMSES = parsedResultOP.data;
            }
          }
        }
      }).catch((err) => {

      }).finally(() => {
         var options = {
                dom: "lBfrtip",
                buttons: [
                  { extend: "copy", className: "btn-sm" },
                  { extend: "csv", className: "btn-sm" },
                  { extend: "excel", className: "btn-sm" },
                  { extend: "pdf", className: "btn-sm" },
                  { extend: "print", className: "btn-sm" }
                ],
                responsive: true,
                autoFill: true,
                colReorder: true,
                keys: true,
                rowReorder: true,
                select: true
              };

              if ($(window).width() <= 767) {
                options.rowReorder = false;
                options.colReorder = false;
                options.autoFill = false;
              }

              $("#data-table-combine-payments").DataTable(options);
      
      });
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

