<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp 
    :USERROLES='ROLES' 
    :USERPROFILE="USERPROFILE" 
    :ActiveSubMenu="'ManageVehicle'" 
    :ActiveMenu="'Taxi Operator Vehicles'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Taxi Vehicle Photos"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Vehicle"
        breadcrumbitem3="Taxi Vehicle Photos"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->
      <div id="gallery" class="gallery">

      <!-- begin image -->
				<div class="image gallery-group-4">
					<div class="image-inner">
						<a href="#" data-lightbox="gallery-group-4">
							<img src="" alt="" />
						</a>
						<p class="image-caption">
							#5721 Scottwills Snow
						</p>
					</div>
					<div class="image-info">
						<h5 class="title">Nunc eget hendrerit nisi sodales</h5>
						<div class="pull-right">
							<small>by</small> <a href="javascript:;">Andy Wong</a>
						</div>
						<div class="rating">
							<span class="star active"></span>
							<span class="star active"></span>
							<span class="star active"></span>
							<span class="star active"></span>
							<span class="star active"></span>
						</div>
						<div class="desc">
							Ut et augue nisi sodales luctus libero dignissim sodales. Fusce feugiat nisi sodales sapien consequat lacinia fringilla.
						</div>
					</div>
				</div>
				<!-- end image -->
				<!-- begin image -->
				<div class="image gallery-group-4">
					<div class="image-inner">
						<a href="/assets/img/gallery/gallery-9.jpg" data-lightbox="gallery-group-4">
							<img src="/assets/img/gallery/gallery-9.jpg" alt="" />
						</a>
						<p class="image-caption">
							#9921 Scottwills Riverbank
						</p>
					</div>
					<div class="image-info">
						<h5 class="title">Nunc eget hendrerit nisi dignissim</h5>
						<div class="pull-right">
							<small>by</small> <a href="javascript:;">William Tan</a>
						</div>
						<div class="rating">
							<span class="star"></span>
							<span class="star"></span>
							<span class="star"></span>
							<span class="star"></span>
							<span class="star"></span>
						</div>
						<div class="desc">
							Ut et augue luctus libero dignissim sodales. Fusce feugiat sapien consequat libero dignissim lacinia fringilla.
						</div>
					</div>
				</div>
				<!-- end image -->
				<!-- begin image -->
				<div class="image gallery-group-4">
					<div class="image-inner">
						<a href="/assets/img/gallery/gallery-10.jpg" data-lightbox="gallery-group-4">
							<img src="/assets/img/gallery/gallery-10.jpg" alt="" />
						</a>
						<p class="image-caption">
							#6436 Scottwills Buss
						</p>
					</div>
					<div class="image-info">
						<h5 class="title">Sed mollis nisi sed auctor</h5>
						<div class="pull-right">
							<small>by</small> <a href="javascript:;">David King</a>
						</div>
						<div class="rating">
							<span class="star active"></span>
							<span class="star active"></span>
							<span class="star"></span>
							<span class="star"></span>
							<span class="star"></span>
						</div>
						<div class="desc">
							Vestibulum dictum pharetra leo sed euismod. Lorem ipsum dolor sit amet, consectetur adipiscing feugiat sapien elit.
						</div>
					</div>
				</div>
				<!-- end image -->

      </div>

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import headerComp from "@/components/common/header.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import pageloader from "@/components/common/pageloader.vue";
import moment from "moment";

import { createNamespacedHelpers } from "vuex";
import { setTimeout } from "timers";
const { mapGetters, mapActions } = createNamespacedHelpers("OperatorVehicle");

export default {
  name: "VehiclePictures",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      GETALLDOCUMENTTYPE: this.$store.getters["lookups/GETALLDOCUMENTTYPE"],
      taxi_operator_vehicles_id: 0,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      id: this.$route.params.id,
      front: 'https://tams-api.herokuapp.com/api/v1/taxioperatorvehicles/pictures/591/1',
      PICTURES: null,
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },

  computed: {
    ...mapGetters(["GETVEHICLEPICTURES"]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  beforeMount(){
    this.vehiclePicures(this.context);
  },

  methods: {
    ...mapActions([
      "getPicturesById"
    ]),
    DeleteDocument: (context, item) => {
      value.context.$store
            .dispatch("fileupload/delete", {
              id: value.fileId,
              deletedby: value.userID
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");
              value.context.$Notify.Success("Successfully Deleted!", "");
            })
            .catch(error => {
              value.context.$wait.end("add");
              
              value.context.$Notify.Error(error.data[0].message, "");
              
            })
            .finally(() => {});
    },

    vehiclePicures: (context) => {
    context.getPicturesById({
              id: context.id,
              router: context.$router
            })
            .then((data) => {
              
              console.log('pictures found: ',data);
              context.PICTURES = data;
            })
            .catch(error => {
              console.log('picture errors: ',error);
            })
            .finally(() => {});
    },
    fileadded: function(data) {
      
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')
      const context = $("#step-2").data("context");
      context.GETUPLOADEDFILES = context.$store.getters["fileupload/GETALL"].filter(
        item => item.sessionID === context.DropZoneSessionID
      );
    },

    onclick: function() {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(handleSuccess)
        .catch(handleError);
    },

    handleSuccess(stream) {
      screenshotButton.disabled = false;
      video.srcObject = stream;
    }
  },
  mounted: function() {

    App.init();
    
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>