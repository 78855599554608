/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import config from './configbase';

// options...
export default {
  // options... 
  LOGIN_URL: () => `${config.TPAYBASE_URL()}/authentication/login`,
  AGENTLOGIN_URL: () => `${config.TPAYBASE_URL()}/authentication/LoginAgent`,
  URL: apiname => `${config.TPAYBASE_URL()}/${apiname}`,
  URLBYID: apiname => `${config.TPAYBASE_URL()}/${apiname}/{id}`,
  URLBYTAXIID: apiname => `${config.TPAYBASE_URL()}/${apiname}/TaxiAssId/{id}`,
  URL_PAGING: apiname => `${config.TPAYBASE_URL()}/${apiname}/{status}/{skip}/{len}`,
  URL_PAGING2: apiname => `${config.TPAYBASE_URL()}/${apiname}/{skip}/{len}`,
  URLBYACCOUNT: apiname => `${config.TPAYBASE_URL()}/${apiname}/{associationsid}/{accountnumber}/{skip}/{length}`,
  URL_RESTOREDELETED: apiname => `${config.TPAYBASE_URL()}/${apiname}/{id}/{restoredby}`,
  URL_CUSTOM: apiname => `${config.TPAYBASE_URL()}/${apiname}`,

  TPAYDASHBOARDBOOKINGS_URL: () => `${config.TPAYBASE_URL()}/reports/dashboard`,
  

};


