/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';
import configbase from '../../configurations/configbaserenewonline';

export default {
  namespaced: true,
  state: {
    operator_vehicle: [],

  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETUPLOADEDFILES: (state, getters, rootState, rootGetters) => {
      return rootGetters['fileupload/GETALL']
    },
    // GETTAXIVEHICLEEDIT: state => state.taxi_vehicles,
    GETADDVEHICLESTATUS: state => state.vehicle_status,

  },
  mutations: {
    LOADED: (state, _data) => {
      
      if (_data !== undefined) {
        state.operator_vehicle = _data;
      }
    },

  },
  actions: {
    /* Async */
    MarkAsDeliveredLicenseRenewal({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      AddModel,
      router
    }) {
      return new Promise((resolve, reject) => { 
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.RENEWLICENSEDISK_MARKASDELIVERED_URL,
      };

      this._vm.$TAMS_API.add(requestheader,
        AddModel).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('LOADDETAILS', parsedResultOP.data[0]);
          // navigateTo(router, parsed_result.data[0]);
          resolve(parsedResultOP.data);
        }
      }).catch((error) => {
        console.log(error);
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
    });
    },
    GetAllLicenseDiskOrders({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      return new Promise((resolve, reject) => { 
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.RENEWLICENSE_ORDERS_GETALL_URL,
      };

      this._vm.$TAMS_API.getById(requestheader,
        id
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('LOADDETAILS', parsedResultOP.data[0]);
          // navigateTo(router, parsed_result.data[0]);
          resolve(parsedResultOP.data);
        }
      }).catch((error) => {
        console.log(error);
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
    });
    },
    GetLicenseDiskOrders({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      AddModel,
      router
    }) {
      return new Promise((resolve, reject) => { 
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.RENEWLICENSEPLACEORDER_URL,
      };

      this._vm.$TAMS_API.add(requestheader,
        AddModel).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('LOADDETAILS', parsedResultOP.data[0]);
          // navigateTo(router, parsed_result.data[0]);
          resolve(parsedResultOP.data);
        }
      }).catch((error) => {
        console.log(error);
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
    });
    },
    SearchByLicenseDisk({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      AddModel,
      router,
    }
    ) {
        const url = configbase.RENEWLICENSEDISK_URL("SearchByLicenseDisk");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify(AddModel),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetLicenseDiskPlaceOrder({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      AddModel,
      router
    }) {
      return new Promise((resolve, reject) => { 
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.RENEWLICENSEPLACEORDER_URL,
      };

      this._vm.$TAMS_API.add(requestheader,
        AddModel).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('LOADDETAILS', parsedResultOP.data[0]);
          // navigateTo(router, parsed_result.data[0]);
          resolve(parsedResultOP.data);
        }
      }).catch((error) => {
        console.log(error);
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
    });
    },
    GetLicenseQuote({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      AddModel,
      router,
    }
    ) {
      return new Promise((resolve, reject) => { 
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.RENEWLICENSEDISK_GETQUOTE_URL,
        };
  
        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            resolve(parsedResultOP.data);
          }
        }).catch((error) => {
          console.log(error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
        // const url = configbase.RENEWLICENSEDISK_URL("GetLicenseDiskQuote");
        // return new Promise((resolve, reject) => {
        //   fetch(url, {
        //     method: 'POST',
        //     headers: new Headers({
        //       'Content-Type': 'application/json',
        //       'X-Access-Token':rootGetters['auth/TOKEN'],
        //     }),
        //     body: JSON.stringify(AddModel),
        //   }).then(response => response.json()).then((parsedResult) => {
        //     resolve(parsedResult);
        //   }).catch((error) => {
        //     reject(error);
        //   }).finally(() => {

        //   });
        // });
    },
    AddVehicleToRenewOnline({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      AddModel,
      router,
    }
    ) {
        const url = configbase.RENEWLICENSEDISK_URL("AddVehicleToRenewOnline");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TOKEN'],
            }),
            body: JSON.stringify(AddModel),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
  },
};