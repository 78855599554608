<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'QuickSetup'"
      :ActiveMenu="'LiveTrack'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content content-full-width content-inverse-mode">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Add New System User"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Association"
        breadcrumbitem3="Add"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->
      <!-- begin page-header -->
			<h1 class="page-header">Vector Map <small>header small text goes here...</small></h1>
			<!-- end page-header -->
			<!-- begin map -->
			<div class="map">
					<div id="google-map-default" class="height-full width-full"></div>
				<!-- begin map-float-table -->
				<div class="map-float-table hidden-xs p-15">
					<h4 class="m-t-0"><i class="fa fa-map-marker-alt text-danger m-r-5"></i>Live</h4>
					<!-- begin scrollbar -->
					<div data-scrollbar="true" class="height-md">
						<table class="table table-inverse">
							<thead>
								<tr>
									<th>Agent</th>
                  <th>Description</th>
									<th>RegNo</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Vatican City</td>
                  <td>Vatican City</td>
									<td><span class="text-success">9,820 <i class="fa fa-arrow-up"></i></span></td>
								</tr>
								<tr>
									<td>Monaco</td>
                  <td>Vatican City</td>
									<td>1,920</td>
								</tr>
								<tr>
									<td>Nauru</td>
									<td><span class="text-danger">1,346 <i class="fa fa-arrow-down"></i></span></td>
								</tr>
								<tr>
									<td>Tuvalu</td>
									<td><span class="text-success">6,543 <i class="fa fa-arrow-up"></i></span></td>
								</tr>
								<tr>
									<td>San Marino</td>
									<td><span class="text-success">7,321 <i class="fa fa-arrow-up"></i></span></td>
								</tr>
								<tr>
									<td>Liechtenstein</td>
									<td>3,434</td>
								</tr>
								<tr>
									<td>Marshall Islands</td>
									<td>1,922</td>
								</tr>
								<tr>
									<td>Saint Kitts and Nevis</td>
									<td><span class="text-danger">9,812 <i class="fa fa-arrow-down"></i></span></td>
								</tr>
								<tr>
									<td>Maldives</td>
									<td><span class="text-danger">4,182 <i class="fa fa-arrow-down"></i></span></td>
								</tr>
								<tr>
									<td>Malta</td>
									<td>9,188</td>
								</tr>
								<tr>
									<td>Grenada</td>
									<td>11,198</td>
								</tr>
								<tr>
									<td>Saint Vincent</td>
									<td><span class="text-success">9,101 <i class="fa fa-arrow-up"></i></span></td>
								</tr>
								<tr>
									<td>Barbados</td>
									<td><span class="text-success">9,192 <i class="fa fa-arrow-up"></i></span></td>
								</tr>
								<tr>
									<td>Antigua and Barbuda</td>
									<td><span class="text-success">5,282 <i class="fa fa-arrow-up"></i></span></td>
								</tr>
								<tr>
									<td>Seychelles</td>
									<td><span class="text-success">8,283 <i class="fa fa-arrow-up"></i></span></td>
								</tr>
								<tr>
									<td>Palau</td>
									<td><span class="text-danger">1,222 <i class="fa fa-arrow-down"></i></span></td>
								</tr>
								<tr>
									<td>Andorra</td>
									<td>146</td>
								</tr>
								<tr>
									<td>Saint Lucia</td>
									<td>3,923</td>
								</tr>
								<tr>
									<td>Federated States</td>
									<td>9,827</td>
								</tr>
								<tr>
									<td>Singapore</td>
									<td>7,293</td>
								</tr>
								<tr>
									<td>Kiribati</td>
									<td>9,238</td>
								</tr>
								<tr>
									<td>Tonga</td>
									<td>6,422</td>
								</tr>
								<tr>
									<td>Dominica</td>
									<td><span class="text-success">3,341 <i class="fa fa-arrow-up"></i></span></td>
								</tr>
								<tr>
									<td>Mauritius</td>
									<td><span class="text-success">532 <i class="fa fa-arrow-up"></i></span></td>
								</tr>
								<tr>
									<td>Bahrain</td>
									<td><span class="text-success">8,754 <i class="fa fa-arrow-up"></i></span></td>
								</tr>
								<tr>
									<td>São Tomé and Príncipe</td>
									<td>5,742</td>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- end scrollbar -->
				</div>
				<!-- end map-float-table -->
			</div>
			<!-- end map -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>

  
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("lookups");

export default {
  name: "AddSystemUser",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      SERPROFILE: this.$store.getters["auth/USERPROFILE"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      selectedUserGroupId: 0,
      GETALLUSERGROUPS: [],
      resolutionWidth: undefined,
      resolutionHeight: undefined,
      isUserNameExists: true,
      hacksmartwizard: false,
      USERNAME: "",
      ADDSYSTEMUSER: {
        taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        firstname: "",
        surname: "",
        idnumber: "",
        mobilenumber: "",
        username: "",
        usergroupsid: 0,
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
        titleid: 0,
        genderid: 0,
        photosessionid: this.$uuid.v4()
      }
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
   
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    CapturePhotosControl
  },
  beforeMount() {
    this.getUserGrousByTaxiAssId(this.context);
    
  },
  computed: {
    ...mapGetters(["GETALLTITLES", "GETALLGENDERS"])
  },
  watch: {
    USERNAME: function(val) {
      if (val.toString().length > 3) {
        this.context.$store
          .dispatch("systemusers/getSearchUsername", {
            router: this.$router,
            id: val
          })
          .then(rb => {
            this.isUserNameExists = rb.data[0].fn_system_users_usernameexists;
          });
      }
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted"
    ]),
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    clearAdded: item => {
      for (var propName in item) {
        item[propName] = undefined;
      }
    },
    init: file => {},
    getByTaxiAssId: context => {
      context.$store
        .dispatch("systemusers/getByTaxiAssId", {
          _dataObj: {
            router: context.$router,
            id: context.taxi_association_id
          }
        })
        .then(() => {
          App.init();
          TableManageCombine.init();
          Highlight.init();
        });
    },
    getUserGrousByTaxiAssId: context => {
      context.$store
        .dispatch("usergroups/getByTaxiAssId", {
          router: context.$router,
          id: context.taxi_association_id,
          getbyid: context.USERID
        })
        .then(() => {
          context.GETALLUSERGROUPS =
            context.$store.getters["usergroups/GETALL"];
        });
    }
  },
  mounted: function() {
    //this.get(this.context);

    App.init();

    

    MapGoogle.init();
    
    //MapGoogle.init();

  }
};
</script>

<style scoped>
</style>

