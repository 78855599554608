<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TaxiAssociation'"
      :ActiveMenu="'LetterOfGoodStanding'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Taxi Association Frauded Letters"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Association"
        breadcrumbitem3="Manage Frauded Letters"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin row -->
          <div class="row">
            <!-- begin col-12 -->
            <div class="col-lg-12">
              <!-- begin panel -->
              <div class="panel panel-inverse panel-primary">
                <!-- begin panel-heading -->
                <div class="panel-heading">
                  <div class="panel-heading-btn">
                    <a
                      href="javascript:;"
                      class="btn btn-xs btn-icon btn-circle btn-default"
                      data-click="panel-expand"
                    >
                      <i class="fa fa-expand"></i>
                    </a>
                    <a
                      href="javascript:;"
                      class="btn btn-xs btn-icon btn-circle btn-success"
                      data-click="panel-reload"
                    >
                      <i class="fa fa-redo"></i>
                    </a>
                    <a
                      href="javascript:;"
                      class="btn btn-xs btn-icon btn-circle btn-warning"
                      data-click="panel-collapse"
                    >
                      <i class="fa fa-minus"></i>
                    </a>
                  </div>
                  <h4 class="panel-title">Taxi Association Frauded Letters</h4>
                </div>
                <!-- end panel-heading -->
                <!-- <div class="panel-toolbar">
                  <div class="btn-group m-r-5">
                    <a
                      data-toggle="modal"
                      data-target="#AddModal"
                      class="btn btn-white"
                      href="javascript:;"
                    >
                      <i class="fa fa-plus"></i>
                      Add Route
                    </a>
                  </div>
                </div> -->
              </div>
              <!-- end panel -->

              <!-- begin panel-body -->
              <div class="panel-body">
                <table id="data-table-default" class="table table-striped table-bordered width-full">
                  <thead>
                    <tr>
                      <th class="text-nowrap">Verification Number</th>
                      <th class="text-nowrap">Reported By</th>
                      <th class="text-nowrap">Reporter Mobile Number</th>
                      <th class="text-nowrap">Reporter Email</th>
                      <th class="text-nowrap">Reporter Company</th>
                      <th class="text-nowrap">Date Reported</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(item) in LETTERSFRAUDED"
                      v-bind:key="item.id"
                    >
                      <td>{{item.verification_number}}</td>
                      <td>{{item.reported_by_fullname}}</td>
                      <td>{{item.reported_by_mobilenumber}}</td>
                      <td>{{item.repotedby_email_address}}</td>
                      <td>{{item.company_name}}</td>
                      <td>
                        {{item.date_reported | formatToShortDateWithTime }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- end panel-body -->
            </div>
            <!-- end col-12 -->
          </div>
          <!-- end row -->
        </div>

        <!-- end col-12 -->
      </div>


      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->
	
<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import headerComp from "@/components/common/header.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import pageloader from "@/components/common/pageloader.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";

import { createNamespacedHelpers } from "vuex";
import { setTimeout } from "timers";
import Inputmask from "inputmask";
const { mapGetters, mapActions } = createNamespacedHelpers("TA");

export default {
  name: "AddTaxiAssociation",
  data: function() {
    return {
      currencySymbol: "R ",
      position: "prefix",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      ASSOCIATIONROUTES: [],
      SYSTEMUSERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      id: this.$route.params.id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      LETTERSFRAUDED: [],
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl
  },
  beforeMount() {
    
  },
  computed: {
    ...mapGetters({
    }),
    currency() {
      return { [this.position]: this.currencySymbol };
    },
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions(["addtaxiassociation"]),

    getFraudedLetters: context => {
      context.$store
        .dispatch("TA/getFraudulentLetterByNumber", {
          router: context.$router,
          id: context.id
        })
        .then(data => {
          context.LETTERSFRAUDED = data;
        })
        .catch(error => {
          console.log("Association Routes errors:", error);
        })
        .finally(() => {
          $("#data-table-default").DataTable({
            responsive: true
          });
        });
    },

  },
  mounted: function() {

    this.getFraudedLetters(this.context);
    App.init();
    //this.get(this.context)

    

    $(".btnadded")
      .mouseenter(function() {
        $(this)
          .removeClass("btn-success")
          .addClass("btn-danger")
          .text("Remove");
      })
      .mouseleave(function() {
        $(this)
          .removeClass("btn-danger")
          .addClass("btn-success")
          .text("Added");
      });
  }
};
</script>


<style scoped>
</style>

