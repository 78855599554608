<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

   <!-- begin #sidebar -->
   <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'OnlineServices'"
      :ActiveMenu="'CreditRepair'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Apply For A Credit Repair"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Credit Repair"
        breadcrumbitem3="Renew"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

           <!-- begin nav-pills -->
      <ul class="nav nav-pills">
        <li class="nav-items">
          <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active nav-pills-tab-1">
            <span class="d-sm-none">Apply</span>
            <span class="d-sm-block d-none">Apply</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">Orders</span>
            <span class="d-sm-block d-none">Orders</span>
          </a>
        </li>
      </ul>
      <!-- end nav-pills -->

        <!-- begin tab-content -->
        <div class="tab-content">

      <div class="tab-pane fade active show" id="nav-pills-tab-1">
        <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-2 col-sm-3 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Information
                      <small class="text-ellipsis">New,Transfer And Renew Operating License</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-2 col-sm-3 col-6">
                  <a href="#step-2">
                    <span class="number">2</span>
                    <span class="info text-ellipsis">
                      Upload Form 1B
                      <small
                        class="text-ellipsis"
                      >Upload Completed Upload Form 1B</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-2 col-sm-3 col-6">
                  <a href="#step-3">
                    <span class="number">2</span>
                    <span class="info text-ellipsis">
                      Certified ID Copy
                      <small
                        class="text-ellipsis"
                      >Upload Certificed Copy Of ID Document</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-2 col-sm-3 col-6">
                  <a href="#step-4">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Tax Clearance Certificate
                      <small
                        class="text-ellipsis"
                      >Upload Valid Tax Clearance Certificate</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-2 col-sm-3 col-6">
                  <a href="#step-5">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Vehicle licence and registration
                      <small
                        class="text-ellipsis"
                      >Valid vehicle licence and registration</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-2 col-sm-3 col-6">
                  <a href="#step-6">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Letter From Taxi Association
                      <small
                        class="text-ellipsis"
                      >Valid Letter From Taxi Association</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-2 col-sm-3 col-6">
                  <a href="#step-7">
                    <span class="number">4</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Complete Registration</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <fieldset>

                    <div class="d-flex">
<a class="w-60px" href="javascript:;">
<img src="https://www.taxiassociations.co.za/img/blacklisted-min.jpeg" alt="" class="mw-50 rounded">
</a>
<div class="ps-3 flex-1">
<h5 class="mb-2">Sanral Prepaid Prepaid eTag</h5>
<p class="mb-1">How It Works</p>
<p class="mb-1">
  <ol>
  <li>Lorem ipsum dolor sit amet</li>
  <li>Consectetur adipiscing elit</li>
</ol>
</p>
</div>
</div>
<hr class="bg-gray-500"/>
<br/>

                    <div class="alert alert-danger" v-if="percent != null"></div>
                    <!-- begin row -->
                    <div class="row">
                       <!-- begin col-6 -->
                      <div class="col-lg-6">
                        <div class="form-group row">
                          <label class="col-md-4 col-form-label text-md-right">
                            Select Taxi Operator
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              id="selectoperatorpicker"
                              data-size="10"
                              data-live-search="true"
                              data-parsley-required="true"
                            >
                              <option value="0" selected>Select Operator</option>
                              <option
                                :key="item.taxi_operator_id"
                                v-for="item in GETOPERATORS"
                                :value="item.taxi_operator_id"
                              >
                                {{
                                item.firstname +
                                " " +
                                item.surname +
                                "(" +
                                item.id_number +
                                ")"
                                }}
                              </option>
                            </select>
                          </div>
                        </div>
                        

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Display Name
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              id="displayname"
                              name="displayname"
                              data-parsley-group="step-1"
                              data-parsley-required="false"
                              class="form-control"
                              v-model="ADDTAXIVEHICLE.displayname"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Tara
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              id="tara"
                              name="tara"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              data-parsley-type="alphanum"
                              class="form-control"
                              v-model="ADDTAXIVEHICLE.tara"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Vehicle Reg No.
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="LWE564W"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              data-parsley-type="alphanum"
                              class="form-control"
                              v-model="ADDTAXIVEHICLE.vehicle_register_number"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label
                            class="col-md-4 col-form-label text-md-right"
                          >License Disc Expiry Date</label>
                          <span class="text-danger"></span>
                          <div class="col-md-6">
                            <div
                              class="input-group date"
                              id="licence_expiry_date"
                              data-date-format="dd M yyyy"
                            >
                              <input
                                type="text"
                                class="form-control licence_expiry_date"
                                placeholder="Select Date"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                              />
                              <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- end form-group -->

                        

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label
                            class="col-md-4 col-form-label text-md-right"
                          >Operating License Number</label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholer="EN5444TGDV54C"
                              data-parsley-group="step-1"
                              data-parsley-required="false"
                              class="form-control"
                              v-model="ADDTAXIVEHICLE.operatinglicense_number"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->


                    

                     
                      </div>
                      <!-- begin col-6 -->
                      <div class="col-lg-6">

                        
                        <div class="form-group row">
                          <label class="col-md-4 col-form-label text-md-right">
                            Select Taxi Operator
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              id="selectoperatorpicker"
                              data-size="10"
                              data-live-search="true"
                              data-parsley-required="true"
                            >
                              <option value="0" selected>Select Operator</option>
                              <option
                                :key="item.taxi_operator_id"
                                v-for="item in GETOPERATORS"
                                :value="item.taxi_operator_id"
                              >
                                {{
                                item.firstname +
                                " " +
                                item.surname +
                                "(" +
                                item.id_number +
                                ")"
                                }}
                              </option>
                            </select>
                          </div>
                        </div>
                        

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Display Name
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              id="displayname"
                              name="displayname"
                              data-parsley-group="step-1"
                              data-parsley-required="false"
                              class="form-control"
                              v-model="ADDTAXIVEHICLE.displayname"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Tara
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              id="tara"
                              name="tara"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              data-parsley-type="alphanum"
                              class="form-control"
                              v-model="ADDTAXIVEHICLE.tara"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Vehicle Reg No.
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="LWE564W"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              data-parsley-type="alphanum"
                              class="form-control"
                              v-model="ADDTAXIVEHICLE.vehicle_register_number"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label
                            class="col-md-4 col-form-label text-md-right"
                          >License Disc Expiry Date</label>
                          <span class="text-danger"></span>
                          <div class="col-md-6">
                            <div
                              class="input-group date"
                              id="licence_expiry_date"
                              data-date-format="dd M yyyy"
                            >
                              <input
                                type="text"
                                class="form-control licence_expiry_date"
                                placeholder="Select Date"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                              />
                              <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- end form-group -->

                        

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label
                            class="col-md-4 col-form-label text-md-right"
                          >Operating License Number</label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholer="EN5444TGDV54C"
                              data-parsley-group="step-1"
                              data-parsley-required="false"
                              class="form-control"
                              v-model="ADDTAXIVEHICLE.operatinglicense_number"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->


                    

                     
                      </div>
                      <div class="col-lg-6">
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Engine Number
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              value="EN54445454154"
                              id="reg_number"
                              name="reg_number"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              class="form-control"
                              v-model="ADDTAXIVEHICLE.engine_number"
                            />
                          </div>
                        </div>
                        <!-- begin form-group -->
                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Vehicle Joined Date
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <div
                              class="input-group date"
                              id="vehicle_joined_date"
                              data-date-format="dd M yyyy"
                            >
                              <input
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                type="text"
                                class="form-control"
                                placeholder="Select Date"
                              />
                              <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Routes
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              id="selecttaxiroute"
                              data-parsley-required="true"
                              data-parsley-group="step-1"
                            >
                              <option
                                v-bind:key="item._id"
                                v-for="item in ASSOCIATIONROUTES"
                                :value="item._id"
                              >{{item._name}}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->

                      

                        <div class="form-group row m-b-10">
                          <label
                            class="col-md-4 col-form-label text-md-right"
                          >Operator License Issue Date</label>
                          <span class="text-danger"></span>
                          <div class="col-md-6">
                            <div
                              class="input-group date"
                              id="date_of_birth"
                              data-date-format="dd M yyyy"
                            >
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Select Date"
                                data-parsley-group="step-1"
                              />
                              <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>

                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Operator License Expiry Date
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <div
                              class="input-group date"
                              id="operatorlicense_expiry_date"
                              data-date-format="dd M yyyy"
                            >
                              <input
                                data-parsley-group="step-1"
                                type="text"
                                class="form-control"
                                placeholder="Select Date"
                              />
                              <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">Cross Border</label>
                          <div class="col-md-6">
                            <div class="switcher switcher-success">
                              <input
                                type="checkbox"
                                name="switcher_checkbox_hasoverdraft"
                                id="switcher_checkbox_hasoverdraft"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="ADDTAXIVEHICLE.cross_border"
                              />
                              <label for="switcher_checkbox_hasoverdraft"></label>
                            </div>
                          </div>
                        </div>

                        <br />
                      </div>
                    </div>
                    <br />
                    <!--Start Table!-->
                    <div
                      class="panel panel-inverse panel-primary"
                      data-sortable-id="table-basic-7"
                      v-show="SELECTEDOPERATORS.firstname"
                    >
                      <!-- begin panel-heading -->
                      <div class="panel-heading ui-sortable-handle">
                        <h4 class="panel-title">Owner Details</h4>
                      </div>
                      <!-- end panel-heading -->
                      <!-- begin panel-body -->
                      <div class="panel-body">
                        <!-- begin table-responsive -->
                        <div class="table-responsive">
                          <!--Table-->
                          <table class="table">
                            <!--Table head-->
                            <thead>
                              <tr>
                                <th class="th-lg">Name Surname</th>
                                <th class="th-lg">ID Number</th>
                                <th class="th-lg">Membership Number</th>
                                <th class="th-lg">Number Of Taxis</th>
                              </tr>
                            </thead>
                            <!--Table head-->

                            <!--Table body-->
                            <tbody>
                              <tr>
                                <td>
                                  {{
                                  SELECTEDOPERATORS.firstname +
                                  " " +
                                  SELECTEDOPERATORS.surname
                                  }}
                                </td>
                                <td>{{ SELECTEDOPERATORS.id_number }}</td>
                                <td>{{ SELECTEDOPERATORS.membership_number }}</td>
                                <td>
                                  {{
                                  SELECTEDOPERATORS.current_number_vehicles
                                  }}
                                </td>
                              </tr>
                            </tbody>
                            <!--Table body-->
                          </table>
                          <!--Table-->
                        </div>
                      </div>
                      <!-- end panel-body -->
                    </div>
                    <br />
                    <!-- <div class="col-md-12">
                      <div class="card-group">
                        <div :key="item" v-for="item in VEHICLEIMAGES" class="card height-20">
                          <img
                            class="width:40px; height:20px;"
                            :src="`data:image/png;base64,${item.data}`"
                            alt="Card image cap"
                          />
                        </div>
                      </div>
                    </div>-->
                    <div class="col-lg-9 p-t-20" v-if="id!=':id'">
                      <div id="gallery" class="gallery m-r-40 pull-right">
                        <!-- begin image -->
                        <div class="image width-full height-400">
                          <div class="image-inner">
                            <a :href="VEHICLEIMAGES" data-lightbox="gallery-group-2">
                              <img :src="VEHICLEIMAGES" class="height-400" />
                            </a>
                          </div>
                        </div>
                        <!-- end image -->
                      </div>
                    </div>
                  </fieldset>
                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->

                <!-- begin step-2 -->
                <div id="step-2">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->

                    <p class="text-center h4 mb-4">Upload Supporting Documents</p>
                    <div class="panel panel-primary" data-sortable-id="form-dropzone-1">
                      <div class="panel-heading">
                        <div class="panel-heading-btn"></div>
                        <h4 class="panel-title">Drop Files In Here</h4>
                      </div>
                      <div class="panel-body text-inverse">
                        <DropZoneControl
                          :acceptedFiles="acceptedFiles"
                          :ClearData="true"
                          v-on:fileadded="fileadded"
                          :SessionID="DropZoneSessionID"
                        ></DropZoneControl>
                      </div>
                    </div>
                    <br />
                    <!--End dropzone!-->

                    <br />
                    <!--Start Table!-->
                    <div class="panel panel-primary" data-sortable-id="table-basic-7">
                      <!-- begin panel-heading -->
                      <div class="panel-heading ui-sortable-handle">
                        <h4 class="panel-title">Uploaded Files</h4>
                      </div>
                      <!-- end panel-heading -->
                      <!-- begin panel-body -->
                      <div class="panel-body">
                        <!-- begin table-responsive -->
                        <div class="table-responsive">
                          <table class="table table-striped m-b-0">
                            <thead>
                              <tr>
                                <th>Filename</th>
                                <th>Type</th>
                                <th>Size</th>
                                <th width="40%">Document Type</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr :key="item" v-for="(item) in GETUPLOADEDFILES">
                                <td>{{item.filename}}</td>
                                <td>{{item.mimetype}}</td>
                                <td>{{item.size}}</td>
                                <td>
                                  <select
                                    class="form-control col-sm-4"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    data-parsley-required="true"
                                    v-model="item.customType"
                                  >
                                    <option value="0" selected>Select Document Type</option>
                                    <option
                                      v-bind:key="item._id"
                                      v-for="item in GETALLDOCUMENTTYPE"
                                      :value="item._id"
                                    >{{item._name}}</option>
                                  </select>
                                </td>
                                <td class="with-btn" nowrap>
                                  <a
                                    href="#"
                                    @click="deletefile(context,item.fileId, USERPROFILE.system_usersid)"
                                    class="btn btn-sm btn-danger width-60"
                                  >Delete</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end table-responsive -->
                      </div>
                      <!-- end panel-body -->
                      <!-- begin hljs-wrapper -->
                    </div>
                    <div class="alert alert-secondary fade show">
                      <div class="text-center">
                        <!-- <button type="submit" class="btn btn-outline-secondary">Save To Drafts</button> -->
                      </div>
                    </div>
                    <!-- end row -->
                  </fieldset>
                </div>
                <!-- end step-2 -->

                 <!-- begin step-3 -->
                 <div id="step-3">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->

                    <p class="text-center h4 mb-4">Upload Supporting Documents</p>
                    <div class="panel panel-primary" data-sortable-id="form-dropzone-1">
                      <div class="panel-heading">
                        <div class="panel-heading-btn"></div>
                        <h4 class="panel-title">Drop Files In Here</h4>
                      </div>
                      <div class="panel-body text-inverse">
                        <DropZoneControl
                          :acceptedFiles="acceptedFiles"
                          :ClearData="true"
                          v-on:fileadded="fileadded"
                          :SessionID="DropZoneSessionID"
                        ></DropZoneControl>
                      </div>
                    </div>
                    <br />
                    <!--End dropzone!-->

                    <br />
                    <!--Start Table!-->
                    <div class="panel panel-primary" data-sortable-id="table-basic-7">
                      <!-- begin panel-heading -->
                      <div class="panel-heading ui-sortable-handle">
                        <h4 class="panel-title">Uploaded Files</h4>
                      </div>
                      <!-- end panel-heading -->
                      <!-- begin panel-body -->
                      <div class="panel-body">
                        <!-- begin table-responsive -->
                        <div class="table-responsive">
                          <table class="table table-striped m-b-0">
                            <thead>
                              <tr>
                                <th>Filename</th>
                                <th>Type</th>
                                <th>Size</th>
                                <th width="40%">Document Type</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr :key="item" v-for="(item) in GETUPLOADEDFILES">
                                <td>{{item.filename}}</td>
                                <td>{{item.mimetype}}</td>
                                <td>{{item.size}}</td>
                                <td>
                                  <select
                                    class="form-control col-sm-4"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    data-parsley-required="true"
                                    v-model="item.customType"
                                  >
                                    <option value="0" selected>Select Document Type</option>
                                    <option
                                      v-bind:key="item._id"
                                      v-for="item in GETALLDOCUMENTTYPE"
                                      :value="item._id"
                                    >{{item._name}}</option>
                                  </select>
                                </td>
                                <td class="with-btn" nowrap>
                                  <a
                                    href="#"
                                    @click="deletefile(context,item.fileId, USERPROFILE.system_usersid)"
                                    class="btn btn-sm btn-danger width-60"
                                  >Delete</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end table-responsive -->
                      </div>
                      <!-- end panel-body -->
                      <!-- begin hljs-wrapper -->
                    </div>
                    <div class="alert alert-secondary fade show">
                      <div class="text-center">
                        <!-- <button type="submit" class="btn btn-outline-secondary">Save To Drafts</button> -->
                      </div>
                    </div>
                    <!-- end row -->
                  </fieldset>
                </div>
                <!-- end step-3 -->

                 <!-- begin step-4 -->
                  <div id="step-4">
                    <!-- begin fieldset -->
                    <fieldset>
                      <!-- begin row -->

                      <p class="text-center h4 mb-4">Upload Supporting Documents</p>
                      <div class="panel panel-primary" data-sortable-id="form-dropzone-1">
                        <div class="panel-heading">
                          <div class="panel-heading-btn"></div>
                          <h4 class="panel-title">Drop Files In Here</h4>
                        </div>
                        <div class="panel-body text-inverse">
                          <DropZoneControl
                            :acceptedFiles="acceptedFiles"
                            :ClearData="true"
                            v-on:fileadded="fileadded"
                            :SessionID="DropZoneSessionID"
                          ></DropZoneControl>
                        </div>
                      </div>
                      <br />
                      <!--End dropzone!-->

                      <br />
                      <!--Start Table!-->
                      <div class="panel panel-primary" data-sortable-id="table-basic-7">
                        <!-- begin panel-heading -->
                        <div class="panel-heading ui-sortable-handle">
                          <h4 class="panel-title">Uploaded Files</h4>
                        </div>
                        <!-- end panel-heading -->
                        <!-- begin panel-body -->
                        <div class="panel-body">
                          <!-- begin table-responsive -->
                          <div class="table-responsive">
                            <table class="table table-striped m-b-0">
                              <thead>
                                <tr>
                                  <th>Filename</th>
                                  <th>Type</th>
                                  <th>Size</th>
                                  <th width="40%">Document Type</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr :key="item" v-for="(item) in GETUPLOADEDFILES">
                                  <td>{{item.filename}}</td>
                                  <td>{{item.mimetype}}</td>
                                  <td>{{item.size}}</td>
                                  <td>
                                    <select
                                      class="form-control col-sm-4"
                                      data-size="10"
                                      data-live-search="true"
                                      data-parsley-group="step-1"
                                      data-parsley-required="true"
                                      v-model="item.customType"
                                    >
                                      <option value="0" selected>Select Document Type</option>
                                      <option
                                        v-bind:key="item._id"
                                        v-for="item in GETALLDOCUMENTTYPE"
                                        :value="item._id"
                                      >{{item._name}}</option>
                                    </select>
                                  </td>
                                  <td class="with-btn" nowrap>
                                    <a
                                      href="#"
                                      @click="deletefile(context,item.fileId, USERPROFILE.system_usersid)"
                                      class="btn btn-sm btn-danger width-60"
                                    >Delete</a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <!-- end table-responsive -->
                        </div>
                        <!-- end panel-body -->
                        <!-- begin hljs-wrapper -->
                      </div>
                      <div class="alert alert-secondary fade show">
                        <div class="text-center">
                          <!-- <button type="submit" class="btn btn-outline-secondary">Save To Drafts</button> -->
                        </div>
                      </div>
                      <!-- end row -->
                    </fieldset>
                  </div>
                <!-- end step-4 -->

                 <!-- begin step-5 -->
                 <div id="step-5">
                    <!-- begin fieldset -->
                    <fieldset>
                      <!-- begin row -->

                      <p class="text-center h4 mb-4">Upload Supporting Documents</p>
                      <div class="panel panel-primary" data-sortable-id="form-dropzone-1">
                        <div class="panel-heading">
                          <div class="panel-heading-btn"></div>
                          <h4 class="panel-title">Drop Files In Here</h4>
                        </div>
                        <div class="panel-body text-inverse">
                          <DropZoneControl
                            :acceptedFiles="acceptedFiles"
                            :ClearData="true"
                            v-on:fileadded="fileadded"
                            :SessionID="DropZoneSessionID"
                          ></DropZoneControl>
                        </div>
                      </div>
                      <br />
                      <!--End dropzone!-->

                      <br />
                      <!--Start Table!-->
                      <div class="panel panel-primary" data-sortable-id="table-basic-7">
                        <!-- begin panel-heading -->
                        <div class="panel-heading ui-sortable-handle">
                          <h4 class="panel-title">Uploaded Files</h4>
                        </div>
                        <!-- end panel-heading -->
                        <!-- begin panel-body -->
                        <div class="panel-body">
                          <!-- begin table-responsive -->
                          <div class="table-responsive">
                            <table class="table table-striped m-b-0">
                              <thead>
                                <tr>
                                  <th>Filename</th>
                                  <th>Type</th>
                                  <th>Size</th>
                                  <th width="40%">Document Type</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr :key="item" v-for="(item) in GETUPLOADEDFILES">
                                  <td>{{item.filename}}</td>
                                  <td>{{item.mimetype}}</td>
                                  <td>{{item.size}}</td>
                                  <td>
                                    <select
                                      class="form-control col-sm-4"
                                      data-size="10"
                                      data-live-search="true"
                                      data-parsley-group="step-1"
                                      data-parsley-required="true"
                                      v-model="item.customType"
                                    >
                                      <option value="0" selected>Select Document Type</option>
                                      <option
                                        v-bind:key="item._id"
                                        v-for="item in GETALLDOCUMENTTYPE"
                                        :value="item._id"
                                      >{{item._name}}</option>
                                    </select>
                                  </td>
                                  <td class="with-btn" nowrap>
                                    <a
                                      href="#"
                                      @click="deletefile(context,item.fileId, USERPROFILE.system_usersid)"
                                      class="btn btn-sm btn-danger width-60"
                                    >Delete</a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <!-- end table-responsive -->
                        </div>
                        <!-- end panel-body -->
                        <!-- begin hljs-wrapper -->
                      </div>
                      <div class="alert alert-secondary fade show">
                        <div class="text-center">
                          <!-- <button type="submit" class="btn btn-outline-secondary">Save To Drafts</button> -->
                        </div>
                      </div>
                      <!-- end row -->
                    </fieldset>
                  </div>
                <!-- end step-5 -->

                <!-- begin step-6 -->
                    <div id="step-6">
                    <!-- begin fieldset -->
                    <fieldset>
                      <!-- begin row -->

                      <p class="text-center h4 mb-4">Upload Supporting Documents</p>
                      <div class="panel panel-primary" data-sortable-id="form-dropzone-1">
                        <div class="panel-heading">
                          <div class="panel-heading-btn"></div>
                          <h4 class="panel-title">Drop Files In Here</h4>
                        </div>
                        <div class="panel-body text-inverse">
                          <DropZoneControl
                            :acceptedFiles="acceptedFiles"
                            :ClearData="true"
                            v-on:fileadded="fileadded"
                            :SessionID="DropZoneSessionID"
                          ></DropZoneControl>
                        </div>
                      </div>
                      <br />
                      <!--End dropzone!-->

                      <br />
                      <!--Start Table!-->
                      <div class="panel panel-primary" data-sortable-id="table-basic-7">
                        <!-- begin panel-heading -->
                        <div class="panel-heading ui-sortable-handle">
                          <h4 class="panel-title">Uploaded Files</h4>
                        </div>
                        <!-- end panel-heading -->
                        <!-- begin panel-body -->
                        <div class="panel-body">
                          <!-- begin table-responsive -->
                          <div class="table-responsive">
                            <table class="table table-striped m-b-0">
                              <thead>
                                <tr>
                                  <th>Filename</th>
                                  <th>Type</th>
                                  <th>Size</th>
                                  <th width="40%">Document Type</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr :key="item" v-for="(item) in GETUPLOADEDFILES">
                                  <td>{{item.filename}}</td>
                                  <td>{{item.mimetype}}</td>
                                  <td>{{item.size}}</td>
                                  <td>
                                    <select
                                      class="form-control col-sm-4"
                                      data-size="10"
                                      data-live-search="true"
                                      data-parsley-group="step-1"
                                      data-parsley-required="true"
                                      v-model="item.customType"
                                    >
                                      <option value="0" selected>Select Document Type</option>
                                      <option
                                        v-bind:key="item._id"
                                        v-for="item in GETALLDOCUMENTTYPE"
                                        :value="item._id"
                                      >{{item._name}}</option>
                                    </select>
                                  </td>
                                  <td class="with-btn" nowrap>
                                    <a
                                      href="#"
                                      @click="deletefile(context,item.fileId, USERPROFILE.system_usersid)"
                                      class="btn btn-sm btn-danger width-60"
                                    >Delete</a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <!-- end table-responsive -->
                        </div>
                        <!-- end panel-body -->
                        <!-- begin hljs-wrapper -->
                      </div>
                      <div class="alert alert-secondary fade show">
                        <div class="text-center">
                          <!-- <button type="submit" class="btn btn-outline-secondary">Save To Drafts</button> -->
                        </div>
                      </div>
                      <!-- end row -->
                    </fieldset>
                  </div>
                <!-- end step-6 -->

                <!-- begin step-6 -->
                <div id="step-7">
                  <div class="jumbotron m-b-0 text-center">
                    <h2 class="display-4">Taxi Vehicle Has been Successfully Added</h2>
                    <p v-show="false" class="lead mb-4">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Mauris consequat commodo porttitor.
                      <br />Vivamus eleifend, arcu in tincidunt semper, lorem
                      odio molestie lacus, sed malesuada est lacus ac ligula.
                      Aliquam bibendum felis id purus ullamcorper, quis luctus
                      leo sollicitudin.
                    </p>

                    <br />
                    <br />

                    <p>
                      <router-link
                        to="/Vehicles/List"
                        tag="button"
                        v-bind:class="{ 'btn btn-primary btn-lg': true }"
                      >Proceed To Taxi Operator Vehicles</router-link>
                    </p>
                    <p class="text-center">
                      <qr-code error-level="L" :text="context.vehicleQRCode"></qr-code>
                    </p>
                  </div>
                </div>
                <!-- end step-6 -->
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>

        <!-- end col-12 -->
      </div>
      </div>

      <!-- begin row -->

       <!-- begin tab-pane -->
       <div class="tab-pane fade" id="nav-pills-tab-2">
        <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <div class="table-responsive">
            <table class="table table-invoice">
              <thead>
                <tr>
                  <th>Owner Full Name</th>
                  <th>Mobile No</th>
                  <th>License Plate</th>
                  <th>License Fee</th>
                  <th>Expiry Date</th>
                  <th>Admin Fees</th>
                  <th>Delivery Fee</th>
                  <th>Applcation Date</th>
                  <th>Date Delivered</th>
                  <th>Status</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item) in GETALL"
                  v-bind:key="item.id"
                >
                  <td>{{item.fueltypes_desc}}</td>
                  <td>{{item.fuel_price | newcurrencywithSymbol}}</td>
                  <td>{{item.vatpercentage | newcurrencywithSymbol}}</td>
                  <td>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.is_active, 'label-secondary':!item.is_active  }"
                      >{{item.rebatefeepercentage}}%</span>
                      </td>

                  <td>{{item.fueltypes_code}}</td>
                  <td class="with-btn valign-middle" nowrap>
                      <div class="btn-group" role="group" aria-label="Basic example">
                      <button v-if="!item.is_active && item.deleted_by===null" type="button" @click="EnableDisableAccount(context,item,true)" class="btn btn-danger"
                        :disabled="$wait.is('UpdateNextOfKinAction-' +item.id)">
                 <v-wait v-bind:for="'UpdateNextOfKinAction-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                   Delete
                  </v-wait>
                        
                    </button> 

                    <button v-if="item.is_active" type="button" @click="Edit(context,item)" class="btn btn-info"
                      :disabled="$wait.is('UpdateNextOfKinAction-' +item.id)"
                    >
                 <v-wait v-bind:for="'UpdateNextOfKinAction-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Edit
                  </v-wait>
                        
                    </button> 
                  </div>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
        </div>

      </div>
        <!-- end tab-pane -->

      

      <!-- end row -->

  

      <!-- begin #Main Body Template -->

      

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("fuelmanager_dashboard");

export default {
  name: "AddSystemUser",
  data: function() {
    return {
      SELECTEDOPERATORS: {},
      percent: null,
      ADDTAXIVEHICLE: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        taxi_operator_id: 0,
        vehiclemodel_id: 0,
        displayname: "",
        vin_number: "",
        licence_number: "",
        vehicle_register_number: "",
        added_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
        tara: "",
        engine_number: "",
        vehicle_joined_date: "",
        vehicle_color_id: 0,
        vehicle_engine_id: 0,
        vehicle_transmission_type_id: 0,
        licence_expiry_date: "",
        taxi_association_routes_id: 0,
        dropzoneid: "",
        dropzone_files: [
          {
            customType: 0,
            fileId: "",
          },
        ],
        datacollection_id: this.$uuid.v4(),
        operatinglicense_issuedate: "",
        operatinglicense_expirydate: "",
        operatinglicense_number: "",
        cross_border: false,
        vehicle_finance_institution_id: 0,
        vehicle_insurance_institution_id: 0,
        vehicle_tracker_institution_id: 0,
        operating_route_ids: [],
        GETVEHICLEROUTES: [],
        GETFINANCEPROVIDERS: [],
        GETFINSURANCEPROVIDERS: [],
        GETTRACKERPROVIDERS: [],
      },
      GETOPERATORS:[],
      TAXIAFFILIATION:[],
      GETALLSUBURBS:[],
      BILLINGTYPE:[],
      GETBILLINGTYPES:[],
      BANKS:[],
      TPAYPROFILE: this.$store.getters["auth/TPAYPROFILE"],
      isUserNameExists: true,
      ISMOBILEVERIFIED:false,
      GETDRIVERSBYASSID:[],
      GETALL:[],
      ASSOCIATIONRANKS:[],
      ASSOCIATIONROUTES:[],
      OPT:"",
      USERNAME: "",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      SERPROFILE: this.$store.getters["auth/USERPROFILE"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      selectedUserGroupId: 0,
      GETALLUSERGROUPS: [],
      resolutionWidth: undefined,
      resolutionHeight: undefined,
      hacksmartwizard: false,
      SELECTEDRANK: null,
      SELECTEDTAMSRANK: null,
      EDITMODE:false,
      TAXIFARE: 0,
      ADDSYSTEMUSER: {
        taxi_association_id:this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
        fueldescription: "",
        vatpercentage: 15.00,
        fuelprice:0.00,
        rebatefeepercentage:0.00,
        fuelcode: "",
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
    },
    EDITSYSTEMUSER: {
        id:0,
        fueldescription: "",
        vatpercentage: 15.00,
        fuelprice:0.00,
        rebatefeepercentage:0.00,
        fuelcode: "",
        updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
    },
    VERIFIY: {
        user_id: "",
        webpushtoken: "",
        one_time_pin: "",
      },
      ADDTAXIASSOCIATIONS: Object.assign(
        {},
        this.$store.getters["TA/ADDTAXIASSOCIATIONS"]
      ),
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    CapturePhotosControl
  },
  computed: {
    ...mapGetters(["GETALLTITLES", "GETALLGENDERS"])
  },
  watch: {
    USERNAME: function(val) {
      if (val.toString().length > 3) {
        this.context.$store
          .dispatch("tpayauth/LicenseNoExists", {
            licencenumber: val
          })
          .then(rb => {
            
            this.isUserNameExists = rb.Status;
          });
      }
    },
    VINNUMBER: function (val) {
      if (val.toString().length > 3) {
        this.context.$store
          .dispatch("OperatorVehicle/searchVehicleVINnumber", {
            _getPagingModel: {
              id: this.taxiassociationid,
              text: val,
            },
            router: this.$router,
          })
          .then((rb) => {
            this.isVehicleExists = rb.data[0].fn_taxi_operator_vehicleexists;
          });
      }
    },
  },
  methods: {
    ...mapActions([
      "getall",
      "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted"
    ]),
    init: file => {},
    Edit: (context,item) => {
      context.EDITSYSTEMUSER.id = item.id;
      context.ADDSYSTEMUSER.fueldescription = item.fueltypes_desc;
      context.ADDSYSTEMUSER.vatpercentage = item.vatpercentage;
      context.ADDSYSTEMUSER.fuelprice = item.fuel_price;
      context.ADDSYSTEMUSER.rebatefeepercentage = item.rebatefeepercentage;
      context.ADDSYSTEMUSER.fuelcode = item.fueltypes_code;
      context.EDITMODE = true;

      $("#wizard").smartWizard("prev");
      $(".sw-btn-next").text("Edit")
      $('.nav-pills-tab-1').click();
    },
    GetALLRoutesById: (context,id) => {
      context.$store
        .dispatch("fuelmanager_dashboard/getFuelTypeByTaxiAssId", {
          id,
          getbyid: context.USERID,
           router: context.$router,
        })
        .then((rb) => {

          

          context.GETALL = rb.data
          
        })
        .finally((error)=>{
          var options = {
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false,
                    order: [[0, 'asc']]
                  };

                  // if ($(window).width() <= 767) {
                  //   options.rowReorder = false;
                  //   options.colReorder = false;
                  //   options.autoFill = false;
                  // }

 	              $(".table-invoice").DataTable(options);
        })
    },
    getbyAssId: context => {
      context.$store
      .dispatch("tpay_systemuser/GetALLRANKSById", {
          id: context.taxi_association_id,
        })
        .then((data) => {
          context.ASSOCIATIONRANKS = data.Data;
          
        }).catch((error)=>{
          console.log("these are the getter errors:",error);
        })
        .finally((error)=>{
           $('#data-table-default').DataTable({
			responsive: true
		});
        })
    },
    getRoutes: context => {
      context.$store
      .dispatch("TA/getTaxiAssRoutes", {
        router: context.$router,
          id: context.taxi_association_id,
        })
        .then((data) => {
          context.ASSOCIATIONROUTES = data;
          
        }).catch((error)=>{
          console.log("these are the getter errors:",error);
        })
        .finally((error)=>{
           $('#data-table-default').DataTable({
			responsive: true
		});
        })
    },
  },
  mounted: function() {
    //this.get(this.context);

    App.init();

    this.getbyAssId(this.context);
    this.GetALLRoutesById(this.context,this.taxi_association_id);

    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "slide",
      transitionSpeed: 400,
      autoAdjustHeight: true,
      useURLhash: false,
      justified: true,
      showStepURLhash: false,
      toolbarSettings: {
        toolbarPosition: "bottom"
      }
    });

    $("#wizard").data("context", this.context);

    $("#wizard").on("leaveStep", function(
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {
     
      const context = $("#wizard").data("context");
      const currentstep = stepNumber + 1;
      var res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

        if (currentstep === 2 && stepDirection == "backward") {
       
                context.hacksmartwizard = false;
                 $(".sw-btn-next").text("Next") 
        }


        if(context.hacksmartwizard)
        return true;


      $(".sw-btn-next").text("Next");

      if (currentstep === 1 && stepDirection == "forward") {
        
        if (!res){
          return res;
        }
      
          $(".sw-btn-next").text("Submit");
          
      }

      

      
      if (currentstep === 1 && stepDirection == "forward") {

           const context = $("#wizard").data("context");

           if(context.EDITMODE){

            context.ADDSYSTEMUSER.vatpercentage = parseFloat(context.ADDSYSTEMUSER.vatpercentage)
           context.ADDSYSTEMUSER.rebatefeepercentage = parseFloat(context.ADDSYSTEMUSER.rebatefeepercentage)
           context.ADDSYSTEMUSER.fuelprice = parseFloat(context.ADDSYSTEMUSER.fuelprice)

           context.EDITSYSTEMUSER.fueldescription = context.ADDSYSTEMUSER.fueldescription;
          context.EDITSYSTEMUSER.vatpercentage = context.ADDSYSTEMUSER.vatpercentage;
          context.EDITSYSTEMUSER.fuelprice = context.ADDSYSTEMUSER.fuelprice;
          context.EDITSYSTEMUSER.rebatefeepercentage = context.ADDSYSTEMUSER.rebatefeepercentage;
          context.EDITSYSTEMUSER.fuelcode = context.ADDSYSTEMUSER.fuelcode;
          
           

        context.$Notify
          .YesNo(false, { context: context, item: context.EDITSYSTEMUSER })
          .then(value => {
            if (value) {
              $(".sw-btn-next")
                .text("")
                .append('<i class="fas fa-spinner fa-spin"></i>')
                .attr("disabled", "disabled");

              value.router = value.context.$router;
              value.context.$store
                .dispatch("fuelmanager_dashboard/EditFuelType", {id:value.item.id, AddModel:value.item})
                .then((parsedResult) => {
                  if(!parsedResult.status){
                    $(".sw-btn-next")
                    .text("Submit")
                    .removeAttr("disabled", "disabled");
                    value.context.$Notify.Error(parsedResult.message, "");
                    return
                  }
                   value.context.hacksmartwizard = true;
            $("#wizard").smartWizard("next");
                  $(".sw-btn-next")
                    .text("Done")
                    .removeAttr("disabled", "disabled");

                    value.context.EDITMODE = false;
                    value.context.GetALLRoutesById(value.context,value.context.taxi_association_id);
                    

                })
                .catch(error => {
                  $(".sw-btn-next")
                    .text("Submit")
                    .removeAttr("disabled", "disabled");

                  value.context.$Notify.Error(parsedResult.message, "");
                })
                .finally(() => {});
            }
          });

           } else {

        context.ADDSYSTEMUSER.vatpercentage = parseFloat(context.ADDSYSTEMUSER.vatpercentage)
           context.ADDSYSTEMUSER.rebatefeepercentage = parseFloat(context.ADDSYSTEMUSER.rebatefeepercentage)
           context.ADDSYSTEMUSER.fuelprice = parseFloat(context.ADDSYSTEMUSER.fuelprice)
          
           

        context.$Notify
          .YesNo(false, { context: context, item: context.ADDSYSTEMUSER })
          .then(value => {
            if (value) {
              $(".sw-btn-next")
                .text("")
                .append('<i class="fas fa-spinner fa-spin"></i>')
                .attr("disabled", "disabled");

              value.router = value.context.$router;
              value.context.$store
                .dispatch("fuelmanager_dashboard/AddFuelType", value.item)
                .then((parsedResult) => {
                  if(!parsedResult.status){
                    $(".sw-btn-next")
                    .text("Submit")
                    .removeAttr("disabled", "disabled");
                    value.context.$Notify.Error(parsedResult.message, "");
                    return
                  }
                   value.context.hacksmartwizard = true;
            $("#wizard").smartWizard("next");
                  $(".sw-btn-next")
                    .text("Done")
                    .removeAttr("disabled", "disabled");

                    value.context.GetALLRoutesById(value.context,value.context.taxi_association_id);

                })
                .catch(error => {
                  $(".sw-btn-next")
                    .text("Submit")
                    .removeAttr("disabled", "disabled");

                  value.context.$Notify.Error(parsedResult.message, "");
                })
                .finally(() => {});
            }
          });

        }

        return false;
      }

      return res;
    });

  }
};
</script>

<style scoped>
</style>

