<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'FleetManagement'"
      :ActiveMenu="'Bookings'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Bookings"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Fleet Management"
        breadcrumbitem3="Bookings"
      />
      <!-- end page-header -->

      <hr class="bg-grey-lighter" />

      <!-- begin vertical-box -->
      <div class="vertical-box">
        <!-- begin event-list -->
        <div
          class="vertical-box-column p-r-30 d-none d-lg-table-cell"
          v-if="showpredefinedevents"
          style="width: 215px"
        >
      </div>
        <!-- end event-list -->
        <!-- begin col-6 -->
        <div class="col-lg-12">
          <!-- begin #accordion -->
          <div id="accordion" class="card-accordion">
            <!-- begin card -->

            <div class="card collapseOne">
              <div
                class="card-header bg-black text-white pointer-cursor"
                data-toggle="collapse"
                data-target="#collapseOne"
              >Calendar</div>
              <div id="collapseOne" class="collapse show" data-parent="#accordion">
                <div class="card-body">
                  <div id="accordion" class="form-group row">
                    <!-- begin calendar -->
                    <div id="calendar" class="vertical-box-column calendar"></div>
                    <!-- end calendar -->
                  </div>
                </div>
              </div>
            </div>
            <!-- end card -->
            <!-- begin card -->
            <div class="card collapseTwo">
              <div
                class="card-header bg-black text-white pointer-cursor collapsed"
                data-toggle="collapse"
                data-target="#collapseTwo"
              >Add event</div>
              <div id="collapseTwo" class="collapse" data-parent="#accordion">
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <p class="text-center h4 mb-4">Event Details</p>
                  <!-- begin row -->
                  <div class="row">
                    <!-- begin col-8 -->

                    <div class="col-md-12">
                      <div class="row">
                        <!-- begin col-8 -->
                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-nowrap col-form-label text-md-right">
                              Title
                              <span class="text-danger">*</span>
                            </label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <input
                                type="text"
                                placeholder="Event Title"
                                id="title"
                                name="title"
                                data-parsley-required="true"
                                class="form-control pull-center"
                                v-model="ADDMODEL.name"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label
                              class="col-md-3 text-nowrap col-form-label text-md-right"
                            >Description</label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <input
                                type="text"
                                placeholder="Event Description"
                                id="description"
                                name="description"
                                data-parsley-required="false"
                                class="form-control pull-center"
                                v-model="ADDMODEL.description"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-nowrap col-form-label text-md-right">
                              Start Date:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <div
                                class="input-group date"
                                id="datepicker-disabled-past"
                                data-format="yyyy-MM-dd hh:mm:ss"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Start Date"
                                  v-model="ADDMODEL.start_date"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-nowrap col-form-label text-md-right">
                              End Date:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <div
                                class="input-group date"
                                id="datepicker-disabled-past2"
                                data-date-format="dd M yyyy"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="End Date"
                                  v-model="ADDMODEL.end_date"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label
                              class="col-md-3 text-nowrap col-form-label text-md-right"
                            >Schedule reminder date:</label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <div
                                class="input-group date"
                                id="datepicker-disabled-past3"
                                data-date-format="dd M yyyy"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Scheduled Reminder Date"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Message:</label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <!-- textarea -->
                              <textarea v-model="ADDMODEL.smsmessage" class="form-control" rows="3"></textarea>
                            </div>
                          </div>
                        </div>
                        <!-- end col-8 -->

                        <div class="col-lg-6">
                          <!-- begin form-group -->

                          <div class="form-group row m-b-10">
                            <label
                              class="col-md-3 text-nowrap col-form-label text-md-right"
                            >Choose Color:</label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <div class="input-group">
                                <select
                                  name="jquery-simplecolorpicker-fa-add"
                                  v-model="ADDMODEL.event_color"
                                >
                                  <option value="#2d353c">Black</option>
                                  <option value="#f0f3f4">Silver</option>
                                  <option value="#b6c2c9">Grey</option>
                                  <option value="#727cb6">Purple</option>
                                  <option value="#348fe2">Primary</option>
                                  <option value="#49b6d6">Aqua</option>
                                  <option value="#00acac">Green</option>
                                  <option value="#90ca4b">Lime</option>
                                  <option value="#f59c1a">Orange</option>
                                  <option value="#ffd900">Yellow</option>
                                  <option value="#ff5b57">Red</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label
                              class="col-md-3 text-nowrap col-form-label text-md-right"
                            >Select Drivers:</label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <select
                                class="default-selectDriver width-full default-select form-control col-md-20"
                                data-live-search="true"
                                data-size="5"
                                multiple="multiple"
                              >
                                <option
                                  v-for="item in GETALLDRIVERS"
                                  :key="item._taxi_drivers_id"
                                  :value="item._mobilenumber"
                                >{{item._firstname}} {{item._surname}} ({{item._mobilenumber | HumanizeMobile}})</option>
                              </select>
                              <input type="checkbox" id="checkbox" v-model="IsDriversSelected" /> Select All
                            </div>
                          </div>
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label
                              class="col-md-3 text-nowrap col-form-label text-md-right"
                            >Select Operators:</label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <select
                                class="default-selectOperator width-full default-select form-control"
                                data-size="5"
                                data-live-search="true"
                                multiple="multiple"
                              >
                                <option
                                  v-for="item in GETALLOPERATOR"
                                  :key="item.taxi_operator_id"
                                  :value="item.mobilenumber"
                                >{{item.firstname}} {{item.surname}} ({{item.mobilenumber | HumanizeMobile}})</option>
                              </select>
                              <input type="checkbox" id="checkbox" v-model="IsAllOperatorsSelected" /> Select All
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-nowrap col-form-label text-md-right">
                              Add Numbers:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <div class="input-group date">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="external"
                                  placeholder="Add number(s)"
                                  v-model="numbers"
                                  @keyup.enter.native="addNumbers(context,numbers)"
                                />
                                <span class="input-group-addon">
                                  <i class="fas fa-plus" @click="addNumbers(context,numbers)"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <table v-show="EXTERNALNUMBERS.length > 0" class="table">
                            <thead>
                              <tr>
                                <th class="text-nowrap">Mobile number</th>
                                <th class="text-nowrap">Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr
                                v-for="(item,index) in EXTERNALNUMBERS"
                                v-bind:value="item"
                                :key="item"
                                :class="index & 1 ? 'odd gradeA' : 'even'"
                              >
                                <td width="15%">{{item}}</td>

                                <td width="20%">
                                  <button
                                    type="button"
                                    class="btn btn-sm btn-danger m-r-2"
                                    @click="deleteNumber(context,item)"
                                  >Delete</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- end col-8 -->
                  </div>
                  <!-- end row -->

                  <div class="alert alert-white fade show">
                    <div class="text-center">
                      <button
                        type="button"
                        class="btn btn-primary btn-lg"
                        style="margin: 5px;"
                        @click="addEvent(context, ADDMODEL)"
                      >Save</button>
                      <button
                        type="button"
                        class="btn btn-white btn-lg"
                        sstyle="margin: 5px;"
                        @click="ShowOne"
                      >Cancel</button>
                    </div>
                  </div>
                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->
              </div>
            </div>
            <!-- end card -->
            <!-- begin card -->
            <div class="card collapseThree">
              <div
                class="card-header bg-black text-white pointer-cursor collapsed"
                data-toggle="collapse"
                data-target="#collapseThree"
              >Edit event</div>
              <div id="collapseThree" class="collapse" data-parent="#accordion">
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <p class="text-center h4 mb-4">Event Details</p>

                  <!-- begin row -->
                  <div class="row">
                    <!-- begin col-8 -->

                    <div class="col-md-12">
                      <div class="row">
                        <!-- begin col-8 -->
                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-nowrap col-form-label text-md-right">
                              Title
                              <span class="text-danger">*</span>
                            </label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <input
                                type="text"
                                placeholder="Event Title"
                                id="title"
                                name="title"
                                data-parsley-required="true"
                                class="form-control pull-center"
                                v-model="EventTitle"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label
                              class="col-md-3 text-nowrap col-form-label text-md-right"
                            >Description</label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <input
                                type="text"
                                placeholder="Event Description"
                                id="description"
                                name="description"
                                data-parsley-required="false"
                                class="form-control pull-center"
                                v-model="EventDetails"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-nowrap col-form-label text-md-right">
                              Start Date:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <div
                                class="input-group date"
                                id="datepicker-update-start"
                                data-format="yyyy-MM-dd hh:mm:ss"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Start Date"
                                  :value="EventStartDate | formatToLongDateTimeWithSeconds"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-nowrap col-form-label text-md-right">
                              End Date:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <div
                                class="input-group date"
                                id="datepicker-update-end"
                                data-date-format="dd M yyyy"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="End Date"
                                  :value="EventEndDate | formatToLongDateTimeWithSeconds"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label
                              class="col-md-3 text-nowrap col-form-label text-md-right"
                            >Schedule reminder date:</label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <div
                                class="input-group date"
                                id="datepicker-update-scheduled"
                                data-date-format="dd M yyyy"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Scheduled Reminder Date"
                                  :value="EventReminderDate | formatToLongDateTimeWithSeconds"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 col-form-label text-md-right">Message:</label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <!-- textarea -->
                              <textarea v-model="eventMessage" class="form-control" rows="3"></textarea>
                            </div>
                          </div>
                        </div>
                        <!-- end col-8 -->

                        <div class="col-lg-6">
                          <!-- begin form-group -->

                          <div class="form-group row m-b-10">
                            <label
                              class="col-md-3 text-nowrap col-form-label text-md-right"
                            >Choose Color:</label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <div class="input-group">
                                <select name="jquery-simplecolorpicker-fa" v-model="EventColor">
                                  <option value="#2d353c">Black</option>
                                  <option value="#f0f3f4">Silver</option>
                                  <option value="#b6c2c9">Grey</option>
                                  <option value="#727cb6">Purple</option>
                                  <option value="#348fe2">Primary</option>
                                  <option value="#49b6d6">Aqua</option>
                                  <option value="#00acac">Green</option>
                                  <option value="#90ca4b">Lime</option>
                                  <option value="#f59c1a">Orange</option>
                                  <option value="#ffd900">Yellow</option>
                                  <option value="#ff5b57">Red</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label
                              class="col-md-3 text-nowrap col-form-label text-md-right"
                            >Select Drivers:</label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <select
                                class="default-editselectDriver width-full default-select form-control col-md-12"
                                data-live-search="true"
                                data-size="5"
                              >
                                <option
                                  id="checkbox"
                                  v-for="item in GETALLDRIVERS"
                                  :key="item._taxi_drivers_id"
                                  :value="item._mobilenumber"
                                >{{item._firstname}} {{item._surname}} ({{item._mobilenumber | HumanizeMobile}})</option>
                              </select>
                              <input
                                type="checkbox"
                                id="checkbox"
                                @click="selectAllDrvivers(context)"
                              /> Select All
                            </div>
                          </div>
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label
                              class="col-md-3 text-nowrap col-form-label text-md-right"
                            >Select Operators:</label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <select
                                class="default-editselectOperator width-full default-select form-control col-md-10"
                                data-size="5"
                                data-live-search="true"
                              >
                                <option
                                  id="checkbox"
                                  v-for="item in GETALLOPERATOR"
                                  :key="item.taxi_operator_id"
                                  :value="item.mobilenumber"
                                >{{item.firstname}} {{item.surname}} ({{item.mobilenumber | HumanizeMobile}})</option>
                              </select>
                              <input type="checkbox" id="checkbox" /> Select All
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-nowrap col-form-label text-md-right">
                              Add Numbers:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="date text-inverse col-md-7 pull-center">
                              <div class="input-group date">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="external"
                                  placeholder="Add number(s)"
                                  v-model="numbers"
                                  @keyup.enter.native="updateNumbers(context,numbers)"
                                />
                                <span class="input-group-addon">
                                  <i class="fas fa-plus" @click="updateNumbers(context,numbers)"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <table v-show="eventNumbers.length > 0" class="table">
                            <thead>
                              <tr>
                                <th class="text-nowrap">Mobile number</th>
                                <th class="text-nowrap">Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr
                                v-for="(item,index) in eventNumbers"
                                v-bind:value="item"
                                :key="item"
                                :class="index & 1 ? 'odd gradeA' : 'even'"
                              >
                                <td width="15%">{{item}}</td>

                                <td width="100%">
                                  <button
                                    type="button"
                                    class="btn btn-sm btn-danger m-r-2"
                                    @click="deleteEventNumber(context,item)"
                                  >Delete</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- end col-8 -->
                  </div>
                  <!-- end row -->

                  <div class="alert alert-white fade show">
                    <div class="text-center">
                      <button
                        type="button"
                        @click="updateEvent(context, UPDATEMODEL)"
                        class="btn btn-primary btn-lg"
                      >Update</button>
                      <button
                        type="button"
                        style="margin: 5px;"
                        class="btn btn-danger btn-lg"
                        @click="deleteEvent(context, EventId, USERID)"
                      >Delete</button>
                      <button type="button" @click="ShowOne" class="btn btn-white btn-lg">Cancel</button>
                    </div>
                  </div>
                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->
              </div>
            </div>
            <!-- end card -->
          </div>
          <!-- end #accordion -->
        </div>
        <!-- end col-6 -->
      </div>
      <!-- end vertical-box -->
    </div>
    <!-- end #content -->
    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { setTimeout } from "timers";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("calendar");
// value.context.$emit("init");

export default {
  name: "Calendar",

  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    CapturePhotosControl
  },
  beforeMount: function() {
    this.getallEvents(this.context);
  },
  data: function() {
    return {
      showpredefinedevents: false,
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      // GETDRIVERSBYASSID: this.$store.getters["TaxiDrivers/GETDRIVERSBYASSID"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      numbers: "",
      EXTERNALNUMBERS: [],
      _scheduleDateConverted: "",
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      numberAdded: false,
      IsAllOperatorsSelected: false,
      IsDriversSelected: false,
      context: this,
      skip: 0,
      len: 10000,
      GETALLOPERATOR: [],
      GETALLDRIVERS: [],
      ADDMODEL: {
        taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        name: "",
        description: "",
        reminderdate: undefined,
        start_date: "",
        end_date: "",
        sms_batch_number_id: this.$uuid.v4(),
        sms_batch_reminder_id: this.$uuid.v4(),
        event_color: "",
        smsmessage: "",
        mobilenumbers: [
          {
            usernumber: ""
          }
        ],
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid
      },
      UPDATEMODEL: {},
      EventTitle: "",
      EventDetails: "",
      EventStartDate: "",
      EventEndDate: "",
      EventReminderDate: undefined,
      EventColor: "",
      EventId: 0,
      id: 1,
      externalId: this.$uuid.v4(),
      eventNumbers: [],
      eventMessage: "",
      operatorResults: [],
      driverResults: [],
      results: []
    };
  },

  computed: {
    ...mapGetters(["GETALL"]),
    // LoggedInUserFullName: function() {
    //   return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    // }
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  watch: {
    IsAllOperatorsSelected: function(val) {
      $(".default-selectOperator").select2({ disabled: val });
    },
    IsDriversSelected: function(val) {
      $(".default-selectDriver").select2({ disabled: val });
    },
    IsEditAllOperatorsSelected: function(val) {
      $(".default-editselectOperator").select2({ disabled: val });
    },
    IsEditDriversSelected: function(val) {
      $(".default-editselectDriver").select2({ disabled: val });
    }
  },
  methods: {
    ...mapActions(["getById", "getByTaxiAssId", "delete", "edit", "add"]),
    ShowOne: () => {
      $(".collapseThree")
        .removeClass("collapse in")
        .hide();
      $(".collapseTwo")
        .removeClass("collapse in")
        .hide();

      $(".collapseOne")
        .show()
        .addClass("collapse in")
        .collapse("show");
      $("#collapseOne").collapse("show");
    },
    ShowTwo: () => {
      $(".collapseThree")
        .removeClass("collapse in")
        .hide();
      $(".collapseOne")
        .removeClass("collapse in")
        .hide();

      $(".collapseTwo")
        .show()
        .addClass("collapse in")
        .collapse("show");
      $("#collapseTwo").collapse("show");
    },
    ShowThree: () => {
      $(".collapseTwo")
        .removeClass("collapse in")
        .hide();
      $(".collapseOne")
        .removeClass("collapse in")
        .hide();

      $(".collapseThree")
        .show()
        .addClass("collapse in")
        .collapse("show");
      $("#collapseThree").collapse("show");
    },
    getDriversbyid: context => {
      context.$store
        .dispatch("TaxiDrivers/getAllByTaxiAssIdNoPic", {
          id: context.taxiassociationid,
          router: context.$router
        })
        .then(results => {
          console.log("drivers found: ", results);
          context.GETALLDRIVERS = results;
        })
        .catch(error => {
          console.log("drivers errros: ", error);
        });
    },

    getAllOperatorByTaxiAssId: context => {
      context.$store
        .dispatch("TO/getAllByTaxiAssIdNoPic", {
          router: context.$router,
          id: context.taxiassociationid
        })
        .then(results => {
          console.log("operators found: ", results);
          context.GETALLOPERATOR = results;
        })
        .catch(error => {
          console.log("operators errors: ", error);
          // context.$Notify.Error(error.data[0].message, "");
        });
    },

    addNumbers: (context, number) => {
      if (
        number.length == 10 &&
        context.EXTERNALNUMBERS.filter(i => i === number).length === 0
      ) {
        context.EXTERNALNUMBERS.push(number);

        context.numbers = "";
      } else {
        context.$Notify.Error(
          "Not a valid Number! \n Please enter a 10-digit number",
          "We cannot accept duplicate numbers."
        );
      }
    },
    updateNumbers: (context, number) => {
      alert(context.number);

      if (number.length == 10) {
        context.eventNumbers.push(context.number);

        context.number = "";
      } else {
        context.$Notify.Error(
          "Not a valid Number! \n Please enter a 10-digit number",
          "We cannot accept duplicate numbers."
        );
      }
    },
    deleteNumber: (context, number) => {
      context.EXTERNALNUMBERS = context.EXTERNALNUMBERS.filter(
        i => i != number
      );
    },
    deleteEventNumber: (context, number) => {
      context.eventNumbers = context.eventNumbers.filter(i => i != number);
    },
    deleteEvent: (context, id, deletedby) => {
      context.$Notify.YesNo(false, { context, id, deletedby }).then(value => {
        if (value) {
          value.context.$wait.start("delete-" + value.id);
          value.router = value.context.$router;
          value.context.$store
            .dispatch("calendar/delete", {
              _dataObj: value
            })
            .then(id => {
              value.context.$wait.end("delete-" + id);
              value.context.$Notify.Success("Successfully Deleted!", "");

              $(".collapseThree")
                .removeClass("collapse in")
                .hide();
              $(".collapseTwo")
                .removeClass("collapse in")
                .hide();

              $(".collapseOne")
                .show()
                .addClass("collapse in")
                .collapse("show");
              $("#collapseOne").collapse("show");
            })
            .catch(error => {
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },

    addDropableEvent: context => {
      context.$Notify
        .YesNo(false, { context })
        .then(value => {
          "$calendar".fullCalendar("gotoDate", date);
          console.log(date);
        })
        .catch(error => {
          console.log(error);
        });
    },

    selectAllDrvivers: function(context) {
      var input = document.getElementById("checkbox");
      if (input != " " && input != undefined) {
        context.GETDRIVERSBYASSID.forEach(item => {
          console.log(item);
        });
      } else console.log("not selected");
    },

    getallEvents: context => {
      context.$store
        .dispatch("calendar/getall", {
          _dataObj: {
            router: context.$router
          }
        })
        .then(() => {
          const calendarEventsAdded = context.GETALL;
          console.log(calendarEventsAdded);
          const calendarevent = [];
          if (calendarEventsAdded.length > 0) {
            calendarEventsAdded.forEach(item => {
              calendarevent.push({
                id: item._id,
                title: item._name,
                start: item._start_date,
                end: item._end_date,
                color: item._event_color,
                description: item._description,
                schedule_date: item._schedule_date,
                message: item._message,
                mobilenumbers: item._mobilenumbers,
                sms_batch_number_id: item._sms_batch_number_id
              });
            });
          }

          App.init();
          initiateCalendar(calendarevent);
        });
    },

    addEvent: (context, addModel) => {
      
      if (context.IsDriversSelected) {
        context.$store.getters["TaxiDrivers/GETDRIVERSBYASSIDNOPIC"].forEach(
          item => {
            context.EXTERNALNUMBERS.push(item._mobilenumber);
          }
        );
      } else {
        const drivernum = $(".default-selectDriver")
          .select2("data")
          .map(item => {
            return item.id;
          });
        if (drivernum.length > 0) {
          context.driverResults = [...drivernum];
        }
      }

      if (context.IsAllOperatorsSelected) {
        context.$store.getters["TO/GETOPERATORSNOPIC"].forEach(item => {
          context.EXTERNALNUMBERS.push(item.mobilenumber);
        });
      } else {
        const operatornum = $(".default-selectOperator")
          .select2("data")
          .map(item => {
            return item.id;
          });

        if (operatornum.length > 0) {
          context.operatorResults = [...operatornum];
        }
      }

      if (
        context.driverResults.length > 0 &&
        context.operatorResults.length == 0
      ) {
        addModel.mobilenumbers = context.driverResults;
      } else if (
        context.operatorResults.length > 0 &&
        context.driverResults.length == 0
      ) {
        addModel.mobilenumbers = context.operatorResults;
      } else if (
        context.driverResults.length > 0 &&
        context.operatorResults.length > 0
      ) {
        addModel.mobilenumbers = [
          ...context.driverResults,
          ...context.operatorResults,
          ...context.EXTERNALNUMBERS
        ];
      } else {
        addModel.mobilenumbers = context.EXTERNALNUMBERS;
      }

      const _startDateConverted = context.$Toolkit.ConvertToServerDate(
        addModel.start_date
      );

      const _endDateConverted = context.$Toolkit.ConvertToServerDate(
        addModel.end_date
      );

      if (
        $("#datepicker-disabled-past3")
          .find("input")
          .val() != ""
      ) {
        context._scheduleDateConverted = context.$Toolkit.ConvertToServerDate(
          $("#datepicker-disabled-past3")
            .find("input")
            .val()
        );
      } else {
        context._scheduleDateConverted = null;
      }

      addModel.start_date = _startDateConverted;
      addModel.end_date = _endDateConverted;
      addModel.reminderdate = context._scheduleDateConverted;

      context.$Notify.YesNo(false, { context, addModel }).then(value => {
        
        if (value) {
          // value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("calendar/add", {
              router: value.router,
              addModel
            })
            .then(() => {
              // $("#AddModal").modal("hide");
              // value.context.$wait.end("add");
              value.context.$Notify.Success("Successfully Added!", "");
              location.reload();
            })
            .catch(error => {
              value.context.$wait.end("add");

              value.context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        }
      });
    },

    updateEvent: (context, updateModel) => {
      
      if (context.IsDriversSelected) {
        context.$store.getters["TaxiDrivers/GETDRIVERSBYASSID"].forEach(
          item => {
            context.eventNumbers.push(item._mobilenumber);
          }
        );
      } else {
        const drivernum = $(".default-editselectDriver")
          .select2("data")
          .map(item => {
            return item.id;
          });
        if (drivernum.length > 0) {
          context.driverResults = [...drivernum];
        }
      }

      if (context.IsAllOperatorsSelected) {
        context.$store.getters["TO/GETADDOPERATOR"].forEach(item => {
          context.eventNumbers.push(item.mobilenumber);
        });
      } else {
        const operatornum = $(".default-editselectOperator")
          .select2("data")
          .map(item => {
            return item.id;
          });

        if (operatornum.length > 0) {
          context.operatorResults = [...operatornum];
        }
      }

      if (
        context.driverResults.length > 0 &&
        context.operatorResults.length == 0
      ) {
        context.eventNumbers = context.driverResults;
      } else if (
        context.operatorResults.length > 0 &&
        context.driverResults.length == 0
      ) {
        context.eventNumbers = context.operatorResults;
      } else if (
        context.driverResults.length > 0 &&
        context.operatorResults.length > 0
      ) {
        updateModel.newMobileNumbers = [
          ...context.driverResults,
          ...context.operatorResults,
          ...context.eventNumbers
        ];
      } else {
        updateModel.newMobileNumbers = [...context.eventNumbers];
      }

      const _startDateConverted = context.$Toolkit.ConvertToServerDate(
        context.EventStartDate
      );

      const _endDateConverted = context.$Toolkit.ConvertToServerDate(
        context.EventEndDate
      );

      const _scheduleDateConverted = context.$Toolkit.ConvertToServerDate(
        context.EventReminderDate
      );

      // context.eventNumbers

      context.$Notify.YesNo(false, { context, updateModel }).then(value => {
        
        if (value) {
          value.router = value.context.$router;
          value.context.$store
            .dispatch("calendar/update", {
              id: context.EventId,
              updateModel: {
                taxi_association_id:
                  context.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
                name: context.EventTitle,
                description: context.EventDetails,
                reminderdate: _scheduleDateConverted,
                start_date: _startDateConverted,
                end_date: _endDateConverted,
                sms_batch_number_id: context.$uuid.v4(),
                sms_batch_reminder_id: context.$uuid.v4(),
                event_color: context.EventColor,
                smsmessage: context.eventMessage,
                mobilenumbers: value.updateModel.newMobileNumbers,
                updatedby:
                  context.$store.getters["auth/USERPROFILE"].system_usersid
              },
              router: value.router
            })
            .then(_rb => {
              // $("#AddModal").modal("hide");
              console.log(_rb);
              value.context.$Notify.Success("Successfully Updated!", "");
              location.reload();
            })
            .catch(error => {
              console.log("this is the error!", error);
              value.context.$Notify.Error(error, "");
            })
            .finally(() => {});
        }
      });
    }

    // delete: (context, fileId, userID) => {
    //
    //   context.$Notify.YesNo(false, { context, fileId, userID }).then(value => {
    //     if (value) {
    //       value.context.$wait.start("add");
    //       value.router = value.context.$router;
    //       value.context.$store
    //         .dispatch("fileupload/delete", {
    //           id: value.fileId,
    //           deletedby: value.userID
    //         })
    //         .then(() => {
    //           $("#AddModal").modal("hide");
    //           value.context.$wait.end("add");
    //           value.context.$Notify.Success("Successfully Deleted!", "");
    //         })
    //         .catch(error => {
    //           value.context.$wait.end("add");
    //
    //           value.context.$Notify.Error(error.data[0].message, "");
    //
    //         })
    //         .finally(() => {});
    //     }
    //   });
    // }
  },
  mounted: function() {
    //  $('.data-table').DataTable().clear().destroy();
    // $(".data-table").DataTable({
    //   responsive: true
    // });

    $("#calendar").data("context", this.context);

    $(".collapseOne").removeClass("collapse in");
    $(".collapseTwo")
      .removeClass("collapse in")
      .hide();
    $(".collapseThree")
      .removeClass("collapse in")
      .hide();

    $('select[name="jquery-simplecolorpicker-fa"]')
      .simplecolorpicker({ theme: "fontawesome" })
      .on("change", function() {
        var context = $("#calendar").data("context");
        context.EventColor = $(this).val();
      });

    $('select[name="jquery-simplecolorpicker-fa-add"]')
      .simplecolorpicker({ theme: "fontawesome" })
      .on("change", function() {
        var context = $("#calendar").data("context");
        context.ADDMODEL.event_color = $(this).val();
      });

    $("#datepicker-disabled-past").datetimepicker({
      sideBySide: true
    });
    $("#datepicker-disabled-past2").datetimepicker({
      sideBySide: true
    });
    $("#datepicker-disabled-past3").datetimepicker({
      sideBySide: true
    });

    $("#datepicker-update-start").datetimepicker({
      sideBySide: true
    });
    $("#datepicker-update-end").datetimepicker({
      sideBySide: true
    });
    $("#datepicker-update-scheduled").datetimepicker({
      sideBySide: true
    });

    $(".default-selectOperator").select2({
      multiple: true,
      placeholder: "Select A Operator",
      allowClear: true
    });

    $(".default-selectDriver").select2({
      multiple: true,
      placeholder: "Select A Driver",
      allowClear: true
    });

    this.getDriversbyid(this.context);
    this.getAllOperatorByTaxiAssId(this.context);
  }
};
var initiateCalendar = function(_calendarevents) {
  $("#external-events .fc-event").each(function() {
    $(this).data("event", {
      title: $.trim($(this).text()), // use the element's text as the event title
      stick: true, // maintain when user navigates (see docs on the renderEvent method)
      color: $(this).attr("data-color") ? $(this).attr("data-color") : ""
    });
    $(this).draggable({
      zIndex: 999,
      revert: true, // will cause the event to go back to its
      revertDuration: 0 //  original position after the drag
    });
  });

  var date = new Date();
  var currentYear = date.getFullYear();
  var currentMonth = date.getMonth() + 1;

  currentMonth = currentMonth < 10 ? "0" + currentMonth : currentMonth;

  $("#calendar").fullCalendar({
    header: {
      left: "month,agendaWeek,agendaDay",
      center: "title",
      right: "prev,today,next "
    },
    droppable: true, // this allows things to be dropped onto the calendar
    drop: function(e) {
      console.log(e);
      $(this).remove();
    },
    selectable: true,
    selectHelper: true,
    select: function(start, end, event) {
      var context = $("#calendar").data("context");
      context.$Notify
        .YesNo(false, { start, end, event })
        .then(value => {
          if (value) {
            console.log(start, end, event);

            var eventData;

            eventData = {
              start: start,
              end: end
            };
            context.ADDMODEL.start_date = context.$options.filters.formatToLongDateTimeWithSeconds(
              eventData.start._d
            );
            context.ADDMODEL.end_date = context.$options.filters.formatToLongDateTimeWithSeconds(
              eventData.end._d
            );

            // data-toggle:"collapse"
            // data-target="#collapseTwo"
            console.log(context.ADDMODEL.start_date);

            $(".collapseOne")
              .removeClass("collapse in")
              .hide();
            $(".collapseThree")
              .removeClass("collapse in")
              .hide();

            $("#calendar").fullCalendar("renderEvent", eventData, true);
            setTimeout(() => {
              $("#modalTitle").html(value.event.title);
              $("#modalStartDate").html(value.start_date);
              $("#modalEndDate").html(value.end_date);
              // $("#collapseOne").collapse();

              context.ShowTwo();

              $("#calendar").fullCalendar("unselect");

              $(".default-selectOperator").select2({
                multiple: true,
                placeholder: "Select A Operator",
                allowClear: true
              });

              $(".default-selectDriver").select2({
                multiple: true,
                placeholder: "Select A Driver",
                allowClear: true
              });
            }, 800);
          }
        })
        .catch(error => {
          console.log(error);
          value.context.$wait.end("add");

          value.context.$Notify.Error(error.message, "");
        });
    },
    editable: true,
    eventLimit: true, // allow "more" link when too many events
    events: _calendarevents,
    eventClick: function(info) {
      var context = $("#calendar").data("context");
      console.log(info);
      context.EventId = info.id;
      context.EventStartDate = info.start._d;
      context.EventEndDate = info.end._d;
      context.EventTitle = info.title;
      context.EventColor = info.color;
      context.eventMessage = info.message;
      context.eventNumbers = info.mobilenumbers;
      context.EventDetails = info.description;
      context.EventReminderDate = info.schedule_date;

      $("#eventTitle").html(context.EventTitle);
      $("#modalBody").html(event.description);
      $("#eventUrl").attr("href", event.url);

      context.ShowThree();

      $(".default-editselectOperator").select2({
        multiple: true,
        placeholder: "Select A Operator",
        allowClear: true
      });

      $(".default-editselectDriver").select2({
        multiple: true,
        placeholder: "Select A Driver",
        allowClear: true
      });
    }
  });
};
</script>

	

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>