/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';


export default {
  namespaced: true,
  state: {
    gender: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETALL: state => state.gender,
  },
  mutations: {
    LOADED: (state, _data) => {
      if (_data !== undefined) {
        state.gender = _data;
      }
    },
    ADDED: (state, _data) => {
      state.gender.push(_data);
    },
    UPDATED: (state, _data) => {
      if (_data !== undefined) {
        Object.assign(state.gender.filter(item => item._id === _data._id)[0], _data);
      }
    },
    RESTOREDELETED: (state, { id }) => {
      if (id !== undefined) {
        state.gender.filter(item => item._id === id)[0]._deleted_by = undefined;
        state.gender.filter(item => item._id === id)[0]._date_deleted = undefined;
      }
    },
    MARKASDELETE: (state, { id, deletedby }) => {
      if (id !== undefined) {
        state.gender.filter(item => item._id === id)[0]._deleted_by = deletedby;
        state.gender.filter(item => item._id === id)[0]._date_deleted = Date.now;
      }
    },
    DELETE: (state, id) => {
      if (id !== undefined) {
        state.gender = state.gender.filter(item => item._id !== id);
        state.deleted = true;
      }
    },
  },
  actions: {
    /* Async */

    /* Auth.js Mutations */
    getall({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const { router } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GENDER_URL,
        };

        

        this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
          if (parsedResult.status) {
            commit('LOADED', parsedResult.data);
            resolve();
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
    add({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          item, router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GENDER_URL,
        };

        const AddModel = {
          name: item._name,
          description: item._description,
          code: item._code,
          addedby: item._added_by,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            commit('ADDED', item);
            resolve();
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    update({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          item, router, updatedby,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GENDER_URL,
        };

        const updateModel = {
          name: item._name,
          description: item._description,
          code: item._code,
          isactive: item._is_active,
          updatedby,
        };

        this._vm.$TAMS_API.update(requestheader,
          item._id,
          updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            commit('UPDATED', item);
            resolve();
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    delete({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby, id, router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GENDER_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id,
          {
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('DELETE', id);
            resolve(id);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    markAsdelete({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby, id, router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GENDER_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id,
          {
            id,
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('MARKASDELETE', { id, deletedby });
            resolve(id);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    restoreDeleted({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          restoredby, id, router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GENDER_URL,
        };

        this._vm.$TAMS_API.restoreDeleted(requestheader,
          {
            id,
            restoredby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('RESTOREDELETED', { id });
            resolve(id);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

  },

};
