<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Money'"
      :ActiveMenu="'CreateInvoice'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Create An Invoice"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Money"
        breadcrumbitem3="Create Invoice"
        ActiveSubMenu="Money"
        ActiveMenu="CreateInvoice"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin invoice -->
      <div class="invoice">
        <!-- begin invoice-Logo -->
        <span class="pull-right">
          <img :src="encodedImage" class="width-160 height-40" />
        </span>
        <!-- begin invoice-Logo -->
        <!-- begin invoice-company -->
        <div class="invoice-company text-inverse f-w-600">
          <span class="pull-right hidden-print">
            <a
              href="javascript:;"
              :disabled="$wait.is('createAndPaymentInvoice')"
              @click="createInvoiceAndPayment(context)"
              :class="{'btn btn-lg btn-success m-r-20 m-b-10 p-l-5': !$wait.is('createAndPaymentInvoice'),
            'btn btn-lg btn-grey m-b-10 p-l-5': $wait.is('createAndPaymentInvoice')
            }"
            >
              <i
                class="fa fa-registered t-plus-1 text-white fa-fw fa-lg"
                v-show="!$wait.is('createAndPaymentInvoice')"
              ></i>

              <i
                class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
                v-show="$wait.is('createAndPaymentInvoice')"
              ></i>

              <span>Create Invoice And Payment</span>
            </a>
            <a
              href="javascript:;"
              :disabled="$wait.is('createInvoice')"
              @click="createInvoice(context)"
              :class="{'btn btn-lg btn-primary m-r-20 m-b-10 p-l-5': !$wait.is('createInvoice'),
            'btn btn-lg btn-grey m-b-10 p-l-5': $wait.is('createInvoice')
            }"
            >
              <i
                class="fa fa-copyright t-plus-1 text-white fa-fw fa-lg"
                v-show="!$wait.is('createInvoice')"
              ></i>

              <i
                class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
                v-show="$wait.is('createInvoice')"
              ></i>

              <span>Create Invoice</span>
            </a>
            <a
              v-show="printReady"
              href="#modal-dialog"
              data-toggle="modal"
              class="btn m-r-20 btn-lg btn-white m-b-10 p-l-5"
            >
              <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print Receipt
            </a>
          </span>
          {{USERPROFILE.taxi_associations_name}}
          <div class="spinner-border spinner-border-lg text-info" v-if="!dataloadedABC"  role="status">
                <span class="sr-only">Loading...</span>
              </div>

        </div>
        <!-- end invoice-company -->
        <!-- begin invoice-header -->
        <div class="invoice-header">
          <div v-show="invoiceCreated" class="alert alert-success fade show">
            <span class="close" data-dismiss="alert">×</span>
            <strong>Success!</strong>
            Invoice Successfully Created.
          </div>

          <div class="row">
            <div class="col-lg-6">
              <!-- begin card -->
              <div class="card text-center">
                <div class="card-header">
                  <ul class="nav nav-tabs card-header-tabs">
                    <li class="nav-item" v-show="SELECTEDTAXIASSOCIATION._create_vehicle_account">
                      <a class="nav-link active" data-toggle="tab" href="#card-tab-1">Taxis</a>
                    </li>
                    <li class="nav-item" v-show="SELECTEDTAXIASSOCIATION._create_operator_account">
                      <a class="nav-link" data-toggle="tab" href="#card-tab-2">Operators</a>
                    </li>
                    <li class="nav-item" v-show="SELECTEDTAXIASSOCIATION._create_driver_account">
                      <a class="nav-link" data-toggle="tab" href="#card-tab-3">Drivers</a>
                    </li>
                  </ul>
                </div>
                <div class="card-block">
                  <div class="tab-content p-0 m-0">
                    <div
                      class="tab-pane fade active show"
                      id="card-tab-1"
                      v-show="SELECTEDTAXIASSOCIATION._create_vehicle_account"
                    >
                      <div class="invoice-to">
                        <small>
                          <select
                            class="default-selectTaxi default-select form-control"
                            data-size="5"
                            data-live-search="true"
                          >
                            <option
                              :key="item._taxi_operator_vehicles_id"
                              v-for="item in GETALLTAXIS"
                              :value="item._taxi_operator_vehicles_id"
                            >{{item._licence_plate}} ({{item._taxi_operators_name}}) Bal: R{{item._available_balance || 0}}</option>
                          </select>
                        </small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse"></strong>
                          <br />
                          <br />
                          <br />
                          <br />
                        </address>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="card-tab-2"
                      v-show="SELECTEDTAXIASSOCIATION._create_operator_account"
                    >
                      <div class="invoice-to">
                        <small>
                          <select
                            class="default-selectOperator default-select form-control"
                            data-size="5"
                            data-live-search="true"
                          >
                            <option
                              v-for="item in GETALLOPERATORS"
                              :key="item.taxi_operator_id"
                              :value="item.taxi_operator_id"
                            >{{item.firstname}} {{item.surname}}({{item.id_number}}) Bal: R{{item._available_balance}}</option>
                          </select>
                        </small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse"></strong>
                          <br />
                          <br />
                          <br />
                          <br />
                        </address>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="card-tab-3"
                      v-show="SELECTEDTAXIASSOCIATION._create_driver_account"
                    >
                      <div class="invoice-to">
                        <small>
                          <select
                            class="default-selectTaxiDrivers default-select form-control"
                            data-size="5"
                            data-live-search="true"
                          >
                            <option
                              v-for="item in GETAllTAXIDRIVERS"
                              :key="item._taxi_drivers_id"
                              :value="item._taxi_drivers_id"
                            >{{item._firstname}} {{item._surname}}({{item._id_number}}) Bal: R{{item._available_balance}}</option>
                          </select>
                        </small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse"></strong>
                          <br />
                          <br />
                          <br />
                          <br />
                        </address>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end card -->
            </div>
            <div class="col-lg-6">
              <div class="invoice-date">
                <small></small>
                <div class="date text-inverse m-t-10 width-200 pull-right">
                  Invoice Date:
                  <div
                    class="input-group date"
                    id="datepicker-disabled-past"
                    data-date-format="dd M yyyy"
                  >
                    <input type="text" class="form-control" placeholder="Select Date" />
                    <span class="input-group-addon">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>

                <div class="invoice-detail">
                  <br />
                </div>
              </div>

              <br />
              <!-- begin widget-list -->
              <div
                v-if="VEHICLETRANSACTIONS.length > 0"
                class="widget-list widget-list-rounded m-b-30"
              >
                <!-- begin widget-list-item -->
                <div class="widget-list-item" :key="item._id" v-for="item in VEHICLETRANSACTIONS">
                  <div class="widget-list-content">
                    <h4
                      class="widget-list-title"
                    >Amount: {{item._transaction_amount != null ? item._transaction_amount : 0}}</h4>
                    <p class="widget-list-desc">
                      <b>Details:</b>
                      {{item._transaction_reference != null ? item._transaction_reference : 'N/A'}}
                    </p>
                  </div>
                  <div class="widget-list-action">
                    <h4 class="widget-list-title">{{item._captured_date | formatToLongDateTime }}</h4>
                  </div>
                </div>
                <!-- end widget-list-item -->
              </div>

              <!-- end widget-list -->

            </div>
          </div>
        </div>
        <!-- end invoice-header -->
        <!-- begin invoice-content -->
        <div class="invoice-content">
          <!-- begin table-responsive -->
          <div class="table-responsive">
            <table class="table table-invoice">
              <thead>
                <tr>
                  <th width="9%"></th>
                  <th>DESCRIPTION</th>
                  <th class="text-right" width="20%">AMOUNT</th>
                  <th class="text-center" width="10%">QTY</th>
                  <th class="text-right" width="20%">LINE TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in BILLINGITEMS" :key="item._billing_items_id">
                  <td class>
                    <div class="row p-t-10">
                      <div class="col-md-4">
                        <a
                          href="javascript:;"
                          @click="AddNewBillItem(context)"
                          class="btn btn-danger btn-icon btn-circle btn-sm"
                        >
                          <i class="fa fa-plus"></i>
                        </a>
                      </div>
                      <div class="col-md-4">
                        <a
                          href="javascript:;"
                          @click="removeBillItem(context,index)"
                          v-show="index > 0"
                          class="btn btn-warning btn-icon btn-circle btn-sm"
                        >
                          <i class="fa fa-minus"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <select
                      class="default-selectBIllingitems form-control"
                      data-size="5"
                      data-live-search="true"
                      @change="changeBillItem(context, item ,item.billingitems)"
                      v-model="item.billingitems"
                    >
                      <option
                        v-for="itembilling in GETAllBILLINGITEMSFILTERED(context,TYPEOFBILLINGCLIENT)"
                        :key="itembilling._billing_items_id"
                        :value="itembilling"
                      >{{itembilling._collection_settings_name}}-{{itembilling._taxi_association_routes_id_name}}({{itembilling._billing_items_amount}})</option>
                    </select>
                  </td>
                  <td class="text-right p-t-20 f-s-20">R{{item.lineamount | currency}}</td>
                  <td class="text-center" width="20%">
                    <div class="btn-group btn-group-justified">
                      <a @click="item.lineqty > 0 ? --item.lineqty : 1" class="btn btn-default">
                        <i class="fa fa-minus m-t-10"></i>
                      </a>
                      <a class="btn btn-default active">
                        <input
                          class="form-control form-control-sm text-center"
                          @blur="item.lineqty > 0 ? item.lineqty : 0"
                          min="1"
                          v-model.lazy="item.lineqty"
                          type="number"
                        />
                      </a>
                      <a @click="item.lineqty++" class="btn btn-default">
                        <i class="fa fa-plus m-t-10"></i>
                      </a>
                    </div>
                  </td>
                  <td class="text-right p-t-20 f-s-20">R{{item.linetotal | currency}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- end table-responsive -->
          <!-- begin invoice-price -->
          <div class="invoice-price">
            <div class="invoice-price-left">
              <div class="invoice-price-row">
                <div class="sub-price">
                  <small>SUBTOTAL</small>
                  <span class="text-inverse">R{{linetotal | currency}}</span>
                </div>
                <div class="sub-price">
                  <i class="fa fa-plus text-muted"></i>
                </div>
                <div class="sub-price">
                  <small>TRANSACTION FEE ({{this.TRANSFEE}}%)</small>
                  <span class="text-inverse">R{{TRANSACTIONFEE | currency}}</span>
                </div>
              </div>
            </div>
            <div class="invoice-price-right">
              <small>TOTAL</small>
              <span class="f-w-600">R{{TOTALMOUNT | currency}}</span>
            </div>
          </div>
          <!-- end invoice-price -->
        </div>
        <!-- end invoice-content -->
        <!-- begin invoice-note -->
        <div class="invoice-note">
          <div class="form-group row">
            <label class="col-md-1 col-form-label">Comment</label>
            <div class="col-md-5">
              <textarea
                v-model="COMMENT"
                class="form-control m-b-10"
                id="clipboard-textarea"
                rows="7"
              ></textarea>
            </div>
          </div>
          * Make all invoices payable to {{USERPROFILE.taxi_associations_name}}
          <br />
        </div>
        <!-- end invoice-note -->
        <!-- begin invoice-footer -->
        <div class="invoice-footer">
          <p class="text-center m-b-5 f-w-600">{{USERPROFILE.taxi_associations_name}}</p>
          <p class="text-center">
            <span class="m-r-10">
              <i class="fa fa-fw fa-lg fa-globe"></i>
              {{SELECTEDTAXIASSOCIATION._abbriviated_name}}.TAMS.co.za
            </span>
            <span class="m-r-10">
              <i class="fa fa-fw fa-lg fa-phone-volume"></i>
              T:{{SELECTEDTAXIASSOCIATION._telephone | HumanizeMobile}}
            </span>
          </p>
        </div>
        <!-- end invoice-footer -->
      </div>
      <!-- end invoice -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- #modal-dialog -->
    <div class="modal fade" id="modal-dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Print Invoices</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
          </div>
          <div class="modal-body">
            <table id="data-table-titles" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th class="text-nowrap">Type</th>
                  <th class="text-nowrap">Name</th>
                  <th class="text-nowrap">Receipt Number</th>
                  <th class="text-nowrap">
                    <a
                      @click="printAll(context)"
                      href="javascript:;"
                      data-dismiss="modal"
                      class="btn btn-sm btn-white"
                    >
                      <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print All
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in GETINVOICERESULTS"
                  v-bind:key="item._id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                  <td>{{item.entityname}}</td>
                  <td>{{PAYMENTRESULTS_NAME(context,item.entityname,item.entity_id)}}</td>
                  <td>{{item.invoice_number}}</td>
                  <td class="with-btn valign-middle" nowrap>
                    <a
                      @click="printNow(context,item)"
                      href="javascript:;"
                      class="btn btn-sm btn-white"
                    >
                      <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print
                    </a>
                    <a
                      @click="printPreview(context,item)"
                      href="javascript:;"
                      class="btn btn-sm btn-white"
                    >
                      <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i>Preview
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <a href="javascript:;" class="btn btn-white" data-dismiss="modal">Close</a>
          </div>
        </div>
      </div>
    </div>
    <!-- #modal-dialog -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { setTimeout } from "timers";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("invoices");

export default {
  name: "CreateInvoice",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      SELECTEDTAXI: {},
      SELECTEDOPERATOR: {},
      SELECTEDDRIVER: {},
      SELECTEDTAXIASSOCIATION: this.$store.getters[
        "auth/SELECTEDTAXIASSOCIATION"
      ],
      TRANSFEE: this.$store.getters["auth/SELECTEDTAXIASSOCIATION"]._percentage,
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      GETALLTAXIS: [],
      GETALLOPERATORS: this.$store.getters["TO/GETOPERATORSBYACCOUNTNO"],
      GETAllTAXIDRIVERS: this.$store.getters["TaxiDrivers/GETDRIVERSBYASSIDNOPIC"],
      GETAllTAXIMODELS: this.$store.getters["TV/GETALLMODEL"],
      GETAllBILLINGITEMS: this.$store.getters["billingitems/GETALL"],
      taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      printReady: false,
      encodedImage: undefined,
      COMMENT: "",
      selectedTitleId: undefined,
      selectedGenderId: undefined,
      selectedUserGroupId: undefined,
      dataloadedABC: false,
      invoiceCreated: false,
      VEHICLETRANSACTIONS: [],
      OPERATORTRANSACTIONS: [],
      DRIVERTRANSACTIONS: [],
      skip: 0,
      len: 3,
      linetotal: 0,
      TYPEOFBILLINGCLIENT: 3,
      BILLINGITEMS: [
        {
          billingitems_id: 0,
          lineamount: 0,
          lineqty: 1,
          linetotal: 0
        }
      ]
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  beforeMount: function() {
    this.getAllTaxiByTaxiAssId(this.context, this.taxiassociation_id);
    this.getAllOperatorByTaxiAssId(this.context, this.taxiassociation_id);
    this.getAllTaxiDriversByTaxiAssId(this.context, this.taxiassociation_id);
    this.getAllBillingItemsByTaxiAssId(this.context, this.taxiassociation_id);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["GETINVOICERESULTS"]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
    CurrentDate: function() {
      return Date();
    },

    TOTALINVOICEAMOUNT: function() {
      let _totalInvoiceAmount = 0;
      this.BILLINGITEMS.forEach(item => {
        item.linetotal = item.lineamount * item.lineqty;
        _totalInvoiceAmount += item.linetotal;
      });

      this.linetotal = _totalInvoiceAmount;

      return _totalInvoiceAmount;
    },
    TRANSACTIONFEE: function() {
      let _transfee = 0;
      return (this.TOTALINVOICEAMOUNT * this.TRANSFEE) / 100;
    },
    TOTALMOUNT: function() {
      return this.TOTALINVOICEAMOUNT + this.TRANSACTIONFEE;
    }
  },
  watch: {},
  methods: {
    ...mapActions([
      "createInvoiceAndPayment",
      "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted"
    ]),
    QtyKeyPress: function(e) {
      
      alert("test");
    },
    reloadInfo: function(context) {
      context.getAllTaxiByTaxiAssId(context, context.taxiassociation_id);
      context.getAllOperatorByTaxiAssId(context, context.taxiassociation_id);
      context.getAllTaxiDriversByTaxiAssId(context, context.taxiassociation_id);
    },
    GETAllBILLINGITEMSFILTERED: function(context, id) {
      if (context.GETAllBILLINGITEMS)
        return context.GETAllBILLINGITEMS.filter(
          item => item._typeofbillingclient_id === id
        );
    },
    FILTERBYENTITYTYPE: function(context, text) {
      if (text === "Taxis") {
        context.TYPEOFBILLINGCLIENT = 3;
      } else if (text === "Operators") {
        context.TYPEOFBILLINGCLIENT = 1;
      } else if (text === "Drivers") {
        context.TYPEOFBILLINGCLIENT = 2;
      }
    },
    printNow: function(context, item) {
      let routeData = context.$router.resolve({
        name: "printinvoice",
        params: { id: item.invoice_id, preview: false }
      });
      window.open(routeData.href, "_blank");
    },
    printPreview: function(context, item) {
      let routeData = context.$router.resolve({
        name: "printinvoice",
        params: { id: item.invoice_id, preview: true }
      });
      window.open(routeData.href, "_blank");
    },
    printAll: function(context) {
      context.GETINVOICERESULTS.forEach(item => {
        let routeData = context.$router.resolve({
          name: "printinvoice",
          params: { id: item.invoice_id, review: false }
        });
        window.open(routeData.href, item.invoice_id);
      });
    },
    PAYMENTRESULTS_NAME: function(context, entityname, id) {
      
      if (entityname.toUpperCase() === "Driver".toUpperCase()) {
        if (context.GETAllTAXIDRIVERS.length > 0) {
          const _details = context.GETAllTAXIDRIVERS.filter(
            item => item._taxi_drivers_id === id
          )[0];
          return _details._firstname + " " + _details._surname;
        }
      } else if (entityname.toUpperCase() === "Taxi".toUpperCase()) {
        if (context.GETALLTAXIS.length > 0)
          return context.GETALLTAXIS.filter(
            item => item._taxi_operator_vehicles_id === id
          )[0]._vehicle_register_number;
      } else if (entityname.toUpperCase() === "Operator".toUpperCase()) {
        if (context.GETALLOPERATORS.length > 0) {
          const _details = context.GETALLOPERATORS.filter(
            item => item.taxi_operator_id === id
          )[0];
          return _details.firstname + " " + _details.surname;
        }
      }
    },
    reset: function(context) {
      $(".default-selectTaxiDrivers")
        .val(null)
        .trigger("change");
      $(".default-selectTaxi")
        .val(null)
        .trigger("change");
      $(".default-selectOperator")
        .val(null)
        .trigger("change");
      context.COMMENT = "";
      context.BILLINGITEMS = [
        {
          billingitems_id: 0,
          lineamount: 0,
          lineqty: 1,
          linetotal: 0
        }
      ];
    },
    changeBillItem: function(context, rowitem, billingitem) {
      rowitem.billingitems_id = billingitem._billing_items_id;
      rowitem.lineamount = billingitem._billing_items_amount;
    },
    AddNewBillItem: function(context) {
      context.BILLINGITEMS.push({
        billingitems_id: 0,
        lineamount: 0,
        lineqty: 1,
        linetotal: 0
      });
    },
    removeBillItem: function(context, index) {
      context.BILLINGITEMS.splice(index, 1);
    },
    GETTAXISBYMODELID: function(context, id) {
      return context.GETALLTAXIS.filter(item => item._vehiclemodel_id === id);
    },
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    getAllBillingItemsByTaxiAssId: (context, id) => {
      context.$store
        .dispatch("billingitems/getAllByTaxiAssID", {
          router: context.$router,
          id
        })
        .then(() => {})
        .catch(error => {
          value.context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },
    getvehicleTransactionsById: (context, id) => {
      
      context.$store
        .dispatch("OperatorVehicle/getVehicleTransactionsById", {
          id,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          console.log("vehicles transactions results: ", data);
          context.VEHICLETRANSACTIONS = data;
          context.OPERATORTRANSACTIONS = [];
          context.DRIVERTRANSACTIONS = [];
        })
        .catch((error) => {
          console.log("vehicles vue page: ", error);
          context.$Notify.Error(error.message, "");
        })
        .finally(() => {});
    },
    getAllTaxiDriversByTaxiAssId: (context, id) => {
      context.$store
        .dispatch("TaxiDrivers/getAllByTaxiAssIdNoPic", {
          router: context.$router,
          id,
          skip: 0,
          len: 10000
        })
        .then(() => {
          $(".default-selectTaxiDrivers").select2({
            multiple: true,
            placeholder: "Select A Taxi",
            allowClear: true
          });

          $(".default-selectTaxiDrivers")
            .val(null)
            .trigger("change");
        })
        .catch(error => {
          value.context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },
    getAllTaxiByTaxiAssId: (context, id) => {
      context.dataloadedABC = false;
      context.$store
        .dispatch("OperatorVehicle/getVehiclesByAssIdDropDowns", {
          router: context.$router,
          taxiassociation_id: id,
          skip: 0,
          len: 2000,
        })
        .then((data) => {
          //context.GETALLOPERATORS = context.$store.getters["TO/GETADDOPERATOR"];

          console.log("vehicles vue: ", data);
          context.GETALLTAXIS = data;

          context.dataloadedABC = true;

          // $(".default-selectTaxi")
          // .val(null)
          // .trigger("change");
        })
        .catch((error) => {
          console.log("vehicles errors: ", error);
          context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {

          $(".default-selectTaxi").select2({
            multiple: false,
            placeholder: "Select A Taxi",
            allowClear: false,
          });

          $(".default-selectTaxi").on("change", function () {
            
            var taxiID = $(".default-selectTaxi option:selected").val();
            console.log("selected Taxi", taxiID);
            if (taxiID != null) {
              var context = $(".nav-link").data("context");
              context.getvehicleTransactionsById(context, taxiID);
            } else {
              console.log("no taxi selected!");
            }
          });

        });
    },
    getAllOperatorByTaxiAssId: (context, id) => {
      context.$store
        .dispatch("TO/getOperatorByAccountNo", {
          router: context.$router,
          id
        })
        .then(() => {
          $(".default-selectOperator").select2({
            multiple: true,
            placeholder: "Select A Operator",
            allowClear: true
          });

          $(".default-selectOperator")
            .val(null)
            .trigger("change");
        })
        .catch(error => {
          context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },
    createInvoice: context => {
      
      if (context.linetotal <= 0)
        return context.$Notify.Error("Warning", "Please choose Bill Item");

      let _Operator = [];
      try {
        _Operator = $(".default-selectOperator").select2("data");
      } catch (e) {}
      let _Taxis = [];
      try {
        _Taxis = $(".default-selectTaxi").select2("data");
      } catch (e) {}
      let _Drivers = [];
      try {
        _Drivers = $(".default-selectTaxiDrivers").select2("data");
      } catch (e) {}
      const BILLINGITEMS = context.BILLINGITEMS;

      if (
        _Operator.length === 0 &&
        _Taxis.length === 0 &&
        _Drivers.length === 0
      )
        return context.$Notify.Error(
          "Warning",
          "Please choose Operator, Taxi And Driver"
        );

      //TRANSACTIONFEE
      //TOTALINVOICEAMOUNT
      //TOTALMOUNT

      const invoicedetails = {
        transactionfee: context.TRANSACTIONFEE,
        totalinvoiceamount: context.TOTALINVOICEAMOUNT,
        totalamount: context.TOTALMOUNT,
        billingitems: []
      };

      

      BILLINGITEMS.forEach(function(item, index) {
        const billingitemname = context.GETAllBILLINGITEMS.filter(
          t => t._billing_items_id == item.billingitems_id
        )[0]._billingtype_name;

        invoicedetails.billingitems.push({
          billingitemid: item.billingitems_id,
          lineamount: item.lineamount,
          billingitemname,
          lineqty: Number(item.lineqty),
          linetotal: item.linetotal
        });
      });

      const _converteddate = context.$Toolkit.ConvertToServerDate(
        $("#datepicker-disabled-past").datepicker("getDate")
      );

      const Payload = {
        invoicedetails,
        linetotal: context.TOTALMOUNT,
        comment: context.COMMENT,
        reference: "",
        added_by: context.USERID,
        captured_by: context.USERID,
        invoice_date: _converteddate,
        taxi_association_id: context.taxiassociation_id
      };

      const billitems_array = [];
      const Operators = [];
      const Taxis = [];
      const Drivers = [];

      BILLINGITEMS.forEach(function(billitem, index) {
        billitems_array.push(billitem.billingitems_id);
      });

      _Drivers.forEach(function(_item, index) {
        Drivers.push(_item.id);
      });

      _Taxis.forEach(function(_item, index) {
        Taxis.push(_item.id);
      });

      _Operator.forEach(function(_item, index) {
        Operators.push(_item.id);
      });

      context.$Notify
        .YesNo(false, {
          Payload,
          context,
          billitems_array,
          Operators,
          Taxis,
          Drivers
        })
        .then(value => {
          if (value) {
            
            value.context.$wait.start("createInvoice");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("invoices/createInvoice", {
                router: value.router,
                payload: value.Payload,
                billitems_array: value.billitems_array,
                _Operator: value.Operators,
                _Taxis: value.Taxis,
                _Drivers: value.Drivers,
                PrePayment: false
              })
              .then(_data => {
                value.context.reloadInfo(value.context);
                value.context.reset(value.context);
                value.context.invoiceCreated = true;
                value.context.printReady = true;
                value.context.$wait.end("createInvoice");
                value.context.$Notify.Success("Successfully Added!", "");
              })
              .catch(error => {
                context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    createInvoiceAndPayment: context => {
      
      if (context.linetotal <= 0)
        return context.$Notify.Error("Warning", "Please choose Bill Item");

      let _Operator = [];
      try {
        _Operator = $(".default-selectOperator").select2("data");
      } catch (e) {}
      let _Taxis = [];
      try {
        _Taxis = $(".default-selectTaxi").select2("data");
      } catch (e) {}
      let _Drivers = [];
      try {
        _Drivers = $(".default-selectTaxiDrivers").select2("data");
      } catch (e) {}
      const BILLINGITEMS = context.BILLINGITEMS;

      if (
        _Operator.length === 0 &&
        _Taxis.length === 0 &&
        _Drivers.length === 0
      )
        return context.$Notify.Error(
          "Warning",
          "Please choose Operator, Taxi And Driver"
        );

      //TRANSACTIONFEE
      //TOTALINVOICEAMOUNT
      //TOTALMOUNT

      const invoicedetails = {
        transactionfee: context.TRANSACTIONFEE,
        totalinvoiceamount: context.TOTALINVOICEAMOUNT,
        totalamount: context.TOTALMOUNT,
        billingitems: []
      };

      

      BILLINGITEMS.forEach(function(item, index) {
        const billingitemname = context.GETAllBILLINGITEMS.filter(
          t => t._billing_items_id == item.billingitems_id
        )[0]._collection_settings_name;

        invoicedetails.billingitems.push({
          billingitemid: item.billingitems_id,
          lineamount: item.lineamount,
          billingitemname,
          lineqty: Number(item.lineqty),
          linetotal: item.linetotal
        });
      });

      const _converteddate = context.$Toolkit.ConvertToServerDate(
        $("#datepicker-disabled-past").datepicker("getDate")
      );

      const Payload = {
        invoicedetails,
        linetotal: context.TOTALMOUNT,
        comment: context.COMMENT,
        reference: "",
        added_by: context.USERID,
        captured_by: context.USERID,
        invoice_date: _converteddate,
        taxi_association_id: context.taxiassociation_id
      };

      const billitems_array = [];
      const Operator = [];
      const Taxis = [];
      const Drivers = [];

      BILLINGITEMS.forEach(function(billitem, index) {
        billitems_array.push(billitem.billingitems_id);
      });

      _Drivers.forEach(function(_item, index) {
        Drivers.push(_item.id);
      });

      _Taxis.forEach(function(_item, index) {
        Taxis.push(_item.id);
      });

      _Operator.forEach(function(_item, index) {
        Operator.push(_item.id);
      });

      context.$Notify
        .YesNoMore("Create Invoice And Payment", "Are you sure?", false, {
          Payload,
          context,
          billitems_array,
          Operator,
          Taxis,
          Drivers
        })
        .then(value => {
          if (value) {
            

            value.context.$wait.start("createAndPaymentInvoice");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("invoices/createInvoice", {
                router: value.router,
                payload: value.Payload,
                billitems_array: value.billitems_array,
                _Operator: value.Operator,
                _Taxis: value.Taxis,
                _Drivers: value.Drivers,
                PrePayment: true
              })
              .then(_data => {
                value.context.reloadInfo(value.context);
                value.context.reset(value.context);
                value.context.invoiceCreated = true;
                value.context.printReady = true;
                value.context.$wait.end("createAndPaymentInvoice");
                value.context.$Notify
                  .Success("Successfully Added!", "")
                  .then(rb => {
                    let routeData = value.context.$router.resolve({
                      name: "prepayment",
                      params: {
                        PrePayment: "Yes"
                      }
                    });

                    var randomnumber = Math.floor(Math.random() * 100 + 1);
                    window.open(routeData.href, randomnumber);
                  });
              })
              .catch(error => {
                context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    }
  },
  mounted: function() {
    App.init();

    //Inputmask().mask(document.querySelectorAll(".inputmask"));

    //Get Data
    // $('#mySelect2').select2('data');

    $(".default-selectDriver").select2({
      placeholder: "Select A Driver",
      allowClear: true
    });
    $(".default-selectOperator").select2({
      placeholder: "Select A Operator",
      allowClear: true
    });

    $(".default-selectTaxi").select2({
      placeholder: "Select A Vehicle",
      allowClear: true
    });

    $("#datepicker-disabled-past").datepicker({
      todayHighlight: true,
      autoclose: true
    });

    $("#datepicker-disabled-past").datepicker("update", new Date());

    $(".nav-link").data("context", this.context);

    $(".nav-link").click(() => {
      setTimeout(() => {
        $(".default-select").select2({
          multiple: true,
          allowClear: true
        });

        $(".default-selectOperator")
          .val(null)
          .trigger("change");
        $(".default-selectTaxi")
          .val(null)
          .trigger("change");
        $(".default-selectTaxiDrivers")
          .val(null)
          .trigger("change");

        const context = $(".nav-link").data("context");
        context.FILTERBYENTITYTYPE(context, $(".nav-link.active").text());
      }, 500);
    });


    this.$store.dispatch("TA/getLOGOURL", {
      id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id
    }).then((result) => {
      this.context.encodedImage = result;
    }).catch((err) => {
    });

  }
};
</script>

<style scoped>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>

