<template>
  <div class="app">
    <!-- begin #page-loader -->
    <div id="page-loader" class="fade show">
      <span class="spinner"></span>
    </div>
    <!-- end #page-loader -->

    <!-- begin #page-container -->
      <!-- begin #header -->
    <headerComp/>
      <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">

		<!-- begin page-header -->
		<headerpagebreadcrumb />
		<!-- end page-header -->

      <!-- begin statsCards -->
      <statsCards />
      <!-- end statsCards -->

      <!-- begin row -->
      <div class="row">
        <!-- begin col-8 -->
        <div class="col-md-6 col-lg-3">
          <!-- begin panel -->
          <div class="panel panel-inverse" data-sortable-id="index-7">
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-danger"
                  data-click="panel-remove"
                >
                  <i class="fa fa-times"></i>
                </a>
              </div>
              <h4 class="panel-title">Taxi Association By TaxAffiation</h4>
            </div>
            <div class="panel-body">
              <div id="donut-chart" class="height-sm"></div>
            </div>
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-8 -->
        <!-- begin col-4 -->
        <div class="col-md-6 col-lg-3">
          <!-- begin panel -->
          <div class="panel panel-inverse">
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-danger"
                  data-click="panel-remove"
                >
                  <i class="fa fa-times"></i>
                </a>
              </div>
              <h4 class="panel-title">Taxi Operators By TaxAffiation</h4>
            </div>
            <div class="panel-body">
              <div id="nv-donut-chart" class="height-sm"></div>
            </div>
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-4 -->
        <!-- begin col-8 -->
        <div class="col-md-6 col-lg-3">
          <!-- begin panel -->
          <div class="panel panel-inverse" data-sortable-id="morris-chart-4">
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-danger"
                  data-click="panel-remove"
                >
                  <i class="fa fa-times"></i>
                </a>
              </div>
              <h4 class="panel-title">Taxi Vehicle By TaxAffiation</h4>
            </div>
            <div class="panel-body">
              <div id="morris-donut-chart" class="height-sm"></div>
            </div>
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-8 -->
        <!-- begin col-4 -->
        <div class="col-md-6 col-lg-3">
          <!-- begin panel -->
          <div class="panel panel-inverse">
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-danger"
                  data-click="panel-remove"
                >
                  <i class="fa fa-times"></i>
                </a>
              </div>
              <h4 class="panel-title">Taxi Driver By TaxAffiation</h4>
            </div>
            <div class="panel-body">
              <div id="nv-pie-chart" class="height-sm"></div>
            </div>
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-4 -->
      </div>
      <!-- end row -->
      <!-- begin row -->
      <div class="row">
        <!-- begin col-8 -->
        <div class="col-lg-8">
          <!-- begin panel -->
          <div class="panel panel-inverse">
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-danger"
                  data-click="panel-remove"
                >
                  <i class="fa fa-times"></i>
                </a>
              </div>
              <h4 class="panel-title">
                Taxi Association By Region
                <select
                  id="provinceFilter1"
                  class="ml-2 btn btn-success btn-xs"
                >
                  <option value="GP" disabled selected>Filter by province</option>
                  <option value="GP">Gauteng</option>
                  <option value="NW">North West</option>
                  <option value="LP">Limpopo</option>
                  <option value="KZN">Kwazulu Natal</option>
                  <option value="FS">Free State</option>
                  <option value="NC">Northen Cape</option>
                  <option value="WC">Western Cape</option>
                  <option value="EC">Eastern Cape</option>
                  <option value="MP">Mpumalanga</option>
                </select>
                <select id="affiliateFilter1" class="ml-2 btn btn-success btn-xs">
                  <option value="SANTACO" disabled selected>Filter by Affiliate</option>
                  <option value="SANTACO">SANTACO</option>
                  <option value="NTA">NTA</option>
                </select>
              </h4>
            </div>
            <div class="panel-body">
              <div id="nv-bar-chart" class="height-sm"></div>
            </div>
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-8 -->
        <!-- begin col-4 -->
        <div class="col-lg-4">
          <div class="panel panel-inverse" data-sortable-id="index-1">
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-danger"
                  data-click="panel-remove"
                >
                  <i class="fa fa-times"></i>
                </a>
              </div>
              <h4 class="panel-title">Taxi Association By Province</h4>
            </div>
            <div class="panel-body">
              <div id="donut-chart5" class="height-sm"></div>
            </div>
          </div>
        </div>
        <!-- end col-4 -->
      </div>
      <!-- end row -->
      <!-- begin row -->
      <div class="row">
        <!-- begin col-4 -->
        <div class="col-lg-4">
          <div class="panel panel-inverse" data-sortable-id="index-1">
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-danger"
                  data-click="panel-remove"
                >
                  <i class="fa fa-times"></i>
                </a>
              </div>
              <h4 class="panel-title">Taxi Operators By Province</h4>
            </div>
            <div class="panel-body">
              <div id="donut-chart6" class="height-sm"></div>
            </div>
          </div>
        </div>
        <!-- end col-4 -->
        <!-- begin col-8 -->
        <div class="col-lg-8">
          <!-- begin panel -->
          <div class="panel panel-inverse">
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-danger"
                  data-click="panel-remove"
                >
                  <i class="fa fa-times"></i>
                </a>
              </div>
              <h4 class="panel-title">
                Taxi Operators By Region
                <select
                  id="provinceFilter"
                  class="ml-2 btn btn-success btn-xs"
                >
                  <option value="GP" disabled selected>Filter by province</option>
                  <option value="GP">Gauteng</option>
                  <option value="NW">North West</option>
                  <option value="LP">Limpopo</option>
                  <option value="KZN">Kwazulu Natal</option>
                  <option value="FS">Free State</option>
                  <option value="NC">Northen Cape</option>
                  <option value="WC">Western Cape</option>
                  <option value="EC">Eastern Cape</option>
                  <option value="MP">Mpumalanga</option>
                </select>
                <select id="affiliateFilter" class="ml-2 btn btn-success btn-xs">
                  <option value="SANTACO" disabled selected>Filter by Affiliate</option>
                  <option value="SANTACO">SANTACO</option>
                  <option value="NTA">NTA</option>
                </select>
              </h4>
            </div>
            <div class="panel-body">
              <div id="nv-bar-chart2" class="height-sm"></div>
            </div>
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-8 -->
      </div>
      <!-- end row -->
      <!-- begin row -->
      <div class="row">
        <!-- begin col-8 -->
        <div class="col-lg-8">
          <!-- begin panel -->
          <div class="panel panel-inverse">
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-danger"
                  data-click="panel-remove"
                >
                  <i class="fa fa-times"></i>
                </a>
              </div>
              <h4 class="panel-title">
                Taxi Vehicles By Region
                <select
                  id="provinceFilter"
                  class="ml-2 btn btn-success btn-xs"
                >
                  <option value="GP" disabled selected>Filter by province</option>
                  <option value="GP">Gauteng</option>
                  <option value="NW">North West</option>
                  <option value="LP">Limpopo</option>
                  <option value="KZN">Kwazulu Natal</option>
                  <option value="FS">Free State</option>
                  <option value="NC">Northen Cape</option>
                  <option value="WC">Western Cape</option>
                  <option value="EC">Eastern Cape</option>
                  <option value="MP">Mpumalanga</option>
                </select>
                <select id="affiliateFilter" class="ml-2 btn btn-success btn-xs">
                  <option value="SANTACO" disabled selected>Filter by Affiliate</option>
                  <option value="SANTACO">SANTACO</option>
                  <option value="NTA">NTA</option>
                </select>
              </h4>
            </div>
            <div class="panel-body">
              <div id="nv-bar-chart3" class="height-sm"></div>
            </div>
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-8 -->
        <!-- begin col-4 -->
        <div class="col-lg-4">
          <div class="panel panel-inverse" data-sortable-id="index-1">
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-danger"
                  data-click="panel-remove"
                >
                  <i class="fa fa-times"></i>
                </a>
              </div>
              <h4 class="panel-title">Taxi Vehicles By Province</h4>
            </div>
            <div class="panel-body">
              <div id="donut-chart7" class="height-sm"></div>
            </div>
          </div>
        </div>
        <!-- end col-4 -->
      </div>
      <!-- end row -->
      <!-- begin row -->
      <div class="row">
        <!-- begin col-4 -->
        <div class="col-lg-4">
          <div class="panel panel-inverse" data-sortable-id="index-1">
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-danger"
                  data-click="panel-remove"
                >
                  <i class="fa fa-times"></i>
                </a>
              </div>
              <h4 class="panel-title">Taxi Drivers By Province</h4>
            </div>
            <div class="panel-body">
              <div id="donut-chart8" class="height-sm"></div>
            </div>
          </div>
        </div>
        <!-- end col-4 -->
        <!-- begin col-8 -->
        <div class="col-lg-8">
          <!-- begin panel -->
          <div class="panel panel-inverse">
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-danger"
                  data-click="panel-remove"
                >
                  <i class="fa fa-times"></i>
                </a>
              </div>
              <h4 class="panel-title">
                Taxi Drivers By Region
                <select
                  id="provinceFilter"
                  class="ml-2 btn btn-success btn-xs"
                >
                  <option value="GP" disabled selected>Filter by province</option>
                  <option value="GP">Gauteng</option>
                  <option value="NW">North West</option>
                  <option value="LP">Limpopo</option>
                  <option value="KZN">Kwazulu Natal</option>
                  <option value="FS">Free State</option>
                  <option value="NC">Northen Cape</option>
                  <option value="WC">Western Cape</option>
                  <option value="EC">Eastern Cape</option>
                  <option value="MP">Mpumalanga</option>
                </select>
                <select id="affiliateFilter" class="ml-2 btn btn-success btn-xs">
                  <option value="SANTACO" disabled selected>Filter by Affiliate</option>
                  <option value="SANTACO">SANTACO</option>
                  <option value="NTA">NTA</option>
                </select>
              </h4>
            </div>
            <div class="panel-body">
              <div id="nv-bar-chart4" class="height-sm"></div>
            </div>
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-8 -->
      </div>
      <!-- end row -->
    </div>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <a
      href="javascript:;"
      class="btn btn-icon btn-circle btn-success btn-scroll-to-top fade"
      data-click="scroll-top"
    >
      <i class="fa fa-angle-up"></i>
    </a>
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";

import sidebarComp from "@/components/common/sidebar.vue";

import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";

import statsCards from "@/components/charts/statsCards.vue";

export default {
  name: "sysadmin_dashboard",
  data: function() {
    return {
      message: "Some Message"
    };
  },
  components: {
		headerComp,
		sidebarComp,
		headerpagebreadcrumb,
		statsCards
  },
  methods: {},
  mounted: function() {
    // `this` points to the vm instance
	/*@@@@@@@@@@@@@@@@@@@@@@@@@@ PIE CHART START @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**/
	var handleDonutChart = function () {
	"use strict";
	//PIE CHART 1
	if ($('#donut-chart').length !== 0) {
		var donutData = [
			{ label: "SANTACO",  data: 8000, color: COLOR_PURPLE_DARKER},
		
			{ label: "NTA",  data: 9000, color: COLOR_PURPLE_LIGHTER},
			
		];
		$.plot('#donut-chart', donutData, {
			series: {
				pie: {
					innerRadius: 0.5,
					show: true,
					label: {
						show: true
					}
				}
			},
			legend: {
				show: true
			}
		});
	}

		//PIE CHART 6 taxi association by province 

		if ($('#donut-chart5').length !== 0) {
		var donutData = [
			
			{ label: "GP",  data: 12, color: COLOR_ORANGE},
			{ label: "NW",  data: 5, color: COLOR_GREEN},
			{ label: "LP",  data: 3, color: COLOR_PURPLE},
			{ label: "KZN",  data: 10, color: COLOR_AQUA},
			{ label: "MP",  data: 8, color: COLOR_GREY},
			{ label: "FP",  data: 7, color: COLOR_BLACK},
			{ label: "WC",  data: 6, color: COLOR_GREY_LIGHTER},
			{ label: "EC",  data: 2, color: COLOR_BLUE_DARKER},
			{ label: "NP",  data: 7, color: COLOR_BLUE_DARKER}
		];
		$.plot('#donut-chart5', donutData, {
			series: {
				pie: {
					innerRadius: 0.5,
					show: true,
					label: {
						show: true
					}
				}
			},
			legend: {
				show: true
			}
		});
	}




		//PIE CHART 7 taxi Operators by province 

		if ($('#donut-chart6').length !== 0) {
		var donutData = [
			
			{ label: "GP",  data: 12, color: COLOR_ORANGE},
			{ label: "NW",  data: 5, color: COLOR_GREEN},
			{ label: "LP",  data: 3, color: COLOR_PURPLE},
			{ label: "KZN",  data: 10, color: COLOR_AQUA},
			{ label: "MP",  data: 8, color: COLOR_GREY},
			{ label: "FP",  data: 7, color: COLOR_BLACK},
			{ label: "WC",  data: 6, color: COLOR_GREY_LIGHTER},
			{ label: "EC",  data: 2, color: COLOR_BLUE_DARKER},
			{ label: "NP",  data: 7, color: COLOR_BLUE_DARKER}
		];
		$.plot('#donut-chart6', donutData, {
			series: {
				pie: {
					innerRadius: 0.5,
					show: true,
					label: {
						show: true
					}
				}
			},
			legend: {
				show: true
			}
		});
	}
		//PIE CHART 8 taxi Vehicles by province 

		if ($('#donut-chart7').length !== 0) {
		var donutData = [
			
			{ label: "GP",  data: 12, color: COLOR_ORANGE},
			{ label: "NW",  data: 5, color: COLOR_GREEN},
			{ label: "LP",  data: 3, color: COLOR_PURPLE},
			{ label: "KZN",  data: 10, color: COLOR_AQUA},
			{ label: "MP",  data: 8, color: COLOR_GREY},
			{ label: "FP",  data: 7, color: COLOR_BLACK},
			{ label: "WC",  data: 6, color: COLOR_GREY_LIGHTER},
			{ label: "EC",  data: 2, color: COLOR_BLUE_DARKER},
			{ label: "NP",  data: 7, color: COLOR_BLUE_DARKER}
		];
		$.plot('#donut-chart7', donutData, {
			series: {
				pie: {
					innerRadius: 0.5,
					show: true,
					label: {
						show: true
					}
				}
			},
			legend: {
				show: true
			}
		});
	}	
		//PIE CHART 9 taxi Drivers by province 

		if ($('#donut-chart8').length !== 0) {
		var donutData = [
			
			{ label: "GP",  data: 12, color: COLOR_ORANGE},
			{ label: "NW",  data: 5, color: COLOR_GREEN},
			{ label: "LP",  data: 3, color: COLOR_PURPLE},
			{ label: "KZN",  data: 10, color: COLOR_AQUA},
			{ label: "MP",  data: 8, color: COLOR_GREY},
			{ label: "FP",  data: 7, color: COLOR_BLACK},
			{ label: "WC",  data: 6, color: COLOR_GREY_LIGHTER},
			{ label: "EC",  data: 2, color: COLOR_BLUE_DARKER},
			{ label: "NP",  data: 7, color: COLOR_BLUE_DARKER}
		];
		$.plot('#donut-chart8', donutData, {
			series: {
				pie: {
					innerRadius: 0.5,
					show: true,
					label: {
						show: true
					}
				}
			},
			legend: {
				show: true
			}
		});
	}
};
/*=================PIE CHART 2 Taxi Operators by TaxAffiliation AND PIE CHART 4 Taxi Driver by TaxAffiliation============*/
var handlePieAndDonutChart = function() {
	"use strict";
	var donutChartData = [
		{ 'label': 'NTA', 'value' : 300, 'color': COLOR_RED },  
		{ 'label': 'SANTACO', 'value' : 258, 'color': COLOR_AQUA }
	];
	var pieChartData = [
		{ 'label': 'NTA', 'value' : 300, 'color': COLOR_ORANGE },  
		{ 'label': 'SANTACO', 'value' : 258, 'color': COLOR_GREY }
	];
	nv.addGraph(function() {
		var pieChart = nv.models.pieChart()
		  .x(function(d) { return d.label })
		  .y(function(d) { return d.value })
		  .showLabels(true)
		  .labelType("percent")
		  .labelThreshold(.05);

		d3.select('#nv-pie-chart').append('svg')
		  .datum(pieChartData)
		  .transition().duration(350)
		  .call(pieChart);

		return pieChart;
	});
	nv.addGraph(function() {
		var chart = nv.models.pieChart()
		  .x(function(d) { return d.label })
		  .y(function(d) { return d.value })
		  .showLabels(true)
		  .labelThreshold(.05)
		  .labelType("percent")
		  .donut(true) 
		  .donutRatio(0.35);

		d3.select('#nv-donut-chart').append('svg')
		  .datum(donutChartData)
		  .transition().duration(350)
		  .call(chart);

		return chart;
	});
};
/*=============PIE CHART 3 Taxi Vehicle by TaxiAffiliation=====================*/
var handleMorrisDonusChart = function() {
	Morris.Donut({
		element: 'morris-donut-chart',
		data: [
			{label: 'SANTACO', value: 25 },
			{label: 'NTA', value: 40 },
		],
		formatter: function (y) { return y + "%" },
		resize: true,
		gridLineColor: [COLOR_GREY_LIGHTER],
		gridTextFamily: FONT_FAMILY,
		gridTextColor: FONT_COLOR,
		gridTextWeight: FONT_WEIGHT,
		gridTextSize: FONT_SIZE,
		colors: [COLOR_AQUA, COLOR_BLUE]
	});
};

/*@@@@@@@@@@@@@@@@@@@@@@@@@@ PIE CHART END @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**/

/*@@@@@@@@@@@@@@@@@@@@@@@@@@ BAR CHART START @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**/

var handleBarChart = function() {
	"use strict";
// Bar graph 1 Taxi Association By RegionF
	var barChartData = [{
		key: 'Cumulative Return',
		values: [
			{ 'label' : 'SA', 'value' : 29, 'color' : COLOR_RED }, 
			{ 'label' : 'NW', 'value' : 15, 'color' : COLOR_ORANGE }, 
			{ 'label' : 'LP', 'value' : 32, 'color' : COLOR_GREEN }, 
			{ 'label' : 'ff', 'value' : 196, 'color' : COLOR_AQUA },  
			{ 'label' : 'E', 'value' : 44, 'color' : COLOR_BLUE },  
			{ 'label' : 'F', 'value' : 98, 'color' : COLOR_PURPLE },  
			{ 'label' : 'G', 'value' : 13, 'color' : COLOR_GREY },  
			{ 'label' : 'H', 'value' : 5, 'color' : COLOR_BLACK }
		]
	}];

	nv.addGraph(function() {
		var barChart = nv.models.discreteBarChart()
		  .x(function(d) { return d.label })
		  .y(function(d) { return d.value })
		  .showValues(true)
		  .duration(250);

		barChart.yAxis.axisLabel("Total Sales");
		barChart.xAxis.axisLabel('Product');

		d3.select('#nv-bar-chart').append('svg').datum(barChartData).call(barChart);
		nv.utils.windowResize(barChart.update);

		return barChart;
	});


// Bar graph 2 Taxi Operators By Region
	var barChartData2 = [{
		key: 'Cumulative Return',
		values: [
			{ 'label' : 'SA', 'value' : 29, 'color' : COLOR_RED }, 
			{ 'label' : 'NW', 'value' : 15, 'color' : COLOR_ORANGE }, 
			{ 'label' : 'LP', 'value' : 32, 'color' : COLOR_GREEN }, 
			{ 'label' : 'ff', 'value' : 196, 'color' : COLOR_AQUA },  
			{ 'label' : 'E', 'value' : 44, 'color' : COLOR_BLUE },  
			{ 'label' : 'F', 'value' : 98, 'color' : COLOR_PURPLE },  
			{ 'label' : 'G', 'value' : 13, 'color' : COLOR_GREY },  
			{ 'label' : 'H', 'value' : 5, 'color' : COLOR_BLACK }
		]
	}];

	nv.addGraph(function() {
		var barChart2 = nv.models.discreteBarChart()
		  .x(function(d) { return d.label })
		  .y(function(d) { return d.value })
		  .showValues(true)
		  .duration(250);

		barChart2.yAxis.axisLabel("Total Sales");
		barChart2.xAxis.axisLabel('Product');

		d3.select('#nv-bar-chart2').append('svg').datum(barChartData2).call(barChart2);
		nv.utils.windowResize(barChart2.update);

		return barChart2;
	});
	// Bar graph 3 Taxi Operators By Region
	var barChartData3 = [{
		key: 'Cumulative Return',
		values: [
			{ 'label' : 'SA', 'value' : 29, 'color' : COLOR_RED }, 
			{ 'label' : 'NW', 'value' : 15, 'color' : COLOR_ORANGE }, 
			{ 'label' : 'LP', 'value' : 32, 'color' : COLOR_GREEN }, 
			{ 'label' : 'ff', 'value' : 196, 'color' : COLOR_AQUA },  
			{ 'label' : 'E', 'value' : 44, 'color' : COLOR_BLUE },  
			{ 'label' : 'F', 'value' : 98, 'color' : COLOR_PURPLE },  
			{ 'label' : 'G', 'value' : 13, 'color' : COLOR_GREY },  
			{ 'label' : 'H', 'value' : 5, 'color' : COLOR_BLACK }
		]
	}];

	nv.addGraph(function() {
		var barChart3 = nv.models.discreteBarChart()
		  .x(function(d) { return d.label })
		  .y(function(d) { return d.value })
		  .showValues(true)
		  .duration(250);

		barChart3.yAxis.axisLabel("Total Sales");
		barChart3.xAxis.axisLabel('Product');

		d3.select('#nv-bar-chart3').append('svg').datum(barChartData3).call(barChart3);
		nv.utils.windowResize(barChart3.update);

		return barChart3;
	});
		// Bar graph 4 Taxi Operators By Region
		var barChartData4 = [{
		key: 'Cumulative Return',
		values: [
			{ 'label' : 'SA', 'value' : 29, 'color' : COLOR_RED }, 
			{ 'label' : 'NW', 'value' : 15, 'color' : COLOR_ORANGE }, 
			{ 'label' : 'LP', 'value' : 32, 'color' : COLOR_GREEN }, 
			{ 'label' : 'ff', 'value' : 196, 'color' : COLOR_AQUA },  
			{ 'label' : 'E', 'value' : 44, 'color' : COLOR_BLUE },  
			{ 'label' : 'F', 'value' : 98, 'color' : COLOR_PURPLE },  
			{ 'label' : 'G', 'value' : 13, 'color' : COLOR_GREY },  
			{ 'label' : 'H', 'value' : 5, 'color' : COLOR_BLACK }
		]
	}];

	nv.addGraph(function() {
		var barChart4 = nv.models.discreteBarChart()
		  .x(function(d) { return d.label })
		  .y(function(d) { return d.value })
		  .showValues(true)
		  .duration(250);

		barChart4.yAxis.axisLabel("Total Sales");
		barChart4.xAxis.axisLabel('Product');

		d3.select('#nv-bar-chart4').append('svg').datum(barChartData4).call(barChart4);
		nv.utils.windowResize(barChart4.update);

		return barChart4;
	});
}



/*==================For taxi association by province and taxiAffiliation ==========*/

var barChartData = {
	labels: ['Gauteng', 'Kwazulu Natal', 'North West', 'Limpopo','Mpumalanga' ,'North West', 'Northen Province','Eastern cape', 'Western cape'],
	datasets: [{
		label: 'SANTACO',
		borderWidth: 2,
		borderColor: COLOR_PURPLE,
		backgroundColor: COLOR_BLACK_TRANSPARENT_3,
		data: [randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor()]
	}, {
		label: 'NTA',
		borderWidth: 2,
		borderColor: COLOR_BLACK,
		backgroundColor: COLOR_RED_TRANSPARENT_3,
		data: [randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(),randomScalingFactor(), randomScalingFactor()]
	}]
};


/*==================For taxi Operators by province and taxiAffiliation==========*/
var barChartData2 = {
	labels: ['Gauteng', 'Kwazulu Natal', 'North West', 'Limpopo','Mpumalanga' ,'North West', 'Northen Province','Eastern cape', 'Western cape'],
	datasets: [{
		label: 'SANTACO',
		borderWidth: 2,
		borderColor: COLOR_PURPLE,
		backgroundColor: COLOR_PURPLE,
		data: [randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor()]
	}, {
		label: 'NTA',
		borderWidth: 2,
		borderColor: COLOR_BLACK,
		backgroundColor: COLOR_GREEN,
		data: [randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(),randomScalingFactor(), randomScalingFactor()]
	}]
};
/*==================For taxi Vehicles by province and taxiAffiliation==========*/
var barChartData3 = {
	labels: ['Gauteng', 'Kwazulu Natal', 'North West', 'Limpopo','Mpumalanga' ,'North West', 'Northen Province','Eastern cape', 'Western cape'],
	datasets: [{
		label: 'SANTACO',
		borderWidth: 2,
		borderColor: COLOR_PURPLE,
		backgroundColor: COLOR_ORANGE_TRANSPARENT_3,
		data: [randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor()]
	}, {
		label: 'NTA',
		borderWidth: 2,
		borderColor: COLOR_BLACK,
		backgroundColor: COLOR_AQUA,
		data: [randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(),randomScalingFactor(), randomScalingFactor()]
	}]
};
/*==================For taxi Drivers by province and taxiAffiliation==========*/
var barChartData4 = {
	labels: ['Gauteng', 'Kwazulu Natal', 'North West', 'Limpopo','Mpumalanga' ,'North West', 'Northen Province','Eastern cape', 'Western cape'],
	datasets: [{
		label: 'SANTACO',
		borderWidth: 2,
		borderColor: COLOR_PURPLE,
		backgroundColor: COLOR_PURPLE_TRANSPARENT_3,
		data: [randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor()]
	}, {
		label: 'NTA',
		borderWidth: 2,
		borderColor: COLOR_BLACK,
		backgroundColor: COLOR_BLACK_TRANSPARENT_3,
		data: [randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(),randomScalingFactor(), randomScalingFactor()]
	}]
};
//
var handleChartJs = function() {
	/*==================For taxi association by province and taxiAffiliation==========*/

	/*var ctx = document.getElementById('bar-chart').getContext('2d');
	var barChart = new Chart(ctx, {
		type: 'bar',
		data: barChartData
	});
*/
	/*==================For taxi Operators by province and taxiAffiliation==========*/
	/*var ctx2 = document.getElementById('bar-chart2').getContext('2d');
	var barChart = new Chart(ctx2, {
		type: 'bar',
		data: barChartData2
	});
*/
	/*==================For taxi Vehicles by province and taxiAffiliation==========*/
	/*var ctx3 = document.getElementById('bar-chart3').getContext('2d');
	var barChart = new Chart(ctx3, {
		type: 'bar',
		data: barChartData3
	});*/
	/*==================For taxi Drivers by province and taxiAffiliation==========*/
	/*var ctx4 = document.getElementById('bar-chart4').getContext('2d');
	var barChart = new Chart(ctx4, {
		type: 'bar',
		data: barChartData4
	});*/
};

/*@@@@@@@@@@@@@@@@@@@@@@@@@@ BAR CHART END @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**/


 COLOR_GREEN = '#5AC8FA';
			App.init();
			DashboardV2.init();
			
			handleDonutChart();
			handleChartJs();
			//handleMorrisBarChart();
			handleMorrisDonusChart();
			handlePieAndDonutChart();
			handleBarChart();

  }
};
</script>
