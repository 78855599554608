<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TaxiAssociation'"
      :ActiveMenu="'ReceiptStock'"
    />

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Manage Taxi Association"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Association"
        breadcrumbitem3="Receipt Stock"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <h4 class="panel-title">Receipt Management</h4>
            </div>
            <!-- end panel-heading -->

            <!-- begin panel-body -->
            <div class="table-responsive">
                <table class="table">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Added By</th>
                    <th class="text-nowrap">Current stock</th>
                    <th class="text-nowrap">Remaining Stock</th>
                    <th class="text-nowrap">Minimum Stock</th>
                    <th class="text-nowrap">Last Updated By</th>
                    <th class="text-nowrap">Last Update Date</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-bind:key="item._id"
                    v-for="(item,index) in GETASSOCIATIONRECEIPTSTOCK"
                    :class="{'success': item.date_deleted}"
                  >
                    <td class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.added_by_name}}</td>
                    <td class="text-green-darker">{{item.current_stock}}</td>
                    <td class="text-blue-darker">{{item.remaining_stock}}</td>
                    <td class="text-red-darker">{{item.minimum_stock}}</td>
                    <td>{{item.updated_by_name}}</td>
                    <td>{{item.date_updated | formatToShortDate}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <button
                        v-if="item.remaining_stock <= item.minimum_stock"
                        type="button"
                        class="btn btn-sm btn-primary width-80"
                      >Restock</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TAB");

export default {
  name: "ManageTaxiAssociation",
  data: function() {
    return {
      TAXIAFFILIATION: this.$store.getters["taxiAffiliation/GETALLACTIVE"],
      GETALLPROVINCES: this.$store.getters["lookups/GETALLPROVINCES"],
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
      addedsuccess: undefined,
      errormessage: undefined,
      GETSELECTED: Object.assign({}, this.$store.getters["district/GETALL"]),
      GETADDONSERVICETYPES: JSON.parse(
        JSON.stringify(
          Object.assign({}, this.$store.getters["lookups/GETADDONSERVICETYPES"])
        )
      ),
      ADDONSERVICES: {
        _taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        _id: null,
        _added_by: this.$store.getters["auth/USERPROFILE"].system_usersid
      },
      added: false,

      ADDONSERVICETYPES: []
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  beforeMount: function() {
    this.getById(this.context);
  },

  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["GETASSOCIATIONRECEIPTSTOCK"]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions(["getall", "delete", "edit", "add"]),
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },

    add: (context, item) => {
      context.$Notify
        .YesNo(false, { context, item })
        .then(value => {
          
          if (value) {
            value.context.$wait.start("add");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("TA/addAddOns", {
                _dataObj: value
              })
              .then(() => {
                $("#AddModal").modal("hide");
                value.context.$wait.end("add");
                value.context.$Notify.Success("Successfully Added!", "");
                value.context.added = true;
              })
              .catch(error => {
                value.context.$wait.end("add");
                value.context.added = false;
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    setAdd(val) {
      this.$store
        .dispatch("add", {
          _dataObj: val
        })
        .then(() => {});
    },
    getById: context => {
      context.$store
        .dispatch("TAB/getReceiptstockById", {
          _dataObj: {
            id: context.taxi_association_id,
            router: context.$router
          }
        })
        .then((data) => {
          console.log('these are the results',data);

          App.init();
        });
    },
    edited: (context, item, updatedby) => {
      //item = Object.assign({}, item);
      context.$Notify.YesNo(false, { context, item, updatedby }).then(value => {
        if (value) {
          value.context.$wait.start("edited");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("district/update", {
              _dataObj: value
            })
            .then(() => {
              $("#EditModal").modal("hide");
              value.context.$wait.end("edited");
              value.context.$Notify.Success("Successfully Updated!", "");
            })
            .catch(error => {
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {
              value.context.$wait.end("edited");
            });
        }
      });
    },
    deletedata: (context, item, deletedby) => {
      context.$Notify
        .YesNo(false, { context, id: item._id, deletedby })
        .then(value => {
          if (value) {
            value.context.$wait.start("delete-" + value.id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("district/markAsdelete", {
                _dataObj: value
              })
              .then(id => {
                value.context.$wait.end("delete-" + id);
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch(error => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    restoredelete: (context, item, restoredby) => {
      context.$Notify
        .YesNo(false, { context, id: item._id, restoredby })
        .then(value => {
          if (value) {
            value.context.$wait.start("restoreDeleted-" + value.id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("district/restoreDeleted", {
                _dataObj: value
              })
              .then(id => {
                value.context.$wait.end("restoreDeleted-" + id);
                value.context.$Notify.Success("Successfully Restored!", "");
              })
              .catch(error => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    }
  },
  mounted: function() {

    $(".btnadded")
      .mouseenter(function() {
        $(this)
          .removeClass("btn-success")
          .addClass("btn-danger")
          .text("Remove");
      })
      .mouseleave(function() {
        $(this)
          .removeClass("btn-danger")
          .addClass("btn-success")
          .text("Added");
      });
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

