<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'BookASeat'"
      :ActiveMenu="'NewRank'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="New Taxi Rank"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Book-A-Seat"
        breadcrumbitem3="New Taxi Rank"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

           <!-- begin nav-pills -->
      <ul class="nav nav-pills">
        <li class="nav-items">
          <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active">
            <span class="d-sm-none">Add Rank</span>
            <span class="d-sm-block d-none">Add Rank</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">View Rank</span>
            <span class="d-sm-block d-none">View Rank</span>
          </a>
        </li>
      </ul>
      <!-- end nav-pills -->

        <!-- begin tab-content -->
        <div class="tab-content">

      <div class="tab-pane fade active show" id="nav-pills-tab-1">
        <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Vehicle Info
                      <small class="text-ellipsis">License No, Make, Model</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-3">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Complete Registration</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->
                    <div class="row">
                      <div class="col-md-8 offset-md-2">
                        <legend
                          class="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-inverse"
                        >Enter Taxi Rank Details</legend>


                           <!-- end form-group -->
                           <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                           Select Countries
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <select
                                  class="form-control"
                                  id="select_prov"
                                  data-parsley-required="true"
                                  data-parsley-group="step-1"
                                  @change="GetProvincesById(context,$event.target.value)"
                                  
                                >
                                  <option
                                  v-for="(item) in COUNTRIES"
                                  :key="item.id"
                                  :value="item.id"
                                  >{{item.name}}</option>
                                </select>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                           Select Province
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <select
                                  class="form-control"
                                  id="select_prov"
                                  dadata-parsley-required="true"
                                  data-parsley-group="step-1"
                                  @change="GetCitiesById(context,$event.target.value)"
                                >
                                <option
                                  v-for="(item) in PROVINCES"
                                  :key="item.id"
                                  :value="item.id"
                                  >{{item.name}}</option>
                                </select>
                          </div>
                        </div>
                        <!-- end form-group -->
                        
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                           Select City
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <select
                                  class="form-control"
                                  id="selecttamsops"
                                  data-parsley-required="true"
                                  data-parsley-group="step-1"
                                  @change="GetSurburbById(context,$event.target.value)"
                                  v-model="ADDSYSTEMUSER._city_id"
                                >
                                <option
                                  v-for="(item) in CITIES"
                                  :key="item.id"
                                  :value="item.id"
                                  >{{item.name}}</option>
                                </select>
                          </div>
                        </div>
                        <!-- end form-group -->


                            <!-- end form-group -->
                            <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                           Select Suburb
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <select
                                  class="form-control"
                                  id="selecttamsops"
                                  data-parsley-required="false"
                                  data-parsley-group="step-1"
                                  v-model="ADDSYSTEMUSER._surburb_id"
                                >
                                <option
                                  v-for="(item) in SUBURBS"
                                  :key="item.id"
                                  :value="item.id"
                                  >{{item.name}}</option>
                                 </select>
                          </div>
                        </div>
                        <!-- end form-group -->


                            <!-- end form-group -->
                            <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                           Select TAMS Rank
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <select
                                  class="form-control"
                                  id="selecttamsops"
                                  data-parsley-required="true"
                                  data-parsley-group="step-1"
                                  v-model="tams_rank"
                                >
                                  <option
                                  v-for="(item) in ASSOCIATIONRANKS"
                                  :key="item.id"
                                  :value="item"
                                  >{{item.name}}</option>
                                </select>
                          </div>
                        </div>
                        <!-- end form-group -->

        

                      </div>

                 
                    </div>
                    <!-- end row -->
                  </fieldset>
                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->
              

                <!-- begin step-3 -->
                <div id="step-3">
                  <div class="jumbotron m-b-0 text-center">
                    <h2 class="text-inverse">Rank Registered Successfully</h2>
                    <p class="m-b-30 f-s-16 ">
                     
                    </p>
                    <p>
                      <a href="#" class="btn btn-primary btn-lg hide">Proceed to User Profile</a>
                    </p>
                  </div>
                </div>
                <!-- end step-3 -->
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>
        <!-- end col-12 -->
      </div>
      </div>

      <!-- begin row -->

       <!-- begin tab-pane -->
       <div class="tab-pane fade" id="nav-pills-tab-2">
        <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <div class="table-responsive">
            <table class="table table-invoice">
              <thead>
                <tr>
                  <th>NAME</th>
                  <th>DESCRIPTION</th>
                  <th>SUBURB</th>
                  <th>CITY</th>
                  <th>PROVINCE</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item) in GETALL"
                  v-bind:key="item.id"
                >
                  <td>{{item.name}}</td>
                  <td>{{item.description}}</td>
                  <td>{{item.suburb_name}}</td>
                  <td>{{item.cityname}}</td>
                  <td>{{item.province_name}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
        </div>

      </div>
        <!-- end tab-pane -->

      

      <!-- end row -->

  

      <!-- begin #Main Body Template -->

      

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("tpay_systemuser");

export default {
  name: "AddSystemUser",
  data: function() {
    return {
      BANKS:[],
      TPAYPROFILE: this.$store.getters["auth/TPAYPROFILE"],
      isUserNameExists: true,
      ISMOBILEVERIFIED:false,
      GETDRIVERSBYASSID:[],
      COUNTRIES:[],
      COUNTRYID:0,
      PROVINCES:[],
      CITIES:[],
      SUBURBS:[],
      GETALL:[],
      ASSOCIATIONRANKS:[],
      OPT:"",
      USERNAME: "",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      SERPROFILE: this.$store.getters["auth/USERPROFILE"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      selectedUserGroupId: 0,
      GETALLUSERGROUPS: [],
      resolutionWidth: undefined,
      resolutionHeight: undefined,
      hacksmartwizard: false,
      tams_rank: null,
      ADDSYSTEMUSER: {
        _taxi_association_id:this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
        _city_id:0,
        _surburb_id:0,
        tams_rank_id: 0,
        _name : "",
        _description : "",
        addedby: this.$store.getters["auth/TPAYPROFILE"].SystemUsersId,
    },
    VERIFIY: {
        user_id: "",
        webpushtoken: "",
        one_time_pin: "",
      },
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    CapturePhotosControl
  },
  computed: {
    ...mapGetters(["GETALLTITLES", "GETALLGENDERS"])
  },
  watch: {
    USERNAME: function(val) {
      if (val.toString().length > 3) {
        this.context.$store
          .dispatch("tpayauth/LicenseNoExists", {
            licencenumber: val
          })
          .then(rb => {
            
            this.isUserNameExists = rb.Status;
          });
      }
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted"
    ]),
    init: file => {},
    GetALLRANKSById: (context,id) => {
      context.$store
        .dispatch("tpay_systemuser/GetALLRANKSById", {
          id
        })
        .then((rb) => {

          context.GETALL = rb.Data
          
        }).finally((error)=>{
          var options = {
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false,
                    order: [[0, 'asc']]
                  };

                  // if ($(window).width() <= 767) {
                  //   options.rowReorder = false;
                  //   options.colReorder = false;
                  //   options.autoFill = false;
                  // }

 	                  $(".table-invoice").DataTable(options);
        })
    },
    GetCountries: (context) => {
      context.$store
        .dispatch("tpay_systemuser/GetCountries", {
          
        })
        .then((rb) => {

          context.COUNTRIES = rb.Data
          
        });
    },
    GetProvincesById: (context,id) => {
      context.$store
        .dispatch("tpay_systemuser/GetProvincesById", {
          id: parseInt(id),
        })
        .then((rb) => {

          context.PROVINCES = rb.Data
          
        });
    },
    GetCitiesById: (context,id) => {
      context.$store
        .dispatch("tpay_systemuser/GetCitiesById", {
          id: parseInt(id),
        })
        .then((rb) => {

          context.CITIES = rb.Data
          
        });
    },
    GetSurburbById: (context,id) => {
      context.$store
        .dispatch("tpay_systemuser/GetSurburbById", {
          id: parseInt(id),
        })
        .then((rb) => {

          context.SUBURBS = rb.Data
          
        });
    },
    getbyAssId: context => {
      context.$store
        .dispatch("TA/getAssociationRanksByAssId", {
          id: context.taxi_association_id,
          router: context.$router
        })
        .then((data) => {
          context.ASSOCIATIONRANKS = data;
          
        }).catch((error)=>{
          console.log("these are the getter errors:",error);
        })
        .finally((error)=>{
           $('#data-table-default').DataTable({
			responsive: true
		});
        })
    },
  },
  mounted: function() {
    //this.get(this.context);

    App.init();

    this.GetCountries(this.context);
    this.getbyAssId(this.context);
    this.GetALLRANKSById(this.context,this.taxi_association_id);

    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "slide",
      transitionSpeed: 400,
      useURLhash: false,
      showStepURLhash: false,
      toolbarSettings: {
        toolbarPosition: "bottom"
      }
    });

    $("#wizard").data("context", this.context);

    $("#wizard").on("leaveStep", function(
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {
     
      const context = $("#wizard").data("context");
      const currentstep = stepNumber + 1;
      var res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

        if(context.hacksmartwizard)
        return true;


      $(".sw-btn-next").text("Next");

      if (currentstep === 1 && stepDirection == "forward") {
        
        if (!res){
          return res;
        }
      
          $(".sw-btn-next").text("Submit");
          
      }

      if (currentstep === 1 && stepDirection == "forward") {

           const context = $("#wizard").data("context");

           context.ADDSYSTEMUSER.tams_rank_id = context.tams_rank.id;
           context.ADDSYSTEMUSER._name = context.tams_rank.name;
           context.ADDSYSTEMUSER._description = context.tams_rank.name;

           

        context.$Notify
          .YesNo(false, { context: context, item: context.ADDSYSTEMUSER })
          .then(value => {
            if (value) {
              $(".sw-btn-next")
                .text("")
                .append('<i class="fas fa-spinner fa-spin"></i>')
                .attr("disabled", "disabled");
              value.router = value.context.$router;
              value.context.$store
                .dispatch("tpay_systemuser/AddTaxiRank", value.item)
                .then((parsedResult) => {
                  if(!parsedResult.Status){
                    $(".sw-btn-next")
                    .text("Submit")
                    .removeAttr("disabled", "disabled");
                    value.context.$Notify.Error(parsedResult.Message, "");
                    return
                  }
                   value.context.hacksmartwizard = true;
            $("#wizard").smartWizard("next");
                  $(".sw-btn-next")
                    .text("Done")
                    .attr("disabled", "disabled");
                })
                .catch(error => {
                  $(".sw-btn-next")
                    .text("Submit")
                    .removeAttr("disabled", "disabled");

                  value.context.$Notify.Error(parsedResult.Message, "");
                })
                .finally(() => {});
            }
          });

        return false;
      }

      return res;
    });

  }
};
</script>

<style scoped>
</style>

