/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import configbase from '../../configurations/config';
import config from '../../configurations/configbase';

export default {
  namespaced: true,
  state: {
    taxi_operator: [],
    taxi_operators: [],  
    taxi_operatorsNoPic: [],
    taxi_operatorsByidNoPic:[],
    taxi_operatorsByAffNoPic:[],
    operator_numbers: [],
    operator_supportingDocs: [],
    operator_timeline: [],
    operator_status: [],
    operator_billings:[],
    operator_transactions: [],
    operator_invoiceDisputes: [],
    operator_receiptsDisputes: [],
    LookupCity:[],
    taxi_operators_pendingVerification: [],
    taxi_operatorbyAccNo: [],
    taxi_operator_vehicles: [],
    banks: [],
    accounttype: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETUPLOADEDFILES: (state, getters, rootState, rootGetters) => {
      return rootGetters['fileupload/GETALL']
      },
   GETOPERATOR: state => state.taxi_operator,
   GETOPERATORNUMBERS: state => state.operator_numbers,
   GETOPERATORSUPPORTINGDOCS: state => state.operator_supportingDocs,
   GETOPERATORTIMELINE: state => state.operator_timeline,
   GETOPERATORSTATUS: state => state.operator_status,
   GETOPERATORBILLINGS: state => state.operator_billings,
   GETOPERATORTRANSACTIONS: state => state.operator_transactions,
   GETOPERATORINVOICEDISPUTES: state => state.operator_invoiceDisputes,
   GETOPERATORRECEIPTSDISPUTES: state => state.operator_receiptsDisputes,
   GETALLPROVINCES(state, getters, rootState, rootGetters)
   { 
   return rootGetters['lookups/GETALLPROVINCES'];
  },
  GETALLCITY: state => state.LookupCity,
  GETOPERATORS: state =>  state.taxi_operators,
  GETOPERATORSNOPIC: state => state.taxi_operatorsNoPic,
  GETOPERATORSBYAFFILLIATION: state => state.taxi_operatorsByAffNoPic,
  GETOPERATORSBYIDNOPIC: state => state.taxi_operatorsByidNoPic,
  GETOPERATORSPENDINGVERIFICATION: state => state.taxi_operators_pendingVerification,
  GETOPERATORSBYACCOUNTNO: state => state.taxi_operatorbyAccNo,
  GETOPERATORVEHICLESBYID: state => state.taxi_operator_vehicles,  
  GETBANKS: state => state.banks,
  GETACCOUNTTYPE: state => state.accounttype,
},
mutations: {
  NUMBERSLOADED: (state, _data) => {
    if (_data !== undefined) {
      
      state.operator_numbers = _data;
    }
  },
  DOCSLOADED: (state, _data) => {
    if (_data !== undefined) {
      
      state.operator_supportingDocs = _data;
    }
  },
  TIMELINELOADED: (state, _data) => {
    if (_data !== undefined) {
      
      state.operator_timeline = _data;
    }
  },
  STATUSLOADED: (state, _data) => {
    if (_data !== undefined) {
      
      state.operator_status = _data;
    }
  },
  OPERATORBILLINGSLOADED: (state, _data) => {
    if (_data !== undefined) {
      
      state.operator_billings = _data;
    }
  },
  TRANSACTIONSLOADED: (state, _data) => {
    if (_data !== undefined) {
      state.operator_transactions = _data;
    }
  },
  INVOICEDISPUTESLOADED: (state, _data) => {
    if (_data !== undefined) {
      state.operator_invoiceDisputes = _data;
    }
  },
  RECEIPTSDISPUTESLOADED: (state, _data) => {
    if (_data !== undefined) {
      state.operator_receiptsDisputes = _data;
    }
  },
  LOADED: (state, _data) => {
    if (_data !== undefined) {
      
      state.taxi_operator = _data;
    }
  },

  BANKSLOADED: (state, _data) => {
    if (_data !== undefined) {
      
      state.banks = _data;
    }
  },

  ACCOUNTTYPELOADED: (state, _data) => {
    if (_data !== undefined) {
      
      state.accounttype = _data;
    }
  },


  OPERATORSLOADED: (state, _data) => {
    if (_data !== undefined) {
      state.taxi_operators = _data;
    }
  },

  OPERATORVEHICLESLOADED: (state, _data) => {
    if (_data !== undefined) {
      state.taxi_operator_vehicles = _data;
    }
  },

OPERATORSBYACCOUNTLOADED: (state, _data) => {
    if (_data !== undefined) {
      state.taxi_operatorbyAccNo = _data;
    }
  },

  OPERATORSNOPICLOADED: (state, _data) => {
    if (_data !== undefined) {
      state.taxi_operatorsNoPic = _data;
    }
  },
  OPERATORSBYAFFNOPICLOADED: (state, _data) => {
    if (_data !== undefined) {
      state.taxi_operatorsByAffNoPic = _data;
    }
  },
  OPERATORSPENDINGVERIFICATION: (state, _data) => {
    if (_data !== undefined) {
      state.taxi_operators_pendingVerification = _data;
    }
  },

  

  OPERATORBYIDNOPIC: (state, _data) => {
    if (_data !== undefined) {
      state.taxi_operatorsByidNoPic = _data;
    }
  },
  ADDED: (state, _data) => {
    state.taxi_operator.push(_data);
  },
  UPDATED: (state, _data) => {
    if (_data !== undefined) {
      Object.assign(state.taxi_operator.filter(item => item._id === _data._id)[0], _data);
    }
  },
  RESTOREDELETED: (state, { id }) => {
    if (id !== undefined) {
      state.taxi_operator.filter(item => item._id === id)[0]._deleted_by = undefined;
      state.taxi_operator.filter(item => item._id === id)[0]._date_deleted = undefined;
    }
  },
  MARKASDELETE: (state, { id, deletedby }) => {
    if (id !== undefined) {
      state.taxi_operator.filter(item => item._id === id)[0]._deleted_by = deletedby;
      state.taxi_operator.filter(item => item._id === id)[0]._date_deleted = Date.now;
    }
  },
  LOADEDCITY: (state, _data) => {
    if (_data !== undefined) {
      state.LookupCity = _data;
    }
  },
  CLEARALL: (state) => {
    state.taxi_operator = [];
    state.taxi_operators = [];
    state.taxi_operatorsNoPic = [];
    state.taxi_operatorsByAffNoPic = [];
    state.operator_numbers = [];
    state.operator_supportingDocs = [];
    state.operator_timeline = [];
    state.operator_status = [];
    state.operator_billings =[];
    state.operator_transactions = [];
    state.operator_invoiceDisputes = [];
    state.operator_receiptsDisputes = [];
    state.LookupCity = [];
},
},
  actions: {
    /* Async */
    clearAll({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }) {
      /* Auth.js Mutations */
      console.log('page cleared!')
      commit('CLEARALL');
    },
    loadcitybyprovince({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
  
        const { router, id } = _dataObj;
        let requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.CITY_URL,
        };

        const requestpayload = {
          status: true,
          skip: 0,
          len: 1000,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((Result_City) => {
     
          if (Result_City.status) {
            commit('LOADEDCITY', Result_City.data);
           
            // navigateTo(router, parsed_result.data[0]);
            resolve();
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });

      });
    },

    updateOperatorSupportingDocs({
      commit,
      state,
      dispatch,
      rootGetters
    }, {
      addModel,
      router,
    }) {
      return new Promise((resolve, reject) => {

        

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.OPERATORSUPPORTINGDOCSADD_URL,
        };

        this._vm.$TAMS_API.add(requestheader, addModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('ADDED', parsedResult.data)
            resolve(parsedResult.data)
            console.log('updated succesfully')
          } else {
            // commit('EXTERNALUPDATED', parsedResult.data);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },

    getOperatorById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id, router
    }) {
      return new Promise((resolve, reject) => {
        

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TO_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('LOADED', parsedResult.data);
            resolve(parsedResult.data[0]);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    // getOperatorTrafficFinesById({
    //   state,
    //   rootState,
    //   rootGetters,
    //   commit,
    //   dispatch,
    //   getters,
    // }, {
    //   id,skip,len,router
    // }) {
    //  
    //   return new Promise((resolve, reject) => {
       
    //     const requestheader = {
    //       router,
    //       token: rootGetters['auth/TOKEN'],
    //       apiName: config.GETOPERATORTRAFFICFINESBYID_URL,
    //     };

    //     this._vm.$TAMS_API.getByPaging(requestheader,
    //       {status: id, skip, len }
    //       ).then((parsedResultOP) => {
    //         
    //         console.log(parsedResultOP);
    //         if (parsedResultOP.status) {
    //           // commit('BILLINGLOADED', parsedResultOP.data);
    //           resolve(parsedResultOP.data);
              
    //           // navigateTo(router, parsed_result.data[0]);
    //         }
    //       }).catch((error) => {
    //         console.log('traffic fines errors: ',error);
    //         reject(error);
    //         // commit('', error.data);
    //       });
    //   });
    // },

    getOperatorByIdNopic({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id, router
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TOBYIDNOPIC_URL ,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            console.log("this is the operator: ",parsedResult.data);
            // commit('OPERATORBYIDNOPIC', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {

          console.log("operator errors: ",error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    searchOperator({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _getPagingModel,router }) {
      return new Promise((resolve, reject) => {
  
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SEARCHBYOPERATOR,
        };
        this._vm.$TAMS_API.getByPaging2(requestheader, _getPagingModel).then((parsedResult) => {
          if (parsedResult.status) {
            console.log('search operator results: ',parsedResult.data);
            // commit('OPERATORSLOADED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('search operator errors: ',error);
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getSearchOperatorIDnumber({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _getPagingModel, router }) {
      
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.OPERATOREXISTS_URL,
        };
       
        this._vm.$TAMS_API.getByPaging2(requestheader, _getPagingModel ).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          // navigateTo(router, parsed_result.data[0]);
          }
          else
          reject(parsedResult);
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getOperatorByAccountNo({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
       router,id
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETOPERATORSBYACCOUNTNO_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            console.log("these are the operators: ",parsedResult.data);
            // commit('OPERATORSBYACCOUNTLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {

          console.log("operator errors: ",error);
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getOperatorNumbersById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          id,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETOPERATORMOBILENUMBERS_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('NUMBERSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    
    getOperatorSupportingDocsById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          id,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETOPERATORSUPPRTINGDOCS_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('DOCSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getOperatorTimelineById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          id,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETOPERATORTIMELINE_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('TIMELINELOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getOperatorStatusByAssociationId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          id,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETOPERATORTSTATUS_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('STATUSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getOperatorTransactionsByOperatorId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { id, skip,len,router }) {
      
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.GETOPERATORTRANSACTIONSBYID_URL,
      };

      this._vm.$TAMSFIN_API.getByPaging(requestheader, { status: id, skip: skip, len: len }
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('OPERATORBILLINGSLOADED', parsedResultOP.data);
          resolve(parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        reject(error);
        // commit('LOGINFAILED', error.data);
      });
    });
    },

    getOperatorBillingsById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { id, skip,len,router }) {
      
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.GETOPERATORBILLINGS_URL,
      };

      this._vm.$TAMSFIN_API.getByPaging(requestheader, { status: id, skip: skip, len: len }
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('OPERATORBILLINGSLOADED', parsedResultOP.data);
          resolve(parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        reject(error);
        // commit('LOGINFAILED', error.data);
      });
    });
    },

    
    

    getOperatorTransactionsById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      taxiassociationid,
      accountNumber,
      skip,
      length,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        if(accountNumber != ""){
          const requestheader = {
            router,
            token: rootGetters['auth/TOKEN'],
            apiName: config.GETOPERATORTRANSACTIONS_URL,
          };
  
          this._vm.$TAMSFIN_API.getbyAccountNumber(requestheader, {
            associationsid: taxiassociationid, accountnumber:accountNumber, skip, length
          }).then((parsedResult) => {
            if (parsedResult.status) {
              // commit('TRANSACTIONSLOADED', parsedResult.data);
              resolve(parsedResult.data);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            console.log("transactions errors: ",error)
            // commit('CLEARTRANSACTIONS', error);
            reject(error);
          });
        }
        else{
          // commit('CLEARTRANSACTIONS', parsedResult.data);
          resolve(parsedResult.data);
        }
        
      });
    },

    getOperatorInvoiceDisputesById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          id,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETOPERATORINVOICEDISPUTES_URL,
        };
        this._vm.$TAMSFIN_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('INVOICEDISPUTESLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getoperatorsSMSes({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      return new Promise((resolve, reject) => {
       

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETOPERATORSMS_URL,
        };
        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('INVOICEDISPUTESLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getOperatorReceiptsDisputesById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          id,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETOPERATORRECEIPTSDISPUTES_URL,
        };
        this._vm.$TAMSFIN_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('RECEIPTSDISPUTESLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    
    selected({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _id }) {
      const _taxiass = state.taxiassociations.filter(t => t._taxi_associations_id == _id)[0];

      /* Auth.js Mutations */
      commit('auth/UPDATETAXIASSOCIATION', {
        id: _taxiass._taxi_associations_id,
        name: _taxiass._name,
        abbr: _taxiass._abbriviated_name,
        code: _taxiass._code,
      }, { root: true });
    },
    getByPaging({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _waitObj }) {
      const { _wait } = _waitObj;
      const _waitname = _waitObj.name;
      const router = _waitObj._router;

      if (_waitname) { _wait.start(_waitname); }
      this._vm.$TAMS_API.getByPaging(router, rootGetters['auth/TOKEN'],
        config.TA_URL,
        {
          isactive: true,
          skip: 0,
          length: 1,
        }).then((parsedResult) => {
        console.log(parsedResult);
        if (parsedResult.status) {
          commit('TAXIASSLOADED', parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
        }

        if (_waitname) { _wait.end(_waitname); }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
        if (_waitname) { _wait.end(_waitname); }
      });
    },
    getAllByTaxiAssID({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TOBYTAXIASSID_URL,
      };

      this._vm.$TAMS_API.getById(requestheader,
      id
      ).then((parsedResultOP) => {
        
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('OPERATORSLOADED', parsedResultOP.data);
          resolve(parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
    });
    },

    getTaxiOperatorsByAssId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,taxiassociation_id,skip,len }) {
      
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.GETOPERATORSBYASSID_URL,
      };

      this._vm.$TAMS_API.getByPaging(requestheader,
      {status: taxiassociation_id, skip, len }
      ).then((parsedResultOP) => {
        
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          commit('OPERATORSLOADED', parsedResultOP.data);
          resolve(parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    });
    },
    getTaxiOperatorsByAssIdSummary({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,taxiassociation_id,skip,len }) {
      
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.GETOPERATORSBYASSIDSUMMARY_URL,
      };

      this._vm.$TAMS_API.getByPaging(requestheader,
      {status: taxiassociation_id, skip, len }
      ).then((parsedResultOP) => {
        
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          commit('OPERATORSLOADED', parsedResultOP.data);
          resolve(parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    });
    },

    getOperatorTrafficFinesById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { id,skip,len,router }) {
      
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.GETOPERATORTRAFFICFINESBYID_URL,
      };

      this._vm.$TAMS_API.getByPaging(requestheader,
      {status: id, skip, len }
      ).then((parsedResultOP) => {
        
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('OPERATORSLOADED', parsedResultOP.data);
          console.log('traffic fines: ', parsedResultOP.data);
          resolve(parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        reject(error);
        // commit('LOGINFAILED', error.data);
      });
    });
    },

    callOperator({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,agentno,mobileno }) {
      
      return new Promise((resolve, reject) => {

        const requestOptions = {
          method: "GET",
          redirect: "follow"
        };
      
      const url = configbase.URL_DIALER(agentno,mobileno);
      fetch(url,requestOptions)
      .then(response => {
        
        response.json()
      })
      .then((parsedResult) => {
        
          if (parsedResult.status) { resolve(parsedResult.data[0]); } else { reject(parsedResult); }
      }).catch((error) => {
        
        reject(error);
      })

    });
    },

    getTaxiOperatorVehiclesById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,operator_id,skip,len }) {
      
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.GETOPERATORVEHICLESBYID_URL,
      };

      this._vm.$TAMS_API.getByPaging(requestheader,
      {status: operator_id, skip, len }
      ).then((parsedResultOP) => {
        
        console.log(parsedResultOP);
        if (parsedResultOP.status) {

          commit('OPERATORVEHICLESLOADED', parsedResultOP.data);
          resolve(parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    });
    },

    getSuburbs({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,status,skip,len }) {
      
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.SUBURBS_URL,
      };

      this._vm.$TAMS_API.getByPaging(requestheader,
      {status, skip, len }
      ).then((parsedResultOP) => {
        
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          console.log('suburbs found:',parsedResultOP.data);
          // commit('OPERATORVEHICLESLOADED', parsedResultOP.data);
          resolve(parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log('suburbs errors:',error);
        // commit('LOGINFAILED', error.data);
      });
    });
    },

    getAllByTaxiAssIdNoPic({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TOBYTAXIASSIDNOPIC_URL,
      };

      this._vm.$TAMS_API.getById(requestheader,
      id
      ).then((parsedResultOP) => {
        
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('OPERATORSNOPICLOADED', parsedResultOP.data);
          resolve(parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        reject(error);
        // commit('LOGINFAILED', error.data);
      });
    });
    },

    getAllPendingVerification({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id,
      skip,
      len
    }) {

      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.OPERATORSPENDINGVERIFICATION_URL,
        };

        

        this._vm.$TAMS_API.getByPaging(requestheader, {status: id, skip, len}).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {

            console.log('pending verification: ',parsedResultOP.data);
            commit('OPERATORSPENDINGVERIFICATION', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('pending errors: ',error);
          commit('LOGINFAILED', error.data);
        });
      });
    },

    getAllByTaxiAffiliationId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TOBYTAXIAFFILIATIONIDNOPIC_URL,
      };

      this._vm.$TAMS_API.getById(requestheader,
      id
      ).then((parsedResultOP) => {
        
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('OPERATORSBYAFFNOPICLOADED', parsedResultOP.data);
          resolve(parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        reject (error);
        // commit('LOGINFAILED', error.data);
      });
    });
    },

    getAllTransactionsByDate({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TRANSACTIONSOPERATOR,
      };

      this._vm.$TAMSFIN_API.getCustomURL(requestheader,
        payload
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          //commit('LOADED', parsedResultOP.data);
          resolve(parsedResultOP);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    });
    },

    getAllBanks({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.BANK_URL,
      };

      this._vm.$TAMSFIN_API.getAll(requestheader
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('BANKSLOADED', parsedResultOP.data);
          resolve(parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        // commit('LOGINFAILED', error.data);
        reject(error.message);
      });
    });
    },

    getAccountTypes({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.ACCOUNTTYPE_URL,
      };

      this._vm.$TAMSFIN_API.getAll(requestheader
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('ACCOUNTTYPELOADED', parsedResultOP.data);
          resolve(parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        // commit('LOGINFAILED', error.data);
        reject(error.message);
      });
    });
    },

    getLOGOURL({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { id }) {
      return new Promise((resolve, reject) => {

        resolve(format(conf.URLBYID(config.TA_LOGO_URL),{ id }));

    });
   
  },


  changeOperatorBilling({
    state,
    rootState,
    rootGetters,
    commit,
    dispatch,
    getters,
  }, {
    addModel,
    router
  }) {
    return new Promise((resolve, reject) => {
      
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.CHANGEOPERATORBILLING_URL,
        };

        this._vm.$TAMSFIN_API.add(requestheader, addModel
        ).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('TRANSACTIONSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      
    });
  },

  updateOperatorStatusRequest({
    commit,
    state,
    dispatch,
    rootGetters
  }, {
    addModel,
    router,
  }) {
    return new Promise((resolve, reject) => {
      
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.CHANGEOPERATORSTATUS_URL,
      };

      this._vm.$TAMS_API.add(requestheader, addModel).then((parsedResult) => {
        if (parsedResult.status) {
          // commit('ADDED', parsedResult.data)
          resolve( parsedResult.data)
          console.log('updated succesfully')
        } else {
          commit('LOADED', parsedResult.data);
        }
      }).catch((error) => {
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
    });
  },

  updateOperatorProfileRequest({
    state, rootState, rootGetters, commit, dispatch, getters,
  }, { updateModel,router }) {
    return new Promise((resolve, reject) => {
     
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.OPERATORUPDATEREQUEST_URL,
      };

      this._vm.$TAMS_API.add(requestheader, updateModel )
      .then((parsedResult) => {
          
        if (parsedResult.status) {
          console.log('operator update requested: ',parsedResult.data);
          // commit('UPDATEMORATORIUM', updateModel);
          resolve(parsedResult.data);
        // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
      // commit('LOGINFAILED', error.data);

      console.log('operator update errors: ',error)
        reject(error);
      });
    });
  },

  notificationsRequest({
    state, rootState, rootGetters, commit, dispatch, getters,
  }, { _addModel,router }) {
    return new Promise((resolve, reject) => {

      
     
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.OPERATORNOTIFICATIONSREQUEST_URL,
      };

      this._vm.$TAMS_API.add(requestheader, _addModel)
      .then((parsedResult) => {
          
        if (parsedResult.status) {
          console.log('notifications requested: ',parsedResult.data);
          // commit('UPDATEMORATORIUM', updateModel);
          resolve(parsedResult.data);
        // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        
        console.log('notifications errors: ',error);
        reject(error);
      // commit('LOGINFAILED', error.data);
      });
    });
  },

  

  markAsDeleted({
    state,
    rootState,
    rootGetters,
    commit,
    dispatch,
    getters,
  }, {
    router, id, deletedby
  }) {
    return new Promise((resolve, reject) => {
      

      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TO_URL,
      };

      this._vm.$TAMS_API.delete(requestheader,
        id, {
          id,
          deletedby,
        }).then((parsedResult) => {
        if (parsedResult.status) {
          // commit('MARKASDELETE', {
          //   id,
          //   deletedby
          // });
          // console.log('this is the id: ',id);
          resolve(parsedResult);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
    });
  },

  restoreDeleted({
    state,
    rootState,
    rootGetters,
    commit,
    dispatch,
    getters,
  }, {
    router, id, restoredby
  }) {
    return new Promise((resolve, reject) => {
      
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TO_URL,
      };

      this._vm.$TAMS_API.restoreDeleted(requestheader, {
        id,
        restoredby,
      }).then((parsedResult) => {
        if (parsedResult.status) {
          // commit('RESTOREDELETED', {
          //   id
          // });
          resolve(parsedResult);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
    });
  },

    add({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          item, router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TO_URL,
        };
        this._vm.$TAMS_API.add(requestheader,
          item).then((parsedResult) => {
          if (parsedResult.status) {
            console.log('added operator results: ',parsedResult.data);
            // commit('ADDED',parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
        console.log('added operator errors: ',error.data);
          reject(error);
        });
      });
    },
    AddMobileNo({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _addModel,router }) {
      return new Promise((resolve, reject) => {
  
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETOPERATORADDMOBILENUMBERS_URL,
        };
        this._vm.$TAMS_API.add(requestheader,
          _addModel).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);

            const url = "https://mobifytrackerwhatsapp-m456ah7djq-uw.a.run.app/api/v1/TAMS/WhatsAppService/Refresh"
              fetch(url, {
                method: 'GET',
                headers: new Headers({
                  'Content-Type': 'application/json',
                  'X-Access-Token':rootGetters['auth/TOKEN'],
                }),
              }).then(response => response.json()).then((parsedResult) => {
                //resolve(parsedResult);
              }).catch((error) => {
                //reject(error);
              }).finally(() => {

              });
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
        console.log('added operator errors: ',error.data);
          reject(error);
        });
      });
  },
  },

};

// ===
// Private helpers
// ===

function navigateTo(router, _userProfile) {
  if (_userProfile.is_system_admin) {
    router.push('sysadmin_dashboard');
  }
}
