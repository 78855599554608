<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Operators'"
      :ActiveMenu="'Verify'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Taxi Operator"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Operators"
        breadcrumbitem3="All"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->
       <!-- begin row -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-inverse">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">All Operators</h4>
            </div>
            <!-- end panel-heading -->

            <!-- <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <router-link :to="{ name: 'addTaxiOperators'}" class="btn btn-white">
                  <i class="fa fa-plus"></i>
                  Add Operator
                </router-link>
              </div>
            </div> -->

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-combine" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">ID Number</th>
                    <th class="text-nowrap">Membership Number</th>
                    <th class="text-nowrap">Balance</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap hidden-print">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETOPERATORSBYASSID"
                    v-bind:key="item._id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td width="1%" class="with-img">
                      <img
                        :src="item.images != null ? item.images[0]['data'] : ''"
                        class="img-rounded height-30"
                      />
                    </td>
                    <td>{{item.title_name + " " + item.firstname + " " + item.surname}}</td>
                    <td>{{item.id_number}}</td>
                    <td>{{item.membership_number}}</td>
                    <td>{{item._available_balance}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._deleted_by }"
                        v-if="ISINTERNALSYSTEMUSER && item._deleted_by"
                        
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item._is_active, 'label-secondary':!item._is_active  }"
                        v-if="!item._deleted_by"
                      >{{item.taxi_operators_status}}</span>
                    </td>
                    <td class="with-btn valign-middle" nowrap>
                      <router-link
                        :to="{ name: 'taxioperatorprofile', params: { id: item.taxi_operator_id } }"
                        class="btn btn-sm btn-warning width-60 m-r-2"
                        :disabled="!item._deleted_by"
                        data-role="UTO"
                      >Edit</router-link>
                      <button
                        type="button"
                        class="btn btn-sm btn-primary width-60 m-r-2"
                        v-if="ISINTERNALSYSTEMUSER || !item._deleted_by"
                        data-role="UTO"
                        :disabled="$wait.is('verify-' + item.taxi_operator_id)"
                      >
                        <v-wait v-bind:for="'verify-' + item.taxi_operator_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Verify
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import headerComp from "@/components/common/header.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import pageloader from "@/components/common/pageloader.vue";

import { createNamespacedHelpers } from "vuex";
import { setTimeout } from "timers";
const { mapGetters, mapActions } = createNamespacedHelpers("TO");

export default {
  name: "ManageOperators",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      skip: 0,
      len: 1000000,
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      // accountid: this.$store.getters["TaxiDrivers/GETDRIVERACCOUNT"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
    };
  },
  beforeMount: function() {
    
    this.getbyid(this.context, this.taxiassociationid);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters({
      GETOPERATORSBYASSID: "GETOPERATORSPENDINGVERIFICATION"
    }),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "delete",
      "edit",
      "add",
      "getAllPendingVerification",
      "markAsDeleted",
      "clearAll",
      "restoreDeleted"
    ]),

    clear: context => {
      context.clearAll();
    },
    
    getbyid: (context, taxiassociationid) => {
      context
        .getAllPendingVerification({
          router: context.$router,
          id: taxiassociationid,
          skip: context.skip,
          len: context.len
        })
        .then(() => {
          App.init();
        });
    },

    FILTERBYROLE: context => {
      $(".byrole").each(function() {
        if (
          context.USERROLES.length > 0 &&
          context.USERROLES.filter(
            item => item._code === $(this).attr("data-role")
          ).length > 0
        )
          $(this).removeClass('hide');
      });
    },

    // Delete: (context, id, deletedby) => {
    //   context.$Notify.YesNo(false, { context, id, deletedby }).then(value => {
    //     if (value) {
    //       value.context.$wait.is("delete-" + value.id);

    //       value.context
    //         .markAsDeleted({
    //           router: value.context.$router,
    //           id: value.id,
    //           deletedby: value.deletedby
    //         })
    //         .then(() => {
    //           value.context.$wait.end("delete-" + value.id);
    //           value.context.$Notify.Success("Successfully Deleted!", "");
    //         })
    //         .catch(error => {
    //           value.context.$wait.end("delete-" + value.id);
    //           value.context.$Notify.Error(error.data[0].message, "");
    //         })
    //         .finally(() => {});
    //     }
    //   });
    // },
    // Restore: (context, id, restoredby) => {
    //   context.$Notify.YesNo(false, { context, id, restoredby }).then(value => {
    //     if (value) {
    //       value.context.$wait.is("restore-" + value.id);

    //       value.context
    //         .restoreDeleted({
    //           router: value.context.$router,
    //           id: value.id,
    //           restoredby: value.restoredby
    //         })
    //         .then(() => {
    //           value.context.$wait.end("restore-" + value.id);
    //           value.context.$Notify.Success("Successfully Deleted!", "");
    //         })
    //         .catch(error => {
    //           value.context.$wait.end("restore-" + value.id);
    //           value.context.$Notify.Error(error.data[0].message, "");
    //         })
    //         .finally(() => {});
    //     }
    //   });
    // },
    
  },
  beforeDestroy: function(){
    this.clear(this.context);
  },
  mounted: function() {

    
    
    $(".byrole").addClass('hide');
    this.FILTERBYROLE(this.context);
    window.PushManager.listenToIncomingMessages(this.context);

    // TableManageCombine.init();
  },
  
  
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

