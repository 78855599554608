<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp :USERPROFILE="USERPROFILE" />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Province"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Province"
        breadcrumbitem3="Manage"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!--Begin col-lg-12-->
        <div class="col-lg-12">
          <!-- begin panel -->
          <!-- begin panel -->
          <div class="panel panel-inverse">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">Province</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <a
                  data-toggle="modal"
                  data-target="#AddModal"
                  class="btn btn-white"
                  href="javascript:;"
                  @click="clearAdded(GETSELECTED)"
                >
                  <i class="fa fa-plus"></i>
                  Add Provinces
                </a>
              </div>
            </div>

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-titles" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">Description</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Last Updated By</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETALL"
                    v-bind:key="item._id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item._name}}</td>
                    <td>{{item._description}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._deleted_by }"
                        v-if="item._deleted_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item._is_active, 'label-secondary':!item._is_active  }"
                        v-if="!item._deleted_by"
                      >{{item._is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td>{{item._updateby_name || item._addedby_name}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <button
                        type="button"
                        class="btn btn-sm btn-white width-60 m-r-2"
                        data-toggle="modal"
                        data-target="#ViewModal"
                        @click="setViewDetail(item)"
                      >View</button>
                      <button
                        type="button"
                        class="btn btn-sm btn-primary width-60 m-r-2"
                        data-toggle="modal"
                        data-target="#EditModal"
                        @click="setViewDetail(item)"
                        :disabled="item._deleted_by"
                      >Edit</button>
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-80"
                        data-toggle="modal"
                        data-target="#deletedModal"
                        v-if="item._deleted_by"
                        @click="restoredelete(context,item,USERPROFILE.system_usersid)"
                        :disabled="$wait.is('restoreDeleted-' + item._id)"
                      >
                        <v-wait v-bind:for="'restoreDeleted-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Restore
                        </v-wait>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-80"
                        v-if="!item._deleted_by"
                        @click="deletetitle(context,item,USERPROFILE.system_usersid)"
                        :disabled="$wait.is('delete-' + item._id)"
                      >
                        <v-wait v-bind:for="'delete-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Delete
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Edit Modal HTML START-->
              <div id="ViewModal" class="modal fade" v-if="GETSELECTED">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">View</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Name</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                :value="GETSELECTED._name"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Description</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                :value="GETSELECTED._description"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">
                              Added
                              By
                            </label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="AddedBy"
                                id="added_by"
                                class="form-control"
                                :value="GETSELECTED._addedby_name"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">
                              Date
                              Addded
                            </label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="AddedBy"
                                id="added_by"
                                placeholder
                                class="form-control"
                                :value="GETSELECTED._date_added"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->

                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">
                              Updated
                              By
                            </label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="UpdatedBy"
                                id="updated-by"
                                placeholder
                                class="form-control"
                                :value="GETSELECTED._updateby_name"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->

                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">
                              Date
                              Updated
                            </label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="AddedBy"
                                id="added_by"
                                placeholder
                                class="form-control"
                                :value="GETSELECTED._date_updated"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->

                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">
                              Deleted
                              By
                            </label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="DeletedBy"
                                class="form-control"
                                :value="GETSELECTED._deletedby_name"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->

                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">
                              Date
                              Deleted
                            </label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="AddedBy"
                                id="added_by"
                                class="form-control"
                                :value="GETSELECTED._date_deleted"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->

                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Status</label>
                            <div
                              class="checkbox checkbox-css"
                              :class="{'is-valid':GETSELECTED._is_active}"
                            >
                              <input
                                type="checkbox"
                                id="cssCheckbox1"
                                :checked="GETSELECTED._is_active"
                                disabled
                              />
                              <label v-if="GETSELECTED._deleted_by" for="cssCheckbox1">Deleted</label>
                              <label
                                v-if="!GETSELECTED._deleted_by"
                                for="cssCheckbox1"
                              >{{GETSELECTED._is_active == true ? 'Active' : 'Disabled' }}</label>
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Edit Modal HTML START-->

              <!-- Edit Modal HTML START-->
              <div id="EditModal" class="modal fade" v-if="GETSELECTED">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Edit Title</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Name</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                v-model.lazy="GETSELECTED._name"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Description</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                class="form-control"
                                v-model.lazy="GETSELECTED._description"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                           <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Code</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                v-model.lazy="GETSELECTED._code"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Status</label>
                            <div class="checkbox checkbox-css">
                              <input
                                type="checkbox"
                                id="cssCheckbox1edit"
                                v-model="GETSELECTED._is_active"
                                :checked="GETSELECTED._is_active"
                              />
                              <label
                                v-if="!GETSELECTED._deleted_by"
                                for="cssCheckbox1edit"
                              >{{GETSELECTED._is_active ? 'Active' : 'Disabled' }}</label>
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        />
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="edited(context,GETSELECTED,USERPROFILE.system_usersid)"
                          :disabled="$wait.is('edited')"
                        >
                          <v-wait v-bind:for="'edited'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Save
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Edit Modal HTML START-->

              <!-- Add Modal HTML START-->
              <div id="AddModal" class="modal fade" v-if="GETSELECTED">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Add Province</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">
                              Country
                            </label>
                            <div class="col-md-9">
                              <select
                                class="form-control"
                                @change="selectTitle"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="GETSELECTED._country_id"
                              >
                                <option value="0" selected>Select Country</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLCOUNTRY"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Name</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="billingTypeName"
                                id="biiling_type_name"
                                placeholder="Enter Name"
                                class="form-control"
                                minlength="1"
                                v-model="GETSELECTED._name"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->

                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Description</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="billingTypeName"
                                id="biiling_type_name"
                                placeholder="Enter Description"
                                class="form-control"
                                minlength="3"
                                v-model="GETSELECTED._description"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                           <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Code</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="provinceCode"
                                id="code"
                                placeholder="Enter Province Code"
                                class="form-control"
                                minlength="3"
                                v-model="GETSELECTED._code"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        />
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="add(context,GETSELECTED,USERPROFILE.system_usersid)"
                          :disabled="$wait.is('add')"
                        >
                          <v-wait v-bind:for="'add'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Add
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Add Modal HTML START-->
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
          <!--End col-lg-12-->
        </div>

        <!-- end row -->
      </div>
      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import headerComp from "@/components/common/header.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import pageloader from "@/components/common/pageloader.vue";

import { createNamespacedHelpers } from "vuex";
import { setTimeout } from "timers";
const { mapGetters, mapActions } = createNamespacedHelpers("province");

export default {
  name: "ManageProvince",
  data: function() {
    return {
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      GETALLCOUNTRY: this.$store.getters["lookups/GETALLCOUNTRY"],
      context: this,
      addedsuccess: undefined,
      errormessage: undefined,
      GETSELECTED: Object.assign({}, this.$store.getters["province/GETALL"])[0]
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["GETALL"]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "delete",
      "edit",
      "add",
      "markAsdelete",
      "restoreDeleted"
    ]),
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    clearAdded: item => {
        
      for (var propName in item) {
      
        item[propName] = undefined;
      }
    },
    add: (context, item, addedby) => {
      item._is_active = true;
      item._added_by = addedby;
      item._addedby_name = context.LoggedInUserFullName;

      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("province/add", {
              _dataObj: value
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");
              value.context.$Notify.Success("Successfully Added!", "");
            })
            .catch(error => {
              value.context.$wait.end("add");
              
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    setAdd(val) {
      
      this.$store
        .dispatch("province/add", {
          _dataObj: val
        })
        .then(() => {});
    },
    getall: context => {
      context.$store
        .dispatch("province/getall", {
          _dataObj: {
            router: context.$router
          }
        })
        .then(() => {
          App.init();
          TableManageCombine.init();
          Highlight.init();
        });
    },
    edited: (context, item, updatedby) => {
      //item = Object.assign({}, item);
      context.$Notify.YesNo(false, { context, item, updatedby }).then(value => {
        if (value) {
          
          value.context.$wait.start("edited");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("province/update", {
              _dataObj: value
            })
            .then(() => {
              $("#EditModal").modal("hide");
              value.context.$wait.end("edited");
              value.context.$Notify.Success("Successfully Updated!", "");
            })
            .catch(error => {
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {
              value.context.$wait.end("edited");
            });
        }
      });
    },
    deletetitle: (context, item, deletedby) => {
      context.$Notify
        .YesNo(false, { context, id: item._id, deletedby })
        .then(value => {
          if (value) {
            value.context.$wait.start("delete-" + value.id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("province/markAsdeleted", {
                _dataObj: value
              })
              .then(id => {
                value.context.$wait.end("delete-" + id);
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch(error => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    restoredelete: (context, item, restoredby) => {
      context.$Notify
        .YesNo(false, { context, id: item._id, restoredby })
        .then(value => {
          if (value) {
            value.context.$wait.start("restoreDeleted-" + value.id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("province/restoreDeleted", {
                _dataObj: value
              })
              .then(id => {
                value.context.$wait.end("restoreDeleted-" + id);
                value.context.$Notify.Success("Successfully Restored!", "");
              })
              .catch(error => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    }
  },
  mounted: function() {
    
    this.getall(this.context);
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

