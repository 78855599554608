<template>
    <li>
          <form class="navbar-form">
            <div class="form-group">
              <input v-model="searchfield" v-on:keydown.enter.prevent='onEnter' type="text" class="form-control" placeholder="Enter Reg or ID Number">
              <button @click="onEnter" type="button" class="btn btn-search">
                <i class="ion-ios-search"></i>
              </button>
            </div>
          </form>
        </li>
</template>

<script>
export default {
  name: "TopSearchControl",
  data: function() {
    return {
      searchfield: '',
    };
  },
  components: {},
  computed: {
  },
  methods: {
     onEnter: function(e)
     {
       if(e) e.preventDefault();
       console.log('Enter ');
       if(this.$route.name !== 'fullsystem_search' ){
         this.$router.push({name: 'fullsystem_search' , query: {text:this.searchfield}});
       }
     }
  }
}
</script>

<style scoped>

</style>

