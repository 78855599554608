<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Money'"
      :ActiveMenu="'TakeBulkPayment'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Make Payment"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Money"
        breadcrumbitem3="Take Payment"
        ActiveSubMenu="Money"
        ActiveMenu="TakePayment"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin invoice -->
      <div class="invoice">
        <!-- begin invoice-Logo -->
        <span class="pull-right">
          <img :src="encodedImage" class="width-160 height-40" />
        </span>
        <!-- begin invoice-Logo -->
        <!-- begin invoice-company -->
        <div class="invoice-company text-inverse f-w-600">
          <span class="pull-right hidden-print">
            <a
              href="javascript:;"
              :disabled="$wait.is('createpayment')"
              @click="createpayment(context,PAYMENTMODEL,taxiassociation_id)"
              :class="{'btn btn-lg btn-success m-r-20 m-b-10 p-l-5': !$wait.is('createpayment'),
            'btn btn-lg btn-grey m-b-10 p-l-5': $wait.is('createpayment')
            }"
            >
              <i
                class="fa fa-registered t-plus-1 text-white fa-fw fa-lg"
                v-show="!$wait.is('createpayment')"
              ></i>

              <i
                class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
                v-show="$wait.is('createpayment')"
              ></i>

              <span>Take Payment</span>
            </a>
          </span>
          {{USERPROFILE.taxi_associations_name}}
        </div>
        <!-- end invoice-company -->

        <!-- begin invoice-header -->
        <div class="invoice-header">
          <div v-show="paymentCreated" class="alert alert-success fade show">
            <span class="close" data-dismiss="alert">×</span>
            <strong>Success!</strong>
            Payment Successfully.
          </div>
          
          <DropZoneControl
            :acceptedFiles="acceptedFiles"
            :ClearData="true"
            v-on:fileadded="fileadded"
            :SessionID="SessionID"
          ></DropZoneControl>
        </div>
        <!-- end invoice-header -->
        <!-- begin invoice-content -->
        <div class="invoice-content">
          <!-- begin table-responsive -->
          <div class="panel-body">
            <div v-if="fileupisloaded" :class="{'show': filehaserrors}" class="alert alert-danger fade">{{erromessage}}</div>
             <div v-if="fileupisloaded" :class="{'show': !filehaserrors}" class="alert alert-success fade">File Uploaded Successfully</div>
            <div v-if="dataLoaded" class="btn-row">
              <div class="text-center">
                <div class="spinner-border spinner-border-lg text-info" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
             <table id="data-table-default" v-if="filehaserrors" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th width="7%">Row Number</th>
                  <th class="text-center">CAR REGISTRATION</th>
                  <th class="text-center">DATE</th>
                  <th class="text-center">AMOUNT</th>
                  <th class="text-center">CAPTURED BY</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item) in PAYMENTERRORMODEL" :key="item.id">
                  <td class>
                    <div class="row p-t-10">
                      <div class="col-md-4">
                        {{item.rownum}}
                      </div>
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Select Date"
                      data-parsley-group="step-1"
                      data-parsley-required="true"
                      v-model="item.vehicle_license"
                      disabled
                    />
                  </td>
                  <td class="p-t-20 f-s-20">
                    <div
                      class="input-group date p-0"
                      id="licence_expiry_date"
                      data-date-format="dd M yyyy"
                    >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Select Date"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        :value="item.payment_date | formatToShortDate"
                        disabled
                      />
                      <span class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </span>
                    </div>
                  </td>
                  <td class="text-center" width="20%">
                    <CurrencyInput
                      v-model="item.amount"
                      :auto-decimal-mode="true"
                      :currency="currency"
                      data-parsley-required="false"
                      class="form-control text-left demo__currency-input"
                      disabled
                    />
                  </td>
                  <td class="text-center">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Select Date"
                      data-parsley-group="step-1"
                      data-parsley-required="true"
                      v-model="item.captured_by_name"
                      disabled
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table id="data-table-default" v-if="!filehaserrors" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th width="7%"></th>
                  <th class="text-center">CAR REGISTRATION</th>
                  <th class="text-center">DATE</th>
                  <th class="text-center">AMOUNT</th>
                  <th class="text-center">CAPTURED BY</th>
                  <th class="text-center">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item) in PAYMENTMODEL" :key="item.id">
                  <td class>
                    <div class="row p-t-10">
                      <div class="col-md-4">
                        <a
                          href="javascript:;"
                          @click="AddNewBillItem(context)"
                          class="btn btn-danger btn-icon btn-circle btn-sm"
                        >
                          <i class="fa fa-plus"></i>
                        </a>
                      </div>
                      <div class="col-md-4">
                        <a
                          href="javascript:;"
                          @click="PAYMENTMODEL.splice(--item,1)"
                          v-show="item > 0"
                          class="btn btn-warning btn-icon btn-circle btn-sm"
                        >
                          <i class="fa fa-minus"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Select Date"
                      data-parsley-group="step-1"
                      data-parsley-required="true"
                      v-model="item.vehicle_license"
                      disabled
                    />
                  </td>
                  <td class="p-t-20 f-s-20">
                    <div
                      class="input-group date p-0"
                      id="licence_expiry_date"
                      data-date-format="dd M yyyy"
                    >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Select Date"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        :value="item.payment_date | formatToShortDate"
                        disabled
                      />
                      <span class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </span>
                    </div>
                  </td>
                  <td class="text-center" width="20%">
                    <CurrencyInput
                      v-model="item.amount"
                      :auto-decimal-mode="true"
                      :currency="currency"
                      data-parsley-required="false"
                      class="form-control text-left demo__currency-input"
                      disabled
                    />
                  </td>
                  <td class="text-center">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Select Date"
                      data-parsley-group="step-1"
                      data-parsley-required="true"
                      v-model="item.captured_by_name"
                      disabled
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary width-60"
                      data-toggle="modal"
                      data-target="#EditModal"
                      @click="viewDetails(context,item)"
                    >Edit</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- end table-responsive -->
        </div>
        <!-- end invoice-content -->

        <!-- begin invoice-note -->
        <div class="invoice-note">
          * Make all invoices payable to {{USERPROFILE.taxi_associations_name}}
          <br />
        </div>
        <!-- end invoice-note -->
        <!-- begin invoice-footer -->
        <div class="invoice-footer">
          <p class="text-center m-b-5 f-w-600">{{USERPROFILE.taxi_associations_name}}</p>
          <p class="text-center">
            <span class="m-r-10">
              <i class="fa fa-fw fa-lg fa-globe"></i>
              {{SELECTEDTAXIASSOCIATION._abbriviated_name}}.TAMS.co.za
            </span>
            <span class="m-r-10">
              <i class="fa fa-fw fa-lg fa-phone-volume"></i>
              T:{{SELECTEDTAXIASSOCIATION._telephone}}
            </span>
          </p>
        </div>
        <!-- end invoice-footer -->
      </div>
      <!-- end invoice -->

      <!-- begin #Main Body Template -->
      <!-- Add Modal HTML START-->
      <div id="EditModal" class="modal fade">
        <div class="modal-dialog">
          <div class="modal-content">
            <form>
              <div class="modal-header">
                <h4 class="modal-title">Edit Bulk Payment</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      VEHICLE REGISTRATION:
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        v-model="GETSELECTED.vehicle_license"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      VEHICLE REGISTRATION:
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        v-model="GETSELECTED.vehicle_license"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->
                </div>
              </div>
              <div class="modal-footer">
                <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Add Modal HTML START-->

      <!-- end #content -->
    </div>
    <!-- #modal-dialog -->
    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { setTimeout } from "timers";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("payments");

export default {
  name: "CreateInvoice",
  data: function () {
    return {
      currencySymbol: "",
      filehaserrors: false,
      erromessage: false,
      fileupisloaded: false,
      position: "prefix",
      acceptedFiles: ".csv",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      SELECTEDTAXI: {},
      SELECTEDOPERATOR: {},
      SELECTEDDRIVER: {},
      SELECTEDTAXIASSOCIATION: this.$store.getters[
        "auth/SELECTEDTAXIASSOCIATION"
      ],
      TRANSFEE: this.$store.getters["auth/SELECTEDTAXIASSOCIATION"]._percentage,
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      GETALLTAXIS: [],
      GETALLOPERATORS: [],
      GETAllTAXIDRIVERS: [],
      skip: 0,
      len: 3,
      GETSELECTED: {},
      GETAllTAXIMODELS: this.$store.getters["TV/GETALLMODEL"],
      GETAllBILLINGITEMS: this.$store.getters["billingitems/GETALL"],
      VEHICLETRANSACTIONS: [],
      OPERATORTRANSACTIONS: [],
      DRIVERTRANSACTIONS: [],
      taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      encodedImage: undefined,
      context: this,
      COMMENT: "",
      CAPTUREDBY: [],
      selectedTitleId: undefined,
      selectedGenderId: undefined,
      selectedUserGroupId: undefined,
      paymentCreated: false,
      printReady: false,
      linetotal: 0,
      SELECTEDTAXIID: 0,
      TOTALINVOICEAMOUNT: "",
      vehicleAccountInfo: [],
      SessionID: this.$uuid.v4(),
      capturedBySelected: false,
      dataLoaded: false,
      PAYMENTERRORMODEL : [{
          id: this.$uuid.v4(),
          vehicle_license: "",
          payment_date: "",
          amount: 0,
          captured_by_name: "",
      }],
      PAYMENTMODEL: [
        {
          id: this.$uuid.v4(),
          vehicle_registration_id: "",
          vehicle_license: "",
          payment_date: "",
          amount: 0,
          captured_by: "",
          captured_by_name: "",
        },
      ],
      BILLINGITEMS: [
        {
          billingitems_id: 0,
          lineamount: 0,
          lineqty: 1,
          linetotal: 0,
        },
      ],
      PREPAYMENTOBJECT: this.$store.getters["payments/GETPREPAYMENTDETAILS"],
      PrePayment: this.$route.params.PrePayment,
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  beforeMount: function () {
    this.getCapturedBy(this.context);
    this.getAllTaxiByTaxiAssId(this.context, this.taxiassociation_id);

    // this.getAllOperatorByTaxiAssId(this.context, this.taxiassociation_id);
    // this.getAllTaxiDriversByTaxiAssId(this.context, this.taxiassociation_id);
    // this.getAllBillingItemsByTaxiAssId(this.context, this.taxiassociation_id);
    $(".nav-link").click(() => {
      setTimeout(() => {
        $(".default-select").select2({
          multiple: false,
          allowClear: true,
        });
        $(".default-selectOperator").val(null).trigger("change");
        $(".default-selectTaxi").val(null).trigger("change");
        $(".default-selectTaxiDrivers").val(null).trigger("change");
      }, 500);
    });
  },
  beforeDestroy: function () {
    this.PAYMENTMODEL = [];
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
  },
  computed: {
    ...mapGetters([]),
    currency() {
      return { [this.position]: this.currencySymbol };
    },
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
    CurrentDate: function () {
      return Date();
    },
    PAYMENTAMOUNT: function () {
      return Number(this.TOTALINVOICEAMOUNT);
    },
    TRANSACTIONFEE: function () {
      let _transfee = 0;
      return (Number(this.TOTALINVOICEAMOUNT) * this.TRANSFEE) / 100;
    },
    TOTALMOUNT: function () {
      return Number(this.TOTALINVOICEAMOUNT) + this.TRANSACTIONFEE;
    },
  },
  watch: {
    SELECTEDTAXIID: function (val) {
      alert(val);
      if (val != null) {
        console.log("selected value: ", val);
      }
    },
  },
  methods: {
    ...mapActions(["getById", "getByTaxiAssId", "prepayment", "makepayment"]),

    AddNewBillItem: function (context) {
      context.PAYMENTMODEL.push({
        id: context.$uuid.v4(),
        vehicle_registration_id: "",
        vehicle_license: "",
        payment_date: "",
        amount: 0,
        captured_by: "",
        captured_by_name: "",
      });
    },
    fileadded: function (data) {

      this.fileupisloaded = false
      this.dataLoaded = true;

      console.log("Header:fileadded");
      console.log(data);

      const context = this;
      context.getFilecontentById(context, data.fileId);
    },
    viewDetails: (context, item) => {
      
      console.log(item);
      context.GETSELECTED = Object.assign({}, item);
      $("#EditModal").modal("show");
    },
    getFilecontentById: (context, fileId) => {
      context.$store
        .dispatch("payments/getfileByDropzoneUpload", {
          id: fileId,   //test id: "d8bebaf0-38b0-11eb-bbe0-512081eb562a",
          skip: 0,
          len: 1000000,
          router: context.$router,
        })
        .then((data) => {
          
          console.log("files found: ", data);

          context.PAYMENTMODEL = [];

          data.map((item) => {
            

            if (
              item.vehicle_id != "" &&
              item.vehicle_id != undefined &&
              item.vehicle_license != "" &&
              item.payment_date != "" &&
              item.amount != "" &&
              item.captured_by != "" &&
              item.captured_by != undefined
            ) {
              var vehicleIdInt = parseInt(item.vehicle_id);

              if (!isNaN(vehicleIdInt)) {
                context.PAYMENTMODEL.push({
                  id: context.$uuid.v4(),
                  vehicle_registration_id: parseInt(item.vehicle_id),
                  vehicle_license: item.vehicle_license,
                  payment_date: context.$Toolkit.ConvertToServerDate(
                    item.payment_date
                  ),
                  amount: parseInt(item.amount),
                  captured_by: parseInt(item.captured_by),
                  captured_by_name: item.captured_by_name,
                });
              }
              // $("#captured_by").select2("val", item.capturedbyId);
            }
          });

          
          context.fileupisloaded = true
          context.filehaserrors = false;
          context.dataLoaded = false;
          // element.VehicleId = element.Reg.split('-')[1];
          // element.Reg = element.Reg.split('-')[0];
          // element.capturedbyId = element.Captured_By.split('-')[1];
          // element.capturedbyName = element.Captured_By.split('-')[0];

            $("#data-table-default").DataTable({
            responsive: true,
          });

        })
        .catch((data) => {
          console.log("captured page: ", data);
          // context.$Notify.Error(error.message, "");

          context.filehaserrors = true;
          context.fileupisloaded = true
          context.dataLoaded = false;

          if(data.message)
          context.erromessage = data.message
          
          context.PAYMENTERRORMODEL = [];

    
          if(data.data)
          {
             data.data.map((item) => {
               

                context.PAYMENTERRORMODEL.push({
                  rownum: item.rownum,
                  id: context.$uuid.v4(),
                  vehicle_license: item.vehicle_license,
                  payment_date: item.payment_date ? context.$Toolkit.ConvertToServerDate(
                    item.payment_date
                  ) : "",
                  amount: item.amount ? parseInt(item.amount) : 0,
                  captured_by_name: item.captured_by_name,
                });

          });
          }

        })
        .finally(() => {
         
        });
    },

    reset: function (context) {
      $(".default-selectTaxiDrivers").val(null).trigger("change");
      $(".default-selectTaxi").val(null).trigger("change");
      $(".default-selectOperator").val(null).trigger("change");
      context.COMMENT = "";
      context.TOTALINVOICEAMOUNT = "";
    },

    getCapturedBy: (context) => {
      
      context.$store
        .dispatch("systemusers/getSystemUsersCapturedBy", {
          associationid: context.taxiassociation_id,
          userid: context.USERID,
          router: context.$router,
        })
        .then((data) => {
          console.log("captured By results: ", data);
          context.CAPTUREDBY = data;
        })
        .catch((error) => {
          console.log("captured page: ", error);
          // context.$Notify.Error(error.message, "");
        })
        .finally(() => {});
    },

    getAllTaxiByTaxiAssId: (context, id) => {
      context.$store
        .dispatch("OperatorVehicle/getAllByTaxiAssID", {
          router: context.$router,
          id,
        })
        .then((data) => {
          //context.GETALLOPERATORS = context.$store.getters["TO/GETADDOPERATOR"];

          console.log("vehicles vue: ", data);
          context.GETALLTAXIS = data;

          // $(".default-selectTaxi")
          // .val(null)
          // .trigger("change");
        })
        .catch((error) => {
          console.log("vehicles errors: ", error);
          context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },

    generatePaymentResults: function (context) {},
    setViewDetail(val) {},

    createpayment: (context, item, taxiassociation_id) => {
      
      context.$Notify
        .YesNoMore("Take Payment", "Are you sure?", false, {
          context,
          item,
          taxiassociation_id,
        })
        .then((value) => {
          if (value) {
            
            value.context.$wait.start("createpayment");
            value.router = context.$router;

            var datacount = 1;
            var itemdata = [];

            for (var i = 0; i < item.length; i++) {

               if(datacount === 1000)
               {

              value.context.$store
                .dispatch("payments/makeUploadBulkVehiclepayment", {
                  router: value.router,
                  payload: itemdata,
                  taxiassociation_id,
                })
                .then((_data) => {
                  console.log(_data);
                  context.$Notify.Success("Payments Made Successfully!");
                  context.PAYMENTMODEL = [];
                  context.PAYMENTMODEL.push({
                    id: context.$uuid.v4(),
                    vehicle_registration_id: 0,
                    payment_date: "",
                    amount: 0,
                    captured_by: 0,
                  });
                  value.context.$wait.end("createpayment");
                })
                .catch((error) => {
                  context.$Notify.Error(error.message, "");
                  context.$wait.end("createpayment");
                })
                .finally(() => {});

                datacount = 1;
                 itemdata = [];

               }
               else
               {
                 datacount++;
                 itemdata.push(item[i]);
               }

            }

            if(itemdata.length > 0)
            {
               value.context.$store
                .dispatch("payments/makeUploadBulkVehiclepayment", {
                  router: value.router,
                  payload: itemdata,
                  taxiassociation_id,
                })
                .then((_data) => {
                  console.log(_data);
                  context.$Notify.Success("Payments Made Successfully!");
                  context.PAYMENTMODEL = [];
                  context.PAYMENTMODEL.push({
                    id: context.$uuid.v4(),
                    vehicle_registration_id: 0,
                    payment_date: "",
                    amount: 0,
                    captured_by: 0,
                  });
                  value.context.$wait.end("createpayment");
                })
                .catch((error) => {
                  context.$Notify.Error(error.message, "");
                  context.$wait.end("createpayment");
                })
                .finally(() => {});
            }

            
          }
        });
    },
  },
  mounted: function () {
    $("#operatorStatus").data("context", this.context);

    //this.getFilecontentById(this.context);

    $("#captured_by").select2({
      multiple: false,
      placeholder: "Select Captured By",
      allowClear: true,
    });

    App.init();

    if (this.PrePayment) {
      this.createprepayment(this.context);
    }

    this.$store
      .dispatch("TA/getLOGOURL", {
        id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id,
      })
      .then((result) => {
        this.context.encodedImage = result;
      })
      .catch((err) => {});
  },
};
</script>

<style scoped>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
.logo-image {
  width: 200px;
  height: 200px;
}
</style>

