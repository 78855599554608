/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';


export default {
  namespaced: true,
  state: {
    pending:  [],
    approved: [],
    rejected: [],
  
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETALLPENDING:  state => state.pending,
  GERTALLAPPROVED: state => state.approved,
  GETALLREJECTED:  state => state.rejected,

   
},
  mutations: {
   
    LOADEDPENDING: (state, _data) => {
      if (_data !== undefined) {
        state.pending = _data;
        
      }
    },
  
    LOADEDAPPROVED: (state, _data) => {
      if (_data !== undefined) {
        state.approved = _data;
        
      }
    },
    LOADEDREJECTED: (state, _data) => {
      if (_data !== undefined) {
        state.rejected = _data;
      }
    },
    ADDED: (state, _data) => {
      state.approval.push(_data);
    },
    APPROVED: (state, id) => {
      if (id !== undefined) {
        state.pending = state.pending.filter(item => item._id !== id);
      }
    },

    REJECTED: (state, id) => {
      if (id !== undefined) {
        state.pending = state.pending.filter(item => item._id !== id);
      }
    },
    RESUBMIYREJECTED:(state, olditem) => {
        //delete restored request
        state.rejected = state.rejected.filter(item => item._id !== olditem._id);
    },
    RESTOREDELETED: (state, { id }) => {
      if (id !== undefined) {
        state.approval.filter(item => item._id === id)[0]._deleted_by = undefined;
        state.approval.filter(item => item._id === id)[0]._date_deleted = undefined;
      }
    },
    MARKASDELETE: (state, { id, deletedby }) => {
      if (id !== undefined) {
        state.approval.filter(item => item._id === id)[0]._deleted_by = deletedby;
        state.approval.filter(item => item._id === id)[0]._date_deleted = Date.now;
      }
    },
    DELETE: (state, id) => {
      if (id !== undefined) {
        state.approval = state.approval.filter(item => item._id !== id);
        state.deleted = true;
      }
    },
  },
  actions: {
    /* Async */


    /* Auth.js Mutations */
    getall({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const { router } = _dataObj;
        
        const requestheader = {
          router,
          
          token: rootGetters['auth/TOKEN'],
          apiName: config.APPROVAL_URL,
        };

        this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
          if (parsedResult.status) {
            //commit('LOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
    getAllpending ({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.APPROVAL_URL,
      };
   
      this._vm.$TAMS_API.getCustomURL(requestheader,
        payload
      ).then((parsedResultOP) => {
      
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          // commit('LOADEDPENDING', parsedResultOP.data);
          resolve(parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log('Pending approval errors',error);
        reject(error);
        // commit('LOGINFAILED', error.data);
      });
    });
    }, 

    getCaputedById({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, id }) {
      return new Promise((resolve, reject) => {
        
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.CAPUTREDDATA_URL,
      };
      
      this._vm.$TAMS_API.getById(requestheader, id).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {

          //console.log("caputred data getter: ",parsedResultOP.data);
          // commit('LOADEDAPPROVED', parsedResultOP.data);
          resolve(parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log("Approved getter errros: ",error);
        reject(error);
        // commit('LOGINFAILED', error.data);
      });
    });
    },
    
    getAllApproved({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.APPROVED_URL,
      };
      
      this._vm.$TAMS_API.getCustomURL(requestheader,
        payload
      ).then((parsedResultOP) => {
        
        console.log(parsedResultOP);
        if (parsedResultOP.status) {

          console.log("Approved getter: ",parsedResultOP.data);
          // commit('LOADEDAPPROVED', parsedResultOP.data);
          resolve(parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log("Approved getter errros: ",error);
        reject(error);
        // commit('LOGINFAILED', error.data);
      });
    });
    },
    getAllRejected({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.REJECTED_URL, 
      };
   
      this._vm.$TAMS_API.getCustomURL(requestheader,
        payload
      ).then((parsedResultOP) => {
      
        console.log(parsedResultOP);
        if (parsedResultOP.status) {

          console.log("rejects getter: ",parsedResultOP.data);
          // commit('LOADEDREJECTED', parsedResultOP.data);
          resolve(parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log("rejects errors: ",error);
        reject(error);
        // commit('LOGINFAILED', error.data);
      });
    });
    },
    getByPaging({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _waitObj }) {
      const { _wait } = _waitObj;
      const _waitname = _waitObj.name;
      const router = _waitObj._router;

      if (_waitname) { _wait.start(_waitname); }
      this._vm.$TAMS_API.getByPaging(router, rootGetters['auth/TOKEN'],
      config.APPROVAL_URL,
        {
          associd: id,
          userid:useid,
        
        }).then((parsedResult) => {
        console.log(parsedResult);
        if (parsedResult.status) {
          //commit('LOADED', parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
        }

        if (_waitname) { _wait.end(_waitname); }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
        if (_waitname) { _wait.end(_waitname); }
      });
    },
    getAllByTaxiAssID({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, id }) {

      
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.APPROVAL_URL,
      };

      this._vm.$TAMS_API.getByTaxiAssId(requestheader,
        id
      ).then((parsedResultOP) => {
        
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          //commit('LOADED', parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    },
    add({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          item, router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.APPROVAL_URL,
        };

        const AddModel = {
          province_id: item._province_id,
          name: item._name,
          description: item._description,
          code: item._code,
          addedby: item._added_by,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
            if (parsedResult.status) {
              //commit('ADDED', item);
              resolve();
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },
   
    executeapproval({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      
      return new Promise((resolve, reject) => {
        const {
          item, router, approved_by,comment,taxiassociation_id,approval_id
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.EXECUTEAPPROVAL_URL,
        };
        

        const updateModel = {
        approval_id,
        taxiassociation_id,
         approved_by,
          comment
        };
        

        this._vm.$TAMS_API.update(requestheader,
          item._id,
          updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            commit('APPROVED', item._id);
            resolve();
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    executereject({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          item, router, rejected_by,comment,taxiassociation_id,approval_id
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.EXECUTEREJECT_URL,
        };
        

        const updateModel = {
          approval_id,
          taxiassociation_id,
          rejected_by,
          comment
        };

        this._vm.$TAMS_API.update(requestheader,
          item._id,
          updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            console.log('Rejected Successfully',parsedResult.data);
            // commit('REJECTED', item._id);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('Rejected errors: ',error);
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },


     
    delete({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby, id, router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.APPROVAL_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id,
          {
            deletedby,
          }).then((parsedResult) => {
            if (parsedResult.status) {
              commit('DELETE', id);
              resolve(id);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },
    markAsdelete({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby, id, router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.APPROVAL_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id,
          {
            id,
            deletedby,
          }).then((parsedResult) => {
            if (parsedResult.status) {
              commit('MARKASDELETE', { id, deletedby });
              resolve(id);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },
    executeResubmit({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { item , router, added_by }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.EXECUTERESUBMITAPPROVAL_URL,
        };

        

        this._vm.$TAMS_API.restoreDeleted(requestheader,
          {
            id: item._id,
            restoredby: added_by,
          }).then((parsedResult) => {
            if (parsedResult.status) {
              commit('RESUBMIYREJECTED', item);
              resolve(item);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            // commit('LOGINFAILED', error.data);
            reject(error);
          });
      });
    },

  },

};
