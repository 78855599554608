<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
      v-on:init="init"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Dashboard'"
      :ActiveMenu="'Summary'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <transition name="bounce">
      <div id="content" class="content" v-show="USERPROFILE.taxi_associations_id">
        <!-- begin page-header -->
        <headerpagebreadcrumb
          pageheaderTitle="Chairman Dashboard"
          pageheaderdescription
          breadcrumbitem1="Home"
          breadcrumbitem2="Dashboard"
          breadcrumbitem3="Chairman"
        />
        <!-- end page-header -->
        <!-- begin #Main Body Template -->

        <!-- begin row -->
        <div class="row">
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Taxi Association</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totaltaxiassociations}}</div>

                <div class="stats-desc">Total Number Of Association In The Region</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Taxi Operators</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totaloperators}}</div>

                <div class="stats-desc">Total Number Of Operators In The Region</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-red">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Taxis</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totaltaxis}}</div>

                <div class="stats-desc">Total Number Of Taxis In The Region</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="fas fa-taxi"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Drivers</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totaldrivers}}</div>

                <div class="stats-desc">Total Number Of Drivers In The Region</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="fas fa-taxi"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Avg Number Of Taxis Per Operator</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.operatortaxiratio}}</div>

                <div class="stats-desc">Average Taxis Per Operator In The Region</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Avg Operators Per Association</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.avroperatorspertaxiass}}</div>

                <div class="stats-desc">Average Operator Per Association</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="fas fa-taxi"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Avg Taxis Per Association</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.avrtaxipertaxiass}}</div>

                <div class="stats-desc">Average Taxi Per Association In The Region</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Avg Age Of Operators</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.avrageperoperator}}</div>

                <div class="stats-desc">Average Age Of Operators In The Region</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           
        </div>

        <!-- end row -->

         <!-- begin row -->
        <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-12">

                <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Operators By Taxi Association</h4>
              </div>
              <div class="panel-body">
                <div id="interactive-chartOpbyAss" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
         
        </div>
        <!-- end row -->


        <!-- begin row -->
        <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-8">

           <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-2">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Operators By Gender</h4>
              </div>
              <div class="panel-body">
                <div id="donut-OperatorByGender" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-3">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Operators By Age Group</h4>
              </div>
              <div class="panel-body">
                <div id="donut-OperatorByAgeGroup" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->

              <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-4">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Operating License</h4>
              </div>
              <div class="panel-body">
                <div id="VehicleWithNoOperatingLicense" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->


              <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-4">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Taxi Finance Chart</h4>
              </div>
              <div class="panel-body">
                <div id="Vehiclefinancechart" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->


          </div>

         
          <!-- end col-8 -->
          <!-- begin col-8 -->
          <div class="col-xl-4">

              <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-6">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top Operators By Associations In The Region</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Association</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETOPERATORFINSTATSTOP5.top5operators"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
                        <td>
                          {{item.taxiassociation}}
                        </td>
                        <td>{{item.numberofoperators}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

             <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-10">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top Taxis In The Region</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
						            <th>Association</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETOPERATORFINSTATSTOP5.top5taxis"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
						<td>
                          {{item.taxiassociation}}
                        </td>
                        <td>{{item.numberoftaxis}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

              <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-9">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top 5 Taxi By Models</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Taxi Model</th>
                        <th>Totals</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETOPERATORFINSTATSTOP5.vehiclemodelchart"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
                        <td>
                          {{item.vehiclemodel}}
                        </td>
                        <td>
                          {{item.numberofvehicles}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->
            
             <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-8">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Operators With Most Vehicles</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Operator Name</th>
                        <th>Taxi Association</th>
                        <th>Total Taxis</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETOPERATORFINSTATSTOP5.operatorswithmostvehicles"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
                        <td>
                          {{item.fullname}}
                        </td>
                        <td>
                          {{item.taxiassociation}}
                        </td>
                        <td class="text-center">{{item.numberoftaxis}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->


          </div>
          <!-- end col-8 -->
         
        </div>
        <!-- end row -->

      
        <!-- begin row -->

        <!-- begin #Main Body Template -->
      </div>
    </transition>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import statsCards from "@/components/charts/statsCards.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers(
  "regionalchairmansummarydashboard"
);

export default {
  name: "regionalchairman_dashboard",
  data: function() {
    return {
      message: "Some Message",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: 2,
      GETDASHBOARDCARDS: {},
      GETOPERATORFINSTATS: {},
      GETOPERATORFINSTATSTOP5: {}
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    statsCards,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["", ""])
  },
  methods: {
    ...mapActions(["getdashboardcards", "getoperatorfinstats","getoperatorfinstatstop5"]),
    init: function() {}
  },
  mounted: function() {
    $(document).data("context", this);
    App.init();

    const router = this.$router;

    var payload = {
      regionid: this.taxiassociationid
    };

    this.getdashboardcards({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        context.GETDASHBOARDCARDS = parsedResultOP;
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

    this.getoperatorfinstats({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        

        context.GETOPERATORFINSTATS = parsedResultOP;

        handleOperatorByGender();
    handleOperatorByAgeGroup();
		handleVehicleWithNoOperatingLicense();
    handleTotalOpPerTaxiAssBarChart();
    handleVehicleWithfinancechartdisk();

    
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

      this.getoperatorfinstatstop5({ router, payload }).then(parsedResultOP => {
        const context = $(document).data("context");
        
        context.GETOPERATORFINSTATSTOP5 = parsedResultOP;
      })  
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

    var handleOperatorByAgeGroup = function() {
      "use strict";
      if ($("#donut-OperatorByAgeGroup").length !== 0) {
        const context = $(document).data("context");

        var operatorAgeGroupData = [
          {
            label: `Retirement(${context.GETOPERATORFINSTATS.operatorbyagegroup[0].oldage})`,
            data: context.GETOPERATORFINSTATS.operatorbyagegroup[0].oldage,
            color: COLOR_BLUE
          },
          {
            label: `Middle Age(${context.GETOPERATORFINSTATS.operatorbyagegroup[0].middleage})`,
            data: context.GETOPERATORFINSTATS.operatorbyagegroup[0].middleage,
            color: COLOR_PURPLE_LIGHTER
          },
          {
            label: `Youth(${context.GETOPERATORFINSTATS.operatorbyagegroup[0].youth})`,
            data: context.GETOPERATORFINSTATS.operatorbyagegroup[0].youth,
            color: COLOR_GREEN
          }
        ];

        $.plot("#donut-OperatorByAgeGroup", operatorAgeGroupData, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true
              }
            }
		  },
		  grid: {
            hoverable: true,
            clickable: true
          },
          legend: {
            show: true
          }
        });
      }
    };

    var handleOperatorByGender = function() {
      "use strict";
      if ($("#donut-OperatorByGender").length !== 0) {
        const context = $(document).data("context");

        const colors = [COLOR_PURPLE_LIGHTER, COLOR_BLUE];

        var operatorGenderData = context.GETOPERATORFINSTATS.operatorsbygender.map(
          (item, index) => {
            return {
              label: item.gendertype + `(${item.gendercount})`,
              data: item.gendercount,
              color: colors[index]
            };
          }
        );

        $.plot("#donut-OperatorByGender", operatorGenderData, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true
              }
            }
          },
          grid: {
            hoverable: true,
            clickable: true
          },
          legend: {
            show: true
          }
        });
      }
	};
	
	 var handleVehicleWithNoOperatingLicense = function() {
      "use strict";
      if ($("#VehicleWithNoOperatingLicense").length !== 0) {
        const context = $(document).data("context");

		const colors = [COLOR_PURPLE_LIGHTER, COLOR_BLUE];
		

        var nooperatinglicense = [
			{
            label: `Yes(${context.GETOPERATORFINSTATS.operatinglicense[0].hasoperatinglicense})`,
            data: context.GETOPERATORFINSTATS.operatinglicense[0].hasoperatinglicense,
            color: COLOR_GREEN
          },
          {
            label: `No(${context.GETOPERATORFINSTATS.operatinglicense[0].nooperatinglicense})`,
            data: context.GETOPERATORFINSTATS.operatinglicense[0].nooperatinglicense,
            color: COLOR_RED
          },
          {
            label: `Expired(${context.GETOPERATORFINSTATS.operatinglicense[0].expiredoperatinglicense})`,
            data: context.GETOPERATORFINSTATS.operatinglicense[0].expiredoperatinglicense,
            color: COLOR_PURPLE_LIGHTER
          },
        ];

        $.plot("#VehicleWithNoOperatingLicense", nooperatinglicense, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true
              }
            }
          },
          grid: {
            hoverable: true,
            clickable: true
          },
          legend: {
            show: true
          }
        });
      }
	};

var handleTotalOpPerTaxiAssBarChart = function () {
'use strict';
	
	if ($('#interactive-chartOpbyAss').length !== 0) {

    const context = $(document).data("context");

    

    var data_op =  context.GETOPERATORFINSTATS.numofoptaxiinregion.map((item, index) => {
          return item.numberofoperators
        });
    
     var data_taxi =  context.GETOPERATORFINSTATS.numofoptaxiinregion.map((item, index) => {
          return item.numberoftaxis
        });

        var data_taxiass =  context.GETOPERATORFINSTATS.numofoptaxiinregion.map((item, index) => {
          return item.abbriviated_name
        });
	
	var options = {
		chart: {
			height: 350,
			type: 'bar'
		},
		title: {
			text: 'Operators & Taxis Per Association Chart',
			align: 'center'
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '100%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top'
        }
			},
		},
		dataLabels: {
      enabled: true
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		},
		colors: [COLOR_BLUE, COLOR_GREEN],
		series: [{
			name: 'Operators',
			data: data_op
		}, {
			name: 'Taxis',
			data: data_taxi
		}],
		xaxis: {
			categories: data_taxiass,
			axisBorder: {
				show: true,
				color: COLOR_SILVER_TRANSPARENT_5,
				height: 1,
				width: '100%',
				offsetX: 0,
				offsetY: -1
			},
			axisTicks: {
				show: true,
				borderType: 'solid',
				color: COLOR_SILVER,
				height: 6,
				offsetX: 0,
				offsetY: 0
			}
		},
		yaxis: {
			title: {
				text: '$ (thousands)'
			}
		},
		fill: {
			opacity: 1
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return + val
				}
			}
		}
	};
	
	var chart = new ApexCharts(
		document.querySelector('#interactive-chartOpbyAss'),
		options
	);

	chart.render();
	
	}
};

var handleVehicleWithfinancechartdisk = function () {
'use strict';
	
	if ($('#Vehiclefinancechart').length !== 0) {

    const context = $(document).data("context");

    

    var data_ =  context.GETOPERATORFINSTATS.vehiclefinchat.map((item, index) => {
          return item.numberofvehicles
        });
    
     var data_fdi =  context.GETOPERATORFINSTATS.vehiclefinchat.map((item, index) => {
          return item.fdi
        });
	
	var options = {
		chart: {
			height: 350,
			type: 'bar'
		},
		title: {
			text: 'Taxi Finance Chart',
			align: 'center'
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '100%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top'
        }
			},
		},
		dataLabels: {
			enabled: true
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		},
		colors: [COLOR_BLUE, COLOR_GREEN],
		series: [{
			name: 'Operators',
			data: data_
		}],
		xaxis: {
			categories: data_fdi,
			axisBorder: {
				show: true,
				color: COLOR_SILVER_TRANSPARENT_5,
				height: 1,
				width: '100%',
				offsetX: 0,
				offsetY: -1
			},
			axisTicks: {
				show: true,
				borderType: 'solid',
				color: COLOR_SILVER,
				height: 6,
				offsetX: 0,
				offsetY: 0
			}
		},
		yaxis: {
			title: {
				text: ''
			}
		},
		fill: {
			opacity: 1
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return + val
				}
			}
		}
	};
	
	var chart = new ApexCharts(
		document.querySelector('#Vehiclefinancechart'),
		options
	);

	chart.render();
	
	}
};
  

   
  }
};
</script>

<style>
@import "/assets/plugins/nvd3/build/nv.d3.css";
</style>