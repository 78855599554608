/* eslint-disable no- */
export default {
  // options...
  To(router, _userProfile) {
    
    if (_userProfile.is_system_admin) {
      router.push({ name: 'sysadmin_dashboard' })
      return;
    }

    switch (_userProfile.usergroups_code) {
      case 'CHM' /* Chairman */ :
        router.push({ name: 'chairmandashboard' })
        break;
      case 'TRS' /* Treasurer */ :
        router.push({ name: 'treasurerdashboard' })
        break;
        case 'SGL' /* Secretary General */ :
          router.push({ name: 'secretarydashboard' })
        break;
      case 'DCL' /* Debt Collectors */ :
        router.push({ name: 'collectordashboard' })
        break;
      case 'OCK' /* Office Clerk */ :
        router.push({ name: 'officeclerksdashboard' })
        break;
      case 'GMG' /* Manager In the Grievance Office */ :
        router.push({ name: 'grievancemanagerdashboard' })
        break;
      case 'GOF' /* Grievance Officers */ :
        router.push({ name: 'grievanceofficersdashboard' })
        break;
      case 'RUS' /* Regional Users */ :
        router.push({ name: 'regionalusersdashboard' })
        break;
      case 'PUS' /* Provincial Users */ :
        router.push({ name: 'provincialusersdashboard' })
        break;
      case 'NUS' /* National Users */ :
        router.push({ name: 'nationalusersdashboard' })
        break;
        case 'FAD' /* Finance Administrator */ :
          router.push({ name: 'collectordashboard' })
          break;
        case 'CMR' /* Debt Collection Manager */ :
          router.push({ name: 'collectionmgr_dashboard' })
          break;
        // eslint-disable-next-line no-empty
      default: {}
    }
  },
};
