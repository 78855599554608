<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageDrivers'"
      :ActiveMenu="'AddTaxiDriver'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Assign Driver"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Driver"
        breadcrumbitem3="Assign Driver"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Assign Driver
                      <small
                        class="text-ellipsis"
                      >Select vehicle/operator, date, comment</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->
                    <div class="row">
                      <!-- begin col-3 -->
                      <div class="col-md-4 offset-md-4">
                        <div
                          class="widget widget-stats bg-white text-inverse"
                          style="box-shadow: 12px 12px 10px #888888; border-radius:10px;"
                        >
                          <div
                            class="stats-icon stats-icon-square bg-gradient-blue m-t-40"
                            style="width:120px; height:90px;"
                          >
                            <i class="fas fa-user fa-2x m-t-4"></i>
                          </div>
                          <div class="stats-content">
                            <div class="stats-title text-center f-s-20">DRIVER INFORMATION</div>
                            <br />
                            <div
                              class="stats-title f-s-12"
                            >{{driverFullname || 'No Driver Found.'}}</div>
                            <div class="stats-number">{{driverIdNumber || 'No ID No. Found.'}}</div>
                            <!-- <div class="stats-progress progress">
                              <div class="progress-bar" style="width: 40.5%;"></div>
                            </div>-->
                            <div class="stats-desc">{{driverNumber|| 'No Number found'}}</div>
                          </div>
                        </div>
                      </div>
                      <!-- end col-3 -->
                      <!-- begin col-8 -->
                      <div class="col-md-8 offset-md-2">
                        <!-- begin form-group -->
                        <!-- <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">Select Operators:</label>
                          <select
                            class="selectpicker selectnewoperatorpicker form-control col-md-6"
                            data-size="5"
                            data-live-search="true"
                          >
                          <option value="0">Select An Operator</option>
                            <option
                              v-for="item in GETOPERATORS"
                              :key="'operator' + item.taxi_operator_id"
                              :value="item.taxi_operator_id"
                            >{{item.firstname}} {{item.surname}}</option>
                          </select>
                        </div> -->
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">Select Vehicle:
                            <span class="text-danger">*</span>
                          </label>
                          <select
                            class="default-selectTaxi text-center default-select form-control col-md-6"
                            data-size="5"
                            data-live-search="true"
                            id="selectTaxi"
                          >
                            <option>Select Taxi Vehicle</option>
                            <option
                              v-for="item in OPERATORVEHICLES"
                              :key="item._taxi_operator_vehicles_id"
                              :value="item._taxi_operator_vehicles_id"
                            >{{item._licence_plate}} ({{item._taxi_operators_name}})</option>
                          </select>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div v-if="GETDRIVEREMPLOYMENTHISTORY != 0" class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">Termination Reason:</label>
                          <select
                            class="default-selectTermination default-select form-control col-md-6"
                            data-size="5"
                            data-live-search="true"
                          >
                            <option
                              v-for="item in GETTERMINATIONREASONS"
                              :key="item._reason_for_termination_id"
                              :value="item._reason_for_termination_id"
                            >{{item._name}}</option>
                          </select>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form group -->
                        <div v-if="GETDRIVEREMPLOYMENTHISTORY != 0" class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">Termination date:</label>
                          <div class="col-md-6">
                            <div
                              class="input-group date datepicker-disabled-past"
                              id="terminationDate"
                              data-date-format="dd M yyyy"
                            >
                              <input
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                type="text"
                                class="form-control"
                                placeholder="Select Date"
                              />
                              <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- end form group -->
                        <!-- begin form group -->
                        <div class="form-group row m-b-10 ">
                          <label class="col-md-3 col-form-label text-md-right">Employment date:</label>
                          <div class="col-md-6">
                            <div
                              class="input-group date datepicker-disabled-past"
                              id="employmentDate"
                              data-date-format="dd M yyyy"
                            >
                              <input
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                type="text"
                                class="form-control"
                                placeholder="Select Date"
                              />
                              <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- end form group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">Comment:</label>
                          <textarea
                            class="textarea form-control width-550"
                            placeholder="Enter text ..."
                            rows="7"
                            v-model="ASSIGNDRIVERMODEL.comment"
                          ></textarea>
                          <br />
                        </div>
                        <!-- end email content -->
                        <div class="alert alert-white fade show">
                          <div class="text-center">
                            <button
                              @click="$router.go(-1)"
                              type="button"
                              class="btn btn-danger btn-lg m-r-5"
                            >Cancel</button>
                            <button

                              type="button"
                              @click="assignDriver(context, ASSIGNDRIVERMODEL)"
                              class="btn btn-primary btn-lg m-r-5"
                              :disabled="$wait.is('assignDriver') && $wait.is('assignDriver')"
                            >
                             <v-wait v-bind:for="'assignDriver'">
                        <template slot="waiting">
                          <i class="fas fa-spinner fa-spin"></i>
                        </template>
                        Submit
                      </v-wait></button>
                          </div>
                        </div>
                      </div>
                      <!-- end col-8 -->
                    </div>
                    <!-- end row -->
                  </fieldset>
                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>

        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TaxiDrivers");

export default {
  name: "AssignDriver",
  data: function () {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      id: this.$route.params.id,
      ASSIGNDRIVERMODEL: {
        taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        taxi_driversid: this.$route.params.id,
        taxi_operatorid: 0,
        taxi_operator_vehicle: 0,
        employmentdate: "",
        employmentterminationdate: "",
        reasonforterminationid: 0,
        comment: "",
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      GETALLTAXIS: this.$store.getters["TV/GETVEHICLE"],
      GETAllTAXIMODELS: this.$store.getters["TV/GETALLMODEL"],
      operator: {},
      taxi: {},
      driverImage: false,
      GETOPERATORS: [],
      OPERATORVEHICLES: [],
      skip: 0,
      length: 1000000,
      driverFullname: '',
      driverIdNumber: '',
      driverNumber: '',
    };
  },
  beforeMount: function () {
    $(".default-select").select2({
          multiple: false,
          allowClear: true,
        });
        $(".selectnewoperatorpicker").val(null).trigger("change");
        $(".default-selectTaxi").val(null).trigger("change");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
  },
  computed: {
    ...mapGetters({
      GETTAXIDRIVER: "GETTAXIDRIVEREDIT",
      GETTERMINATIONREASONS: "GETTERMINATIONREASONS",
      GETDRIVEREMPLOYMENTHISTORY: "GETDRIVEREMPLOYMENTHISTORY",
    }),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  watch: {},
  methods: {
    ...mapActions([
      "getall",
      "delete",
      "edit",
      "add",
      "getAllByTaxiAssID",
      "markAsDeleted",
      "clearAll",
      "restoreDeleted",
      "getDriverById",
      "updateDriverStatusRequest",
      "assignDriverVehicle",
    ]),
    GETTAXISBYMODELID: function (context, id) {
      return context.GETALLTAXIS.filter((item) => item._vehiclemodel_id === id);
    },

    driverTermination: (context, taxiassociationid) => {
      context.$store
        .dispatch("TaxiDrivers/getReasonForTerminationById", {
          _dataObj: {
            id: taxiassociationid,
            router: context.$router,
          },
        })
        .then((data) => {})
        .catch((err) => {
          console.log(err);
        });
      //this.$emit('init')
    },

    check: (context) => {},

    loadnewperators: (context) => {
      context.$store
        .dispatch("TO/getAllByTaxiAssID", {
          router: context.$router,
          id: context.taxiassociationid,
        })
        .then((data) => {
          console.log("operators Found: ", data);
          context.GETOPERATORS = data;

          $(".selectnewoperatorpicker").select2({
            multiple: false,
            placeholder: "Select An Operator",
            allowClear: true,
          });
        });

      $(".selectnewoperatorpicker").val(null).trigger("change");
    },
    operatorVehiclesById: (context) => {
      // 
      context.$store
        .dispatch("OperatorVehicle/getVehiclesByAssIdClean", {
          router: context.$router,
          taxiassociation_id: context.taxiassociationid,
          skip: context.skip,
          len: context.length,
        })
        .then((data) => {
          console.log("operator Vehicles: ", data);
          context.OPERATORVEHICLES = data;

          $(".default-selectTaxi").select2({
            multiple: false,
            placeholder: "Select A Taxi",
            allowClear: false,
          });

          $(".default-selectTaxi").val(null).trigger("change");
        })
        .catch((err) => {
          console.log("operator Vehicles errors -> ", err);
        })
        .finally(() => {
          // $("#data-table-vehicles").DataTable({
          //   responsive: true,
          // });
        });
      //this.$emit('init')
    },

    driverEmploymentHistory: (context, id) => {
      context.$store
        .dispatch("TaxiDrivers/getDriverEmploymentHistoryById", {
          _dataObj: {
            id,
            router: context.$router,
          },
        })
        .then((data) => {})
        .catch((err) => {
          console.log(err);
        });
      //this.$emit('init')
    },

    getDriverById: (context, id) => {
      context.$store
        .dispatch("TaxiDrivers/getDriverByIdNoPic", {
          id,
          router: context.$router,
        })
        .then((data) => {
          context.driverFullname = `${data[0].firstname} ${data[0].surname}`;
          context.driverIdNumber = data[0].id_number;
          context.driverNumber = data[0].mobilenumber;
          console.log("driver found: ", data);
        })
        .catch((err) => {
          console.log("driver errors: ", err);
        });
      //this.$emit('init')
    },

    assignDriver:(context, _addModel) => {
      // if (
      //   $(".selectnewoperatorpicker").select2("data") != undefined &&
      //   $(".selectnewoperatorpicker").select2("data") != null &&
      //   $(".selectnewoperatorpicker").select2("data").length > 0
      // ) {
      //   context.operator = $(".default-selectOperator").select2("data")[0];
      // } else {
      //   context.operator = 0;
      // }
        
      if (
        $(".default-selectTaxi").select2("data") != undefined &&
        $(".default-selectTaxi").select2("data") != null &&
        $(".default-selectTaxi").select2("data").length > 0
      ) {
        context.taxi = $(".default-selectTaxi").select2("data")[0];
      } else {
        context.taxi = 0;
      
      context.$Notify.Error("Taxi","Please Select Taxi").then((value) => {
        if (value) {
          
        }
      });

        return
      }

      context.ASSIGNDRIVERMODEL.taxi_operatorid = parseInt(context.operator.id);
      context.ASSIGNDRIVERMODEL.taxi_operator_vehicle = parseInt(
        context.taxi.id
      );

      if (
        $("#employmentDate").find("input").val() != "" &&
        $("#employmentDate").find("input").val() != null &&
        $("#employmentDate").find("input").val() != undefined
      ) {
        context.ASSIGNDRIVERMODEL.employmentdate = context.$Toolkit.ConvertToServerDate(
          $("#employmentDate").datepicker("getDate")
        );
      } else {
        context.ASSIGNDRIVERMODEL.employmentdate = null;
      }

      context.ASSIGNDRIVERMODEL.taxi_driversid = parseInt(context.ASSIGNDRIVERMODEL.taxi_driversid); 

      if (
        $("#terminationDate").find("input").val() != "" &&
        $("#terminationDate").find("input").val() != null &&
        $("#terminationDate").find("input").val() != undefined
      ) {
        context.ASSIGNDRIVERMODEL.employmentterminationdate = context.$Toolkit.ConvertToServerDate(
          $("#terminationDate").datepicker("getDate")
        );
      } else {
        context.ASSIGNDRIVERMODEL.employmentterminationdate = null;
      }

      context.$Notify.YesNo(false, { context, _addModel }).then((value) => {
        if (value) {
          value.context.$wait.start("assignDriver");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TaxiDrivers/assignDriver", {
              addModel: value._addModel,
              router: value.router,
            })
            .then(() => {
              value.context.$wait.end("assignDriver");
              value.context.$Notify.Success("Driver Assigned Successfully", "");
              context.$router.go(-1);
            })
            .catch((error) => {
              
              value.context.$wait.end("assignDriver");

              context.$Notify.ErrorObject(error);
              
            })
            .finally(() => {});
        }
      });
    },
  },
  mounted: function () {
    // document.title = "All Drivers";

    App.init();

    // this.getAllOperatorByTaxiAssId(this.context);
    // this.driverTermination(this.context, this.taxiassociationid);
    // this.driverEmploymentHistory(this.context, this.id);
    this.getDriverById(this.context, this.id);
    this.loadnewperators(this.context);
    this.operatorVehiclesById(this.context);

    $("#terminationDate").datepicker({
      todayHighlight: false,
      autoclose: true
    });

    $("#employmentDate").datepicker({
      todayHighlight: false,
      autoclose: true
    });

    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "",
      transitionSpeed: 0,
      useURLhash: false,
      showStepURLhash: false,
      toolbarSettings: {
        toolbarPosition: "bottom",
      },
    });
    $(".sw-btn-next").remove();
    $(".sw-btn-prev").remove();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>