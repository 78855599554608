/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import config from './configbase';

// options...
export default {
  // options...
  FIN_DEBITORDERS_URL: apiname => `${config.TTAMSFINTECHFIN_URL()}/debitorder/${apiname}`,
  FIN_BANKCARDS_URL: apiname => `${config.TTAMSFINTECHFIN_URL()}/BankCards/${apiname}`,
};

