<template>
  <div class="panel panel-primary" data-sortable-id="form-dropzone-1">
    <div class="panel-heading">
      <div class="btn-group pull-left"></div>
      <div class="panel-heading-btn">
        <a
          href="javascript:;"
          class="btn btn-xs btn-icon btn-circle btn-default"
          data-click="panel-expand"
        >
          <i class="fa fa-expand"></i>
        </a>
        <a
          href="javascript:;"
          class="btn btn-xs btn-icon btn-circle btn-success"
          data-click="panel-reload"
        >
          <i class="fa fa-redo"></i>
        </a>
        <a
          href="javascript:;"
          class="btn btn-xs btn-icon btn-circle btn-warning"
          data-click="panel-collapse"
        >
          <i class="fa fa-minus"></i>
        </a>
        <a
          href="javascript:;"
          class="btn btn-xs btn-icon btn-circle btn-danger"
          data-click="panel-remove"
        >
          <i class="fa fa-times"></i>
        </a>
      </div>
      <h4 class="panel-title">Capture Photos</h4>
    </div>
    <div class="panel-toolbar">
      <div class="btn-group m-r-5">
        <button type="button" class="btn btn-primary" @click="onCapture">Capture Photo</button>
        <button type="button" class="btn btn-danger hide" @click="onStop">Stop Camera</button>
        <button type="button" class="btn btn-success hide" @click="onStart">Start Camera</button>
      </div>
      <div class="btn-group m-r-5">
        <select class="form-control" v-model="camera">
          <option>-- Select Device --</option>
          <option
            v-for="device in devices"
            :key="device.deviceId"
            :value="device.deviceId"
          >{{ device.label }}</option>
        </select>
      </div>
    </div>
    <div class="panel-body text-inverse">
      <vue-web-cam
        ref="webcam"
        id="webcam"
        :device-id="deviceId"
        v-bind:width="CameraWidth"
        v-bind:height="CameraHeight"
        @started="onStarted"
        @stopped="onStopped"
        @error="onError"
        @cameras="onCameras"
        @camera-change="onCameraChange"
      />

      <!-- begin row -->
      <div class="row" v-show="ShowPreviewControl">
        <div class="col-lg-3 col-md-6" :key="item" v-for="(item, index) in GETALL">
          <Cropper
            v-show="index==0"
            ref="cropperimages"
            class="cropper"
            :id="'image-'+ index"
            :src="item.filecontent"
            :stencilProps="{
      aspectRatio: 10/12
    }"
            @change="change"
          ></Cropper>

          <!-- begin card -->
          <div class="cardz">
            <img :id="'imagepreview-'+ index" class="card-img-top hide" alt />
            <img v-show="index>0" class="card-img-top" :src="item.filecontent" alt />
            <div class="card-block">
              <h4 v-show="index==0" class="card-title m-t-0 m-b-10">Profile Photo</h4>
              <h4 v-show="index>0" class="card-title m-t-0 m-b-10">Photo {{index + 1}}</h4>
              <a
                href="javascript:;"
                @click="Delete(context, item.photoid)"
                class="btn btn-sm btn-danger"
              >Remove</a>
              <a
                v-show="index==0"
                href="javascript:;"
                @click="Save(context, item.photoid)"
                class="btn btnsave btn-sm btn-success"
              >Save</a>
            </div>
          </div>
          <!-- end card -->
        </div>
      </div>
      <!-- end row -->
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { Cropper } from "vue-advanced-cropper";
const { mapGetters, mapActions } = createNamespacedHelpers("capturephotos");
import { WebCam } from "vue-web-cam";

export default {
  name: "CapturePhotosControl",
  props: {
    SessionID: {
      type: String,
      required: true
    },
    CameraWidth: {
      type: String,
      required: true
    },
    CameraHeight: {
      type: String,
      required: true
    },
    ClearData: {
      type: Boolean,
      required: true
    },
    ShowStopStartWebCamControl: {
      type: Boolean,
      required: true
    },
    ShowPreviewControl: {
      type: Boolean,
      required: true
    }
  },
  beforeMount() {
    if (this.ClearData) {
      this.$store
        .dispatch("capturephotos/clearall", {
          router: this.$router
        })
        .finally(() => {});
    }
  },
  data: function() {
    return {
      context: this,
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      context: this,
      CAPTUREPHOT0S: [],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      addedBy: this.$store.getters["auth/USERPROFILE"].system_usersid,
      croppedphoto: ""
    };
  },
  components: {
    "vue-web-cam": WebCam,
    Cropper: Cropper
  },
  computed: {
    ...mapGetters(["GETALL"]),
    device: function() {
      return this.devices.find(n => n.deviceId === this.deviceId);
    }
  },
  watch: {
    camera: function(id) {
      this.deviceId = id;
    },
    devices: function() {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    }
  },
  methods: {
    ...mapActions([""]),
    Save(context, photoid) {
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image
      $(".btnsave")
        .text("Saved...")
        .addClass("disabled")
        .attr("disabled", true);
      $("#imagepreview-0")
        .attr("src", context.croppedphoto)
        .height($("#image-0").height())
        .width($("#image-0").width());
      $("#imagepreview-0").removeClass("hide");
      $("#image-0").addClass("hide");

      context.$store.dispatch("capturephotos/updateprofilephoto", {
        id: photoid,
        updatedby: context.addedBy,
        photo: context.croppedphoto,
        router: context.$router
      });
    },
    change({ coordinates, canvas }) {
      this.croppedphoto = canvas.toDataURL();
    },
    Delete: (context, id) => {
      context.$store.dispatch("capturephotos/deletephoto", {
        id,
        deletedby: context.addedBy,
        router: context.$router
      });
      $(".btnsave")
        .text("Save")
        .removeClass("disabled")
        .attr("disabled", false);
    },
    onCapture() {
      if (this.GETALL.length === 1 && $(".btnsave").text() === "Save") {
        this.$Notify.Error("Please Save profile Picture", "");
        return false;
      }

      this.CAPTUREPHOT0S.push(this.$refs.webcam.capture());
      const _photo = this.$refs.webcam.capture();
      this.$store.dispatch("capturephotos/added", {
        router: this.$router,
        filecontent: _photo,
        sessionId: this.SessionID,
        photoid: this.$uuid.v1(),
        addedBy: this.addedBy
      });
      //this.$emit("onCapture",_photo);
    },
    onStarted(stream) {
      console.log("On Started Event", stream);
      this.$emit("onStarted", stream);
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
      this.$emit("onStopped", stream);
    },
    onStop() {
      this.$refs.webcam.stop();
      this.$emit("onStopped");
    },
    onStart() {
      this.$refs.webcam.start();
      this.$emit("onStopped");
    },
    onError(error) {
      console.log("On Error Event", error);
      this.$emit("onStopped", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log("On Cameras Event", cameras);
      this.$emit("onCameras", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      console.log("On Camera Change Event", deviceId);
      this.$emit("onCameraChange", deviceId);
    }
  },
  mounted() {
    $(".panel-body").data("context", this);
  }
};
</script>

<style scoped>
.cropper {
  background: #ddd;
}
</style>

