/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import config from './configbase';

// options...
export default {
  // options...
  RENEWLICENSEDISK_URL: apiname => `${config.BASE_HEROKU_URL()}/onlineservices/${apiname}`,
};

