<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Office'"
      :ActiveMenu="'SendSMS'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Send Bulk SMSes"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Office"
        breadcrumbitem3="SendSMS"
      />
      <!-- end page-header -->

      <!-- begin row -->
      <div class="row">
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-paper-plane"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">ACCOUNT NUMBER</div>
              <div class="stats-number">{{ACCOUNTDETAILS._account_number || 'N/A'}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-logo-usd"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">AVAILABLE BALANCE</div>
              <div class="stats-number">R{{ACCOUNTDETAILS._available_balance | newcurrency}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-mail-open"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">NUMBER OF SENT SMSES</div>
              <div class="stats-number">{{ACCOUNTDETAILS._number_of_sent_smses }}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
        <!-- <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-remove-circle"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">Failed</div>
              <div class="stats-number">{{failed}}</div>
            </div>
          </div>
        </div>-->
        <!-- end col-3 -->
      </div>
      <!-- end row -->

      <!-- begin nav-pills -->
      <ul class="nav nav-pills">
        <li class="nav-items">
          <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active">
            <span class="d-sm-none">Pills 1</span>
            <span class="d-sm-block d-none">Send Bulk SMSs</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-2" data-toggle="tab" id="ViewSms" class="nav-link">
            <span class="d-sm-none">Pills 2</span>
            <span class="d-sm-block d-none">View Sent SMSs</span>
          </a>
        </li>
        <li class="nav-items" v-if="ISINTERNALSYSTEMUSER">
          <a href="#nav-pills-tab-3" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">Pills 3</span>
            <span class="d-sm-block d-none">Top Up</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-4" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">Pills 4</span>
            <span class="d-sm-block d-none">Transactions</span>
          </a>
        </li>
      </ul>
      <!-- end nav-pills -->
      <!-- begin tab-content -->
      <div class="tab-content">
        <!-- begin tab-pane -->
        <div class="tab-pane fade active show" id="nav-pills-tab-1">
          <!-- begin row -->
          <div class="row">
            <!-- begin col-8 -->

            <div class="col-md-12">
              <div class="row">
                <!-- begin col-8 -->
                <div class="col-lg-6">
                  <br />
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-nowrap col-form-label text-md-right">SMS date:</label>
                    <div class="date text-inverse col-md-7 pull-center">
                      <div
                        class="input-group date"
                        id="datepicker-disabled-past3"
                        data-date-format="dd M yyyy"
                      >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Scheduled Reminder Date"
                        />
                        <span class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 col-form-label text-md-right">Message: </label>
                    <div class="date text-inverse col-md-7 pull-center">
                      <!-- textarea -->
                      <textarea v-model="SENDMODEL.message" class="form-control" rows="3"></textarea>
                      <label class="col-md-3 col-form-label" v-text="charactersLeft"></label>
                    </div>
                  
                  </div>
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-nowrap col-form-label text-md-right">Select Drivers:</label>
                    <div class="date text-inverse col-md-7 pull-center">
                      <select
                        class="default-selectDriver width-full default-select form-control col-md-20"
                        data-live-search="true"
                        data-size="5"
                        multiple="multiple"
                      >
                        <option
                          v-for="(item) in GETALLDRIVERS"
                          :key="item._taxi_drivers_id"
                          :value="item._mobilenumber"
                        >{{item._firstname}} {{item._surname}} ({{item._mobilenumber | HumanizeMobile}})</option>
                      </select>
                      <input type="checkbox" id="checkbox" v-model="IsDriversSelected" /> Select All
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label
                      class="col-md-3 text-nowrap col-form-label text-md-right"
                    >Select Operators:</label>
                    <div class="date text-inverse col-md-7 pull-center">
                      <select
                        class="default-selectOperator width-full default-select form-control"
                        data-size="5"
                        data-live-search="true"
                        multiple="multiple"
                      >
                        <option
                          v-for="item in GETALLOPERATOR"
                          :key="item.taxi_operator_id"
                          :value="item.mobilenumber"
                        >{{item.firstname}} {{item.surname}} ({{item.mobilenumber | HumanizeMobile}})</option>
                      </select>
                      <input type="checkbox" id="checkbox" v-model="IsAllOperatorsSelected" /> Select All
                    </div>
                  </div>
                  <!-- end form-group -->
                </div>
                <!-- end col-8 -->

                <div class="col-lg-6">
                  <br />
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-nowrap col-form-label text-md-right">
                      Add Numbers:
                      <span class="text-danger">*</span>
                    </label>
                    <div class="date text-inverse col-md-7 pull-center">
                      <div class="input-group date">
                        <input
                          type="text"
                          class="form-control"
                          id="external"
                          placeholder="Add number(s)"
                          v-model="numbers"
                          @keyup.enter.native="addNumbers(context,numbers)"
                        />
                        <span class="input-group-addon" @click="addNumbers(context,numbers)">
                          <i class="fas fa-plus"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- end form-group -->
                  <table v-show="EXTERNALNUMBERS.length > 0" class="table">
                    <thead>
                      <tr>
                        <th class="text-nowrap">Mobile number</th>
                        <th class="text-nowrap">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item,index) in EXTERNALNUMBERS"
                        v-bind:value="item"
                        :key="item"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td width="15%">{{item}}</td>

                        <td width="20%">
                          <button
                            type="button"
                            class="btn btn-sm btn-danger m-r-2"
                            @click="deleteNumber(context,item)"
                          >Delete</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end col-8 -->
          </div>
          <!-- end row -->

          <!-- submit buttons begin -->
          <div class="alert alert-white fade show">
            <div class="text-center">
              <button
                type="button"
                class="btn btn-primary btn-lg"
                style="margin: 5px;"
                @click="addbulkSMSs(context, SENDMODEL)"
              :disabled="$wait.is('sendbulk')  || charactersLeft<0 "
                      >
                        <v-wait v-bind:for="'sendbulk'">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Submit
                        </v-wait>
                      </button>
              <button
                type="button"
                class="btn btn-white btn-lg"
                style="margin: 5px;"
                @click="cancel(context)"
              >Cancel</button>
            </div>
          </div>
          <!-- submit buttons end -->
        </div>
        <!-- end tab-pane -->
        <!-- begin tab-pane -->
        <div class="tab-pane fade" id="nav-pills-tab-2">
          <!-- begin table-responsive -->
          <div class="panel-body">
            <table id="data-table-combine" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th width="1%" data-orderable="false"></th>
                  <th width="20%">Date</th>
                  <th>Message</th>
                  <th>Total SMSes</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in GETSENTSMSES"
                  :key="item.id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                  <td>{{++index}}</td>
                  <td>{{item.date_added | formatToLongDateTime}}</td>
                  
                  <td>{{item.message}}</td>
                  <td class="text-center">{{item.number_of_batch_smses}}</td>
                  <td>
                     <router-link
                      :to="{ name: 'smsdetails', params: { batchname: item.batch_number }}"
                      class="btn btn-info btn-sm width-120"
                    >View Details</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- end table-responsive -->
        </div>
        <!-- end tab-pane -->

        <!-- begin tab-pane -->
        <div class="tab-pane fade" id="nav-pills-tab-3" v-if="ISINTERNALSYSTEMUSER">
          <!-- begin form-group -->

          <div class="row text-center">
            <!-- begin col-6 -->

            <div class="col-lg-8">
              <div class="form-group row m-b-10">
                <label class="col-md-5 col-form-label text-md-right">
                  Add On Service Type
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-6">
                  <select
                    class="form-control"
                    data-size="10"
                    data-live-search="true"
                    data-parsley-group="step-1"
                    data-parsley-required="true"
                    data-parsley-min="1"
                    v-model="ACCOUNTTOPUP.addon_servicetype"
                  >
                    <option value="3" selected>Bulk SMS</option>
                  </select>
                </div>
              </div>
              <!-- end form-group -->

              <!-- begin form-group -->
              <div class="form-group row m-b-10">
                <label class="col-md-5 col-form-label text-md-right">
                  Amount
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-6">
                  <CurrencyInput
                    :auto-decimal-mode="true"
                    :currency="currency"
                    data-parsley-group="step-1"
                    data-parsley-required="true"
                    class="form-control text-left demo__currency-input"
                    v-model="ACCOUNTTOPUP.amount"
                  />
                </div>
              </div>
              <!-- end form-group -->

              <!-- begin form-group -->
              <div class="form-group row m-b-10">
                <label class="col-md-5 col-form-label text-md-right">
                  Reference:
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-6">
                  <textarea
                    placeholder="Type your reference"
                    class="form-control m-b-10"
                    id="clipboard-textarea"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <!-- end form-group -->

              <!-- begin form-group -->
              <div class="form-group row m-b-10">
                <label class="col-md-5 col-form-label text-md-right">
                  <span class="text-danger"></span>
                </label>
                <div class="col-md-5 m-l-25">
                  <button
                    @click="topUpAssociationAccount(context,ACCOUNTTOPUP)"
                    class="btn btn-primary btn-lg width-120"
                  >Submit</button>
                </div>
              </div>
              <!-- end form-group -->
            </div>
          </div>
        </div>
        <!-- end tab-pane -->

        <!-- begin tab-pane -->
        <div class="tab-pane fade" id="nav-pills-tab-4">
          <!-- begin table-responsive -->
          <div class="panel-body">
            <table id="data-table-default" class="table table-striped table-bordered width-full">
              <thead>
                <tr>
                  <th width="0.5%" data-orderable="false"></th>
                  <th nowrap>ID</th>
                  <th nowrap>DATE</th>
                  <th nowrap>STATUS</th>
                  <th nowrap>REFERENCE</th>
                  <th nowrap>TRANSACTION AMOUNT</th>
                  <th nowrap>REMAINING BALANCE</th>

                  <!-- <th nowrap>Action</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in ACCOUNTTRANSACTIONS"
                  :key="item.id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                  <td width="0.5%" class="f-s-200 text-inverse">{{++index}}</td>
                  <td>{{item.id}}</td>
                  <td>{{item.date_added | formatToLongDateTime}}</td>
                  <td class="text-center">
                    <span
                      class="badge f-s-12"
                      v-bind:class="{ 'badge-success': item.is_active, 'label-secondary':!item.is_active  }"
                      v-if="!item.deleted_by"
                    >{{item.is_active != false ? 'Active' : 'Not-Active'}}</span>
                  </td>
                  <td>{{item.transaction_reference }}</td>
                  <td>{{item.transaction_amount }}</td>
                  <td>{{item.remaning_balance}}</td>

                  <td>
                    <!-- <button
                      @click="resendSms(context,item)"
                      type="button"
                      class="btn btn-primary"
                      disabled
                    >Details</button>-->
                  </td>
                </tr>
              </tbody>
              <!-- pagination -->
              <!-- <ul class="pagination" style="margin-left:125%;">
                <li class="page-item disabled">
                  <a href="#" class="page-link">«</a>
                </li>
                <li class="page-item active">
                  <a href="#" class="page-link">1</a>
                </li>
                <li class="page-item">
                  <a class="page-link">2</a>
                </li>
                <li class="page-item">
                  <a href="#" class="page-link">3</a>
                </li>
                <li class="page-item">
                  <a href="#" class="page-link">4</a>
                </li>
                <li class="page-item">
                  <a href="#" class="page-link">5</a>
                </li>
                <li class="page-item">
                  <a href="#" class="page-link">»</a>
                </li>
              </ul>-->
              <!-- end pagination -->
            </table>
          </div>
          <!-- end table-responsive -->
        </div>
        <!-- end tab-pane -->
      </div>
      <!-- end tab-content -->
    </div>
    <!-- end #content -->
    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("sms");
// value.context.$emit("init");

export default {
  name: "bulkSmses",
  data: function () {
    return {
      currencySymbol: "R ",
      position: "prefix",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      getSmses: this.$store.getters["sms/GETALL"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      GETALLOPERATOR: [],
      GETALLDRIVERS: [],
      DRIVERS: [],
      totalmessages: 0,
      delivered: 0,
      notsent: 0,
      failed: 0,
      ACCOUNTDETAILS: [],
      SENTSMSES: [],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      numbers: "",
      EXTERNALNUMBERS: [],
      driverResults: [],
      operatorResults: [],
      numberAdded: false,
      IsAllOperatorsSelected: false,
      IsDriversSelected: false,
      added_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
      context: this,
      skip: 0,
      len: 10000,
      SENDMODEL: {
        taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        taxi_operator_id: [],
        taxi_driver_id: [],
        sms_number: "",
        message: "",
        batch_name: this.$uuid.v4(),
        added_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
        future_date: new Date(),
        failedurl: "",
        deliveredurl: "",
      },
      eventNumbers: [],
      eventMessage: "",
      statementGenerated: false,
      GETSENTSMSES: [],
      GETMODEL: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        userid: this.$store.getters["auth/USERPROFILE"].system_usersid,
        startdate: "",
        enddate: "",
        skip: 0,
        len: 1000,
      },
      RESENDMODEL: {},
      smsesloaded: false,
      ACCOUNTTOPUP: {
        taxiassociations_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        addon_servicetype: 0,
        amount: 0,
        transaction_reference: "",
        capturedby_id: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      ACCOUNTTRANSACTIONS: [],
    };
  },
  beforeMount: function () {
    this.getDriversbyid(this.context);
    this.getAllOperatorByTaxiAssId(this.context);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    CapturePhotosControl,
  },

  computed: {
    ...mapGetters(["GETALL", "GETSENT"]),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
    currency() {
      return { [this.position]: this.currencySymbol };
    },
    charactersLeft() {
        var char = this.SENDMODEL.message.length,
            limit = 160;

            var r = (limit - char);

        return r;
      },
      numberofmessages() {
        var char = this.SENDMODEL.message.length,
            limit = 160;

            var r = Math.round(char/limit)

            if (r === 0){
              r=r+1
            }

        return "Message(" + r + "):";
      },
  },
  watch: {
    IsAllOperatorsSelected: function (val) {
      $(".default-selectOperator").select2({ disabled: val });
    },
    IsDriversSelected: function (val) {
      $(".default-selectDriver").select2({ disabled: val });
    },
  },
  methods: {
    ...mapActions([
      "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "getSMSsByAssociation",
    ]),

    getAssociationAccountDetails: (context) => {
      context.$store
        .dispatch("TA/getAccountDetailsByServiceTypeId", {
          taxi_assocation_id: context.taxiassociationid,
          service_type_id: 3,
          router: context.$router,
        })
        .then((results) => {
          console.log("Details found: ", results);
          context.ACCOUNTDETAILS = results[0];
        })
        .catch((error) => {
          console.log("Details erros: ", error);

          const errorTitle = "Error";

          const errorMessage = `${error.data.data.message}
          
              Add New Account?`;

          context.$Notify
            .YesNoMore(errorTitle, errorMessage, false, { context, error })
            .then((value) => {
              console.log(value);
              if (value) {
                value.context.$store
                  .dispatch("TA/addTaxiAssociationAccount", {
                    AddModel: {
                      account_type_code: "TAT",
                      taxiassociation_id: value.context.taxiassociationid,
                      add_on_service_type_id: 3,
                      accountstatus_code: "ACT",
                      added_by: value.context.added_by,
                    },
                    router: context.$router,
                  })
                  .then((results) => {
                    console.log("Account added: ", results);
                    // context.ACCOUNTDETAILS = results[0];
                    value.context.$Notify.Success("Successfully Created", "");

                    value.context.getAssociationAccountDetails(context);
                  })
                  .catch((error) => {
                    console.log("add error: ", error);
                    value.context.$Notify.Error(
                      "Something Went Wrong",
                      error.data[0].message
                    );
                  });
              } else {
                // context.$router.go(-1);
              }
            });
        });
    },

    getAccountTransactionsByServiceTypeId: (context) => {
      context.$store
        .dispatch("TA/getAccountTransactionsByServiceTypeId", {
          taxi_assocation_id: context.taxiassociationid,
          service_type_id: 3,
          router: context.$router,
        })
        .then((results) => {
          console.log("Account Transactions found: ", results);
          context.ACCOUNTTRANSACTIONS = results;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          $("#data-table-default").DataTable({
            responsive: true,
          });
        });
    },

    // getAssociationSentSMSes: (context) => {
    //   
    //   context.$store
    //     .dispatch("TA/getAssociationSmsesById", {
    //       id: context.taxiassociationid,
    //       router: context.$router,
    //     })
    //     .then((results) => {
    //       console.log("smses found: ", results);
    //       context.SENTSMSES = results;

    //       if (context.SENTSMSES.length !== 0) {
    //         if ($("#data-table-combine").length !== 0) {
    //           var options = {
    //             dom: "lBfrtip",
    //             buttons: [
    //               { extend: "copy", className: "btn-sm" },
    //               { extend: "csv", className: "btn-sm" },
    //               { extend: "excel", className: "btn-sm" },
    //               { extend: "pdf", className: "btn-sm" },
    //               { extend: "print", className: "btn-sm" },
    //             ],
    //             responsive: true,
    //             autoFill: true,
    //             colReorder: true,
    //             keys: true,
    //             rowReorder: true,
    //             select: true,
    //           };

    //           if ($(window).width() <= 767) {
    //             options.rowReorder = false;
    //             options.colReorder = false;
    //             options.autoFill = false;
    //           }

    //           if (!context.smsesloaded) {
    //             $("#data-table-combine").DataTable(options);
    //           }

    //           context.smsesloaded = true;
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       console.log("Details erros: ", error);
    //     });
    // },

    getDriversbyid: (context) => {
      context.$store
        .dispatch("TaxiDrivers/getAllByTaxiAssIdNoPic", {
          id: context.taxiassociationid,
          router: context.$router,
        })
        .then((results) => {
          context.GETALLDRIVERS = results;
        })
        .catch((error) => {});
    },

    getAllOperatorByTaxiAssId: (context) => {
      context.$store
        .dispatch("TO/getAllByTaxiAssIdNoPic", {
          router: context.$router,
          id: context.taxiassociationid,
        })
        .then((results) => {
          context.GETALLOPERATOR = results;
        })
        .catch((error) => {
          // context.$Notify.Error(error.data[0].message, "");
        });
    },

    addNumbers: (context, number) => {
      console.log(number);
      console.log(number.length);
      if (
        number.length == 10 &&
        context.EXTERNALNUMBERS.filter((i) => i === number).length === 0
      ) {
        context.EXTERNALNUMBERS.push(number);

        context.numbers = "";
      } else {
        context.$Notify.Error(
          "Not a valid Number! \n Please enter a 10-digit number",
          "We cannot accept duplicate numbers."
        );
      }
    },
    deleteNumber: (context, number) => {
      context.EXTERNALNUMBERS = context.EXTERNALNUMBERS.filter(
        (i) => i != number
      );
    },

    addbulkSMSs: (context, addModel) => {
      
      if (context.IsDriversSelected) {
        context.GETALLDRIVERS.forEach((item) => {
          context.EXTERNALNUMBERS.push(item._mobilenumber);
        });
      } else {
        var drivernum = $(".default-selectDriver")
          .select2("data")
          .map((item) => {
            return item.id;
          });
        if (drivernum.length > 0) {
          context.driverResults = [...drivernum];
        }
      }

      if (context.IsAllOperatorsSelected) {
        context.GETALLOPERATOR.forEach((item) => {
          context.EXTERNALNUMBERS.push(item.mobilenumber);
        });
      } else {
        const operatornum = $(".default-selectOperator")
          .select2("data")
          .map((item) => {
            return item.id;
          });

        if (operatornum.length > 0) {
          context.operatorResults = [...operatornum];
        }
      }

      if (context.driverResults !== [] && context.operatorResults === []) {
        addModel.sms_number = [...context.driverResults];
      } else if (
        context.operatorResults !== [] &&
        context.driverResults === []
      ) {
        addModel.sms_number = [...context.operatorResults];
      } else if (
        context.driverResults !== [] &&
        context.operatorResults !== []
      ) {
        addModel.sms_number = [
          ...context.driverResults,
          ...context.operatorResults,
          ...context.EXTERNALNUMBERS,
        ];
      } else {
        addModel.sms_number = context.EXTERNALNUMBERS;
      }

      if (
        $("#datepicker-disabled-past3").find("input").val() != null &&
        $("#datepicker-disabled-past3").find("input").val() != undefined &&
        $("#datepicker-disabled-past3").find("input").val() != ""
      ) {
        const _scheduleDateConverted = context.$Toolkit.ConvertToServerDate(
          $("#datepicker-disabled-past3").find("input").val()
        );
        addModel.future_date = _scheduleDateConverted;
      } else {
        addModel.future_date = context.$Toolkit.ConvertToServerDate(new Date());
      }

      

      if((context.ACCOUNTDETAILS._available_balance-addModel.sms_number.length) < 0)
      {
        context.EXTERNALNUMBERS = [];
        context.$Notify.Error("Not Enough Credits", "");
        return;
      }

      context.$Notify.YesNo(false, { context, addModel }).then((value) => {
        if (value) {
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$wait.start("sendbulk");
          value.context.$store
            .dispatch("sms/add", {
              router: value.router,
              addModel,
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("sendbulk");
              value.context.$Notify.Success("Successfully Added!", "");
              value.context.EXTERNALNUMBERS = [];
              value.context.SENDMODEL.message = "";

              value.context.getAssociationAccountDetails(context);
              // value.context.getAssociationSentSMSes(context);
            })
            .catch((error) => {
              value.context.$wait.end("sendbulk");


               if(Array.isArray(error.data))
              value.context.$Notify.Error(error.data[0].message, "");
              else
              value.context.$Notify.Error(error.data.message, "");
            
              
            })
            .finally((error) => {});
        }
        else{
          context.EXTERNALNUMBERS = [];

        }
      });
    },

    resendSms: (context, item) => {
      console.log(item);

      let numbers = [];
      numbers.push(item._sms_number);

      let newDate = context.$Toolkit.ConvertToServerDate(new Date());

      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          value.context.$wait.start("add");
          const details = {
            taxi_association_id: value.context.taxiassociationid,
            taxi_operator_id: [],
            taxi_driver_id: [],
            sms_number: numbers,
            message: value.item._message,
            batch_name: value.item._batch_name,
            added_by: value.context.SENDMODEL.added_by,
            future_date: newDate,
            failedurl: "",
            deliveredurl: "",
          };
          value.router = value.context.$router;
          value.context.$store
            .dispatch("sms/add", {
              router: value.router,
              addModel: details,
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");
              value.context.$Notify.Success("Successfully Sent!", "");
            })
            .catch((error) => {
              value.context.$wait.end("add");


            if(Array.isArray(error.data))
              value.context.$Notify.Error(error.data[0].message, "");
              else
              value.context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        }
      });
    },

    topUpAssociationAccount: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          value.router = value.context.$router;

          value.item.transaction_reference = document.getElementById(
            "clipboard-textarea"
          ).value;

          value.item.addon_servicetype = parseInt(value.item.addon_servicetype);

          context.$store
            .dispatch("TA/associationAccountTopUp", {
              AddModel: value.item,
              router: value.router,
            })
            .then((results) => {
              value.context.$Notify.Success("Successfully Added!", "");
              value.context.getAssociationAccountDetails(context);

              value.context.ACCOUNTTOPUP.amount = 0;

              value.context.ACCOUNTTOPUP.transaction_reference = "";
            })
            .catch((error) => {
              value.context.$Notify.Error(error.data.message, "");
            });
        }
      });
    },

    cancel: (context) => {
      context.$router.go(-1);
    },
  },
  mounted: function () {
    App.init();
    // const context = this;

    this.getAssociationAccountDetails(this.context);
    this.getAccountTransactionsByServiceTypeId(this.context);

    const context = this;

    $(".nav-link").on("click", function () {
      if ($(this).attr("id") === "ViewSms") {
        
        context.$store
          .dispatch("sms/getAssociationSmsesById", {
            id: context.taxiassociationid,
            router: context.$router,
          })
          .then((results) => {
            context.smsesloaded = false;
            context.GETSENTSMSES = results.data;
            
            //console.log("smses found: ", results);
            if (results.data.length > 0) {
              if ($("#data-table-combine").length !== 0) {
                

                // if (!context.smsesloaded) {
                //   $("#data-table-combine").DataTable(options);
                // }

                // context.smsesloaded = true;
              }
            }
          })
          .catch((error) => {
            console.log("Details erros: ", error);
          }).finally(() => {
          
            var options = {
                  dom: "lBfrtip",
                  buttons: [
                    { extend: "copy", className: "btn-sm" },
                    { extend: "csv", className: "btn-sm" },
                    { extend: "excel", className: "btn-sm" },
                    { extend: "pdf", className: "btn-sm" },
                    { extend: "print", className: "btn-sm" },
                  ],
                  responsive: true,
                  autoFill: true,
                  colReorder: true,
                  keys: true,
                  rowReorder: true,
                  select: true,
                };

                if ($(window).width() <= 767) {
                  options.rowReorder = false;
                  options.colReorder = false;
                  options.autoFill = false;
                }

                $("#data-table-combine").DataTable(options);

          });
      }
    });

    //  $('.data-table').DataTable().clear().destroy();
    // $(".data-table").DataTable({
    //   responsive: true
    // });

    $(".default-selectDriver").select2({
      multiple: true,
      placeholder: "Select A Driver",
      allowClear: true,
    });

    $(".default-selectOperator").select2({
      multiple: true,
      placeholder: "Select A Operator",
      allowClear: true,
    });

    $("#datepicker-disabled-past3").datetimepicker({
      sideBySide: true,
    });

    $("#advance-daterange span").data("context", this.context);
    $("#advance-daterange span").html(
      moment().subtract("days", 29).format("MMMM D, YYYY") +
        " - " +
        moment().format("MMMM D, YYYY")
    );

    $("#advance-daterange").daterangepicker(
      {
        format: "MM/DD/YYYY",
        startDate: moment().subtract(29, "days"),
        endDate: moment(),
        minDate: "01/01/2010",
        maxDate: "12/31/2030",
        dateLimit: { days: 60 },
        showDropdowns: true,
        showWeekNumbers: true,
        timePicker: false,
        timePickerIncrement: 1,
        timePicker12Hour: true,
        ranges: {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days"),
          ],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ],
        },
        opens: "right",
        drops: "down",
        buttonClasses: ["btn", "btn-sm"],
        applyClass: "btn-primary",
        cancelClass: "btn-default",
        separator: " to ",
        locale: {
          applyLabel: "Submit",
          cancelLabel: "Cancel",
          fromLabel: "From",
          toLabel: "To",
          customRangeLabel: "Custom",
          daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
          monthNames: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          firstDay: 1,
        },
      },
      function (start, end, label) {
        const context = $("#advance-daterange span").data("context");

        context.GETMODEL.startdate = start.format("D MMMM YYYY");
        context.GETMODEL.enddate = end.format("D MMMM YYYY");
        $("#advance-daterange span").html(
          start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
        );
      }
    );
    $('[data-toggle="batchNametooltip "]').tooltip();
    $('[data-toggle="BatchNumbertooltip "]').tooltip();
    $('[data-toggle="messagetooltip "]').tooltip();
  },
};
</script>

	

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>