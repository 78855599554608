/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';


export default {
  namespaced: true,
  state: {
    receipt_disputes: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETALLRECEIPTDISPUTES: state => state.data,
  },
  mutations: {
    LOADED: (state, _data) => {
      if (_data !== undefined) {
        state.receipt_disputes = _data;
      }
    },
    LOADDETAILS: (state, _data) => {
      if (_data !== undefined) {
        state.print = _data;
      }
    },
    LOADINVOICERESULTS: (state, _data) => {
      if (_data !== undefined) {
        state.invoiceresults = _data;
      }
    },
    ADDED: (state, _data) => {
      state.receipt_disputes.push(_data);
    },
    UPDATED: (state, _data) => {
      if (_data !== undefined) {
        Object.assign(state.receipt_disputes.filter(item => item._id === _data._id)[0], _data);
      }
    },
    RESTOREDELETED: (state, {
      id
    }) => {
      if (id !== undefined) {
        state.receipt_disputes.filter(item => item._id === id)[0]._deleted_by = undefined;
        state.receipt_disputes.filter(item => item._id === id)[0]._date_deleted = undefined;
      }
    },
    MARKASDELETE: (state, {
      id,
      deletedby
    }) => {
      if (id !== undefined) {
        state.data.filter(item => item._id === id)[0]._deleted_by = deletedby;
        state.data.filter(item => item._id === id)[0]._date_deleted = Date.now;
      }
    },
    DELETE: (state, id) => {
      if (id !== undefined) {
        state.data = state.data.filter(item => item._id !== id);
        state.deleted = true;
      }
    },
  },
  actions: {
    /* Async */

    /* Auth.js Mutations */
    getall({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TITLE_URL,
        };

        this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
          if (parsedResult.status) {
            commit('LOADED', parsedResult.data);
            resolve();
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
    getById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => {
     
      

      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.INVOICES_URL,
      };

      this._vm.$TAMSFIN_API.getById(requestheader,
        id
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          commit('LOADDETAILS', parsedResultOP.data[0]);
          resolve(parsedResultOP.data[0]);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        reject(error);
        commit('LOGINFAILED', error.data);
      });
    });
    },
    getAllByTaxiAssID({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {

      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.BILLINGITEMS_URL,
      };

      this._vm.$TAMSFIN_API.getByTaxiAssId(requestheader,
        id
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          commit('LOADED', parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    },

    createInvoiceDispute({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,_addDispute }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.RECEIPTDISPUTES_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          _addDispute).then((parsedResult) => {
          if (parsedResult.status) {
            item._id = parsedResult.data;
            commit('ADDED', parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    // createReceiptDisputeRequest({
    //   state,
    //   rootState,
    //   rootGetters,
    //   commit,
    //   dispatch,
    //   getters,
    // }, {
    //   router,
    //   _addModel
    // }) {
    //   return new Promise((resolve, reject) => {
        
    //     const requestheader = {
    //       router,
    //       token: rootGetters['auth/TOKEN'],
    //       apiName: config.RECEIPTDISPUTES_URL,
    //     };

    //     this._vm.$TAMSFIN_API.add(requestheader,
    //       _addModel).then((parsedResult) => {
    //       if (parsedResult.status) {

    //         commit('DISPUTEADDED', parsedResult.data);
    //         resolve(parsedResult.data);
          
    //       }
    //     }).catch((error) => {
    //       // commit('LOGINFAILED', error.data);
    //       reject(error);
    //     });
    //   });
    // },

    delete({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TITLE_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id, {
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('DELETE', id);
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    markAsdelete({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TITLE_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id, {
            id,
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('MARKASDELETE', {
              id,
              deletedby
            });
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    restoreDeleted({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          restoredby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TITLE_URL,
        };

        this._vm.$TAMS_API.restoreDeleted(requestheader, {
          id,
          restoredby,
        }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('RESTOREDELETED', {
              id
            });
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

  },

};