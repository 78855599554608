<template>
  <!-- begin invoice -->
  <div class="invoice">
    <!-- begin invoice-Logo -->
    <span class="pull-right">
      <img :src="encodedImage" class="width-160 height-40" />
    </span>
    <!-- begin invoice-Logo -->
    <!-- begin invoice-company -->
    <div class="invoice-company text-inverse f-w-600">
      <span class="pull-right hidden-print">
        <a href="javascript:;" onclick="window.close()" class="btn btn-warning m-r-20 m-b-10 p-l-5">
          <i class="fa fa-window-close t-plus-1 text-white fa-fw fa-lg"></i> Exit
        </a>
        <a href="javascript:;" @click="print(context)" class="btn btn-white m-r-20 m-b-10 p-l-5">
          <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print Receipt
        </a>
      </span>
      Cash Receipt
    </div>
    <!-- end invoice-company -->
    <!-- begin invoice-header -->
    <div class="invoice-header">
      <div class="invoice-from">
        <small>from</small>
        <address class="m-t-5 m-b-5">
          <strong class="text-inverse">{{Payment.taxi_associations_name}}.</strong>
          <br />
          {{Payment.taxi_associations_address}}
          <br />
          {{Payment.taxi_associations_suburb_name}},
          {{Payment.taxi_associations_suburb_postalcode}}
          <br />
          Phone: {{Payment.taxi_associations_telephone | HumanizeMobile}}
          <br />
          Cel: {{Payment.taxi_associations_mobilenumer | HumanizeMobile}}
        </address>
      </div>
      <div
        class="invoice-to"
        :class="{ 'hidden-print': !Payment.taxi_driver_id }"
        v-if="Payment.taxi_driver_id"
      >
        <small>to</small>
        <address class="m-t-5 m-b-5">
          <strong class="text-inverse">{{Payment.taxi_driver_name}}</strong>
          <br />
          {{Payment.taxi_driver_address}}
          <br />
          {{Payment.taxi_driver_suburb_name}},
          {{Payment.taxi_driver_suburb_postalcode}}
          <br />
        </address>
      </div>
      <div
        class="invoice-to"
        :class="{ 'hidden-print': !Payment.taxi_operator_id }"
        v-if="Payment.taxi_operator_id"
      >
        <small>to</small>
        <address class="m-t-5 m-b-5">
          <strong class="text-inverse">{{Payment.taxi_operator_name}}</strong>
          <br />
          {{Payment.taxi_operator_address}}
          <br />
          {{Payment.taxi_operator_suburb_name}},
          {{Payment.taxi_operator_suburb_postalcode}}
        </address>
      </div>
      <div
        class="invoice-to"
        :class="{ 'hidden-print': !Payment.taxi_operator_vehicles_id }"
        v-if="Payment.taxi_operator_vehicles_id"
      >
        <small>to</small>
        <address class="m-t-5 m-b-5">
          <strong class="text-inverse">{{Payment.taxi_operator_vehicles_regno}}</strong>
          <br />
          <strong
            class="text-inverse"
          >{{Payment.taxi_operator_vehicles_vehiclemake}} {{Payment.taxi_operator_vehicles_vehiclemodel}}</strong>
          <br />
          {{Payment.taxi_vehicle_operator_name}}
          <br />
          {{Payment.taxi_operator_vehicles_address}}
          <br />
          {{Payment.taxi_operator_vehicles_suburb_name}},
          {{Payment.taxi_operator_vehicles_suburb_postalcode}}
          <br />
        </address>
      </div>

      <div class="invoice-date">
        <small>Receipt / {{Payment.captured_date | formatMonth }} Period</small>
        <div class="date text-inverse m-t-5">{{Payment.captured_date | formatToShortDate }}</div>
        <div class="invoice-detail">Receipt No: {{Payment.receipt_number}}</div>
      </div>
    </div>
    <!-- end invoice-header -->
    <!-- begin invoice-content -->
    <div class="invoice-content">
      <!-- begin invoice-price -->
      <div class="invoice-price">
        <div class="invoice-price-left">
          <div class="invoice-price-row">
            <div class="sub-price">
              <small>PAYMENT</small>
              <span v-if="Payment.paymentdetails" class="text-inverse">R{{Payment.paymentdetails.paymentamount | currency}}</span>
              <span v-if="!Payment.paymentdetails" class="text-inverse">R{{Math.abs(Payment.amount) | currency}}</span>
            </div>
            <div class="sub-price">
              <i class="fa fa-plus text-muted"></i>
            </div>
            <div class="sub-price">
              <small>TRANSACTION FEE</small>
              <span v-if="Payment.paymentdetails" class="text-inverse">R{{Payment.paymentdetails.transactionfee | currency}}</span>
              <span v-if="!Payment.paymentdetails" class="text-inverse">R{{0 | currency}}</span>
            </div>
          </div>
        </div>
        <div class="invoice-price-right">
          <small>TOTAL PAYMENT</small>
          <span v-if="Payment.paymentdetails" class="f-w-600">R{{Payment.paymentdetails.totalamount | currency}}</span>
          <span v-if="!Payment.paymentdetails" class="f-w-600">R{{Math.abs(Payment.amount) | currency}}</span>
        </div>
      </div>
      <!-- end invoice-price -->
    </div>
    <!-- end invoice-content -->
    <!-- begin invoice-note -->
    <div class="invoice-note"></div>
    <!-- end invoice-note -->
    <!-- begin invoice-footer -->
    <div class="invoice-footer">
      <p class="text-center m-b-5 f-w-600">THANK YOU FOR YOUR BUSINESS</p>
      <p class="text-center">
        <span class="m-r-10">
          <i class="fa fa-fw fa-lg fa-phone-volume"></i>
          T:{{Payment.taxi_associations_telephone | HumanizeMobile}}
        </span>
        <span class="m-r-10">
          <i class="fa fa-fw fa-lg fa-phone-volume"></i>
          M:{{Payment.taxi_associations_mobilenumer | HumanizeMobile}}
        </span>
      </p>
    </div>
    <!-- end invoice-footer -->
  </div>
  <!-- end invoice -->
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("payments");

export default {
  name: "ViewPayment",
  data: function() {
    return {
      context: this,
      id: this.$route.params.id,
      encodedImage: undefined,
      SELECTEDTAXIASSOCIATION: this.$store.getters[
        "auth/SELECTEDTAXIASSOCIATION"
      ],
      preview: this.$options.filters.parseBoolean(this.$route.params.preview)
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  beforeMount: function() {
    this.getPaymentDetailsId(this.context, this.id);
  },
  components: {},

  computed: {
    ...mapGetters({
      Payment: "GETDETAILS"
    })
  },
  watch: {},
  methods: {
    print: context => {
      document.title = context.Payment.receipt_number;
      window.print();
      document.title = context.Payment.taxi_associations_name;
    },
    printnow: context => {
      window.print();
      setTimeout(function() {
        window.close();
      }, 600);
    },
    getPaymentDetailsId: (context, id) => {
      context.$store
        .dispatch("payments/getById", {
          router: context.$router,
          id
        })
        .then(() => {
          App.init();
          if (context.preview === false) {
            
            document.title = context.Payment.receipt_number;
            context.printnow(context.context);
          } else {
            document.title = context.Payment.taxi_associations_name;
          }
        })
        .catch(error => {
          value.context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    }
  },
  mounted: function() {
    this.$store.dispatch("TA/getLOGOURL", {
      id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id
    }).then((result) => {
      this.context.encodedImage = result;
    }).catch((err) => {
    });
  }
};
</script>

<style scoped>
</style>

