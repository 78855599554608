<template>
  <!-- begin invoice -->
  <div class="invoice">
    <!-- begin invoice-Logo -->
    <span class="pull-right">
     <img :src="encodedImage" class="width-160 height-40" />
    </span>
    <!-- begin invoice-Logo -->
    <!-- begin invoice-company -->
    <div class="invoice-company text-inverse f-w-600">
      <span class="pull-right hidden-print">
        <a href="javascript:;" onclick="window.close()" class="btn btn-warning m-r-20 m-b-10 p-l-5">
          <i class="fa fa-window-close t-plus-1 text-white fa-fw fa-lg"></i> Exit
        </a>
        <a href="javascript:;" @click="print(context)" class="btn btn-white m-r-20 m-b-10 p-l-5">
          <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print Invoice
        </a>
      </span>
      Invoice
    </div>
    <!-- end invoice-company -->
    <!-- begin invoice-header -->
    <div class="invoice-header">
      <div class="invoice-from">
        <small>from</small>
        <address class="m-t-5 m-b-5">
          <strong class="text-inverse">{{Invoice.taxi_associations_name}}.</strong>
          <br />
          {{Invoice.taxi_associations_address}}
          <br />
          {{Invoice.taxi_associations_suburb_name}},
          {{Invoice.taxi_associations_suburb_postalcode}}
          <br />
          Phone: {{Invoice.taxi_associations_telephone | HumanizeMobile}}
          <br />
          Cel: {{Invoice.taxi_associations_mobilenumer | HumanizeMobile}}
        </address>
      </div>
      <div class="invoice-to" v-if="Invoice.taxi_driver_id">
        <small v-show="Invoice.taxi_driver_id">to</small>
        <address class="m-t-5 m-b-5">
          <strong class="text-inverse">{{Invoice.taxi_driver_name}}</strong>
          <br />
          {{Invoice.taxi_driver_address}}
          <br />
          {{Invoice.taxi_driver_suburb_name}},
          {{Invoice.taxi_driver_suburb_postalcode}}
          <br />
        </address>
      </div>
      <div class="invoice-to" v-if="Invoice.taxi_operator_id">
        <small v-show="Invoice.taxi_operator_id">to</small>
        <address class="m-t-5 m-b-5">
          <strong class="text-inverse">{{Invoice.taxi_operator_name}}</strong>
          <br />
          {{Invoice.taxi_operator_address}}
          <br />
          {{Invoice.taxi_operator_suburb_name}},
          {{Invoice.taxi_operator_suburb_postalcode}}
          <br />
        </address>
      </div>
      <div class="invoice-to" v-if="Invoice.taxi_operator_vehicles_id">
        <small v-show="Invoice.taxi_operator_vehicles_id">to</small>
        <address class="m-t-5 m-b-5">
          <strong class="text-inverse">{{Invoice.taxi_operator_vehicles_regno}}</strong>
          <br />
          <strong
            class="text-inverse"
          >{{Invoice.taxi_operator_vehicles_vehiclemake}} {{Invoice.taxi_operator_vehicles_vehiclemodel}}</strong>
          <br />
          {{Invoice.taxi_vehicle_operator_name}}
          <br />
          {{Invoice.taxi_operator_vehicles_address}}
          <br />
          {{Invoice.taxi_operator_vehicles_suburb_name}},
          {{Invoice.taxi_operator_vehicles_suburb_postalcode}}
          <br />
        </address>
      </div>
      <div class="invoice-date">
        <small>Invoice / {{Invoice.invoice_date | formatMonth }} Period</small>
        <div class="date text-inverse m-t-5">{{Invoice.invoice_date | formatToShortDate }}</div>
        <div class="invoice-detail">
          Invoice No: {{Invoice.invoice_number}}
          <br />
        </div>
      </div>
    </div>
    <!-- end invoice-header -->
    <!-- begin invoice-content -->
    <div class="invoice-content">
      <!-- begin table-responsive -->
      <div class="table-responsive">
        <table class="table table-invoice">
          <thead>
            <tr>
              <th></th>
              <th>DESCRIPTION</th>
              <th class="text-center" width="10%">QTY</th>
              <th class="text-center" width="10%">PRICE</th>
              <th class="text-right" width="20%">LINE TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in Invoice.invoice_details.billingitems" v-bind:key="item._id">
              <td>
                <span class="text-inverse">{{++index}}</span>
                <br />
              </td>
              <td>
                <span class="text-inverse">{{item.billingitemname}}</span>
                <br />
              </td>

              <td class="text-center">{{item.lineqty}}</td>
              <td class="text-center">R{{item.lineamount | currency}}</td>
              <td class="text-right">R{{item.linetotal | currency}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- end table-responsive -->
      <!-- begin invoice-price -->
      <div class="invoice-price">
        <div class="invoice-price-left">
          <div class="invoice-price-row">
            <div class="sub-price">
              <small>SUBTOTAL</small>
              <span class="text-inverse">R{{Invoice.invoice_details.totalinvoiceamount | currency}}</span>
            </div>
            <div class="sub-price">
              <i class="fa fa-plus text-muted"></i>
            </div>
            <div class="sub-price">
              <small>TRANSACTION FEE</small>
              <span class="text-inverse">R{{Invoice.invoice_details.transactionfee | currency}}</span>
            </div>
          </div>
        </div>
        <div class="invoice-price-right">
          <small>TOTAL</small>
          <span class="f-w-600">R{{Invoice.invoice_details.totalamount | currency}}</span>
        </div>
      </div>
      <!-- end invoice-price -->
    </div>
    <!-- end invoice-content -->
    <!-- begin invoice-note -->
    <div class="invoice-note">
      * Make all cheques payable to {{Invoice.taxi_associations_name}}
      <br />* Payment is due within 30 days
      <br />
    </div>
    <!-- end invoice-note -->
    <!-- begin invoice-footer -->
    <div class="invoice-footer">
      <p class="text-center m-b-5 f-w-600">THANK YOU FOR YOUR BUSINESS</p>
      <p class="text-center">
        <span class="m-r-10">
          <i class="fa fa-fw fa-lg fa-phone-volume"></i>
          T:{{Invoice.taxi_associations_telephone | HumanizeMobile}}
        </span>
        <span class="m-r-10">
          <i class="fa fa-fw fa-lg fa-phone-volume"></i>
          M:{{Invoice.taxi_associations_mobilenumer | HumanizeMobile}}
        </span>
      </p>
    </div>
    <!-- end invoice-footer -->
  </div>
  <!-- end invoice -->
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("invoices");

export default {
  name: "ViewInvoice",
  data: function() {
    return {
      context: this,
      id: this.$route.params.id,
      encodedImage: undefined,
      preview: this.$options.filters.parseBoolean(this.$route.params.preview)
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  beforeMount: function() {
    this.getInvoiceDetailsId(this.context, this.id);
  },
  components: {},

  computed: {
    ...mapGetters({
      Invoice: "GETDETAILS"
    })
  },
  watch: {},
  methods: {
    print: context => {
      document.title = context.Invoice.invoice_number;
      window.print();
      document.title = context.Invoice.taxi_associations_name;
    },
    printnow: context => {
      window.print();

      setTimeout(function() {
        window.close();
      }, 600);
    },
    getInvoiceDetailsId: (context, id) => {
      context.$store
        .dispatch("invoices/getById", {
          router: context.$router,
          id
        })
        .then(() => {
          App.init();
          if (context.preview === false) {
            document.title = context.Invoice.invoice_number;
            context.printnow(context.context);
          } else {
            document.title = context.Invoice.taxi_associations_name;
          }
        })
        .catch(error => {
          value.context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    }
  },
  mounted: function() {
    this.$store.dispatch("TA/getLOGOURL", {
      id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id
    }).then((result) => {
      this.context.encodedImage = result;
    }).catch((err) => {
    });
  }
};
</script>

<style scoped>
</style>

