<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageVehicle'"
      :ActiveMenu="'ManageMakes'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Models"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Makes"
        breadcrumbitem3="Models"
        breadcrumbitem4="Manage"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">All Models</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <a
                  data-toggle="modal"
                  data-target="#AddModal"
                  class="btn btn-white"
                  href="javascript:;"
                >
                  <i class="fa fa-plus"></i>
                  Add Model
                </a>
              </div>
            </div>

            <!-- Add Modal HTML START-->
            <div id="AddModal" class="modal fade">
              <div class="modal-dialog">
                <div class="modal-content">
                  <form>
                    <div class="modal-header">
                      <h4 class="modal-title">Add Model</h4>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                      >&times;</button>
                    </div>
                    <div class="modal-body">
                      <div class="col-md-12">
                        <!-- begin form-group row -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Name
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              data-parsley-group="step-1"
                              data-live-search="true"
                              data-parsley-required="true"
                              class="form-control"
                              v-model="ADDMODEL.name"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Description
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              data-parsley-group="step-1"
                              data-live-search="true"
                              data-parsley-required="true"
                              class="form-control"
                              v-model="ADDMODEL.description"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Code
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              data-parsley-group="step-1"
                              data-live-search="true"
                              data-parsley-required="true"
                              class="form-control"
                              v-model="ADDMODEL.code"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                      </div>
                    </div>
                    <div class="modal-footer">
                      <input
                        type="button"
                        class="btn btn-default"
                        data-dismiss="modal"
                        value="Cancel"
                      />
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-80"
                        :disabled="$wait.is('add-')"
                        @click="add(context,ADDMODEL)"
                      >
                        <v-wait v-bind:for="'add-'">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Save
                        </v-wait>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- Add Modal HTML START-->

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-titles" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Make</th>
                    <th class="text-nowrap">Model</th>
                    <th class="text-nowrap">Year</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Last Updated By</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETVEHICLEMODELSBYID"
                    v-bind:key="item._id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item._vehiclemake_name}}</td>
                    <td>{{item._description}}</td>
                    <td>{{item._yearmodel_name}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._deleted_by }"
                        v-if="item._deleted_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item._is_active, 'label-secondary':!item._is_active  }"
                        v-if="!item._deleted_by"
                      >{{item._is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td>{{item._updateby_name || item._addedby_name}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <button type="button" class="btn btn-sm btn-white width-80 m-r-2">View</button>
                      <button
                        type="button"
                        class="btn btn-sm btn-primary width-80 m-r-2"
                        data-toggle="modal"
                        :disabled="item._deleted_by"
                      >Edit</button>
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-80"
                        v-show="item._deleted_by"
                        :disabled="$wait.is('restoreDeleted-' + item._id)"
                      >
                        <v-wait v-bind:for="'restoreDeleted-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Restore
                        </v-wait>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-80"
                        v-show="!item._deleted_by"
                        :disabled="$wait.is('delete-' + item._id)"
                      >
                        <v-wait v-bind:for="'delete-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Delete
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import headerComp from "@/components/common/header.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import pageloader from "@/components/common/pageloader.vue";

import { createNamespacedHelpers } from "vuex";
import { setTimeout } from "timers";
const { mapGetters, mapActions } = createNamespacedHelpers("OperatorVehicle");

export default {
  name: "ManageModels",
  data: function() {
    return {
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      addedsuccess: undefined,
      errormessage: undefined,
      id: this.$route.params.id,
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      GETVEHICLEMODELSBYID: [],
      ADDMODEL: {
        vehiclemake_id: this.$route.params.id,
        yearmodel_id: 1,
        name: "",
        description: "",
        code: "",
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid
      },
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters([]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions(["getall", "delete", "edit", "add"]),
    add: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          value.context.$wait.start("add-");

          value.item.vehiclemake_id = parseInt(context.ADDMODEL.vehiclemake_id);
          value.router = value.context.$router;
          value.context.$store
            .dispatch("vehiclemodel/add", {
              AddModel: value.item,
              router: value.router
            })
            .then((data) => {
              $("#AddModal").modal("hide");
              console.log("add results: ", data);
              context.getallmodels(context);
              value.context.$wait.end("add-");
              value.context.$Notify.Success("Successfully Added!", "");
            })
            .catch(error => {
              value.context.$wait.end("add-");

              console.log("add errors: ", error);

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    setAdd(val) {
      this.$store
        .dispatch("title/add", {
          _dataObj: val
        })
        .then(() => {});
    },
    getallmodels: context => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleModelsByMakeID", {
          _dataObj: {
            id: context.id,
            router: context.$router
          }
        })
        .then(data => {
          console.log("vehicle models found:", data);
          context.GETVEHICLEMODELSBYID = data;
        });
    },
    edited: (context, item, updatedby) => {
      //item = Object.assign({}, item);
      context.$Notify.YesNo(false, { context, item, updatedby }).then(value => {
        if (value) {
          value.context.$wait.start("edited");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("title/update", {
              _dataObj: value
            })
            .then(() => {
              $("#EdititleModal").modal("hide");
              value.context.$wait.end("edited");
              value.context.$Notify.Success("Successfully Updated!", "");
            })
            .catch(error => {
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {
              value.context.$wait.end("edited");
            });
        }
      });
    },
    deletetitle: (context, item, deletedby) => {
      context.$Notify
        .YesNo(false, { context, id: item._id, deletedby })
        .then(value => {
          if (value) {
            value.context.$wait.start("delete-" + value.id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("title/markAsdelete", {
                _dataObj: value
              })
              .then(id => {
                value.context.$wait.end("delete-" + id);
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch(error => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    }
  },
  mounted: function() {
    App.init();
    this.getallmodels(this.context);
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

