<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp :USERPROFILE="USERPROFILE" />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Taxi Vehicle Status"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Vehicles"
        breadcrumbitem3="Manage Vehicle Status"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-inverse">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">All Vehicles</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <a
                  data-toggle="modal"
                  data-target="#AddModal"
                  class="btn btn-white"
                  href="javascript:;"
                  @click="clearAdded(GETSELECTED)"
                >
                  <i class="fa fa-plus"></i>
                  Add Status
                </a>
              </div>
            </div>

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-titles" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">Description</th>
                     <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Added By</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETADDVEHICLESTATUS"
                    v-bind:key="item._vehiclestatus_id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item._name}}</td>
                     <td>{{item._description}}</td>
  
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._deleted_by }"
                        v-if="item._deleted_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item._is_active, 'label-secondary':!item._is_active  }"
                        v-if="!item._deleted_by"
                      >{{item._is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td>{{item._updateby_name || item._addedby_name}}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-80"
                        data-toggle="modal"
                        data-target="#deletedModal"
                        v-if="item._deleted_by"
                        @click="Restore(context,item,USERPROFILE.system_usersid)"
                        :disabled="$wait.is('restoreDeleted-' + item._id)"
                      >
                        <v-wait v-bind:for="'restoreDeleted-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Restore
                        </v-wait>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-80"
                        v-if="!item._deleted_by"
                        @click="Delete(context,item,USERPROFILE.system_usersid)"
                        :disabled="$wait.is('delete-' + item._id)"
                      >
                        <v-wait v-bind:for="'delete-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Delete
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                  <div class="btn-group ml-auto">
                        <button @click="show(context,false);" class="btn btn-white btn-sm">
                          <i class="fa fa-chevron-left f-s-14 t-plus-1"></i>
                        </button>
                        <button @click="show(context,true);" class="btn btn-white btn-sm">
                          <i class="fa fa-chevron-right f-s-14 t-plus-1"></i>
                        </button>
                      </div>
                </tbody>
              </table>

              <!-- Edit Modal HTML START-->
              <div id="ViewModal" class="modal fade" v-if="GETSELECTED">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">View</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Name</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                :value="GETSELECTED._name"
                                disabled
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Description</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                :value="GETSELECTED._description"
                                disabled
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->
                            <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Code</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                :value="GETSELECTED._code"
                                disabled
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">
                              Added
                              By
                            </label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="AddedBy"
                                id="added_by"
                                class="form-control"
                                :value="GETSELECTED._addedby_name"
                                disabled
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">
                              Date
                              Addded
                            </label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="AddedBy"
                                id="added_by"
                                placeholder=""
                                class="form-control"
                                :value="GETSELECTED._date_added"
                                disabled
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->

                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">
                              Updated
                              By
                            </label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="UpdatedBy"
                                id="updated-by"
                                placeholder=""
                                class="form-control"
                                :value="GETSELECTED._updateby_name"
                                disabled
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->

                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">
                              Date
                              Updated
                            </label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="AddedBy"
                                id="added_by"
                                placeholder=""
                                class="form-control"
                                :value="GETSELECTED._date_updated"
                                disabled
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->

                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">
                              Deleted
                              By
                            </label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="DeletedBy"
                                class="form-control"
                                :value="GETSELECTED._deletedby_name"
                                disabled
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->

                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">
                              Date
                              Deleted
                            </label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="AddedBy"
                                id="added_by"
                                class="form-control"
                                :value="GETSELECTED._date_deleted"
                                disabled
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->

                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Status</label>
                            <div
                              class="checkbox checkbox-css"
                              :class="{'is-valid':GETSELECTED._is_active}"
                            >
                              <input
                                type="checkbox"
                                id="cssCheckbox1"
                                :checked="GETSELECTED._is_active"
                                disabled
                              >
                              <label v-if="GETSELECTED._deleted_by" for="cssCheckbox1">Deleted</label>
                              <label
                                v-if="!GETSELECTED._deleted_by"
                                for="cssCheckbox1"
                              >{{GETSELECTED._is_active == true ? 'Active' : 'Disabled' }}</label>
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        >
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Edit Modal HTML START-->

              <!-- Edit Modal HTML START-->
              <div id="EditModal" class="modal fade" v-if="GETSELECTED">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Edit User Group</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Name</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                v-model.lazy="GETSELECTED._name"
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Description</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                class="form-control"
                                v-model.lazy="GETSELECTED._description"
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->
                           <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Code</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                class="form-control"
                                v-model.lazy="GETSELECTED._code"
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Status</label>
                            <div class="checkbox checkbox-css">
                              <input
                                type="checkbox"
                                id="cssCheckbox1edit"
                                v-model="GETSELECTED._is_active"
                                :checked="GETSELECTED._is_active"
                              >
                              <label
                                v-if="!GETSELECTED._deleted_by"
                                for="cssCheckbox1edit"
                              >{{GETSELECTED._is_active ? 'Active' : 'Disabled' }}</label>
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        >
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="edited(context,GETSELECTED,USERPROFILE.system_usersid)"
                          :disabled="$wait.is('edited')"
                        >
                          <v-wait v-bind:for="'edited'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Save
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Edit Modal HTML START-->

              <!-- Add Modal HTML START-->
              <div id="AddModal" class="modal fade" v-if="GETSELECTED">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Add Title</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Name</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="billingTypeName"
                                id="biiling_type_name"
                                placeholder="Enter Name"
                                class="form-control"
                                minlength="1"
                                v-model="GETSELECTED._name"
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->

                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Description</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="billingTypeName"
                                id="biiling_type_name"
                                placeholder="Enter Description"
                                class="form-control"
                                minlength="3"
                                v-model="GETSELECTED._description"
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->
                           <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Code</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="billingTypeName"
                                id="biiling_type_name"
                                placeholder="Enter Description"
                                class="form-control"
                                minlength="3"
                                v-model="GETSELECTED._code"
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        >
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="add(context,GETSELECTED,USERPROFILE.system_usersid)"
                          :disabled="$wait.is('add')"
                        >
                          <v-wait v-bind:for="'add'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Add
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Add Modal HTML START-->
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller/>
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("OperatorVehicle");

export default {
  name: "ManageVehiclesStatus",
  data: function() {
    return {
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
       GETOPERATORS: this.$store.getters["TO/GETADDOPERATOR"],
       SELECTEDOPERATORS: {},
      context: this,
      addedsuccess: undefined,
      errormessage: undefined,
       skip:-30,
       len:30,

    //  id: this.$route.params.id,
     GETSELECTED: Object.assign({}, this.$store.getters["OperatorVehicle/GETADDVEHICLESTATUS"])[0]

     
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["GETADDVEHICLESTATUS"]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "getByPaging",
      "getAllByTaxiAssID",
      "delete",
      "edit",
      "add",
      "markAsdelete",
      "restoreDeleted",
      "getVehicleStatus"
    ]),
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
         Delete: (context, id, deletedby) => {
      context.$Notify.YesNo(false, { context, id, deletedby }).then(value => {
        if (value) {
          value.context.$wait.is("delete-" + value.id);
          
          value.context
            .markAsDeleted({
              router: value.context.$router,
              id: value.id,
              deletedby: value.deletedby
            })
            .then(() => {
              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Success("Successfully Deleted!", "");
            })
            .catch(error => {
              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    Restore: (context, id, restoredby) => {
      context.$Notify.YesNo(false, { context, id, restoredby }).then(value => {
        if (value) {
          value.context.$wait.is("restore-" + value.id);
          
          value.context
            .restoreDeleted({
              router: value.context.$router,
              id: value.id,
              restoredby: value.restoredby
            })
            .then(() => {
              value.context.$wait.end("restore-" + value.id);
              value.context.$Notify.Success("Successfully Deleted!", "");
            })
            .catch(error => {
              value.context.$wait.end("restore-" + value.id);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
   getbyid: (context, taxiassociationid) => {
      
      context.getAllByTaxiAssID({
            id: taxiassociationid,
            router: context.$router
        })
        .then(() => {
          App.init();
          TableManageCombine.init();
        });
    },
    
show:(context,increment) => {
  
   if(increment)

 context.skip += 30;

else
{
  if(context.skip > 0)
    context.skip -= 30;
}

      context.getVehicleStatus( {
          status: context.taxiassociationid,
          skip: context.skip,
          len: context.len,
          router: context.$router
        })
        .then(() => {
          App.init();
   });
},
//  getByTaxiAssId: context => {
//       context.$store
//         .dispatch("OperatorVehicle/getByTaxiAssId", {
//           _dataObj: {
//             router: context.$router,
//             id: context.taxiassociationid
//           }
//         })
//         .then(() => {
//           App.init();
//           TableManageCombine.init();
//           Highlight.init();
//         });
//     },
//     getall
    // getVehiclesByPaging: context => {
    //   context.$store
    //     .dispatch("OperatorVehicle/getByPaging", {
    //       _dataObj: {
    //         router: context.$router
    //         // id: this.context.taxiassociationid,
    //         // useid: this.context.USERPROFILE.system_usersid,
    //       }
    //     })
    //     .then(() => {
    //       App.init();
    //       TableManageCombine.init();
    //       Highlight.init();
    //     });
    // },

    setAdd(val) {
      
      this.$store
        .dispatch("OperatorVehicle/add", {
          _dataObj: val
        })
        .then(() => {});
    },
  

  
  },
   beforeMount: function() {
  this.show(this.context, true);
  },
  mounted: function() {
  //  this.getByTaxiAssId(this.context);
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

