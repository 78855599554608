<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
      v-on:init="init"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Dashboard'"
      :ActiveMenu="'Summary'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <transition name="bounce">
      <div id="content" class="content" v-show="USERPROFILE.taxi_associations_id">
        <!-- begin page-header -->
        <headerpagebreadcrumb
          pageheaderTitle="Summary Dashboard"
          pageheaderdescription
          breadcrumbitem1="Home"
          breadcrumbitem2="Dashboard"
          breadcrumbitem3
        />
        <!-- end page-header -->

        <!-- begin statsCards -->
        <div class="row">
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-blue">
                <i class="ion-ios-business"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">TAXI ASSOCIATIONS</div>
                <div class="stats-number">{{TTS.amount}}</div>
                <div class="stats-desc">{{TTS.name}}</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->

          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-blue">
                <i class="ion-ios-contacts"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">TAXI OPERATORS</div>
                <div class="stats-number">{{TTO.amount}}</div>
                <div class="stats-desc">{{TTO.name}}</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-blue">
                <i class="fas fa-taxi"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">TAXI VEHICLE</div>
                <div class="stats-number">{{TTV.amount}}</div>
                <div class="stats-desc">{{TTV.name}}</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-blue">
                <i class="ion-ios-man"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">TAXI DRIVERS</div>
                 <div class="stats-number">{{TTD.amount}}</div>
                <div class="stats-desc">{{TTD.name}}</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
        </div>
        <!-- end statsCards -->

        <!-- begin #Main Body Template -->

        <!-- begin row -->
        <div class="row hide">
          <!-- begin col-8 -->
          <div class="col-md-6 col-lg-3">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-7">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Taxi Association By TaxAffiation</h4>
              </div>
              <div class="panel-body">
                <div id="chart-TAT" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-8 -->
          <!-- begin col-4 -->
          <div class="col-md-6 col-lg-3">
            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Taxi Operators By TaxAffiation</h4>
              </div>
              <div class="panel-body">
                <div id="chart-TOT" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-4 -->
          <!-- begin col-8 -->
          <div class="col-md-6 col-lg-3">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="morris-chart-4">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Taxi Vehicle By TaxAffiation</h4>
              </div>
              <div class="panel-body">
                <div id="chart-TVT" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-8 -->
          <!-- begin col-4 -->
          <div class="col-md-6 col-lg-3">
            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Taxi Driver By TaxAffiation</h4>
              </div>
              <div class="panel-body">
                <div id="chart-TDT" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-4 -->
        </div>
        <!-- end row -->
        <!-- begin row -->
        <div class="row hide">
          <!-- begin col-8 -->
          <div class="col-lg-12">
            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">
                  Taxi Association By Province
                  <select
                    id="affiliateFilter1"
                    class="ml-2 btn btn-success btn-xs"
                    @change="ChangeFilterByTaxiAfficiation"
                    v-model="FilterByTaxiAfficiation"
                  >
                    <option value="0" disabled selected>Filter by Affiliate</option>
                    <option value="SNC">SANTACO</option>
                    <option value="NTA">NTA</option>
                  </select>
                </h4>
              </div>
              <div class="panel-body">
                <div id="nv-bar-chart" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-8 -->

          <!-- begin col-8 -->
          <div class="col-lg-12">
            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">
                  Taxi Operators By Province
                  <select
                    id="affiliateFilter1"
                    class="ml-2 btn btn-success btn-xs"
                    @change="ChangeTOPFilterByTaxiAfficiation"
                    v-model="FilterByTaxiAfficiation"
                  >
                    <option value="0" disabled selected>Filter by Affiliate</option>
                    <option value="SNC">SANTACO</option>
                    <option value="NTA">NTA</option>
                  </select>
                </h4>
              </div>
              <div class="panel-body">
                <div id="nv-bar-chartoperators" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-8 -->

          <!-- begin col-8 -->
          <div class="col-lg-12">
            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">
                  Taxis By Province
                  <select
                    id="affiliateFilter1"
                    class="ml-2 btn btn-success btn-xs"
                    @change="ChangeTVPFilterByTaxiAfficiation"
                    v-model="FilterByTaxiAfficiation"
                  >
                    <option value="0" disabled selected>Filter by Affiliate</option>
                    <option value="SNC">SANTACO</option>
                    <option value="NTA">NTA</option>
                  </select>
                </h4>
              </div>
              <div class="panel-body">
                <div id="nv-bar-charttaxisTVP" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-8 -->

          <!-- begin col-8 -->
          <div class="col-lg-12">
            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">
                  Drivers By Province
                  <select
                    id="affiliateFilter1"
                    class="ml-2 btn btn-success btn-xs"
                    @change="ChangeTDPFilterByTaxiAfficiation"
                    v-model="FilterByTaxiAfficiation"
                  >
                    <option value="0" disabled selected>Filter by Affiliate</option>
                    <option value="SNC">SANTACO</option>
                    <option value="NTA">NTA</option>
                  </select>
                </h4>
              </div>
              <div class="panel-body">
                <div id="nv-bar-chartdrivers" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-8 -->
        </div>
        <!-- end row -->

        <!-- begin #Main Body Template -->
      </div>
    </transition>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import statsCards from "@/components/charts/statsCards.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("sysadmindashboard");

export default {
  name: "sysadmin_dashboard",
  data: function() {
    return {
      message: "Some Message",
      FilterByTaxiAfficiation: 0,
      context: this,
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      GETUSERGROUPCODE: this.$store.getters["auth/USERPROFILE"].usergroups_code,
      TTS: {
        amount:0,
        name:'',
      },
      TTO: {
        amount:0,
        name:'',
      },
      TTD: {
        amount:0,
        name:'',
      },
      TTV: {
        amount:0,
        name:'',
      }
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  beforeMount() {},
  components: {
    headerpagebreadcrumb,
    statsCards,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["GETBYREPORTID", "GETBYREPORTIDS"])
  },
  methods: {
    ...mapActions(["getdashboard"]),
    init: function() {
      App.init();
      this.initcharts();
    },
    onResult(data) {
      console.log("The blob data:", data);
      console.log("Downloadable audio", window.URL.createObjectURL(data));
    },
    ChangeFilterByTaxiAfficiation: function(e) {
      this.filterTAPhandlePerProvince(
        this,
        this.FilterByTaxiAfficiation === "SNC" ? "NTA" : "SNC"
      );
    },
    ChangeTOPFilterByTaxiAfficiation: function(e) {
      this.filterTOPhandlePerProvince(
        this,
        this.FilterByTaxiAfficiation === "SNC" ? "NTA" : "SNC"
      );
    },
    ChangeTVPFilterByTaxiAfficiation: function(e) {
      this.filterTVPhandlePerProvince(
        this,
        this.FilterByTaxiAfficiation === "SNC" ? "NTA" : "SNC"
      );
    },
    ChangeTDPFilterByTaxiAfficiation: function(e) {
      this.filterTDPhandlePerProvince(
        this,
        this.FilterByTaxiAfficiation === "SNC" ? "NTA" : "SNC"
      );
    },
    filterTAPhandlePerProvince: function(context, taxiaffcode) {
      "use strict";
      // Bar graph 1 Taxi Association By RegionF

      const colors = [
        COLOR_RED,
        COLOR_ORANGE,
        COLOR_GREEN,
        COLOR_AQUA,
        COLOR_BLUE,
        COLOR_PURPLE,
        COLOR_GREY,
        COLOR_BLACK,
        COLOR_BLACK
      ];

      const barTAP = context
        .GETBYREPORTIDS("TAP")
        .filter(item => item.additional1 !== taxiaffcode)
        .map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals,
            color: colors[index]
          };
        });

      var barChartData = [
        {
          key: "Cumulative Return",
          values: barTAP
        }
      ];

      window.chartData
        .datum(barChartData)
        .transition()
        .duration(500)
        .call(window.barChart);
      nv.utils.windowResize(window.barChart.update);
    },
    filterTOPhandlePerProvince: function(context, taxiaffcode) {
      "use strict";
      // Bar graph 1 Taxi Association By RegionF

      const colors = [
        COLOR_RED,
        COLOR_ORANGE,
        COLOR_GREEN,
        COLOR_AQUA,
        COLOR_BLUE,
        COLOR_PURPLE,
        COLOR_GREY,
        COLOR_BLACK,
        COLOR_BLACK
      ];

      const barTAP = context
        .GETBYREPORTIDS("TOP")
        .filter(item => item.additional1 !== taxiaffcode)
        .map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals,
            color: colors[index]
          };
        });

      var barChartData = [
        {
          key: "Cumulative Return",
          values: barTAP
        }
      ];

      window.chartDataTOP
        .datum(barChartData)
        .transition()
        .duration(500)
        .call(window.barChartTOP);
      nv.utils.windowResize(window.barChartTOP.update);
    },
    ChangeTVPFilterByTaxiAfficiation: function(context, taxiaffcode) {
      "use strict";
      // Bar graph 1 Taxi Association By RegionF

      const colors = [
        COLOR_RED,
        COLOR_ORANGE,
        COLOR_GREEN,
        COLOR_AQUA,
        COLOR_BLUE,
        COLOR_PURPLE,
        COLOR_GREY,
        COLOR_BLACK,
        COLOR_BLACK
      ];

      const barTVP = context
        .GETBYREPORTIDS("TVP")
        .filter(item => item.additional1 !== taxiaffcode)
        .map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals,
            color: colors[index]
          };
        });

      var barChartData = [
        {
          key: "Cumulative Return",
          values: barTVP
        }
      ];

      window.chartDataTVP
        .datum(barChartData)
        .transition()
        .duration(500)
        .call(window.barChartTVP);
      nv.utils.windowResize(window.barChartTVP.update);
    },
    ChangeTDPFilterByTaxiAfficiation: function(context, taxiaffcode) {
      "use strict";
      // Bar graph 1 Taxi Association By RegionF

      const colors = [
        COLOR_RED,
        COLOR_ORANGE,
        COLOR_GREEN,
        COLOR_AQUA,
        COLOR_BLUE,
        COLOR_PURPLE,
        COLOR_GREY,
        COLOR_BLACK,
        COLOR_BLACK
      ];

      const barTDP = context
        .GETBYREPORTIDS("TDP")
        .filter(item => item.additional1 !== taxiaffcode)
        .map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals,
            color: colors[index]
          };
        });

      var barChartData = [
        {
          key: "Cumulative Return",
          values: barTDP
        }
      ];

      window.chartDataTDP
        .datum(barChartData)
        .transition()
        .duration(500)
        .call(window.barChartTDP);
      nv.utils.windowResize(window.barChartTDP.update);
    },
    initcharts: function() {
      // `this` points to the vm instance
      /*@@@@@@@@@@@@@@@@@@@@@@@@@@ PIE CHART START @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**/


      /*=============PIE CHART 3 Taxi Association by TaxiAffiliation=====================*/
      var handleTAT = function(context) {
        "use strict";
        //PIE CHART 1

        try {
          if (context) {
            const colors = [COLOR_PURPLE_DARKER, COLOR_PURPLE_LIGHTER];

            const donutData = context
              .GETBYREPORTIDS("TAT")
              .map((item, index) => {
                return {
                  label: item.additional2,
                  data: item._totals,
                  color: colors[index]
                };
              });

            //GETBYREPORTID

            $.plot("#chart-TAT", donutData, {
              series: {
                pie: {
                  innerRadius: 0.5,
                  show: true,
                  label: {
                    show: true
                  }
                }
              },
              legend: {
                show: true
              }
            });
          }
        } catch (e) {}
      };


    /*=============PIE CHART 3 Taxi Operator by TaxiAffiliation=====================*/
      var handleTOT = function(context) {
        "use strict";

        try {
          const colors = [COLOR_RED, COLOR_AQUA];

          const donutChartData = context
            .GETBYREPORTIDS("TOT")
            .map((item, index) => {
              return {
                label: item.additional2,
                value: item._totals,
                color: colors[index]
              };
            });
 // var donutChartData = [
          // { 'label': 'NTA', 'value' : 300, 'color': COLOR_RED },
          // { 'label': 'SANTACO', 'value' : 258, 'color': COLOR_AQUA }
          // ];
        
          nv.addGraph(function() {
            var chart = nv.models
              .pieChart()
              .x(function(d) {
                return d.label;
              })
              .y(function(d) {
                return d.value;
              })
              .showLabels(true)
              .labelThreshold(0.05)
              .labelType("percent")
              .donut(true)
              .donutRatio(0.35);

            d3.select("#chart-TOT")
              .append("svg")
              .datum(donutChartData)
              .transition()
              .duration(350)
              .call(chart);

            return chart;
          });
        } catch (e) {}
      };

      /*=============PIE CHART 3 Taxi Vehicle by TaxiAffiliation=====================*/
      var handleTVT = function(context) {
        

        try {
          const pieTVT = context.GETBYREPORTIDS("TVT").map((item, index) => {
            return {
              label: item.additional2,
              value: item._totals
            };
          });

            var arcRadius = [
                { inner: 0.65, outer: 0.93 },
                { inner: 0.6, outer: 1 }
              ];
          
          nv.addGraph(function() {
              var donutChartTVT = nv.models.pieChart()
                .x(function(d) { return d.label })
                .y(function(d) { return d.value })
                .margin({'left': 10,'right':  10,'top': 10,'bottom': 10})
                .showLegend(true)
                .donut(true) 
                .growOnHover(true)
                .arcsRadius(arcRadius)
                .donutRatio(0.5);
              
              donutChartTVT.labelFormat(d3.format(',.0f'));
              
              d3.select('#chart-TVT').append('svg')
                .datum(pieTVT)
                .transition().duration(3000)
                .call(donutChartTVT);
              
              return donutChartTVT;
          });

        } catch (e) {}
      };

      /*=============PIE CHART 3 Taxi Driver by TaxiAffiliation=====================*/
      var handleTDT = function(context) {

           try {
        
        const donutData = context.GETBYREPORTIDS("TDT").map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals
          };
        });

        Morris.Donut({
          element: "chart-TDT",
          data: donutData,
          formatter: function(y) {
            return y + "%";
          },
          resize: true,
          gridLineColor: [COLOR_GREY_LIGHTER],
          gridTextFamily: FONT_FAMILY,
          gridTextColor: FONT_COLOR,
          gridTextWeight: FONT_WEIGHT,
          gridTextSize: FONT_SIZE,
          colors: [COLOR_AQUA, COLOR_BLUE]
        });

        } catch (e) {
        
        }

      };

      var handlePerProvince = function(context) {
        "use strict";
        // Bar graph 1 Taxi Association By RegionF

        try
        {

        const colors = [
          COLOR_RED,
          COLOR_ORANGE,
          COLOR_GREEN,
          COLOR_AQUA,
          COLOR_BLUE,
          COLOR_PURPLE,
          COLOR_GREY,
          COLOR_BLACK,
          COLOR_BLACK
        ];

        /* Taxi Association By Province */

        const barTAP = context.GETBYREPORTIDS("TAP").map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals,
            color: colors[index]
          };
        });

        

        var barChartData = [
          {
            key: "Cumulative Return",
            values: barTAP
          }
        ];

        nv.addGraph(function() {
          window.barChart = nv.models
            .discreteBarChart()
            .x(function(d) {
              return d.label;
            })
            .y(function(d) {
              return d.value;
            })
            .showValues(false)
            .duration(250);

          barChart.yAxis.axisLabel("Taxi Associations");
          barChart.xAxis.axisLabel("Provinces");

          window.chartData = d3
            .select("#nv-bar-chart")
            .append("svg")
            .datum(barChartData)
            .call(window.barChart);
          nv.utils.windowResize(window.barChart.update);

          return barChart;
        });

        /* Taxi Operators By Province */

        const barTOP = context.GETBYREPORTIDS("TOP").map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals,
            color: colors[index]
          };
        });

        var barChartDataTOP = [
          {
            key: "Cumulative Return",
            values: barTOP
          }
        ];

        nv.addGraph(function() {
          window.barChartTOP = nv.models
            .discreteBarChart()
            .x(function(d) {
              return d.label;
            })
            .y(function(d) {
              return d.value;
            })
            .showValues(false)
            .duration(250);

          barChart.yAxis.axisLabel("Taxi Operators");
          barChart.xAxis.axisLabel("Provinces");

          window.chartDataTOP = d3
            .select("#nv-bar-chartoperators")
            .append("svg")
            .datum(barChartDataTOP)
            .call(window.barChartTOP);
          nv.utils.windowResize(window.barChartTOP.update);

          return barChartTOP;
        });

        /* Taxi Vehicles By Province */

        const barTVP = context.GETBYREPORTIDS("TVP").map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals,
            color: colors[index]
          };
        });

        var barChartDatabarTVP = [
          {
            key: "Cumulative Return",
            values: barTVP
          }
        ];

        nv.addGraph(function() {
          window.barChartTVP = nv.models
            .discreteBarChart()
            .x(function(d) {
              return d.label;
            })
            .y(function(d) {
              return d.value;
            })
            .showValues(false)
            .duration(250);

          barChart.yAxis.axisLabel("Taxis");
          barChart.xAxis.axisLabel("Provinces");

          window.chartDataTVP = d3
            .select("#nv-bar-charttaxisTVP")
            .append("svg")
            .datum(barChartDatabarTVP)
            .call(window.barChartTVP);
          nv.utils.windowResize(window.barChartTVP.update);

          return barChartTVP;
        });

        /* Taxi Drivers By Province */

        const barTDP = context.GETBYREPORTIDS("TDP").map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals,
            color: colors[index]
          };
        });


        nv.addGraph(function() {
          window.barChartTDP = nv.models
            .discreteBarChart()
            .x(function(d) {
              return d.label;
            })
            .y(function(d) {
              return d.value;
            })
            .showValues(false)
            .duration(250);

          barChart.yAxis.axisLabel("Drivers");
          barChart.xAxis.axisLabel("Provinces");

          window.chartDataTDP = d3
            .select("#nv-bar-chartdrivers")
            .append("svg")
            .datum([
                {
                  key: "Cumulative Return",
                  values: barTDP
                }
              ])
            .call(window.barChartTDP);
          nv.utils.windowResize(window.barChartTDP.update);

          return barChartTDP;
        });

       
          
        } catch (error) {
          
        }


      };

      /*@@@@@@@@@@@@@@@@@@@@@@@@@@ BAR CHART END @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**/
      COLOR_GREEN = "#5AC8FA";

      setTimeout(function() {
        //DashboardV2.init();

        const context = $(document).data("context");

        

        //Display Totals
        if(context.GETBYREPORTID('TTS'))
        {
          context.TTS.amount = context.GETBYREPORTID('TTS')._totals;
          context.TTS.name = context.GETBYREPORTID('TTS').report_name;
        }

        if(context.GETBYREPORTID('TTO'))
        {
          context.TTO.amount = context.GETBYREPORTID('TTO')._totals;
          context.TTO.name = context.GETBYREPORTID('TTO').report_name;
        }

        if(context.GETBYREPORTID('TTD'))
        {
          context.TTD.amount = context.GETBYREPORTID('TTD')._totals;
          context.TTD.name = context.GETBYREPORTID('TTD').report_name;
        }

        if(context.GETBYREPORTID('TTV'))
        {
          context.TTV.amount = context.GETBYREPORTID('TTV')._totals;
          context.TTV.name = context.GETBYREPORTID('TTV').report_name;
        }

        handleTAT(context);
        handleTOT(context);
        handleTVT(context);
        handleTDT(context);
        handlePerProvince(context);
        //handleMorrisBarChart();
        //handleMorrisDonusChart(context);
        
        //handleBarChart(context);
      }, 1000);
    }
  },
  mounted: function() {
    $(document).data("context", this.context);

    this.getdashboard({
      router: this.$router
    }).then(() => {
      App.init();
      this.initcharts();
    });

    document.body.insertBefore(this.$el, document.body.firstChild);
  }
};
</script>

<style>
@import "/assets/plugins/nvd3/build/nv.d3.css";
</style>