<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageVehicle'"
      :ActiveMenu="'Taxi Vehicle Impounds'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Taxi Vehicle"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Vehicles"
        breadcrumbitem3="Manage Impounds"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">All Vehicles</h4>
            </div>
            <!-- end panel-heading -->

            <!-- <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <a
                  data-toggle="modal"
                  data-target="#AddModal"
                  class="btn btn-white"
                  href="javascript:;"
                
                >
                  <i class="fa fa-plus"></i>
                  
                <router-link to="/Vehicle/Add">Add Taxi Vehicle</router-link>
             
                </a>
              </div>
            </div>-->
            <br />
            <br />

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-combine-collectorweekly" class="table table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Model/Make</th>
                    <th class="text-nowrap">Licence Plate</th>
                    <th class="text-nowrap">Operator Name</th>
                    <th class="text-nowrap">Assigned Drive</th>
                    <th class="text-nowrap">Balance</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Last Updated By</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETVEHICLEBYASSID"
                    v-bind:key="item._taxi_operator_vehicles_id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item._vehicle_model+' '+item._vehicle_make}}</td>
                    <td>
                      <router-link
                        :to="{ name: 'taxivehicleprofile', params: { id: item._taxi_operator_vehicles_id }}"
                        class="btn btn-link width-60 m-r-2"
                      >{{item._vehicle_register_number}}</router-link>
                    </td>
                    <td>{{item._taxi_operators_name}}</td>
                    <td>{{item._taxidrivers_name}}</td>
                    <td>{{item._available_balance | newcurrencywithSymbol}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-success': item._vehiclestatus_name == 'Active' }"
                        v-if="item._vehiclestatus_name == 'Active'"
                      >{{item._vehiclestatus_name}}</span>
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._vehiclestatus_name == 'Impounded' }"
                        v-if="item._vehiclestatus_name == 'Impounded'"
                      >{{item._vehiclestatus_name}}</span>
                    </td>
                    <td>{{item._updateby_name || item._addedby_name}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-80"
                        :disabled="$wait.is('impound-' + item._id)"
                        v-if="item._vehiclestatus_name == 'Active'"
                        @click="impoundDialog(context,item)"
                      >
                        <v-wait v-bind:for="'impound-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Impound
                        </v-wait>
                      </button>

                      <button
                        type="button"
                        class="btn btn-sm btn-success width-80"
                        :disabled="$wait.is('release-' + item._id)"
                        v-if="item._vehiclestatus_name == 'Impounded'"
                        @click="releaseDialog(context,item)"
                      >
                        <v-wait v-bind:for="'release-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Release
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
            <!-- #modal-message start -->
            <div class="modal fade" id="modal-dialog">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="text-center">
                    <h4 class="modal-title">Impound Vehicle</h4>
                  </div>
                  <div class="modal-body">
                    <h4 class="modal-title">Add Pictures Or Voice Notes</h4>
                    <br />
                    <div class="height-450">
                      <!-- begin nav-pills -->
                      <ul class="nav nav-pills">
                        <li class="nav-items">
                          <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active">
                            <span class="d-sm-none">Pills 1</span>
                            <span class="d-sm-block d-none">Media</span>
                          </a>
                        </li>
                        <li class="nav-items">
                          <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
                            <span class="d-sm-none">Pills 2</span>
                            <span class="d-sm-block d-none">Voicenotes</span>
                          </a>
                        </li>
                      </ul>
                      <!-- end nav-pills -->
                      <!-- begin tab-content -->
                      <div class="tab-content">
                        <!-- begin tab-pane -->
                        <div class="tab-pane fade active show" id="nav-pills-tab-1">
                          <h3 class="m-t-10">Attach Pictures</h3>
                          <DropZoneControl
                            :acceptedFiles="acceptedFiles"
                            :ClearData="true"
                            v-on:fileadded="fileadded"
                            :SessionID="IMPOUNDVEHICLE.session_id"
                          ></DropZoneControl>
                        </div>
                        <!-- end tab-pane -->
                        <!-- begin tab-pane -->
                        <div class="tab-pane fade" id="nav-pills-tab-2">
                          <AudioVideoControl
                            :SessionID="IMPOUNDVEHICLE.session_id"
                            :ClearData="true"
                            v-on:fileadded="fileadded2"
                          />
                        </div>
                        <!-- end tab-pane -->
                      </div>
                      <!-- end tab-content -->
                    </div>
                  </div>
                  <div class="text-center m-b-5">
                    <a
                      href="javascript:;"
                      class="btn btn-danger m-5 btn-lg"
                      data-dismiss="modal"
                    >Close</a>
                    <a
                      href="javascript:;"
                      class="btn btn-primary m-5 btn-lg"
                      data-dismiss="modal"
                      @click="impound(context, IMPOUNDVEHICLE)"
                    >Impound</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- #modal-message end -->

            
          </div>
          <!-- end panel -->
          <!-- #modal-message start -->
            <div class="modal fade" id="release-dialog">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="text-center">
                    <h4 class="modal-title">Release Vehicle</h4>
                  </div>
                  <div class="modal-body">
                    <h4 class="modal-title">Add Pictures Or Voice Notes</h4>
                    <br />
                    <div class="height-450">
                      <!-- begin nav-pills -->
                      <ul class="nav nav-pills">
                        <li class="nav-items">
                          <a href="#nav-pills-tab-3" data-toggle="tab" class="nav-link active">
                            <span class="d-sm-none">Pills 1</span>
                            <span class="d-sm-block d-none">Media</span>
                          </a>
                        </li>
                        <li class="nav-items">
                          <a href="#nav-pills-tab-4" data-toggle="tab" class="nav-link">
                            <span class="d-sm-none">Pills 2</span>
                            <span class="d-sm-block d-none">Voicenotes</span>
                          </a>
                        </li>
                      </ul>
                      <!-- end nav-pills -->
                      <!-- begin tab-content -->
                      <div class="tab-content">
                        <!-- begin tab-pane -->
                        <div class="tab-pane fade active show" id="nav-pills-tab-3">
                          <h3 class="m-t-10">Attach Pictures</h3>
                          <DropZoneControl2
                            :acceptedFiles="acceptedFiles"
                            :ClearData="true"
                            v-on:fileadded="fileadded3"
                            :SessionID="RELEASEVEHICLE.session_id"
                          ></DropZoneControl2>
                        </div>
                        <!-- end tab-pane -->
                        <!-- begin tab-pane -->
                        <div class="tab-pane fade" id="nav-pills-tab-4">
                          <AudioVideoControl
                            :SessionID="RELEASEVEHICLE.session_id"
                            :ClearData="true"
                            v-on:fileadded="fileadded4"
                          />
                        </div>
                        <!-- end tab-pane -->
                      </div>
                      <!-- end tab-content -->
                    </div>
                  </div>
                  <div class="text-center m-b-5">
                    <a
                      href="javascript:;"
                      class="btn btn-danger m-5 btn-lg"
                      data-dismiss="modal"
                    >Close</a>
                    <a
                      href="javascript:;"
                      class="btn btn-primary m-5 btn-lg"
                      data-dismiss="modal"
                      @click="submitrelease(context, RELEASEVEHICLE)"
                    >Release</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- #modal-message end -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import AudioVideoControl from "@/components/common/AudioVideoControl.vue";
import { default as DropZoneControl, default as DropZoneControl2 } from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("OperatorVehicle");

export default {
  name: "ManageVehicles",
  data: function() {
    return {
      acceptedFiles: "image/*",
      arrearsoperatorsloaded: false,
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      context: this,
      addedsuccess: undefined,
      voicenotesSessionID: this.$uuid.v4(),
      errormessage: undefined,
      id: this.$route.query.id,
      GETVEHICLEBYASSID: [],
      IMPOUNDVEHICLE: {
        taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        taxi_vehicle_id: null,
        impounded_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
        session_id: this.$uuid.v4(),
        pictures_id: null,
        voicenotes_id: this.$uuid.v4(),
        geolocation: null,
        added_by: this.$store.getters["auth/USERPROFILE"].system_usersid
      },
      RELEASEVEHICLE: {
        taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
        taxi_vehicle_id: null,
        released_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
        session_id: this.$uuid.v4(),
        pictures_id: this.$uuid.v4(),
        voicenotes_id: this.$uuid.v4(),
        geolocation: null,
        added_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      GETUPLOADEDFILES: {},
      GETUPLOADEDVOICENOTES: {}
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    DropZoneControl2,
    AudioVideoControl
  },
  computed: {
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "getByPaging",
      "getAllByTaxiAssID",
      "delete",
      "edit",
      "add",
      "markAsdelete",
      "restoreDeleted",
      "releaseVehicle",
      "impoundVehicles"
    ]),

    impoundDialog: (context, item) => {
      console.log("This is the vehicle: ", item);
      context.IMPOUNDVEHICLE.taxi_vehicle_id = item._taxi_operator_vehicles_id;
      $("#modal-dialog").modal("show");
    },

    impound: (context, payload) => {
      debugger
      context.$Notify.YesNo(false, { context, payload }).then(value => {
        if (value) {

          debugger

          const dropzone_uploads = context.$store.getters["fileupload/GETALL"];
          

          if (dropzone_uploads.length > 0) {
            dropzone_uploads.forEach(element => {
              if(element.mimetype === 'image/jpeg'){
                value.payload.pictures_id =  element.photoid;
              }
            });
          }

          const voicenote_uploads = context.GETUPLOADEDVOICENOTES;
          if (voicenote_uploads.length > 0) {
            voicenote_uploads.forEach(element => {
              value.payload.voicenotes_id = element.fileid;
            });
          }

          value.context
            .impoundVehicles({
              router: value.context.$router,
              addModel: payload
            })
            .then(() => {
              value.context.$Notify.Success("Successfully Impounded!", "");
              value.context.getbyid(value.context, value.context.taxiassociationid);
            })
            .catch(error => {
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },

    submitrelease: (context, payload) => {

      debugger;
      
      context.$Notify.YesNo(false, { context, payload }).then(value => {
        if (value) {
          
          const dropzone_uploads = context.$store.getters["fileupload/GETALL"];
          

          if (dropzone_uploads.length > 0) {
            dropzone_uploads.forEach(element => {
              if(element.mimetype === 'image/jpeg'){
                value.payload.pictures_id =  element.photoid;
              }
            });
          }

          const voicenote_uploads = context.GETUPLOADEDVOICENOTES;
          if (voicenote_uploads.length > 0) {
            voicenote_uploads.forEach(element => {
              value.payload.voicenotes_id = element.fileid;
            });
          }

          debugger

          value.context
            .releaseVehicle({
              router: value.context.$router,
              addModel: payload
            })
            .then(() => {
              value.context.$Notify.Success("Successfully RELEASED!", "");
              value.context.getbyid(value.context, value.context.taxiassociationid);
            })
            .catch(error => {
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },

    releaseDialog: (context, item) => {
      debugger
      console.log("This is the vehicle: ", item);
      context.RELEASEVEHICLE.taxi_vehicle_id = item._taxi_operator_vehicles_id;
      $("#release-dialog").modal("show");
    },

    fileadded: function(data) {
      console.log("Header:fileadded");
      console.log('dropzone files: ',data);
      //this.$emit('init')

      this.GETUPLOADEDFILES = data;
    },

    fileadded2: function(data) {
      console.log("Header:fileadded");
      console.log('voicenote files: ',data);
      //this.$emit('init')

      this.GETUPLOADEDVOICENOTES = data;
      console.log("binding Array: ", this.GETUPLOADEDVOICENOTES);
    },

    fileadded3: function(data) {
      console.log("Header:fileadded");
      console.log('dropzone files: ',data);
      //this.$emit('init')
    },

    fileadded4: function(data) {
      console.log("Header:fileadded");
      console.log('voicenote files: ',data);
      //this.$emit('init')
    },

    Delete: (context, id, deletedby) => {
      context.$Notify.YesNo(false, { context, id, deletedby }).then(value => {
        if (value) {
          value.context.$wait.is("delete-" + value.id);

          value.context
            .markAsDeleted({
              router: value.context.$router,
              id: value.id,
              deletedby: value.deletedby
            })
            .then(() => {
              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Success("Successfully Deleted!", "");
            })
            .catch(error => {
              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    Restore: (context, id, restoredby) => {
      context.$Notify.YesNo(false, { context, id, restoredby }).then(value => {
        if (value) {
          value.context.$wait.is("restore-" + value.id);

          value.context
            .restoreDeleted({
              router: value.context.$router,
              id: value.id,
              restoredby: value.restoredby
            })
            .then(() => {
              value.context.$wait.end("restore-" + value.id);
              value.context.$Notify.Success("Successfully Deleted!", "");
            })
            .catch(error => {
              value.context.$wait.end("restore-" + value.id);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    getbyid: (context, taxiassociationid) => {
      context
        .getAllByTaxiAssID({
          id: taxiassociationid,
          router: context.$router
        })
        .then(data => {
          console.log("These are the vehicles:(vue page) ", data);
         
          context.GETVEHICLEBYASSID = data;
   
                  var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };

                  setTimeout(() => {
                    if(!context.arrearsoperatorsloaded)
                  $("#data-table-combine-collectorweekly").DataTable(options); 
                  context.arrearsoperatorsloaded = true;
                  }, 1000);


          // TableManageCombine.init();
        })
        .catch(error => {
          console.log("vehicles error: ", error);
        });
    },
    //  getByTaxiAssId: context => {
    //       context.$store
    //         .dispatch("OperatorVehicle/getByTaxiAssId", {
    //           _dataObj: {
    //             router: context.$router,
    //             id: context.taxiassociationid
    //           }
    //         })
    //         .then(() => {
    //           App.init();
    //           TableManageCombine.init();
    //           Highlight.init();
    //         });
    //     },
    //     getall
    // getVehiclesByPaging: context => {
    //   context.$store
    //     .dispatch("OperatorVehicle/getByPaging", {
    //       _dataObj: {
    //         router: context.$router
    //         // id: this.context.taxiassociationid,
    //         // useid: this.context.USERPROFILE.system_usersid,
    //       }
    //     })
    //     .then(() => {
    //       App.init();
    //       TableManageCombine.init();
    //       Highlight.init();
    //     });
    // },

    setAdd(val) {
      this.$store
        .dispatch("OperatorVehicle/add", {
          _dataObj: val
        })
        .then(() => {});
    }
  },
  beforeMount: function() {
    this.getbyid(this.context, this.taxiassociationid);
  },
  mounted: function() {
    //  this.getByTaxiAssId(this.context);

    App.init();
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

