/* eslint-disable no-unused-vars */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import * as modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  plugins: [
 createPersistedState(), // https://www.npmjs.com/package/vuex-persistedstate
  //createCache(), // https://github.com/superwf/vuex-cache
  ],
  modules,
}); 

