<template>
  <div class="home">
    <!-- begin #page-container -->
     <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->

     <!-- begin #sidebar -->
      <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Dashboard'"
      :ActiveMenu="'Search'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
       <headerpagebreadcrumb
        pageheaderTitle="Driver"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Driver"
        breadcrumbitem3="Photos"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
      <div class="panel panel-inverse" data-sortable-id="form-dropzone-1">
                      <div class="panel-heading">
                        <div class="panel-heading-btn"></div>
                        <h4 class="panel-title">Drop Files In Here</h4>
                      </div>
                      <div class="panel-body text-inverse">
                       <CapturePhotosControl
                    :SessionID="CapturedPhotosSessionID"
                    :ShowStopStartWebCamControl="false"
                    :CameraWidth="'40%'"
                    :ShowPreviewControl="true"
                    :CameraHeight="'40%'"
                    :ClearData="true"
                  />
                    <!-- begin table-responsive -->
                        <div class="table-responsive">
                          <table class="table table-striped m-b-0">
                            <thead>
                              <tr>
                                <th></th>
                                <th>Filename</th>
                                <th>Format</th>
                                <th>Size</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              
                              <tr :key="item" v-for="item in GETTAXIDRIVEREDIT._images">
                                <td width="1%" class="with-img"><img :src="item.data" class="img-rounded height-30" /></td>
                                <td>{{item.filename}}</td>
                                <td>{{item.mimetype}}</td>
                                <td>{{item.length}}</td>
                                <td class="with-btn" nowrap>
                                  <a
                                    href="#"
                                    @click="deletefile(context,item.fileId, USERPROFILE.system_usersid)"
                                    class="btn btn-sm btn-danger width-60"
                                  >Delete</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end table-responsive -->
              
                      </div>
                    </div>
                    <!--End dropzone!-->

                    <br />
                  
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller/>
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import headerComp from "@/components/common/header.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import pageloader from "@/components/common/pageloader.vue";
import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";

import { createNamespacedHelpers } from "vuex";
import { setTimeout } from "timers";
const { mapGetters, mapActions } = createNamespacedHelpers("TaxiDrivers");

export default {
  name: "ManageDrivers",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      addedsuccess: undefined,
      errormessage: undefined,
      skip: 0,
      len: 10000,
      id: this.$route.params.id,
      CapturedPhotosSessionID: this.$uuid.v4(),
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      GETSELECTED: Object.assign({}, this.$store.getters["title/GETALL"])[0],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      GETDRIVERSBYASSID: this.$store.getters["TaxiDrivers/GETDRIVERSBYASSID"],
      accountid: this.$store.getters["TaxiDrivers/GETDRIVERACCOUNT"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
    };
  },
  beforeMount: function() {
    this.editDriver(this.id);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    CapturePhotosControl
  },
  computed: {
    ...mapGetters(["GETTAXIDRIVEREDIT"]),
    LoggedInUserFullName: function ()
    {
      return this.USERPROFILE.first_name + ' ' + this.USERPROFILE.surname
    },
  },
  methods: {
    ...mapActions(["getall", "delete", "edit", "add","markAsDeleted","editDriver"]),
    getbyid: (context, taxiassociationid) => {
      
      context.getAllByTaxiAssID({
            id: taxiassociationid,
            skip: context.skip,
            len: context.len,
            router: context.$router
        })
        .then(() => {
          App.init();
          TableManageCombine.init();
        });
    },
    deletedriver: (context, item, deletedby) => {
      context.$Notify.YesNo(false,{ context, id: item._taxi_drivers_id, deletedby })
      .then(value => {
        if (value) {
            
          value.context.$wait.start("delete-" + value.context.id);
          value.router = value.context.$router;
           value.context.markAsDeleted({
             router: value.context.$router,
              id: value.id, 
              deletedby: value.deletedby
            }).then(() => {   
              value.context.$wait.end("delete-" + id);
              value.context.$Notify.Success("Successfully Deleted!" + context.id)
            }).catch(error => {
              value.context.$Notify.Error(error.data[0].message, '');
            })
            .finally(() => {
            });
        }
      });
    },
  },
  mounted: function() {
    document.title = 'Drivers';
    App.init();
  }
};
</script>

<style scoped>

</style>

