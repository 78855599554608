/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';


export default {
  namespaced: true,
  state: {
    Operators: [
      {
        taxi_operator_id: 1,
        fk_title_id: 1,
        title_name: 'Mr',
        firstname: 'John',
        surname: 'Ghiotto',
        id_number: '3543209345',
        date_of_birth: '2018-10-05',
        passport_number: '1544066015',
        membership_number: '22',
        physicaladdress_id: 2,
        postaladdress: '2 Coleman Court',
        postaladdress2: '8 Pawling Point',
        postal_suburb: 'Half Way House',
        postal_city: 'Midrand',
        postal_province: 'Gauteng',
        postal_country: 'South Africa',
        postaladdress_id: 281,
        physicaladdress: '0 Emmet Circle',
        physicaladdress2: '6 Pearson Plaza',
        _physical_suburb: 'Half Way House',
        physical_city: 'Midrand',
        physical_province: 'Gauteng',
        physical_country: 'South Africa',
        country_id: 1,
        country_name: 'South Africa',
        telephone_number: '581-454-4871',
        fax_number: '961-470-0065',
        email_address: 'jghiotto1@usa.gov',
        taxi_operators_status_id: 3,
        taxi_operators_status: 'Active',
        taxi_associations_with_taxi_operators_id: 1,
        approvedby: 2,
        approvedby_name: 'System Administrator',
        approved_date: '2019-08-22T17:28:31.819907',
        rejected_by: null,
        rejectedby_name: null,
        rejected_date: null,
        taxi_ass_name: 'Internal Taxi Association',
        taxi_ass_abbr: 'Internal Taxi Association',
        added_by: 1,
        addedby_name: 'Internal Sys Admin',
        date_added: '2019-08-22T17:24:05.96424',
        updated_by: 2,
        updateby_name: 'System Administrator',
        date_updated: '2019-08-22T17:28:31.819907',
      },
    ],
    Drivers: [],
    Taxis: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETOPERATORS: state => state.Operators,
    GETDRIVERS: state => state.Drivers,
    GETTAXIS: state => state.Vehicles,
  },
  mutations: {
    LOADEDOPERATORS: (state, _data) => {
      if (_data !== undefined) {
        state.Operators = _data;
      }
    },
    LOADEDDRIVERS: (state, _data) => {
      if (_data !== undefined) {
        state.Drivers = _data;
      }
    },
    LOADEDTAXIS: (state, _data) => {
      if (_data !== undefined) {
        state.Vehicles = _data;
      }
    },
  },
  actions: {
    /* Async */

    /* Mutations */
    searchOperator({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const { id, router } = _dataObj;

        

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SEARCHBYOPERATOR,
        };
        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('LOADEDOPERATORS', parsedResult.data[0].fn_entity_getbysearch.taxi_operators);
            resolve(parsedResult);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
    searchTaxi({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const { id, router } = _dataObj;

        

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SEARCHBYTAXI,
        };
        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('LOADEDTAXIS', parsedResult.data[0].fn_entity_getbysearch.taxi_operators);
            resolve(parsedResult);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
  },
};