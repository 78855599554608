<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'OnlineServices'"
      :ActiveMenu="'RenewLicenseDisk'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Renew License Disk - ONLY AVAILABLE IN GAUTENG AND LIMPOPO"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Vehicle License Disk"
        breadcrumbitem3="Renew"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

           <!-- begin nav-pills -->
      <ul class="nav nav-pills">
        <li class="nav-items">
          <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active nav-pills-tab-1">
            <span class="d-sm-none">Renew</span>
            <span class="d-sm-block d-none">Renew</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">Orders</span>
            <span class="d-sm-block d-none">Orders</span>
          </a>
        </li>
      </ul>
      <!-- end nav-pills -->

        <!-- begin tab-content -->
        <div class="tab-content">
          
      <div class="tab-pane fade active show" id="nav-pills-tab-1">
   
      <div class="card text-center">
        <div v-if="isVehicleExists" class="alert alert-success fade show">CAPTURED EXPIRY DATE: {{ VehicleExistsObject.ExpireDate }}</div>
      </div>

        <div class="row">
          <h3 class="text-danger">NB: PLEASE TAKE YOUR CAR TO ROADWORTHY TEST FIRST</h3>
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Renew
                      <small class="text-ellipsis">Renew License Disk</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-2">
                    <span class="number">2</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Complete Registration</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->
                    <div class="row">

                      <div class="col-md-12">
                      <div class="row panel panel-inverse">
                        <!-- begin col-8 -->
                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                             Select Vehicle
                              <span class="text-danger"><i v-show="GETVEHICLEBYASSID.length===0" class="fas fa-spinner fa-spin"></i></span>
                            </label>
                            <div class="col-md-6">
                              <select
                                id="SelectVehicle"
                                name="SelectVehicle"
                                class="form-control"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                              >
                                <option disabled value>Select Vehicle</option>
                                <option
                                  v-for="item in GETVEHICLEBYASSID"
                                  v-bind:key="item._taxi_operator_vehicles_id"
                                  :value="item._taxi_operator_vehicles_id"
                                >{{item._licence_plate}}({{item._taxi_operators_name}})</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                       
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Vehicle Register Number
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="e.g. DLC200X"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="SUBMITQUOTE.renewRegNumber"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Licence Plate:
                              <span class="text-danger">*<i v-show="isVehicleExistsLoading" class="fas fa-spinner fa-spin"></i></span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                id="vin_number"
                                name="vin_number"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                placeholder="Enter Licence Plate"
                                :class="['form-control',{'is-invalid': !isVehicleExists, 'is-valid': isVehicleExists}]"
                                v-model.lazy="renewLicencePlate"
                              />
                            <div class="invalid-tooltip">License Plate Not Captured Before</div>
                            <div class="valid-tooltip">License Plate Captured!</div>
                          </div>
                          </div>
                          <br/>
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Make And Model
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                id="telephone"
                                name="telephone"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                placeholder="Enter Make And Model"
                                v-model="SUBMITQUOTE.renewMake"
                              />
                            </div>
                          </div>
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Tara
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                id="TaxiAssociationMobileNumber"
                                type="number"
                                name="TaxiAssociationMobileNumber"
                                data-parsley-group="step-1"
                                max="9999"
                                data-parsley-required="true"
                                class="form-control"
                                placeholder="Enter Tara"
                                v-model="SUBMITQUOTE.renewTara"
                              />
                            </div>
                          </div>
                           <!-- begin form-group row -->
                           <div class="form-group row m-b-10">
                            <label class="col-md-4 text-md-right col-form-label">Licence Expiry Date
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <div
                                class="input-group date LicenceExpiryDate"
                                id="LicenceExpiryDate"
                                data-date-format="yyyy-mm"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Select Date"
                                  data-parsley-group="step-1"
                                  data-parsley-required="false"
                                  
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                        <!-- end col-8 -->

                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Owner ID Number
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                name="MaxNumberOfOperators"
                                placeholder="Enter Owner ID Number"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="SUBMITQUOTE.renewOwnId"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Owner Name & Surname
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="MaxNumberOfTaxisOperators"
                                placeholder="Enter Owner Name & Surname"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="SUBMITQUOTE.renewOwnName"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                            <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Mobile Number
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                name="MaxNumberOfOperators"
                                placeholder="Enter Number Or Leave Empty"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="SUBMITQUOTE.renewOwnNumber"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Delivery Address
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="MaxNumberOfTaxisOperators"
                                placeholder="Enter Number Or Leave Empty"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="SUBMITQUOTE.DeliveryAddress"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <br/>
                           <!-- begin form-group -->
                           <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              
                              <span class="text-danger"></span>
                            </label>
                            <div class="col-md-6">
                              <a
                                href="javascript:;"
                                v-show="IsGetQuoteReady"
                                :disabled="$wait.is('GetQuote')"
                                @click="GetQuote(context)"
                                :class="{'btn btn-lg btn-success m-r-20 m-b-10 p-l-5': !$wait.is('GetQuote'),
                              'btn btn-lg btn-grey m-b-10 p-l-5': $wait.is('GetQuote')
                              }"
                              >
                                <i
                                  class="fa fa-registered t-plus-1 text-white fa-fw fa-lg"
                                  v-show="!$wait.is('GetQuote')"
                                ></i>

                                <i
                                  class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
                                  v-show="$wait.is('GetQuote')"
                                ></i>

                                <span>Get Quote</span>
                                </a>
                                <a
                                href="javascript:;"
                                v-show="IsCanAddVehicle"
                                :disabled="$wait.is('AddVehicle')"
                                @click="AddVehicle(context)"
                                :class="{'btn btn-lg btn-success m-r-20 m-b-10 p-l-5': !$wait.is('AddVehicle'),
                              'btn btn-lg btn-grey m-b-10 p-l-5': $wait.is('AddVehicle')
                              }"
                              >
                                <i
                                  class="fa fa-car t-plus-1 text-white fa-fw fa-lg"
                                  v-show="!$wait.is('AddVehicle')"
                                ></i>

                                <i
                                  class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
                                  v-show="$wait.is('AddVehicle')"
                                ></i>

                                <span>Add Vehicle</span>
                                </a>
                            </div>
                          </div>
                          <!-- end form-group -->
                         
                        </div>
                       

                        <!-- begin col-12 -->
                        <div class="col-lg-12">
                          <!-- begin panel -->
                          <div class="panel panel-primary">
                            <!-- begin panel-heading -->
                            <div class="panel-heading">
                              <h4 class="panel-title">Quotation</h4>
                            </div>
                            <!-- end panel-heading -->

                            <div class="alert alert-danger fade hide addonserviceerror">
                              <span class="close" data-dismiss="alert">×</span>
                              <i class="fa fa-exclamation-triangle fa-2x pull-left m-r-10"></i>
                              <p class="m-b-0">Please choose at least one Add On Service</p>
                            </div>

                            <div class="panel-toolbar">
                              <div class="btn-group m-r-5">
                                <span>All Prices Include Vat</span>
                              </div>
                            </div>

                            <!-- begin panel-body -->
                            <div class="panel-body">
                              <table
                                id="data-table-addonservice"
                                class="table table-striped data-table table-bordered"
                              >
                                <thead>
                                  <tr>
                                    <th width="1%" data-orderable="false"></th>
                                    <th class="text-nowrap">License Fees</th>
                                    <th class="text-nowrap">Admin Fees</th>
                                    <th class="text-nowrap">Delivery Fees</th>
                                    <th width="15%">Total</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr
                                    v-bind:key="item._id"
                                    v-for="(item,index) in RENEWALQUOTE"
                                    :class="{'success': item.TaxiAssociationId}"
                                  >
                                    <td class="f-s-600 text-inverse">{{++index}}</td>
                                    <td>{{item.LicenseFees | currencywithSymbol}}</td>
                                    <td>{{parseFloat(item.AdminFees) + (item.TAMSAdminFees/2) | currencywithSymbol}}</td>
                                    <td>{{parseFloat(item.DeliveryFee) + (item.TAMSAdminFees/2)| currencywithSymbol}}</td>
                                    <td>{{parseFloat(item.Total) + item.TAMSAdminFees | currencywithSymbol}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <!-- end panel-body -->
                          </div>
                          <!-- end panel -->
                        </div>
                        <!-- end col-12 -->
                      </div>
                    </div>

                 
                    </div>
                    <!-- end row -->
                  </fieldset>
                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->
              

                <!-- begin step-3 -->
                <div id="step-2">
                  <div class="jumbotron m-b-0 text-center">
                    <h2 class="text-inverse">Successfully</h2>
                    <h4 class="text-inverse">Order Reference: {{SUBMITTEDORDER.OrderNumber}}</h4>
                    <h4 class="text-inverse">Total Price: {{parseFloat(SUBMITTEDORDER.Total) + SUBMITTEDORDER.TAMSAdminFees | currencywithSymbol}}</h4>
                    <p class="m-b-30 f-s-16 ">
                      <a :href="SUBMITTEDORDER.OrderNumberPDF" target="_blank" class="btn btn-success btn-lg">View Invoice</a>
                    </p>
                    <p>
                    </p>
                  </div>
                </div>
                <!-- end step-3 -->
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>
        <!-- end col-12 -->
      </div>
      </div>

      <!-- begin row -->

       <!-- begin tab-pane -->
       <div class="tab-pane fade" id="nav-pills-tab-2">
        <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <div class="table-responsive">
            <table class="table table-invoice">
              <thead>
                <tr>
                  <th>Owner Full Name</th>
                  <th>Owner Mobile No</th>
                  <th>License Plate</th>
                  <th>License Fee</th>
                  <th>Admin Fees</th>
                  <th>Delivery Fee</th>
                  <th>Total</th>
                  <th>Applcation Date</th>
                  <th>Date Delivered</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item) in GETORDERSBYASSID"
                  v-bind:key="item.id"
                >
                  <td>{{item.renewownname}}</td>
                  <td>{{item.renewownnumber}}</td>
                  <td>{{item.licenseplateno}}</td>
                  <td>{{item.licensefees | newcurrencywithSymbol}}</td>
                  <td>{{item.adminfees + (item.tamsadminfees/2) | newcurrencywithSymbol}}</td>
                  <td>{{item.deliveryfee + (item.tamsadminfees/2) | newcurrencywithSymbol}}</td>
                  <td>{{item.total + item.tamsadminfees | newcurrencywithSymbol}}</td>
                  <td>{{item.date_added}}</td>
                  <td>{{item.delivered_date}}</td>
                  <td class="with-btn valign-middle" nowrap>
                      <div class="btn-group" role="group" aria-label="Basic example">
                      <button v-if="!item.is_delivered" type="button" @click="MarkAsDelivered(context,item.id)" class="btn btn-success"
                        :disabled="$wait.is('MarkAsDelivered-' +item.id)">
                 <v-wait v-bind:for="'MarkAsDelivered-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                   Mark As Delivered
                  </v-wait>
                        
                    </button> 
                  </div>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
        </div>

      </div>
        <!-- end tab-pane -->

      

      <!-- end row -->

  

      <!-- begin #Main Body Template -->

      

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("fuelmanager_dashboard");

export default {
  name: "AddSystemUser",
  data: function() {
    return {
      GETVEHICLEBYASSID:[],
      RENEWALQUOTE:[],
      SUBMITTEDORDER:{},
      SUBMITQUOTE: {
        TaxiAssociationId:this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
        renewExpireDate: "",
        renewOwnId: "",
        renewOwnName: "",
        renewOwnNumber: "",
        renewOwnNumber2: "0826869834",
        renewEmail: "licensediskrenewal@sharedmobility.africa",
        DeliveryAddress: this.$store.getters["auth/SELECTEDTAXIASSOCIATION"]._physicaladdress + "," + this.$store.getters["auth/SELECTEDTAXIASSOCIATION"]._physicaladdress2 + "," + this.$store.getters["auth/SELECTEDTAXIASSOCIATION"]._province_name,
        renewRegNumber: "",
        renewLicencePlate: "",
        renewMake: "",
        renewTara: "",
        AddedBy: this.$store.getters["auth/USERPROFILE"].system_usersid,
    },
    GETORDERSBYASSID:[],
    RegNoExists:undefined,
    isVehicleExistsLoading:false,
    renewLicencePlate:"",
    isVehicleExists: undefined,
    VehicleExistsObject: [],
      TAXIAFFILIATION:[],
      GETALLSUBURBS:[],
      BILLINGTYPE:[],
      GETBILLINGTYPES:[],
      BANKS:[],
      TPAYPROFILE: this.$store.getters["auth/TPAYPROFILE"],
      isUserNameExists: true,
      ISMOBILEVERIFIED:false,
      GETDRIVERSBYASSID:[],
      GETALL:[],
      ASSOCIATIONRANKS:[],
      ASSOCIATIONROUTES:[],
      OPT:"",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      SERPROFILE: this.$store.getters["auth/USERPROFILE"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      selectedUserGroupId: 0,
      GETALLUSERGROUPS: [],
      resolutionWidth: undefined,
      resolutionHeight: undefined,
      hacksmartwizard: false,
      SELECTEDRANK: null,
      SELECTEDTAMSRANK: null,
      EDITMODE:false,
      skip: 0,
      length: 10000,
      TAXIFARE: 0,
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    CapturePhotosControl
  },
  computed: {
    IsGetQuoteReady() {
      // `this` points to the component instance
      return this.isVehicleExists && this.SUBMITQUOTE.renewExpireDate != "" && this.SUBMITQUOTE.renewExpireDate != "" && this.SUBMITQUOTE.renewOwnId != "" && this.SUBMITQUOTE.renewOwnName != "" && this.SUBMITQUOTE.renewOwnNumber != "" && this.SUBMITQUOTE.renewOwnNumber2 != "" && this.SUBMITQUOTE.renewEmail != "" && this.SUBMITQUOTE.DeliveryAddress != "" && this.SUBMITQUOTE.renewRegNumber != "" && this.SUBMITQUOTE.renewLicencePlate != "" && this.SUBMITQUOTE.renewMake != "" && this.SUBMITQUOTE.renewTara != "";
    },
    IsCanAddVehicle() {
      // `this` points to the component instance
      return this.isVehicleExists != undefined && !this.isVehicleExists && this.SUBMITQUOTE.renewExpireDate != "" && this.SUBMITQUOTE.renewExpireDate != "" && this.SUBMITQUOTE.renewOwnId != "" && this.SUBMITQUOTE.renewOwnName != "" && this.SUBMITQUOTE.renewOwnNumber != "" && this.SUBMITQUOTE.renewOwnNumber2 != "" && this.SUBMITQUOTE.renewEmail != "" && this.SUBMITQUOTE.DeliveryAddress != "" && this.SUBMITQUOTE.renewRegNumber != "" && this.SUBMITQUOTE.renewLicencePlate != "" && this.SUBMITQUOTE.renewMake != "" && this.SUBMITQUOTE.renewTara != "";
    }
  },
  watch: {
    renewLicencePlate: function(val) {
      if(val.length === 0) return;
      this.isVehicleExistsLoading = true;
      debugger;
      if (val.toString().length > 3) {
        this.context.$store
          .dispatch("onlineservices/SearchByLicenseDisk", {
            router: this.$router,
            AddModel:{
            renewLicencePlate: val
          }
        })
          .then(rb => {
            console.log(rb);
            this.isVehicleExists = rb.Found;
            this.VehicleExistsObject = rb;
            this.SUBMITQUOTE.renewLicencePlate = val;
            if(rb.Found && this.SUBMITQUOTE.renewExpireDate !== ""){
              if(this.SUBMITQUOTE.renewExpireDate !== rb.ExpireDate)
              this.isVehicleExists = false;
            }
            
            //this.isUserNameExists = rb.Status;
          }).finally(()=>{
            this.isVehicleExistsLoading = false;
          });
      }
    },
  },
  methods: {
    ...mapActions([
      "getall",
      "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted"
    ]),
    init: file => {},
    MarkAsDelivered: (context,Id) => {
      context.$Notify
          .YesNo(false, { context, Id })
          .then(value => {
            if (value) {
              value.context.$wait.start("MarkAsDelivered-"+value.Id);
              value.context.$store
              .dispatch("onlineservices/MarkAsDeliveredLicenseRenewal", {
                  router: value.context.$router,
                  AddModel: {
                    Id: value.Id,
                    AddedBy: value.context.USERID
                  }
              })
                .then((data) => {
                  value.context.$Notify.Success("Success!", "");
                  value.context.getOrders(value.context);
                })
                .catch((error) => {
                  console.log("vehicles error: ", error);
                }).finally(()=>{
                  value.context.$wait.end("MarkAsDelivered-"+value.Id);
                })
        }
          });
    },
    AddVehicle: context => {
      context.$wait.start("AddVehicle");
      context.$store
      .dispatch("onlineservices/AddVehicleToRenewOnline", {
          router: context.$router,
          AddModel:context.SUBMITQUOTE
      })
        .then((data) => {
          context.isVehicleExists = data.Found;
          debugger;
          console.log(data);
        })
        .catch((error) => {
          console.log("vehicles error: ", error);
        }).finally(()=>{
          context.$wait.end("AddVehicle");
        })
    },
    GetQuote: context => {
      context.$wait.start("GetQuote");
      context.$store
      .dispatch("onlineservices/GetLicenseQuote", {
          router: context.$router,
          AddModel:context.SUBMITQUOTE
      })
        .then((data) => {
          debugger;
          context.RENEWALQUOTE.push(data)
          $(".sw-btn-next").text("Place An Order").removeAttr("disabled");
        })
        .catch((error) => {
          console.log("vehicles error: ", error);
        }).finally(()=>{
          context.$wait.end("GetQuote");
        })
    },
    getbyAssId: context => {
      debugger;
      context.$store
      .dispatch("OperatorVehicle/getAllByTaxiAssID", {
          router: context.$router,
          id: context.taxi_association_id,
      })
        .then((data) => {
          debugger;
          console.log("These are the vehicles:(vue page) ", data);

          context.GETVEHICLEBYASSID = data;
          context.dataLoaded = true;

          // TableManageCombine.init();
        })
        .catch((error) => {
          console.log("vehicles error: ", error);
        }).finally(()=>{

        })
    },
    getOrders: context => {
      debugger;
      context.$store
      .dispatch("onlineservices/GetAllLicenseDiskOrders", {
          router: context.$router,
          id: context.taxi_association_id,
      }).then((data) => {
          debugger;

          context.GETORDERSBYASSID = data[0].data;
          context.dataLoaded = true;

          // TableManageCombine.init();
        })
        .catch((error) => {
          console.log("vehicles error: ", error);
        }).finally(()=>{

        })
    },
    getRoutes: context => {
      context.$store
      .dispatch("TA/getTaxiAssRoutes", {
        router: context.$router,
          id: context.taxi_association_id,
        })
        .then((data) => {
          context.ASSOCIATIONROUTES = data;
          
        }).catch((error)=>{
          console.log("these are the getter errors:",error);
        })
        .finally((error)=>{
           $('#data-table-default').DataTable({
			responsive: true
		});
        })
    },
  },
  mounted: function() {
    //this.get(this.context);

    App.init();

    $(".LicenceExpiryDate").datepicker({
      todayHighlight: false,
      autoclose: true,
    }).on('changeDate', function(e) {
      const context = $("#wizard").data("context");
      context.SUBMITQUOTE.renewExpireDate = e.format("yyyy-mm")
    });

    this.getbyAssId(this.context);
    this.getOrders(this.context);

    if(this.$store.getters["auth/SELECTEDTAXIASSOCIATION"]._physicaladdress2 === '' || 
    this.$store.getters["auth/SELECTEDTAXIASSOCIATION"]._physicaladdress2 == null){
      this.SUBMITQUOTE.DeliveryAddress = "55 Richards Drive, Halfway House, Midrand, 1685"
    }

    
    $("#SelectVehicle").select2({
      multiple: false,
      placeholder: "Select Taxi",
      allowClear: false,
    }).on("select2:select", function (e) {
      const context = $("#wizard").data("context");

      const Item = context.GETVEHICLEBYASSID.find(
        (x) => x._taxi_operator_vehicles_id == $("#SelectVehicle").val()
      );

      debugger;
      
      if(Item._licence_expiry_date != null){
        context.SUBMITQUOTE.renewExpireDate = Item._licence_expiry_date.split('T')[0].substring(0, 7)
        $('#LicenceExpiryDate > input').val(Item._licence_expiry_date.split('T')[0].substring(0, 7));
      }
      

      context.SUBMITQUOTE.renewOwnId = Item._taxi_operators_idnumber;
      context.SUBMITQUOTE.renewOwnName = Item._taxi_operators_name;
      context.SUBMITQUOTE.renewOwnNumber = Item._taxi_operators_mobilenumber;
      //context.SUBMITQUOTE.renewOwnNumber2 = Item._licence_expiry_date
      //context.SUBMITQUOTE.renewEmail = Item._licence_expiry_date
      //context.SUBMITQUOTE.DeliveryAddress = Item._licence_expiry_date
      context.SUBMITQUOTE.renewRegNumber = Item._vehicle_register_number;
      context.SUBMITQUOTE.renewLicencePlate = Item._licence_plate;
      context.renewLicencePlate = Item._licence_plate;
      context.SUBMITQUOTE.renewMake = Item._vehicle_make + " " + Item._vehicle_model;
      context.SUBMITQUOTE.renewTara = Item._tara;
      context.isVehicleExists = undefined;
      context.RENEWALQUOTE = []
      $(".sw-btn-next").text("Place An Order").attr("disabled", "disabled");
      
    });

    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "slide",
      transitionSpeed: 400,
      useURLhash: false,
      showStepURLhash: false,
      toolbarSettings: {
        toolbarPosition: "bottom"
      }
    });

    $(".sw-btn-next").attr("disabled", "disabled");

    $("#wizard").data("context", this.context);

    $("#wizard").on("leaveStep", function(
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {
     
      const context = $("#wizard").data("context");
      const currentstep = stepNumber + 1;
      var res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

        if (currentstep === 2 && stepDirection == "backward") {
       
                context.hacksmartwizard = false;
                 $(".sw-btn-next").text("Next") 
        }


        if(context.hacksmartwizard)
        return true;


      $(".sw-btn-next").text("Next");

      if (currentstep === 1 && stepDirection == "forward") {
        
        if (!res){
          return res;
        }
      
          $(".sw-btn-next").text("Submit");
          
      }

      

      
      if (currentstep === 1 && stepDirection == "forward") {

           const context = $("#wizard").data("context");
   
        context.$Notify
          .YesNo(false, { context: context, item: context.ADDSYSTEMUSER })
          .then(value => {
            if (value) {
              $(".sw-btn-next")
                .text("")
                .append('<i class="fas fa-spinner fa-spin"></i>')
                .attr("disabled", "disabled");

              value.router = value.context.$router;
              value.context.$store
              .dispatch("onlineservices/GetLicenseDiskPlaceOrder", {
                    router: value.context.$router,
                    AddModel:value.context.SUBMITQUOTE
                }).then((parsedResult) => {
                  debugger
                  value.context.SUBMITTEDORDER = parsedResult;
                   value.context.hacksmartwizard = true;

                $("#wizard").smartWizard("next");
                  $(".sw-btn-next")
                    .text("Done")
                    .attr("disabled", "disabled");

                    //value.context.GetALLRoutesById(value.context,value.context.taxi_association_id);

                })
                .catch(error => {
                  $(".sw-btn-next")
                    .text("Place An Order")
                    .removeAttr("disabled", "disabled");

                  value.context.$Notify.Error(parsedResult.message, "");
                })
                .finally(() => {});
            }
          });

        return false;
      }

      return res;
    });

  }
};
</script>

<style scoped>
</style>

