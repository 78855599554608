<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TaxiAssociation'"
      :ActiveMenu="'AddTaxiAssociation'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Add New Taxi Association"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Association"
        breadcrumbitem3="Add"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Taxi Association Information
                      <small
                        class="text-ellipsis"
                      >District, Taxi Affiliation, Name and Tel</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-2">
                    <span class="number">2</span>
                    <span class="info text-ellipsis">
                      Billing
                      <small class="text-ellipsis">Taxi Association Billing</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-3">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Contact Person And Logo
                      <small
                        class="text-ellipsis"
                      >Logo And Personal Details Of Contact Person.....</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-4">
                    <span class="number">5</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Complete Registration</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-2 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <!-- begin row -->
                  <div class="row">
                    <!-- begin col-8 -->
                    <!-- <div class="panel-toolbar">
                      <div class="btn-group m-r-5">
                        <a
                          data-toggle="modal"
                          data-target="#AddModal"
                          class="btn btn-white"
                          href="javascript:;"
                          @click="clearAdded(GETSELECTED)"
                        >
                          <i class="fa fa-plus"></i>
                          Add District
                        </a>
                      </div>
                    </div> -->

                    <div class="col-md-12">
                      <div class="row panel panel-inverse">
                        <!-- begin col-8 -->
                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Taxi Affiliation
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <select
                                name="TaxiAffiliationName"
                                class="form-control"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="ADDTAXIASSOCIATIONS._fk_taxi_affiliation_id"
                              >
                                <option disabled value>Select Taxi Affiliation</option>
                                <option
                                  v-for="item in TAXIAFFILIATION"
                                  v-bind:key="item._id"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              District
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <select
                                name="DistrictName"
                                class="form-control"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="ADDTAXIASSOCIATIONS._district_id"
                                :disabled="!ADDTAXIASSOCIATIONS._fk_taxi_affiliation_id"
                              >
                                <option disabled value>Select District</option>
                                <option
                                  v-for="item in GETALLDISTRICTSBYTAXIFILICATION(context,ADDTAXIASSOCIATIONS._fk_taxi_affiliation_id)"
                                  v-bind:key="item.district_id"
                                  :value="item.district_id"
                                >{{ item.district_name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              RAS Registration No.
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="e.g. RASGP84652DFD/"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._ras_registration_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Taxi Association Name
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="taxiassociationname"
                                placeholder="e.g. Midrand Taxi Association"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._name"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Abbreviation
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="Abbreviation"
                                placeholder="e.g. MTA"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._abbriviated_name"
                              />
                            </div>
                          </div>
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Telephone</label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                id="telephone"
                                name="telephone"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._telephone"
                              />
                            </div>
                          </div>
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Mobile Number</label>
                            <div class="col-md-6">
                              <input
                                id="TaxiAssociationMobileNumber"
                                type="number"
                                name="TaxiAssociationMobileNumber"
                                data-parsley-group="step-1"
                                max="10"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._mobilenumber"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>
                        <!-- end col-8 -->

                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Max Number Of Operators
                              <span class="text-danger"></span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                name="MaxNumberOfOperators"
                                placeholder="Enter Number Or Leave Empty"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._maximum_number_members"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Max Number Of Taxis Per Operator
                              <span class="text-danger"></span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                name="MaxNumberOfTaxisOperators"
                                placeholder="Enter Number Or Leave Empty"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._maximum_number_vehicles"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Has Taxi Operator Billing?
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <div class="switcher switcher-success">
                                <input
                                  type="checkbox"
                                  name="switcher_checkbox_operatorbilling"
                                  id="switcher_checkbox_operatorbilling"
                                  data-parsley-checkmin="1"
                                  required
                                  data-parsley-group="step-1"
                                  data-parsley-required="true"
                                  checked
                                  true-value="true"
                                  false-value="false"
                                  v-model="ADDTAXIASSOCIATIONS._create_operator_account"
                                />
                                <label for="switcher_checkbox_operatorbilling"></label>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Has Taxi Vehicle Billing?
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <div class="switcher switcher-success">
                                <input
                                  type="checkbox"
                                  name="switcher_checkbox_Vehiclebilling"
                                  id="switcher_checkbox_Vehiclebilling"
                                  data-parsley-checkmin="1"
                                  required
                                  data-parsley-group="step-1"
                                  data-parsley-required="true"
                                  checked
                                  true-value="true"
                                  false-value="false"
                                  v-model="ADDTAXIASSOCIATIONS._create_vehicle_account"
                                />
                                <label for="switcher_checkbox_Vehiclebilling"></label>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Has Driver Billing?
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <div class="switcher switcher-success">
                                <input
                                  type="checkbox"
                                  name="switcher_checkbox_Driverbilling"
                                  id="switcher_checkbox_Driverbilling"
                                  checked
                                  true-value="true"
                                  false-value="false"
                                  v-model="ADDTAXIASSOCIATIONS._create_driver_account"
                                />
                                <label for="switcher_checkbox_Driverbilling"></label>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>

                        <div class="col-lg-6">
                          <!-- begin col-6 -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Physical Address
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="6 Tottenham Ave, Melrose Flats"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._physical_address"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Physical Address 2
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="36 Saxon Rd, Sandhurst "
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._physical_address2"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Physical Suburb
                              <span class="text-danger"></span>
                            </label>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                id="selectPhysicalSuburb"
                                v-model="ADDTAXIASSOCIATIONS._physical_suburb_id"
                              >
                                <option disabled value>Select a Suburb</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLSUBURBS"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>
                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Postal Address
                              <span class="text-danger"></span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._postal_address"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Postal Address 2
                              <span class="text-danger"></span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._postal_address2"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Postal Suburb
                              <span class="text-danger"></span>
                            </label>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="100"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                id="selectPostalSuburb"
                                v-model="ADDTAXIASSOCIATIONS._postal_suburb_id"
                              >
                                <option disabled value>Select a Suburb</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLSUBURBS"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>

                        <!-- begin col-12 -->
                        <div class="col-lg-12">
                          <!-- begin panel -->
                          <div class="panel panel-inverse panel-primary">
                            <!-- begin panel-heading -->
                            <div class="panel-heading">
                              <h4 class="panel-title">Main Subscription</h4>
                            </div>
                            <!-- end panel-heading -->
                            <div class="panel-toolbar">
                              <div class="btn-group m-r-5">
                                <span>All Prices Exclude Vat</span>
                              </div>
                            </div>
                            <!-- begin panel-body -->
                            <div class="panel-body">
                              <div class="rows">
                                <div class="col-lg-6">
                                  <!-- end form-group -->
                                  <div class="form-group row m-b-10">
                                    <label class="col-md-4 col-form-label text-md-right">
                                      Billing Type
                                      <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-md-6">
                                      <select
                                        class="form-control"
                                        data-size="100"
                                        data-live-search="true"
                                        data-parsley-group="step-1"
                                        data-parsley-required="true"
                                        v-model="BILLINGTYPE"
                                      >
                                        <option disabled value>Select a Billing Type</option>
                                        <option
                                          v-bind:key="item._id"
                                          v-for="item in GETBILLINGTYPES"
                                          :value="item"
                                        >{{item._description}}</option>
                                      </select>
                                    </div>
                                  </div>
                                  <!-- end form-group -->
                                  <!-- begin form-group -->
                                  <div class="form-group row m-b-10">
                                    <label class="col-md-4 col-form-label text-md-right">
                                      Retainer Amount
                                      <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-md-6">
                                      <CurrencyInput
                                        v-model="BILLINGTYPE._price"
                                        :auto-decimal-mode="true"
                                        :currency="currency"
                                        data-parsley-group="step-1"
                                        data-parsley-required="true"
                                        class="form-control text-left demo__currency-input"
                                      />
                                    </div>
                                  </div>
                                  <!-- end form-group -->

                                  <!-- begin form-group -->
                                  <div class="form-group row m-b-10">
                                    <label class="col-md-4 col-form-label text-md-right">
                                      Transaction Fee(%)
                                      <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-md-6">
                                      <input
                                        ref="transfee"
                                        type="Number"
                                        placeholder
                                        data-parsley-group="step-1"
                                        data-parsley-required="true"
                                        class="form-control"
                                        v-model="BILLINGTYPE._percentage"
                                      />
                                    </div>
                                  </div>
                                  <!-- end form-group -->
                                </div>
                              </div>
                            </div>
                            <!-- end panel-body -->
                          </div>
                          <!-- end panel -->
                        </div>
                        <!-- end col-12 -->

                        <!-- begin col-12 -->
                        <div class="col-lg-12">
                          <!-- begin panel -->
                          <div class="panel panel-primary">
                            <!-- begin panel-heading -->
                            <div class="panel-heading">
                              <h4 class="panel-title">Add On Billing</h4>
                            </div>
                            <!-- end panel-heading -->

                            <div class="alert alert-danger fade hide addonserviceerror">
                              <span class="close" data-dismiss="alert">×</span>
                              <i class="fa fa-exclamation-triangle fa-2x pull-left m-r-10"></i>
                              <p class="m-b-0">Please choose at least one Add On Service</p>
                            </div>

                            <div class="panel-toolbar">
                              <div class="btn-group m-r-5">
                                <span>All Prices Exclude Vat</span>
                              </div>
                            </div>

                            <!-- begin panel-body -->
                            <div class="panel-body">
                              <table
                                id="data-table-addonservice"
                                class="table table-striped data-table table-bordered"
                              >
                                <thead>
                                  <tr>
                                    <th width="1%" data-orderable="false"></th>
                                    <th class="text-nowrap">Name</th>
                                    <th class="text-nowrap">Description</th>
                                    <th width="15%">Price Per Unit(R)</th>
                                    <th width="2%"></th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr
                                    v-bind:key="item._id"
                                    v-for="(item,index) in GETADDONSERVICETYPES"
                                    :class="{'success': item._date_deleted}"
                                  >
                                    <td class="f-s-600 text-inverse">{{++index}}</td>
                                    <td>{{item._name}}</td>
                                    <td>{{item._description}}</td>
                                    <td>{{item._unit_price | currencywithSymbol}}</td>
                                    <td class="with-btn valign-middle" nowrap>
                                      <button
                                        v-show="!item._date_deleted"
                                        type="button"
                                        class="btn btn-sm btn-yellow width-80"
                                        @click="() => { ADDONSERVICETYPES.push(item._id); item._date_deleted = true; }"
                                      >Add</button>
                                      <button
                                        v-show="item._date_deleted"
                                        type="button"
                                        class="btn btn-sm btn-success btnadded width-80"
                                        @click="() => { ADDONSERVICETYPES.slice(--index,1); item._date_deleted= false; }"
                                      >Added</button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <!-- end panel-body -->
                          </div>
                          <!-- end panel -->
                        </div>
                        <!-- end col-12 -->
                      </div>
                    </div>
                    <!-- end col-8 -->
                  </div>
                  <!-- end row -->
                  <!-- end fieldset -->
                </div>
                <!-- end step-2 -->
                <!-- begin step-2 -->
                <div id="step-2">
                  <!-- begin fieldset -->
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->
                    <div class="row">
                      <!-- begin col-12 -->
                      <div class="col-lg-12">
                        <!-- begin panel -->
                        <div class="panel panel-inverse panel-primary">
                          <!-- begin panel-heading -->
                          <div class="panel-heading">
                            <div class="panel-heading-btn">
                              <a
                                href="javascript:;"
                                class="btn btn-xs btn-icon btn-circle btn-default"
                                data-click="panel-expand"
                              >
                                <i class="fa fa-expand"></i>
                              </a>
                              <a
                                href="javascript:;"
                                class="btn btn-xs btn-icon btn-circle btn-success"
                                data-click="panel-reload"
                              >
                                <i class="fa fa-redo"></i>
                              </a>
                              <a
                                href="javascript:;"
                                class="btn btn-xs btn-icon btn-circle btn-warning"
                                data-click="panel-collapse"
                              >
                                <i class="fa fa-minus"></i>
                              </a>
                            </div>

                            <h4 class="panel-title">Taxi Association Routes</h4>
                          </div>
                          <!-- end panel-heading -->

                          <div class="panel-toolbar">
                            <div class="btn-group m-r-5">
                              <a
                                data-toggle="modal"
                                data-target="#AddModal"
                                class="btn btn-white"
                                href="javascript:;"
                                @click="addRoute(context)"
                              >
                                <i class="fa fa-plus"></i>
                                Add Route
                              </a>
                            </div>
                          </div>

                          <!-- begin panel-body -->
                          <div class="panel-body">
                            <table id="data-table-titles" class="table">
                              <thead>
                                <tr>
                                  <th width="1%" data-orderable="false"></th>
                                  <th class="text-nowrap">Name</th>
                                  <th class="text-nowrap">Description</th>
                                  <th class="text-nowrap">Taxi Fare</th>
                                  <th class="text-nowrap">Code</th>
                                  <th class="text-nowrap"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr
                                  v-for="(item,index) in TaxiAssociationRoutes"
                                  :key="item.id"
                                  :value="item"
                                >
                                  <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                                  <td class="with-form-control">
                                    <input
                                      type="text"
                                      data-parsley-group="step-2"
                                      data-parsley-required="false"
                                      placeholder="e.g. Local To Town"
                                      class="form-control"
                                      v-model="item.name"
                                    />
                                  </td>
                                  <td class="with-form-control">
                                    <input
                                      type="text"
                                      data-parsley-group="step-2"
                                      data-parsley-required="false"
                                      placeholder="e.g. Local To Town"
                                      class="form-control"
                                      v-model="item.description"
                                    />
                                  </td>
                                  <td class="with-form-control">
                                    <CurrencyInput
                                      v-model="item.taxifare"
                                      :auto-decimal-mode="true"
                                      :currency="currency"
                                      data-parsley-required="false"
                                      class="form-control text-left demo__currency-input"
                                    />
                                  </td>
                                  <td class="with-form-control">
                                    <input
                                      ref="code"
                                      type="text"
                                      data-parsley-group="step-2"
                                      data-parsley-required="false"
                                      placeholder="e.g. LTT"
                                      class="form-control"
                                      v-model="item.code"
                                    />
                                  </td>
                                  <td class="with-btn" nowrap>
                                    <button
                                      :disabled="!item.candelete"
                                      type="button"
                                      class="btn btn-sm btn-danger width-80"
                                      @click="TaxiAssociationRoutes.splice(--index,1)"
                                    >Delete</button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <!-- end panel-body -->
                        </div>
                        <!-- end panel -->
                      </div>
                      <!-- end col-12 -->

                      <!-- begin col-12 -->
                      <div class="col-lg-12">
                        <!-- begin panel -->
                        <div class="panel panel-inverse panel-primary">
                          <!-- begin panel-heading -->
                          <div class="panel-heading">
                            <div class="panel-heading-btn">
                              <a
                                href="javascript:;"
                                class="btn btn-xs btn-icon btn-circle btn-default"
                                data-click="panel-expand"
                              >
                                <i class="fa fa-expand"></i>
                              </a>
                              <a
                                href="javascript:;"
                                class="btn btn-xs btn-icon btn-circle btn-success"
                                data-click="panel-reload"
                              >
                                <i class="fa fa-redo"></i>
                              </a>
                              <a
                                href="javascript:;"
                                class="btn btn-xs btn-icon btn-circle btn-warning"
                                data-click="panel-collapse"
                              >
                                <i class="fa fa-minus"></i>
                              </a>
                            </div>

                            <h4 class="panel-title">Billing Items</h4>
                          </div>
                          <!-- end panel-heading -->

                          <div class="panel-toolbar">
                            <div class="btn-group m-r-5">
                              <a
                                data-toggle="modal"
                                data-target="#AddModal"
                                class="btn btn-white"
                                href="javascript:;"
                                @click="AddBillingItems(context)"
                              >
                                <i class="fa fa-plus"></i>
                                Add Bill Item
                              </a>
                            </div>
                          </div>

                          <!-- begin panel-body -->
                          <div class="panel-body panel-body">
                            <div class="row panel panel-inverse">
                              <!-- begin col-8 -->
                              <div class="col-lg-6">
                                <!-- begin form-group -->
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">Route</label>
                                  <div class="col-md-6">
                                    <select
                                      class="form-control"
                                      data-parsley-group="step-2"
                                      v-model="billitems.route"
                                    >
                                      <option value="0" selected>Select Route</option>
                                      <option
                                        v-for="item in TaxiAssociationRoutes"
                                        v-bind:key="item.id"
                                        :value="item"
                                      >{{item.name}}</option>
                                    </select>
                                  </div>
                                </div>
                                <!-- end form-group -->
                                <!-- begin form-group -->
                                <div class="form-group row m-b-10">
                                  <label
                                    class="col-md-4 col-form-label text-md-right"
                                  >Type Of Client</label>
                                  <div class="col-md-6">
                                    <select
                                      class="form-control"
                                      data-parsley-group="step-2"
                                      v-model="billitems.typeofclients"
                                    >
                                      <option value="0" selected>Select Type Of Client</option>
                                      <option
                                        v-for="item in GETTYPEOFBILLINGCLIENT"
                                        v-bind:key="item._typeofbillingclien_id"
                                        :value="item"
                                      >{{item._name}}</option>
                                    </select>
                                  </div>
                                </div>
                                <!-- end form-group -->
                                <!-- begin form-group -->
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">
                                    Collection Settings
                                  </label>
                                  <div class="col-md-6">
                                    <select
                                      class="form-control"
                                      data-parsley-group="step-2"
                                      v-model="billitems.collectionsettings"
                                    >
                                      <option value="0" selected>Select A Settings</option>
                                      <option
                                        v-for="item in GETCOLLECTIONSETTINGS"
                                        v-bind:key="item._collection_settings_id"
                                        :value="item"
                                      >{{item._collection_settings_name + '(' + item._collection_frequency_num_of_days + ' Days' + ')'}}</option>
                                    </select>
                                  </div>
                                </div>
                                <!-- end form-group -->
                                <!-- begin form-group -->
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">Amount</label>
                                  <div class="col-md-6">
                                    <CurrencyInput
                                      v-model="billitems.amount"
                                      :auto-decimal-mode="true"
                                      :currency="currency"
                                      class="form-control text-left demo__currency-input"
                                    />
                                  </div>
                                </div>
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">Start Date</label>
                                  <div class="col-md-6">
                                    <div
                                      id="startdate"
                                      class="input-group date datepicker-disabled-past"
                                      data-date-format="dd-mm-yyyy"
                                      data-date-start-date="Date.default"
                                    >
                                      <input
                                        type="text"
                                        data-parsley-group="step-2"
                                        class="form-control"
                                        placeholder="Select Date"
                                      />
                                      <span class="input-group-addon">
                                        <i class="fa fa-calendar"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">
                                    End Date
                                    <span class="text-danger"></span>
                                  </label>
                                  <div class="col-md-6">
                                    <div
                                      id="enddate"
                                      class="input-group date datepicker-disabled-past"
                                      data-date-format="dd-mm-yyyy"
                                      data-date-start-date="Date.default"
                                    >
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Select Date"
                                      />
                                      <span class="input-group-addon">
                                        <i class="fa fa-calendar"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <!-- end form-group -->
                              </div>
                              <!-- end col-8 -->

                              <div class="col-lg-6">
                                <!-- begin form-group -->
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">
                                    Can Be Disputed
                                    <span class="text-danger"></span>
                                  </label>
                                  <div class="col-md-6">
                                    <div class="switcher switcher-success">
                                      <input
                                        type="checkbox"
                                        name="switcher_checkbox_canbedisputed"
                                        id="switcher_checkbox_canbedisputed"
                                        true-value="true"
                                        false-value="false"
                                        v-model="billitems.canbedisputed"
                                      />
                                      <label for="switcher_checkbox_canbedisputed"></label>
                                    </div>
                                  </div>
                                </div>
                                <!-- end form-group -->
                                <!-- begin form-group -->
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">
                                    Can Be Deleted
                                    <span class="text-danger"></span>
                                  </label>
                                  <div class="col-md-6">
                                    <div class="switcher switcher-success">
                                      <input
                                        type="checkbox"
                                        name="switcher_checkbox_canbedeleted"
                                        id="switcher_checkbox_canbedeleted"
                                        true-value="true"
                                        false-value="false"
                                        v-model="billitems.canbedeleted"
                                      />
                                      <label for="switcher_checkbox_canbedeleted"></label>
                                    </div>
                                  </div>
                                </div>
                                <!-- end form-group -->
                                <!-- begin form-group -->
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">
                                    Penalty Collection Setting
                                    <span class="text-danger"></span>
                                  </label>
                                  <div class="col-md-6">
                                    <select
                                      class="form-control"
                                      v-model="billitems.penaltycollectionsettings"
                                    >
                                      <option value="0" selected>Select Type Of Client</option>
                                      <option
                                        v-for="item in GETCOLLECTIONSETTINGS"
                                        v-bind:key="item._collection_settings_id"
                                        :value="item"
                                      >{{item._collection_settings_name + '(' + item._collection_frequency_num_of_days + ' Days' + ')'}}</option>
                                    </select>
                                  </div>
                                </div>
                                <!-- end form-group -->
                                <!-- begin form-group -->
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">
                                    Penalty Fee Warning
                                    <span class="text-danger"></span>
                                  </label>
                                  <div class="col-md-6">
                                    <input
                                      type="text"
                                      v-model="billitems.penaltyfeewarning"
                                      id="default_rangeSlider"
                                      name="default_rangeSlider"
                                      value
                                    />
                                  </div>
                                </div>
                                <!-- end form-group -->
                                <!-- begin form-group -->
                                <div class="form-group row m-b-10">
                                  <label class="col-md-4 col-form-label text-md-right">
                                    Penalty Fee Amount
                                    <span class="text-danger"></span>
                                  </label>
                                  <div class="col-md-6">
                                    <CurrencyInput
                                      v-model="billitems.penaltyfeeamount"
                                      :auto-decimal-mode="true"
                                      :currency="currency"
                                      data-parsley-group="step-2"
                                      data-parsley-required="false"
                                      class="form-control text-left demo__currency-input"
                                    />
                                  </div>
                                </div>
                                <!-- end form-group -->
                              </div>
                            </div>

                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th class="text-nowrap"></th>
                                    <th class="text-nowrap">Route</th>
                                    <th class="text-nowrap">Type Of Client</th>
                                    <th class="text-nowrap">Collection Settings</th>
                                    <th class="text-nowrap">Amount</th>
                                    <th class="text-nowrap">Start Date</th>
                                    <th class="text-nowrap">End Date</th>
                                    <th class="text-nowrap">Next Bill Date</th>
                                    <th class="text-nowrap">Can Be Disputed</th>
                                    <th class="text-nowrap">Can Be Disputed</th>
                                    <th class="text-nowrap">Penalty Settings</th>
                                    <th class="text-nowrap">Penalty Warning</th>
                                    <th class="text-nowrap">Penalty Amount</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr
                                    v-for="item in billitemsadded"
                                    v-bind:key="item._typeofbillingclien_id"
                                    :value="item"
                                  >
                                    <td class="with-btn" nowrap>
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-danger width-80"
                                        @click="billitemsadded.splice(--index,1)"
                                      >Delete</button>
                                    </td>
                                    <td>{{item.route.name}}</td>
                                    <td>{{item.typeofclients._name}}</td>
                                    <td>{{item.collectionsettings._collection_settings_name}}</td>
                                    <td>{{item.amount}}</td>
                                    <td>{{item.startdate | formatToShortDate}}</td>
                                    <td>{{item.enddate | formatToShortDate}}</td>
                                    <td>{{item.nextbilldate | formatToShortDate}}</td>
                                    <td class="with-checkbox">
                                      <div class="checkbox checkbox-css">
                                        <input
                                          type="checkbox"
                                          :value="item.canbedisputed"
                                          disabled
                                          id="table_checkbox_2"
                                          checked
                                        />
                                        <label for="table_checkbox_2">&nbsp;</label>
                                      </div>
                                    </td>
                                    <td class="with-checkbox">
                                      <div class="checkbox checkbox-css">
                                        <input
                                          type="checkbox"
                                          :value="item.canbedeleted"
                                          disabled
                                          id="table_checkbox_2"
                                          checked
                                        />
                                        <label for="table_checkbox_2">&nbsp;</label>
                                      </div>
                                    </td>
                                    <td>{{item.penaltycollectionsettings._collection_settings_name}}</td>
                                    <td>{{item.penaltyfeewarning}}</td>
                                    <td>{{item.penaltyfeeamount}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div class="alert alert-danger fade hide routebillingerror">
                              <span class="close" data-dismiss="alert">×</span>
                              <i class="fa fa-exclamation-triangle fa-2x pull-left m-r-10"></i>
                              <p class="m-b-0">Please choose at least one Add On Billing Item</p>
                            </div>
                          </div>
                          <!-- end panel-body -->
                        </div>
                        <!-- end panel -->
                      </div>
                      <!-- end col-12 -->
                    </div>
                    <!-- end row -->
                  </fieldset>
                  <!-- end fieldset -->
                  <!-- end fieldset -->
                </div>
                <!-- end step-2 -->
                <!-- begin step-3 -->
                <div id="step-3">
                  <!-- begin fieldset -->
                  <!-- begin row -->
                  <div class="row">
                    <!-- begin col-8 -->

                    <div class="col-md-12">
                      <div class="row panel panel-inverse">
                        <!-- begin col-8 -->
                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              First Name
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="ContactPersonFirstName"
                                data-parsley-group="step-3"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="contactdetails_first_name"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Surname
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="ContactPersonSurname"
                                data-parsley-group="step-3"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="contactdetails_surname"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Mobile Number
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                id="ContactPersonMobileNumber"
                                type="number"
                                name="ContactPersonMobileNumber"
                                placeholder="(082) 999-9999"
                                data-parsley-group="step-3"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDTAXIASSOCIATIONS._contact_person_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>
                        <!-- end col-8 -->
                        <div class="col-lg-6">
                          <div class="panel panel-inverse panel-primary">
                            <div class="panel-heading">
                              <div class="panel-heading-btn"></div>
                              <h4 class="panel-title">Drop Logo In Here(Recommended Size: Width:161 x Height:50)</h4>
                            </div>
                            <div class="panel-body text-inverse">
                              <DropZoneControl
                                :ClearData="true"
                                :acceptedFiles="acceptedFiles"
                                :maxFiles="maxFiles"
                                :resizeWidth="DropZoneresizeWidth"
                                :resizeHeight="DropZoneresizeHeight"
                                v-on:fileadded="fileadded"
                                :SessionID="DropZoneSessionID"
                              ></DropZoneControl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end col-8 -->
                  </div>
                  <!-- end row -->
                  <!-- end fieldset -->
                </div>
                <!-- begin step-4 -->
                <div id="step-4">
                  <div class="jumbotron m-b-0 text-center">
                    <h2 class="display-4">Taxi Association Successfully Registered</h2>
                    <p v-show="false" class="lead mb-4">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat commodo porttitor.
                      <br />Vivamus eleifend, arcu in tincidunt semper, lorem odio molestie lacus, sed malesuada est lacus ac ligula. Aliquam bibendum felis id purus ullamcorper, quis luctus leo sollicitudin.
                    </p>
                    <br />
                    <br />
                    <p>
                      <router-link
                        v-bind:class="{ 'btn btn-primary btn-lg': true }"
                        tag="button"
                        to="/TaxiAssociation/Manage"
                      >Manage Taxi Associations</router-link>
                    </p>
                  </div>
                </div>
                <!-- end step-4 -->
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>

        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->
	


<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TA");

export default {
  name: "AddTaxiAssociation",
  data: function() {
    return {
      currencySymbol: "R ",
      position: "prefix",
      DropZoneresizeWidth: 292,
      DropZoneresizeHeight: 40,
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      SYSTEMUSERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      GETALLDISTRICTS: [],
      GETALLSUBURBS: [],
      GETBILLINGTYPES: [],
      GETSELECTED: Object.assign({}, this.$store.getters["district/GETALL"]),
      GETADDONSERVICETYPES: JSON.parse(
        JSON.stringify(
          Object.assign({}, [])
        )
      ),
      GETTYPEOFBILLINGCLIENT: [],
      GETCOLLECTIONSETTINGS: [],
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      selectedTaxiAffiliationId: undefined,
      selectedDistrictId: undefined,
      contactdetails_first_name: undefined,
      contactdetails_surname: undefined,
      GETALLPROVINCES: [],
      ADDTAXIASSOCIATIONS: Object.assign(
        {},
        this.$store.getters["TA/ADDTAXIASSOCIATIONS"]
      ),
      BILLINGTYPE: {},
      ADDONSERVICETYPES: [],
      DropZoneSessionID: this.$uuid.v4(),
      acceptedFiles: "image/*",
      maxFiles: 1,
      firsttime: 0,
      TaxiAssociationRoutes: [
        {
          id: this.$uuid.v4(),
          name: "",
          description: "",
          taxifare: "",
          code: "",
          added_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
          candelete: false
        }
      ],
      billitems: {
        id: this.$uuid.v4(),
        route: {},
        typeofclients: {},
        collectionsettings: {},
        amount: 0,
        startdate: "",
        enddate: "",
        nextbilldate: "",
        canbedisputed: false,
        canbedeleted: false,
        penaltycollectionsettings: {},
        penaltyfeewarning: 0,
        penaltyfeeamount: 0
      },
      billitemsadded: [],
      status: true,
      skip: 0,
      len: 1000,
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl
  },
  beforeMount() {
    this.GETALLTAXIAFFILIATIONS(this.context);
    this.loadSuburbs(this.context);

    this.ADDTAXIASSOCIATIONS._dropzoneid = this.DropZoneSessionID;
  },
  computed: {
    ...mapGetters({
      //GETALLTITLES: "GETALLTITLES",
      TAXIAFFILIATION: "GETTAXIAFFILIATIONS"
    }),
    currency() {
      return { [this.position]: this.currencySymbol };
    },
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions(["addtaxiassociation"]),
    get_taxi_affiliation_name: (context,id) =>
    {
       var affiliation_name = context.TAXIAFFILIATION.filter(item => item._id === id)[0]._name;
      return affiliation_name;
    },
    get_province_name: (context, id) =>
    {
      var province_name = context.GETALLPROVINCES.filter(item => item._id === id)[0]._name;   ;
      return province_name;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
    },
    addDistrict: (context, item, addedby,LoggedInUserFullName, _taxi_affiliation_name, _province_name) => {
      
      item._addedby_name = LoggedInUserFullName;
      item.taxi_affiliation_name = _taxi_affiliation_name;
      item.province_name = _province_name;
      item._is_active = true;

      

      context.$Notify.YesNo(false,{ context, item, addedby })
      .then(value => {
        if (value) {
          
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("district/add", {
              _dataObj: value
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");
              value.context.$Notify.Success("Successfully Added!", "");
            })
            .catch(error => {
              value.context.$wait.end("add");
              
              value.context.$Notify.Error(error.data[0].message, '');
            })
            .finally(() => {});
        }
      });
    },
    loadLookUps(context) {
      
      context.$store
        .dispatch("lookups/init", {
          router: context.$router,
        })
        .then((data) => {
          console.log("loadLookUps found: ", data);
          

          context.GETCOLLECTIONSETTINGS = data.LOADEDCOLLECTONSETTINGS;
          context.GETTYPEOFBILLINGCLIENT = data.LOADEDTYPEOFBILLINGCLIENTS;
          context.GETBILLINGTYPES = data.LOADEDBILLINGTYPES;
          context.GETADDONSERVICETYPES = JSON.parse(
        JSON.stringify(
          Object.assign({}, data.LOADEDADDONSERVICETYPES)
        )
      );
          context.GETALLDISTRICTS = data.LOADEDDISTRICTS;
          context.GETALLPROVINCES = data.LOADEDPROVINCES;
          context.GETALLTITLES = data.LOADEDTITLES;
        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
    loadSuburbs(context) {
      context.$store
        .dispatch("TO/getSuburbs", {
          router: context.$router,
          status: context.status,
          skip: context.skip,
          len: context.len,
        })
        .then((data) => {
          console.log("suburbs found: ", data);

          context.GETALLSUBURBS = data;
        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },

    AddBillingItems: context => {
      const res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + 2);

      if (!res) {
        return res;
      }

      context.billitems.startdate = context.$Toolkit.ConvertToServerDate(
        $("#startdate").datepicker("getDate")
      );
      context.billitems.enddate = context.$Toolkit.ConvertToServerDate(
        $("#enddate").datepicker("getDate")
      );
      context.billitems.nextbilldate = context.$Toolkit.ConvertToServerDate(
        $("#startdate").datepicker("getDate")
      );
      context.billitems.penaltyfeewarning = $("#default_rangeSlider").data(
        "ionRangeSlider"
      ).result.from;

      context.billitemsadded.push(context.billitems);

      context.billitems = {
        id: context.$uuid.v4(),
        route: {},
        typeofclients: {},
        collectionsettings: {},
        amount: 0,
        startdate: "",
        enddate: "",
        nextbilldate: "",
        canbedisputed: false,
        canbedeleted: false,
        penaltycollectionsettings: {},
        penaltyfeewarning: 0,
        penaltyfeeamount: 0
      };

      let table = {};

      if ($.fn.dataTable.isDataTable("#datatablebillitems")) {
        table = $("#datatablebillitems").DataTable();
      } else {
        table = $("#datatablebillitems").DataTable({
          responsive: true,
          paging: false
        });
      }
      $("#datatablebillitems").addClass("bs-select");
    },
    addRoute: context => {
      context.TaxiAssociationRoutes.push({
        id: context.$uuid.v4(),
        name: "",
        description: "",
        taxifare: "",
        code: "",
        added_by: context.$store.getters["auth/USERPROFILE"].system_usersid,
        candelete: true
      });
    },
    deleteRoute: (context, id) => {
      context.TaxiAssociationRoutes = context.TaxiAssociationRoutes.filter(
        item => item.id !== id
      );
    },
    fileadded: function(data) {
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')
      const context = $("#wizard").data("context");
      //context.GETUPLOADEDFILES = context.$store.getters["fileupload/GETALL"];
    },
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    GETALLTAXIAFFILIATIONS: context => {
      context.$store
        .dispatch("taxiAffiliation/getall", {
          _dataObj: {
            router: context.$router
          }
        })
        .then(() => {})
        .catch(error => {})
        .finally(() => {});
    },
    GETALLDISTRICTSBYTAXIFILICATION: (context, id) => {
      return context.GETALLDISTRICTS.filter(
        item => item.taxi_affiliation_id === id
      );
    },
    clearAdded: item => {
      for (var propName in item) {
        item[propName] = undefined;
      }
    },
    add: (context, item, addedby) => {
      
      item._billtypeid = context.BILLINGTYPE._id;
      if (context.BILLINGTYPE._price != null) {
        item._billtypeamount = context.BILLINGTYPE._price;
      } else {
        item._billtypeamount = 0;
      }

      item._billtypetransactionfee = parseInt(context.BILLINGTYPE._percentage);
      item._addonbillingservices = context.ADDONSERVICETYPES;

      // if (item._postal_suburb_id.toString().trim() == 0)
      //   item._postal_suburb_id = 0;

      item._postal_suburb_id = parseInt($("#selectPostalSuburb").val());
      item._physical_suburb_id = parseInt($("#selectPhysicalSuburb").val());

      item._taxiassociation_routes = context.TaxiAssociationRoutes;
      item._taxiassociation_billingitems = context.billitemsadded.map(
        billitem => {
          return {
            collection_settings_id:
              billitem.collectionsettings._collection_settings_id,
            typeofbillingclient_id:
              billitem.typeofclients._typeofbillingclien_id,
            amount: billitem.amount,
            billing_start_date: billitem.startdate,
            billing_end_date: billitem.enddate,
            next_billing_date: billitem.nextbilldate,
            has_penality_fee: billitem.penaltycollectionsettings[
              "_collection_settings_id"
            ]
              ? true
              : false,
            penaltyfee_amount: billitem.penaltyfeeamount,
            send_penalty_warning:
              billitem.penaltyfeewarning === 0 ? false : true,
            warning_numberofdays_before: billitem.penaltyfeewarning,
            penatyfeecollection_settings_id: billitem.penaltycollectionsettings[
              "_collection_settings_id"
            ]
              ? billitem.penaltycollectionsettings._collection_settings_id
              : undefined,
            can_be_deleted: Boolean(context.billitemsadded[0].canbedeleted),
            can_be_disputed: Boolean(context.billitemsadded[0].canbedisputed)
          };
        }
      );

      item._code = item._abbriviated_name;
      item._maximum_number_members = context.$options.filters.parseInteger(
        item._maximum_number_members
      );

      if (item._maximum_number_members.toString().trim().length === 0)
        item._maximum_number_members = 0;

      item._maximum_number_vehicles = context.$options.filters.parseInteger(
        item._maximum_number_vehicles
      );

      if (item._maximum_number_vehicles.toString().trim().length === 0)
        item._maximum_number_vehicles = 0;

      item._create_operator_account = context.$options.filters.parseBoolean(
        item._create_operator_account
      );
      item._create_driver_account = context.$options.filters.parseBoolean(
        item._create_driver_account
      );
      item._create_vehicle_account = context.$options.filters.parseBoolean(
        item._create_vehicle_account
      );
      item._addedby = addedby;
      item._contact_person_name =
        context.contactdetails_first_name +
        " " +
        context.contactdetails_surname;

      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          $(".sw-btn-next")
            .text("")
            .append('<i class="fas fa-spinner fa-spin"></i>')
            .attr("disabled", "disabled");

          value.router = value.context.$router;
          value.AddModel = value.item;
          value.context.$store
            .dispatch("TA/add", {
              _dataObj: value
            })
            .then(() => {
              $(".sw-btn-next")
                .text("Done")
                .attr("disabled", "disabled");
              $(".sw-btn-prev").attr("disabled", "disabled");
              $("#wizard").data("taxiassociationadded", true);
              $("#wizard").smartWizard("next");
              value.context.$Notify.Success("Successfully Added!", "");
            })
            .catch(error => {
              $(".sw-btn-next")
                .text("Submit")
                .removeAttr("disabled");
              console.log("adding association errors: ", error);
              value.context.$Notify.Error(error.data[0].message);
            })
            .finally(() => {});
        }
      });
    }
  },
  mounted: function() {
    //this.get(this.context)

    this.loadLookUps(this.context);

    $("#selectPhysicalSuburb").select2({
      multiple: false,
      placeholder: "Select a suburb",
      allowClear: false,
    });

    $("#selectPostalSuburb").select2({
      multiple: false,
      placeholder: "Select a suburb",
      allowClear: false,
    });

    this.ADDONSERVICETYPES = [];

    $(".btnadded")
      .mouseenter(function() {
        $(this)
          .removeClass("btn-success")
          .addClass("btn-danger")
          .text("Remove");
      })
      .mouseleave(function() {
        $(this)
          .removeClass("btn-danger")
          .addClass("btn-success")
          .text("Added");
      });

    App.init();

    

    $("#default_rangeSlider").ionRangeSlider({
      min: 0,
      max: 30,
      type: "single",
      postfix: " Days",
      maxPostfix: "+",
      prettify: false,
      hasGrid: true
    });

    $(".datepicker-disabled-past").datepicker({
      todayHighlight: true,
      autoclose: true
    });

    //$('.selectpicker1').selectpicker('render');
    //FormWizardValidation.init();

    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "",
      transitionSpeed: 0,
      backButtonSupport: false,
      keyNavigation: false,
      useURLhash: false,
      showStepURLhash: false,
      backButtonSupport: false,
      toolbarSettings: {
        toolbarPosition: "bottom"
      }
    });
    $("#wizard").data("context", this.context);
    $("#wizard").on("leaveStep", function(
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {
      const context = $("#wizard").data("context");
      const currentstep = stepNumber + 1;
      const res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

      $(".sw-btn-next").text("Next");

      if (stepDirection == "backward") return true;

      if (currentstep === 1 && stepDirection == "forward") {
        const addonserviceerror = context.ADDONSERVICETYPES.length !== 0;

        if (!addonserviceerror)
          $(".addonserviceerror")
            .addClass("show")
            .removeClass("hide");

        return addonserviceerror;
      }
      if (currentstep === 2 && stepDirection == "forward") {
        const routebilling = context.billitemsadded.length >= 0;

        if (!routebilling) {
          $(".routebillingerror")
            .addClass("show")
            .removeClass("hide");
        } else {
          $(".sw-btn-next").text("Submit");
        }

        return routebilling;
      }

      if (currentstep === 3 && stepDirection == "forward") {

        const context = $("#wizard").data("context");
        if(context.contactdetails_first_name == undefined && context.contactdetails_surname == undefined && ADDTAXIASSOCIATIONS._contact_person_number == "") {
          value.context.$Notify.Error("Please Enter contact details!");
          $(".sw-btn-next").text("Submit");
        } else {

          const taxiassociationadded = $("#wizard").data(
          "taxiassociationadded"
        );
        if (!taxiassociationadded) {
          context.add(
            context,
            context.ADDTAXIASSOCIATIONS,
            context.SYSTEMUSERID
          );
        }
        return taxiassociationadded;

        }
      }

      return res;
    });

    //     $(function(){
    //    $(".dropdown-menu").on('click', 'a', function(){
    //        $(this).parents('.dropdown').find('button').text($(this).text());
    //    })
    // });

    $(".selectpicker").select2();
    $("#wizard").data("taxiassociationadded", false);

    $(".data-table").DataTable({
      responsive: false,
      paging: false
    });
  },
  Updated: function() {}
};
</script>


<style scoped>
</style>

