<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp :USERPROFILE="USERPROFILE"/>
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Search Results"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Search"
        breadcrumbitem3="Full"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

    	<!-- begin row -->
			<div class="row">
				<!-- begin col-12 -->
				<div class="col-md-12">
					<!-- begin result-container -->
					<div class="result-container">
						<!-- begin input-group -->
						<div class="input-group input-group-lg m-b-20">
							<input type="text" v-on:keydown.enter.prevent='onSeachclick' v-model="searchText" class="form-control input-white" placeholder="Enter keywords here..." />
							<div class="input-group-append">
								<button type="button" @click="onSeachclick" class="btn btn-primary"><i class="fa fa-search fa-fw"></i>
               Search
                </button>
							</div>
						</div>
						<!-- end input-group -->
					
<!-- begin nav-pills -->
					<ul class="nav nav-pills">
						<li class="nav-items">
							<a @click="onEntityclick" href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active">
								<span class="d-sm-none">Taxis</span>
								<span class="d-sm-block d-none">Taxis</span>
							</a>
						</li>
						<li class="nav-items">
							<a @click="onEntityclick" href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
								<span class="d-sm-none">Drivers</span>
								<span class="d-sm-block d-none">Drivers</span>
							</a>
						</li>
						<li class="nav-items">
							<a @click="onEntityclick" href="#nav-searchoperator" data-toggle="tab" class="nav-link">
								<span class="d-sm-none">Operators</span>
								<span class="d-sm-block d-none">Operators</span>
							</a>
						</li>
					</ul>
					<!-- end nav-pills -->
					<!-- begin tab-content -->
					<div class="tab-content">
						<!-- begin tab-pane -->
						<div class="tab-pane fade active show" id="nav-pills-tab-1">
							<p>
							<!-- begin result-list -->
						<ul class="result-list">
							<li>
								<a href="#" class="result-image" style="background-image: url(../assets/img/gallery/gallery-52.jpg)"></a>
								<div class="result-info">
									<h4 class="title"><a href="javascript:;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</a></h4>
									<p class="location">United State, BY 10089</p>
									<p class="desc">
										Nunc et ornare ligula. Aenean commodo lectus turpis, eu laoreet risus lobortis quis. Suspendisse vehicula mollis magna vel aliquet. Donec ac tempor neque, convallis euismod mauris. Integer dictum dictum ipsum quis viverra.
									</p>
									<div class="btn-row">
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Analytics"><i class="fa fa-fw fa-chart-bar"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Tasks"><i class="fa fa-fw fa-tasks"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Configuration"><i class="fa fa-fw fa-cog"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Performance"><i class="fa fa-fw fa-tachometer-alt"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Users"><i class="fa fa-fw fa-user"></i></a>
									</div>
								</div>
								<div class="result-price">
									$102,232 <small>PER MONTH</small>
									<a href="javascript:;" class="btn btn-yellow btn-block">View Details</a>
								</div>
							</li>
							<li>
								<a href="#" class="result-image" style="background-image: url(../assets/img/gallery/gallery-53.jpg)"></a>
								<div class="result-info">
									<h4 class="title"><a href="javascript:;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</a></h4>
									<p class="location">United State, BY 10089</p>
									<p class="desc">
										Nunc et ornare ligula. Aenean commodo lectus turpis, eu laoreet risus lobortis quis. Suspendisse vehicula mollis magna vel aliquet. Donec ac tempor neque, convallis euismod mauris. Integer dictum dictum ipsum quis viverra.
									</p>
									<div class="btn-row">
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Analytics"><i class="fa fa-fw fa-chart-bar"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Tasks"><i class="fa fa-fw fa-tasks"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Configuration"><i class="fa fa-fw fa-cog"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Performance"><i class="fa fa-fw fa-tachometer-alt"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Users"><i class="fa fa-fw fa-user"></i></a>
									</div>
								</div>
								<div class="result-price">
									$183,921 <small>PER MONTH</small>
									<a href="javascript:;" class="btn btn-yellow btn-block">View Details</a>
								</div>
							</li>
							<li>
								<a href="#" class="result-image" style="background-image: url(../assets/img/gallery/gallery-54.jpg)"></a>
								<div class="result-info">
									<h4 class="title"><a href="javascript:;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</a></h4>
									<p class="location">United State, BY 10089</p>
									<p class="desc">
										Nunc et ornare ligula. Aenean commodo lectus turpis, eu laoreet risus lobortis quis. Suspendisse vehicula mollis magna vel aliquet. Donec ac tempor neque, convallis euismod mauris. Integer dictum dictum ipsum quis viverra.
									</p>
									<div class="btn-row">
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Analytics"><i class="fa fa-fw fa-chart-bar"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Tasks"><i class="fa fa-fw fa-tasks"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Configuration"><i class="fa fa-fw fa-cog"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Performance"><i class="fa fa-fw fa-tachometer-alt"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Users"><i class="fa fa-fw fa-user"></i></a>
									</div>
								</div>
								<div class="result-price">
									$82,991 <small>PER MONTH</small>
									<a href="javascript:;" class="btn btn-yellow btn-block">View Details</a>
								</div>
							</li>
							<li>
								<a href="#" class="result-image" style="background-image: url(../assets/img/gallery/gallery-55.jpg)"></a>
								<div class="result-info">
									<h4 class="title"><a href="javascript:;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</a></h4>
									<p class="location">United State, BY 10089</p>
									<p class="desc">
										Nunc et ornare ligula. Aenean commodo lectus turpis, eu laoreet risus lobortis quis. Suspendisse vehicula mollis magna vel aliquet. Donec ac tempor neque, convallis euismod mauris. Integer dictum dictum ipsum quis viverra.
									</p>
									<div class="btn-row">
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Analytics"><i class="fa fa-fw fa-chart-bar"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Tasks"><i class="fa fa-fw fa-tasks"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Configuration"><i class="fa fa-fw fa-cog"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Performance"><i class="fa fa-fw fa-tachometer-alt"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Users"><i class="fa fa-fw fa-user"></i></a>
									</div>
								</div>
								<div class="result-price">
									$422,999 <small>PER MONTH</small>
									<a href="javascript:;" class="btn btn-yellow btn-block">View Details</a>
								</div>
							</li>
							<li>
								<a href="#" class="result-image" style="background-image: url(../assets/img/gallery/gallery-56.jpg)"></a>
								<div class="result-info">
									<h4 class="title"><a href="javascript:;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</a></h4>
									<p class="location">United State, BY 10089</p>
									<p class="desc">
										Nunc et ornare ligula. Aenean commodo lectus turpis, eu laoreet risus lobortis quis. Suspendisse vehicula mollis magna vel aliquet. Donec ac tempor neque, convallis euismod mauris. Integer dictum dictum ipsum quis viverra.
									</p>
									<div class="btn-row">
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Analytics"><i class="fa fa-fw fa-chart-bar"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Tasks"><i class="fa fa-fw fa-tasks"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Configuration"><i class="fa fa-fw fa-cog"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Performance"><i class="fa fa-fw fa-tachometer-alt"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Users"><i class="fa fa-fw fa-user"></i></a>
									</div>
								</div>
								<div class="result-price">
									$891,872 <small>PER MONTH</small>
									<a href="javascript:;" class="btn btn-yellow btn-block">View Details</a>
								</div>
							</li>
						</ul>
						<!-- end result-list -->
						<!-- begin pagination -->
						<div class="clearfix m-t-20">
							<ul class="pagination pull-right">
								<li class="disabled"><a href="javascript:;" class="page-link">«</a></li>
								<li class="active"><a href="javascript:;" class="page-link">1</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">2</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">3</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">4</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">5</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">6</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">7</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">»</a></li>
							</ul>
						</div>
						<!-- end pagination -->
							</p>
						</div>
						<!-- end tab-pane -->
						<!-- begin tab-pane -->
						<div class="tab-pane fade" id="nav-pills-tab-2">
								<p>
							<!-- begin result-list -->
						<ul class="result-list">
							<li>
								<a href="#" class="result-image" style="background-image: url(../assets/img/gallery/gallery-51.jpg)"></a>
								<div class="result-info">
									<h4 class="title"><a href="javascript:;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</a></h4>
									<p class="location">United State, BY 10089</p>
									<p class="desc">
										Nunc et ornare ligula. Aenean commodo lectus turpis, eu laoreet risus lobortis quis. Suspendisse vehicula mollis magna vel aliquet. Donec ac tempor neque, convallis euismod mauris. Integer dictum dictum ipsum quis viverra.
									</p>
									<div class="btn-row">
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Analytics"><i class="fa fa-fw fa-chart-bar"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Tasks"><i class="fa fa-fw fa-tasks"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Configuration"><i class="fa fa-fw fa-cog"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Performance"><i class="fa fa-fw fa-tachometer-alt"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Users"><i class="fa fa-fw fa-user"></i></a>
									</div>
								</div>
								<div class="result-price">
									$92,101 <small>PER MONTH</small>
									<a href="javascript:;" class="btn btn-yellow btn-block">View Details</a>
								</div>
							</li>
							<li>
								<a href="#" class="result-image" style="background-image: url(../assets/img/gallery/gallery-52.jpg)"></a>
								<div class="result-info">
									<h4 class="title"><a href="javascript:;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</a></h4>
									<p class="location">United State, BY 10089</p>
									<p class="desc">
										Nunc et ornare ligula. Aenean commodo lectus turpis, eu laoreet risus lobortis quis. Suspendisse vehicula mollis magna vel aliquet. Donec ac tempor neque, convallis euismod mauris. Integer dictum dictum ipsum quis viverra.
									</p>
									<div class="btn-row">
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Analytics"><i class="fa fa-fw fa-chart-bar"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Tasks"><i class="fa fa-fw fa-tasks"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Configuration"><i class="fa fa-fw fa-cog"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Performance"><i class="fa fa-fw fa-tachometer-alt"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Users"><i class="fa fa-fw fa-user"></i></a>
									</div>
								</div>
								<div class="result-price">
									$102,232 <small>PER MONTH</small>
									<a href="javascript:;" class="btn btn-yellow btn-block">View Details</a>
								</div>
							</li>
							<li>
								<a href="#" class="result-image" style="background-image: url(../assets/img/gallery/gallery-53.jpg)"></a>
								<div class="result-info">
									<h4 class="title"><a href="javascript:;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</a></h4>
									<p class="location">United State, BY 10089</p>
									<p class="desc">
										Nunc et ornare ligula. Aenean commodo lectus turpis, eu laoreet risus lobortis quis. Suspendisse vehicula mollis magna vel aliquet. Donec ac tempor neque, convallis euismod mauris. Integer dictum dictum ipsum quis viverra.
									</p>
									<div class="btn-row">
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Analytics"><i class="fa fa-fw fa-chart-bar"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Tasks"><i class="fa fa-fw fa-tasks"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Configuration"><i class="fa fa-fw fa-cog"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Performance"><i class="fa fa-fw fa-tachometer-alt"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Users"><i class="fa fa-fw fa-user"></i></a>
									</div>
								</div>
								<div class="result-price">
									$183,921 <small>PER MONTH</small>
									<a href="javascript:;" class="btn btn-yellow btn-block">View Details</a>
								</div>
							</li>
							<li>
								<a href="#" class="result-image" style="background-image: url(../assets/img/gallery/gallery-54.jpg)"></a>
								<div class="result-info">
									<h4 class="title"><a href="javascript:;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</a></h4>
									<p class="location">United State, BY 10089</p>
									<p class="desc">
										Nunc et ornare ligula. Aenean commodo lectus turpis, eu laoreet risus lobortis quis. Suspendisse vehicula mollis magna vel aliquet. Donec ac tempor neque, convallis euismod mauris. Integer dictum dictum ipsum quis viverra.
									</p>
									<div class="btn-row">
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Analytics"><i class="fa fa-fw fa-chart-bar"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Tasks"><i class="fa fa-fw fa-tasks"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Configuration"><i class="fa fa-fw fa-cog"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Performance"><i class="fa fa-fw fa-tachometer-alt"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Users"><i class="fa fa-fw fa-user"></i></a>
									</div>
								</div>
								<div class="result-price">
									$82,991 <small>PER MONTH</small>
									<a href="javascript:;" class="btn btn-yellow btn-block">View Details</a>
								</div>
							</li>
							<li>
								<a href="#" class="result-image" style="background-image: url(../assets/img/gallery/gallery-55.jpg)"></a>
								<div class="result-info">
									<h4 class="title"><a href="javascript:;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</a></h4>
									<p class="location">United State, BY 10089</p>
									<p class="desc">
										Nunc et ornare ligula. Aenean commodo lectus turpis, eu laoreet risus lobortis quis. Suspendisse vehicula mollis magna vel aliquet. Donec ac tempor neque, convallis euismod mauris. Integer dictum dictum ipsum quis viverra.
									</p>
									<div class="btn-row">
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Analytics"><i class="fa fa-fw fa-chart-bar"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Tasks"><i class="fa fa-fw fa-tasks"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Configuration"><i class="fa fa-fw fa-cog"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Performance"><i class="fa fa-fw fa-tachometer-alt"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Users"><i class="fa fa-fw fa-user"></i></a>
									</div>
								</div>
								<div class="result-price">
									$422,999 <small>PER MONTH</small>
									<a href="javascript:;" class="btn btn-yellow btn-block">View Details</a>
								</div>
							</li>
							<li>
								<a href="#" class="result-image" style="background-image: url(../assets/img/gallery/gallery-56.jpg)"></a>
								<div class="result-info">
									<h4 class="title"><a href="javascript:;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</a></h4>
									<p class="location">United State, BY 10089</p>
									<p class="desc">
										Nunc et ornare ligula. Aenean commodo lectus turpis, eu laoreet risus lobortis quis. Suspendisse vehicula mollis magna vel aliquet. Donec ac tempor neque, convallis euismod mauris. Integer dictum dictum ipsum quis viverra.
									</p>
									<div class="btn-row">
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Analytics"><i class="fa fa-fw fa-chart-bar"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Tasks"><i class="fa fa-fw fa-tasks"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Configuration"><i class="fa fa-fw fa-cog"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Performance"><i class="fa fa-fw fa-tachometer-alt"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Users"><i class="fa fa-fw fa-user"></i></a>
									</div>
								</div>
								<div class="result-price">
									$891,872 <small>PER MONTH</small>
									<a href="javascript:;" class="btn btn-yellow btn-block">View Details</a>
								</div>
							</li>
						</ul>
						<!-- end result-list -->
						<!-- begin pagination -->
						<div class="clearfix m-t-20">
							<ul class="pagination pull-right">
								<li class="disabled"><a href="javascript:;" class="page-link">«</a></li>
								<li class="active"><a href="javascript:;" class="page-link">1</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">2</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">3</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">4</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">5</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">6</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">7</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">»</a></li>
							</ul>
						</div>
						<!-- end pagination -->
							</p>
						</div>
						<!-- end tab-pane -->
						<!-- begin tab-pane -->
						<div class="tab-pane fade" id="nav-searchoperator">
								<p>
							<!-- begin result-list -->
						<ul class="result-list">
							<li v-for="(item, index) in GETOPERATORS"
                    v-bind:key="item.taxi_operator_id">
								<a href="#" class="result-image" style="background-image: url(../assets/img/gallery/gallery-51.jpg)"></a>
								<div class="result-info">
									<h4 class="title"><a href="javascript:;">{{item.firstname}}</a></h4>
									<p class="location">United State, BY 10089</p>
									<p class="desc">
										Nunc et ornare ligula. Aenean commodo lectus turpis, eu laoreet risus lobortis quis. Suspendisse vehicula mollis magna vel aliquet. Donec ac tempor neque, convallis euismod mauris. Integer dictum dictum ipsum quis viverra.
									</p>
									<div class="btn-row">
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Analytics"><i class="fa fa-fw fa-chart-bar"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Tasks"><i class="fa fa-fw fa-tasks"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Configuration"><i class="fa fa-fw fa-cog"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Performance"><i class="fa fa-fw fa-tachometer-alt"></i></a>
										<a href="javascript:;" data-toggle="tooltip" data-container="body" data-title="Users"><i class="fa fa-fw fa-user"></i></a>
									</div>
								</div>
								<div class="result-price">
									$92,101 <small>PER MONTH</small>
									<a href="javascript:;" class="btn btn-yellow btn-block">View Details</a>
								</div>
							</li>
						</ul>
						<!-- end result-list -->
						<!-- begin pagination -->
						<div class="clearfix m-t-20">
							<ul class="pagination pull-right">
								<li class="disabled"><a href="javascript:;" class="page-link">«</a></li>
								<li class="active"><a href="javascript:;" class="page-link">1</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">2</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">3</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">4</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">5</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">6</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">7</a></li>
								<li class="page-item"><a href="javascript:;" class="page-link">»</a></li>
							</ul>
						</div>
						<!-- end pagination -->
							</p>
						</div>
						<!-- end tab-pane -->
						
					</div>
					<!-- end tab-content -->

					</div>
					<!-- end result-container -->
				</div>
				<!-- end col-12 -->

			</div>
			<!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller/>
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import headerComp from "@/components/common/header.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import pageloader from "@/components/common/pageloader.vue";
import { createNamespacedHelpers } from "vuex";
import { setTimeout } from "timers";
const { mapGetters, mapActions } = createNamespacedHelpers("search");
export default {
  name: "ManageUserGroups",
  data: function() {
    return {
      searchText: '',
      searchEntityfocused: 'Taxi',
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      selectedTitleId: undefined,
      selectedGenderId: undefined,
      selectedUserGroupId: undefined
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["GETOPERATORS", "GETDRIVERS", "GETTAXIS"]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted"
    ]),
    onEntityclick: function(e) {
    },
    onSeachclick: function(e) {
      
      
          this.$store
            .dispatch("search/searchOperator", {
              _dataObj: {
                router: this.$router,
                id: this.searchText,
              },
            })
            .then(() => {
              this.context.$wait.start("searching");
            })
            .catch(error => {
              // value.context.$Notify.Error(error.data[0].message, '');
            })
            .finally(() => {
              this.context.$wait.end("searching");
            });
    },
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    clearAdded: item => {
      for (var propName in item) {
        
        item[propName] = undefined;
      }
    },
    add: (context, item, addedby) => {
      item._is_active = true;
      item._added_by = addedby;
      item._addedby_name = context.LoggedInUserFullName;
      item._fk_taxi_association_id = context.taxi_association_id;
      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("systemusers/add", {
              _dataObj: value
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");
              value.context.$Notify.Success("Successfully Added!", "");
            })
            .catch(error => {
              value.context.$wait.end("add");
              
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    setTitle(val) {
      
      this.selectedTitleId = val;
    },
    setGender(val) {
      
      this.selectedGenderId = val;
    },
    setUserGroup(val) {
      
      this.selectedUserGroupId = val;
    },
    setAdd(val) {
      
      this.$store
        .dispatch("systemusers/add", {
          _dataObj: val
        })
        .then(() => {});
    },
    getByTaxiAssId: context => {
      context.$store
        .dispatch("systemusers/getByTaxiAssId", {
          _dataObj: {
            router: context.$router,
            id: context.taxi_association_id
          }
        })
        .then(() => {
          App.init();
          TableManageCombine.init();
          Highlight.init();
        });
    },
    getById: (context, id) => {
      context.$store
        .dispatch("systemusers/getById", {
          _dataObj: {
            router: context.$router,
            id
          }
        })
        .then(() => {
          App.init();
          TableManageCombine.init();
          Highlight.init();
        });
    },
    get: context => {
      if (context.isSystemAdmin) context.getall(context);
      else context.getByTaxiAssId(context);
    },
    getall: context => {
      context.$store
        .dispatch("systemusers/getall", {
          _dataObj: {
            router: context.$router
          }
        })
        .then(() => {
          App.init();
          TableManageCombine.init();
          Highlight.init();
        });
    },
    edited: (context, item, updatedby) => {
      //item = Object.assign({}, item);
      item._fk_taxi_association_id = context.taxi_association_id;
      context.$Notify.YesNo(false, { context, item, updatedby }).then(value => {
        if (value) {
          
          value.context.$wait.start("edited");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("systemusers/update", {
              _dataObj: value
            })
            .then(() => {
              $("#EditModal").modal("hide");
              value.context.$wait.end("edited");
              value.context.$Notify.Success("Successfully Updated!", "");
            })
            .catch(error => {
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {
              value.context.$wait.end("edited");
            });
        }
      });
    },
    deletetitle: (context, item, deletedby) => {
      context.$Notify
        .YesNo(false, { context, id: item._id, deletedby })
        .then(value => {
          if (value) {
            value.context.$wait.start("delete-" + value.id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("systemusers/markAsdelete", {
                _dataObj: value
              })
              .then(id => {
                value.context.$wait.end("delete-" + id);
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch(error => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    restoredeletetitle: (context, item, restoredby) => {
      context.$Notify
        .YesNo(false, { context, id: item._id, restoredby })
        .then(value => {
          if (value) {
            value.context.$wait.start("restoreDeleted-" + value.id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("systemusers/restoreDeleted", {
                _dataObj: value
              })
              .then(id => {
                value.context.$wait.end("restoreDeleted-" + id);
                value.context.$Notify.Success("Successfully Restored!", "");
              })
              .catch(error => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    onclick: function() {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(handleSuccess)
        .catch(handleError);
    },
    videoonclick: function() {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext("2d").drawImage(video, 0, 0);
      // Other browsers will fall back to image/png
      img.src = canvas.toDataURL("image/webp");
    },
    handleSuccess(stream) {
      screenshotButton.disabled = false;
      video.srcObject = stream;
    }
  },
  mounted: function() {
    
    //this.get(this.context);
    App.init();
    //$('.selectpicker1').selectpicker('render');
    FormWizardValidation.init();
    $("#masked-input-phone").mask("(082) 686-9833");
    $("#masked-input-tid").mask("99999999999999");
    function update(stream) {
      document.querySelector("video").src = stream.url;
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
