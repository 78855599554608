/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */

// options...
export default {
  // options...
  

  //BASE_URL: () => process.env.WEBSERVICEBASEURL || 'https://mobifytracker.ngrok.io/api/v1',
  // BASESOCKET_URL: () => process.env.WEBSERVICEBASESOCKETURL || 'https://tams-api.herokuapp.com/',
  //BASEFIN_URL: () => process.env.WEBSERVICEBASEFINURL || 'https://mobifytracker.ngrok.io/api/v1',

  BASE_URL: () => process.env.WEBSERVICEBASEURL || 'https://tamsapi.taxiassociations.co.za/api/v1',
  BASESOCKET_URL: () => process.env.WEBSERVICEBASESOCKETURL || 'https://tamsapi.taxiassociations.co.za/api/v1',
  BASEFIN_URL: () => process.env.WEBSERVICEBASEFINURL || 'https://tamsfinapi.taxiassociations.co.za/api/v1',

  TPAYBASE_URL: () => process.env.TPAYBASE_URL || 'https://mainapi.bookaseat.africa/api/v1',
  TPAYBASEFIN_URL: () => process.env.TPAYBASEFIN_URL || 'https://finapi.bookaseat.africa/api/v1',

  //BASE_HEROKU_URL: () => process.env.TPAYBASE_URL || 'https://tams-api.herokuapp.com/api/v1',
  BASE_HEROKU_URL: () => process.env.TPAYBASE_URL || 'https://mobifytracker.ngrok.io/api/v1',


  TAMSFINTECHBASE_URL: () => process.env.TAMSFINTECHBASE_URL || 'https://tamsfintechapi-bc6zqxje4a-uc.a.run.app/api/v1',
  TTAMSFINTECHFIN_URL: () => process.env.TTAMSFINTECHFIN_URL || 'https://tamsfintechapi-bc6zqxje4a-uc.a.run.app/api/v1',
  DIALER_URL: () => process.env.DIALER_URL || 'https://dialer.mobifytracker.co.za/agc/api.php?source=test&user=apiuser&pass=Ocvbcz6NYHYKMObSMA',

  // TAMSFINTECHBASE_URL: () => process.env.TAMSFINTECHBASE_URL || 'https://89de-41-169-160-57.ngrok.io/api/v1',
  // TTAMSFINTECHFIN_URL: () => process.env.TTAMSFINTECHFIN_URL || 'https://89de-41-169-160-57.ngrok.io/api/v1',


  //TPAYBASE_URL: () => process.env.TPAYBASE_URL || 'https://d7ce-41-169-160-57.ngrok.io/api/v1',
  //TPAYBASEFIN_URL: () => process.env.TPAYBASEFIN_URL || 'https://f725-41-169-160-57.ngrok.io/api/v1',

  
  //BASE_URL: () => process.env.WEBSERVICEBASEURL || 'https://2c96-41-145-193-234.ngrok.io/api/v1',
  //BASEFIN_URL: () => process.env.WEBSERVICEBASEFINURL || 'https://dfa1-45-222-11-220.ngrok.io/api/v1',
  // BASESOCKET_URL: () => process.env.WEBSERVICEBASESOCKETURL || 'https://tamsapi-bc6zqxje4a-uc.a.run.app/',

  //BASE_URL: () => process.env.WEBSERVICEBASEURL || 'http://localhost:3001/api/v1',
  // BASESOCKET_URL: () => process.env.WEBSERVICEBASESOCKETURL || 'http://localhost:3001/',
  //BASEFIN_URL: () => process.env.WEBSERVICEBASEFINURL || 'http://localhost:3002/api/v1',

  //BASE_URL: () => process.env.WEBSERVICEBASEURL || 'https://81fb-45-222-9-23.ngrok.io/api/v1',
  //BASEFIN_URL: () => process.env.WEBSERVICEBASEFINURL || 'https://16e6-41-150-32-12.ngrok.io/api/v1',
   

  TA_URL: 'taxiassociations',
  TA_REGION_URL: 'taxiassociations/region/{id}',
  PROFILEPICTUREURL: 'systemusers/ProfilePic',
  TA_BILLING_URL: 'taxiassociations/billing',
  TITLE_URL: 'title',
  ROLEBYUSERID_URL: 'role/userid',
  INVOICES_URL: 'invoices',
  PAYMENTS_URL: 'receipts',
  INVOICEDISPUTES_URL: 'invoices/invoicedispute',
  INVOICEDISPUTESMEDIADOWNLOAD_URL: 'invoices/getUploadMediaData',
  GETDRIVERINVOICEDISPUTES_URL: 'invoices/getInvoiceDisputesByDriverId',
  RECEIPTDISPUTES_URL: 'receipts/receiptdispute',
  RECEIPTDISPUTESMEDIADOWNLOAD_URL: 'receipts/getUploadMediaData',
  GETDRIVERRECEIPTDISPUTES_URL: 'receipts/getReceiptDisputesByDriverId',
  GETRECEIPTSHISTORY_URL: 'receipts/ReceiptsByDateWeb/{taxiassociationsId}/{startDate}/{endDate}/{skip}/{length}',
  GETBILLINPAYMENTSGHISTORY_URL: 'receipts/ReceiptsByBillPaymentsIDByDate/{taxiassociationsId}/{startDate}/{endDate}/{skip}/{length}',
  GETRECEIPTBYCOLLECTORIDSHISTORY_URL: 'receipts/ReceiptsByCollectorIDByDate/{taxiassociationsId}/{collectorid}/{startDate}/{endDate}/{inprogress}',
  PAYMENTSCUSTOM_URL: 'receipts/custom',
  PAYMENTREFUNDSCUSTOM_URL: 'receipts/refund',
  PAYMENTBULKUPLOADSBYID_URL: 'receipts/getUploadedFilebyId',
  PAYMENTVEHICLEBULK_URL: 'receipts/customVehicleBulk',
  INVOICESCUSTOM_URL: 'invoices/custom',
  TRANSACTIONSDRIVERS: 'taxidriver/TransactionsByDate/{taxiassociationsId}/{taxioperatorId}/{startDate}/{endDate}',
  TRANSACTIONSVEHICLES: 'taxioperatorvehicles/TransactionsByDate/{taxiassociationsId}/{taxioperatorId}/{startDate}/{endDate}',
  TRANSACTIONSOPERATOR: 'taxioperator/TransactionsByDate/{taxiassociationsId}/{taxioperatorId}/{startDate}/{endDate}',
  ASSIGNDRIVER_URL: 'taxidriver/assignDriver',
  BILLINGITEMS_URL: 'billingitems',
  GENDER_URL: 'gender',
  USERGROUPS_URL: 'usergroups',
  USERGROUPSBYTAXIASSID_URL: 'usergroups/userGroupsByAssId/{taxiassociationsId}/{userid}',
  FUELTYPESBYTAXIASSID_URL: 'fueltypes/FuelTypesByAssId/{taxiassociationsId}/{userid}',
  FUELMANAGERDELETE_URL: 'fueltypes/FuelTransDelete/{transid}/{deletedby}',
  DOCUMENTYPE_URL: 'documenttype',
  EMAILS_URL: 'messageinbox',
  DELETEEMAILS_URL: 'messageinbox/messageInboxDelete',
  EMAILSINBOX_URL: 'messageinbox/getUserInbox',
  EMAILSDOWNLOAD_URL: 'messageinbox/downloadfile',
  SENTEMAILS_URL: 'messageinbox/getUserSentMessages',
  SEARCHSYSTEMUSERS_URL: 'messageinbox/getSystemUsersbyTextSearch',
  READEMAIL_URL: 'messageinbox/markAsRead',
  EMAILREPLY_URL: 'messageinbox/messageInboxReply',
  EMAILSINBOXTOTALUNREAD_URL: 'messageinbox/getTotalUnread',
  GETEMAILBYID_URL: 'messageinbox/getMessageInboxbyId',
  GETDELETEDEMAILSBYID_URL: 'messageinbox/getDeletedInboxMessagebyId',
  SUBURBS_URL: 'suburb',
  DISTRICT_URL: 'district',
  PHYSICALADDRESS_URL: 'physicaladdress',
  POSTALADDRESS_URL: 'postaladdress',
  SYSTEMUSERS_URL: 'systemusers',
  SYSTEMUSERSOTHER_URL: 'systemusers/other',
  SYSTEMUSERSGETBYID_URL: 'systemusers/getSystemUsersById',
  SYSTEMUSERSGETBYTAXIASSIBYID_URL: 'systemusers/TaxiAssId/{id}/{userid}',
  SYSTEMUSERSUPDATEPASSWORD_URL: 'systemusers/updateUserPassword',
  SYSTEMUSERSUPDATEGENERATEDPASSWORD_URL: 'systemusers/updateUserGeneratedPassword',
  SYSTEMUSERSFORGOTPASSWORD_URL: 'systemusers/forgotPassword',
  SYSTEMROLESBYASSOCIATION_URL: 'systemusers/getRolesByAssociation',
  ADDSYSTEMUSERROLES_URL: 'systemusers/adduserGroupRole',
  ACTIVATESYSTEMUSER_URL: 'systemusers/activateSystemUser',
  DEACTIVATESYSTEMUSER_URL: 'systemusers/deactivateSystemUser',
  REMOVESYSTEMUSERROLES_URL: 'systemusers/removeUserGroupRole',
  USERGROUPSBYASSOCIATION_URL: 'systemusers/getUserGroups',
  ROLEUSERGROUPSBYASSOCIATION_URL: 'systemusers/getRoleUserGroups',
  SYSTEMUSERSSEACHUSERNAME_URL: 'systemusers/SearchUserName',
  SYSTEMUSERSUSERGROUPS_URL: 'systemusers/getUserGroupsByAssociation',
  SYSTEMUSERSUPDATEUSERGROUP_URL: 'systemusers/updateUserGroup',
  SYSTEMUSERCAPTUREDBY_URL: 'systemusers/getCapuredByUserID',
  SYSTEMUSERVERIFYPIN_URL: 'systemusers/verifyOneTimePin',
  SYSTEMUSERUPDATE_URL: 'systemusers/UpdateSystemUser',
  FULLSYSTEMSEARCH_URL: 'systemusers/getEntityBySearch',
  SYSTEMUSERSUPDATEPICTURES_URL: 'systemusers/UpdateSystemUserPictures',
  ADDMERCHANTACCOUNT_URL: 'prepaidservices/addMerchantAccount',
  GETMERCHANTSBYASSID_URL: 'prepaidservices/merchantsByAssId',
  GETMERCHANTBYUSERID_URL: 'prepaidservices/merchantByUserId',
  GETTRANSACTIONTYPES_URL: 'prepaidservices/transactionTypes',
  RENEWLICENSE_ORDERS_GETALL_URL: 'onlineservices/GetLicenseDiskOrders',
  RENEWLICENSEDISK_GETQUOTE_URL: 'onlineservices/GetLicenseDiskQuote',
  RENEWLICENSEPLACEORDER_URL: 'onlineservices/GetLicenseDiskPlaceOrder/{id}',
  RENEWLICENSEDISK_MARKASDELIVERED_URL: 'onlineservices/MarkAsDeliveredLicenseRenewal',
  ADDMERCHANTTRANSACTION_URL: 'prepaidservices/addMerchantTransactionWeb',
  ADDMERCHANTCASHTRANSFERTRANSACTION_URL: 'prepaidservices/addMerchantTransactionCashTransferWeb',
  REVERSEMERCHANTCASHBACKTRANSACTION_URL: 'prepaidservices/addMerchantTransactionCashBackReverseWeb',
  GETMERCHANTTRANSACTIONS_URL: 'prepaidservices/getTransactionsByAgentIdWeb/{taxiassociationsId}/{agentid}/{startDate}/{endDate}',
  MERCHANTSTARTCASHUP_URL:'prepaidservices/cashupStart',
  MERCHANTENDCASHUP_URL:'prepaidservices/cashupEnd',
  MERCHANTPENDINGCASHUP_URL:'prepaidservices/pending',
  MERCHANTVERIFYCASHUP_URL: 'prepaidservices/verifyCashup',
  MERCHANTCASHUPPENDINGAPPROVAL_URL:'prepaidservices/pendingApproval',
  MERCHANTAPPROVEDCASHUP_URL:'prepaidservices/getapproved',
  MERCHANTAPPROVECASHUP_URL: 'prepaidservices/approveCashup',
  MERCHANTBLDBALANCEURL: 'prepaidservices/getAccountInfo',
  TAXIASSOCIATION_URL: 'taxiassociations',
  TAXIAFFILIATION_URL: 'taxiaffiliation',
  COUNTRY_URL: 'country', 
  FLEETMANAGEMENT_URL: 'fleetmanagement', 
  FLEETMANAGEMENUPDATECONTRACT_URL: 'fleetmanagement/UpdateContract', 
  FLEETMANAGEMENTBYTAXIASSID_URL: 'fleetmanagement/ByTaxiAssId',
  FLEETMANAGEMENTALLCLIENTSBYTAXIASSID_URL: 'fleetmanagement/AllClients/ByTaxiAssId',
  FLEETMANAGEMENTINVENTORYBYTAXIASSID_URL: 'fleetmanagement/AllInventory',
  FLEETMANAGEMENTINVENTORYADD_URL: 'fleetmanagement/AllInventory/Add',
  FLEETMANAGEMENTINVENTORYUPDATE_URL: 'fleetmanagement/AllInventory/Update',
  FLEETMANAGEMENTALLCLIENTSARREARSBYTAXIASSID_URL: 'fleetmanagement/AllClientsArrears/ByTaxiAssId',
  FLEETMANAGEMENTALLTRANSBYCLIENTID_URL: 'fleetmanagement/AllTransClientsID/ById',
  PROVINCE_URL: 'province',
  FUELTYPE_URL: 'fueltypes',
  CITY_URL: 'city',
  TO_URL: 'taxioperator',
  GETOPERATORADDMOBILENUMBERS_URL: 'taxioperator/AddMobileNumbers',
  GETOPERATORMOBILENUMBERS_URL: 'taxioperator/getMobileNumbersById',
  GETOPERATORSUPPRTINGDOCS_URL: 'taxioperator/getSupportingDocsById',
  GETOPERATORTIMELINE_URL: 'taxioperator/getTimelineHistoryById',
  GETOPERATORTSTATUS_URL: 'taxioperator/getOperatorStatusByAssId',
  GETOPERATORBILLINGS_URL: 'taxioperator/getOperatorBillingsById',
  GETOPERATORTRANSACTIONSBYID_URL: 'taxioperator/operatorTransactionsById',
  GETOPERATORTRAFFICFINESBYID_URL: 'taxioperator/operatortrafficFinesById',
  GETOPERATORTRANSACTIONS_URL: 'taxioperator/getTransactionsByAccNo',
  GETOPERATORINVOICEDISPUTES_URL: 'taxioperator/getOperatorInvoiceDisputesById',
  GETOPERATORINVOICEDISPUTES_URL: 'SMS/getOperatorInvoiceDisputesById',
  GETOPERATORRECEIPTSDISPUTES_URL: 'taxioperator/getOperatorReceiptsDisputesById',
  CHANGEOPERATORBILLING_URL: 'taxioperator/changeOperatorBillingType',
  CHANGEOPERATORSTATUS_URL: 'taxioperator/operatorStatusChangeRequest',
  GETOPERATORSBYASSID_URL: 'taxioperator/getOperatorsByAssId',
  GETOPERATORSBYASSIDSUMMARY_URL: 'taxioperator/getOperatorsByAssIdSummary',
  GETOPERATORVEHICLESBYID_URL: 'taxioperator/getOperatorVehiclesById',
  TOBYTAXIASSID_URL: 'taxioperator/ByTaxiAssId',
  TOBYTAXIASSIDNOPIC_URL: 'taxioperator/getOperatorsByAssIdNoPic',
  TOBYTAXIAFFILIATIONIDNOPIC_URL: 'taxioperator/getoperatorsbyAffilliationId',
  TOBYIDNOPIC_URL: 'taxioperator/getOperatorByIdNoPic',
  OPERATORSPENDINGVERIFICATION_URL: 'taxioperator/operatorsPendingVerification',
  OPERATORUPDATEREQUEST_URL: 'taxioperator/operatorRequestUpdate',
  OPERATORNOTIFICATIONSREQUEST_URL: 'taxioperator/operatorNotificationsRequest',
  GETOPERATORSBYACCOUNTNO_URL: 'taxioperator/getOperatorsByAccountNo',
  GETOPERATORSARREARS_URL: 'taxioperator/getArrearsByOperators/{taxiassociationsId}/{skip}/{length}',
  GETOPERATORSOFARREARS_URL: 'taxioperator/getArrearsOfOperators/{taxiassociationsId}/{skip}/{length}',
  GETDRIVEROFARREARS_URL: 'taxioperator/getArrearsOfDrivers/{taxiassociationsId}/{skip}/{length}',
  TAXIVEHICLE_URL: 'taxioperatorvehicles',
  OPERATORVEHICLE_URL: 'taxioperatorvehicles',
  GETTAXIVEHICLETRANSACTIONS_URL: 'taxioperatorvehicles/getTransactionsByAccNo',
  GETTAXIVEHICLEINVOICEDISPUTES_URL: 'taxioperatorvehicles/getVehicleInvoiceDisputes',
  GETTAXIVEHICLERECEIPTDISPUTES_URL: 'taxioperatorvehicles/getVehicleReceiptDisputes',
  GETTAXIVEHICLEARREARS_URL: 'taxioperatorvehicles/ArrearsByVehicle/{taxiassociationsId}/{skip}/{length}',
  SUBURB_URL: 'suburb',
  ENGINETYPE_URL:'vehicleengine',
  VEHICLEMODEL_URL: 'vehiclemodel',
  VEHICLEMAKE_URL: 'vehiclemake', 
  VEHICLECOLOR_URL: 'vehiclecolor',
  VEHICLETRANSMISION_URL: 'vehicletransmissiontype',
  TAXIASSOCIATIONROUTES_URL:'taxiassociationroutes',
  TAXIASSOCIATIONBILLINGTYPE_URL:'taxiassociationsbilling/billingtypes',
  TAXIASSOCIATIONADDONSERVICETYPE_URL:'taxiassociationsbilling/AddOnServiceTypes',
  TYPEOFBILLINGCLIENT:'typeofbillingclient',
  COLLECTIONSETTINGS:'collectionsettings',
  VEHICLEHISTORY_URL:'vehiclestimelinehistory',
  GETRECIEPTBYID_URL: 'transactions/getRecieptById',
  FILEUPLOAD_URL: 'fileupload',
  CAPTUREPHOTO_URL: 'capturephoto',
  TAXIDRIVER_URL: 'taxidriver',
  TAXIDRIVERBYASSOCIATIONBYID_URL: 'taxidriver/TaxiAssId',
  TAXIDRIVERBYASSOCIATION_URL: 'taxidriver/getTaxiDriversByAss',
  TAXIDRIVERPENDINGVERIFICATION_URL: 'taxidriver/driversPendingVerification',
  DOCUMENTDOWNLOAD_URL: 'taxidriver/downloadfile',
  GETDRIVERMOBILENUMBERS_URL: 'taxidriver/getMobileNumbersById',
  GETDRIVERTIMELINE_URL: 'taxidriver/getTimelineById',
  GETDRIVEREMPLOYMENTHISTORY_URL: 'taxidriver/getDriverEmploymentHistoryById',
  GETDRIVERACCOUNT_URL: 'taxidriver/getAccountDetailsById',
  GETDRIVERTRANSACTIONS_URL: 'taxidriver/getTransactionsByAccNo',
  DRIVERUPDATE_URL: 'taxidriver/driverUpdateRequest',
  DRIVERSTATUSUPDATE_URL: 'taxidriver/driverStatusChangeRequest',
  GETDRIVERSUPPORTINGDOCS_URL: 'taxidriver/getDriverSupportingDocsById',
  DRIVERSUPPORTINGDOCSADD_URL: 'taxidriver/driverSupportingDocsAdd',
  DRIVERCAPTUREPHOTOSADD_URL: 'taxidriver/driverCapturePhotosAdd',
  GETTERMINATION_URL: 'taxidriver/getReasonForTermination',
  GETDRIVERBILLINGS_URL: 'taxidriver/getDriverBillingsById',
  DRIVERTRANSACTIONSBYID_URL: 'taxidriver/transactionsById',
  CHANGEDRIVERBILLING_URL: 'taxidriver/changeDriverBillingType',
  GETDRIVERSBYAFFILIATION_URL: 'taxidriver/getTaxiDriversByAffiliation',
  GETDRIVERSBYASSIDNOPIC_URL: 'taxidriver/getDriversByAssIdNoPic',
  GETDRIVERBYIDNOPIC_URL: 'taxidriver/getDriverByIdNoPic',
  DOESDRIVEREXIST_URL: 'taxidriver/doesDriverExist',
  DRIVERSTATUS_URL:'driverstatus',
  DRIVERSTATUSBYASSOCIATION_URL:'taxidriver/getDriverStatusById',
  APPROVAL_URL: 'approval/ByUserId/{taxiassociationid}/{userid}',
  EXECUTEAPPROVAL_URL: 'approval/Approved',
  EXECUTEREJECT_URL:'approval/Rejected',
  APPROVED_URL: 'approval/getApproved/{taxiassociationid}/{userid}/{skip}/{length}',
  CALENDAREVENTS_URL: 'calendarevent',
  PENDING_URL: 'approval/Pending/{taxiassociationid}/{userid}/{skip}/{length}',
  EXECUTERESUBMITAPPROVAL_URL: 'approval/resubmit',
  REJECTED_URL: 'approval/getRejected/{taxiassociationid}/{userid}/{skip}/{length}',
  CAPUTREDDATA_URL: 'approval/capturedbyUserId',
  VEHICLEBYTAXIASSID_URL:'taxioperatorvehicles/TaxiAssId',
  SEARCHBYOPERATOR: 'taxioperator/AssociationTextSearch',
  OPERATOREXISTS_URL: 'taxioperator/searchOperator',
  SEARCHBYDRIVER: 'taxidriver/ByTextSearch',
  SEARCHBYTAXI: 'taxioperatorvehicles/ByTextSearch',
  VEHICLESUPPORTINGDOCSADD_URL: 'taxioperatorvehicles/vehicleSupportingDocsAdd', 
  OPERATORSUPPORTINGDOCSADD_URL: 'taxioperator/operatorSupportingDocsAdd', 
  VEHICLEOPERATOR_URL: 'taxioperatorvehicles/getByOperartorId',
  VEHICLETRANSEACTIONS_URL: 'taxioperatorvehicles',
  VEHICLETIMELINE_URL:'taxioperatorvehicles/getTimelineById', 
  VEHICLESEARCH_URL: 'taxioperatorvehicles/ByTextSearch/{taxiassociationsId}/{searchText}',
  VEHICLESUPPORTINGDOCSGET_URL:'taxioperatorvehicles/getVehicleDocsById',
  VEHICLESTATUSCHANGE_URL:'taxioperatorvehicles/changeVehicleStatusrequest',
  VEHICLEBILLINGCHANGEREQUEST_URL:'taxioperatorvehicles/changeVehicleBillingRequest',
  VEHICLESTATUS_URL: 'taxioperatorvehicles/getVehicleStatusByAssociationId',
  VEHICLESBYASSOCIATION_URL: 'taxioperatorvehicles/getvehiclesByTaxiAssId',
  VEHICLESBYASSOCIATIONSUMMARY_URL: 'taxioperatorvehicles/getvehiclesByTaxiAssIdSummary',
  VEHICLESBYASSOCIATIONEXPIREDLICENSES_URL: 'taxioperatorvehicles/getvehiclesByTaxiAssIdExpiredlicenses',
  VEHICLESUPDATELEFTRANK_URL: 'taxioperatorvehicles/mobifytrackervehicleupdateleftranknotification',
  VEHICLESBYASSOCIATIONCLEAN_URL: 'taxioperatorvehicles/getvehiclesByTaxiAssIdClean',
  VEHICLESBYASSIDDROPDOWN_URL: 'taxioperatorvehicles/vehiclesByAssIdDropdown',
  FINANCEPROVIDER_URL: 'taxioperatorvehicles/gettrackerproviderById',
  INSURANCEPROVIDER_URL: 'taxioperatorvehicles/gettrackerproviderById',
  TAXITRACKERPROVIDER_URL: 'taxioperatorvehicles/gettrackerproviderById',
  VEHICLECHANGEOFOWNER_URL:'taxioperatorvehicles/changeOwnershipRequest',
  VEHICLEGETBILLING_URL:'taxioperatorvehicles/getVehicleBillingById',
  VEHICLETRANSACTIONSBYID_URL:'taxioperatorvehicles/vehicleTransactionsById',
  GETVEHICLESBYAFFILIATION_URL:'taxioperatorvehicles/getTaxiVehiclesByAffilliationId',
  GETVEHICLESTAXIROUTESBYTAXIID_URL:'taxioperatorvehicles/ByTaxiRoutesByTaxiId',
  GETVEHICLEPICTURES_URL: 'taxioperatorvehicles/getTaxiVehiclePictures',
  VEHICLEIMPOUND_URL: 'taxioperatorvehicles/Impounded',
  EXCLUDETRIPTRACKER_URL: 'taxioperatorvehicles/ExcludeTripTracker',
  VEHICLERELEASE_URL: 'taxioperatorvehicles/Released',
  VEHICLEUPDATEREQUEST_URL: 'taxioperatorvehicles/VehicleUpdateRequest',
  DATACOLLECTIONBYASSOCIATION_URL: 'taxioperatorvehicles/dataCollectionByAssId',
  DATACOLLECTIONOPERATORIMAGE_URL: 'taxioperatorvehicles/dataCollectionOpeatorImageById',
  DATACOLLECTIONVEHICLEIMAGES_URL: 'taxioperatorvehicles/dataCollectionVehicleimagesbyid',
  DATACOLLECTIONDRIVERIMAGES_URL: 'taxioperatorvehicles/dataCollectionDriverimagesbyid',
  DATACOLLECTIONDELETE_URL: 'taxioperatorvehicles/datacollectionDelete',
  VEHICLEMODELBYMAKEID_URL: 'taxioperatorvehicles/vehicleModelByMakeId',
  VEHICLEINSURANCE_URL: 'taxioperatorvehicles/vehicleInsurance',
  VEHICLEFINANCE_URL: 'taxioperatorvehicles/vehiclefinance',
  VEHICLETRACKER_URL: 'taxioperatorvehicles/vehicleTrackers',
  VEHICLEEXISITS_URL: 'taxioperatorvehicles/OperatorVehicleExists',
  VEHICLEEXISITSREGNUM_URL: 'taxioperatorvehicles/OperatorVehicleExistsRegNum',
  VEHICLEFINESBYID_URL: 'taxioperatorvehicles/vehicleFinesById',
  VEHICLEDISKVALIDATORBYID_URL: 'taxioperatorvehicles/licenseDiskExpiryCheckById',
  VEHICLEDRIVEITEMSBYASS_URL: 'taxioperatorvehicles/getVehicleDriveFileByFolderID',
  VEHICLEDRIVEITEMSBYID_URL: 'taxioperatorvehicles/vehicleDriveFileById',
  VEHICLETRIPHISTORYBYID_URL: 'taxioperatorvehicles/vehicleTripHistoryById',
  COLLECTORVEHICLEADD_URL: 'taxioperatorvehicles/collectorVehiclesAdd',
  COLLECTORVEHICLEDELETE_URL: 'taxioperatorvehicles/collectorVehiclesDelete',
  COLLECTORVEHICLESGETBYID_URL: 'taxioperatorvehicles/collectorVehiclesById',
  COLLECTORARREARSBYID_URL: 'taxioperatorvehicles/arrearsByCollector',
  COLLECTORWEEKLYARREARSBYID_URL: 'taxioperatorvehicles/weeklyArrearsByCollectorId',
  COLLECTORPREVIOUSWEEKLYARREARSBYID_URL: 'taxioperatorvehicles/previousWeeklyArrearsByCollectorId',
  VEHICLEPLACEUNDERBREAKDOWN_URL: 'taxioperatorvehicles/placetaxiunderbreakdown',
  VEHICLEREMOVEUNDERBREAKDOWN_URL: 'taxioperatorvehicles/removetaxifrombreakdown',
  SYSADMINDASHBOARDCARDS_URL: 'reports/sysadmindashboard',
  SYSADMINDASHBOARDFINANCECARDS_URL: 'reports/sysadminfinancedashboard',
  COLLECTIONDASHBOARDCARDS_URL: 'reports/collectionmanagerdashboard/{taxiassociationsId}/{startDate}/{endDate}',
  COLLECTIONDASHBOARDCARDSTATS_URL: 'reports/collectionmanagerdashboardstats/{taxiassociationsId}/{startDate}/{endDate}',
  CHAIRMANDASHBOARDCARDS_URL: 'reports/chairmandashboard/{taxiassociationsId}',
  VEHICLEDASHBOARDCARDS_URL: 'reports/chairmandashboard/vehicles/{taxiassociationsId}',
  FUELMANAGERDASHBOARDCARDS_URL: 'reports/fuelmanager/dashboard/{taxiassociationsId}',
  FUELMANAGERALLTRANS_URL: 'reports/fuelmanager/getallfueltransactions/{taxiassociationsId}',
  FUELMANAGERDASHBOARDSTATS_URL: 'reports/fuelmanager/dashboardstats/{taxiassociationsId}',
  CHAIRMANDASHBOARDCARDSTATS_URL: 'reports/chairmandashboardstats/{taxiassociationsId}',
  VEHICLEDASHBOARDCARDSTATS_URL: 'reports/chairmandashboardstats/vehicles/{taxiassociationsId}',
  REGIONALCHAIRMANDASHBOARDCARDSTATS_URL: 'reports/regionalchairmandashboardstats/{regionid}',
  REGIONALCHAIRMANDASHBOARDCARDTOP5STATS_URL: 'reports/regionalchairmandashboardstatstop/{regionid}',
  REGIONALCHAIRMANDASHBOARDCARDS_URL: 'reports/regionalchairmandashboard/{regionid}',
  REALTIMERANKMANAGERCARDS_URL: 'reports/realtimerankmanager/{rankid}/{startDate}/{endDate}',
  REALTIMERANKMANAGERDETAILSCARDS_URL: 'reports/realtimerankmanagerdetails/{routeid}/{startDate}/{endDate}',
  REALTIMERANKMANAGERTOP5CARDS_URL: 'reports/realtimerankmanagertop5/{rankid}/{startDate}/{endDate}',
  RANKMANAGER_META_CARDS_URL: 'reports/rankmanagermetadata/{taxiassociationsId}',
  RANKMANAGER_META_CARDS_REGION_URL: 'reports/rankmanagermetadata/region/{regionid}',
  RANKMANAGER_RANKPERFORMANCECHARTS_URL: 'reports/getRankPerformanceCharts/{taxiassociationsId}',
  RANKMANAGER_RANKPERFORMANCECARDS_URL: 'reports/getRankPerformanceCards/{taxiassociationsId}',
  RANKMANAGER_TOPPERFORMINGMARSHALS_URL: 'reports/getTop5MarshalsYearToDate/{taxiassociationsId}',
  RANKMANAGER_RANKPERFORMANCECARDS_REGION_URL: 'reports/getRankPerformanceCardsRegion/region/{regionid}/{startDate}/{endDate}',
  RANKMANAGER_RANKPERFORMANCECHARTS_REGION_URL: 'reports/getRankPerformanceChartsRegion/region/{regionid}/{startDate}/{endDate}',
  RANKMANAGER_ROUTESBYRANKID_URL: 'taxirank/TaxiRouteByRankId/{rankid}',
  RANKMANAGER_DELETETRIP_URL: 'taxirank/MarkAsDeleted/{tripid}/{userid}',
  CASHUP_URL:'cashup',
  APPROVEDCASHUP_URL:'cashup/approved',
  PENDINGCASHUP_URL:'collectorcashup/pending',
  CASHUPPENDINGAPPROVAL_URL:'collectorcashup/pendingApproval',
  APPROVECASHUP_URL: 'collectorcashup/approveCashup',
  VERIFYCASHUP_URL: 'collectorcashup/verifyCashup',
  REJECTCASHUP_URL: 'cashup/executeRejected',
  STARTCASHUP_URL:'collectorcashup/cashupStart',
  ENDCASHUP_URL:'collectorcashup/cashupEnd',
  ACTIVECASHUP_URL: 'collectorcashup/getactivebyuserid',
  GETCASHUPBYASS_URL: 'collectorcashup/TaxiAssId',
  SMS_URL: 'sms',
  BULKSMS_URL: 'sms/bulkAdd',
  BULKSMSGETBYBATCH_URL: 'sms/getBulkSmssBybatch',
  GETSMSBYVEHICLEID_URL: 'sms/getSMSesByVehicleId/{taxiassociationid}/{vehicleid}',
  GETVEHICLEIDTRIPTRACKER_URL: 'taxioperatorvehicles/getTripTracker/{taxiassociationid}/{vehicleid}',
  GETOPERATORSMS_URL: 'sms/getSMSByOperatorID',
  SMSBYASSOCIATION_URL: 'sms/getSMSsbyAss',
  TASS_BILLING_URL: 'taxiassociationbillings',
  TA_ROUTES_URL: 'taxiassociationroutes',
  TA_BANKING_URL:'taxiassociationsbilling/bankdetails',
  TA_RECEIPTSTOCK_URL:'taxiassociationsbilling/getReceiptStockById',
  TA_SERVICETYPE_URL: '/taxiassociationsbilling/servicetype',
  DRIVER_LOGO_URL: 'taxidriver/logo',
  TA_BILLINGUPDATE_URL:'taxiassociationbillings',
  TA_CHANGEDEFAULT_BANK:'taxiassociationsbilling/bankdetails',
  TA_BILLING_UPDATE: '/taxiassociationsbilling',
  TA_ADD_ONS_URL: '/taxiassociationsbilling/addSubscribingAddOns',
  TA_ADD_ON_REMOVE_URL: '/taxiassociationsbilling/removeSubscribingAddOns',
  TA_BANKING_URL:'taxiassociationsbilling/getBankDetails',
  TA_BILLING_ITEMS_UPDATE:'taxiassociationbillings',
  TA_BANK_ACC_ADD:'taxiassociationsbilling/addBankAccount',
  TA_GETSETTINGS_URL: 'taxiassociations/getAssociationSettingsById',
  TA_ROUTESBYASSID_URL: 'taxiassociations/getAssociationRoutesByAssId',
  TA_ROUTES_EDIT_URL: 'taxiassociations/editRoute',
  TA_ROUTESBYID_URL: 'taxiassociations/getRouteById',
  TA_ROUTEUPDATE_URL: 'taxiassociations/updateAssociationRoute',
  TA_RANKS_URL: 'taxiassociations/associationRanks',
  TA_RANK_SUMMARY_URL: 'taxiassociations/rankSummary',
  TA_ROUTE_SUMMARY_URL: 'taxiassociations/routeSummary',
  TA_LOGO_URL: 'taxiassociations/logo',
  TA_UPDATE_URL: 'taxiassociations/update',
  TA_ROUTES_ADD_URL:'taxiassociations/addroute',
  TA_MEMBER_MORATORIUM_UPDATE:'taxiassociations/updateMoratorium',
  TA_RANKADD_URL: 'taxiassociations/addRank',
  TA_RANKUPDATE_URL: 'taxiassociations/updateAssociationRank',
  TA_RANKBYID_URL: 'taxiassociations/getRankById',
  TA_SPOTFINES_URL: 'taxiassociations/operationalCompliance',
  TA_ADDROUTEBYNATIONALCODE_URL: 'taxiassociations/addroutebynationalcode',
  TA_ADDROUTEBYRANKID_URL: 'taxiassociations/addRouteByRank',
  TA_ADDLETTEROFGOODSTANDING_URL: 'taxiassociations/addLetterofGoodStanding',
  TA_GETLETTEROFGOODSTANDINGBYASSID_URL: 'taxiassociations/letterOfGoodStanding',
  TA_CREATELETTEROFGOODSTANDINGPDF_URL: 'taxiassociations/makeLetterOfGoodstandingPdf',
  TA_REPORTFRAUDULENTLETTER_URL: 'taxiassociations/letterOfGoodStandingFraudulentReport',
  TA_VERIFYLETTEROFGOODSTANDING_URL: 'taxiassociations/verifyletterOfGoodStanding',
  TA_GETLETTERBYVERIFICATIONCODE_URL: 'taxiassociations/getLetterPathByVerificationNumber',
  TA_GETOPERATORSALESLEADS_URL: 'taxiassociations/operatorSalesLeadsById',
  TA_GETFRAUDULENTLETTERSBYNUMBER_URL: 'taxiassociations/getFraudulentLettersByVerificationNumber',
  TA_GETSERVICEACCOUNTBYID_URL: 'taxiassociations/getAccountByServiceId',
  TA_GETACCOUNTTRANSACIONSBYID_URL: 'taxiassociations/getTransactionsByServiceId',
  TA_GETBULKSMSESBYID_URL: 'taxiassociations/getAssociationByBulkId',
  TA_ADDSERVICETYPEACCOUNT_URL: 'taxiassociations/associationAccountAdd',
  TA_ACCOUNTTOPUP_URL: 'taxiassociations/associationAccountTopUp',
  VIPCOMMUTERSADD_URL: 'taxivip/addVipCommuter',
  VIPCOMMUTERSUPDATE_URL: 'taxivip/updateCommuter',
  VIPCOMMUTERS_URL: 'taxivip',
  VIPCOMMUTERCARDSBYASSID_URL: 'taxivip/commuterCardsByAssId',
  VIPCOMMUTERCARDSBYUSERID_URL: 'taxivip/commuterCardByCommuterId',
  VIPCOMMUTERCARDSBYID_URL: 'taxivip/commuterCardById',
  VIPCARDTRANSACTIONSBYID_URL: 'taxivip/getTransactionsByAccNoPDA',
  VIPCOMMUTERROUTES_URL: 'taxivip/commuterRoutesById',
  VIPROUTESBYASSID_URL: 'taxivip/routeByAssId',
  VIPCARDTRANSACTIONSBYASSID_URL: 'taxivip/cardTransactionsByAssId',
  VIPCARDADD_URL: 'taxivip/addVipCommuterCard',
  VIPCARDINVENTORYBYID_URL: 'taxivip/cardInventoryById',
  VIPCARDINVENTORYBYASSID_URL: 'taxivip/unusedCardInventoryByAssId',
  VIPCARDINVENTORYADD_URL: 'taxivip/addCardInventory',
  VIPCARDTAXIROUTEADD_URL: 'taxivip/addTaxiRoute',
  VIPCARDTAXIROUTEPRICINGADD_URL: 'taxivip/addRoutePricing',
  VIPCARDTAXIROUTEPRICINGBYASSID_URL: 'taxivip/routePricingByAssId',
  VIPCARDTAXIROUTEPRICINGBYID_URL: 'taxivip/routePricingById',
  VIPCARD_ROUTESBYASSID_URL: 'taxivip/getAssociationRoutesByAssId',
  VIPCARD_REMOVEVIPROUTES_URL: 'taxivip/RemoveVIPRouteByAssId',
  VIPCARDPAYMENT_URL: 'taxivip/addVipCardPayment',
  VIPBLOCKCARD_URL: 'taxivip/blockCard',
  VIPUNBLOCKCARD_URL: 'taxivip/unblockCard',
  VIPCOMMUTERCARDUPDATE_URL: 'taxivip/updateVIPCommuterCard',
  BANK_URL: 'bank',
  ACCOUNTTYPE_URL: 'accounttype',
  BANKDebitOrders_URL: 'bank/DebitOrders',
  ACCOUNTTYPEDebitOrders_URL: 'bank/DebitOrdersAccountTypes',
  FLEETMANAGEMENTGETALLINVENTORYTYPES_URL: 'fleetmanagement/GetAllInventoryType',
  FLEETMANAGERDASHBOARDCARDS_URL: 'reports/fleetmanagement/dashboard/{taxiassociationsId}',
  FLEETMANAGERDASHBOARDSTATS_URL: 'reports/fleetmanagement/dashboardstats/{taxiassociationsId}',
  VIPGETALLINVENTORYTYPES_URL: 'taxivip/GetAllInventoryType',
  VIPDASHBOARDCARDS_URL: 'reports/taxivip/dashboard/{taxiassociationsId}',
  VIPDASHBOARDSTATS_URL: 'reports/taxivip/dashboardstats/{taxiassociationsId}',
  VIPALLTRANS_URL: 'reports/taxivip/getalltransactions/{taxiassociationsId}',
};
