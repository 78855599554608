/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';


export default {
  namespaced: true,
  state: {
    data: [],
    print: [],
    paymentresults: [],
    prepayment: [],
    paymenthistory: [],
    arrearsbyvehicles: [],
    arrearsbyoperators: [],
    paymentbycollectorhistory: [],
    arrearsbycollector: [],
    weeklyarrearsbycollector: [],
    previousweeklyarrearsbycollector: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETALL: state => state.data,
    GETDETAILS: state => state.print,
    GETPREPAYMENTDETAILS: state => state.prepayment,
    GETPAYMENTRESULTS: state => state.paymentresults,
    GETPAYMENTHISTORY: state => state.paymenthistory,
    GETARREARSBYVEHICLES: state => state.arrearsbyvehicles,
    GETARREARSBYOPERATORS: state => state.arrearsbyoperators,
    GETARREARSBYCOLLECTORS: state => state.arrearsbycollector,
    GETWEEKLYARREARSBYCOLLECTOR: state => state.weeklyarrearsbycollector,
    GETPREVIOUSWEEKLYARREARSBYCOLLECTOR: state => state.previousweeklyarrearsbycollector,
    GETCOLLECTORIDPAYMENTHISTORY: state => state.paymentbycollectorhistory,
  },
  mutations: {
    LOADCOLLECTORIDPAYMENTHISTORY: (state, _data) => {
      state.paymentbycollectorhistory = _data;
    },
    LOADARREARSBYVEHICLES: (state, _data) => {
      state.arrearsbyvehicles = _data;
    },
    LOADARREARSBYOPERATORS: (state, _data) => {
      state.arrearsbyoperators = _data;
    },

    LOADARREARSBYCOLLECTOR: (state, _data) => {
      state.arrearsbycollector = _data;
    },

    LOADWEEKLYARREARSBYCOLLECTOR: (state, _data) => {
      state.weeklyarrearsbycollector = _data;
    },


    LOADPREVIOUSWEEKLYARREARSBYCOLLECTOR: (state, _data) => {
      state.previousweeklyarrearsbycollector = _data;
    },



    LOADPAYMENTHISTORY: (state, _data) => {
      state.paymenthistory = _data;
    },
    CLEARPAYMENTHISTORY: (state) => {
      state.paymenthistory = [];
    },
    LOADED: (state, _data) => {
      if (_data !== undefined) {
        state.data = _data;
      }
    },
    LOADPREPAYMENT: (state, _data) => {
      state.prepayment = _data;
    },
    LOADDETAILS: (state, _data) => {
      if (_data !== undefined) {
        state.print = _data;
      }
    },
    LOADPAYMENTRESULTS: (state, _data) => {
      if (_data !== undefined) {
        state.paymentresults = _data;
      }
    },
    ADDED: (state, _data) => {
      state.data.push(_data);
    },
    UPDATED: (state, _data) => {
      if (_data !== undefined) {
        Object.assign(state.data.filter(item => item._id === _data._id)[0], _data);
      }
    },
    RESTOREDELETED: (state, {
      id
    }) => {
      if (id !== undefined) {
        state.data.filter(item => item._id === id)[0]._deleted_by = undefined;
        state.data.filter(item => item._id === id)[0]._date_deleted = undefined;
      }
    },
    MARKASDELETE: (state, {
      id,
      deletedby
    }) => {
      if (id !== undefined) {
        state.data.filter(item => item._id === id)[0]._deleted_by = deletedby;
        state.data.filter(item => item._id === id)[0]._date_deleted = Date.now;
      }
    },
    DELETE: (state, id) => {
      if (id !== undefined) {
        state.data = state.data.filter(item => item._id !== id);
        state.deleted = true;
      }
    },
    CLEARALL: (state) => {
      state.paymentresults = [];
    },
  },
  actions: {
    /* Async */

    /* Auth.js Mutations */

    clearAll({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }) {
      /* Auth.js Mutations */
      commit('CLEARALL');
      console.log('payment results cleared!');
    },

    refundpayment({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      payload,
      item,
      _Drivers,
      _Taxis,
      _Operator
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.PAYMENTREFUNDSCUSTOM_URL,
        };

        const AddModel = {
          taxi_association_id: payload.taxi_association_id,
          taxi_driver_id: _Drivers,
          taxi_operator_id: _Operator,
          taxi_operator_vehicles_id: _Taxis,
          payment_date: payload.payment_date,
          amount: payload.linetotal,
          comment: payload.comment,
          reference: payload.reference,
          added_by: payload.added_by,
          captured_by: payload.captured_by,
          paymentdetails: payload.paymentdetails
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('LOADPAYMENTRESULTS', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    makepayment({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      payload,
      item,
      _Drivers,
      _Taxis,
      _Operator
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.PAYMENTSCUSTOM_URL,
        };

        const AddModel = {
          taxi_association_id: payload.taxi_association_id,
          taxi_driver_id: _Drivers,
          taxi_operator_id: _Operator,
          taxi_operator_vehicles_id: _Taxis,
          payment_date: payload.payment_date,
          amount: payload.linetotal,
          comment: payload.comment,
          reference: payload.reference,
          added_by: payload.added_by,
          captured_by: payload.captured_by,
          paymentdetails: payload.paymentdetails
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          console.log('Payment made: ', parsedResult);
          if (parsedResult.status) {
            console.log('Payment made: ', )
            commit('LOADPAYMENTRESULTS', parsedResult.data);
            resolve(parsedResult);
            // navigateTo(router, parsed_result.data[0]);
          } else
            reject(arsedResult.data[0]);
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    makeBulkVehiclepayment({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      payload,
      taxiassociation_id
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.PAYMENTVEHICLEBULK_URL,
        };

        var custom_vehicles_payment = [];

        payload.map((payments)=>{
            custom_vehicles_payment.push({
              vehicle_id: payments.vehicle_registration_id,
              captured_by: payments.captured_by,
              amount: payments.amount,
              payment_date: payments.payment_date
            })
          
        })

        const AddModel = {
          taxi_association_id: taxiassociation_id,
          custom_vehicles_payment: custom_vehicles_payment
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          console.log('Payment made: ', parsedResult);
          if (parsedResult.status) {
            console.log('Payment made: ',parsedResult );
            // commit('LOADPAYMENTRESULTS', parsedResult.data);
            resolve(parsedResult);
            // navigateTo(router, parsed_result.data[0]);
          } 
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    makeUploadBulkVehiclepayment({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      payload,
      taxiassociation_id
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.PAYMENTVEHICLEBULK_URL,
        };

        var custom_vehicles_payment = [];

        payload.map((payments)=>{
          if(payments.vehicle_registration_id != null && payments.vehicle_registration_id != undefined && !isNaN(payments.vehicle_registration_id)){
            custom_vehicles_payment.push({
              vehicle_id: payments.vehicle_registration_id,
              captured_by: payments.captured_by,
              amount: payments.amount,
              payment_date: payments.payment_date
            })
          }
        })

        const AddModel = {
          taxi_association_id: taxiassociation_id,
          custom_vehicles_payment: custom_vehicles_payment
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          console.log('Payment made: ', parsedResult);
          if (parsedResult.status) {
            console.log('Payment made: ',parsedResult );
            // commit('LOADPAYMENTRESULTS', parsedResult.data);
            resolve(parsedResult);
            // navigateTo(router, parsed_result.data[0]);
          } 
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    prepayment({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      PrePayment
    }) {
      commit('LOADPREPAYMENT', PrePayment);
    },
    getById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {

      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.PAYMENTS_URL,
      };

      this._vm.$TAMSFIN_API.getById(requestheader,
        id
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          commit('LOADDETAILS', parsedResultOP.data[0]);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    },

    getfileByDropzoneUpload({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id,
      skip,
      len
    }) {

      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.PAYMENTBULKUPLOADSBYID_URL,
        };

        const _getPagingModel = {
          status: id,
          skip: skip,
          len: len
        };

        this._vm.$TAMSFIN_API.getByPaging(requestheader,
          _getPagingModel
        ).then((parsedResultOP) => {
          
          console.log(parsedResultOP);
          if (parsedResultOP.status) {

            console.log('LOADDETAILS', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          
          console.log(error);
          reject(error);
          // commit('LOGINFAILED', error.data);
        });

      });
    },



    delete({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TITLE_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id, {
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('DELETE', id);
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    markAsdelete({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TITLE_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id, {
            id,
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('MARKASDELETE', {
              id,
              deletedby
            });
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    restoreDeleted({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          restoredby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TITLE_URL,
        };

        this._vm.$TAMS_API.restoreDeleted(requestheader, {
          id,
          restoredby,
        }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('RESTOREDELETED', {
              id
            });
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    generatepayment4billingHistory({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      payload
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETBILLINPAYMENTSGHISTORY_URL,
        };

        

        this._vm.$TAMSFIN_API.getCustomURL(requestheader,
          payload
        ).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            //commit('LOADPAYMENTHISTORY', parsedResultOP.data);
            resolve(parsedResultOP);
          }
        }).catch((error) => {
          console.log(error);
          commit('LOGINFAILED', error.data);
        });
      });
    },
    generatepaymentHistory({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      payload
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETRECEIPTSHISTORY_URL,
        };

        

        this._vm.$TAMSFIN_API.getCustomURL(requestheader,
          payload
        ).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            //commit('LOADPAYMENTHISTORY', parsedResultOP.data);
            resolve(parsedResultOP);
          }
        }).catch((error) => {
          console.log(error);
          commit('LOGINFAILED', error.data);
        });
      });
    },

    generateArrearsByVehicles({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      payload
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETTAXIVEHICLEARREARS_URL,
        };

        

        this._vm.$TAMSFIN_API.getCustomURL(requestheader,
          payload
        ).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            //commit('LOADARREARSBYVEHICLES', parsedResultOP.data);
            resolve(parsedResultOP);
          }
        }).catch((error) => {
          console.log(error);
          commit('LOGINFAILED', error.data);
        });
      });
    },

    generateArrearsByOperators({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      payload
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETOPERATORSARREARS_URL,
        };

        

        this._vm.$TAMSFIN_API.getCustomURL(requestheader,
          payload
        ).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            //commit('LOADARREARSBYOPERATORS', parsedResultOP.data);
            resolve(parsedResultOP);
          }
        }).catch((error) => {
          console.log(error);
          commit('LOGINFAILED', error.data);
        });
      });
    },
    generateArrearsOfOperators({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      payload
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETOPERATORSOFARREARS_URL,
        };

        

        this._vm.$TAMSFIN_API.getCustomURL(requestheader,
          payload
        ).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            //commit('LOADARREARSBYOPERATORS', parsedResultOP.data);
            resolve(parsedResultOP);
          }
        }).catch((error) => {
          console.log(error);
          commit('LOGINFAILED', error.data);
        });
      });
    },
    generateArrearsOfDrivers({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      payload
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETDRIVEROFARREARS_URL,
        };

        

        this._vm.$TAMSFIN_API.getCustomURL(requestheader,
          payload
        ).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            //commit('LOADARREARSBYOPERATORS', parsedResultOP.data);
            resolve(parsedResultOP);
          }
        }).catch((error) => {
          console.log(error);
          commit('LOGINFAILED', error.data);
        });
      });
    },
    generateArrearsByCollector({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.COLLECTORARREARSBYID_URL,
        };
        this._vm.$TAMSFIN_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            //commit('LOADARREARSBYCOLLECTOR', parsedResult.data);
            console.log('collector arrears: ',parsedResult);
            resolve(parsedResult);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log('collector arrears errors: ',error);
          reject(error);
        });
      });
    },

    getWeeklyArrearsByCollectorId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.COLLECTORWEEKLYARREARSBYID_URL,
        };
        this._vm.$TAMSFIN_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            commit('LOADWEEKLYARREARSBYCOLLECTOR', parsedResult.data);
            console.log('collector weekly arrears: ',parsedResult);
            resolve(parsedResult);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log('collector arrears errors: ',error);
          reject(error);
        });
      });
    },

    getPreviousWeeklyArrearsByCollectorId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.COLLECTORPREVIOUSWEEKLYARREARSBYID_URL,
        };
        this._vm.$TAMSFIN_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('LOADPREVIOUSWEEKLYARREARSBYCOLLECTOR', parsedResult.data);
            //console.log('collector previous week arrears: ',parsedResult);
            resolve(parsedResult);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log('collector arrears errors: ',error);
          reject(error);
        });
      });
    },


    generatepaymentByCollectorIdHistory({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      payload
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETRECEIPTBYCOLLECTORIDSHISTORY_URL,
        };

        

        this._vm.$TAMSFIN_API.getCustomURL(requestheader,
          payload
        ).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            //commit('LOADCOLLECTORIDPAYMENTHISTORY', parsedResultOP.data);
            resolve(parsedResultOP);
          }
        }).catch((error) => {
          console.log(error);
          commit('LOGINFAILED', error.data);
        });
      });
    },

    generatepaymentByCollectorIdHistory({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      payload
    }) {
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETRECEIPTBYCOLLECTORIDSHISTORY_URL,
        };

        

        this._vm.$TAMSFIN_API.getCustomURL(requestheader,
          payload
        ).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            commit('LOADCOLLECTORIDPAYMENTHISTORY', parsedResultOP.data);
            resolve(parsedResultOP);
          }
        }).catch((error) => {
          console.log(error);
          commit('LOGINFAILED', error.data);
        });
      });
    },

  },




};