<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
      v-on:init="init"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'RankManager'"
      :ActiveMenu="'RankManagerStats'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <transition name="bounce">
      <div id="content" class="content" v-show="USERPROFILE.taxi_associations_id">
        <!-- begin page-header -->
        <headerpagebreadcrumb
          pageheaderTitle="Rank Manager Statistics"
          pageheaderdescription
          breadcrumbitem1="Home"
          breadcrumbitem2="Rank Manager"
          breadcrumbitem3="Stats"
        />
        <!-- end page-header -->
        <!-- begin #Main Body Template -->

        <!-- begin row -->
        <div class="row">
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-green">
                <i class="ion-logo-usd"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Fare</div>
                <div
                  class="stats-number"
                >{{GETDASHBOARDCARDSTOTALS.totalfares | newcurrencywithSymbol}}</div>

                <div class="stats-desc">Cash Received In Ranks For Trips Traveled</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-red">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Passengers</div>
                <div class="stats-number">{{GETDASHBOARDCARDSTOTALS.totalpassengers | numberwith }}</div>
                <div class="stats-desc">Passengers That Have Been To The Ranks</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="fas fa-taxi"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Trips Travelled</div>
                <div class="stats-number">{{GETDASHBOARDCARDSTOTALS.totaltrips | numberwith}}</div>
                <div class="stats-desc">Trips Made In & Out Of Ranks By Vehicles</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="fas fa-taxi"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Waiting Period</div>
                <div class="stats-number">{{GETDASHBOARDCARDSTOTALS.totalwaitingperiod}}</div>

                <div class="stats-desc">Waiting Peroid In Days</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
        </div>

        <!-- end row -->

        <!-- begin page-header -->
        <h1 class="page-header">Rank Averages</h1>
        <!-- end page-header -->

        <!-- begin row -->

        <div class="row">
          <!-- begin col-3 -->
          <div class="col-lg-4 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-green">
                <i class="ion-logo-usd"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Trip Average</div>
                <div
                  class="stats-number"
                >{{GETDASHBOARDCARDSTOTALAVGS.totalfareaverage }}</div>
                <div class="stats-desc">Trips Made VS Passengers</div>
              </div>
            </div>
          </div>
          <!-- begin col-3 -->

          <!-- begin col-3 -->
          <div class="col-lg-4 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="ion-logo-usd"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Passengers Average</div>
                <div
                  class="stats-number"
                >{{GETDASHBOARDCARDSTOTALAVGS.passengersaverage }}</div>

                <div class="stats-desc">Passengers Per vehicle</div>
              </div>
            </div>
          </div>
          <!-- begin col-3 -->

          <!-- begin col-3 -->
          <div class="col-lg-4 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-red">
                <i class="ion-logo-usd"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Trips Average</div>
                <div
                  class="stats-number"
                >{{GETDASHBOARDCARDSTOTALAVGS.tripsaverage }}</div>

                <div class="stats-desc">Number Of Trips Daily trips year to date</div>
              </div>
            </div>
          </div>
          <!-- begin col-3 -->

          <div class="col-lg-6 col-md-6 hide">
            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Yearly Turnover Comparison</h4>
              </div>
              <div class="panel-body">
                <canvas id="morris-donut-chart" class="height-sm"></canvas>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 hide">
            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Quartely Comparison Turnover Comparison</h4>
              </div>
              <div class="panel-body">
                <canvas id="morris-donut-chart" class="height-sm"></canvas>
              </div>
            </div>
          </div>
        </div>

        <!-- end row -->

        <!-- begin row -->
        <div class="row">
          <!-- begin col-xl-8 -->
          <div class="col-xl-8">

            

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Weekly Fare Collected</h4>
              </div>
              <div class="panel-body">
                <div id="interactive-chart-threemonthsweeklyfare" class="height-sm interactive-chart"></div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Monthly Fare Collected</h4>
              </div>
              <div class="panel-body">
                <div id="interactive-chart-threemonthsfare" class="height-sm interactive-chart"></div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">3 Month Of Weekly Statistics Passengers VS Trips</h4>
              </div>
              <div class="panel-body">
                <div id="interactive-chart-threemonths" class="height-sm interactive-chart"></div>
              </div>
            </div>
            <!-- end panel -->



            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Daily Statistics Trips VS Passengers</h4>
              </div>
              <div class="panel-body">
                <div id="interactive-chart-monthly" class="height-sm interactive-chart"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>

          <!-- begin col-xl-4 -->
          <div class="col-xl-4">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-6">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top 5 Most Fare Collected</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Marshal</th>
                        <th>Route</th>
                        <th>Total Collected</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item) in GETDASHBOARDSTATS.top5marshalsmostfare"
                        v-bind:key="item.id"
                      >
                        <td>{{item.marshal}}</td>
                        <td class="text-center">{{item.rankname}}</td>
                        <td class="text-center">{{item.totalcollected | newcurrencywithSymbol}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-6">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top 5 Marshal Recorded Trips</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Marshal</th>
                        <th>Route</th>
                        <th>Total Trips</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item) in GETDASHBOARDSTATS.top5marshalsmosttrips"
                        v-bind:key="item.id"
                      >
                        <td>{{item.marshal}}</td>
                        <td class="text-center">{{item.rankname }}</td>
                        <td class="text-center">{{item.totaltrips }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-6">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top 5 Marshal Most Passengers</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Marshal</th>
                        <th>Route</th>
                        <th>Total Trips</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item) in GETDASHBOARDSTATS.top5marshalsmostpassengers"
                        v-bind:key="item.id"
                      >
                        <td>{{item.marshal}}</td>
                        <td class="text-center">{{item.rankname }}</td>
                        <td class="text-center">{{item.totalpassengers }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-xl-4 -->
        </div>
        <!-- end row -->

        <!-- begin #Main Body Template -->
      </div>
    </transition>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import statsCards from "@/components/charts/statsCards.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("rankmanagerstats");

export default {
  name: "sysadmin_dashboard",
  data: function () {
    return {
      message: "Some Message",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      GETDASHBOARDCARDS: {},
      GETDASHBOARDCARDSTOTALS: {},
      GETDASHBOARDCARDSTOTALAVGS: {},
      GETDASHBOARDSTATS: {},
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    statsCards,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters(["", ""]),
  },
  methods: {
    ...mapActions([
      "getRankManagerPerformanceCards",
      "getRankManagerPerformanceCharts",
    ]),
    init: function () {},
  },
  mounted: function () {

    
    $(document).data("context", this);
    App.init();

    const router = this.$router;

    var payload = {
      taxiassociationsId: this.taxiassociationid,
    };

    this.getRankManagerPerformanceCards({ router, payload })
      .then((parsedResultOP) => {
        console.log("cards: ", parsedResultOP);
        const context = $(document).data("context");

        context.GETDASHBOARDCARDS = parsedResultOP[0];
        context.GETDASHBOARDCARDSTOTALS = parsedResultOP[0]._totals[0];
        context.GETDASHBOARDCARDSTOTALAVGS = parsedResultOP[0]._totalaverages[0]
      })
      .catch((error) => {
        console.log("cards errors: ", error);
        //context.$wait.end("generatestatement");
      });

    this.getRankManagerPerformanceCharts({ router, payload })
      .then((parsedResultOP) => {
        const context = $(document).data("context");
        console.log("charts stats: ", parsedResultOP);

        context.GETDASHBOARDSTATS = parsedResultOP[0];

        handleInteractiveChartMonthly();
        handleInteractiveChartWeekly();
        
        handleInteractiveChart3MonthsFare();
        handleInteractiveChart3MonthsWeeklyFare();
        
      })
      .catch((error) => {
        console.log("charts errors: ", error);
        //context.$wait.end("generatestatement");
      });

      var handleInteractiveChart3MonthsWeeklyFare = function () {
      "use strict";
      function showTooltip(x, y, contents) {
        $('<div id="tooltip" class="flot-tooltip">' + contents + "</div>")
          .css({
            top: y - 45,
            left: x - 55,
          })
          .appendTo("body")
          .fadeIn(200);
      }
      if ($("#interactive-chart-threemonthsweeklyfare").length !== 0) {
        const context = $(document).data("context");

        

        var data1 = context.GETDASHBOARDSTATS.weeklyfare.map(
          (item, index) => {
            if (item.weeklyfare == null){
              item.weeklyfare = 0;
              
            }

            return [item.row_number, item.weeklyfare];
            
          }
        );


        var xLabel = context.GETDASHBOARDSTATS.weeklytimeseries.map(
          (item, index) => {
            return [item.row_number, item.rdate];
          }
        );

        $.plot(
          $("#interactive-chart-threemonthsweeklyfare"),
          [
            {
              data: data1,
              label: "Fare",
              color: COLOR_BLUE,
              lines: { show: true, fill: false, lineWidth: 2 },
              points: { show: true, radius: 3, fillColor: COLOR_WHITE },
              shadowSize: 0,
            },
            // {
            //   data: data2,
            //   label: "Passengers",
            //   color: COLOR_GREEN,
            //   lines: { show: true, fill: false, lineWidth: 2},
            //   points: { show: true, radius: 3, fillColor: COLOR_WHITE },
            //   shadowSize: 0,
            // },
            // {
            //   data: data3,
            //   label: "Trips",
            //   color: COLOR_ORANGE,
            //   lines: { show: true, fill: false, lineWidth: 2 },
            //   points: { show: true, radius: 3, fillColor: COLOR_WHITE },
            //   shadowSize: 0,
            // },
          ],
          {
            xaxis: {
              ticks: xLabel,
              tickDecimals: 0,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
            },
            yaxis: {
              ticks: 10,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
              min: 0,
            },
            grid: {
              hoverable: true,
              clickable: true,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
              borderWidth: 1,
              backgroundColor: "transparent",
              borderColor: COLOR_BLACK_TRANSPARENT_2,
            },
            legend: {
              labelBoxBorderColor: COLOR_BLACK_TRANSPARENT_2,
              margin: 10,
              noColumns: 1,
              show: true,
            },
          }
        );
        var previousPoint = null;
        $("#interactive-chart-threemonthsweeklyfare").bind("plothover", function (
          event,
          pos,
          item
        ) {
          $("#x").text(pos.x.toFixed(2));
          $("#y").text(pos.y.toFixed(2));
          if (item) {
            if (previousPoint !== item.dataIndex) {
              previousPoint = item.dataIndex;
              $("#tooltip").remove();
              var y = item.datapoint[1].toFixed(2);

              var content = item.series.label + " " + y;
              showTooltip(item.pageX, item.pageY, content);
            }
          } else {
            $("#tooltip").remove();
            previousPoint = null;
          }
          event.preventDefault();
        });
      }
    };

      var handleInteractiveChart3MonthsFare = function () {
      "use strict";
      function showTooltip(x, y, contents) {
        $('<div id="tooltip" class="flot-tooltip">' + contents + "</div>")
          .css({
            top: y - 45,
            left: x - 55,
          })
          .appendTo("body")
          .fadeIn(200);
      }
      if ($("#interactive-chart-threemonthsfare").length !== 0) {
        const context = $(document).data("context");

        

        var data1 = context.GETDASHBOARDSTATS.threemonthsfare.map(
          (item, index) => {
            if (item.totalfares == null){
              item.totalfares = 0;
              
            }

            return [item.row_number, item.totalfares];
            
          }
        );


        var xLabel = context.GETDASHBOARDSTATS.monthlytimeseries.map(
          (item, index) => {
            return [item.row_number, item.rdate];
          }
        );

        $.plot(
          $("#interactive-chart-threemonthsfare"),
          [
            {
              data: data1,
              label: "Fare",
              color: COLOR_BLUE,
              lines: { show: true, fill: false, lineWidth: 2 },
              points: { show: true, radius: 3, fillColor: COLOR_WHITE },
              shadowSize: 0,
            },
            // {
            //   data: data2,
            //   label: "Passengers",
            //   color: COLOR_GREEN,
            //   lines: { show: true, fill: false, lineWidth: 2},
            //   points: { show: true, radius: 3, fillColor: COLOR_WHITE },
            //   shadowSize: 0,
            // },
            // {
            //   data: data3,
            //   label: "Trips",
            //   color: COLOR_ORANGE,
            //   lines: { show: true, fill: false, lineWidth: 2 },
            //   points: { show: true, radius: 3, fillColor: COLOR_WHITE },
            //   shadowSize: 0,
            // },
          ],
          {
            xaxis: {
              ticks: xLabel,
              tickDecimals: 0,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
            },
            yaxis: {
              ticks: 10,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
              min: 0,
            },
            grid: {
              hoverable: true,
              clickable: true,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
              borderWidth: 1,
              backgroundColor: "transparent",
              borderColor: COLOR_BLACK_TRANSPARENT_2,
            },
            legend: {
              labelBoxBorderColor: COLOR_BLACK_TRANSPARENT_2,
              margin: 10,
              noColumns: 1,
              show: true,
            },
          }
        );
        var previousPoint = null;
        $("#interactive-chart-threemonthsfare").bind("plothover", function (
          event,
          pos,
          item
        ) {
          $("#x").text(pos.x.toFixed(2));
          $("#y").text(pos.y.toFixed(2));
          if (item) {
            if (previousPoint !== item.dataIndex) {
              previousPoint = item.dataIndex;
              $("#tooltip").remove();
              var y = item.datapoint[1].toFixed(2);

              var content = item.series.label + " " + y;
              showTooltip(item.pageX, item.pageY, content);
            }
          } else {
            $("#tooltip").remove();
            previousPoint = null;
          }
          event.preventDefault();
        });
      }
    };

      

    var handleInteractiveChartWeekly = function () {
      "use strict";
      function showTooltip(x, y, contents) {
        $('<div id="tooltip" class="flot-tooltip">' + contents + "</div>")
          .css({
            top: y - 45,
            left: x - 55,
          })
          .appendTo("body")
          .fadeIn(200);
      }
      if ($("#interactive-chart-threemonths").length !== 0) {
        const context = $(document).data("context");

        

        var data1 = context.GETDASHBOARDSTATS.threemonthsfare.map(
          (item, index) => {
            return [item.row_number, item.totalfares];
          }
        );

        var data2 = context.GETDASHBOARDSTATS.threemonthspassengers.map(
          (item, index) => {
            return [item.row_number, item.totalpassengers];
          }
        );

        var data3 = context.GETDASHBOARDSTATS.threemonthstrips.map(
          (item, index) => {
            return [item.row_number, item.totaltrips];
          }
        );

        var xLabel = context.GETDASHBOARDSTATS.threemonthtimeseries.map(
          (item, index) => {
            return [item.row_number, item.rdate];
          }
        );

        $.plot(
          $("#interactive-chart-threemonths"),
          [
            // {
            //   data: data1,
            //   label: "Fare",
            //   color: COLOR_BLUE,
            //   lines: { show: true, fill: false, lineWidth: 2 },
            //   points: { show: true, radius: 3, fillColor: COLOR_WHITE },
            //   shadowSize: 0,
            // },
            {
              data: data2,
              label: "Passengers",
              color: COLOR_GREEN,
              lines: { show: true, fill: false, lineWidth: 2},
              points: { show: true, radius: 3, fillColor: COLOR_WHITE },
              shadowSize: 0,
            },
            {
              data: data3,
              label: "Trips",
              color: COLOR_ORANGE,
              lines: { show: true, fill: false, lineWidth: 2 },
              points: { show: true, radius: 3, fillColor: COLOR_WHITE },
              shadowSize: 0,
            },
          ],
          {
            xaxis: {
              ticks: xLabel,
              tickDecimals: 0,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
            },
            yaxis: {
              ticks: 10,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
              min: 0,
            },
            grid: {
              hoverable: true,
              clickable: true,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
              borderWidth: 1,
              backgroundColor: "transparent",
              borderColor: COLOR_BLACK_TRANSPARENT_2,
            },
            legend: {
              labelBoxBorderColor: COLOR_BLACK_TRANSPARENT_2,
              margin: 10,
              noColumns: 1,
              show: true,
            },
          }
        );
        var previousPoint = null;
        $("#interactive-chart-threemonths").bind("plothover", function (
          event,
          pos,
          item
        ) {
          $("#x").text(pos.x.toFixed(2));
          $("#y").text(pos.y.toFixed(2));
          if (item) {
            if (previousPoint !== item.dataIndex) {
              previousPoint = item.dataIndex;
              $("#tooltip").remove();
              var y = item.datapoint[1].toFixed(2);

              var content = item.series.label + " " + y;
              showTooltip(item.pageX, item.pageY, content);
            }
          } else {
            $("#tooltip").remove();
            previousPoint = null;
          }
          event.preventDefault();
        });
      }
    };

      var handleInteractiveChartMonthly = function () {
        "use strict";
        function showTooltip(x, y, contents) {
          $('<div id="tooltip" class="flot-tooltip">' + contents + "</div>")
            .css({
              top: y - 45,
              left: x - 55,
            })
            .appendTo("body")
            .fadeIn(200);
        }
        if ($("#interactive-chart-monthly").length !== 0) {
          const context = $(document).data("context");

          

          var data1 =  context.GETDASHBOARDSTATS.dailytrips.map((item, index) => {
            return [item.row_number,item.dailytrips]
          });

          var data2 = context.GETDASHBOARDSTATS.dailypassengers.map(
            (item, index) => {
              return [item.row_number, item.dailypassengers];
            }
          );

          var xLabel = context.GETDASHBOARDSTATS.dailytimeseries.map(
            (item, index) => {
              return [item.row_number, item.rdate];
            }
          );

          $.plot(
            $("#interactive-chart-monthly"),
            [
              {
                data: data1,
                label: "Trips",
                color: COLOR_ORANGE,
                lines: { show: true, fill: false, lineWidth: 2 },
                points: { show: true, radius: 3, fillColor: COLOR_WHITE },
                shadowSize: 0
              },
              {
                data: data2,
                label: "Passengers",
                color: COLOR_GREEN,
                lines: { show: true, fill: false, lineWidth: 2 },
                points: { show: true, radius: 3, fillColor: COLOR_WHITE },
                shadowSize: 0,
              },
            ],
            {
              xaxis: {
                ticks: xLabel,
                tickDecimals: 0,
                tickColor: COLOR_BLACK_TRANSPARENT_2,
              },
              yaxis: {
                ticks: 10,
                tickColor: COLOR_BLACK_TRANSPARENT_2,
                min: 0,
              },
              grid: {
                hoverable: true,
                clickable: true,
                tickColor: COLOR_BLACK_TRANSPARENT_2,
                borderWidth: 1,
                backgroundColor: "transparent",
                borderColor: COLOR_BLACK_TRANSPARENT_2,
              },
              legend: {
                labelBoxBorderColor: COLOR_BLACK_TRANSPARENT_2,
                margin: 10,
                noColumns: 1,
                show: true,
              },
            }
          );
          var previousPoint = null;
          $("#interactive-chart-monthly").bind("plothover", function (
            event,
            pos,
            item
          ) {
            $("#x").text(pos.x.toFixed(2));
            $("#y").text(pos.y.toFixed(2));
            if (item) {
              if (previousPoint !== item.dataIndex) {
                previousPoint = item.dataIndex;
                $("#tooltip").remove();
                var y = item.datapoint[1].toFixed(2);

                var content = item.series.label + " " + y;
                showTooltip(item.pageX, item.pageY, content);
              }
            } else {
              $("#tooltip").remove();
              previousPoint = null;
            }
            event.preventDefault();
          });
        }
      };
  },
};
</script>

<style>
@import "/assets/plugins/nvd3/build/nv.d3.css";
</style>