<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
      v-on:init="init"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Dashboard'"
      :ActiveMenu="'Summary'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <transition name="bounce">
      <div
        id="content"
        class="content"
        v-show="USERPROFILE.taxi_associations_id"
      >
        <!-- begin page-header -->
        <headerpagebreadcrumb
          pageheaderTitle="Treasurers Dashboard"
          pageheaderdescription
          breadcrumbitem1="Home"
          breadcrumbitem2="Dashboard"
          breadcrumbitem3="Chairman"
        />
        <!-- end page-header -->
        <!-- begin #Main Body Template -->

        <!-- begin row -->
        <div class="row">
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Taxi Operators</div>
                <div class="stats-number">
                  {{ GETDASHBOARDCARDS.totaloperators }}
                </div>

                <div class="stats-desc">Total Number Of Taxi Operators</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-red">
                <i class="fas fa-taxi"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Taxis</div>
                <div class="stats-number">
                  {{ GETDASHBOARDCARDS.totaltaxis }}
                </div>

                <div class="stats-desc">Total Number Of Taxis</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Drivers</div>
                <div class="stats-number">
                  {{ GETDASHBOARDCARDS.totaldrivers }}
                </div>

                <div class="stats-desc">Total Number Of Taxi Drivers</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="fas fa-tools"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Breakdown</div>
                <div class="stats-number">
                  {{ GETDASHBOARDCARDS.operatortaxiratio }}
                </div>

                <div class="stats-desc">Taxis Under Breakdown</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
        </div>

        <!-- end row -->

        <!-- begin row -->
        <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-8">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Operators By Gender</h4>
              </div>
              <div class="panel-body">
                <div id="donut-OperatorByGender" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-2">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Operators By Age Group</h4>
              </div>
              <div class="panel-body">
                <div id="donut-OperatorByAgeGroup" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-3">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Operating License</h4>
              </div>
              <div class="panel-body">
                <div id="VehicleWithNoOperatingLicense" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-4">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Expired License Disc</h4>
              </div>
              <div class="panel-body">
                <div id="VehicleWithLicensedisk" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-4">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Taxi Finance Chart</h4>
              </div>
              <div class="panel-body">
                <div id="Vehiclefinancechart" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>

          <!-- end col-8 -->
          <!-- begin col-8 -->
          <div class="col-xl-4">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-5">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Operators With Most Vehicles</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Operator Name</th>
                        <th>Total Taxis</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item,
                        index) in GETOPERATORFINSTATS.operatorswithmostvehicles"
                        v-bind:key="item.id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td>
                          {{ item.fullname }}
                        </td>
                        <td class="text-center">{{ item.numberoftaxis }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-6">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Operators With Outstanding Arrears</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Operator</th>
                        <th>Arrears</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item,
                        index) in GETOPERATORFINSTATS.operatorswithmostarrears"
                        v-bind:key="item.id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td>
                          {{ item.operatorname }}
                        </td>
                        <td class="text-center">
                          {{ item.arrears | newcurrencywithSymbol }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-7">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top Grossing Operators</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Operator</th>
                        <th>Revenue</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item,
                        index) in GETOPERATORFINSTATS.topgrossingoperators"
                        v-bind:key="item.id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td>
                          {{ item.operatorname }}
                        </td>
                        <td class="text-center">
                          {{ item.totalrevenue | newcurrencywithSymbol }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-6">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top Taxis with Arrears</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Reg No</th>
                        <th>Operator Name</th>
                        <th>Arrears</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item,
                        index) in GETOPERATORFINSTATS.toptaxiswitharrears"
                        v-bind:key="item.id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td>
                          {{ item.regnumber }}
                        </td>
                        <td>
                          {{ item.operatorname }}
                        </td>
                        <td class="text-center">
                          {{ item.arrears | newcurrencywithSymbol }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-6">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top Grossing Taxis</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Reg No</th>
                        <th>Operator</th>
                        <th>Revenue</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item,
                        index) in GETOPERATORFINSTATS.topgrossingtaxis"
                        v-bind:key="item.id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td>
                          {{ item.regnumber }}
                        </td>
                        <td>
                          {{ item.operatorname }}
                        </td>
                        <td class="text-center">
                          {{ item.totalrevenue | newcurrencywithSymbol }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-8 -->
        </div>
        <!-- end row -->

        <!-- begin row -->

        <!-- begin #Main Body Template -->
      </div>
    </transition>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import statsCards from "@/components/charts/statsCards.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers(
  "chairmansummarydashboard"
);

export default {
  name: "chairman_dashboard",
  data: function () {
    return {
      message: "Some Message",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      GETDASHBOARDCARDS: {},
      GETOPERATORFINSTATS: {},
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    statsCards,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters(["", ""]),
  },
  methods: {
    ...mapActions(["getdashboardcards", "getoperatorfinstats"]),
    init: function () {},
  },
  mounted: function () {
    $(document).data("context", this);
    App.init();

    const router = this.$router;

    var payload = {
      taxiassociationsId: this.taxiassociationid,
    };

    this.getdashboardcards({ router, payload })
      .then((parsedResultOP) => {
        const context = $(document).data("context");

        context.GETDASHBOARDCARDS = parsedResultOP;
      })
      .catch((error) => {
        //context.$wait.end("generatestatement");
      });

    this.getoperatorfinstats({ router, payload })
      .then((parsedResultOP) => {
        const context = $(document).data("context");

        

        context.GETOPERATORFINSTATS = parsedResultOP;

        

        handleOperatorByGender();
        handleOperatorByAgeGroup();
        handleVehicleWithNoOperatingLicense();
        handleVehicleWithNoLicensedisk();
        handleVehicleWithfinancechartdisk();
      })
      .catch((error) => {
        //context.$wait.end("generatestatement");
      });

    var handleOperatorByAgeGroup = function () {
      "use strict";
      if ($("#donut-OperatorByAgeGroup").length !== 0) {
        const context = $(document).data("context");
        

        var operatorAgeGroupData = [
          {
            label: `Retirement(${context.GETOPERATORFINSTATS.operatorbyagegroup[0].oldage})`,
            data: context.GETOPERATORFINSTATS.operatorbyagegroup[0].oldage,
            color: COLOR_BLUE,
          },
          {
            label: `Middle Age(${context.GETOPERATORFINSTATS.operatorbyagegroup[0].middleage})`,
            data: context.GETOPERATORFINSTATS.operatorbyagegroup[0].middleage,
            color: COLOR_PURPLE_LIGHTER,
          },
          {
            label: `Youth(${context.GETOPERATORFINSTATS.operatorbyagegroup[0].youth})`,
            data: context.GETOPERATORFINSTATS.operatorbyagegroup[0].youth,
            color: COLOR_GREEN,
          },
        ];

        $.plot("#donut-OperatorByAgeGroup", operatorAgeGroupData, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true,
              },
            },
          },
          grid: {
            hoverable: true,
            clickable: true,
          },
          legend: {
            show: true,
          },
        });
      }
    };

    var handleOperatorByGender = function () {
      "use strict";
      if ($("#donut-OperatorByGender").length !== 0) {
        const context = $(document).data("context");

        const colors = [COLOR_PURPLE_LIGHTER, COLOR_BLUE];

        var operatorGenderData = context.GETOPERATORFINSTATS.operatorsbygender.map(
          (item, index) => {
            return {
              label: item.gendertype + `(${item.gendercount})`,
              data: item.gendercount,
              color: colors[index],
            };
          }
        );

        $.plot("#donut-OperatorByGender", operatorGenderData, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true,
              },
            },
          },
          grid: {
            hoverable: true,
            clickable: true,
          },
          legend: {
            show: true,
          },
        });
      }
    };

    var handleVehicleWithNoOperatingLicense = function () {
      "use strict";
      if ($("#VehicleWithNoOperatingLicense").length !== 0) {
        const context = $(document).data("context");

        const colors = [COLOR_PURPLE_LIGHTER, COLOR_BLUE];

        var nooperatinglicense = [
          {
            label: `Yes(${context.GETOPERATORFINSTATS.operatinglicense[0].hasoperatinglicense})`,
            data:
              context.GETOPERATORFINSTATS.operatinglicense[0]
                .hasoperatinglicense,
            color: COLOR_GREEN,
          },
          {
            label: `No(${context.GETOPERATORFINSTATS.operatinglicense[0].nooperatinglicense})`,
            data:
              context.GETOPERATORFINSTATS.operatinglicense[0]
                .nooperatinglicense,
            color: COLOR_RED,
          },
          {
            label: `Expired(${context.GETOPERATORFINSTATS.operatinglicense[0].expiredoperatinglicense})`,
            data:
              context.GETOPERATORFINSTATS.operatinglicense[0]
                .expiredoperatinglicense,
            color: COLOR_PURPLE_LIGHTER,
          },
        ];

        $.plot("#VehicleWithNoOperatingLicense", nooperatinglicense, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true,
              },
            },
          },
          grid: {
            hoverable: true,
            clickable: true,
          },
          legend: {
            show: true,
          },
        });
      }
    };

    var handleVehicleWithNoLicensedisk = function () {
      "use strict";
      if ($("#VehicleWithLicensedisk").length !== 0) {
        const context = $(document).data("context");

        const colors = [COLOR_PURPLE_LIGHTER, COLOR_BLUE];

        var nooperatinglicense = [
          {
            label: `Yes(${context.GETOPERATORFINSTATS.expirylicensedisc[0].haslicense})`,
            data: context.GETOPERATORFINSTATS.expirylicensedisc[0].haslicense,
            color: COLOR_GREEN,
          },
          {
            label: `No(${context.GETOPERATORFINSTATS.expirylicensedisc[0].nolicense})`,
            data: context.GETOPERATORFINSTATS.expirylicensedisc[0].nolicense,
            color: COLOR_RED,
          },
        ];

        $.plot("#VehicleWithLicensedisk", nooperatinglicense, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true,
              },
            },
          },
          grid: {
            hoverable: true,
            clickable: true,
          },
          legend: {
            show: true,
          },
        });
      }
    };

    var handleInteractiveChartWeekly = function () {
      "use strict";
      function showTooltip(x, y, contents) {
        $('<div id="tooltip" class="flot-tooltip">' + contents + "</div>")
          .css({
            top: y - 45,
            left: x - 55,
          })
          .appendTo("body")
          .fadeIn(200);
      }
      if ($("#interactive-chart-threemonths").length !== 0) {
        const context = $(document).data("context");

        

        var data1 = context.GETDASHBOARDFINSTATS.threemonthsinvoices.map(
          (item, index) => {
            return [item.row_number, item.totalinvoices];
          }
        );

        var data2 = context.GETDASHBOARDFINSTATS.threemonthspayments.map(
          (item, index) => {
            return [item.row_number, item.totalpayments];
          }
        );

        var xLabel = context.GETDASHBOARDFINSTATS.threemonthtimeseries.map(
          (item, index) => {
            return [item.row_number, item.rdate];
          }
        );

        $.plot(
          $("#interactive-chart-threemonths"),
          [
            {
              data: data1,
              label: "Invoices",
              color: COLOR_BLUE,
              lines: { show: true, fill: false, lineWidth: 2 },
              points: { show: true, radius: 3, fillColor: COLOR_WHITE },
              shadowSize: 0,
            },
            {
              data: data2,
              label: "Payments",
              color: COLOR_GREEN,
              lines: { show: true, fill: false, lineWidth: 2 },
              points: { show: true, radius: 3, fillColor: COLOR_WHITE },
              shadowSize: 0,
            },
          ],
          {
            xaxis: {
              ticks: xLabel,
              tickDecimals: 0,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
            },
            yaxis: {
              ticks: 10,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
              min: 0,
            },
            grid: {
              hoverable: true,
              clickable: true,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
              borderWidth: 1,
              backgroundColor: "transparent",
              borderColor: COLOR_BLACK_TRANSPARENT_2,
            },
            legend: {
              labelBoxBorderColor: COLOR_BLACK_TRANSPARENT_2,
              margin: 10,
              noColumns: 1,
              show: true,
            },
          }
        );
        var previousPoint = null;
        $("#interactive-chart-threemonths").bind("plothover", function (
          event,
          pos,
          item
        ) {
          $("#x").text(pos.x.toFixed(2));
          $("#y").text(pos.y.toFixed(2));
          if (item) {
            if (previousPoint !== item.dataIndex) {
              previousPoint = item.dataIndex;
              $("#tooltip").remove();
              var y = item.datapoint[1].toFixed(2);

              var content = item.series.label + " " + y;
              showTooltip(item.pageX, item.pageY, content);
            }
          } else {
            $("#tooltip").remove();
            previousPoint = null;
          }
          event.preventDefault();
        });
      }
    };

    var handleInteractiveChartMonthly = function () {
      "use strict";
      function showTooltip(x, y, contents) {
        $('<div id="tooltip" class="flot-tooltip">' + contents + "</div>")
          .css({
            top: y - 45,
            left: x - 55,
          })
          .appendTo("body")
          .fadeIn(200);
      }
      if ($("#interactive-chart-monthly").length !== 0) {
        const context = $(document).data("context");

        

        // var data1 =  context.GETDASHBOARDFINSTATS.dailymonthsinvoicesdaily.map((item, index) => {
        //   return [item.row_number,item.totalinvoices]
        // });

        var data2 = context.GETDASHBOARDFINSTATS.dailymonthspaymentsdaily.map(
          (item, index) => {
            return [item.row_number, item.totalpayments];
          }
        );

        var xLabel = context.GETDASHBOARDFINSTATS.dailymonthtimeseriesdaily.map(
          (item, index) => {
            return [item.row_number, item.rdate];
          }
        );

        $.plot(
          $("#interactive-chart-monthly"),
          [
            // {
            //   data: data1,
            //   label: "Invoices",
            //   color: COLOR_BLUE,
            //   lines: { show: true, fill: false, lineWidth: 2 },
            //   points: { show: true, radius: 3, fillColor: COLOR_WHITE },
            //   shadowSize: 0
            // },
            {
              data: data2,
              label: "Payments",
              color: COLOR_GREEN,
              lines: { show: true, fill: false, lineWidth: 2 },
              points: { show: true, radius: 3, fillColor: COLOR_WHITE },
              shadowSize: 0,
            },
          ],
          {
            xaxis: {
              ticks: xLabel,
              tickDecimals: 0,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
            },
            yaxis: {
              ticks: 10,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
              min: 0,
            },
            grid: {
              hoverable: true,
              clickable: true,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
              borderWidth: 1,
              backgroundColor: "transparent",
              borderColor: COLOR_BLACK_TRANSPARENT_2,
            },
            legend: {
              labelBoxBorderColor: COLOR_BLACK_TRANSPARENT_2,
              margin: 10,
              noColumns: 1,
              show: true,
            },
          }
        );
        var previousPoint = null;
        $("#interactive-chart-monthly").bind("plothover", function (
          event,
          pos,
          item
        ) {
          $("#x").text(pos.x.toFixed(2));
          $("#y").text(pos.y.toFixed(2));
          if (item) {
            if (previousPoint !== item.dataIndex) {
              previousPoint = item.dataIndex;
              $("#tooltip").remove();
              var y = item.datapoint[1].toFixed(2);

              var content = item.series.label + " " + y;
              showTooltip(item.pageX, item.pageY, content);
            }
          } else {
            $("#tooltip").remove();
            previousPoint = null;
          }
          event.preventDefault();
        });
      }
    };

    var handleVehicleWithfinancechartdisk = function () {
      "use strict";

      if ($("#Vehiclefinancechart").length !== 0) {
        const context = $(document).data("context");

        

        var data_ = context.GETOPERATORFINSTATS.vehiclefinchat.map(
          (item, index) => {
            return item.numberofvehicles;
          }
        );

        var data_fdi = context.GETOPERATORFINSTATS.vehiclefinchat.map(
          (item, index) => {
            return item.fdi;
          }
        );

        var options = {
          chart: {
            height: 350,
            type: "bar",
          },
          title: {
            text: "Taxi Finance Chart",
            align: "center",
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "100%",
              endingShape: "rounded",
              dataLabels: {
                position: "top",
              },
            },
          },
          dataLabels: {
            enabled: true,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          colors: [COLOR_BLUE, COLOR_GREEN],
          series: [
            {
              name: "Operators",
              data: data_,
            },
          ],
          xaxis: {
            categories: data_fdi,
            axisBorder: {
              show: true,
              color: COLOR_SILVER_TRANSPARENT_5,
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: -1,
            },
            axisTicks: {
              show: true,
              borderType: "solid",
              color: COLOR_SILVER,
              height: 6,
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            title: {
              text: "",
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return +val;
              },
            },
          },
        };

        var chart = new ApexCharts(
          document.querySelector("#Vehiclefinancechart"),
          options
        );

        chart.render();
      }
    };
  },
};
</script>

<style>
@import "/assets/plugins/nvd3/build/nv.d3.css";
</style>