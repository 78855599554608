<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageVehicle'"
      :ActiveMenu="'AddTaxi'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Add Taxi Vehicle"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi"
        breadcrumbitem3="Add"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- Add Modal HTML START-->
      <div id="AddModal" class="modal fade">
        <div class="modal-dialog">
          <div class="modal-content">
            <form>
              <div class="modal-header">
                <h4 class="modal-title">Add Route</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <!-- begin form-group row -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Name
                      <span class="text-danger"></span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-live-search="true"
                        data-parsley-required="false"
                        class="form-control"
                        v-model="ADDROUTE.name"
                        disabled
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Description
                      <span class="text-danger"></span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-live-search="true"
                        data-parsley-required="false"
                        class="form-control"
                        v-model="ADDROUTE.description"
                        disabled
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Code
                      <span class="text-danger"></span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-live-search="true"
                        data-parsley-required="false"
                        class="form-control"
                        v-model="ADDROUTE.code"
                        disabled
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      National Route Code
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-live-search="true"
                        data-parsley-required="true"
                        class="form-control"
                        v-model="ADDROUTE.national_route_code"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Board Route Code
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-live-search="true"
                        data-parsley-required="true"
                        class="form-control"
                        v-model="ADDROUTE.board_route_code"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Origin
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-live-search="true"
                        data-parsley-required="true"
                        class="form-control"
                        v-model="ADDROUTE.origin"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Destination
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-live-search="true"
                        data-parsley-required="true"
                        class="form-control"
                        v-model="ADDROUTE.destination"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      Taxi Fare
                      <span class="text-danger"></span>
                    </label>
                    <div class="col-md-6">
                      <CurrencyInput
                        :auto-decimal-mode="true"
                        :currency="currency"
                        data-parsley-group="step-1"
                        data-parsley-required="false"
                        class="form-control text-left demo__currency-input"
                        v-model="ADDROUTE.taxifare"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->
                </div>
              </div>
              <div class="modal-footer">
                <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
                <button
                  type="button"
                  class="btn btn-sm btn-success width-80"
                  :disabled="$wait.is('add-')"
                  @click="addRoute(context,ADDROUTE)"
                >
                  <v-wait v-bind:for="'add-'">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Save
                  </v-wait>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Add Modal HTML START-->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-2 col-sm-3 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Vehicle Information
                      <small class="text-ellipsis">Name, Address, ID No and DOB</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-2 col-sm-3 col-6">
                  <a href="#step-2">
                    <span class="number">2</span>
                    <span class="info text-ellipsis">
                      Upload Supporting Documents
                      <small
                        class="text-ellipsis"
                      >ID document, Proof of Address,License ect.....</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-2 col-sm-3 col-6">
                  <a href="#step-3">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Complete Registration</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <fieldset>
                    <div class="alert alert-danger" v-if="percent != null"></div>
                    <!-- begin row -->
                    <div class="row">
                      <!-- begin col-6 -->
                      <div class="col-lg-6">
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Vin Number
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              id="vin_number"
                              name="vin_number"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              placeholder="Enter VIN Number"
                              :class="['form-control',{'is-invalid': isVehicleExists, 'is-valid': !isVehicleExists}]"
                              v-model.lazy="VINNUMBER"
                            />
                            <div class="invalid-tooltip">Please choose a unique and valid VIN No.</div>
                            <div class="valid-tooltip">Looks good!</div>
                          </div>
                        </div>
                        <!-- end form-group -->
                        <br />
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            License Plate Number
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              id="lic_number"
                              name="lic_number"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              placeholder="License Plate Number"
                              :class="['form-control',{'is-invalid': isVehicleLicNumExists, 'is-valid': !isVehicleLicNumExists}]"
                              v-model.lazy="LICENSEPLATE"
                            />
                            <div class="invalid-tooltip">Please enter a unique and valid License Plate.</div>
                            <div class="valid-tooltip">Looks good!</div>
                          </div>
                        </div>
                        <!-- end form-group -->
                        <br />
                        <br />
                        <div class="form-group row">
                          <label class="col-md-4 col-form-label text-md-right">
                            Select Taxi Operator
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              id="selectoperatorpicker"
                              data-size="10"
                              data-live-search="true"
                              data-parsley-required="true"
                            >
                              <option value="0" selected>Select Operator</option>
                              <option
                                :key="item.taxi_operator_id"
                                v-for="item in GETOPERATORS"
                                :value="item.taxi_operator_id"
                              >
                                {{
                                item.firstname +
                                " " +
                                item.surname +
                                "(" +
                                item.id_number +
                                ")"
                                }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Display Name
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              id="displayname"
                              name="displayname"
                              data-parsley-group="step-1"
                              data-parsley-required="false"
                              class="form-control"
                              v-model="ADDTAXIVEHICLE.displayname"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Tara
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              id="tara"
                              name="tara"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              data-parsley-type="alphanum"
                              class="form-control"
                              v-model="ADDTAXIVEHICLE.tara"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Vehicle Reg No.
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="LWE564W"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              data-parsley-type="alphanum"
                              class="form-control"
                              v-model="ADDTAXIVEHICLE.vehicle_register_number"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label
                            class="col-md-4 col-form-label text-md-right"
                          >License Disc Expiry Date</label>
                          <span class="text-danger"></span>
                          <div class="col-md-6">
                            <div
                              class="input-group date"
                              id="licence_expiry_date"
                              data-date-format="dd M yyyy"
                            >
                              <input
                                type="text"
                                class="form-control licence_expiry_date"
                                placeholder="Select Date"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                              />
                              <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- end form-group -->

                        

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label
                            class="col-md-4 col-form-label text-md-right"
                          >Operating License Number</label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholer="EN5444TGDV54C"
                              data-parsley-group="step-1"
                              data-parsley-required="false"
                              class="form-control"
                              v-model="ADDTAXIVEHICLE.operatinglicense_number"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label
                            class="col-md-4 col-form-label text-md-right"
                          >Vehicle Finance Provider</label>
                          <div class="col-md-6">
                            <select class="form-control" id="selectFinance">
                              <option value="0" selected>Select Finance</option>
                              <option
                                v-bind:key="item._id"
                                v-for="item in GETFINANCEPROVIDERS"
                                :value="item._id"
                              >{{ item._name }}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">Insurance Provider</label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              id="selectInsurance"
                              data-parsley-group="step-1"
                              data-parsley-required="false"
                            >
                              <option value="0" selected>Select Insurance</option>
                              <option
                                v-bind:key="item._id"
                                v-for="item in GETFINSURANCEPROVIDERS"
                                :value="item._id"
                              >{{ item._name }}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Tracker Provider
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              id="selectTracker"
                              data-parsley-group="step-1"
                              data-parsley-required="false"
                            >
                              <option value="0" selected>Select Tracker</option>
                              <option
                                v-bind:key="item._id"
                                v-for="item in GETTRACKERPROVIDERS"
                                :value="item._id"
                              >{{ item._name }}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->
                      </div>
                      <div class="col-lg-6">
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Engine Number
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              value="EN54445454154"
                              id="reg_number"
                              name="reg_number"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              class="form-control"
                              v-model="ADDTAXIVEHICLE.engine_number"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Engine Type
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              id="selectEngine"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                            >
                              <option
                                v-bind:key="item._id"
                                v-for="item in GETALLENGINETYPES"
                                :value="item._id"
                              >{{ item._name }}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Transmission Type
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              id="selectTransmission"
                              data-live-search="true"
                              data-size="10"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                            >
                              <option
                                v-bind:key="item._id"
                                v-for="item in GETALLTRANSMISIONTYPES"
                                :value="item._id"
                              >{{ item._name }}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Color
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              id="selectColor"
                              data-live-search="true"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                            >
                              <option
                                v-bind:key="item._id"
                                v-for="item in GETALLCOLORS"
                                :value="item._id"
                              >{{ item._name }}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Make/Model
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              id="selectModel"
                              data-size="10"
                              data-live-search="true"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                            >
                              <option
                                v-bind:key="item._vehiclemodel_id"
                                v-for="item in GETALLMODEL"
                                :value="item._vehiclemodel_id"
                              >
                                {{
                                item._vehiclemake_name + " " + item._name
                                }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Vehicle Joined Date
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <div
                              class="input-group date"
                              id="vehicle_joined_date"
                              data-date-format="dd M yyyy"
                            >
                              <input
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                type="text"
                                class="form-control"
                                placeholder="Select Date"
                              />
                              <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Billing Route
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              id="selecttaxiroute"
                              data-parsley-required="true"
                              data-parsley-group="step-1"
                            >
                              <option
                                v-bind:key="item._id"
                                v-for="item in ASSOCIATIONROUTES"
                                :value="item._id"
                              >{{item._name}}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">Operating Routes</label>
                          <div class="col-md-8">
                            <div class="input-group">
                              <div class="col-md-7">
                              <span class="input-group-prepend">
                                <select
                                  class="form-control"
                                  id="selectoperatingroute"
                                  data-parsley-required="true"
                                  data-parsley-group="step-1"
                                >
                                  <option
                                    v-bind:key="item._id"
                                    v-for="item in OPERATINGROUTES"
                                    :value="item._id"
                                  >{{item._name}}</option>
                                </select>
                                <a
                                  href="javascript:;"
                                  data-toggle="modal"
                                  data-target="#AddModal"
                                  class="btn btn-sm btn-grey"

                                >
                                  <i class="fa fa-plus fa-md m-t-5"></i>
                                </a>
                              </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <div class="form-group row m-b-10">
                          <label
                            class="col-md-4 col-form-label text-md-right"
                          >Operator License Issue Date</label>
                          <span class="text-danger"></span>
                          <div class="col-md-6">
                            <div
                              class="input-group date"
                              id="date_of_birth"
                              data-date-format="dd M yyyy"
                            >
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Select Date"
                                data-parsley-group="step-1"
                              />
                              <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>

                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Operator License Expiry Date
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <div
                              class="input-group date"
                              id="operatorlicense_expiry_date"
                              data-date-format="dd M yyyy"
                            >
                              <input
                                data-parsley-group="step-1"
                                type="text"
                                class="form-control"
                                placeholder="Select Date"
                              />
                              <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">Cross Border</label>
                          <div class="col-md-6">
                            <div class="switcher switcher-success">
                              <input
                                type="checkbox"
                                name="switcher_checkbox_hasoverdraft"
                                id="switcher_checkbox_hasoverdraft"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="ADDTAXIVEHICLE.cross_border"
                              />
                              <label for="switcher_checkbox_hasoverdraft"></label>
                            </div>
                          </div>
                        </div>

                        <br />
                      </div>
                    </div>
                    <br />
                    <!--Start Table!-->
                    <div
                      class="panel panel-inverse panel-primary"
                      data-sortable-id="table-basic-7"
                      v-show="SELECTEDOPERATORS.firstname"
                    >
                      <!-- begin panel-heading -->
                      <div class="panel-heading ui-sortable-handle">
                        <h4 class="panel-title">Owner Details</h4>
                      </div>
                      <!-- end panel-heading -->
                      <!-- begin panel-body -->
                      <div class="panel-body">
                        <!-- begin table-responsive -->
                        <div class="table-responsive">
                          <!--Table-->
                          <table class="table">
                            <!--Table head-->
                            <thead>
                              <tr>
                                <th class="th-lg">Name Surname</th>
                                <th class="th-lg">ID Number</th>
                                <th class="th-lg">Membership Number</th>
                                <th class="th-lg">Number Of Taxis</th>
                              </tr>
                            </thead>
                            <!--Table head-->

                            <!--Table body-->
                            <tbody>
                              <tr>
                                <td>
                                  {{
                                  SELECTEDOPERATORS.firstname +
                                  " " +
                                  SELECTEDOPERATORS.surname
                                  }}
                                </td>
                                <td>{{ SELECTEDOPERATORS.id_number }}</td>
                                <td>{{ SELECTEDOPERATORS.membership_number }}</td>
                                <td>
                                  {{
                                  SELECTEDOPERATORS.current_number_vehicles
                                  }}
                                </td>
                              </tr>
                            </tbody>
                            <!--Table body-->
                          </table>
                          <!--Table-->
                        </div>
                      </div>
                      <!-- end panel-body -->
                    </div>
                    <br />
                    <!-- <div class="col-md-12">
                      <div class="card-group">
                        <div :key="item" v-for="item in VEHICLEIMAGES" class="card height-20">
                          <img
                            class="width:40px; height:20px;"
                            :src="`data:image/png;base64,${item.data}`"
                            alt="Card image cap"
                          />
                        </div>
                      </div>
                    </div>-->
                    <div class="col-lg-9 p-t-20" v-if="id!=':id'">
                      <div id="gallery" class="gallery m-r-40 pull-right">
                        <!-- begin image -->
                        <div class="image width-full height-400">
                          <div class="image-inner">
                            <a :href="VEHICLEIMAGES" data-lightbox="gallery-group-2">
                              <img :src="VEHICLEIMAGES" class="height-400" />
                            </a>
                          </div>
                        </div>
                        <!-- end image -->
                      </div>
                    </div>
                  </fieldset>
                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->

                <!-- begin step-2 -->
                <div id="step-2">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->

                    <p class="text-center h4 mb-4">Upload Supporting Documents</p>
                    <div class="panel panel-primary" data-sortable-id="form-dropzone-1">
                      <div class="panel-heading">
                        <div class="panel-heading-btn"></div>
                        <h4 class="panel-title">Drop Files In Here</h4>
                      </div>
                      <div class="panel-body text-inverse">
                        <DropZoneControl
                          :acceptedFiles="acceptedFiles"
                          :ClearData="true"
                          v-on:fileadded="fileadded"
                          :SessionID="DropZoneSessionID"
                        ></DropZoneControl>
                      </div>
                    </div>
                    <br />
                    <!--End dropzone!-->

                    <br />
                    <!--Start Table!-->
                    <div class="panel panel-primary" data-sortable-id="table-basic-7">
                      <!-- begin panel-heading -->
                      <div class="panel-heading ui-sortable-handle">
                        <h4 class="panel-title">Uploaded Files</h4>
                      </div>
                      <!-- end panel-heading -->
                      <!-- begin panel-body -->
                      <div class="panel-body">
                        <!-- begin table-responsive -->
                        <div class="table-responsive">
                          <table class="table table-striped m-b-0">
                            <thead>
                              <tr>
                                <th>Filename</th>
                                <th>Type</th>
                                <th>Size</th>
                                <th width="40%">Document Type</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr :key="item" v-for="(item) in GETUPLOADEDFILES">
                                <td>{{item.filename}}</td>
                                <td>{{item.mimetype}}</td>
                                <td>{{item.size}}</td>
                                <td>
                                  <select
                                    class="form-control col-sm-4"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    data-parsley-required="true"
                                    v-model="item.customType"
                                  >
                                    <option value="0" selected>Select Document Type</option>
                                    <option
                                      v-bind:key="item._id"
                                      v-for="item in GETALLDOCUMENTTYPE"
                                      :value="item._id"
                                    >{{item._name}}</option>
                                  </select>
                                </td>
                                <td class="with-btn" nowrap>
                                  <a
                                    href="#"
                                    @click="deletefile(context,item.fileId, USERPROFILE.system_usersid)"
                                    class="btn btn-sm btn-danger width-60"
                                  >Delete</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end table-responsive -->
                      </div>
                      <!-- end panel-body -->
                      <!-- begin hljs-wrapper -->
                    </div>
                    <div class="alert alert-secondary fade show">
                      <div class="text-center">
                        <!-- <button type="submit" class="btn btn-outline-secondary">Save To Drafts</button> -->
                      </div>
                    </div>
                    <!-- end row -->
                  </fieldset>
                </div>
                <!-- end step-3 -->

                <!-- begin step-4 -->

                <div id="step-3">
                  <div class="jumbotron m-b-0 text-center">
                    <h2 class="display-4">Taxi Vehicle Has been Successfully Added</h2>
                    <p v-show="false" class="lead mb-4">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Mauris consequat commodo porttitor.
                      <br />Vivamus eleifend, arcu in tincidunt semper, lorem
                      odio molestie lacus, sed malesuada est lacus ac ligula.
                      Aliquam bibendum felis id purus ullamcorper, quis luctus
                      leo sollicitudin.
                    </p>

                    <br />
                    <br />

                    <p>
                      <router-link
                        to="/Vehicles/List"
                        tag="button"
                        v-bind:class="{ 'btn btn-primary btn-lg': true }"
                      >Proceed To Taxi Operator Vehicles</router-link>
                    </p>
                    <p class="text-center">
                      <qr-code error-level="L" :text="context.vehicleQRCode"></qr-code>
                    </p>
                  </div>
                </div>
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>

        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";
// import DropZoneControl2 from "@/components/common/DropZoneControl.vue";
import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import VueQRCodeComponent from "vue-qrcode-component";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("OperatorVehicle");

// Vue.component(VueQrcode.name, VueQrcode);

export default {
  name: "ADDVEHICLE",
  data: function () {
    return {
      currencySymbol: "R ",
      acceptedFiles: "application/pdf",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      GETALLENGINETYPES: [],
      GETALLTRANSMISIONTYPES: [],
      GETALLCOLORS: [],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      SELECTEDASSOCIATION: this.$store.getters["auth/SELECTEDTAXIASSOCIATION"],
      ASSOCIATIONROUTES: [],
      OPERATINGROUTES: [],
      GETALLDOCUMENTTYPE: [],
      taxi_operator_vehicles_id: 0,
      isVehicleExists: true,
      isVehicleLicNumExists: true,
      VINNUMBER: "",
      LICENSEPLATE:"",
      // taxi_operator_vehicles_id: this.$
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      ADDROUTE: {
        taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        name: "",
        description: "",
        taxifare: 0,
        code: "",
        national_route_code: '',
        board_route_code: '',
        origin: '',
        destination: '',
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      GETOPERATORS: [],
      // GETROUTES: this.$store.getters["TA/TAXIASSOCIATIONSROUTES"],
      SELECTEDOPERATORS: {},
      GETUPLOADEDFILES: [],
      ADDEDVEHICLEID: {},
      selectVehicleMakeId: 1,
      id: this.$route.params.id,
      DropZoneSessionID: this.$uuid.v4(),
      CapturedPhotosSessionID: this.$uuid.v4(),
      dropzone_files: this.$store.getters["fileupload/GETALL"],
      current_number_vehicles: 0,
      maximum_number_vehicles: 0,
      percent: null,
      vehicleQRCode: "",
      hacksmartwizard: false,
      GETALLMODEL: [],
      status: true,
      skip: 0,
      len: 10000,
      ADDTAXIVEHICLE: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        taxi_operator_id: 0,
        vehiclemodel_id: 0,
        displayname: "",
        vin_number: "",
        licence_number: "",
        vehicle_register_number: "",
        added_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
        tara: "",
        engine_number: "",
        vehicle_joined_date: "",
        vehicle_color_id: 0,
        vehicle_engine_id: 0,
        vehicle_transmission_type_id: 0,
        licence_expiry_date: "",
        taxi_association_routes_id: 0,
        dropzoneid: "",
        dropzone_files: [
          {
            customType: 0,
            fileId: "",
          },
        ],
        datacollection_id: this.$uuid.v4(),
        operatinglicense_issuedate: "",
        operatinglicense_expirydate: "",
        operatinglicense_number: "",
        cross_border: false,
        vehicle_finance_institution_id: 0,
        vehicle_insurance_institution_id: 0,
        vehicle_tracker_institution_id: 0,
        operating_route_ids: [],
        GETVEHICLEROUTES: [],
        GETFINANCEPROVIDERS: [],
        GETFINSURANCEPROVIDERS: [],
        GETTRACKERPROVIDERS: [],
      },
      VEHICLEIMAGES:null,
      DATACOLLECTION: this.$store.getters["OperatorVehicle/GETDATACOLLECTED"],
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    // DropZoneControl2,
    CapturePhotosControl,
    "qr-code": VueQRCodeComponent,
  },

  computed: {
    ...mapGetters([
    ]),
    currency() {
      return { [this.position]: this.currencySymbol };
    },
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  watch: {
    VINNUMBER: function (val) {
      if (val.toString().length > 3) {
        this.context.$store
          .dispatch("OperatorVehicle/searchVehicleVINnumber", {
            _getPagingModel: {
              id: this.taxiassociationid,
              text: val,
            },
            router: this.$router,
          })
          .then((rb) => {
            this.isVehicleExists = rb.data[0].fn_taxi_operator_vehicleexists;
          });
      }
    },
    LICENSEPLATE: function (val) {
      if (val.toString().length > 5) {
        this.context.$store
          .dispatch("OperatorVehicle/searchVehicleRegNumber", {
            _getPagingModel: {
              id: this.taxiassociationid,
              text: val,
            },
            router: this.$router,
          })
          .then((rb) => {
            this.isVehicleLicNumExists = rb.data[0].fn_taxi_operator_vehicleexistsbyregnumber;
          });
      }
    },
  },
  beforeMount: function () {
    this.getAllOperatorByTaxiAssId(this.context);
    

    // this.percentage2(this.context);

    // 
  },

  methods: {
    ...mapActions([
      "getall",
      "getById",
      "getAllByTaxiAssID",
      "delete",
      "edit",
      "add",
      "restoreDeleted",
      "getTaxiAssRoutes",
    ]),

    // getLicenseExpiryDate: () => {
    //   const _converteddate = context.$Toolkit.ConvertToServerDate(
    //     $("#expirydate").datepicker("getDate")
    //   );
    // },

    clearOperators: (context) => {
      context.$store
        .dispatch("TO/clearAll", {})
        .then(() => {})
        .catch((error) => {
          console.log("operator errors: ", error);
        });
    },

    getAllOperatorByTaxiAssId: (context) => {
      context.$store
        .dispatch("TO/getAllByTaxiAssIdNoPic", {
          router: context.$router,
          id: context.taxiassociationid,
        })
        .then((data) => {
          console.log("operators found: ", data);
          context.GETOPERATORS = data;
        })
        .catch((error) => {
          console.log("operator errors: ", error);
          context.$Notify.Error(error.data[0].message, "");
        });
    },

    getRoutesById: (context, taxiassociationid) => {
      context.$store
        .dispatch("TA/getTaxiAssRoutes", {
          router: context.$router,
          id: taxiassociationid,
        })
        .then((data) => {
          console.log("Association Routes ", data);
          context.ASSOCIATIONROUTES = data;
          context.OPERATINGROUTES = data;
        })
        .catch((error) => {
          console.log("Association Routes by id errors: ", error);
        });
    },

    loadVehicleinfo(context) {
      if (context.id != ":id") {
        context.$store
          .dispatch("OperatorVehicle/getDatacollectedVehicleImage", {
            router: context.$router,
            id: context.id,
          })
          .then((_data) => {
              context.ADDTAXIVEHICLE.vehicle_register_number =
                _data.vehicleregnum;
              context.ADDTAXIVEHICLE.licence_number =
                _data.licenseno;
                context.LICENSEPLATE = _data.licenseno;
              context.ADDTAXIVEHICLE.engine_number =
                _data.engineno;
              context.ADDTAXIVEHICLE.licence_expiry_date = _data.expirydate;
              $('#licence_expiry_date').datepicker('update', _data.expirydate);

              //$('.licence_expiry_date').val(_data.expirydate);
                context.VINNUMBER =
                _data.vinno;
              context.ADDTAXIVEHICLE.vin_number =
                _data.vinno;
          })
          .catch((error) => {
            console.log("this is the vehicle info getter errors: ", error);
          })
          .finally(() => {});
      }
    },
    loadVehicleImages(context) {
      // if (context.id != ":id")
      //   context.VEHICLEIMAGES = context.DATACOLLECTION.filter(el => el.datacollection_id == context.id)[0].taxilicencedisk_image[0].data;
    },

    fileadded: function (data) {
      console.log("Header:fileadded");
      console.log(data);
      //  this.$emit('init')
      const context = $("#wizard").data("context");
      context.GETUPLOADEDFILES = data;
    },

    getAllVehicleTracker: (context) => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleTracker", {
          status: context.status,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          console.log("vehicle trackers: ", data);
          
        context.GETTRACKERPROVIDERS = data;
        })
        .catch((error) => {
          console.log("vehicle trackers errors: ", error);
        });
    },

    getAllVehiclemodels: (context) => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleModels", {
          router: context.$router,
        })
        .then((data) => {
          console.log("vehicle models: ", data);

          context.GETALLMODEL = data;
        })
        .catch((error) => {
          console.log("vehicle models errors: ", error);
        });
    },

    getVehicleFinance: (context) => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleFinance", {
          status: context.status,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          console.log("vehicle finance: ", data);
          context.GETFINANCEPROVIDERS = data;
        })
        .catch((error) => {
          console.log("vehicle finance errors: ", error);
        });
    },

    getVehicleInsurance: (context) => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleInsurance", {
          status: context.status,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          console.log("vehicle insurance: ", data);
          context.GETFINSURANCEPROVIDERS = data;
        })
        .catch((error) => {
          console.log("vehicle insurance errors: ", error);
        });
    },

    addRoute: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TA/addRouteByNationalCode", {
              AddModel: value.item,
              router: value.router
            })
            .then((result) => {
              console.log("Route Added successfully: ", result);
              value.context.$Notify.Success("Successfully Added!", "");
              $("#AddModal").modal("hide");
              context.ADDROUTE.name = "";
              context.ADDROUTE.description = "";
              context.ADDROUTE.taxifare = 0;
              context.ADDROUTE.code = "";
              context.ADDROUTE.national_route_code =  "",
              context.ADDROUTE.board_route_code =  "",
              context.ADDROUTE.origin =  "",
              context.ADDROUTE.destination =  "",
              context.getRoutesByAssId(context);
            })
            .catch((error) => {
              value.context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        }
      });
    },

    add: (context, item, taxiassociationid, added_by) => {
      context.$Notify
        .YesNo(false, { context, item: context.ADDTAXIVEHICLE })
        .then((value) => {
          

          const dropzone_uploads = context.GETUPLOADEDFILES;
          const fileuploaded = [];

          if (dropzone_uploads.length > 0) {
            var failedvalidation = false;
            dropzone_uploads.forEach((element) => {
              if (element.customType === 0) {
                failedvalidation = true;
                return;
              }
              fileuploaded.push({
                customType: element.customType,
                fileId: element.fileId,
              });
            });
            if (failedvalidation) {
              value.context.$Notify.Error("Please Select Document Type.", "");
              return;
            }
          }

          value.item.dropzone_files = fileuploaded;
          // value.item.photosessionid = context.CapturedPhotosSessionID;
          value.item.dropzoneid = context.DropZoneSessionID;
          value.item.vehicle_joined_date = context.$Toolkit.ConvertToServerDate(
            $("#vehicle_joined_date").datepicker("getDate")
          );

          value.item.licence_expiry_date = context.$Toolkit.ConvertToServerDate(
            $("#licence_expiry_date").datepicker("getDate")
          );

          value.item.operatinglicense_issuedate = context.$Toolkit.ConvertToServerDate(
            $("#date_of_birth").datepicker("getDate")
          );

          value.item.operatinglicense_expirydate = context.$Toolkit.ConvertToServerDate(
            $("#operatorlicense_expiry_date").datepicker("getDate")
          );

          value.item.vehicle_insurance_institution_id = parseInt(
            $("#selectInsurance").val()
          );
          value.item.vehicle_tracker_institution_id = parseInt(
            $("#selectTracker").val()
          );
          value.item.vehicle_finance_institution_id = parseInt(
            $("#selectFinance").val()
          );
          value.item.taxi_operator_id = parseInt(
            $("#selectoperatorpicker").val()
          );

          value.item.taxi_association_routes_id = parseInt(
            $("#selecttaxiroute").val()
          );
          value.item.vehiclemodel_id = parseInt($("#selectModel").val());
          value.item.vehicle_color_id = parseInt($("#selectColor").val());
          value.item.vehicle_transmission_type_id = parseInt(
            $("#selectTransmission").val()
          );
          value.item.vehicle_engine_id = parseInt($("#selectEngine").val());

          const operatingroutes = $("#selectoperatingroute")
            .select2("data")
            .map((item) => {
              console.log("users: ", item);
              return item.id;
            });

          console.log("", operatingroutes);

          value.item.operating_route_ids = [...operatingroutes];

          $(".sw-btn-next").text("Loading...").attr("disabled", "disabled");

          if (value) {
            
            // value.context.$wait.start("add");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("OperatorVehicle/add", {
                _dataObj: value,
              })
              .then((_rb) => {
                console.log("The added vehicle information: ", _rb);

                var taxiassociationid = context.taxiassociationid;
                var taxiassociationidString = taxiassociationid.toString();

                var operatorid = _rb[0].taxi_operator_id;
                var operatoridString = operatorid.toString();

                var vehicleid = _rb[0].taxi_operator_vehicles_id;
                var vehicleidString = vehicleid.toString();

                var associationName = "No name for now";

                var operatorName = _rb[0]._taxi_operators_name;

                var vehicleVinNumber = _rb[0]._vin_number;

                var newString = `%${taxiassociationidString},${operatoridString},${vehicleidString},${associationName}, ${associationName},${operatorName},${vehicleVinNumber}%`;

                console.log("newString code: ", newString);
                context.vehicleQRCode = newString;

                console.log("vehicleQRCode: ", context.vehicleQRCode);

                // %association_id, %operator_id, %vehicle_id, %association_name,
                //%Operator_firstname & surname, %vin_number;
                // value.context.$wait.end("add");
                value.context.$Notify.Success(
                  "Taxi Vehicle Add Request Has Been Sent!"
                );
                $(".sw-btn-next").text("Save").attr("disabled", "disabled");
                $("#wizard").data("taxivehicleadded", true);
                $("#wizard").smartWizard("next");
              })
              .catch((error) => {
                console.log("vehicle errors: ", error);
                $(".sw-btn-next").text("Next").removeAttr("disabled");
                $("#wizard").data("taxivehicleadded", false);

                value.context.$Notify.Error(error.data.message, "");
              })
              .finally(() => {});
          }
        });
    },
    loadLookUps(context) {
      
      context.$store
        .dispatch("lookups/init", {
          router: context.$router,
        })
        .then((data) => {
          console.log("loadLookUps found: ", data);
          
          context.GETALLENGINETYPES = data.LOADEDENGINE;
          context.GETALLCOLORS = data.LOADEDCOLORS;
          context.GETALLDOCUMENTTYPE = data.LOADEDDOCUMENTTYPE;
          context.GETALLTRANSMISIONTYPES = data.LOADEDTRANSMISSION;
        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
  },
  beforeDestroy: function () {
    this.clearOperators(this.context);
  },

  mounted: function () {
    // Vue.component(VueQrcode.name, VueQrcode);

    App.init();

    this.loadLookUps(this.context);
    this.getAllOperatorByTaxiAssId(this.context);
    this.getRoutesById(this.context, this.taxiassociationid);
    this.loadVehicleinfo(this.context);
    this.loadVehicleImages(this.context);
    this.getAllVehicleTracker(this.context);
    this.getAllVehiclemodels(this.context);
    this.getVehicleFinance(this.context);
    this.getVehicleInsurance(this.context);

    $("#selectoperatorpicker").select2({
      multiple: false,
      placeholder: "Select A Taxi Operator",
      allowClear: false,
    });

    $("#selectFinance").select2({
      multiple: false,
      placeholder: "Select Financer",
      allowClear: false,
    });

    $("#date_of_birth").datepicker({
      todayHighlight: false,
      autoclose: true,
      startDate: date,
    });

    $("#selecttaxiroute").select2({
      multiple: false,
      placeholder: "Select A Taxi Route",
      allowClear: true,
    });

    $("#selectoperatingroute").select2({
      multiple: true,
      placeholder: "Select Operating Route",
      allowClear: true,
    });

    $("#selectTracker").select2({
      multiple: false,
      placeholder: "Select a Tracker",
      allowClear: false,
    });

    $("#selectEngine").select2({
      multiple: false,
      placeholder: "Select Engine",
      allowClear: true,
    });

    $("#selectTransmission").select2({
      multiple: false,
      placeholder: "Select Transmission",
      allowClear: true,
    });

    $("#selectColor").select2({
      multiple: false,
      placeholder: "Select Color",
      allowClear: true,
    });

    $("#selectModel").select2({
      multiple: false,
      placeholder: "Select A Model",
      allowClear: true,
    });

    $("#selectInsurance").select2({
      multiple: false,
      placeholder: "Select Insurer",
      allowClear: false,
    });

    $("#vehicle_joined_date").datepicker({
      todayHighlight: true,
      autoclose: true,
      startDate: date,
    });

    $("#licence_expiry_date").datepicker({
      todayHighlight: false,
      autoclose: true,
      startDate: date,
    });

    $("#operatorlicense_issue_date").datepicker({
      todayHighlight: false,
      autoclose: true,
      startDate: date,
    });

    $("#operatorlicense_expiry_date").datepicker({
      todayHighlight: true,
      autoclose: true,
      startDate: date,
    });

    $("#step-2").data("context", this.context);

    $("#wizard").data("taxivehicleadded", false);

    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "",
      transitionSpeed: 0,
      useURLhash: false,
      showStepURLhash: false,
      toolbarSettings: {
        toolbarPosition: "bottom",
      },
    });
    $("#wizard").data("context", this.context);
    $("#wizard").on("leaveStep", function (
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {
      const currentstep = stepNumber + 1;
      const res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

        const context = $("#wizard").data("context");
        if(context.isVehicleExists){
          context.$Notify.Error("Vehicle with this VIN number already exists", "");
          return false;
        }

      $(".sw-btn-next").text("Next");

      if (stepDirection == "backward") return true;

      if (currentstep === 1 && stepDirection == "forward") {
        $(".sw-btn-next").text("Save");
      }

      if (currentstep === 2 && stepDirection == "forward") {
        const context = $("#wizard").data("context");
        context.ADDTAXIVEHICLE.vin_number = context.VINNUMBER;
        context.ADDTAXIVEHICLE.licence_number = context.LICENSEPLATE;
        const taxivehicleadded = $("#wizard").data("taxivehicleadded");
        if (!taxivehicleadded) {
          const context = $("#wizard").data("context");
          
           
          context.add(
            context,
            context.item,
            context.taxiassociationid,
            context.added_by
          );
        }
        return taxivehicleadded;
      }

      return res;
    });

    //  $(".datepicker-disabled-past").datepicker("update", new Date());
    //disable

    var date = new Date();

    function calculateDivider() {
      var dividerValue = 4;
      if ($(this).width() <= 480) {
        dividerValue = 1;
      } else if ($(this).width() <= 767) {
        dividerValue = 2;
      } else if ($(this).width() <= 980) {
        dividerValue = 3;
      }
      return dividerValue;
    }
    var handleIsotopesGallery = function () {
      "use strict";
      var container = $("#gallery");
      var dividerValue = calculateDivider();
      var containerWidth = $(container).width();
      var columnWidth = containerWidth / dividerValue;
      $(container).isotope({
        resizable: true,
        masonry: {
          columnWidth: columnWidth,
        },
      });

      $(window).smartresize(function () {
        var dividerValue = calculateDivider();
        var containerWidth = $(container).width();
        var columnWidth = containerWidth / dividerValue;
        $(container).isotope({
          masonry: {
            columnWidth: columnWidth,
          },
        });
      });

      var $optionSets = $("#options .gallery-option-set"),
        $optionLinks = $optionSets.find("a");

      $optionLinks.click(function () {
        var $this = $(this);
        if ($this.hasClass("active")) {
          return false;
        }
        var $optionSet = $this.parents(".gallery-option-set");
        $optionSet.find(".active").removeClass("active");
        $this.addClass("active");

        var options = {};
        var key = $optionSet.attr("data-option-key");
        var value = $this.attr("data-option-value");
        value = value === "false" ? false : value;
        options[key] = value;
        $(container).isotope(options);
        return false;
      });
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
