<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'PrepaidServices'"
      :ActiveMenu="'AddMerchant'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Add Merchant"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Prepaid Services"
        breadcrumbitem3="Add Merchant"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Personal Info
                      <small class="text-ellipsis">Name, Address, ID No and DOB</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-2">
                    <span class="number">2</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Complete Registration</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->
                    <div class="row">

                      <!-- begin col-8 -->
                      <div class="col-md-8 offset-md-2">
                        <legend class="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-inverse"></legend>

                        <!-- begin form-group -->

                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            System User
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              data-size="10"
                              data-live-search="true"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              data-parsley-min="1"
                              v-model="ADDMERCHANTACCOUNT.systemusers_id"
                              
                            >
                              <option value="0" selected>Select System User</option>
                              <option
                              v-for="item in GETSYSTEMUSERS"
                              :key="item._id"
                              :value="item._id"
                            >{{item._firstname}} {{item._surname}}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            Overdraft Limit Amount
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="Number"
                              name="surname"
                              autocomplete="off"
                              data-parsley-group="step-1"
                              data-parsley-required="false"
                              class="form-control"
                              v-model="ADDMERCHANTACCOUNT.overdraft_limit"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">Has Overdraft</label>
                          <div class="col-md-6">
                            <div class="switcher switcher-success">
                              <input
                                type="checkbox"
                                name="switcher_checkbox_hasoverdraft"
                                id="switcher_checkbox_hasoverdraft"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="ADDMERCHANTACCOUNT.over_draft"
                              />
                              <label for="switcher_checkbox_hasoverdraft"></label>
                            </div>
                          </div>
                        </div>

                      </div>
                      <!-- end col-8 -->
                    </div>
                    <!-- end row -->
                  </fieldset>
                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->
                <!-- begin step-2 -->
                <div id="step-2">
                  <div class="jumbotron m-b-0 text-center">
                    <h2 class="text-inverse">Merchant Registered Successfully</h2>
                    
                    <p>
                      <a href="#" class="btn btn-primary btn-lg hide">Proceed to User Profile</a>
                    </p>
                  </div>
                </div>
                <!-- end step-2 -->
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("lookups");

export default {
  name: "AddSystemUser",
  data: function () {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      SERPROFILE: this.$store.getters["auth/USERPROFILE"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      selectedUserGroupId: 0,
      GETALLUSERGROUPS: [],
      resolutionWidth: undefined,
      resolutionHeight: undefined,
      isUserNameExists: true,
      hacksmartwizard: false,
      GETSYSTEMUSERS: [],
      USERNAME: "",
      ADDMERCHANTACCOUNT: {
        taxiassociations_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        systemusers_id: 0,
        capturedby_id: this.$store.getters["auth/USERPROFILE"].system_usersid,
        over_draft: false,
        overdraft_limit:0
      },
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    CapturePhotosControl,
  },
  beforeMount() {
    this.getSystemUsers(this.context);
    // this.getUserGrousByTaxiAssId(this.context);
  },
  computed: {
    ...mapGetters(["GETALLTITLES", "GETALLGENDERS"]),
  },
  watch: {
    USERNAME: function (val) {
      if (val.toString().length > 3) {
        this.context.$store
          .dispatch("systemusers/getSearchUsername", {
            router: this.$router,
            id: val,
          })
          .then((rb) => {
            this.isUserNameExists = rb.data[0].fn_system_users_usernameexists;
          });
      }
    },
  },
  methods: {
    ...mapActions([
      "getall",
      "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted",
    ]),
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    clearAdded: (item) => {
      for (var propName in item) {
        item[propName] = undefined;
      }
    },
    init: (file) => {},
    getByTaxiAssId: (context) => {
      context.$store
        .dispatch("systemusers/getByTaxiAssId", {
          _dataObj: {
            router: context.$router,
            id: context.taxi_association_id,
          },
        })
        .then(() => {
          App.init();
          TableManageCombine.init();
          Highlight.init();
        });
    },

    getSystemUsers: context => {
      context.$store
        .dispatch("systemusers/getByTaxiAssId", {
        id: context.taxi_association_id,
        router : context.$router,
        userid : context.USERID
      }).then(data => {
          console.log("System users Loaded: ", data);

          context.GETSYSTEMUSERS = data;
        })
        .catch(error => {
          console.log(error);
          context.$Notify.Error(error, "");
        });
    },  

    getUserGrousByTaxiAssId: (context) => {
      context.$store
        .dispatch("usergroups/getByTaxiAssId", {
          router: context.$router,
          id: context.taxi_association_id,
          getbyid: context.USERID,
        })
        .then(() => {
          context.GETALLUSERGROUPS =
            context.$store.getters["usergroups/GETALL"];
        });
    },

    addMerchantAccount: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          
          value.router = value.context.$router;
          value.context.$store
            .dispatch("prepaidservices/addMerchantAccount", {
              AddModel: value.item,
              router: value.router,
            })
            .then((data) => {
              
               $(".sw-btn-next").text("Done").attr("disabled", "disabled");
                $(".sw-btn-prev").attr("disabled", "disabled");
                $("#wizard").data("merchantadded", true);
                $("#wizard").smartWizard("next");
              value.context.$Notify.Success(
                "Merchant Added Successfully!",
                ""
              );
              // context.$wait.end("submit");

              console.log("merchant results: ", data);
            })
            .catch((error) => {
              // context.$wait.end("submit");
              
              context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {
              
            });
        }
      });
    },
  },
  mounted: function () {
    //this.get(this.context);

    App.init();

    $("#wizard").data("merchantadded", false);

    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "slide",
      transitionSpeed: 400,
      useURLhash: false,
      showStepURLhash: false,
      toolbarSettings: {
        toolbarPosition: "bottom",
      },
    });

    $("#wizard").data("context", this.context);

    $("#wizard").on("leaveStep", function (
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {
      const context = $("#wizard").data("context");
      const currentstep = stepNumber + 1;
      var res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

      if (context.hacksmartwizard) return true;

      $(".sw-btn-next").text("Submit");

      if (currentstep === 1 && stepDirection == "forward") {
        $(".sw-btn-next").text("Submit");
        const merchantadded = $("#wizard").data("merchantadded");
        if (!merchantadded) {

          context.ADDMERCHANTACCOUNT.overdraft_limit = parseInt(context.ADDMERCHANTACCOUNT.overdraft_limit);
          context.addMerchantAccount(context, context.ADDMERCHANTACCOUNT);
        }

        return merchantadded;
        
      }

      if (currentstep === 2 && stepDirection == "forward") {
        
      }

      return res;
    });
  },
};
</script>

<style scoped>
</style>

