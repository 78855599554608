/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import format from 'string-format';
import conf from '../../configurations/config';
import config from '../../configurations/configbase';

export default {
  namespaced: true,
  state: {
    taxi_driver: [],
    taxi_drivers: [],
    taxi_driversNoPic: [],
    taxi_driversByIdNoPic: [],
    driver_numbers: [],
    driver_timeline: [],
    driver_employmenthistory: [],
    driver_docs: [],
    driver_transactions: [],
    driver_invoices: [],
    driver_receipts: [],
    driver_vehicle: [],
    driver_termination: [],
    driver_status: [],
    driver_billings: [],
    taxi_driverByAffiliation: [],
    taxi_drivers_pendingVerification: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETUPLOADEDFILES: (state, getters, rootState, rootGetters) => {
      return rootGetters['fileupload/GETALL']
    },
    GETSTATUSBYASSID: (state, getters, rootState, rootGetters) => {
      return rootGetters['lookups/GETDRIVERSTATUSBYID']
    },
    GETTAXIDRIVER: state => state.taxi_driver,
    GETTAXIDRIVEREDIT: state => state.taxi_driver,
    GETDRIVERSBYASSID: state => state.taxi_drivers,
    GETDRIVERNUMBERS: state => state.driver_numbers,
    GETDRIVERTIMELINE: state => state.driver_timeline,
    GETDRIVEREMPLOYMENTHISTORY: state => state.driver_employmenthistory,
    GETDRIVERTRANSACTIONS: state => state.driver_transactions,
    GETDRIVERSUPPORTINGDOCS: state => state.driver_docs,
    GETDRIVERINVOICES: state => state.driver_invoices,
    GETDRIVERRECEIPTS: state => state.driver_receipts,
    GETDRIVERVEHICLE: state => state.driver_vehicle,
    GETTERMINATIONREASONS: state => state.driver_termination,
    GETDRIVERSTATUS: state => state.driver_status,
    GETDRIVERBILLINGS: state => state.driver_billings,
    GETDRIVERSBYAFFILIATION: state => state.taxi_driverByAffiliation,
    GETDRIVERSBYASSIDNOPIC: state => state.taxi_driversNoPic,
    GETDRIVERSBYIDNOPIC: state => state.taxi_driversByIdNoPic,
    GETDRIVERSPENDINGVERIFICATION: state => state.taxi_drivers_pendingVerification,
  },
  mutations: {
    NUMBERSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.driver_numbers = _data;
      }
    },
    TIMELINELOADED: (state, _data) => {
      if (_data !== undefined) {
        state.driver_timeline = _data;
      }
    },
    HISTORYLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.driver_employmenthistory = _data;
      }
    },
    SUPPORTINGDOCSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.driver_docs = _data;
      }
    },
    LOADEDSTATUSBYID: (state, _data) => {
      if (_data !== undefined) {
        state.driver_status = _data;
      }
    },
    TERMINATIONLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.driver_termination = _data;
      }
    },
    TRANSACTIONSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.driver_transactions = _data;
      }
    },
    INVOICESLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.driver_invoices = _data;
      }
    },
    RECEIPTSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.driver_receipts = _data;
      }
    },
    LOADED: (state, _data) => {
      if (_data !== undefined) {
        state.taxi_driver = _data;
      }
    },
    DRIVERSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.taxi_drivers = _data;
      }
    },

    DRIVERSNOPICLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.taxi_driversNoPic = _data;
      }
    },
    DRIVERBYIDNOPICLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.taxi_driversByIdNoPic = _data;
      }
    },
    DRIVERSBYAFFLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.taxi_driverByAffiliation = _data;
      }
    },
    DRIVERSBYPENDINGVERIFICATION: (state, _data) => {
      if (_data !== undefined) {
        state.taxi_drivers_pendingVerification = _data;
      }
    },
    DRIVERBILLINGSLOADED: (state, _data) => {
      if (_data !== undefined) {
        state.driver_billings = _data;
      }
    },
    DRIVERSLOADEDEDIT: (state, id) => {
      if (id !== undefined) {
        state.taxi_driver = state.taxi_drivers.filter(item => item._taxi_drivers_id === Number(id))[0];
      }
    },
    VEHICLEADDED: (state, _data) => {
      state.driver_vehicle = _data;
    },
    ADDED: (state, _data) => {
      state.taxi_driver = _data;
    },
    UPDATED: (state, _data) => {
      if (_data !== undefined) {
        Object.assign(state.taxi_driver.filter(item => item._taxi_drivers_id === _data._id)[0], _data);
      }
    },
    RESTOREDELETED: (state,id) => {
      if (id !== undefined) {
        state.taxi_driver.filter(item => item._taxi_drivers_id === id)[0]._deleted_by = undefined;
        state.taxi_driver.filter(item => item._taxi_drivers_id === id)[0]._date_deleted = undefined;
      }
    },
    MARKASDELETE: (state,id,deletedby) => {
      if (id !== undefined) {
        state.taxi_driver.filter(item => item._taxi_drivers_id === id)[0]._deleted_by = deletedby;
        state.taxi_driver.filter(item => item._taxi_drivers_id === id)[0]._date_deleted = Date.now;
      }
    },
    CLEARALL: (state) => {
      state.taxi_driver = [];
      state.taxi_drivers = [];
      state.taxi_driversNoPic = [];
      state.driver_numbers = [];
      state.driver_timeline = [];
      state.driver_employmenthistory = [];
      state.driver_docs = [];
      state.driver_transactions = [];
      state.driver_invoices = [];
      state.driver_receipts = [];
      state.driver_vehicle = [];
      state.driver_termination = [];
      state.driver_status = [];
      state.driver_billings = [];
      state.taxi_driverByAffiliation = [];
    },
    CLEARTRANSACTIONS: (state) => {
      state.driver_transactions = [];
    },

    CLEARDRIVERS: (state) => {
      state.taxi_driver = [];
      state.taxi_drivers = [];
      state.taxi_driversNoPic = [];
      state.taxi_driverByAffiliation = [];
    },
  },
  actions: {

    clearAll({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }) {
      /* Auth.js Mutations */
      commit('CLEARALL');
      console.log('drivers cleared!');
    },

    clearDrivers({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }) {
      /* Auth.js Mutations */
      
      commit('CLEARDRIVERS');
    },

    getDriverNumbersById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          id,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETDRIVERMOBILENUMBERS_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            commit('NUMBERSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
    driverStatusByAssId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.DRIVERSTATUSBYASSOCIATION_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((Result_StatusById) => {
          if (Result_StatusById.status) {
            commit('LOADEDSTATUSBYID', Result_StatusById.data);
            resolve(Result_StatusById.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });

      });
    },
    getDriverDocsById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {

        const {
          id,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETDRIVERSUPPORTINGDOCS_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('SUPPORTINGDOCSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
    getDriverEmploymentHistoryById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {

        const {
          id,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETDRIVEREMPLOYMENTHISTORY_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            commit('HISTORYLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
    getDriverTimelineById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {

        const {
          id,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETDRIVERTIMELINE_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            commit('TIMELINELOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },

    getDriverBillingsById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      skip,
      len,
      router
    }) {
      
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETDRIVERBILLINGS_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging(requestheader, {
          status: id,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            commit('DRIVERBILLINGSLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          commit('LOGINFAILED', error.data);
        });
      });
    },

    getDriverTransactionsByDriverId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      skip,
      len,
      router
    }) {
      
      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.DRIVERTRANSACTIONSBYID_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging(requestheader, {
          status: id,
          skip: skip,
          len: len
        }).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            // commit('DRIVERBILLINGSLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          reject(error);
          // commit('LOGINFAILED', error.data);
        });
      });
    },


    getDriverTransactionsById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      taxiassociationid,
      accountNumber,
      skip,
      length,
      router
    }) {
      return new Promise((resolve, reject) => {

        if (accountNumber != "") {
          const requestheader = {
            router,
            token: rootGetters['auth/TOKEN'],
            apiName: config.GETDRIVERTRANSACTIONS_URL,
          };

          this._vm.$TAMSFIN_API.getbyAccountNumber(requestheader, {
            associationsid: taxiassociationid,
            accountnumber: accountNumber,
            skip,
            length
          }).then((parsedResult) => {
            if (parsedResult.status) {
              commit('TRANSACTIONSLOADED', parsedResult.data);
              resolve(parsedResult.data);
              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            commit('CLEARTRANSACTIONS', error);
            reject(error);
          });
        } else {
          commit('CLEARTRANSACTIONS', parsedResult.data);
          resolve(parsedResult.data);
        }

      });
    },

    getDriverById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      return new Promise((resolve, reject) => {


        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TAXIDRIVER_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('LOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getDriverByIdNoPic({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      return new Promise((resolve, reject) => {


        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETDRIVERBYIDNOPIC_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('DRIVERBYIDNOPICLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getDriversByAffiliation({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      return new Promise((resolve, reject) => {


        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETDRIVERSBYAFFILIATION_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('DRIVERSBYAFFLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('driver errors: ', error);
          // commit('LOGINFAILED', error.data);
          reject(error.data);
        });
      });
    },
    getAllTransactionsByDate({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,payload }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TRANSACTIONSDRIVERS,
      };

      this._vm.$TAMSFIN_API.getCustomURL(requestheader,
        payload
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          commit('TRANSACTIONSLOADED', parsedResultOP.data);
          resolve(parsedResultOP);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    });
    },
    

    /* Async */
    selected({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _id
    }) {
      const _taxiass = state.taxiassociations.filter(t => t._taxi_associations_id == _id)[0];

      /* Auth.js Mutations */
      commit('auth/UPDATETAXIASSOCIATION', {
        id: _taxiass._taxi_associations_id,
        name: _taxiass._name,
        abbr: _taxiass._abbriviated_name,
        code: _taxiass._code,
      }, {
        root: true
      });
    },

    updateDriverRequest({
      commit,
      state,
      dispatch,
      rootGetters
    }, {
      addModel,
      router,
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.DRIVERUPDATE_URL,
        };

        this._vm.$TAMS_API.add(requestheader, addModel).then((parsedResult) => {
          if (parsedResult.status) {
            commit('DRIVERSLOADEDEDIT', parsedResult.data);
            resolve(parsedResult.data)
            console.log('updated succesfully')
          } else {
            // commit('EXTERNALUPDATED', parsedResult.data);
          }
        }).catch((error) => {
          commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },

    getLOGOURL({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id
    }) {
      return new Promise((resolve, reject) => {
        resolve(format(conf.URLBYID(config.DRIVER_LOGO_URL), {
          id
        }));
      });

    },
    updateDriverStatusRequest({
      commit,
      state,
      dispatch,
      rootGetters
    }, {
      addModel,
      router,
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.DRIVERSTATUSUPDATE_URL,
        };

        this._vm.$TAMS_API.add(requestheader, addModel).then((parsedResult) => {
          if (parsedResult.status) {
            commit('ADDED', parsedResult.data)
            resolve(parsedResult.data)
            console.log('updated succesfully')
          } else {
            // commit('EXTERNALUPDATED', parsedResult.data);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    assignDriver({
      commit,
      state,
      dispatch,
      rootGetters
    }, {
      addModel,
      router,
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.ASSIGNDRIVER_URL,
        };

        this._vm.$TAMS_API.add(requestheader, addModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('VEHICLEADDED', parsedResult.data)
            
            resolve(parsedResult.data)
            console.log('updated succesfully')
          } else {
            // commit('EXTERNALUPDATED', parsedResult.data);
          }
        }).catch((error) => {
          
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    updateDriverSupportingDocs({
      commit,
      state,
      dispatch,
      rootGetters
    }, {
      addModel,
      router,
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.DRIVERSUPPORTINGDOCSADD_URL,
        };

        this._vm.$TAMS_API.add(requestheader, addModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('ADDED', parsedResult.data)
            resolve(parsedResult.data)
            console.log('updated succesfully')
          } else {
            // commit('EXTERNALUPDATED', parsedResult.data);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
    downloadfile({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      messageid,
      fileid,
      router
    }) {

      return new Promise((resolve, reject) => {



        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.DOCUMENTDOWNLOAD_URL,
        };

        this._vm.$TAMS_API.getByPaging2(requestheader, {
          id: messageid,
          text: fileid
        }).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            resolve(parsedResultOP.data[0].fn_message_inbox_get_attachmentcontent[0]);

            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          commit('LOGINFAILED', error.data);
        });
      });
    },

    updateDriverCapturePhotos({
      commit,
      state,
      dispatch,
      rootGetters
    }, {
      addModel,
      router,
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.DRIVERCAPTUREPHOTOSADD_URL,
        };

        this._vm.$TAMS_API.add(requestheader, addModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('ADDED', parsedResult.data)
            resolve(parsedResult.data)
            console.log('updated succesfully')
          } else {
            // commit('EXTERNALUPDATED', parsedResult.data);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },

    downloadMedia({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      receiptsdisputesid,
      fileid,
      router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.RECEIPTDISPUTESMEDIADOWNLOAD_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging2(requestheader, {
          skip: receiptsdisputesid,
          len: fileid
        }).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('DISPUTEADDED', parsedResult.data);
            resolve(parsedResult.data);

          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },


    getAllByTaxiAssID({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      skip,
      len,
      router
    }) {

      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TAXIDRIVERBYASSOCIATIONBYID_URL,
        };

        

        this._vm.$TAMS_API.getByPaging(requestheader, {status: id, skip, len}).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {
            // commit('DRIVERSLOADED', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log(error);
          commit('LOGINFAILED', error.data);
        });
      });
    },

    getAllPendingVerification({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      skip,
      len,
      router
    }) {

      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TAXIDRIVERPENDINGVERIFICATION_URL,
        };

        

        this._vm.$TAMS_API.getByPaging(requestheader, {status: id, skip, len}).then((parsedResultOP) => {
          console.log(parsedResultOP);
          if (parsedResultOP.status) {

            console.log('pending verification: ',parsedResultOP.data);
            commit('DRIVERSBYPENDINGVERIFICATION', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          console.log('pending errors: ',error);
          commit('LOGINFAILED', error.data);
        });
      });
    },
    
    getAllByTaxiAssIdNoPic({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {

      return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETDRIVERSBYASSIDNOPIC_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id)
          .then((parsedResultOP) => {
            console.log(parsedResultOP);
            if (parsedResultOP.status) {
              console.log('drivers by association: ',parsedResultOP.data);
              // commit('DRIVERSNOPICLOADED', parsedResultOP.data);
              resolve(parsedResultOP.data);

              // navigateTo(router, parsed_result.data[0]);
            }
          }).catch((error) => {
            console.log('drivers by association error: ',error);
            // commit('LOGINFAILED', error.data);
          });
      });
    },

    getByPaging({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _waitObj
    }) {
      const {
        _wait
      } = _waitObj;
      const _waitname = _waitObj.name;
      const router = _waitObj._router;

      if (_waitname) {
        _wait.start(_waitname);
      }
      this._vm.$TAMS_API.getByPaging(router, rootGetters['auth/TOKEN'],
        config.TAXIDRIVER_URL, {
          isactive: true,
          skip: 0,
          length: 1,
        }).then((parsedResult) => {
        console.log(parsedResult);
        if (parsedResult.status) {
          commit('LOADED', parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
        }

        if (_waitname) {
          _wait.end(_waitname);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
        if (_waitname) {
          _wait.end(_waitname);
        }
      });
    },
    editDriver({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, id) {
      commit('DRIVERSLOADEDEDIT', id);
    },
    searchDriver({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          id,
          router
        } = _dataObj;



        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SEARCHBYDRIVER,
        };
        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            commit('DRIVERSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },



    getReasonForTerminationById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          id,
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETTERMINATION_URL,
        };

        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            commit('TERMINATIONLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },



    getDriverDisputedInvoicesById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      return new Promise((resolve, reject) => {


        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETDRIVERINVOICEDISPUTES_URL,
        };

        this._vm.$TAMSFIN_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            commit('INVOICESLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    changeDriverBilling({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      addModel,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.CHANGEDRIVERBILLING_URL,
        };

        this._vm.$TAMSFIN_API.add(requestheader, addModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('TRANSACTIONSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });

      });
    },

    getSearchDriverIDnumber({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { id, router }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.DOESDRIVEREXIST_URL,
        };
       
        this._vm.$TAMS_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          // navigateTo(router, parsed_result.data[0]);
          }
          else
          reject(parsedResult);
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getDriverReceiptById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      taxiassociationid,
      receiptsid,
      router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETRECIEPTBYID_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging2(requestheader, {
          skip: taxiassociationid,
          len: receiptsid,
        }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('RECEIPTLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },



    getDriverDisputedReceiptsById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      return new Promise((resolve, reject) => {


        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GETDRIVERRECEIPTDISPUTES_URL,
        };

        this._vm.$TAMSFIN_API.getById(requestheader, id).then((parsedResult) => {
          if (parsedResult.status) {
            commit('RECEIPTSLOADED', parsedResult.data);
            resolve(parsedResult.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    getAll({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _waitObj
    }) {
      const {
        router
      } = _waitObj;

      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TAXIDRIVER_URL,
      };
      this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
        console.log(parsedResult);
        if (parsedResult.status) {
          commit('TAXIASSLOADED', parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    },
    add({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          item,
          router,
        } = _dataObj;
        

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TAXIDRIVER_URL,
        };
        this._vm.$TAMS_API.add(requestheader,
          _dataObj.item).then((parsedResult) => {

          if (parsedResult.status) {
            commit('ADDED', parsedResult.data);
            resolve(parsedResult.data);
            console.log("driver added: ",parsedResult.data);
            //navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    markAsDeleted({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id,
      deletedby
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TAXIDRIVER_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id, {
            id,
            deletedby,
          }).then((parsedResult) => {

          if (parsedResult.status) {
            commit('MARKASDELETE', {
              id,
              deletedby
            });
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    restoreDeleted({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      restoredby,
      id,
      router
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TAXIDRIVER_URL,
        };

        this._vm.$TAMS_API.restoreDeleted(requestheader, {
          id,
          restoredby,
        }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('RESTOREDELETED', {
              id
            });
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
  }
}