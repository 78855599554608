<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
      v-on:init="init"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TPAY'"
      :ActiveMenu="'Dashboard'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <transition name="bounce">
      <div id="content" class="content" v-show="USERPROFILE.taxi_associations_id">
        <!-- begin page-header -->
        <headerpagebreadcrumb
          pageheaderTitle="TPAY Dashboard"
          pageheaderdescription
          breadcrumbitem1="Home"
          breadcrumbitem2="TPAY"
          breadcrumbitem3="Dashboard"
        />
        <!-- end page-header -->
        <!-- begin #Main Body Template -->

        <!-- begin row -->
        <div class="row">
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Commuters</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalcommuters}}</div>

                <div class="stats-desc">Total Number Of Commuters</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Operators</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totaloperators}}</div>

                <div class="stats-desc">Total Number Of Operators</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-red">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Drivers</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totaldrivers}}</div>

                <div class="stats-desc">Total Number Of Drivers</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="fas fa-taxi"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Agents</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalagents}}</div>

                <div class="stats-desc">Total Number Of Agents</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="fas fa-taxi"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Organisations</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalorganisations}}</div>

                <div class="stats-desc">Total Number Of Organisations</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total TopUps</div>
                <div class="stats-number">R{{GETDASHBOARDCARDS.total_topups}}</div>

                <div class="stats-desc">Total Payments</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="fas fa-taxi"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Payments</div>
                <div class="stats-number">R{{GETDASHBOARDCARDS.total_payments}}</div>

                <div class="stats-desc">Total Deposits</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-yellow">
                <i class="ion-ios-people"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total CashOut</div>
                <div class="stats-number">R{{GETDASHBOARDCARDS.total_cashouts}}</div>

                <div class="stats-desc">Total Cashback + Withdrawals</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           
        </div>

        <!-- end row -->

         <!-- begin row -->
        <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-12">

                <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Daily Transactions(30 Days)</h4>
              </div>
              <div class="panel-body">
                <div id="interactive-chartOpbyAss" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
         
        </div>
        <!-- end row -->


        <!-- begin row -->
        <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-8">

           <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-2">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Total Deposits vs Payments</h4>
              </div>
              <div class="panel-body">
                <div id="donut-OperatorByGender" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-3">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Money Transfer - Send Money Vs Withdrawals</h4>
              </div>
              <div class="panel-body">
                <div id="donut-OperatorByAgeGroup" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->

              <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-4">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Commuter Age Segment</h4>
              </div>
              <div class="panel-body">
                <div id="VehicleWithNoOperatingLicense" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->


              <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-4">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Taxi Finance Chart</h4>
              </div>
              <div class="panel-body">
                <div id="Vehiclefinancechart" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->


          </div>

         
          <!-- end col-8 -->
          <!-- begin col-8 -->
          <div class="col-xl-4">

              <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-6">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top Commuters With Deposits</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Agent Name</th>
                        <th>Total(R)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETOPERATORFINSTATSTOP5.top5operators"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
                        <td>
                          {{item.taxiassociation}}
                        </td>
                        <td>{{item.numberofoperators}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

              <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-10">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top Agents With Most Deposit</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
						            <th>Driver Name</th>
                        <th>Total(R)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETOPERATORFINSTATSTOP5.top5taxis"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
						<td>
                          {{item.taxiassociation}}
                        </td>
                        <td>{{item.numberoftaxis}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

             <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-10">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top Drivers With Most Incomes</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
						            <th>Driver Name</th>
                        <th>Total(R)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETOPERATORFINSTATSTOP5.top5taxis"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
						<td>
                          {{item.taxiassociation}}
                        </td>
                        <td>{{item.numberoftaxis}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

              <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-9">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top Operators With Most Income</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Taxi Model</th>
                        <th>Totals</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETOPERATORFINSTATSTOP5.vehiclemodelchart"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
                        <td>
                          {{item.vehiclemodel}}
                        </td>
                        <td>
                          {{item.numberofvehicles}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->
            
             <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-8">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top Agents With Most Deposits</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Operator Name</th>
                        <th>Taxi Association</th>
                        <th>Total Taxis</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETOPERATORFINSTATSTOP5.operatorswithmostvehicles"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
                        <td>
                          {{item.fullname}}
                        </td>
                        <td>
                          {{item.taxiassociation}}
                        </td>
                        <td class="text-center">{{item.numberoftaxis}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->


          </div>
          <!-- end col-8 -->
         
        </div>
        <!-- end row -->

      
        <!-- begin row -->

        <!-- begin #Main Body Template -->
      </div>
    </transition>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import statsCards from "@/components/charts/statsCards.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers(
  "tpaysummary_dashboard"
);

export default {
  name: "regionalchairman_dashboard",
  data: function() {
    return {
      message: "Some Message",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: 2,
      GETDASHBOARDCARDS: {},
      GETOPERATORFINSTATS: {},
      GETOPERATORFINSTATSTOP5: {}
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    statsCards,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["", ""])
  },
  methods: {
    ...mapActions(["getdashboardcards", "getoperatorfinstats","getoperatorfinstatstop5"]),
    init: function() {}
  },
  mounted: function() {
    $(document).data("context", this);
    App.init();

    const router = this.$router;

    var payload = {
      regionid: this.taxiassociationid
    };

    this.getdashboardcards({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        

        context.GETDASHBOARDCARDS = parsedResultOP;
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

    this.getoperatorfinstats({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        

        context.GETOPERATORFINSTATS = parsedResultOP;

        handleOperatorByGender();
    handleOperatorByAgeGroup();
		handleVehicleWithNoOperatingLicense();
    handleTotalOpPerTaxiAssBarChart();
    handleVehicleWithfinancechartdisk();

    
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

      this.getoperatorfinstatstop5({ router, payload }).then(parsedResultOP => {
        const context = $(document).data("context");
        
        context.GETOPERATORFINSTATSTOP5 = parsedResultOP;
      })  
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

    var handleOperatorByAgeGroup = function() {
      "use strict";
      if ($("#donut-OperatorByAgeGroup").length !== 0) {
        const context = $(document).data("context");

       const colors = [COLOR_GREEN,COLOR_RED];

        var operatorAgeGroupData = [
          {
              label: 'Send Money',
              data: 10000,
              color: colors[0]
            },
            {
              label: 'Withdrawals',
              data: 9000,
              color: colors[1]
            }
        ]

        $.plot("#donut-OperatorByAgeGroup", operatorAgeGroupData, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true
              }
            }
		  },
		  grid: {
            hoverable: true,
            clickable: true
          },
          legend: {
            show: true
          }
        });
      }
    };

    var handleOperatorByGender = function() {
      "use strict";
      if ($("#donut-OperatorByGender").length !== 0) {
        const context = $(document).data("context");

        const colors = [COLOR_GREEN,COLOR_RED];

        var operatorGenderData = [
          {
              label: 'Deposits',
              data: 10000,
              color: colors[0]
            },
            {
              label: 'Payments',
              data: 9000,
              color: colors[1]
            }
        ]

        $.plot("#donut-OperatorByGender", operatorGenderData, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true
              }
            }
          },
          grid: {
            hoverable: true,
            clickable: true
          },
          legend: {
            show: true
          }
        });
      }
	};
	
	 var handleVehicleWithNoOperatingLicense = function() {
      "use strict";
      if ($("#VehicleWithNoOperatingLicense").length !== 0) {
        const context = $(document).data("context");

		const colors = [COLOR_PURPLE_LIGHTER, COLOR_BLUE];
		

        var nooperatinglicense = [
			{
            label: `Under 35`,
            data: 50,
            color: COLOR_GREEN
          },
          {
            label: `Over 35`,
            data: 30,
            color: COLOR_RED
          },
          {
            label: `Over 60`,
            data: 10,
            color: COLOR_PURPLE_LIGHTER
          },
        ];

        $.plot("#VehicleWithNoOperatingLicense", nooperatinglicense, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true
              }
            }
          },
          grid: {
            hoverable: true,
            clickable: true
          },
          legend: {
            show: true
          }
        });
      }
	};

var handleTotalOpPerTaxiAssBarChart = function () {
'use strict';
	
	if ($('#interactive-chartOpbyAss').length !== 0) {

    const context = $(document).data("context");

  

    var data_dep =  [
    23,
    211,
    147,
    24,
    63,
    66,
    20,
    298,
    27,
    40,
    323,
    142,
    671,
    56,
    119,
    65,
    191,
    30,
    49,
    103,
    36,
    27,
    34,
    29,
    90,
    29,
    27,
    34,
    29,
    90,
    29,
];

    
     var data_pay =  [
    32,
    363,
    303,
    32,
    140,
    131,
    46,
    542,
    43,
    88,
    853,
    306,
    1510,
    229,
    450,
    108,
    471,
    56,
    80,
    282,
    91,
    49,
    74,
    131,
    381,
    54,
    49,
    74,
    131,
    381,
    54
]

        

        var data_30days =  [
          '1 Jul','2 Jul','3 Jul','4 Jul','4 Jul','5 Jul','6 Jul','7 Jul','8 Jul','9 Jul','10 Jul',
          '11 Jul','12 Jul','13 Jul','14 Jul','15 Jul','16 Jul','17 Jul','18 Jul','19 Jul','20 Jul','21 Jul',
          '22 Jul','23 Jul','24 Jul','25 Jul','26 Jul','27 Jul','28 Jul','29 Jul','30 Jul','31 Jul',
        ]

          
	
	var options = {
		chart: {
			height: 350,
			type: 'bar'
		},
		title: {
			text: 'Daily Deposits And Payments',
			align: 'center'
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '100%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top'
        }
			},
		},
		dataLabels: {
      enabled: true
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		},
		colors: [COLOR_GREEN,COLOR_RED],
		series: [{
			name: 'Deposits',
			data: data_dep
		}, {
			name: 'Payments',
			data: data_pay
		}],
		xaxis: {
			categories: data_30days,
			axisBorder: {
				show: true,
				color: COLOR_SILVER_TRANSPARENT_5,
				height: 1,
				width: '100%',
				offsetX: 0,
				offsetY: -1
			},
			axisTicks: {
				show: true,
				borderType: 'solid',
				color: COLOR_SILVER,
				height: 6,
				offsetX: 0,
				offsetY: 0
			}
		},
		yaxis: {
			title: {
				text: '$ (thousands)'
			}
		},
		fill: {
			opacity: 1
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return + val
				}
			}
		}
	};
	
	var chart = new ApexCharts(
		document.querySelector('#interactive-chartOpbyAss'),
		options
	);

	chart.render();
	
	}
};

var handleVehicleWithfinancechartdisk = function () {
'use strict';
	
	if ($('#Vehiclefinancechart').length !== 0) {

    const context = $(document).data("context");

    

    var data_ =  context.GETOPERATORFINSTATS.vehiclefinchat.map((item, index) => {
          return item.numberofvehicles
        });
    
     var data_fdi =  context.GETOPERATORFINSTATS.vehiclefinchat.map((item, index) => {
          return item.fdi
        });
	
	var options = {
		chart: {
			height: 350,
			type: 'bar'
		},
		title: {
			text: 'Taxi Finance Chart',
			align: 'center'
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '100%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top'
        }
			},
		},
		dataLabels: {
			enabled: true
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		},
		colors: [COLOR_BLUE, COLOR_GREEN],
		series: [{
			name: 'Operators',
			data: data_
		}],
		xaxis: {
			categories: data_fdi,
			axisBorder: {
				show: true,
				color: COLOR_SILVER_TRANSPARENT_5,
				height: 1,
				width: '100%',
				offsetX: 0,
				offsetY: -1
			},
			axisTicks: {
				show: true,
				borderType: 'solid',
				color: COLOR_SILVER,
				height: 6,
				offsetX: 0,
				offsetY: 0
			}
		},
		yaxis: {
			title: {
				text: ''
			}
		},
		fill: {
			opacity: 1
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return + val
				}
			}
		}
	};
	
	var chart = new ApexCharts(
		document.querySelector('#Vehiclefinancechart'),
		options
	);

	chart.render();
	
	}
};
  

   
  }
};
</script>

<style>
@import "/assets/plugins/nvd3/build/nv.d3.css";
</style>