<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageDrivers'"
      :ActiveMenu="'AddTaxiDriver'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Add New Taxi Driver"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Driver"
        breadcrumbitem3="Add New"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <div class="card text-center">
        <div
          v-if="isIdNumberValid == undefined"
          class="alert alert-primary fade show"
        >WAITING TO VERIFY ID NUMBER</div>
      </div>

      <div class="card text-center">
        <div
          v-if="!isIdNumberValid && isIdNumberValid != undefined "
          class="alert alert-danger fade show"
        >INVALID ID NUMBER!</div>
      </div>

      <div class="card text-center">
        <div v-if="isIdNumberValid" class="alert alert-success fade show">ID NUMBER VERFIED</div>
      </div>

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Driver Information
                      <small class="text-ellipsis">Name, Address, ID No and DOB</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-2">
                    <span class="number">2</span>
                    <span class="info text-ellipsis">
                      Capture Photo
                      <small class="text-ellipsis">Driver Photo</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-3">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Upload Supporting Documents
                      <small
                        class="text-ellipsis"
                      >ID document, Proof of Address,License ect.....</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-4">
                    <span class="number">4</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Complete Registration</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <!-- begin row -->
                  <div class="row">
                    <!-- begin col-8 -->

                    <div class="col-md-12">
                      <div class="row panel panel-primary">
                        <!-- begin col-8 -->
                        <div class="col-lg-6">
                          <!-- begin form-group -->

                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              ID Number
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="9345454545454"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                :class="['form-control',{'is-invalid': isIdNumberExists , 'is-valid': !isIdNumberExists  }]"
                                v-model.lazy="IDNUMBER"
                              />
                              <div class="invalid-tooltip">Driver Exists.</div>
                              <div class="valid-tooltip">New Driver!</div>
                            </div>
                          </div>
                          <!-- end form group -->
                          <br />
                          <br />
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Foreign ID</label>
                            <div class="col-md-6">
                              <div class="switcher switcher-success">
                                <input
                                  type="checkbox"
                                  name="switcher_checkbox_hasoverdraft"
                                  id="switcher_checkbox_hasoverdraft"
                                  data-parsley-group="step-1"
                                  data-parsley-required="false"
                                  v-model="IsForeign"
                                />
                                <label for="switcher_checkbox_hasoverdraft"></label>
                              </div>
                            </div>
                          </div>
                          <br />
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Title
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="ADDTAXIDRIVER.title_id"
                              >
                                <option value="0" selected>Select a Title</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLTITLES"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Gender
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="ADDTAXIDRIVER.gender_id"
                              >
                                <option value="0" selected>Select a Gender</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLGENDERS"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              First Name
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="Siya"
                                id="firstname"
                                name="firstname"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDTAXIDRIVER.firstname"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Surname
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="surname"
                                value="Kolisi"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDTAXIDRIVER.surname"
                              />
                            </div>
                          </div>
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Country</label>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="ADDTAXIDRIVER.country_id"
                              >
                                <option value="0" selected>Select a Country</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLCOUNTRY"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Passport Number</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="passport"
                                value="910708987461121"
                                class="form-control"
                                v-model="ADDTAXIDRIVER.passport_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Date Of Birth
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <div
                                class="input-group date datepicker-disabled-past"
                                id="date_of_birth"
                                data-date-format="dd M yyyy"
                              >
                                <input
                                  data-parsley-group="step-1"
                                  data-parsley-required="true"
                                  type="text"
                                  class="form-control"
                                  placeholder="Select Date"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Mobile Number
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                name="mobilenumber"
                                id="masked-input-phone"
                                value="0819399999"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDTAXIDRIVER.mobilenumber"
                              />
                            </div>
                          </div>
                          <!-- begin form-group -->
                        </div>
                        <!-- end col-8 -->

                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Membership Number</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="membershipnumber"
                                value="INT-3201476"
                                class="form-control"
                                v-model="ADDTAXIDRIVER.existing_membership_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label
                              class="col-md-4 col-form-label text-md-right"
                            >Driver License Number</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="lastname"
                                value="INT-3201476"
                                data-parsley-group="step-1"
                                class="form-control"
                                v-model="ADDTAXIDRIVER.driver_license_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">PDP Number</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="pdp number"
                                id="masked-input-phone"
                                class="form-control"
                                v-model="ADDTAXIDRIVER.pdp_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Joined Date</label>
                            <div class="col-md-6">
                              <div
                                class="input-group date datepicker-disabled-past"
                                id="taxi_driver_joined_date"
                                data-date-format="dd M yyyy"
                              >
                                <input
                                  data-parsley-group="step-1"
                                  type="text"
                                  class="form-control"
                                  placeholder="Select Date"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">License Expiry Date</label>
                            <div class="col-md-6">
                              <div
                                class="input-group date datepicker-disabled-past"
                                id="drivers_license_expirydate"
                                data-date-format="dd M yyyy"
                              >
                                <input type="text" class="form-control" placeholder="Select Date" />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">PDP Expiry Date</label>
                            <div class="col-md-6">
                              <div
                                class="input-group date datepicker-disabled-past"
                                id="pdp_license_expirydate"
                                data-date-format="dd M yyyy"
                              >
                                <input type="text" class="form-control" placeholder="Select Date" />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Telephone Number</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="telephone"
                                id="masked-input-phone"
                                value="0819399999"
                                class="form-control"
                                v-model="ADDTAXIDRIVER.telephone_number"
                              />
                            </div>
                          </div>
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Email</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="lastname"
                                class="form-control"
                                v-model="ADDTAXIDRIVER.email_address"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Fax Number</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="faxnumber"
                                id="masked-input-phone"
                                class="form-control"
                                v-model="ADDTAXIDRIVER.fax_number"
                              />
                            </div>
                          </div>
                          <!-- begin form-group -->
                          <!-- end form-group -->
                          <!-- end form-group -->
                        </div>

                        <div class="col-lg-6">
                          <!-- begin col-6 -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Physical Address
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDTAXIDRIVER.physical_address"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Physical Address 2
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="ADDTAXIDRIVER.physical_address2"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Suburb
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <div class="input-group">
                                <span class="input-group-append">
                                  <select
                                    class="form-control width-250"
                                    data-size="10"
                                    data-parsley-required="true"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    id="selectPhysicalSuburb"
                                  >
                                    <option value="0">Select A Suburb</option>
                                    <option
                                      v-bind:key="item._id"
                                      v-for="item in GETALLSUBURBS"
                                      :value="item._id"
                                    >{{item._name}}</option>
                                  </select>
                                  <a
                                    href="javascript:;"
                                    data-toggle="modal"
                                    data-target="#AddModal"
                                    class="btn btn-sm btn-grey"
                                  >
                                    <i class="fa fa-plus fa-md m-t-5"></i>
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>
                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Postal Address</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                class="form-control"
                                v-model="ADDTAXIDRIVER.postal_address"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Postal Address 2</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                class="form-control"
                                v-model="ADDTAXIDRIVER.postal_address2"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Suburb</label>
                            <div class="col-md-6">
                              <div class="input-group">
                                <span class="input-group-append">
                                  <select
                                    class="form-control width-250"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    id="selectPostalSuburb"
                                  >
                                    <option value="0">Select A Suburb</option>
                                    <option
                                      v-bind:key="item._id"
                                      v-for="item in GETALLSUBURBS"
                                      :value="item._id"
                                    >{{item._name}}</option>
                                  </select>
                                  <a
                                    href="javascript:;"
                                    data-toggle="modal"
                                    data-target="#AddModal"
                                    class="btn btn-sm btn-grey"
                                  >
                                    <i class="fa fa-plus fa-md m-t-5"></i>
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>
                      </div>
                    </div>
                    <!-- end col-8 -->
                    <br />
                    <div class="col-lg-9 p-t-20" v-if="id!=':id'">
                      <div id="gallery" class="gallery m-r-25 pull-right">
                        <!-- begin image -->
                        <div class="image width-full height-400">
                          <div class="image-inner">
                            <a :href="VEHICLEIMAGESFRONT" data-lightbox="gallery-group-3">
                              <img :src="VEHICLEIMAGESFRONT" class="width-full height-400" />
                            </a>
                          </div>
                        </div>
                        <!-- end image -->
                      </div>
                      <div id="gallery" class="gallery m-r-25 pull-left">
                        <!-- begin image -->
                        <div class="image width-full height-400">
                          <div class="image-inner">
                            
                            <a :href="VEHICLEIMAGESBACK" data-lightbox="gallery-group-3">
                              <img :src="VEHICLEIMAGESBACK" class="width-full height-400" />
                            </a>
                          </div>
                        </div>
                        <!-- end image -->
                      </div>
                    </div>
                  </div>

                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->

                <!-- Add Modal HTML START-->
                <div id="AddModal" class="modal fade">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <form>
                        <div class="modal-header">
                          <h4 class="modal-title">Add Suburb</h4>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                          >&times;</button>
                        </div>
                        <div class="modal-body">
                          <div class="col-md-12">
                            <!-- begin form-group row -->
                            <!-- begin form-group row -->
                            <div class="form-group row m-b-10">
                              <label class="col-md-3 text-md-right col-form-label">City</label>
                              <div class="col-md-9">
                                <select
                                  class="form-control"
                                  data-size="10"
                                  data-live-search="true"
                                  data-parsley-group="step-1"
                                  data-parsley-required="false"
                                  v-model="ADDSUBURB._city_id"
                                >
                                  <option value="0" selected>Select City</option>
                                  <option
                                    v-bind:key="item._id"
                                    v-for="item in CITIES"
                                    :value="item._id"
                                  >{{item._name}}</option>
                                </select>
                              </div>
                            </div>
                            <!-- end form-group row -->
                            <div class="form-group row m-b-10">
                              <label class="col-md-3 text-md-right col-form-label">Name</label>
                              <div class="col-md-9">
                                <input
                                  type="text"
                                  placeholder="Enter Name"
                                  class="form-control"
                                  v-model="ADDSUBURB._cityname"
                                />
                              </div>
                            </div>
                            <!-- end form-group row -->

                            <!-- begin form-group row -->
                            <div class="form-group row m-b-10">
                              <label class="col-md-3 text-md-right col-form-label">Description</label>
                              <div class="col-md-9">
                                <input
                                  type="text"
                                  placeholder="Enter Description"
                                  class="form-control"
                                  v-model="ADDSUBURB._description"
                                />
                              </div>
                            </div>
                            <!-- end form-group row -->
                            <!-- begin form-group row -->
                            <div class="form-group row m-b-10">
                              <label class="col-md-3 text-md-right col-form-label">Code</label>
                              <div class="col-md-9">
                                <input
                                  type="text"
                                  placeholder="Enter Code"
                                  class="form-control"
                                  v-model="ADDSUBURB._code"
                                />
                              </div>
                            </div>
                            <!-- end form-group row -->
                            <!-- begin form-group row -->
                            <div class="form-group row m-b-10">
                              <label class="col-md-3 text-md-right col-form-label">Postal Code</label>
                              <div class="col-md-9">
                                <input
                                  type="text"
                                  placeholder="Enter Postal Code"
                                  class="form-control"
                                  v-model="ADDSUBURB._postalcode"
                                />
                              </div>
                            </div>
                            <!-- end form-group row -->
                          </div>
                        </div>
                        <div class="modal-footer">
                          <input
                            type="button"
                            class="btn btn-default"
                            data-dismiss="modal"
                            value="Cancel"
                          />
                          <button
                            type="button"
                            class="btn btn-sm btn-success width-80"
                            @click="addNewSuburb(context,ADDSUBURB)"
                            :disabled="$wait.is('add+')"
                          >
                            <v-wait v-bind:for="'add+'">
                              <template slot="waiting">
                                <i class="fas fa-spinner fa-spin"></i>
                              </template>
                              Add
                            </v-wait>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <!-- Add Modal HTML START-->

                <!-- begin step-2 -->
                <div id="step-2">
                  <CapturePhotosControl
                    :SessionID="CapturedPhotosSessionID"
                    :ShowStopStartWebCamControl="false"
                    :CameraWidth="'40%'"
                    :ShowPreviewControl="true"
                    :CameraHeight="'40%'"
                    :ClearData="true"
                  />
                </div>
                <!-- end step-2 -->

                <!-- begin step-3 -->
                <div id="step-3">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->

                    <p class="text-center h4 mb-4">Upload Supporting Documents</p>
                    <div class="panel panel-primary" data-sortable-id="form-dropzone-1">
                      <div class="panel-heading">
                        <div class="panel-heading-btn"></div>
                        <h4 class="panel-title">Drop Files In Here</h4>
                      </div>
                      <div class="panel-body text-inverse">
                        <DropZoneControl
                          :acceptedFiles="acceptedFiles"
                          :ClearData="true"
                          v-on:fileadded="fileadded"
                          :SessionID="DropZoneSessionID"
                        ></DropZoneControl>
                      </div>
                    </div>
                    <br />
                    <!--End dropzone!-->

                    <br />
                    <!--Start Table!-->
                    <div class="panel panel-primary" data-sortable-id="table-basic-7">
                      <!-- begin panel-heading -->
                      <div class="panel-heading ui-sortable-handle">
                        <h4 class="panel-title">Uploaded Files</h4>
                      </div>
                      <!-- end panel-heading -->
                      <!-- begin panel-body -->
                      <div class="panel-body">
                        <!-- begin table-responsive -->
                        <div class="table-responsive">
                          <table class="table table-striped m-b-0">
                            <thead>
                              <tr>
                                <th>Filename</th>
                                <th>File ID</th>
                                <th>Mimetype</th>
                                <th width="40%">Document Type</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                :key="item"
                                v-for="(item,index) in GETUPLOADEDFILES"
                                :class="index & 1 ? 'odd gradeA' : 'even'"
                              >
                                <td>{{item.filename}}</td>
                                <td>{{item.fileId}}</td>
                                <td>{{item.mimeType}}</td>
                                <td>
                                  <select
                                    class="form-control col-sm-4"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    data-parsley-required="true"
                                    v-model="item.customType"
                                  >
                                    <option value="0" selected>Select Document Type</option>
                                    <option
                                      v-bind:key="item._id"
                                      v-for="item in GETALLDOCUMENTTYPE"
                                      :value="item._id"
                                    >{{item._name}}</option>
                                  </select>
                                </td>
                                <td class="with-btn" nowrap>
                                  <a
                                    href="#"
                                    @click="deletefile(context,item.fileId, USERPROFILE.system_usersid)"
                                    class="btn btn-sm btn-danger width-60"
                                  >Delete</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end table-responsive -->
                      </div>
                      <!-- end panel-body -->
                      <!-- begin hljs-wrapper -->
                    </div>
                    <div class="alert alert-secondary fade show">
                      <div class="text-center">
                        <!-- <button type="submit" class="btn btn-outline-secondary">Save To Drafts</button> -->
                      </div>
                    </div>
                    <!-- end row -->
                  </fieldset>
                </div>
                <!-- end step-3 -->
                <!-- begin step-3 -->
                <div id="step-4">
                  <div class="jumbotron m-b-0 text-center">
                    <h2 class="text-inverse">Taxi Driver Registered Successfully</h2>
                    <p
                      class="m-b-30 f-s-16 hide"
                    >Password has been sent to the New User via SMS, Please check your phone</p>
                    <p>
                      <router-link
                        :to="{ path: '/Driver/All' }"
                        class="btn btn-primary btn-lg"
                      >View Drivers</router-link>
                    </p>
                  </div>
                </div>
                <!-- end step-4 -->
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>

        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";
const saIdParser = require("south-african-id-parser");

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TaxiDrivers");
// value.context.$emit("init");

export default {
  name: "ADDTAXIDRIVER",
  data: function () {
    return {
      acceptedFiles: "application/pdf",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      GETALLTITLES: [],
      GETALLGENDERS: [],
      status: true,
      skip: 0,
      len: 1000,
      GETALLSUBURBS: [],
      GETALLCOUNTRY: [],
      GETALLDOCUMENTTYPE: [],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      IsForeign: false,
      selectedTitleId: undefined,
      selectedGenderId: undefined,
      IDNUMBER: "",
      isIdNumberExists: true,
      isIdNumberValid: undefined,
      selectedUserGroupId: undefined,
      DropZoneSessionID: this.$uuid.v4(),
      CapturedPhotosSessionID: this.$uuid.v4(),
      dropzone_files: this.$store.getters["fileupload/GETALL"],
      GETUPLOADEDFILES: [],
      id: this.$route.params.id,
      DRIVERIMAGES: [],
      // GETADDTAXIDRIVER: this.$store.getters["TaxiDrivers/GETADDTAXIDRIVER"],
      captured_photos: this.$store.getters["capturephotos/GETALL"],
      VEHICLEIMAGESFRONT: null,
      VEHICLEIMAGESBACK: null,
      DATACOLLECTION: this.$store.getters["OperatorVehicle/GETDATACOLLECTED"],
      CITIES: [],
      ADDSUBURB: {
        _city_id: null,
        _cityname: "",
        _description: "",
        _code: "",
        _postalcode: "",
        _added_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      ADDTAXIDRIVER: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        title_id: 0,
        firstname: "",
        surname: "",
        id_number: "",
        date_of_birth: "",
        passport_number: "",
        driver_license_number: "",
        pdp_number: "",
        mobilenumber: "",
        telephone_number: "",
        fax_number: "",
        physical_address: "",
        physical_address2: "",
        physical_suburb_id: null,
        postal_address: "",
        postal_address2: "",
        postal_suburb_id: null,
        email_address: "",
        drivers_license_expirydate: "",
        pdp_license_expirydate: "",
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
        gender_id: null,
        country_id: null,
        photosessionid: "",
        taxi_driver_joined_date: "",
        existing_membership_number: "",
        dropzoneid: "",
        dropzone_files: [
          {
            customType: 0,
            fileId: "",
          },
        ],
      },
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  beforeMount: function () {
    // this.loadDriverImage(this.context);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    CapturePhotosControl,
  },

  computed: {
    // ...mapGetters([]),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  watch: {
    IDNUMBER: function (val) {
      
      if (this.IsForeign == true) {
        if (val.toString().length > 10) {
          this.context.$store
            .dispatch("TaxiDrivers/getSearchDriverIDnumber", {
              router: this.$router,
              id: val,
            })
            .then((rb) => {
              this.isIdNumberExists = rb.data[0].fn_taxi_driverexists;
            })
            .catch((err) => {
              console.log("validation err: ", err);
            });
        }
      } else {
        if (val.toString().length > 11) {
          if (saIdParser.validate(val)) {
            this.isIdNumberValid = true;
            console.log(val);
            this.context.$Notify.Success("ID Verified Successfully", "");
            this.context.$store
              .dispatch("TaxiDrivers/getSearchDriverIDnumber", {
                router: this.$router,
                id: val,
              })
              .then((rb) => {
                this.isIdNumberExists = rb.data[0].fn_taxi_driverexists;
              })
              .catch((err) => {
                console.log("validation err: ", err);
              });
          } else {
            this.context.$Notify.Error("Not A Valid SA ID.");
            this.isIdNumberValid = false;
          }
        }
      }
    },
  },
  methods: {
    ...mapActions([
      "getall",
      "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted",
    ]),
    loadDriverImage(context) {
      if (context.id != ":id")
        context.VEHICLEIMAGESFRONT = context.DATACOLLECTION.filter(el => el.datacollection_id == context.id)[0].driveridcard_front_image[0].data;

      if (context.id != ":id")
        context.VEHICLEIMAGESBACK = context.DATACOLLECTION.filter(el => el.datacollection_id == context.id)[0].driveridcard_back_image[0].data;


    },
    fileadded: function (data) {
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')
      const context = $("#wizard").data("context");
      context.GETUPLOADEDFILES.push({
        filename: data.name,
        fileId: data.fileId,
        size: data.size,
        mimeType: data.type,
        customType: data.customType,
      });
    },

    loadCities(context) {
      context.$store
        .dispatch("city/getall", {
          _dataObj: {
            router: context.$router,
          },
        })
        .then((data) => {
          console.log("cities found:", data);
          context.CITIES = data;
        })
        .catch((error) => {
          console.log("cities errors:", error);
        })
        .finally(() => {});
    },

    loadSuburbs(context) {
      context.$store
        .dispatch("TO/getSuburbs", {
          router: context.$router,
          status: context.status,
          skip: context.skip,
          len: context.len,
        })
        .then((data) => {
          console.log("suburbs found: ", data);

          context.GETALLSUBURBS = data;
        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },

    addNewSuburb: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          value.context.$wait.start("add+");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("suburb/add", {
              _dataObj: {
                item: value.item,
                router: value.router,
              },
            })
            .then((data) => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add+");
              console.log("suburb added: ", data);
              value.context.$Notify.Success("Successfully Added!", "");
              context.loadSuburbs(context);
            })
            .catch((error) => {
              value.context.$wait.end("add+");

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },

    deletefile: (context, fileId, userID) => {
      context.$Notify
        .YesNo(false, { context, fileId, userID })
        .then((value) => {
          if (value) {
            value.context.$wait.start("add");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("fileupload/delete", {
                id: value.fileId,
                deletedby: value.userID,
              })
              .then(() => {
                $("#AddModal").modal("hide");
                value.context.$wait.end("add");
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch((error) => {
                value.context.$wait.end("add");

                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    add: (context, item, taxiassociationid, addedby) => {
      context.$Notify
        .YesNo(false, { context, item: context.ADDTAXIDRIVER })
        .then((value) => {
          
          const dropzone_uploads = context.GETUPLOADEDFILES;
          const fileuploaded = [];

          if (dropzone_uploads.length > 0) {
            var failedvalidation = false;
            dropzone_uploads.forEach((element) => {
              if (element.customType === 0) {
                failedvalidation = true;
                return;
              }
              fileuploaded.push({
                customType: Number(element.customType),
                fileId: element.fileId,
              });
            });
            if (failedvalidation) {
              value.context.$Notify.Error("Please Select Document Type.", "");
              return;
            }
          }

          value.item.dropzone_files = fileuploaded;
          value.item.photosessionid = context.CapturedPhotosSessionID;
          value.item.dropzoneid = context.DropZoneSessionID;

          value.item.taxi_driver_joined_date = context.$Toolkit.ConvertToServerDate(
            $("#taxi_driver_joined_date").datepicker("getDate")
          );

          value.item.drivers_license_expirydate = context.$Toolkit.ConvertToServerDate(
            $("#drivers_license_expirydate").datepicker("getDate")
          );

          value.item.pdp_license_expirydate = context.$Toolkit.ConvertToServerDate(
            $("#pdp_license_expirydate").datepicker("getDate")
          );

          value.item.date_of_birth = context.$Toolkit.ConvertToServerDate(
            $("#date_of_birth").datepicker("getDate")
          );

          value.item.postal_suburb_id = parseInt(
            $("#selectPostalSuburb").val()
          );
          value.item.physical_suburb_id = parseInt(
            $("#selectPhysicalSuburb").val()
          );

          if (value) {
            $(".sw-btn-next")
              .text("")
              .append('<i class="fas fa-spinner fa-spin"></i>')
              .attr("disabled", "disabled");

            value.router = value.context.$router;
            value.context.$store
              .dispatch("TaxiDrivers/add", {
                _dataObj: value,
              })
              .then((data) => {
                console.log("driver vue page", data);
                $(".sw-btn-next").text("Done").attr("disabled", "disabled");
                $(".sw-btn-prev").attr("disabled", "disabled");
                $("#wizard").data("taxidriveradded", true);
                $("#wizard").smartWizard("next");
                value.context.$Notify.Success("Successfully Added!", "");
              })
              .catch((error) => {
                console.log("this is the error: ", error);
                $(".sw-btn-next").removeAttr("disabled");
                value.context.$Notify.Error(error.data.message);
                if (Array.isArray(error.data))
                  value.context.$Notify.Error(error.data[0].message, "");
                else value.context.$Notify.Error(error.data.message, "");
              })
              .finally(() => {});
          }
        });
    },
    loadLookUps(context) {
      
      context.$store
        .dispatch("lookups/init", {
          router: context.$router,
        })
        .then((data) => {
          console.log("loadLookUps found: ", data);
          
          context.GETALLTITLES = data.LOADEDTITLES;
          context.GETALLGENDERS = data.LOADEDGENDER;
          context.GETALLCOUNTRY = data.LOADEDCOUNTRIES;
          context.GETALLDOCUMENTTYPE = data.LOADEDDOCUMENTTYPE;
        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
  },
  mounted: function () {
    //this.get(this.context)

   

    $("#selectPhysicalSuburb").select2({
      multiple: false,
      placeholder: "Select a suburb",
      allowClear: false,
    });

    $("#selectPostalSuburb").select2({
      multiple: false,
      placeholder: "Select a suburb",
      allowClear: false,
    });

    $(".datepicker-disabled-past").datepicker({
      todayHighlight: true,
      autoclose: true,
    });

    App.init();

    this.loadLookUps(this.context);
    this.loadCities(this.context);
    this.loadSuburbs(this.context);

    $("#wizard").data("taxidriveradded", false);

    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "",
      transitionSpeed: 0,
      keyNavigation: false,
      cycleSteps: true,
      useURLhash: false,
      showStepURLhash: false,
      toolbarSettings: {
        toolbarPosition: "bottom",
      },
    });
    $("#wizard").data("context", this.context);
    $("#wizard").on("leaveStep", function (
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {
      const context = $("#wizard").data("context");
      const currentstep = stepNumber + 1;
      const res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

      $(".sw-btn-next").text("Next");

      if (stepDirection == "backward") return true;

      if (currentstep === 2 && stepDirection == "forward") {
        if (context.$store.getters["capturephotos/GETALL"].length > 0) {
          if (context.$store.getters["capturephotos/GETALL"].length < 2) {
            context.$Notify.Error("Please Add More Photos", "");
            return false;
          }
        }

        $(".sw-btn-next").text("Save");
      }

      if (currentstep === 3 && stepDirection == "forward") {
        context.ADDTAXIDRIVER.id_number = context.IDNUMBER;

        const taxidriveradded = $("#wizard").data("taxidriveradded");
        if (!taxidriveradded) {
          context.add(
            context,
            context.item,
            context.taxiassociationid,
            context.addedby
          );
        }

        return taxidriveradded;
      }

      return res;
    });
    // Highlight.init();

    this.loadDriverImage(this.context);

    $(".superbox").SuperBox({
      background: "#242a30",
      border: "rgba(0,0,0,0.1)",
      xColor: "#a8acb1",
      xShadow: "embed",
    });

    // function calculateDivider() {
    //   var dividerValue = 4;
    //   if ($(this).width() <= 480) {
    //     dividerValue = 1;
    //   } else if ($(this).width() <= 767) {
    //     dividerValue = 2;
    //   } else if ($(this).width() <= 980) {
    //     dividerValue = 3;
    //   }
    //   return dividerValue;
    // }
    // var handleIsotopesGallery = function() {
    //   var container = $("#gallery");
    //   var dividerValue = calculateDivider();
    //   var containerWidth = $(container).width();
    //   var columnWidth = containerWidth / dividerValue;
    //   $(container).isotope({
    //     resizable: true,
    //     masonry: {
    //       columnWidth: columnWidth
    //     }
    //   });

    //   $(window).smartresize(function() {
    //     var dividerValue = calculateDivider();
    //     var containerWidth = $(container).width();
    //     var columnWidth = containerWidth / dividerValue;
    //     $(container).isotope({
    //       masonry: {
    //         columnWidth: columnWidth
    //       }
    //     });
    //   });

    //   var $optionSets = $("#options .gallery-option-set"),
    //     $optionLinks = $optionSets.find("a");

    //   $optionLinks.click(function() {
    //     var $this = $(this);
    //     if ($this.hasClass("active")) {
    //       return false;
    //     }
    //     var $optionSet = $this.parents(".gallery-option-set");
    //     $optionSet.find(".active").removeClass("active");
    //     $this.addClass("active");

    //     var options = {};
    //     var key = $optionSet.attr("data-option-key");
    //     var value = $this.attr("data-option-value");
    //     value = value === "false" ? false : value;
    //     options[key] = value;
    //     $(container).isotope(options);
    //     return false;
    //   });
    // };

    //     $(function(){
    //    $(".dropdown-menu").on('click', 'a', function(){
    //        $(this).parents('.dropdown').find('button').text($(this).text());
    //    })
    // });
  },
};
</script>

	

<style scoped>
</style>