<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TaxiVIP'"
      :ActiveMenu="'ManageVIPRoutes'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="VIP ROUTES"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Association"
        breadcrumbitem3="Manage Routes"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin row -->
          <div class="row">
            <!-- begin col-12 -->
            <div class="col-lg-12">
              <!-- begin panel -->
              <div class="panel panel-inverse panel-primary">
                <!-- begin panel-heading -->
                <div class="panel-heading">
                  <div class="panel-heading-btn">
                    <a
                      href="javascript:;"
                      class="btn btn-xs btn-icon btn-circle btn-default"
                      data-click="panel-expand"
                    >
                      <i class="fa fa-expand"></i>
                    </a>
                    <a
                      href="javascript:;"
                      class="btn btn-xs btn-icon btn-circle btn-success"
                      data-click="panel-reload"
                    >
                      <i class="fa fa-redo"></i>
                    </a>
                    <a
                      href="javascript:;"
                      class="btn btn-xs btn-icon btn-circle btn-warning"
                      data-click="panel-collapse"
                    >
                      <i class="fa fa-minus"></i>
                    </a>
                  </div>
                  <h4 class="panel-title">VIP ROUTES</h4>
                </div>
                <!-- end panel-heading -->
                <div class="panel-toolbar">
                  <div class="btn-group m-r-5">
                    <a
                      data-toggle="modal"
                      class="btn btn-white"
                      href="javascript:;"
                      @click="AddVIPRoute(context)"
                    >
                      <i class="fa fa-plus"></i>
                      Add VIP Route
                    </a>
                  </div>
                </div>
            
              <!-- begin panel-body -->
              <div class="panel-body">
                <table id="data-table-default" class="table table-striped table-bordered width-full">
                  <thead>
                    <tr>
                      <th width="1%" data-orderable="false"></th>
                      <th class="text-nowrap">Name</th>
                      <th class="text-nowrap">Description</th>
                      <th class="text-nowrap">Route Fare</th>
                      <th class="text-nowrap">VIP Price</th>
                      <th class="text-nowrap">Status</th>
                      <th class="text-nowrap">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(item,index) in ASSOCIATIONROUTES"
                      v-bind:key="item._id"
                      :class="index & 1 ? 'odd gradeA' : 'even'"
                    >
                      <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                      <td>{{item._name}}</td>
                      <td>{{item._description}}</td>
                      <td>{{item._taxifare | currencywithSymbol}}</td>
                      <td>
                        {{item._vip_price | currencywithSymbol}}
                        <CurrencyInput
                                        :auto-decimal-mode="true"
                                        :currency="currency"
                                        v-if="EDITMMODE && !item._vip_routeid"
                                        :id="item._id+'_vip_price'"
                                        data-parsley-group="step-1"
                                        data-parsley-required="true"
                                        v-model="item._newvip_price"
                                        class="form-control text-left demo__currency-input"
                                      />
                      </td>
                      
                      <td>
                        <span
                          class="badge f-s-12"
                          v-bind:class="{ 'badge-success': item._vipstatus, 'label-secondary':!item._vipstatus  }"
                        >{{item._vipstatus == true ? 'Active' : 'Not Active' }}</span>
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-sm btn-danger width-80"
                          :id="item._id+'_remove_vip_price'"
                          v-if="item._vip_routeid"
                          @click="removeRoutesByAssId(context,item._vip_routeid,item)"
                        >Remove</button>
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          :id="item._id+'_save_vip_price'"
                          v-if="EDITMMODE && !item._vip_routeid"
                          @click="saveRoutesByAssId(context,item._id,item)"
                        >Save</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- end panel-body -->
               </div>
              <!-- end panel -->
            </div>
            <!-- end col-12 -->
          </div>
          <!-- end row -->
        </div>

        <!-- end col-12 -->
      </div>

     

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->
	
<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("taxivip");

export default {
  name: "AddTaxiAssociation",
  data: function() {
    return {
      currencySymbol: "R ",
      position: "prefix",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      ASSOCIATIONROUTES: [],
      SYSTEMUSERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      id: this.$route.params.id,
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      EDITMMODE: false,
      ADDROUTE: {
        _taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        _name: "",
        _description: "",
        _taxifare: 0,
        _code: "",
         _nrc: "",
         _brc: "",
        _origin: "",
        _destination: "",
        _added_by: this.$store.getters["auth/USERPROFILE"].system_usersid
      },
      UPDATEROUTE: {
        taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        name: "",
        description: "",
        taxifare: 0,
        code: "",
         _nrc: "",
         _brc: "",
          _origin: "",
           _destination: "",
        updated_by: this.$store.getters["auth/USERPROFILE"].system_usersid
      },
      routeid: null
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl
  },
  beforeMount() {
    
  },
  computed: {
    ...mapGetters({
    }),
    currency() {
      return { [this.position]: this.currencySymbol };
    },
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions(["addtaxiassociation"]),

    saveRoutesByAssId: (context,id,item) => {
      debugger;
      context.$Notify.YesNo(false, { context, id,item }).then(value => {
        if (value) {
          value.context.$store
        .dispatch("taxivip/addVIPRoute", {
          router: value.context.$router,
          AddModel:{
            taxiassociation_id: value.context.taxi_association_id,
            taxiassociation_routes_id: value.id,
            price: value.item._newvip_price,
            added_by: value.context.SYSTEMUSERID
          }
        })
        .then(response => {

          debugger;

          value.item._vip_price = value.item._newvip_price;
          value.item._vipstatus = true;
          value.item._vip_routeid = response[0].fn_vip_route_pricing_add;
          // document.getElementById(value.item._id+'_vip_price').style.display = "none";
          // document.getElementById(value.item._id+'_save_vip_price').style.display = "none";

        })
        .catch(error => {
          console.log("Association Routes errors:", error);
        })
        .finally(() => {
         
        });
      }
      
    });
    },
    removeRoutesByAssId: (context,id,item) => {
      debugger
      context.$Notify.YesNo(false, { context, id,item }).then(value => {
        if (value) {

          value.context.$store
        .dispatch("taxivip/removeRoutesByAssId", {
          router: value.context.$router,
          id:value.id
        })
        .then(response => {

          debugger

          value.item._vip_price = null;
          value.item._vipstatus = false;
          value.item._vip_routeid = null;
          // document.getElementById(value.item._id+'_vip_price').style.display = "none";
          // document.getElementById(value.item._id+'_save_vip_price').style.display = "none";

        })
        .catch(error => {
          console.log("Association Routes errors:", error);
        })
        .finally(() => {
          
        });

      }});
    }
    ,getRoutesByAssId: context => {
      context.$store
        .dispatch("taxivip/getTaxiAssRoutes", {
          router: context.$router,
          id: context.taxi_association_id
        })
        .then(data => {
          console.log("Association Routes:", data);
          context.ASSOCIATIONROUTES = data;
        })
        .catch(error => {
          console.log("Association Routes errors:", error);
        })
        .finally(() => {
          $("#data-table-default").DataTable({
            responsive: true
          });
        });
    },

    getRoute: (context, item) => {
      console.log("this is the route id: ", item);
      context.getRouteById(context, item);
    },
    AddVIPRoute: (context) => {
      context.EDITMMODE = true;
    },

    getRouteById: (context, id) => {
      context.$store
        .dispatch("TA/getRouteById", {
          router: context.$router,
          id
        })
        .then(data => {
          console.log("Association Route by id: ", data);
          context.UPDATEROUTE.name = data[0]._name;
          context.UPDATEROUTE.description = data[0]._description;
          context.UPDATEROUTE.code = data[0]._code;
          context.UPDATEROUTE._nrc = data[0]._nrc;
          context.UPDATEROUTE._brc = data[0]._brc;
          context.UPDATEROUTE._origin = data[0]._origin;
          context.UPDATEROUTE._destination = data[0]._destination;
          context.UPDATEROUTE.taxifare = data[0]._taxifare;
          context.routeid = data[0]._id;
        })
        .catch(error => {
          console.log("Association Route by id errors: ", error);
        });
    },

    addRoute: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TA/addAssociationRoute", {
              _dataObj: value
            })
            .then(result => {
              console.log("Route Added successfully: ", result);
              value.context.$Notify.Success("Successfully Added!", "");
              $("#AddModal").modal("hide");
              context.ADDROUTE._name = "";
              context.ADDROUTE._description = "";
              context.ADDROUTE._taxifare = 0;
              context.ADDROUTE._code = "";
              context.ADDROUTE._nrc = "";
              context.ADDROUTE._brc = "";
              context.ADDROUTE._origin = "";
              context.ADDROUTE._destination = "";
              context.getRoutesByAssId(context);
            })
            .catch(error => {
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },

    updateRoute: (context, id, item) => {
      context.$Notify.YesNo(false, { context, id, item }).then(value => {
        if (value) {
          
          value.context.$wait.start("update-");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TA/updateAssociationRoute", {
              router: value.router,
              id,
              updateModel: item
            })
            .then(result => {
              console.log("Updated successfully: ", result);
              value.context.$wait.end("update-");
              value.context.$Notify.Success("Updated Successfully!", "");
              $("#EditModal").modal("hide");
              context.getRoutesByAssId(context);
            })
            .catch(error => {
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },

    deleteRoute: (context, id) => {
      context.TaxiAssociationRoutes = context.TaxiAssociationRoutes.filter(
        item => item.id !== id
      );
    }
  },
  mounted: function() {

    this.getRoutesByAssId(this.context);
    App.init();
    //this.get(this.context)

    

    $(".btnadded")
      .mouseenter(function() {
        $(this)
          .removeClass("btn-success")
          .addClass("btn-danger")
          .text("Remove");
      })
      .mouseleave(function() {
        $(this)
          .removeClass("btn-danger")
          .addClass("btn-success")
          .text("Added");
      });
  }
};
</script>


<style scoped>
</style>

