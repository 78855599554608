<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Operators'"
      :ActiveMenu="'BulkDocumentsUpload'"
    />
    <!-- end #sidebar -->
    <transition name="bounce">
      <div id="content" class="content">
        <!-- begin page-header -->
        <headerpagebreadcrumb
          pageheaderTitle="Upload Operators Supporting Documents"
          pageheaderdescription
          breadcrumbitem1="Home"
          breadcrumbitem2="Approval"
          breadcrumbitem3="Upload Documents"
        />
        <!-- begin nav-pills -->

        <div class="panel panel-primary">
          <div class="panel-heading">
            <div class="panel-heading-btn">
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-default"
                data-click="panel-expand"
              >
                <i class="fa fa-expand"></i>
              </a>
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-success"
                data-click="panel-reload"
              >
                <i class="fa fa-redo"></i>
              </a>
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-warning"
                data-click="panel-collapse"
              >
                <i class="fa fa-minus"></i>
              </a>
              <a
                href="javascript:;"
                class="btn btn-xs btn-icon btn-circle btn-danger"
                data-click="panel-remove"
              >
                <i class="fa fa-times"></i>
              </a>
            </div>
            <h4 class="panel-title">Upload Documents</h4>
          </div>
          <!-- begin card -->
          <div class="card text-center">
            <div class="card-block">
              <div class="tab-content p-0 m-0">
                <div class="tab-pane fade active show" id="card-tab-1" v-show="SELECTEDTAXI">
                 <div class="invoice-to">
                    <small>
                      <label class="col-md-0 col-form-label text-md-right">Select Operator</label>
                      <select
                        class="default-selectOperator text-center default-select form-control col-md-12"
                        data-size="5"
                        data-live-search="true"
                      >
                        <option
                          v-for="item in OPERATORSBYAFFILLIATION"
                          :key="item.taxi_operator_id"
                          :value="item.taxi_operator_id"
                          class="text-center"
                        >{{item.firstname}} {{item.surname}} ({{item.id_number}}) ({{item.membership_number}})</option>
                      </select>
                    </small>
                    <address class="m-t-5 m-b-5">
                      <strong class="text-inverse"></strong>
                      <br />
                    </address>
                    <DropZoneControl
                          :acceptedFiles="acceptedFiles"
                          :ClearData="true"
                          v-on:fileadded="fileadded"
                          :SessionID="DropZoneSessionID"
                        ></DropZoneControl>
                  </div>
                 
                  <!--End dropzone!-->
                </div>
              
              </div>
            </div>
          </div>
          <!-- end card -->

          <div
            class="panel panel-primary"
            v-if="GETUPLOADEDFILES.length > 0"
            data-sortable-id="table-basic-7"
          >
            <!-- begin panel-heading -->
            <div class="panel-heading ui-sortable-handle">
              <h4 class="panel-title">Uploaded Files</h4>
            </div>
            <!-- end panel-heading -->
            <!-- begin panel-body -->
            <div class="panel-body">
              <!-- begin table-responsive -->
              <div class="table-responsive">
                <table class="table table-striped m-b-0">
                  <thead>
                    <tr>
                      <th>Filename</th>
                      <th>Type</th>
                      <th>Size</th>
                      <th>Document Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="item" v-for="(item) in GETUPLOADEDFILES">
                      <td>{{item.filename}}</td>
                      <td>{{item.mimetype}}</td>
                      <td>{{item.size}}</td>
                      <td>
                        <select
                          class="form-control col-sm-4"
                          data-size="10"
                          data-live-search="true"
                          data-parsley-group="step-1"
                          data-parsley-required="true"
                          v-model="item.customType"
                        >
                          <option value="0" selected>Select Document Type</option>
                          <option
                            v-bind:key="item._id"
                            v-for="item in GETALLDOCUMENTTYPE"
                            :value="item._id"
                          >{{item._name}}</option>
                        </select>
                      </td>
                      <td class="with-btn" nowrap>
                        <a
                          href="#"
                          @click="deletefile(context,item.fileId, USERPROFILE.system_usersid)"
                          class="btn btn-sm btn-danger width-60"
                        >Delete</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- end table-responsive -->
            </div>
            <!-- end panel-body -->
            <!-- begin hljs-wrapper -->
          </div>

          <div class="btn-row">
            <div class="text-center">
              <button type="button" @click="UploadSupportingFiles(context)" class="btn btn-sm btn-primary width-200 height-40 m-5">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { default as DropZoneControl, default as operatorDropZoneControl } from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import { setTimeout } from "timers";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("approvals");

export default {
  name: "AuthorizeApprovalRequest",
  data: function () {
    return {
      acceptedFiles: "application/pdf",
      acceptedFiles2: "application/pdf",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      context: this,
      SELECTEDTAXI: true,
      SELECTEDOPERATOR: true,
      SELECTEDDRIVER: true,
      addedsuccess: undefined,
      errormessage: undefined,
      vehicleDropZoneSessionID: this.$uuid.v4(),
      operatorDropZoneSessionID: this.$uuid.v4(),
      id: this.$route.params.id,
      affiliation_id: this.$store.getters["auth/USERPROFILE"]
        .selected_taxi_association._taxi_affiliation_id,
      GETALLDOCUMENTTYPE: this.$store.getters["lookups/GETALLDOCUMENTTYPE"],
      GETAllTAXIMODELS: this.$store.getters["TV/GETALLMODEL"],
      GETUPLOADEDFILES: [],
      OPERATORVEHICLES: [],
      GETAllTAXIDRIVERS: [],
      OPERATORSBYAFFILLIATION: [],
      GETOPERATORUPLOADEDFILES: [],
      comment: "",
      skip: 0,
      length: 10000,
      dataLoaded: false,
      DropZoneSessionID: this.$uuid.v4(),
      GETALLDOCUMENTTYPE: [],
      // canApproveOpperator: this.$store.getters["auth/GETUSERROLES"][13]._code,
      // canApproveDriver: this.$store.getters["auth/GETUSERROLES"][6]._code,
      // canApproveOpperatorVehicle: this.$store.getters["auth/GETUSERROLES"][5]._code,
    };
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    operatorDropZoneControl,
  },
  computed: {
    //...mapGetters(["GETALLPENDING", "GETALLAPPROVED", "GETALLREJECTED"]),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  beforeMount() {},
  methods: {
    ...mapActions([
      "getAllApproved",
    ]),

    GETTAXISBYMODELID: function (context, id) {
      return context.OPERATORVEHICLES.filter(
        (item) => item._vehiclemodel_id === id
      );
    },

    UploadSupportingFiles: (context) => {

      

      context.$Notify
        .YesNo(false, { context, item: {
          taxi_operator_id: $(".default-selectOperator").val()
        } })
        .then(value => {

          if(!value)
            return;

          

          const dropzone_uploads = context.GETUPLOADEDFILES;
          const fileuploaded = [];

          if (dropzone_uploads.length > 0) {
            var failedvalidation = false;
            dropzone_uploads.forEach(element => {
              if (element.customType === 0) {
                failedvalidation = true;
                return;
              }
              fileuploaded.push({
                customType: element.customType,
                fileId: element.fileId
              });
            });
            if (failedvalidation) {
              value.context.$Notify.Error("Please Select Document Type.", "");
              return;
            }
          }

          value.item.dropzoneid = context.DropZoneSessionID;
          value.item.dropzone_files = fileuploaded;
          value.item.addedby = context.USERID;

          


      // 
      context.$store
        .dispatch("TO/updateOperatorSupportingDocs", {
          addModel: value.item,
          router: context.$router,
        })
        .then((data) => {
          context.$Notify.Success("Uploaded Successfully");
          context.GETUPLOADEDFILES = [];
          context.$router.go();
        })
        .catch((err) => {
          console.log("operator Vehicles errors -> ", err);
        })
        .finally(() => {
          // $("#data-table-vehicles").DataTable({
          //   responsive: true,
          // });
        });
      //this.$emit('init')

      });

    },
    loadLookUps(context) {
      
      context.$store
        .dispatch("lookups/init", {
          router: context.$router,
        })
        .then((data) => {
          console.log("loadLookUps found: ", data);
          
          context.GETALLDOCUMENTTYPE = data.LOADEDDOCUMENTTYPE;
        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
    getOperatorsByTaxiId: (context) => {
      
      context.OPERATORSBYAFFILLIATION = [];
      context.$store
        .dispatch("TO/getTaxiOperatorsByAssIdSummary", {
          router: context.$router,
          taxiassociation_id: context.taxiassociationid,
          skip: context.skip,
          len: context.length,
        })
        .then((data) => {
          console.log("operators found: ", data);

          context.OPERATORSBYAFFILLIATION = data;

          $(".default-selectOperator").select2({
            multiple: false,
            placeholder: "Select A Operator",
            allowClear: false,
          });

          $(".default-selectOperator").val(null).trigger("change");
        })
        .catch((error) => {
          console.log("operator errors: ", error);
          context.$Notify.Error(error.data.message, "");
        });
    },

    fileadded: function (data) {
      
      console.log("Header:fileadded: ", data);
      //  this.$emit('init')
      this.GETUPLOADEDFILES.push({
        filename: data.name,
        mimetype: data.type,
        size: data.size,
        customType: data.customType,
        fileId: data.fileId
      });
    },
  },

  mounted: function () {
    this.getOperatorsByTaxiId(this.context);

    App.init();

    this.loadLookUps(this.context);

    $(".nav-link").click(() => {
      setTimeout(() => {
        $(".default-select").select2({
          multiple: true,
          allowClear: true,
        });
        $(".default-selectOperator").val(null).trigger("change");
      }, 500);
    });

    // $(".nav-pills a").data("context", this.context);

    // $(".nav-pills a").on("shown.bs.tab", function (event) {
    //   const context = $(".nav-pills a").data("context");
    //   var x = $(event.target).text(); // active tab
    //   var y = $(event.relatedTarget).text(); // previous tab

    //   if (x === "ApprovedApproved") {

    //   } else if (x === "AwaitingAwaiting") {

    //   } else if (x === "RejectedRejected") {

    //   }
    // });
  },
};
</script>