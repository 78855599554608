/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';


export default {
  namespaced: true,
  state: {
    photosuploaded: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETALL: state => state.photosuploaded,
    GETALLBYSESSIONID: (state, id) => state.photosuploaded.filter(item => item.sessionId === id),
  },
  mutations: {
    ADDED: (state, _data) => {
      state.photosuploaded.push(_data);
    },
    MARKASDELETE: (state, _photoid) => {
      if (_photoid !== undefined) {
        state.photosuploaded = state.photosuploaded.filter(item => item.photoid != _photoid);
      }
    },
    CLEARALL: (state) => {
      state.photosuploaded = []
    },
  },
  actions: {
    /* Async */

    /* Auth.js Mutations */
    clearall({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router }) {
      commit('CLEARALL');
    },
    deletephoto({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { id, deletedby, router } ) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.CAPTUREPHOTO_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id,
          {
            id,
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('MARKASDELETE', id);
            resolve();
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });

       
      });
    },
    updateprofilephoto({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { id,updatedby, photo, router } ) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.CAPTUREPHOTO_URL,
        };

        const _updatemodel = {
          photo,
          updatedby,
        }

        this._vm.$TAMS_API.update(requestheader,
          id,
          _updatemodel).then((parsedResult) => {
          if (parsedResult.status) {
            //commit('MARKASDELETE', id);
            resolve();
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });

       
      });
    },
    added({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { filecontent, photoid,sessionId, router, addedBy }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.CAPTUREPHOTO_URL,
        };

        const AddModel = {
          fileId: photoid,
          addedBy,
          sessionId,
          fileName: 'image',
          mimeType: 'image/jpeg',
          data: filecontent,
          length: filecontent.length,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {

            commit('ADDED',{ 
              filecontent,
              sessionId,
              mimetype:'image/jpeg',
              length: filecontent.length,
              photoid,
              customTypeId:0,
            });

            resolve(filecontent);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });

      
    });
    },
  },

};
