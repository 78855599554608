<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
      v-on:init="init"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TPAY'"
      :ActiveMenu="'MoneyTransferDashBoard'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <transition name="bounce">
      <div id="content" class="content" v-show="USERPROFILE.taxi_associations_id">
        <!-- begin page-header -->
        <headerpagebreadcrumb
          pageheaderTitle="Money Transfer Dashboard"
          pageheaderdescription
          breadcrumbitem1="Home"
          breadcrumbitem2="TPAY"
          breadcrumbitem3="Money Transfer DashBoard"
        />
        <!-- end page-header -->

        <!-- begin statsCards -->
        <div class="row">
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-blue">
                <i class="fas fa-registered"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">TOTAL SEND MONEY INCOME</div>
                <div
                  class="stats-number"
                >{{GETFINANCEBYREPORTID('TIS')._totals | newcurrencywithSymbol}}</div>
                <div class="stats-desc">{{GETFINANCEBYREPORTID('TIS').report_description}}</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-green">
                <i class="fas fa-registered"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">TOTAL CASH WITHDRAWAL INCOME</div>
                <div
                  class="stats-number"
                >{{GETFINANCEBYREPORTID('TPS')._totals | newcurrencywithSymbol}}</div>
                <div class="stats-desc">{{GETFINANCEBYREPORTID('TPS').report_description}}</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-red">
                <i class="fas fa-registered"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">TOTAL FLOAT INCOME</div>
                <div
                  class="stats-number"
                >{{GETFINANCEBYREPORTID('TAS')._totals | newcurrencywithSymbol}}</div>
                <div class="stats-desc">{{GETFINANCEBYREPORTID('TAS').report_description}}</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-red">
                <i class="fas fa-registered"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">TOTAL NET INCOME</div>
                <div
                  class="stats-number"
                >{{GETFINANCEBYREPORTID('TAS')._totals | newcurrencywithSymbol}}</div>
                <div class="stats-desc">{{GETFINANCEBYREPORTID('TAS').report_description}}</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
            <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-blue">
                <i class="fas fa-registered"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">AVG MONTHLY MONEY SENT</div>
                <div
                  class="stats-number"
                >{{GETFINANCEBYREPORTID('TIS')._totals | newcurrencywithSymbol}}</div>
                <div class="stats-desc">{{GETFINANCEBYREPORTID('TIS').report_description}}</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-green">
                <i class="fas fa-registered"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">AVG MONTHLY CASH WITHDRAWALS</div>
                <div
                  class="stats-number"
                >{{GETFINANCEBYREPORTID('TPS')._totals | newcurrencywithSymbol}}</div>
                <div class="stats-desc">{{GETFINANCEBYREPORTID('TPS').report_description}}</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-red">
                <i class="fas fa-registered"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">AVG MONTHLY CASH FLOAT</div>
                <div
                  class="stats-number"
                >{{GETFINANCEBYREPORTID('TAS')._totals | newcurrencywithSymbol}}</div>
                <div class="stats-desc">{{GETFINANCEBYREPORTID('TAS').report_description}}</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-red">
                <i class="fas fa-registered"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">AVG MONTHLY NET INCOME</div>
                <div
                  class="stats-number"
                >{{GETFINANCEBYREPORTID('TAS')._totals | newcurrencywithSymbol}}</div>
                <div class="stats-desc">{{GETFINANCEBYREPORTID('TAS').report_description}}</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          
        </div>
        <!-- end statsCards -->

        <!-- begin #Main Body Template -->

        <!-- begin row -->
        <div class="row">
          <!-- begin col-4 -->
          <div class="col-md-6 col-lg-4">
            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <!-- <select class="ml-2 btn btn-success btn-xs">
                    <option disabled selected>Year</option>
                    <option value="2019">2019</option>
                  </select> -->
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">DAILY TRANSACTIONS(Dep/Pay)</h4>
              </div>
              <div class="panel-body">
                <div id="nv-donut-chart" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-4 -->

          <!-- begin col-4 -->
          <div class="col-md-6 col-lg-4">
            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <!-- <select class="ml-2 btn btn-success btn-xs">
                    <option disabled selected>Year</option>
                    <option value="2019">2019</option>
                  </select> -->
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">WEEKLY TRANSACTIONS(Dep/Pay)</h4>
              </div>
              <div class="panel-body">
                <div id="nv-pie-chartTPA" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-4 -->

          <!-- begin col-4 -->
          <div class="col-md-6 col-lg-4">
            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                  <!-- <select class="ml-2 btn btn-success btn-xs">
                    <option disabled selected>Year</option>
                    <option value="2019">2019</option>
                  </select> -->
                </div>
                <h4 class="panel-title">MONTHLY TRANSACTIONS(Dep/Pay)</h4>
              </div>
              <div class="panel-body">
                <div id="morris-donut-chartTAA" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-4 -->
        </div>
        <!-- end row -->

        <!-- begin row -->
        <div class="row">
          <!-- begin col-8 -->
          <div class="col-lg-12">
            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">
                  DAILY TRANSACTIONS 
                  
                </h4>
              </div>
              <div class="panel-body">
                <div id="nv-bar-chart" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-8 -->

          <!-- begin col-8 -->
          <div class="col-lg-12">
            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">
                  WEEKLY TRANSACTIONS 
                 
                </h4>
              </div>
              <div class="panel-body">
                <div id="nv-bar-chartoperators" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-8 -->

          <!-- begin col-8 -->
          <div class="col-lg-12">
            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">
                  MONTHLY TRANSACTIONS 
                 
                </h4>
              </div>
              <div class="panel-body">
                <div id="nv-bar-charttaxis" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-8 -->

               <!-- begin col-8 -->
          <div class="col-lg-12">
            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">
                  QUARTELY TRANSACTIONS 
                 
                </h4>
              </div>
              <div class="panel-body">
                <div id="nv-bar-charttaxis" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-8 -->

            <!-- begin col-8 -->
          <div class="col-lg-12">
            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">
                  YEARLY TRANSACTIONS 
                 
                </h4>
              </div>
              <div class="panel-body">
                <div id="nv-bar-charttaxis" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
          <!-- end col-8 -->

        </div>
        <!-- end row -->

        <!-- begin #Main Body Template -->
      </div>
    </transition>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import statsCards from "@/components/charts/statsCards.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("sysadmindashboard");

export default {
  name: "sysadmin_dashboard",
  data: function() {
    return {
      FilterByTaxiAfficiation: 0,
      context: this,
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      GETUSERGROUPCODE: this.$store.getters["auth/USERPROFILE"].usergroups_code
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  beforeMount() {},
  components: {
    headerpagebreadcrumb,
    statsCards,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters([
      "GETBYREPORTID",
      "GETBYREPORTIDS",
      "GETFINANCEBYREPORTID",
      "GETFINANCEBYREPORTIDS"
    ])
  },
  methods: {
    ...mapActions(["getfinancedashboard", "getdashboard"]),
    init: function() {
      App.init();
      this.initcharts();
    },
    ChangeFilterByTaxiAfficiation: function(e) {
      this.filterTAPhandlePerProvince(
        this,
        this.FilterByTaxiAfficiation === "SNC" ? "NTA" : "SNC"
      );
    },
    ChangeTOPFilterByTaxiAfficiation: function(e) {
      this.filterTOPhandlePerProvince(
        this,
        this.FilterByTaxiAfficiation === "SNC" ? "NTA" : "SNC"
      );
    },
    ChangeTVPFilterByTaxiAfficiation: function(e) {
      this.filterTVPhandlePerProvince(
        this,
        this.FilterByTaxiAfficiation === "SNC" ? "NTA" : "SNC"
      );
    },
    ChangeTDPFilterByTaxiAfficiation: function(e) {
      this.filterTDPhandlePerProvince(
        this,
        this.FilterByTaxiAfficiation === "SNC" ? "NTA" : "SNC"
      );
    },
    filterTAPhandlePerProvince: function(context, taxiaffcode) {
      "use strict";
      // Bar graph 1 Taxi Association By RegionF

      const colors = [
        COLOR_RED,
        COLOR_ORANGE,
        COLOR_GREEN,
        COLOR_AQUA,
        COLOR_BLUE,
        COLOR_PURPLE,
        COLOR_GREY,
        COLOR_BLACK,
        COLOR_BLACK
      ];

      const barTAP = context
        .GETBYREPORTIDS("TAP")
        .filter(item => item.additional1 !== taxiaffcode)
        .map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals,
            color: colors[index]
          };
        });

      var barChartData = [
        {
          key: "Cumulative Return",
          values: barTAP
        }
      ];

      window.chartData
        .datum(barChartData)
        .transition()
        .duration(500)
        .call(window.barChart);
      nv.utils.windowResize(window.barChart.update);
    },
    filterTOPhandlePerProvince: function(context, taxiaffcode) {
      "use strict";
      // Bar graph 1 Taxi Association By RegionF

      const colors = [
        COLOR_RED,
        COLOR_ORANGE,
        COLOR_GREEN,
        COLOR_AQUA,
        COLOR_BLUE,
        COLOR_PURPLE,
        COLOR_GREY,
        COLOR_BLACK,
        COLOR_BLACK
      ];

      const barTAP = context
        .GETBYREPORTIDS("TOP")
        .filter(item => item.additional1 !== taxiaffcode)
        .map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals,
            color: colors[index]
          };
        });

      var barChartData = [
        {
          key: "Cumulative Return",
          values: barTAP
        }
      ];

      window.chartDataTOP
        .datum(barChartData)
        .transition()
        .duration(500)
        .call(window.barChartTOP);
      nv.utils.windowResize(window.barChartTOP.update);
    },
    ChangeTVPFilterByTaxiAfficiation: function(context, taxiaffcode) {
      "use strict";
      // Bar graph 1 Taxi Association By RegionF

      const colors = [
        COLOR_RED,
        COLOR_ORANGE,
        COLOR_GREEN,
        COLOR_AQUA,
        COLOR_BLUE,
        COLOR_PURPLE,
        COLOR_GREY,
        COLOR_BLACK,
        COLOR_BLACK
      ];

      const barTVP = context
        .GETBYREPORTIDS("TVP")
        .filter(item => item.additional1 !== taxiaffcode)
        .map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals,
            color: colors[index]
          };
        });

      var barChartData = [
        {
          key: "Cumulative Return",
          values: barTVP
        }
      ];

      window.chartDataTVP
        .datum(barChartData)
        .transition()
        .duration(500)
        .call(window.barChartTVP);
      nv.utils.windowResize(window.barChartTVP.update);
    },
    ChangeTDPFilterByTaxiAfficiation: function(context, taxiaffcode) {
      "use strict";
      // Bar graph 1 Taxi Association By RegionF

      const colors = [
        COLOR_RED,
        COLOR_ORANGE,
        COLOR_GREEN,
        COLOR_AQUA,
        COLOR_BLUE,
        COLOR_PURPLE,
        COLOR_GREY,
        COLOR_BLACK,
        COLOR_BLACK
      ];

      const barTDP = context
        .GETBYREPORTIDS("TDP")
        .filter(item => item.additional1 !== taxiaffcode)
        .map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals,
            color: colors[index]
          };
        });

      var barChartData = [
        {
          key: "Cumulative Return",
          values: barTDP
        }
      ];

      window.chartDataTDP
        .datum(barChartData)
        .transition()
        .duration(500)
        .call(window.barChartTDP);
      nv.utils.windowResize(window.barChartTDP.update);
    },
    initcharts: function() {
      // `this` points to the vm instance
      /*@@@@@@@@@@@@@@@@@@@@@@@@@@ PIE CHART START @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**/
      var handleTIA = function(context) {
        "use strict";
        //PIE CHART 1

        const colors = [
          COLOR_BLUE,
          COLOR_PURPLE,
          COLOR_RED,
          COLOR_ORANGE,
          COLOR_GREEN,
          COLOR_AQUA,
          COLOR_GREY,
          COLOR_BLACK,
          COLOR_BLUE_DARKER,
          COLOR_BLUE_DARKER,
          COLOR_GREY_LIGHTER
        ];

        if (context) {
          const donutData = context
            .GETFINANCEBYREPORTIDS("TIA")
            .map((item, index) => {
              const pickcolor = Math.floor(Math.random() * 10);
              return {
                label: item.additional1,
                value: item._totals,
                color: colors[pickcolor]
              };
            });

          //GETBYREPORTID

          nv.addGraph(function() {
            var chart = nv.models
              .pieChart()
              .x(function(d) {
                return d.label;
              })
              .y(function(d) {
                return  d.value
              })
              .showLabels(true)
              .labelThreshold(0.05)
              .labelType("value")
              .donut(true)
              .donutRatio(0.35);

            d3.select("#nv-donut-chart")
              .append("svg")
              .datum(donutData)
              .transition()
              .duration(350)
              .call(chart);

            return chart;
          });

          const pieDataTPA = context
            .GETFINANCEBYREPORTIDS("TPA")
            .map((item, index) => {
              const pickcolor = Math.floor(Math.random() * 10);
              return {
                label: item.additional1,
                value: item._totals,
                color: colors[pickcolor]
              };
            });

          //GETBYREPORTID

          nv.addGraph(function() {
            var pieChartTPA = nv.models
              .pieChart()
              .x(function(d) {
                return d.label;
              })
              .y(function(d) {
                return d.value
              })
              .showLabels(true)
              .labelType("value")
              .labelThreshold(0.05);

            d3.select("#nv-pie-chartTPA")
              .append("svg")
              .datum(pieDataTPA)
              .transition()
              .duration(350)
              .call(pieChartTPA);

            return pieChartTPA;
          });

          const pieDataTAA = context
            .GETFINANCEBYREPORTIDS("TAA")
            .map((item, index) => {
              const pickcolor = Math.floor(Math.random() * 10);
              return {
                label: item.additional1,
                value: item._totals,
                color: colors[pickcolor]
              };
            });


            Morris.Donut({
            element: "morris-donut-chartTAA",
            data: [
              {label: 'SANTACO', value: 25 },
              {label: 'NTA', value: 40 },
            ],
            formatter: function (y) { return y + "%" },
            resize: true,
            gridLineColor: [COLOR_GREY_LIGHTER],
            gridTextFamily: FONT_FAMILY,
            gridTextColor: FONT_COLOR,
            gridTextWeight: FONT_WEIGHT,
            gridTextSize: FONT_SIZE,
            colors: [COLOR_AQUA, COLOR_BLUE]
          });


          // Morris.Donut({
          //   element: "morris-donut-chartTAA",
          //   data: pieDataTAA ,
          //   formatter: function(y) {
          //     return context.$options.filters.newcurrencywithSymbol(y)
          //   },
          //   resize: true,
          //   gridLineColor: [COLOR_GREY_LIGHTER],
          //   gridTextFamily: FONT_FAMILY,
          //   gridTextColor: FONT_COLOR,
          //   gridTextWeight: FONT_WEIGHT,
          //   gridTextSize: FONT_SIZE,
          //   colors: [COLOR_BLACK, COLOR_AQUA, COLOR_BLUE, COLOR_GREY]
          // });
        }
      };

      var handleTOT = function(context) {
        "use strict";

        const colors = [COLOR_RED, COLOR_AQUA];

        const donutChartData = context
          .GETBYREPORTIDS("TOT")
          .map((item, index) => {
            return {
              label: item.additional2,
              value: item._totals,
              color: colors[index]
            };
          });

        const colorspieChartData = [COLOR_ORANGE, COLOR_GREY];

        const pieChartData = context
          .GETBYREPORTIDS("TVT")
          .map((item, index) => {
            return {
              label: item.additional2,
              value: item._totals,
              color: colorspieChartData[index]
            };
          });

        // var donutChartData = [
        // { 'label': 'NTA', 'value' : 300, 'color': COLOR_RED },
        // { 'label': 'SANTACO', 'value' : 258, 'color': COLOR_AQUA }
        // ];

        // var pieChartData = [
        //   { label: "NTA", value: 300, color: COLOR_ORANGE },
        //   { label: "SANTACO", value: 258, color: COLOR_GREY }
        // ];
        nv.addGraph(function() {
          var pieChart = nv.models
            .pieChart()
            .x(function(d) {
              return d.label;
            })
            .y(function(d) {
              return d.value;
            })
            .showLabels(true)
            .labelType("percent")
            .labelThreshold(0.05);

          d3.select("#nv-pie-chart")
            .append("svg")
            .datum(pieChartData)
            .transition()
            .duration(350)
            .call(pieChart);

          return pieChart;
        });
        nv.addGraph(function() {
          var chart = nv.models
            .pieChart()
            .x(function(d) {
              return d.label;
            })
            .y(function(d) {
              return d.value;
            })
            .showLabels(true)
            .labelThreshold(0.05)
            .labelType("value")
            .donut(true)
            .donutRatio(0.35);

          d3.select("#nv-donut-chart")
            .append("svg")
            .datum(donutChartData)
            .transition()
            .duration(350)
            .call(chart);

          return chart;
        });
      };

      /*=============PIE CHART 3 Taxi Vehicle by TaxiAffiliation=====================*/
      var handleTVT = function(context) {
        const pieTVT = context.GETBYREPORTIDS("TVT").map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals
          };
        });

        Morris.Donut({
          element: "morris-donut-chart",
          data: pieTVT,
          formatter: function(y) {
            return y + "%";
          },
          resize: true,
          gridLineColor: [COLOR_GREY_LIGHTER],
          gridTextFamily: FONT_FAMILY,
          gridTextColor: FONT_COLOR,
          gridTextWeight: FONT_WEIGHT,
          gridTextSize: FONT_SIZE,
          colors: [COLOR_AQUA, COLOR_BLUE]
        });
      };


       /*=============PIE CHART 3 Taxi Vehicle by TaxiAffiliation=====================*/
      var handleTVT = function(context) {
        const pieTVT = context.GETBYREPORTIDS("TVT").map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals
          };
        });

        Morris.Donut({
          element: "morris-donut-chart",
          data: pieTVT,
          formatter: function(y) {
            return y + "%";
          },
          resize: true,
          gridLineColor: [COLOR_GREY_LIGHTER],
          gridTextFamily: FONT_FAMILY,
          gridTextColor: FONT_COLOR,
          gridTextWeight: FONT_WEIGHT,
          gridTextSize: FONT_SIZE,
          colors: [COLOR_AQUA, COLOR_BLUE]
        });
      };

      /*=============PIE CHART 3 Taxi Vehicle by TaxiAffiliation=====================*/
      var handleMorrisDonusChart = function(context) {
        const donutData = context.GETBYREPORTIDS("TOT").map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals
          };
        });

        Morris.Donut({
          element: "morris-donut-chart",
          data: donutData,
          formatter: function(y) {
            return y + "%";
          },
          resize: true,
          gridLineColor: [COLOR_GREY_LIGHTER],
          gridTextFamily: FONT_FAMILY,
          gridTextColor: FONT_COLOR,
          gridTextWeight: FONT_WEIGHT,
          gridTextSize: FONT_SIZE,
          colors: [COLOR_AQUA, COLOR_BLUE]
        });
      };

      var handlePerProvince = function(context) {
        "use strict";
        // Bar graph 1 Taxi Association By RegionF

        const colors = [
          COLOR_RED,
          COLOR_ORANGE,
          COLOR_GREEN,
          COLOR_AQUA,
          COLOR_BLUE,
          COLOR_PURPLE,
          COLOR_GREY,
          COLOR_BLACK,
          COLOR_BLACK
        ];

        /* Taxi Association By Province */

        const barTAP = context.GETBYREPORTIDS("TAP").map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals,
            color: colors[index]
          };
        });

        var barChartData = [
          {
            key: "Cumulative Return",
            values: barTAP
          }
        ];

        nv.addGraph(function() {
          window.barChart = nv.models
            .discreteBarChart()
            .x(function(d) {
              return d.label;
            })
            .y(function(d) {
              return d.value;
            })
            .showValues(false)
            .duration(250);

          barChart.yAxis.axisLabel("Taxi Associations");
          barChart.xAxis.axisLabel("Provinces");

          window.chartData = d3
            .select("#nv-bar-chart")
            .append("svg")
            .datum(barChartData)
            .call(window.barChart);
          nv.utils.windowResize(window.barChart.update);

          return barChart;
        });

        /* Taxi Operators By Province */

        const barTOP = context.GETBYREPORTIDS("TOP").map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals,
            color: colors[index]
          };
        });

        var barChartDataTOP = [
          {
            key: "Cumulative Return",
            values: barTOP
          }
        ];

        nv.addGraph(function() {
          window.barChartTOP = nv.models
            .discreteBarChart()
            .x(function(d) {
              return d.label;
            })
            .y(function(d) {
              return d.value;
            })
            .showValues(false)
            .duration(250);

          barChart.yAxis.axisLabel("Taxi Operators");
          barChart.xAxis.axisLabel("Provinces");

          window.chartDataTOP = d3
            .select("#nv-bar-chartoperators")
            .append("svg")
            .datum(barChartDataTOP)
            .call(window.barChartTOP);
          nv.utils.windowResize(window.barChartTOP.update);

          return barChartTOP;
        });

        /* Taxi Vehicles By Province */

        const barTVP = context.GETBYREPORTIDS("TVP").map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals,
            color: colors[index]
          };
        });

        var barChartDatabarTVP = [
          {
            key: "Cumulative Return",
            values: barTVP
          }
        ];

        nv.addGraph(function() {
          window.barChartTVP = nv.models
            .discreteBarChart()
            .x(function(d) {
              return d.label;
            })
            .y(function(d) {
              return d.value;
            })
            .showValues(false)
            .duration(250);

          barChart.yAxis.axisLabel("Taxis");
          barChart.xAxis.axisLabel("Provinces");

          window.chartDataTVP = d3
            .select("#nv-bar-charttaxis")
            .append("svg")
            .datum(barChartDatabarTVP)
            .call(window.barChartTVP);
          nv.utils.windowResize(window.barChartTVP.update);

          return barChartTVP;
        });

        /* Taxi Drivers By Province */

        const barTDP = context.GETBYREPORTIDS("TDP").map((item, index) => {
          return {
            label: item.additional2,
            value: item._totals,
            color: colors[index]
          };
        });

        var barChartDatabarTVP = [
          {
            key: "Cumulative Return",
            values: barTDP
          }
        ];

        nv.addGraph(function() {
          window.barChartTDP = nv.models
            .discreteBarChart()
            .x(function(d) {
              return d.label;
            })
            .y(function(d) {
              return d.value;
            })
            .showValues(false)
            .duration(250);

          barChart.yAxis.axisLabel("Drivers");
          barChart.xAxis.axisLabel("Provinces");

          window.chartDataTDP = d3
            .select("#nv-bar-chartdrivers")
            .append("svg")
            .datum(barChartDatabarTVP)
            .call(window.barChartTDP);
          nv.utils.windowResize(window.barChartTDP.update);

          return barChartTDP;
        });
      };

      /*@@@@@@@@@@@@@@@@@@@@@@@@@@ BAR CHART END @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**/
      COLOR_GREEN = "#5AC8FA";

      setTimeout(function() {
        //DashboardV2.init();

        const context = $(document).data("context");

        handleTIA(context);
        //handleTVT(context);
        //handlePerProvince(context);
        //handleTOT(context);
      }, 1000);
    }
  },
  mounted: function() {
    $(document).data("context", this.context);

    this.getfinancedashboard({
      router: this.$router
    }).then(() => {
      App.init();
      this.initcharts();
    });

    document.body.insertBefore(this.$el, document.body.firstChild);
  }
};
</script>

<style>
@import "/assets/plugins/nvd3/build/nv.d3.css";
</style>