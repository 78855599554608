/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';


export default {
  namespaced: true,
  state: {
    data: [],
    print: [],
    invoiceresults: [],
    disputes: [],
    invoicedisputes: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETALL: state => state.data,
    GETDETAILS: state => state.print,
    GETINVOICERESULTS: state => state.invoiceresults,
  },
  mutations: {
    LOADED: (state, _data) => {
      if (_data !== undefined) {
        state.data = _data;
      }
    },
    LOADDETAILS: (state, _data) => {
      if (_data !== undefined) {
        state.print = _data;
      }
    },
    LOADINVOICERESULTS: (state, _data) => {
      if (_data !== undefined) {
        state.invoiceresults = _data;
      }
    },
    LOADINVOICEDISPUTES: (state, _data) => {
      if (_data !== undefined) {
        state.invoicedisputes = _data;
      }
    },
    ADDED: (state, _data) => {
      state.data.push(_data);
    },
    
    DISPUTEADDED: (state, _data) => {
      state.disputes.push(_data);
    },
    UPDATED: (state, _data) => {
      if (_data !== undefined) {
        Object.assign(state.data.filter(item => item._id === _data._id)[0], _data);
      }
    },
    RESTOREDELETED: (state, {
      id
    }) => {
      if (id !== undefined) {
        state.data.filter(item => item._id === id)[0]._deleted_by = undefined;
        state.data.filter(item => item._id === id)[0]._date_deleted = undefined;
      }
    },
    MARKASDELETE: (state, {
      id,
      deletedby
    }) => {
      if (id !== undefined) {
        state.data.filter(item => item._id === id)[0]._deleted_by = deletedby;
        state.data.filter(item => item._id === id)[0]._date_deleted = Date.now;
      }
    },
    DELETE: (state, id) => {
      if (id !== undefined) {
        state.data = state.data.filter(item => item._id !== id);
        state.deleted = true;
      }
    },
  },
  actions: {
    /* Async */

    /* Auth.js Mutations */
    getall({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          router
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TITLE_URL,
        };

        this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
          if (parsedResult.status) {
            commit('LOADED', parsedResult.data);
            resolve();
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
    getById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => {
     
      

      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.INVOICES_URL,
      };

      this._vm.$TAMSFIN_API.getById(requestheader,
        id
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          commit('LOADDETAILS', parsedResultOP.data[0]);
          resolve(parsedResultOP.data[0]);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        reject(error);
        commit('LOGINFAILED', error.data);
      });
    });
    },

    getInvoiceDisputesById({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => {
     
      

      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.GETDRIVERINVOICEDISPUTES_URL,
      };

      this._vm.$TAMSFIN_API.getById(requestheader,
        id
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          commit('LOADINVOICEDISPUTES', parsedResultOP.data[0]);
          resolve(parsedResultOP.data[0]);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        reject(error);
        commit('LOGINFAILED', error.data);
      });
    });
    },

    
    getAllByTaxiAssID({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {

      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.BILLINGITEMS_URL,
      };

      this._vm.$TAMSFIN_API.getByTaxiAssId(requestheader,
        id
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          commit('LOADED', parsedResultOP.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    },

    

    createReceiptDisputeRequest({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      _addModel
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.RECEIPTDISPUTES_URL,
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          _addModel).then((parsedResult) => {
          if (parsedResult.status) {

            commit('DISPUTEADDED', parsedResult.data);
            resolve(parsedResult.data);
          
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    createInvoiceDisputeRequest({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      _addModel
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.INVOICEDISPUTES_URL,
        };

        this._vm.$TAMSFIN_API.add(requestheader,
          _addModel).then((parsedResult) => {
          if (parsedResult.status) {

            commit('DISPUTEADDED', parsedResult.data);
            resolve(parsedResult.data);
          
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    downloadMedia({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      invoicedisputesid,
      fileid,
      router
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.INVOICEDISPUTESMEDIADOWNLOAD_URL,
        };

        this._vm.$TAMSFIN_API.getByPaging2(requestheader,
          {skip: invoicedisputesid, len: fileid }).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('DISPUTEADDED', parsedResult.data);
            resolve(parsedResult.data);
          
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

    
    createInvoice({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      payload,
      billitems_array,
      _Operator,
      _Taxis,
      _Drivers,
      PrePayment
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.INVOICESCUSTOM_URL,
        };


        const AddModel = {
          taxi_association_id: payload.taxi_association_id,
          taxi_driver_id: _Drivers,
          taxi_operator_id: _Operator,
          taxi_operator_vehicles_id: _Taxis,
          billing_items_id: billitems_array,
          invoice_date: payload.invoice_date,
          amount: payload.linetotal,
          comment: payload.comment,
          reference: payload.reference,
          added_by: payload.added_by,
          captured_by: payload.captured_by,
          invoicedetails: payload.invoicedetails
        };


        this._vm.$TAMSFIN_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {

            commit('LOADINVOICERESULTS', parsedResult.data);
            resolve(parsedResult);

            if (PrePayment) {
                const PrePaymentObject = { 
                  Operator: AddModel.taxi_operator_id,
                  Taxis: AddModel.taxi_operator_vehicles_id,
                  Drivers: AddModel.taxi_driver_id,
                  TotalAmount: payload.invoicedetails.totalamount,
                  TransactionFee: payload.invoicedetails.transactionfee,
                  SubTotal: payload.invoicedetails.totalinvoiceamount,
                  PaymentDate: payload.invoice_date,
                  Reference: payload.comment
                  }

              dispatch('payments/prepayment', {
                router,
                PrePayment: PrePaymentObject
              }, {
                root: true
              }).then(() => {

              });
            }

           

            
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    delete({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TITLE_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id, {
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('DELETE', id);
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    markAsdelete({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TITLE_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id, {
            id,
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('MARKASDELETE', {
              id,
              deletedby
            });
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    restoreDeleted({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      _dataObj
    }) {
      return new Promise((resolve, reject) => {
        const {
          restoredby,
          id,
          router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TITLE_URL,
        };

        this._vm.$TAMS_API.restoreDeleted(requestheader, {
          id,
          restoredby,
        }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('RESTOREDELETED', {
              id
            });
            resolve(id);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

  },

};