<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Money'"
      :ActiveMenu="'collectionreport'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Collector Previous Week Report Details"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Collection Report"
        breadcrumbitem3="Collector Report Previous Week Details"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->
      <!-- begin row -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">Collector's report - {{GETWEEKLYARREARSBYCOLLECTOR.length > 0 ? GETWEEKLYARREARSBYCOLLECTOR[0].weekname : ''}}</h4>
            </div>
            <!-- end panel-heading -->

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-combine-collectorweekly" class="table table-bordered">
                    <thead>
                      <tr>
                        <th width="1%" data-orderable="false"></th>
                        <th class="text-nowrap">Vehicle License Number</th>
                        <th class="text-nowrap">Weekly Expected</th>
                        <th class="text-nowrap">Weekly Collected</th>
                        <th class="text-nowrap">Weekly Arrears</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item,index) in GETWEEKLYARREARSBYCOLLECTOR"
                        v-bind:key="item._id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                         <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                        <td>{{item.vehicle_license_number}}</td>
                        <td>{{item.week_expected | newcurrencywithSymbol}}</td>
                        <td >{{item.week_collected | newcurrencywithSymbol}}</td>
                        <td class="alert alert-danger fade show" v-if="item.week_arrears > 0">{{item.week_arrears | newcurrencywithSymbol}}</td>
                        <td class="alert alert-success fade show" v-if="item.week_arrears <= 0">{{item.week_arrears | newcurrencywithSymbol}}</td>
                        
                      </tr>
                    </tbody>
                  </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("payments");

export default {
  name: "PreviousWeeklyReports",
  data: function () {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      skip: 0,
      length: 1000000,
      GETOPERATORSBYASSID: [],
      ID: this.$route.params.id,
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      // accountid: this.$store.getters["TaxiDrivers/GETDRIVERACCOUNT"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      dataLoaded: false,
      arrearsoperatorsloaded: false,
      GETWEEKLYARREARSBYCOLLECTOR:[]
    };
  },
  beforeMount: function () {
    // this.FILTERBYROLE(this.context);
    window.PushManager.listenToIncomingMessages(this.context);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters({}),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions([
      "getall",
      "getPreviousWeeklyArrearsByCollectorId",
    ]),
    // FILTERBYROLE: (context) => {
    //   $(".byrole").each(function () {
    //     if (
    //       context.USERROLES.length > 0 &&
    //       context.USERROLES.filter(
    //         (item) => item._code === $(this).attr("data-role")
    //       ).length > 0
    //     )
    //       $(this).removeClass("hide");
    //   });
    // },

    // getCollectorWeeklyArrears: (context, id) => {
    //   context
    //     .getPreviousWeeklyArrearsByCollectorId({
    //       router: context.$router,
    //       id: id,
    //     })
    //     .then((data) => {
    //       console.log("operators found: ", data);
    //       context.GETOPERATORSBYASSID = data;
    //       context.dataLoaded = true;
    //     })
    //     .catch((error) => {
    //       console.log("operator errors:", error);
    //     })
    //     .finally(() => {
    //       $("#data-table-default").DataTable({
    //         responsive: true,
    //       });
    //     });
    // },
  },
  beforeDestroy: function () {},
  mounted: function () {
    
    App.init();

    $(".byrole").addClass("hide");

    const context = this;

    context
        .getPreviousWeeklyArrearsByCollectorId({
          router: context.$router,
          id: context.ID,
        })
        .then((parsedResultOP) => {
          
          if(parsedResultOP.status){
            if(parsedResultOP.data.length > 0){
                  context.GETWEEKLYARREARSBYCOLLECTOR = parsedResultOP.data
              } else {
              }

          }
        })
        .catch((error) => {
          console.log("operator errors:", error);
        }).finally(() => {
           var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: true,
                    autoFill: true,
                    colReorder: true,
                    keys: true,
                    rowReorder: true,
                    select: true
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }

                  if(!context.arrearsoperatorsloaded)
                    $("#data-table-combine-collectorweekly").DataTable(options);

                  context.arrearsoperatorsloaded = true;
        });

    // TableManageCombine.init();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

