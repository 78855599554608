/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../../../configurations/configbase';


export default {
  namespaced: true,
  state: {
    dashboard_cards: [],
    dashboardfinancestats: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETDASHBOARDCARDS: state => state.dashboard_cards,
    GETDASHBOARDFINSTATS: state => state.dashboardfinancestats,
  },
  mutations: {
    LOADEDDASHBOARDFINSTATS: (state, _data) => {
      if (_data !== undefined) {
        state.dashboardfinancestats = _data;
      }
    },
    LOADEDDASHBOARDCARDS: (state, _data) => {
        if (_data !== undefined) {
          state.dashboard_cards = _data;
        }
      },
      LOADEDFINANCE: (state, _data) => {
        if (_data !== undefined) {
          state.dashboardfinance = _data;
        }
      },
  },
  actions: {
    /* Async */

    getdashboardcards({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.REGIONALCHAIRMANDASHBOARDCARDS_URL,
      };
  
      this._vm.$TAMS_API.getCustomURL(requestheader,
        payload
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          //commit('LOADEDDASHBOARDCARDS', parsedResultOP.data[0]);
          resolve(parsedResultOP.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    });
    },

    getoperatorfinstats({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.REGIONALCHAIRMANDASHBOARDCARDSTATS_URL,
      };
  
      this._vm.$TAMS_API.getCustomURL(requestheader,
        payload
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          //commit('LOADEDDASHBOARDFINSTATS', parsedResultOP.data[0]);
          resolve(parsedResultOP.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        reject(error);
      });
    });
    },

    getoperatorfinstatstop5({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      return new Promise((resolve, reject) => {
      const requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.REGIONALCHAIRMANDASHBOARDCARDTOP5STATS_URL,
      };
  
      this._vm.$TAMS_API.getCustomURL(requestheader,
        payload
      ).then((parsedResultOP) => {
        console.log(parsedResultOP);
        if (parsedResultOP.status) {
          
          //commit('LOADEDDASHBOARDFINSTATS', parsedResultOP.data[0]);
          resolve(parsedResultOP.data[0]);
        }
      }).catch((error) => {
        console.log(error);
        commit('LOGINFAILED', error.data);
      });
    });
    },


   
}
};
