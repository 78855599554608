<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageVehicle'"
      :ActiveMenu="'dataCollection'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Field Data Collection"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Vehicles"
        breadcrumbitem3="Data Collection"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin panel-body -->
      <div class="panel-body">
        <table id="data-table-default" class="table table-striped table-bordered">
          <thead>
            <tr>
              <th width="1%" data-orderable="false"></th>
              <th class="text-nowrap">License Number</th>
              <th class="text-nowrap">Vehicle Added</th>
              <th class="text-nowrap">Added By</th>
              <th class="text-nowrap">Date Added</th>
              <th class="text-nowrap">Action</th>
              <th class="text-nowrap">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item,index) in GETDATACOLLECTED"
              v-bind:key=" 'data Key: ' + item.datacollection_id"
              :class="index & 1 ? 'odd gradeA' : 'even'"
            >
              <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
              <td>{{item.licenseno}}</td>
              <td v-show="item.vehicleid">True</td>
              <td v-show="!item.vehicleid">False</td>
              <td>{{item.added_by_name}}</td>
              <td>{{item.date_added}}</td>
              <td>
                <button
                            class="btn btn-sm btn-success width-120 m-r-2"
                              @click="CaptureVehicle(context,item)"
                              :disabled="$wait.is('CaptureVehicle'-item.id)"
                              v-bind:class="{ 'disabled': item.vehicleid }"
                            >Add Vehicle</button>
                
                <!-- <router-link
                  v-if="item.operatorcarddisk_image"
                  :to="{ name: 'addTaxiOperators', params: { id: item.datacollection_id }}"
                  class="btn btn-sm btn-primary width-80 m-r-2"
                  :disabled="item.isoperator_captured"
                  v-bind:class="{ 'disabled': item.isoperator_captured }"
                >Operator</router-link>
                <router-link
                  v-if="item.driveridcard_front_image"
                  :to="{ name: 'addtaxidriver', params: { id: item.datacollection_id }}"
                  class="btn btn-sm btn-success width-80 m-r-2"
                  :disabled="item.isdriver_captured"
                  v-bind:class="{ 'disabled': item.isdriver_captured }"
                >Driver</router-link> 
              
              <router-link
                  :to="{ name: 'addTaxiVehicle', params: { id: item.datacollection_id }}"
                  class="btn btn-sm btn-warning width-80 m-r-2"
                  :disabled="item.vehicleid"
                  v-bind:class="{ 'disabled': item.istaxi_captured }"
                >Vehicle</router-link>-->
               
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-sm btn-danger width-80"
                  v-if="!item.deleted_by"
                  @click="deleteRecord(context,item.datacollection_id,USERPROFILE.system_usersid)"
                  :disabled="$wait.is('delete-' + item.datacollection_id)"
                >
                  <v-wait v-bind:for="'delete-' + item.datacollection_id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Delete
                  </v-wait>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- end panel-body -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import { default as DropZoneControl, default as DropZoneControl2 } from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("OperatorVehicle");

export default {
  name: "datacollection",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      GETDATACOLLECTED :[],
      id: this.$route.params.id,
    };
  },

  beforeMount: function() {},
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    DropZoneControl2,
    CapturePhotosControl
  },

  computed: {
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },

  methods: {
    ...mapActions(["getDatacollectedByAssId,deleteDatacollectionById,SaveDatacollected"]),
    CaptureVehicle: function(context,item) {

      context.$store
      .dispatch("OperatorVehicle/SaveDatacollected",{
          router: context.$router,
          payload: item
        })
        .then(() => {
          context.$router.push({ name: 'addTaxiVehicle', params: { id: item.id } })
        })
        .catch(error => {
        })
        .finally(() => {
        });      

},
    getbyid: context => {
      
      context.$store
        .dispatch("OperatorVehicle/getDatacollectedByAssId", {
          router: context.$router,
          id: context.taxiassociationid
        })
        .then(data => {
          context.GETDATACOLLECTED = data
          console.log("data collected: ", data);
        })
        .catch(error => {
          console.log("data collected errors: ", error);
        })
        .finally(() => {
           $('#data-table-default').DataTable({
			      responsive: true
             });
        });       
    },
    deleteRecord: (context, id, deletedby) => {
      context.$Notify.YesNo(false, { context, id, deletedby }).then(value => {
        if (value) {
          value.context.$wait.is("delete-" + value.id);
          
          context.$store
            .dispatch("OperatorVehicle/deleteDatacollectionById", {
              router: value.context.$router,
              id: value.id,
              deleted_by: value.deletedby
            })
            .then(() => {
              context.getbyid(context);
              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Success("Successfully Deleted!", "");
            })
            .catch(error => {
              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    }
  },

  mounted: function() {
    this.getbyid(this.context);

    
    
    App.init();
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
