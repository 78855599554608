<template>
  <li v-show="ISINTERNALSYSTEMUSER" class="no-print">
    <form class="navbar-form" v-if="!$wait.is('TaxiOperatorDropDownControlLoading')">
      <div class="form-group">
        <select
          id="TaxiOperatorDropDownControl"
          class="default-select2 form-control"
          :disabled="!isAdminUser"
          v-model="selectedDefault"
          @change="selecttaxiassociation"
          data-live-search="true"
        >
          <option value="0" disabled selected>Select Taxi Association</option>
          <optgroup label="SANTACO">
            <option
              v-for="item in TAXIASSOCIATIONSBYAFFILLIATIONIID(2/*SANTACO*/)"
              :selected="TA.id == item._taxi_associations_id"
              v-bind:key="'SAN-'+item._taxi_associations_id"
              :value="item._taxi_associations_id"
            >{{item._abbriviated_name}}</option>
          </optgroup>
          <optgroup label="NTA">
            <option
              v-for="item in TAXIASSOCIATIONSBYAFFILLIATIONIID(1/*NTA*/)"
              :selected="TA.id == item._taxi_associations_id"
              v-bind:key="'NTA-'+item._taxi_associations_id"
              :value="item._taxi_associations_id"
            >{{item._abbriviated_name}}</option>
          </optgroup>
        </select>
      </div>
    </form>
  </li>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TA");

export default {
  name: "TaxiOperatorDropDownControl",
  props: {
    isAdminUser:Boolean,
    
  },
  data: function() {
    return {
      message: "Some Message",
      TA: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"],
      selectedDefault:
        this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id | 0,
      prevselectedDefault: 0,
      ISINTERNALSYSTEMUSER:false,
    };
  },
  components: {},
  computed: {
    ...mapGetters(["TAXIASSOCIATIONSBYAFFILLIATIONIID"]),
    
  },
  methods: {
    ...mapActions(["getall", "selected"]),
    
    getalltaxiassociations() {
      //this.$wait.start('LoggingIn');
      //this.$router.push('sysadmin_dashboard')
      // name: "TaxiOperatorDropDownControlLoading"
      this.$wait.start("TaxiOperatorDropDownControlLoading");
      this.$store
        .dispatch("TA/getAll", {
          _waitObj: {
            router: this.$router
          }
        })
        .finally(() => {
          //$(".default-select2").select2();
          this.$wait.end("TaxiOperatorDropDownControlLoading");

          // $(".default-select2").select2({
          // multiple: false,
          // placeholder: "Select Vehicles",
          // allowClear: false,
          // });

        });
    },
    selecttaxiassociation(event) {
      const context = this;
      const _data = this.$store.getters["TA/TAXIASSOCIATIONS"].filter(
        t => t._taxi_associations_id == event.target.value
      )[0];

      this.$Notify
        .Confirm(
          "Are you sure?",
          `You have selected: (${_data._abbriviated_name})${_data._name}`,
          {
            status: false,
            context
          },
          {
            status: true,
            context,
            event
          }
        )
        .then(value => {
          if (value.status) {

            
          
             value.context.$store.dispatch('TA/getByBillingId',
                  {
                    router: this.$router,
                    id: value.event.target.value
                  },{ root: true }).then((billingDetails) => {

                
                    if(!billingDetails.status)
                    {
                      context.$Notify.BlockScreen(billingDetails.message, "Please call administrator");
                    }
                    else
                    {
                      if(billingDetails.message)
                      context.$Notify.BlockScreen(billingDetails.message, "");

                             value.context.$store.dispatch("TA/selected", {
              router: this.$router,
              _id: value.event.target.value
            });
       
                      value.context.$emit("init");
                      value.context.prevselectedDefault = value.event.target.value;
                      window.location.reload(false); 
                    }
                  });

            
          } else {
            value.context.selectedDefault = value.context.prevselectedDefault;
          }
        });

      /* this.$store.dispatch("select_taxiassociation", {
       
      }); */
    }
  },
  mounted: function() {
    //$(".default-select2").select2();
    this.getalltaxiassociations();
    
    this.ISINTERNALSYSTEMUSER = this.$store.getters["auth/USERPROFILE"].usergroups_code ==='ISA' /* Internal Admin User */
    
    //document.removeClass('pace-done').addClass('pace-top bg-white');
  }
};
</script>

<style scoped>
</style>


