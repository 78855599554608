<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'BookASeat'"
      :ActiveMenu="'PublicHolidays'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Block Public Holidays"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="BookASeat"
        breadcrumbitem3="Public Holidayss"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

     <!-- begin row -->
     <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-12">

                <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">
                Public Holidays
                <div v-show="ISLOADING" class="spinner-border spinner-border-lg text-info"  role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
                </h4>
              </div>
              <div class="panel-body">

                
               <table  class="table tblholiday" id="tblholiday">
                <thead>
                  <tr>
                    <th></th>
                    <th>NAME</th>
                    <th>TYPE</th>
                    <th>STATUS</th>
                    <th>DATE</th>
                    <th>WEEKDAY</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in HOLIDAYS" :key="item.id" :value="item"
                  :class="item.holidays_taxiassid !== null ? 'badge-success' : 'badge-normal'">
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.type}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.is_active, 'label-secondary':!item.is_active  }"
                      >{{item.is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td>{{item.holidaydate}}</td>
                    <td>{{item.week_day}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <div class="btn-group" role="group" aria-label="Basic example">
                      <button v-if="item.holidays_taxiassid" type="button" @click="RemovePublicHolidayAction(context,item)" class="btn btn-danger"
                        :disabled="$wait.is('RemovePublicHoliday-' +item.id)"
                >
                 <v-wait v-bind:for="'RemovePublicHoliday-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Remove
                  </v-wait>
                        
                    </button> 
                    <button v-if="!item.holidays_taxiassid" type="button" @click="AddHoliday(context,item)" class="btn btn-success"
                      :disabled="$wait.is('AddPublicHoliday-' +item.id)"
                    >
                 <v-wait v-bind:for="'AddPublicHoliday-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                   Add
                  </v-wait>
                        
                    </button> 
                  </div>
                      
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
            <!-- end panel -->
          </div>
         
        </div>
        <!-- end row -->

      <!-- begin #Main Body Template -->


      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("tpay_systemuser");

export default {
  name: "FullSystemSearch",
  data: function () {
    return {
      HOLIDAYS: [],
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      searchText: this.$router.app._route.query.text,
      dataloaded: false,
      NEXTOFKINMODEL:null,
      NEXTOFKIN:{
        FirstName:"",
        LastName:"",
        Mobile:"",
      },
      TPAYPROFILE: this.$store.getters["auth/TPAYPROFILE"],
      ISLOADING: false,
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters({}),
  },
  beforeMount() {},
  methods: {
    ...mapActions([
      "GetHolidays",
      "AddPublicHoliday",
      "RemovePublicHoliday"
    ]),
    getall: (context) => {

          

          const router = context.$router;

          var payload = {
            taxiassociations_id: context.taxiassociationid,
          };

          context.ISLOADING = true;

          context.GetHolidays(payload)
          .then(parsedResultOP => {
            //const context = $(document).data("context");

            context.ISLOADING = false;

            context.HOLIDAYS = parsedResultOP.Data;

            //handleOperatorByGender();
          })
          .catch(error => {
            //context.$wait.end("generatestatement");
          }).finally(() => {
            var options = {
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false,
                    order: [[0, 'asc']]
                  };

            //       // if ($(window).width() <= 767) {
            //       //   options.rowReorder = false;
            //       //   options.colReorder = false;
            //       //   options.autoFill = false;
            //       // }

 	                  $("#tblholiday").DataTable(options);
          });
  },
  AddHoliday: (context,item) => {

      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          
          value.context.$wait.start('AddPublicHoliday-'+ value.item.id)

          var payload = {
              taxi_association_id: value.context.taxiassociationid,
              bookingseats_holidays_id: item.id,
              addedby: value.context.TPAYPROFILE.SystemUsersId
            };

            

          value.context.AddPublicHoliday(payload).then((rb) => {
            value.item.holidays_taxiassid = value.item.id
            value.context.$wait.end('AddPublicHoliday-'+ value.item.id)
            if(rb.Status){
              value.context.$Notify.Success("Successfull","");
            } else {
              value.context.$Notify.Error("Data Not Found", "");
            }
          
          })
        }
      });
  },
  RemovePublicHolidayAction: (context,item) => {

      context.$Notify.YesNo(false, { context, item }).then(value => {
  if (value) {
    
    value.context.$wait.start('RemovePublicHoliday-'+ value.item.id)

    var payload = {
      taxi_holidays_id: item.holidays_taxiassid,
        addedby: value.context.TPAYPROFILE.SystemUsersId
    };

    value.context.RemovePublicHoliday(payload).then((rb) => {
      value.item.holidays_taxiassid = null
      value.context.$wait.end('RemovePublicHoliday-'+ value.item.id)
      if(rb.Status){
        value.context.$Notify.Success("Successfull","");
      } else {
        value.context.$Notify.Error("Data Not Found", "");
      }
    
    })
  }
});
},
  },
  mounted: function () {

    $(document).data("context",this);

    App.init();

    this.getall(this.context);

    

  },
  beforeDestroy: function () {},
};
</script>

<style scoped>
</style>
