/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';

export default {
  namespaced: true,
  state: {
    vehiclemake: [],
    vehiclemodel: []
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETALLMAKES: state => state.vehiclemake,
    GETALLMODEL: state => state.vehiclemodel,
    GETMODELBYMAKEID: (state,id) => {
      return state.vehiclemodel.filter(item => item.vehiclemake_id === id);
    },
  },
  mutations: {
    
    LOADMODELS: (state, _data) => {
     
      if (_data !== undefined) {
        state.vehiclemodel = _data;
      }
    },
    LOADMAKES: (state, _data) => {
      if (_data !== undefined) {
        state.vehiclemake = _data;
      }
    },
    ADDED: (state, _data) => {
      state.vehiclemake.push(_data);
    },
    UPDATED: (state, _data) => {
      if (_data !== undefined) {
        Object.assign(state.vehiclemake.filter(item => item._id === _data._id)[0], _data);
      }
    },
    RESTOREDELETED: (state, { id }) => {
      if (id !== undefined) {
        state.vehiclemake.filter(item => item._id === id)[0]._deleted_by = undefined;
        state.vehiclemake.filter(item => item._id === id)[0]._date_deleted = undefined;
      }
    },
    MARKASDELETE: (state, { id, deletedby }) => {
      if (id !== undefined) {
        state.vehiclemake.filter(item => item._id === id)[0]._deleted_by = deletedby;
        state.vehiclemake.filter(item => item._id === id)[0]._date_deleted = Date.now;
      }
    },
  },
  actions: {
    /* Async */

    /* Auth.js Mutations */
    getall({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router }) {
      return new Promise((resolve, reject) => {
     
  
        let requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEMODEL_URL,
        };

        this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
          if (parsedResult.status) {
            commit('auth/UPDATEPROGRESS', { PROGRESS: 5 , MESSAGE: 'Loading Billing' }, { root: true });
            commit('LOADMODELS', parsedResult.data);
            resolve();
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject();
        });

        requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEMAKE_URL,
        };

        this._vm.$TAMS_API.getAll(requestheader).then((parsedvehiclemakeResult) => {
      
          if (parsedvehiclemakeResult.status) {
            commit('LOADMAKES', parsedvehiclemakeResult.data);
            resolve();
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
  
        // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
    add({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { AddModel, router }) {
      return new Promise((resolve, reject) => {
       
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEMODEL_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            // commit('ADDED', item);
            console.log('added:',parsedResult.data);
            resolve(parsedResult.data);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
        console.log('add model errors:',error);
          reject(error);
        });
      });
    },
    update({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          item, router, updatedby,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEMODEL_URL,
        };

        const updateModel = {
          id: item._id,
          name: item._name,
          description: item._description,
          code: item._code,
          isactive: item._is_active,
          updatedby,
        };

        this._vm.$TAMS_API.update(requestheader,
          item._id,
          updateModel).then((parsedResult) => {
          if (parsedResult.status) {
            commit('UPDATED', item);
            resolve();
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    markAsdeleted({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          deletedby, id, router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEMODEL_URL,
        };

        this._vm.$TAMS_API.delete(requestheader,
          id,
          {
            id,
            deletedby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('MARKASDELETE', { id, deletedby });
            resolve(id);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },
    restoreDeleted({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { _dataObj }) {
      return new Promise((resolve, reject) => {
        const {
          restoredby, id, router,
        } = _dataObj;

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.VEHICLEMODEL_URL,
        };

        this._vm.$TAMS_API.restoreDeleted(requestheader,
          {
            id,
            restoredby,
          }).then((parsedResult) => {
          if (parsedResult.status) {
            commit('RESTOREDELETED', { id });
            resolve(id);
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject(error);
        });
      });
    },

  },

};
