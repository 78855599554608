<template>
  <div class="home; page-content-full-height page-sidebar-fixed page-header-fixed">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Emails'"
      :ActiveMenu="'Inbox'"
    />
    <!-- end #sidebar -->
    <!-- begin page-header -->
    <headerpagebreadcrumb
      pageheaderTitle="Inbox"
      pageheaderdescription
      breadcrumbitem1="Inbox"
      breadcrumbitem2="Email Details"
      breadcrumbitem3="Reply"
    />
    <!-- end page-header -->
    <hr class="bg-grey-lighter" />

    <!-- begin #content -->
    <div id="content" class="content content-full-width inbox">
      <!-- begin vertical-box -->
      <div class="vertical-box with-grid">
        <!-- begin vertical-box-column -->
        <div class="vertical-box-column width-200 bg-silver hidden-xs">
          <!-- begin vertical-box -->
          <div class="vertical-box">
            <!-- begin wrapper -->
            <div class="wrapper bg-silver text-center">
              <router-link to="/Home/Compose" class="btn btn-inverse p-l-40 p-r-40 btn-sm">Compose</router-link>
            </div>
            <!-- end wrapper -->
            <!-- begin vertical-box-row -->
            <div class="vertical-box-row">
              <!-- begin vertical-box-cell -->
              <div class="vertical-box-cell">
                <!-- begin vertical-box-inner-cell -->
                <div class="vertical-box-inner-cell">
                  <!-- begin scrollbar -->
                  <div data-scrollbar="true" data-height="100%">
                    <!-- begin wrapper -->
                    <div class="wrapper p-0">
                      <div class="nav-title">
                        <b>FOLDERS</b>
                      </div>
                      <ul class="nav nav-inbox">
                        <li class="active">
                          <router-link :to="{ name: 'inbox', params: {id: USERID } }">
                            <i class="fa fa-inbox fa-fw m-r-5"></i> Inbox
                            <span class="badge pull-right">{{GETTOTALUNREAD}}</span>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/sent">
                            <i class="fa fa-envelope fa-fw m-r-5"></i> Sent
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/trash">
                            <i class="fa fa-trash fa-fw m-r-5"></i> Trash
                          </router-link>
                        </li>
                      </ul>
                    </div>
                    <!-- end wrapper -->
                  </div>
                  <!-- end scrollbar -->
                </div>
                <!-- end vertical-box-inner-cell -->
              </div>
              <!-- end vertical-box-cell -->
            </div>
            <!-- end vertical-box-row -->
          </div>
          <!-- end vertical-box -->
        </div>
        <!-- end vertical-box-column -->
        <!-- begin vertical-box-column -->
        <div class="vertical-box-column bg-white">
          <!-- begin vertical-box -->
          <div class="vertical-box">
            <!-- begin wrapper -->
            <div class="wrapper bg-silver">
              <span class="btn-group m-r-5">
                <a href="javascript:;" class="btn btn-white btn-sm">
                  <i class="fa fa-envelope f-s-14 m-r-3 m-r-xs-0 t-plus-1"></i>
                  <span class="hidden-xs">Send</span>
                </a>
                <a href="#modal-message" class="btn btn-sm btn-primary" data-toggle="modal">
                  <i class="fa fa-paperclip f-s-14 m-r-3 m-r-xs-0 t-plus-1"></i>
                  <span class="hidden-xs">Attach</span>
                </a>
              </span>
              <span class="pull-right">
                <router-link to="/inbox" class="btn btn-white btn-sm">
                  <i class="fa fa-times f-s-14 m-r-3 m-r-xs-0 t-plus-1"></i>
                  <span class="hidden-xs">Discard</span>
                </router-link>
              </span>
            </div>
            <!-- end wrapper -->
            <!-- begin vertical-box-row -->
            <div class="vertical-box-row bg-white">
              <!-- begin vertical-box-cell -->
              <div class="vertical-box-cell">
                <!-- begin vertical-box-inner-cell -->
                <div class="vertical-box-inner-cell">
                  <!-- begin scrollbar -->
                  <div data-scrollbar="true" data-height="100%" class="p-15">
                    <!-- begin email form -->
                    <form action="/" method="POST" name="email_to_form">
                      <!-- begin email to -->
                      <div class="email-to">
                        <label class="control-label">To:</label>
                        <ul id="email-to" class="primary line-mode">{{sentByName}}</ul>
                      </div>
                      <!-- end email to -->
                      <br />
                      <!-- begin email subject -->
                      <div class="email-subject">
                        <input
                          v-model="REPLYMODEL.subject"
                          type="text"
                          class="form-control form-control-lg"
                          placeholder="Subject"
                        />
                      </div>
                      <!-- end email subject -->
                      <br />
                      <!-- begin row -->
                      <div class="row">
                        <!-- begin col-8 -->

                        <div class="col-md-12">
                          <div class="row panel panel-inverse">
                            <!-- begin col-8 -->
                            <div class="col-lg-6">
                              <!-- begin form-group -->
                              <!-- <div class="form-group row m-b-10">
                                <label class="col-md-0 col-form-label text-md-right">Select Drivers:</label>
                                <div class="date text-inverse col-md-8 pull-center">
                                  <select
                                    class="default-selectDriver default-select form-control col-md-7"
                                    data-size="5"
                                    data-live-search="true"
                                  >
                                    <option
                                      v-for="item in GETDRIVERSBYAFFILIATION"
                                      :key="item._taxi_drivers_id"
                                      :value="item._taxi_drivers_id"
                                    >{{item._firstname}} {{item._surname}} ({{item._id_number}})</option>
                                  </select>
                                </div>
                              </div> -->
                              <!-- end form-group -->
                            </div>
                            <!-- end col-8 -->

                            <div class="col-lg-6">
                              <!-- begin form-group -->
                              <!-- <div class="form-group row m-b-10">
                                <label
                                  class="col-md-0 col-form-label text-md-right"
                                >Select Operators:</label>
                                <div class="date text-inverse col-md-7 pull-up">
                                  <select
                                    class="default-selectOperator default-select form-control col-md-7"
                                    data-size="5"
                                    data-live-search="true"
                                  >
                                    <option
                                      v-for="item in GETOPERATORSBYAFFILLIATION"
                                      :key="item.taxi_operator_id"
                                      :value="item.taxi_operator_id"
                                    >{{item.firstname}} {{item.surname}} ({{item.id_number}})</option>
                                  </select>
                                </div>
                              </div> -->
                              <!-- end form-group -->
                            </div>
                            <div class="col-lg-6">
                              <!-- begin form-group -->
                              <!-- <div class="form-group row m-b-10">
                                <label
                                  class="col-md-0 col-form-label text-md-right"
                                >Select Vehicles:</label>
                                <div class="date text-inverse col-md-7 pull-up">
                                  <select
                                    class="default-selectTaxi default-select form-control col-md-7"
                                    data-size="5"
                                    data-live-search="true"
                                  >
                                    <optgroup
                                      v-for="item_models in GETAllTAXIMODELS"
                                      :key="item_models._vehiclemodel_id"
                                      :value="item_models._vehiclemodel_id"
                                      v-bind:label="item_models._vehiclemake_name + ' ' + item_models._name"
                                    >
                                      <option
                                        :key="item._taxi_operator_vehicles_id"
                                        v-for="item in GETTAXISBYMODELID(context, item_models._vehiclemodel_id)"
                                        :value="item._taxi_operator_vehicles_id"
                                      >{{item._licence_number}} ({{item._taxi_operators_name}})</option>
                                    </optgroup>
                                  </select>
                                </div>
                              </div> -->
                              <!-- end form-group -->
                            </div>
                            <!-- </div> -->
                          </div>
                        </div>
                        <!-- end col-8 -->
                      </div>
                      <!-- end row -->

                      <!-- begin email content -->
                      <div class="email-content p-t-15">
                        <textarea
                          class="textarea form-control"
                          placeholder="Enter text ..."
                          rows="20"
                          id="wysihtml5"
                          onfocus="this.innerHTML=''"
                        ></textarea>
                        <br />
                        <!-- <ul id="dropzonefiles" class="primary line-mode">
                          <li>google@gmail.com</li>
                        </ul> -->
                        <br />
                      </div>
                      <!-- end email content -->
                    </form>
                    <!-- end email form -->
                    <div id="events_container"></div>
                  </div>
                  <!-- end scrollbar -->
                </div>
                <!-- end vertical-box-inner-cell -->
              </div>
              <!-- end vertical-box-cell -->
            </div>
            <!-- end vertical-box-row -->
            <!-- begin wrapper -->
            <div class="wrapper bg-silver text-right">
              <button type="submit" class="btn btn-white p-l-40 p-r-40 m-r-5">Discard</button>
              <button
                @click="emailReply(context,REPLYMODEL)"
                class="btn btn-primary p-l-40 p-r-40"
              >Send</button>
            </div>
            <!-- end wrapper -->
          </div>
          <!-- end vertical-box -->
        </div>
        <!-- end vertical-box-column -->
      </div>
      <!-- end vertical-box -->
    </div>
    <!-- end #content -->
    <!-- #modal-message -->
    <div class="modal modal-message fade" id="modal-message">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="text-center">
            <h4 class="modal-title">Attach files</h4>
          </div>
          <div class="modal-body">
            <p>Upload Documents</p>
            <DropZoneControl
              :acceptedFiles="acceptedFiles"
              :ClearData="true"
              v-on:fileadded="fileadded"
              :SessionID="DropZoneSessionID"
            ></DropZoneControl>
            <br />
            <div class="modal-footer">
              <a href="javascript:;" class="btn btn-primary" data-dismiss="modal">Save Changes</a>
            </div>
          </div>
        </div>
      </div>
      <!-- begin scroll to top btn -->
      <pagescroller />
      <!-- end scroll to top btn -->
    </div>
  </div>
</template>


	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("messageinbox");
// value.context.$emit("init");

export default {
  name: "reply",
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl
  },
  data: function() {
    return {
      acceptedFiles: "application/pdf",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      GETAllTAXIMODELS: this.$store.getters["TV/GETALLMODEL"],
      GETALLDOCUMENTTYPE: this.$store.getters["lookups/GETALLDOCUMENTTYPE"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      context: this,
      skip: 0,
      len: 10000,
      id: this.$route.params.id,
      GETINBOXBYID: [],
      subjectReply: "RE: ",
      DropZoneSessionID: this.$uuid.v4(),
      taxi_affiliation_id: this.$store.getters["auth/USERPROFILE"]
        .taxi_affiliationid,
      taxiassociationid: this.$store.getters["auth/USERPROFILE"]
        .taxi_associations_id,
      // GETUPLOADEDFILES: this.$store.getters["fileupload/GETALL"],
      IsAllOperatorsSelected: false,
      IsDriversSelected: false,
      origionalMessage: "",
      userSignature: "",
      affiliation_id: this.$store.getters["auth/USERPROFILE"]
        .taxi_affiliationid,
      REPLYMODEL: {
        taxi_association_id: this.$store.getters["auth/USERPROFILE"]
          .taxi_associations_id,
        taxi_driver_id: 0,
        taxi_operator_id: 0,
        taxi_vehicle_id: 0,
        subject: "",
        messagecontent: "",
        sentby: this.$store.getters["auth/USERPROFILE"].system_usersid,
        receivedby: null,
        originalmessage_id: this.$route.params.id,
        dropzonesessionid: ""
      },
      recipientsArray: false,
      receiver: [],
      RECIPIENTS: [],
      sentByName: '',
      driver: {},
      taxi: {},
      operator: {},
    };
  },
  beforeMount: function() {
    
    
    // this.getallEmails(this.context);
  },
  computed: {
    ...mapGetters([
      "GETUPLOADEDFILES",
      "GETTOTALUNREAD",
      "GETDRIVERSBYAFFILIATION",
      "GETOPERATORSBYAFFILLIATION",
      "GETVEHICLEBYAFFILLIATION"
    ]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },

  watch: {},
  methods: {
    ...mapActions([
      "getall",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "searchSystemUsers",
      "getinboxById",
      "replyInboxMessage",
      "selected",
      "markAsRead"
    ]),

    signature: (context, pdetails) => {
      console.log("this is the user", pdetails);
      let sig = "";
      sig += `        
        <b>${pdetails.title_name} ${pdetails.first_name} ${
        pdetails.surname
      }</b> | ${pdetails.usergroups_desc} <br/>
         ${pdetails.taxi_affiliation_name} | ${
        pdetails.taxi_associations_name
      } <br/>
        m: <b>${
          pdetails.mobile_number != null ? pdetails.mobile_number : ""
        }</b>`;
      context.userSignature = sig;
      console.log("this is the signature", context.userSignature);
    },
    getbyid: (context, id, userid) => {
      context
        .getinboxById({
          _dataObj: {
            id,
            userid,
            router: context.$router
          }
        })
        .then(data => {
          console.log("this is the reply section: ", data);
          
          let ReplyMessage = "";
          ReplyMessage += "<br/><br/><br/><br/><br/>";
          ReplyMessage += context.userSignature;
          ReplyMessage += "<br/><br/>";
          ReplyMessage +=
            "--------------------------------------------------------------------";
          ReplyMessage += "<br/>";

          context.GETINBOXBYID = data;

          context.RECIPIENTS = data._recipients;

          context.sentByName = data._sentby_name;

          if (
            context.RECIPIENTS != null && context.RECIPIENTS != [] 
          ) {
            context.RECIPIENTS.map(item => {
              if (item != undefined) {
                ReplyMessage += ` <b> ${item.username}, </b> `;
                context.recipientsArray = true;
              }
            });
          } else {
            ReplyMessage += context.GETINBOXBYID._received_by_name;
            context.recipientsArray = false;
          }

          ReplyMessage += "<br/><br/>";
          ReplyMessage += context.GETINBOXBYID._message_content;

          context.REPLYMODEL.messagecontent = ReplyMessage;

          // $("#product_details").data("wysihtml5").editor.getValue();// to get the value
          $("#wysihtml5").data("wysihtml5").editor.setValue(context.REPLYMODEL.messagecontent)//

          context.getRecepients(context);
          context.getMessageDetails(context);

        });
    },

    
    getRecepients: context => {
      if (context.GETINBOXBYID._recipients != null) {
        context.receiver = context.GETINBOXBYID._recipients;
      } else {
        context.receiver = context.GETINBOXBYID._recipients;
      }
    },

    getMessageDetails: context => {
      context.REPLYMODEL.subject = context.GETINBOXBYID._subject;
      // context.origionalMessage = context.GETINBOXBYID._message_content;
    },

    
    

  GETTAXISBYMODELID: function(context, id) {
      return context.GETVEHICLEBYAFFILLIATION.filter(
        item => item._vehiclemodel_id === id
      );
    },

  fileadded: function(data) {
    if(data){
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')
    }
    },

    emailReply: (context, updateModel) => {
      
      if (
        $(".default-selectOperator").select2("data") != undefined &&
        $(".default-selectOperator").select2("data") != null &&
        $(".default-selectOperator").select2("data").length > 0
      ) {
        context.operator = $(".default-selectOperator").select2("data")[0];
      } else {
        context.operator = 0;
      }

      if (
        $(".default-selectDriver").select2("data") != undefined &&
        $(".default-selectDriver").select2("data") != null &&
        $(".default-selectDriver").select2("data").length > 0
      ) {
        context.driver = $(".default-selectDriver").select2("data")[0];
      } else {
        context.driver = 0;
      }

      if (
        $(".default-selectTaxi").select2("data") != undefined &&
        $(".default-selectTaxi").select2("data") != null &&
        $(".default-selectTaxi").select2("data").length > 0
      ) {
        context.taxi = $(".default-selectTaxi").select2("data")[0];
      } else {
        context.taxi = 0;
      }

      context.REPLYMODEL.receivedby = context.GETINBOXBYID._sent_by;
      context.REPLYMODEL.messagecontent = $("#wysihtml5").val();
      context.REPLYMODEL.taxi_operator_id = parseInt(context.operator.id);
      context.REPLYMODEL.taxi_driver_id = parseInt(context.driver.id);
      context.REPLYMODEL.taxi_vehicle_id = parseInt(context.taxi.id);
      updateModel.dropzonesessionid = context.DropZoneSessionID;

      context.$Notify.YesNo(false, { context, updateModel }).then(value => {
        
        value.updateModel.subject = context.REPLYMODEL.subject;
        if (value) {
          value.context.$wait.start("add-" + value.id);
          value.router = value.context.$router;
          value.context
            .replyInboxMessage({
              updateModel,
              router: context.$router
            })
            .then(id => {
              value.context.$wait.end("add-" + id);
              value.context.$Notify.Success("Sent Successfully!", "");
              console.log("reply: ", id);
              router.push("/Home/Inbox");
            })
            .catch(error => {
              console.log("reply errors: ", error);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    


    getDriversbyid: context => {
      context.$store
        .dispatch("TaxiDrivers/getDriversByAffiliation", {
          id: context.affiliation_id
        })
        .then(data => {
          console.log("these are the Drivers: ", data);
          $(".default-selectDriver")
            .select2({
              multiple: false,
              placeholder: "Select A Driver",
              allowClear: true
            })
            .on("select2:close", function(e) {
              $(".default-selectOperator")
                .val(null)
                .trigger("change");

              $(".default-selectTaxi")
                .val(null)
                .trigger("change");
            });
        })
        .catch(error => {
          console.log("these are driver errors: ", error);
        });
    },

     getAllOperatorByTaxiAssId: context => {
      context.$store
        .dispatch("TO/getAllByTaxiAffiliationId", {
          router: context.$router,
          id: context.affiliation_id
        })
        .then(data => {
          console.log("these are the operators: ", data);
          $(".default-selectOperator")
            .select2({
              multiple: false,
              placeholder: "Select A Operator",
              allowClear: true
            })
            .on("select2:close", function(e) {
              $(".default-selectDriver")
                .val(null)
                .trigger("change");

              $(".default-selectTaxi")
                .val(null)
                .trigger("change");
            });
        })
        .catch(error => {
          console.log("these are operator errors: ", error);
          context.$Notify.Error(error.data[0].message, "");
        });
    },


    getAllTaxiByTaxiAssId: context => {
      context.$store
        .dispatch("OperatorVehicle/getAllByTaxiAffilliationID", {
          router: context.$router,
          id: context.affiliation_id
        })
        .then(data => {
          //context.GETALLOPERATORS = context.$store.getters["TO/GETADDOPERATOR"];
          console.log("these are the vehicles: (vue) ", data);
          const taxi = $(".default-selectTaxi")
            .select2({
              multiple: false,
              placeholder: "Select A Taxi",
              allowClear: true
            })
            .on("select2:close", function(e) {
              $(".default-selectOperator")
                .val(null)
                .trigger("change");

              $(".default-selectDriver")
                .val(null)
                .trigger("change");
            });

          console.log(taxi);
        })
        .catch(error => {
          console.log("these are vehicle errors: ", error);
          value.context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },

    // messageRead: (context) => {
    //   context
    //     .markAsRead({
    //       router: context.$router,
    //       id: context.id,
    //     })
    //     .then(data => {
    //       console.log("message read: ", data);
    //     })
    //     .catch(error => {
    //       console.log("message read errors: ", error);
    //     });
    // },
  },
   
  mounted: function() {

    this.signature(this.context, this.USERPROFILE);
    this.getbyid(this.context, this.id, this.USERID);
    this.getDriversbyid(this.context);
    this.getAllOperatorByTaxiAssId(this.context);
    this.getAllTaxiByTaxiAssId(this.context);


    // this.messageRead(this.context);
    // this.getRecepients(this.context);
    $(".default-selectOperator").select2({
      multiple: false,
      placeholder: "Select A Operator",
      allowClear: true
    });

    $(".default-selectDriver").select2({
      multiple: false,
      placeholder: "Select A Driver",
      allowClear: true
    });

    $(".default-selectTaxi").select2({
      multiple: false,
      placeholder: "Select A Vehicle",
      allowClear: true
    });

    // this.fileadded(this.context);

    $("#context").data("context", this.context);

    $("#wysihtml5").wysihtml5();

    // $('#email-to').tagit({ availableTags:[this.GETINBOXBYID._received_by_name] });

  //   $('#email-to').tagit({
	// 	availableTags: ["c++", "java", "php", "javascript", "ruby", "python", "c"]
  // });
  
  // $("#email-to").tagit("availableTags");

    // $("#myTags").tagit("createTag", 'this.GETINBOXBYID._received_by_name');

    

    App.init();

    // var receiver = [];
    // receiver.push(this.GETINBOXBYID._received_by_name);

    // console.log("Send to: ", receiver);

    // const obj = receiver[0];

    // console.log("the object: ", obj);

    // sendTo(obj);

    // $("#email-to").tagit({
    //   autocomplete: {
    //     minLength: 4,
    //     delay: 0,
    //     source: function(request, response) {
    //       const context = $("#email-to").data("context");
    //       context
    //         .searchSystemUsers({
    //           router: context.$router,
    //           id: context.taxi_affiliation_id,
    //           text: request.term
    //         })
    //         .then(_data => {
    //           response(
    //             _data.map(item => {
    //               return (
    //                 item._firstname +
    //                 " " +
    //                 item._surname +
    //                 "( " +
    //                 context.$options.filters.HumanizeMobile(
    //                   item._mobile_number
    //                 ) +
    //                 " )"
    //               );
    //             })
    //           );
    //         })
    //         .catch(err => {
    //           console.log(err);
    //         });
    //     }
    //   }
    // });

    // const fileuploaded = [];
    // $("#dropzonefiles").tagit({
    //   beforeTagAdded: function(event, ui) {
    //     const context = $("#dropzonefiles").data("context");
    //     if (!ui.duringInitialization) {
    //       console.log(ui.tag);
    // context.$store
    //   .dispatch("fileupload/GETALL", {
    //     _dataObj:{
    //       router: context.$router
    //     }
    //   })
    //   .then(element => {
    //    console.log(element)
    //    fileuploaded.push({

    //    })
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   })
    //     }
    //   }
    // });
  }
};

// var sendTo = function(receiver) {
//   const context = $("#email-to").data("context");
//   $("#email-to").tagit({
//     availableTags: receiver[0]
//   });
// };
</script>

	

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>