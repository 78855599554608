<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Money'"
      :ActiveMenu="'TakeManualPayment'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Make Payment"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Money"
        breadcrumbitem3="Take Manual Bulk Payment"
        ActiveSubMenu="Money"
        ActiveMenu="TakePayment"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin invoice -->
      <div class="invoice">
        <!-- begin invoice-Logo -->
        <span class="pull-right">
          <img :src="encodedImage" class="width-160 height-40" />
        </span>
        <!-- begin invoice-Logo -->
        <!-- begin invoice-company -->
        <div class="invoice-company text-inverse f-w-600">
          <span class="pull-right hidden-print">
            <a
              href="javascript:;"
              :disabled="$wait.is('createpayment')"
              @click="createpayment(context, PAYMENTMODEL, taxiassociation_id)"
              :class="{
                'btn btn-lg btn-success m-r-20 m-b-10 p-l-5': !$wait.is(
                  'createpayment'
                ),
                'btn btn-lg btn-grey m-b-10 p-l-5': $wait.is('createpayment'),
              }"
            >
              <i
                class="fa fa-registered t-plus-1 text-white fa-fw fa-lg"
                v-show="!$wait.is('createpayment')"
              ></i>

              <i
                class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
                v-show="$wait.is('createpayment')"
              ></i>

              <span>Take Payment</span>
            </a>
          </span>
          {{ USERPROFILE.taxi_associations_name }}
        </div>
        <!-- end invoice-company -->

        <!-- begin invoice-header -->
        <div class="invoice-header">
          <div v-show="paymentCreated" class="alert alert-success fade show">
            <span class="close" data-dismiss="alert">×</span>
            <strong>Success!</strong>
            Payment Successfully.
          </div>
        </div>
        <!-- end invoice-header -->
        <!-- begin invoice-content -->
        <div class="invoice-content">
          <!-- begin table-responsive -->
          <div class="panel-body">
            <table id="data-table-default" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th width="7%"></th>
                  <th class="text-center">CAR REGISTRATION</th>
                  <th class="text-center">DATE</th>
                  <th class="text-center">AMOUNT</th>
                  <th class="text-center">CAPTURED BY</th>
                  <!-- <th class="text-center">ACTION</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in PAYMENTMODEL" :key="item.id">
                  <td class>
                    <div class="row p-t-10">
                      <div class="col-md-4">
                        <a
                          href="javascript:;"
                          @click.stop.prevent="AddNewBillItem(context, item)"
                          class="btn btn-danger btn-icon btn-circle btn-sm"
                        >
                          <i class="fa fa-plus"></i>
                        </a>
                      </div>
                      <div class="col-md-4">
                        <a
                          href="javascript:;"
                          @click.stop.prevent="PAYMENTMODEL.splice(index--, 1)"
                          v-show="index > 0"
                          class="btn btn-warning btn-icon btn-circle btn-sm"
                        >
                          <i class="fa fa-minus"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="col-md-7">
                      <select
                        class="text-center form-control col-md-12 selectTaxi"
                        data-size="5"
                        data-live-search="true"
                        v-model="item.vehicle_registration_id"
                        v-select2
                      >
                        <option>Select Taxi Vehicle</option>
                        <option
                          v-for="_items in OPERATORVEHICLES"
                          :key="_items._taxi_operator_vehicles_id"
                          :value="_items._taxi_operator_vehicles_id"
                        >
                          {{ _items._licence_plate }} ({{
                          _items._taxi_operators_name
                          }})
                        </option>
                      </select>
                    </div>
                  </td>
                  <td class="text-center">
                    <!-- <div class="input-group date m-0 height-20">
                       <input
                        type="text"
                        class="form-control"
                        placeholder="Select Date"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        v-model.lazy="item.payment_date"
                      />
                      <datepicker v-model.lazy="item.payment_date" name="uniquename"></datepicker>
                      <span class="input-group-addon height">
                        <i class="fa fa-calendar"></i>
                      </span>
                    </div>-->

                    <datepicker class="example" v-model.lazy="item.payment_date"></datepicker>
                  </td>
                  <td class="text-center" width="15%">
                    <CurrencyInput
                      v-model.lazy="item.amount"
                      :auto-decimal-mode="true"
                      :currency="currency"
                      data-parsley-required="false"
                      class="form-control text-left demo__currency-input"
                    />
                  </td>
                  <td class="text-center">
                    <select
                      class="text-center form-control col-md-7 captured_by"
                      data-size="5"
                      data-live-search="true"
                      v-model="item.captured_by"
                      v-select2
                    >
                      <option>Select Captured By</option>
                      <option
                        v-for="_items in CAPTUREDBY"
                        :key="_items.id"
                        :value="_items.id"
                      >{{ _items.firstname }} {{ _items.surname }}</option>
                    </select>
                  </td>
                  <!-- <td>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary width-60"
                      data-toggle="modal"
                      data-target="#EditModal"
                      @click="viewDetails(context,item)"
                    >Edit</button>
                  </td>-->
                </tr>
              </tbody>
            </table>
          </div>
          <!-- end table-responsive -->
        </div>
        <!-- end invoice-content -->

        <!-- begin invoice-note -->
        <div class="invoice-note">
          * Make all invoices payable to
          {{ USERPROFILE.taxi_associations_name }}
          <br />
        </div>
        <!-- end invoice-note -->
        <!-- begin invoice-footer -->
        <div class="invoice-footer">
          <p class="text-center m-b-5 f-w-600">{{ USERPROFILE.taxi_associations_name }}</p>
          <p class="text-center">
            <span class="m-r-10">
              <i class="fa fa-fw fa-lg fa-globe"></i>
              {{ SELECTEDTAXIASSOCIATION._abbriviated_name }}.TAMS.co.za
            </span>
            <span class="m-r-10">
              <i class="fa fa-fw fa-lg fa-phone-volume"></i>
              T:{{ SELECTEDTAXIASSOCIATION._telephone }}
            </span>
          </p>
        </div>
        <!-- end invoice-footer -->
      </div>
      <!-- end invoice -->

      <!-- begin #Main Body Template -->
      <!-- Add Modal HTML START-->
      <div id="EditModal" class="modal fade">
        <div class="modal-dialog">
          <div class="modal-content">
            <form>
              <div class="modal-header">
                <h4 class="modal-title">Edit Bulk Payment</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      VEHICLE REGISTRATION:
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        v-model="GETSELECTED.vehicle_license"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">
                      VEHICLE REGISTRATION:
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        v-model="GETSELECTED.vehicle_license"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->
                </div>
              </div>
              <div class="modal-footer">
                <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Add Modal HTML START-->

      <!-- end #content -->
    </div>
    <!-- #modal-dialog -->
    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { setTimeout } from "timers";
import Datepicker from "vuejs-datepicker";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("payments");

export default {
  name: "CreateInvoice",
  data: function () {
    return {
      currencySymbol: "R ",
      position: "prefix",
      acceptedFiles: ".csv",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      SELECTEDTAXI: {},
      SELECTEDOPERATOR: {},
      SELECTEDDRIVER: {},
      SELECTEDTAXIASSOCIATION: this.$store.getters[
        "auth/SELECTEDTAXIASSOCIATION"
      ],

      TRANSFEE: this.$store.getters["auth/SELECTEDTAXIASSOCIATION"]._percentage,
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      skip: 0,
      len: 10000000,
      OPERATORVEHICLES: [],
      GETSELECTED: {},
      GETAllTAXIMODELS: this.$store.getters["TV/GETALLMODEL"],
      GETAllBILLINGITEMS: this.$store.getters["billingitems/GETALL"],
      VEHICLETRANSACTIONS: [],
      OPERATORTRANSACTIONS: [],
      DRIVERTRANSACTIONS: [],
      taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      encodedImage: undefined,
      context: this,
      COMMENT: "",
      CAPTUREDBY: [],
      selectedTitleId: undefined,
      selectedGenderId: undefined,
      selectedUserGroupId: undefined,
      paymentCreated: false,
      printReady: false,
      linetotal: 0,
      SELECTEDTAXIID: 0,
      TOTALINVOICEAMOUNT: "",
      vehicleAccountInfo: [],
      documentsessionid: this.$uuid.v4(),
      capturedBySelected: false,
      PAYMENTMODEL: [
        {
          id: this.$uuid.v4(),
          vehicle_registration_id: 0,
          payment_date: "",
          amount: 0,
          captured_by: 0,
        },
      ],
      BILLINGITEMS: [
        {
          billingitems_id: 0,
          lineamount: 0,
          lineqty: 1,
          linetotal: 0,
        },
      ],
      PREPAYMENTOBJECT: this.$store.getters["payments/GETPREPAYMENTDETAILS"],
      PrePayment: this.$route.params.PrePayment,
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  beforeMount: function () {
    this.getCapturedBy(this.context);
    this.operatorVehiclesById(this.context);

    // this.getAllOperatorByTaxiAssId(this.context, this.taxiassociation_id);
    // this.getAllTaxiDriversByTaxiAssId(this.context, this.taxiassociation_id);
    // this.getAllBillingItemsByTaxiAssId(this.context, this.taxiassociation_id);
    // $(".nav-link").click(() => {
    //   setTimeout(() => {
    //     $(".default-select").select2({
    //       multiple: false,
    //       allowClear: true,
    //     });
    //     $(".default-selectOperator").val(null).trigger("change");
    //     $(".default-selectTaxi").val(null).trigger("change");
    //     $(".default-selectTaxiDrivers").val(null).trigger("change");
    //   }, 500);
    // });
  },
  beforeDestroy: function () {},
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    Datepicker,
  },
  computed: {
    ...mapGetters([]),
    currency() {
      return { [this.position]: this.currencySymbol };
    },
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
    CurrentDate: function () {
      return Date();
    },
    PAYMENTAMOUNT: function () {
      return Number(this.TOTALINVOICEAMOUNT);
    },
    TRANSACTIONFEE: function () {
      let _transfee = 0;
      return (Number(this.TOTALINVOICEAMOUNT) * this.TRANSFEE) / 100;
    },
    TOTALMOUNT: function () {
      return Number(this.TOTALINVOICEAMOUNT) + this.TRANSACTIONFEE;
    },
  },
  watch: {
    // value: function (value) {
    //   // update value
    //   $(this.$el).val(value).trigger("change");
    //   this.PAYMENTMODEL.vehicle_registration_id = value;
    // },
  },
  methods: {
    ...mapActions(["getById", "getByTaxiAssId", "prepayment", "makepayment"]),

    AddNewBillItem: function (context, item) {
      

      // console.log($("#selectTaxi").select2("data"));

      // context.PAYMENTMODEL.payment_date = context.$Toolkit.ConvertToServerDate(
      //   $("#licence_expiry_date").datepicker("getDate")
      // );

      // context.PAYMENTMODEL.captured_by = parseInt($("#captured_by").val());

      // context.PAYMENTMODEL = [];

      // context.PAYMENTMODEL.push({
      //   id: context.$uuid.v4(),
      //   vehicle_registration_id: context.PAYMENTMODEL.vehicle_registration_id,
      //   payment_date: context.PAYMENTMODEL.payment_date,
      //   amount: item.amount,
      //   captured_by: context.PAYMENTMODEL.captured_by,
      // });

      item.payment_date = context.$Toolkit.ConvertToServerDate(
        item.payment_date
      );

      context.PAYMENTMODEL.push({
        id: context.$uuid.v4(),
        vehicle_registration_id: 0,
        payment_date: "",
        amount: 0,
        captured_by: 0,
      });

      setTimeout(() => {
        $(".captureddate", ".table-striped tr:last").datepicker({
          todayHighlight: true,
          autoclose: true,
        });

        // $(".default-selectTaxi").select2('destroy');

        $(".selectTaxi", ".table-striped tr:last").select2({
          multiple: false,
          placeholder: "Select A Taxi",
          allowClear: false,
        });

        $(".captured_by", ".table-striped tr:last").select2({
          multiple: false,
          placeholder: "Select Captured By",
          allowClear: false,
        });
      }, 100);
    },

    viewDetails: (context, item) => {
      
      console.log(item);
      context.GETSELECTED = Object.assign({}, item);
      $("#EditModal").modal("show");
    },
    fileadded: (data) => {
      
      console.log("Header:fileadded");
      console.log(data);

      const context = $("#dropzone").data("context");
    },

    reset: function (context) {
      $(".default-selectTaxiDrivers").val(null).trigger("change");
      $(".default-selectTaxi").val(null).trigger("change");
      $(".default-selectOperator").val(null).trigger("change");
      context.COMMENT = "";
      context.TOTALINVOICEAMOUNT = "";
    },

    getCapturedBy: (context) => {
      context.$store
        .dispatch("systemusers/getSystemUsersCapturedBy", {
          associationid: context.taxiassociation_id,
          userid: context.USERID,
          router: context.$router,
        })
        .then((data) => {
          console.log("captured By results: ", data);
          context.CAPTUREDBY = data;
        })
        .catch((error) => {
          console.log("captured page: ", error);
          // context.$Notify.Error(error.message, "");
        })
        .finally(() => {});
    },

    operatorVehiclesById: (context) => {
      // 
      context.$store
        .dispatch("OperatorVehicle/getVehiclesByAssIdDropDowns", {
          router: context.$router,
          taxiassociation_id: context.taxiassociation_id,
          skip: context.skip,
          len: context.len,
        })
        .then((data) => {
          
          console.log("operator Vehicles: ", data);
          context.OPERATORVEHICLES = data;
        })
        .catch((err) => {
          console.log("operator Vehicles errors -> ", err);
        })
        .finally(() => {
          // $("#data-table-vehicles").DataTable({
          //   responsive: true,
          // });
        });
      //this.$emit('init')
    },

    generatePaymentResults: function (context) {},
    setViewDetail(val) {},

    createpayment: (context, item, taxiassociation_id) => {
      

      context.item = item.map((data) => {
        data.payment_date = context.$Toolkit.ConvertToServerDate(
          data.payment_date
        );

        return data;
      });

      context.$Notify
        .YesNoMore("Take Payment", "Are you sure?", false, {
          context,
          item,
          taxiassociation_id,
        })
        .then((value) => {
          if (value) {
            
            // value.context.$wait.start("createpayment");
            value.router = value.context.$router;

            value.context.$store
              .dispatch("payments/makeBulkVehiclepayment", {
                router: value.router,
                payload: item,
                taxiassociation_id,
              })
              .then((_data) => {
                console.log(_data);
                context.$Notify.Success("Payments Made Successfully!");
                context.PAYMENTMODEL = [];
                context.PAYMENTMODEL.push({
                  id: context.$uuid.v4(),
                  vehicle_registration_id: 0,
                  payment_date: "",
                  amount: 0,
                  captured_by: 0,
                });

                setTimeout(() => {
                  $(".captureddate", ".table-striped tr:last").datepicker({
                    todayHighlight: true,
                    autoclose: true,
                  });

                  // $(".default-selectTaxi").select2('destroy');

                  $(".selectTaxi", ".table-striped tr:last").select2({
                    multiple: false,
                    placeholder: "Select A Taxi",
                    allowClear: false,
                  });

                  $(".captured_by", ".table-striped tr:last").select2({
                    multiple: false,
                    placeholder: "Select Captured By",
                    allowClear: false,
                  });
                }, 100);
              })
              .catch((error) => {
                context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
  },
  mounted: function () {
    $(".captureddate")
      .datepicker({
        todayHighlight: true,
        autoclose: true,
      })
      .on("changeDate", function () {
        
        var test = $("#my_input").val(
          $(".captureddate").datepicker("getFormattedDate")
        );
        console.log(test);
      });

    $(".selectTaxi").select2({
      multiple: false,
      placeholder: "Select A Taxi",
      allowClear: false,
    });

    $(".captured_by").select2({
      multiple: false,
      placeholder: "Select Captured By",
      allowClear: false,
    });

    App.init();

    if (this.PrePayment) {
      this.createprepayment(this.context);
    }

    this.$store
      .dispatch("TA/getLOGOURL", {
        id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id,
      })
      .then((result) => {
        this.context.encodedImage = result;
      })
      .catch((err) => {});
  },
};
</script>

<style scoped>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
.logo-image {
  width: 200px;
  height: 200px;
}

.example {
  width: 50%;
  margin-top: 3%;
  margin-left: 12%;
}
</style>

