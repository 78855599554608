<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageVehicle'"
      :ActiveMenu="'ManageMakes'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Makes"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Makes"
        breadcrumbitem3="Manage"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">All Makes</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <a
                  data-toggle="modal"
                  data-target="#AddModal"
                  class="btn btn-white"
                  href="javascript:;"
                >
                  <i class="fa fa-plus"></i>
                  Add Make
                </a>
              </div>
            </div>

            <!-- Add Modal HTML START-->
              <div id="AddModal" class="modal fade">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Add Make</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Name
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                data-live-search="true"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDMAKE.name"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Description
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                data-live-search="true"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDMAKE.description"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Code
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                data-live-search="true"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="ADDMAKE.code"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        />
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          :disabled="$wait.is('add-')"
                          @click="add(context, ADDMAKE)"
                        >
                          <v-wait v-bind:for="'add-'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Save
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Add Modal HTML START-->

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-titles" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">Description</th>
                    <th class="text-nowrap">Code</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Last Updated By</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETVEHICLEMAKES"
                    v-bind:key="item.vehiclemake_id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item._name}}</td>
                    <td>{{item._description}}</td>
                    <td>{{item._code}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._deleted_by }"
                        v-if="item._deleted_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item._is_active, 'label-secondary':!item._is_active  }"
                        v-if="!item._deleted_by"
                      >{{item._is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td>{{item._updated_by_name || item._added_by_name}}</td>
                    <td class="with-btn valign-middle" nowrap>
                        <router-link
                        :to="{ name:  'managemodels',  params: { id: item.vehiclemake_id }}"
                        class="btn btn-sm btn-warning width-80 m-r-2"
                        v-if="item.vehiclemake_id"
                      >Models</router-link>
                      <button
                        type="button"
                        class="btn btn-sm btn-primary width-80 m-r-2"
                        data-toggle="modal"
                      >Edit</button>

                      <button
                        type="button"
                        class="btn btn-sm btn-success width-80"
                        data-toggle="modal"
                        v-show="item._deleted_by"
                        :disabled="$wait.is('restoreDeleted-' + item._id)"
                      >
                        <v-wait v-bind:for="'restoreDeleted-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Restore
                        </v-wait>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-80"
                        v-show="!item._deleted_by"
                        :disabled="$wait.is('delete-' + item._id)"
                      >
                        <v-wait v-bind:for="'delete-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Delete
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller/>
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import headerComp from "@/components/common/header.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import pageloader from "@/components/common/pageloader.vue";

import { createNamespacedHelpers } from "vuex";
import { setTimeout } from "timers";
const { mapGetters, mapActions } = createNamespacedHelpers("OperatorVehicle");

export default {
  name: "ManageMakes",
  data: function() {
    return {
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      ADDMAKE: {
        name: '',
        description: '',
        code: '',
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid
      },
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["GETVEHICLEMAKES"]),
    LoggedInUserFullName: function ()
    {
      return this.USERPROFILE.first_name + ' ' + this.USERPROFILE.surname
    },
  },
  methods: {
    ...mapActions(["getall", "delete", "edit", "add"]),
    
    add: (context, item) => {

      context.$Notify.YesNo(false,{ context, item })
      .then(value => {
        if (value) {
          
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("OperatorVehicle/addVehicleMake", {
               addModel: value.item,
               router: value.router
            })
            .then((data) => {
              console.log('added id: ',data);
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");
              value.context.$Notify.Success("Successfully Added!", data);
              context.getallmakes(context);
            })
            .catch(error => {
              value.context.$wait.end("add");
              
              value.context.$Notify.Error(error.data[0].message, '');
            })
            .finally(() => {});
        }
      });
    },
    getallmakes: context => {
      context.$store
        .dispatch("OperatorVehicle/getallVehicleMakes", {
          _dataObj: {
            router: context.$router
          }
        })
        .then((data) => {
          console.log('makes found: ',data);
          
        });
    },
    edited: (context, item, updatedby) => {
      //item = Object.assign({}, item);
      context.$Notify.YesNo(false,{ context, item, updatedby, })
      .then(value => {
        if (value) {
          
          value.context.$wait.start("edited");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("title/update", {
              _dataObj: value
            })
            .then(() => {
              $("#EdititleModal").modal("hide");
              value.context.$wait.end("edited");
              value.context.$Notify.Success("Successfully Updated!", "");
            })
            .catch(error => {
              value.context.$Notify.Error(error.data[0].message, '');
            })
            .finally(() => {
              value.context.$wait.end("edited");
            });
        }
      });
    },
    deletetitle: (context, item, deletedby) => {
      context.$Notify.YesNo(false,{ context, id: item._id, deletedby })
      .then(value => {
        if (value) {
          value.context.$wait.start("delete-" + value.id);
          value.router = value.context.$router;
          value.context.$store
            .dispatch("title/markAsdelete", {
              _dataObj: value
            })
            .then(id => {
              value.context.$wait.end("delete-" + id);
              value.context.$Notify.Success("Successfully Deleted!", "");
            })
            .catch(error => {
              value.context.$Notify.Error(error.data[0].message, '');
            })
            .finally(() => {
            });
        }
      });
    },
  },
  mounted: function() {

    App.init();
    
    this.getallmakes(this.context);
    
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

