<template>
 
</template>

<script>
export default {
    name:'pageloader'
}
</script>


<style scoped>

</style>
