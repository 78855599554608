<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TaxiAssociation'"
      :ActiveMenu="'TaxiAssociationSpotFines'"
    />

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Taxi Association Spot Fines"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Association"
        breadcrumbitem3="Spot Fines"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">All Associations</h4>
            </div>
            <!-- end panel-heading -->

            <!-- <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <router-link
                  class="btn btn-white"
                  href="javascript:;"
                  to="/TaxiAssociation/Add"
                  @click="clearAdded(GETSELECTED)"
                >
                  <i class="fa fa-plus"></i>
                  Add Association
                </router-link>
              </div>
            </div> -->

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-default" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">Description</th>
                    <th class="text-nowrap">Code</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in SPOTFINES"
                    v-bind:key="item._compliance_voilation_settings_id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item._name}}</td>
                    <td>{{item._description}}</td>
                    <td>{{item._code}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import headerComp from "@/components/common/header.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import pageloader from "@/components/common/pageloader.vue";

import { createNamespacedHelpers } from "vuex";
import { setTimeout } from "timers";
const { mapGetters, mapActions } = createNamespacedHelpers("TA");

export default {
  name: "ManageSpotFines",
  data: function() {
    return {
      TAXIAFFILIATION: this.$store.getters["taxiAffiliation/GETALLACTIVE"],
      GETALLPROVINCES: this.$store.getters["lookups/GETALLPROVINCES"],
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      id: this.$route.params.id,
      SPOTFINES: [],
      addedsuccess: undefined,
      errormessage: undefined,
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  beforeMount: function(){
    this.getSpotFinesById(this.context);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["TAXIASSOCIATIONSPOTFINES"]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions(["getall", "delete", "edit", "add"]),

    getSpotFinesById: context => {
      context.$store
        .dispatch("TA/getSpotFinesById", {
            id: context.id,
            router: context.$router
        })
        .then((data) => {
          console.log("spot fines:", data);
          context.SPOTFINES = data;
          
          
        }).catch((error)=>{
          console.log("spot fines errors:", error);
        })
    },
  },
  mounted: function() {
    // this.getall(this.context);

    App.init();
    

    $('#data-table-default').DataTable({
			responsive: true
		});
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

