<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TPAY'"
      :ActiveMenu="'SearchUsers'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Full Search Voucher"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="TPAY"
        breadcrumbitem3="Search Voucher"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-md-12">
          <!-- begin result-container -->
          <div class="result-container">
            <!-- begin input-group -->
            <div class="input-group input-group-lg m-b-20">
              <input
                type="text"
                v-on:keydown.enter.prevent="onSeachclick(context)"
                v-model="searchText"
                class="form-control input-white"
                placeholder="Enter keywords here..."
              />
              <div class="input-group-append">
                <button
                  type="button"
                  @click="onSeachclick(context)"
                  class="btn btn-primary"
                  :disabled="$wait.is('search')"
                >
                  <i class="fa fa-search fa-fw" v-show="!$wait.is('search')"></i>
                  <i
                    class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
                    v-show="$wait.is('search')"
                  ></i>
                  Search
                </button>
              </div>
            </div>
            <!-- end input-group -->
          </div>
          <!-- end result-container -->

          <!-- begin nav-pills -->
          <ul class="nav nav-pills">
             <li class="nav-items">
              <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active">
                <span class="d-sm-none">VOUCHERS</span>
                <span class="d-sm-block d-none">VOUCHERS <span v-if="VEHICLERESULTS != null" class="badge badge-success">{{VEHICLERESULTS.length}}</span></span>
              </a>
            </li>
           
          </ul>
          <!-- end nav-pills -->
          <!-- begin tab-content -->
          <div class="tab-content">
             <!-- begin tab-pane -->
            <div class="tab-pane fade active show" id="nav-pills-tab-1">
              <table id="data-table-commuters" class="table table-striped table-bordered width-full">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">REF</th>
                    <th class="text-nowrap">TRANS REF</th>
                    <th class="text-nowrap">PRODUCT NAME</th>
                    <th class="text-nowrap">SERIAL NUMBER</th>
                    <th class="text-nowrap">STATUS</th>
                    <th class="text-nowrap">AMOUNT</th>
                    <th class="text-nowrap">OUTSTAUNDING AMOUNT</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in VEHICLERESULTS"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                     <td>{{item.ref}}</td>
                    <td>{{item.transref}}</td>
                    <td>{{item.product_name}}</td>
                    <td>{{item.serial_number}}</td>
                    <td>{{item.voucher_status_name}}</td>
                    <td>{{item.amount | newcurrencywithSymbol}}</td>
                     <td>{{item.outstanding_amount | newcurrencywithSymbol}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <router-link
                        :to="{ name: 'tpayvoucherprofile', params: { id: item.id }}"
                        class="btn btn-sm btn-success width-80 m-r-2"
                      >View</router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end tab-pane -->
          </div>
          <!-- end tab-content -->
        </div>
        <!-- end col-12 -->
      </div>
      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("tpay_systemuser");

export default {
  name: "FullSystemSearch",
  data: function () {
    return {
      SEARCHRESULTS: [],
      OPERATORRESULTS: [],
      DRIVERRESULTS: [],
      TAXIRESULTS: [],
      AGENTSRESULTS: [],
      COMMUTERSRESULTS: [],
      ORGANISATIONSRESULTS: [],
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,

      searchText: this.$router.app._route.query.text,
      dataloaded: false
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters({}),
  },
  beforeMount() {},
  methods: {
    ...mapActions([
      "searchOperator",
      "searchVoucherFullSystem",
      "markAsDeleted",
      "clearAll",
      "restoreDeleted",
      "getLOGOURL",
    ]),

    onSeachclick: (context) => {
      if (context.searchText.length === 0) return;

      context.$wait.start("search");
      

      context
        .searchVoucherFullSystem({
          id: context.searchText,
          router: context.$router,
        })
        .then((rb) => {
          
          
          console.log("System search results", rb);

          context.VEHICLERESULTS = rb;

          context.$wait.end("search");
        })
        .catch((error) => {
          console.log("System search errors: ", error);
          context.$Notify.Error(error.data.message, "");
        })
        .finally(() => {

          //if(!context.dataloaded) {

          //   $("#data-table-operators").DataTable({
          //   responsive: true,
          // });

          // $("#data-table-taxis").DataTable({
          //   responsive: true,
          // });

          // $("#data-table-drivers").DataTable({
          //   responsive: true,
          // });

          context.dataloaded = true;
          
          //}

          

          context.$wait.end("search");
        });
    },
  },
  mounted: function () {
    //this.get(this.context);

    // if(this.$router.app._route.query.text.length > 0){
    //   this.onSeachclick(this.context);
    // }

    App.init();

    console.log(this.$router.app._route.query.text);

    //$('.selectpicker1').selectpicker('render');
  },
  beforeDestroy: function () {},
};
</script>

<style scoped>
</style>
