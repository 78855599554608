<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Money'"
      :ActiveMenu="'refundpayment'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Refund Payment"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Money"
        breadcrumbitem3="Refund Payment"
        ActiveSubMenu="Money"
        ActiveMenu="refundpayment"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin invoice -->
      <div class="invoice">
        <!-- begin invoice-Logo -->
        <span class="pull-right">
          <img :src="encodedImage" class="width-160 height-40" />
        </span>
        <!-- begin invoice-Logo -->
        <!-- begin invoice-company -->
        <div class="invoice-company text-inverse f-w-600">
          <span class="pull-right hidden-print">
            <a
              href="javascript:;"
              :disabled="$wait.is('createpayment')"
              @click="createpayment(context)"
              :class="{'btn btn-lg btn-success m-r-20 m-b-10 p-l-5': !$wait.is('createpayment'),
            'btn btn-lg btn-grey m-b-10 p-l-5': $wait.is('createpayment')
            }"
            >
              <i
                class="fa fa-registered t-plus-1 text-white fa-fw fa-lg"
                v-show="!$wait.is('createpayment')"
              ></i>

              <i
                class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
                v-show="$wait.is('createpayment')"
              ></i>

              <span>Refund Payment</span>
            </a>
            <a
              v-show="printReady"
              href="#modal-dialog"
              data-toggle="modal"
              class="btn m-r-20 btn-lg btn-white m-b-10 p-l-5"
            >
              <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print Receipt
            </a>
          </span>
          {{USERPROFILE.taxi_associations_name}}
        </div>
        <!-- end invoice-company -->

        <!-- begin invoice-header -->
        <div class="invoice-header">
          <div v-show="paymentCreated" class="alert alert-success fade show">
            <span class="close" data-dismiss="alert">×</span>
            <strong>Success!</strong>
            Payment Successfully.
          </div>

          <div class="row">
            <div class="col-lg-6">
              <!-- begin card -->
              <div class="card text-center">
                <div class="card-header">
                  <ul class="nav nav-tabs card-header-tabs">
                    <li class="nav-item" v-show="SELECTEDTAXIASSOCIATION._create_vehicle_account">
                      <a class="nav-link active" data-toggle="tab" href="#card-tab-1">Taxis</a>
                    </li>
                    <li class="nav-item" v-show="SELECTEDTAXIASSOCIATION._create_operator_account">
                      <a class="nav-link" data-toggle="tab" href="#card-tab-2">Operators</a>
                    </li>
                    <li class="nav-item" v-show="SELECTEDTAXIASSOCIATION._create_driver_account">
                      <a class="nav-link" data-toggle="tab" href="#card-tab-3">Drivers</a>
                    </li>
                  </ul>
                </div>
                <div class="card-block">
                  <div class="tab-content p-0 m-0">
                    <div
                      class="tab-pane fade active show"
                      id="card-tab-1"
                      v-show="SELECTEDTAXIASSOCIATION._create_vehicle_account"
                    >
                      <div class="invoice-to">
                        <small>
                          <select
                            class="default-selectTaxi default-select form-control"
                            data-size="5"
                            data-live-search="true"
                          >
                            <option
                              :key="item._taxi_operator_vehicles_id"
                              v-for="item in GETALLTAXIS"
                              :value="item._taxi_operator_vehicles_id"
                            >{{item._licence_plate}} ({{item._taxi_operators_name}}) Bal: R{{item._available_balance || 0}}</option>
                          </select>
                        </small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse"></strong>
                          <br />
                          <br />
                          <br />
                          <br />
                        </address>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="card-tab-2"
                      v-show="SELECTEDTAXIASSOCIATION._create_operator_account"
                    >
                      <div class="invoice-to">
                        <small>
                          <select
                            class="default-selectOperator default-select form-control"
                            data-size="5"
                            data-live-search="true"
                          >
                            <option
                              v-for="item in GETALLOPERATORS"
                              :key="item.taxi_operator_id"
                              :value="item.taxi_operator_id"
                            >{{item.firstname}} {{item.surname}}({{item.id_number}}) Bal: R{{item._available_balance || 0}}</option>
                          </select>
                        </small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse"></strong>
                          <br />
                          <br />
                          <br />
                          <br />
                        </address>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="card-tab-3"
                      v-show="SELECTEDTAXIASSOCIATION._create_driver_account"
                    >
                      <div class="invoice-to">
                        <small>
                          <select
                            class="default-selectTaxiDrivers default-select form-control"
                            data-size="5"
                            data-live-search="true"
                          >
                            <option
                              v-for="item in GETAllTAXIDRIVERS"
                              :key="item._taxi_drivers_id"
                              :value="item._taxi_drivers_id"
                            >{{item._firstname}} {{item._surname}}({{item._id_number}}) Bal: R{{item._available_balance || 0}}</option>
                          </select>
                        </small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse"></strong>
                          <br />
                          <br />
                          <br />
                          <br />
                        </address>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end card -->
            </div>
            <div class="col-lg-6">
              <div class="invoice-date">
                <small></small>
                <div class="date text-inverse m-t-10 width-200 pull-right">
                  Payment Date:
                  <div
                    class="input-group date"
                    id="datepicker-disabled-past"
                    data-date-format="dd M yyyy"
                  >
                    <input type="text" class="form-control" placeholder="Select Date" />
                    <span class="input-group-addon">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>

                <div class="invoice-detail">
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end invoice-header -->
        <!-- begin invoice-content -->
        <div class="invoice-content">
          <!-- begin invoice-price -->
          <div class="invoice-price">
            <div class="invoice-price-left">
              <div class="invoice-price-row">
                <div class="sub-price">
                  <small>Cash</small>

                  <span class="text-inverse">
                    R
                    <CurrencyInput
                      id="cash"
                      v-model="TOTALINVOICEAMOUNT"
                      :currency="currency"
                      :auto-decimal-mode="true"
                      class="height-40 text-left demo__currency-input"
                    />
                  </span>
                </div>
                <div class="sub-price">
                  <i class="fa fa-plus text-muted"></i>
                </div>
                <div class="sub-price">
                  <small>TRANSACTION FEE ({{this.TRANSFEE}}%)</small>
                  <span class="text-inverse">R{{TRANSACTIONFEE | currency}}</span>
                </div>
              </div>
            </div>
            <div class="invoice-price-right">
              <small>TOTAL</small>
              <span class="f-w-600">R{{TOTALMOUNT | currency}}</span>
            </div>
          </div>
          <!-- end invoice-price -->
        </div>
        <!-- end invoice-content -->
        <!-- begin invoice-note -->
        <div class="invoice-note">
          <div class="form-group row">
            <label class="col-md-1 col-form-label">Reference</label>
            <div class="col-md-5">
              <textarea
                v-model="COMMENT"
                class="form-control m-b-10"
                id="clipboard-textarea"
                rows="7"
              ></textarea>
            </div>
          </div>
          * Make all invoices payable to {{USERPROFILE.taxi_associations_name}}
          <br />
        </div>
        <!-- end invoice-note -->
        <!-- begin invoice-footer -->
        <div class="invoice-footer">
          <p class="text-center m-b-5 f-w-600">{{USERPROFILE.taxi_associations_name}}</p>
          <p class="text-center">
            <span class="m-r-10">
              <i class="fa fa-fw fa-lg fa-globe"></i>
              {{SELECTEDTAXIASSOCIATION._abbriviated_name}}.TAMS.co.za
            </span>
            <span class="m-r-10">
              <i class="fa fa-fw fa-lg fa-phone-volume"></i>
              T:{{SELECTEDTAXIASSOCIATION._telephone}}
            </span>
          </p>
        </div>
        <!-- end invoice-footer -->
      </div>
      <!-- end invoice -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- #modal-dialog -->
    <div class="modal fade" id="modal-dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Print Receipts</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
          </div>
          <div class="modal-body">
            <table id="data-table-titles" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th class="text-nowrap">Type</th>
                  <th class="text-nowrap">Name</th>
                  <th class="text-nowrap">Receipt Number</th>
                  <th class="text-nowrap">
                    <a
                      @click="printAll(context)"
                      href="javascript:;"
                      data-dismiss="modal"
                      class="btn btn-sm btn-white"
                    >
                      <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print All
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in GETPAYMENTRESULTS"
                  v-bind:key="item._id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                  <td>{{item.entityname}}</td>
                  <td>{{PAYMENTRESULTS_NAME(context,item.entityname,item.entity_id)}}</td>
                  <td>{{item.receiptnumber}}</td>
                  <td class="with-btn valign-middle" nowrap>
                    <a
                      @click="printNow(context,item)"
                      href="javascript:;"
                      class="btn btn-sm btn-white"
                    >
                      <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print
                    </a>
                    <a
                      @click="printPreview(context,item)"
                      href="javascript:;"
                      class="btn btn-sm btn-white"
                    >
                      <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Preview
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <a href="javascript:;" class="btn btn-white" data-dismiss="modal">Close</a>
          </div>
        </div>
      </div>
    </div>
    <!-- #modal-dialog -->
    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import Inputmask from "inputmask";
import { setTimeout } from "timers";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("payments");

export default {
  name: "GiveRefund",
  data: function () {
    return {
      currencySymbol: "",
      position: "prefix",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      SELECTEDTAXI: {},
      SELECTEDOPERATOR: {},
      SELECTEDDRIVER: {},
      SELECTEDTAXIASSOCIATION: this.$store.getters[
        "auth/SELECTEDTAXIASSOCIATION"
      ],
      //TRANSFEE: this.$store.getters["auth/SELECTEDTAXIASSOCIATION"]._percentage,
      TRANSFEE: 0.0,
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      GETALLTAXIS: [],
      GETALLOPERATORS: this.$store.getters["TO/GETOPERATORSBYACCOUNTNO"],
      GETAllTAXIDRIVERS: this.$store.getters[
        "TaxiDrivers/GETDRIVERSBYASSIDNOPIC"
      ],
      GETAllTAXIMODELS: this.$store.getters["TV/GETALLMODEL"],
      GETAllBILLINGITEMS: this.$store.getters["billingitems/GETALL"],
      taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      encodedImage: null,
      context: this,
      COMMENT: "",
      selectedTitleId: undefined,
      selectedGenderId: undefined,
      selectedUserGroupId: undefined,
      paymentCreated: false,
      printReady: false,
      linetotal: 0,
      TOTALINVOICEAMOUNT: "",
      BILLINGITEMS: [
        {
          billingitems_id: 0,
          lineamount: 0,
          lineqty: 1,
          linetotal: 0,
        },
      ],
      PREPAYMENTOBJECT: this.$store.getters["payments/GETPREPAYMENTDETAILS"],
      PrePayment: this.$route.params.PrePayment,
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  beforeMount: function () {
    this.getAllTaxiByTaxiAssId(this.context, this.taxiassociation_id);
    this.getAllOperatorByTaxiAssId(this.context, this.taxiassociation_id);
    this.getAllTaxiDriversByTaxiAssId(this.context, this.taxiassociation_id);
    this.getAllBillingItemsByTaxiAssId(this.context, this.taxiassociation_id);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters(["GETPAYMENTRESULTS"]),
    currency() {
      return { [this.position]: this.currencySymbol };
    },
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
    CurrentDate: function () {
      return Date();
    },
    PAYMENTAMOUNT: function () {
      return Number(this.TOTALINVOICEAMOUNT);
    },
    TRANSACTIONFEE: function () {
      let _transfee = 0;
      return (Number(this.TOTALINVOICEAMOUNT) * this.TRANSFEE) / 100;
    },
    TOTALMOUNT: function () {
      return Number(this.TOTALINVOICEAMOUNT) + this.TRANSACTIONFEE;
    },
  },
  watch: {},
  methods: {
    ...mapActions(["getById", "getByTaxiAssId", "prepayment", "refundpayment"]),
    reloadInfo: function (context) {
      context.getAllTaxiByTaxiAssId(context, context.taxiassociation_id);
      context.getAllOperatorByTaxiAssId(context, context.taxiassociation_id);
      context.getAllTaxiDriversByTaxiAssId(context, context.taxiassociation_id);
    },
    printNow: function (context, item) {
      let routeData = context.$router.resolve({
        name: "printpayment",
        params: { id: item.receiptid, preview: false },
      });
      window.open(routeData.href, "_blank");
    },
    printPreview: function (context, item) {
      let routeData = context.$router.resolve({
        name: "printpayment",
        params: { id: item.receiptid, preview: true },
      });
      window.open(routeData.href, "_blank");
    },
    printAll: function (context) {
      context.GETPAYMENTRESULTS.forEach((item) => {
        let routeData = context.$router.resolve({
          name: "printpayment",
          params: { id: item.receiptid, preview: false },
        });
        window.open(routeData.href, item.receiptid);
      });
    },
    reset: function (context) {
      $(".default-selectTaxiDrivers").val(null).trigger("change");
      $(".default-selectTaxi").val(null).trigger("change");
      $(".default-selectOperator").val(null).trigger("change");
      context.COMMENT = "";
      context.TOTALINVOICEAMOUNT = "";
    },
    PAYMENTRESULTS_NAME: function (context, entityname, id) {
      
      if (
        entityname != undefined &&
        entityname.toUpperCase() === "Driver".toUpperCase()
      ) {
        if (context.GETAllTAXIDRIVERS.length > 0) {
          const _details = context.GETAllTAXIDRIVERS.filter(
            (item) => item._taxi_drivers_id === id
          )[0];
          console.log(_details._firstname + " " + _details._surname);
          return _details._firstname + " " + _details._surname;
        }
      } else if (
        entityname != undefined &&
        entityname.toUpperCase() === "Taxi".toUpperCase()
      ) {
        if (context.GETALLTAXIS.length > 0) {
          return context.GETALLTAXIS.filter(
            (item) => item._taxi_operator_vehicles_id === id
          )[0]._vehicle_register_number;
        }
      } else if (
        entityname != undefined &&
        entityname.toUpperCase() === "Operator".toUpperCase()
      ) {
        if (context.GETALLOPERATORS.length > 0) {
          const _details = context.GETALLOPERATORS.filter(
            (item) => item.taxi_operator_id === id
          )[0];
          console.log(_details.firstname + " " + _details.surname);
          return _details.firstname + " " + _details.surname;
        }
      }
    },
    generatePaymentResults: function (context) {},
    changeBillItem: function (context, rowitem, billingitem) {
      rowitem.billingitems_id = billingitem._billing_items_id;
      rowitem.lineamount = billingitem._billing_items_amount;
    },
    AddNewBillItem: function (context) {
      context.BILLINGITEMS.push({
        billingitems_id: 0,
        lineamount: 0,
        lineqty: 1,
        linetotal: 0,
      });
    },
    removeBillItem: function (context, index) {
      context.BILLINGITEMS.splice(index, 1);
    },
    // GETTAXISBYMODELID: function(context, id) {
    //   return context.GETALLTAXIS.filter(item => item._vehiclemodel_id === id);
    // },
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    getAllBillingItemsByTaxiAssId: (context, id) => {
      context.$store
        .dispatch("billingitems/getAllByTaxiAssID", {
          router: context.$router,
          id,
        })
        .then(() => {})
        .catch((error) => {
          value.context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },
    getAllTaxiDriversByTaxiAssId: (context, id) => {
      context.$store
        .dispatch("TaxiDrivers/getAllByTaxiAssIdNoPic", {
          router: context.$router,
          id,
        })
        .then(() => {
          //context.GETALLTAXIS = context.$store.getters["TV/GETVEHICLE"];

          $(".default-selectTaxiDrivers").select2({
            multiple: true,
            placeholder: "Select A Taxi",
            allowClear: true,
          });

          $(".default-selectTaxiDrivers").val(null).trigger("change");
        })
        .catch((error) => {
          value.context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },

    getAllTaxiByTaxiAssId: (context, id) => {
      context.$store
        .dispatch("OperatorVehicle/getVehiclesByAssIdDropDowns", {
          router: context.$router,
          taxiassociation_id: id,
          skip: 0,
          len: 2000,
        })
        .then((data) => {
          //context.GETALLOPERATORS = context.$store.getters["TO/GETADDOPERATOR"];

          console.log("vehicles vue: ", data);
          context.GETALLTAXIS = data;

          $(".default-selectTaxi").select2({
            multiple: false,
            placeholder: "Select A Taxi",
            allowClear: false,
          });

          $(".default-selectTaxi").val(null).trigger("change");

          // $(".default-selectTaxi").on("change", function () {
          //   var taxiID = $(".default-selectTaxi option:selected").val();
          //   console.log("selected Taxi", taxiID);
          //   if (taxiID != null) {
          //     //context.getvehicleTransactionsById(context, taxiID);
          //   } else {
          //     console.log("no taxi selected!");
          //   }
          // });

          // $(".default-selectTaxi")
          // .val(null)
          // .trigger("change");
        })
        .catch((error) => {
          console.log("vehicles errors: ", error);
          context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },

    getAllOperatorByTaxiAssId: (context, id) => {
      context.$store
        .dispatch("TO/getOperatorByAccountNo", {
          router: context.$router,
          id,
        })
        .then(() => {
          //context.GETAllTAXIDRIVERS = this.$store.getters["TaxiDrivers/GETADDTAXIDRIVER"];

          $(".default-selectOperator").select2({
            multiple: true,
            placeholder: "Select A Operator",
            allowClear: true,
          });

          $(".default-selectOperator").val(null).trigger("change");
        })
        .catch((error) => {
          context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },
    createprepayment: (context) => {
      

      try {
        if (context.PREPAYMENTOBJECT.length == 0) {
          return;
        }
      } catch (e) {}

      try {
        if (context.PREPAYMENTOBJECT.TotalAmount == 0) {
          context.$Notify.Error("Please Enter Amount", "");
          return;
        }
      } catch (e) {}

      const paymentdetails = {
        paymentamount: context.PREPAYMENTOBJECT.SubTotal,
        transactionfee: context.PREPAYMENTOBJECT.TransactionFee,
        totalamount: context.PREPAYMENTOBJECT.TotalAmount,
      };

      const Payload = {
        paymentdetails,
        linetotal: context.PREPAYMENTOBJECT.SubTotal,
        comment: context.PREPAYMENTOBJECT.Reference,
        reference: context.PREPAYMENTOBJECT.Reference,
        added_by: context.USERID,
        captured_by: context.USERID,
        payment_date: context.PREPAYMENTOBJECT.PaymentDate,
        taxi_association_id: context.taxiassociation_id,
      };

      context.$Notify
        .YesNoMore("Refund Payment", "Are you sure?", false, {
          Payload,
          context,
          PREPAYMENTOBJECT: context.PREPAYMENTOBJECT,
        })
        .then((value) => {
          
          if (value) {
            value.context.$wait.start("createpayment");
            value.context
              .refundpayment({
                router: value.context.router,
                payload: value.Payload,
                _Operator: value.PREPAYMENTOBJECT.Operator,
                _Taxis: value.PREPAYMENTOBJECT.Taxis,
                _Drivers: value.PREPAYMENTOBJECT.Drivers,
              })
              .then((_data) => {
                value.context.reloadInfo(value.context);
                value.context.reset(value.context);
                value.context.paymentCreated = true;
                value.context.printReady = true;
                value.context.prepayment({
                  router: value.context.router,
                  PrePayment: undefined,
                });
                value.context.PrePayment = false;
                value.context.PREPAYMENTOBJECT = undefined;
                value.context.$wait.end("createpayment");
                value.context.$Notify.Success("Successfully Added!", "");
              })
              .catch((error) => {
                value.context.$wait.end("createpayment");
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    createpayment: (context) => {
      
      let _Operator = [];
      try {
        _Operator = $(".default-selectOperator").select2("data");
      } catch (e) {}
      let _Taxis = [];
      try {
        _Taxis = $(".default-selectTaxi").select2("data");
      } catch (e) {}
      let _Drivers = [];
      try {
        _Drivers = $(".default-selectTaxiDrivers").select2("data");
      } catch (e) {}

      if (
        _Operator.length === 0 &&
        _Taxis.length === 0 &&
        _Drivers.length === 0
      )
        return context.$Notify.Error(
          "Warning",
          "Please choose Operator, Taxi And Driver"
        );


        if (context.TOTALMOUNT == 0) {
           return context.$Notify.Error(
          "Warning",
          "Please Enter Amount"
        );
        }


      const paymentdetails = {
        paymentamount: context.PAYMENTAMOUNT,
        transactionfee: context.TRANSACTIONFEE,
        totalamount: context.TOTALMOUNT,
      };

      const _converteddate = context.$Toolkit.ConvertToServerDate(
        $("#datepicker-disabled-past").datepicker("getDate")
      );

      const Payload = {
        paymentdetails,
        linetotal: context.TOTALMOUNT,
        comment: context.COMMENT,
        reference: "Refund Captured By " + context.LoggedInUserFullName,
        added_by: context.USERID,
        captured_by: context.USERID,
        payment_date: _converteddate,
        taxi_association_id: context.taxiassociation_id,
      };

      

      const Operators = [];
      const Taxis = [];
      const Drivers = [];

      _Drivers.forEach(function (_item, index) {
        Drivers.push(_item.id);
      });

      _Taxis.forEach(function (_item, index) {
        Taxis.push(_item.id);
      });

      _Operator.forEach(function (_item, index) {
        Operators.push(_item.id);
      });

      context.$Notify
        .YesNoMore("Refund Payment", "Are you sure?", false, {
          Payload,
          context,
          Operators,
          Taxis,
          Drivers,
        })
        .then((value) => {
          if (value) {
            

            value.context.$wait.start("createpayment");
            // value.router = value.context.$router;
            value.context.$store
              .dispatch("payments/refundpayment", {
                router: value.context.$router,
                payload: value.Payload,
                _Operator: value.Operators,
                _Taxis: value.Taxis,
                _Drivers: value.Drivers,
              })
              .then((_data) => {
                
                value.context.$Notify.Success(
                  "Refund Waiting For Approval!",
                  ""
                );
                value.context.reloadInfo(value.context);
                value.context.reset(value.context);
                value.context.paymentCreated = true;
                // value.context.printReady = true;
                value.context.$wait.end("createpayment");
                
              })
              .catch((error) => {
                context.$Notify.Error(error.data[0].message, "");
                value.context.$wait.end("createpayment");
              })
              .finally(() => {});
          }
        });
        
    },
  },
  mounted: function () {
    App.init();

    // Jquery Dependency

    //Get Data
    // $('#mySelect2').select2('data');

    $("#datepicker-disabled-past").datepicker({
      todayHighlight: true,
      autoclose: true,
    });

    $("#datepicker-disabled-past").datepicker("update", new Date());

    $(".nav-link").click(() => {
      setTimeout(() => {
        $(".default-select").select2({
          multiple: false,
            placeholder: "Select A Taxi",
            allowClear: false,
        });
        $(".default-selectOperator").val(null).trigger("change");
        $(".default-selectTaxi").val(null).trigger("change");
        $(".default-selectTaxiDrivers").val(null).trigger("change");
      }, 500);
    });

    Inputmask().mask(document.querySelectorAll(".inputmask"));

    $("#cash").focus();

    if (this.PrePayment) {
      this.createprepayment(this.context);
    }

    this.$store
      .dispatch("TA/getLOGOURL", {
        id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id,
      })
      .then((result) => {
        this.context.encodedImage = result;
      })
      .catch((err) => {});
  },
};
</script>

<style scoped>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
.logo-image {
  width: 200px;
  height: 200px;
}
</style>

