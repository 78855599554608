<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Money'"
      :ActiveMenu="'Cashup'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Cashup"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Money"
        breadcrumbitem3="Cashup"
      />
      <!-- begin nav-pills -->

      <!-- Modal for verification -->
      <div class="modal fade" id="modal-verify">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Verify Cashup Session</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
              <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
                <div class="form-group">
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Amount</label>
                    <div class="col-md-9">
                      <CurrencyInput
                        v-model="VERIFYCASHUP.amount_collected"
                        :currency="currency"
                        :auto-decimal-mode="true"
                        data-parsley-required="true"
                        data-parsley-group="verifysession"
                        class="form-control form-control-lg"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="form-group row m-b-10">
                      <label class="col-md-3 text-md-right col-form-label">Comments</label>
                      <div class="col-md-9">
                        <textarea
                          class="form-control"
                          data-parsley-required="false"
                          data-parsley-group="verifysession"
                          id="clipboard-textarea2"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
              <button
                type="button"
                class="btn btn-sm btn-success width-80"
                @click="verifySession(context,VERIFYCASHUP)"
                :disabled="$wait.is('add')"
              >
                <v-wait v-bind:for="'add'">
                  <template slot="waiting">
                    <i class="fas fa-spinner fa-spin"></i>
                  </template>
                  Verify
                </v-wait>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal for verification -->

      <div class="row">
              <!-- begin col-3 -->
              <div class="col-lg-2 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  
                  <div class="stats-content">
                    <div class="stats-title">TOTAL CASHUP BALANCE</div>
                    <div class="stats-number">{{ TOTALCASHUPBAL | newcurrencywithSymbol}}</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-2 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  
                  <div class="stats-content">
                    <div class="stats-title">TODAY'S BALANCE</div>
                    <div class="stats-number">{{ TODAYSBALANCE | newcurrencywithSymbol}}</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="far fa-money-bill-alt"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">THIS WEEK'S BALANCE</div>
                    <div class="stats-number">
                      {{ THISWEEKSBALANCE | newcurrencywithSymbol}} / 
                      {{ THISEXPCTEDWEEKSBALANCE | newcurrencywithSymbol}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="far fa-money-bill-alt"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">THIS MONTH'S BALANCE</div>
                    <div class="stats-number">
                      {{ THISMONTHSBALANCE | newcurrencywithSymbol}} / 
                      {{ THISEXPCTEDMONTHSBALANCE | newcurrencywithSymbol}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6" v-show="TOTALCASHBACKBALANCE>0">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="far fa-money-bill-alt"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">CASHBACK BALANCE</div>
                    <div class="stats-number">{{ TOTALCASHBACKBALANCE | newcurrencywithSymbol }}</div>
                  </div>
                </div>
              </div>

             
              </div>
              
      <!-- begin nav-pills -->
      <ul class="nav nav-pills">
        <li class="nav-items">
          <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active">
            <span class="d-sm-none">Sessions
              <div
              v-show="ISLOADING"
              class="spinner-border spinner-border-lg text-info"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            </span>
            <span class="d-sm-block d-none">Sessions
              <div
              v-show="ISLOADING"
              class="spinner-border spinner-border-lg text-info"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            </span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">Awaiting Verification</span>
            <span class="d-sm-block d-none">Awaiting Verification</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-3" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">Awaiting Approval</span>
            <span class="d-sm-block d-none">Awaiting Approval</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-4" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">Approved</span>
            <span class="d-sm-block d-none">Approved</span>
          </a>
        </li>
      </ul>
      <!-- end nav-pills -->

      <!-- begin tab-content -->
      <div class="tab-content">
        <!-- begin tab-pane -->
        <div class="tab-pane fade active show" id="nav-pills-tab-1">
          <!-- begin widget-table -->

          <div class="table-responsive">
            <table class="table table-invoice">
              <thead>
                <tr>
                  <th width="10%">Collector</th>
                  <th>Contact No</th>
                  <th>Start Date</th>

                  <th>Status</th>

                  <th>Total Received</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in GETCASHUP"
                  v-bind:key="item.collector_id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                  <td>{{item.collector_firstname +' '+item.collector_surname}}</td>
                  <td>
                    <div>{{item.collector_mobile_number}}</div>
                  </td>
                  <td>{{item.collection_start_date | formatToShortDateWithTime}}</td>

                  <td>
                    <div class="clearfix f-s-10">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item.deletedby }"
                        v-if="item.deletedby"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.collection_is_active, 'label-secondary':!item.collection_is_active }"
                        v-if="!item._deleted_by"
                      >{{item.collection_is_active == true ? 'Active' : 'Disabled' }}</span>
                    </div>
                  </td>

                  <td>{{item.collection_total_receipts_amount | newcurrencywithSymbol}}</td>
                  <td>
                    <button
                      class="btn btn-sm btn-success width-120 m-r-2"
                      v-if="!item.collection_is_active"
                      @click="startSession(context,item,USERPROFILE.system_usersid)"
                      :disabled="$wait.is('startSession-' + item.collector_id)"
                    >
                    
                    <v-wait v-bind:for="'startSession-' + item.collector_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Start Session
                        </v-wait>
                  </button>
                    <button
                      class="btn btn-sm btn-danger width-120 m-r-2"
                      v-if="item.collection_is_active"
                      :disabled="$wait.is('stopSession-' + item.collector_id)"
                      @click="stopSession(context,item,USERPROFILE.system_usersid)"
                    >
                    <v-wait v-bind:for="'stopSession-' + item.collector_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Stop Session
                        </v-wait>
                  </button>
                    <router-link
                      :to="{ name: 'cashupdetails', params: { collectorid: item.assigned_collectors_officer_id,startdate: item.collection_start_date, enddate: item.collection_start_date,activesession:true }}"
                      class="btn btn-info btn-sm width-120"
                    >View Details</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- end tab-pane -->

        <!-- begin tab-pane -->
        <div class="tab-pane fade" id="nav-pills-tab-2">
          <div class="table-responsive">
            <table class="table table-invoice">
              <thead>
                <tr>
                  <th width="10%">Collector</th>
                  <th>Conatct No</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Status</th>
                  <th>Total Payments</th>
                  <th>Session Started By</th>
                  <th>Session Ended By</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :class="{'success': item.verifieddate != null}"
                  v-for="(item) in PENDINGCASHUP"
                  v-bind:key="item.id"
                >
                  <td>{{item.assigned_collectors_officer_firstname +' '+item.assigned_collectors_officer_surname}}</td>
                  <td>
                    <div>{{item.collector_mobile_number}}</div>
                  </td>
                  <td>{{item.collection_start_date | formatToShortDateWithTime}}</td>
                  <td>{{item.collection_end_date | formatToShortDateWithTime}}</td>

                  <td>
                    <div class="clearfix f-s-10">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item.deletedby }"
                        v-if="item.deletedby"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.collection_is_active, 'label-secondary':!item.collection_is_active}"
                        v-if="!item._deleted_by"
                      >{{item.collection_is_active == true ? 'Active' : 'Disabled' }}</span>
                    </div>
                  </td>

                  <td>{{item.collection_total_receipts_amount | newcurrencywithSymbol}}</td>
                  <td>{{item.added_firstname == null ? ' N/A' : item.added_firstname+' '+item.added_surname == null ? '' : item.added_surname}}</td>
                  <td>{{item.updated_firstname == null ? ' N/A' : item.updated_firstname +' '+item.updated_surname == null ? '' : item.updated_surname}}</td>
                  <td>
                    <a
                      class="btn btn-success btn-sm width-100 rounded-corner"
                      @click="verifyCashUp(context, item)"
                      v-if="item.verifieddate == null"
                    >Verify</a>
                    <button
                      v-if="item.verifieddate != null"
                      type="button"
                      class="btn btn-sm btn-danger width-100 rounded-corner"
                      disabled
                    >Verified</button>

                    <button
                      class="btn btn-success btn-sm width-100 rounded-corner"
                      href="#modal-approve"
                      data-toggle="modal"
                      v-if="item.verified_date != null"
                    >Approve</button>
                    <a
                      href="#modal-decline"
                      class="btn btn-danger btn-sm width-100 rounded-corner"
                      data-toggle="modal"
                      v-if="item.verified_date != null"
                    >Reject</a>
                    <router-link
                      :to="{ name: 'cashupdetails', params: { collectorid: item.assigned_collectors_officer_id,startdate: item.collection_start_date, enddate: item.collection_end_date,activesession:false }}"
                      class="btn btn-info btn-sm width-100 rounded-corner"
                    >View Details</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- end tab-pane -->
        <!-- begin tab-pane -->
        <div class="tab-pane fade" id="nav-pills-tab-3">
          <div class="table-responsive">
            <table class="table table-invoice">
              <thead>
                <tr>
                  <th width="10%">Collected By</th>
                  <th>Conatct No</th>
                  <th>Verified By</th>
                  <th>Verified Date</th>
                  <th>Status</th>
                  <th>Verified Amount</th>
                  <th>Session Started By</th>
                  <th>Session Ended By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item) in PENDINGAPPROVAL" v-bind:key="item.id">
                  <td>{{item.assigned_collectors_officer_firstname +' '+item.assigned_collectors_officer_surname}}</td>
                  <td>
                    <div>{{item.collector_mobile_number}}</div>
                  </td>
                  <td>{{item.verified_firstname}} {{item.verified_surname}}</td>
                  <td>{{item.verifieddate | formatToShortDateWithTime}}</td>

                  <td>
                    <div class="clearfix f-s-10">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item.deletedby }"
                        v-if="item.deletedby"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.collection_is_verified, 'label-secondary':!item.collection_is_verified}"
                        v-if="!item._deleted_by"
                      >{{item.collection_is_verified == true ? 'Verified' : 'Disabled' }}</span>
                    </div>
                  </td>

                  <td>{{item.collection_total_receipts_amount | newcurrencywithSymbol}}</td>
                  <td>{{item.added_firstname == null ? ' N/A' : item.added_firstname+' '+item.added_surname == null ? '' : item.added_surname}}</td>
                  <td>{{item.updated_firstname == null ? ' N/A' : item.updated_firstname +' '+item.updated_surname == null ? '' : item.updated_surname}}</td>
                  <td>
                    <!-- <button
                        v-if="item.verifieddate != null"
                        type="button"
                        class="btn btn-sm btn-danger width-100 rounded-corner"
                        disabled
                    >Verified</button>-->
                    <button
                      class="btn btn-warning btn-sm width-100 rounded-corner"
                      @click="getCashup(context,item)"
                      v-if="item.verifieddate != null"
                    >Approve</button>
                    <!-- <a
                      class="btn btn-danger btn-sm width-100 rounded-corner"
                      data-toggle="modal"
                      v-if="item.verifieddate != null"
                    >Reject</a>-->
                    <router-link
                      :to="{ name: 'cashupdetails', params: { collectorid: item.assigned_collectors_officer_id,startdate: item.collection_start_date, enddate: item.collection_end_date,activesession:false }}"
                      class="btn btn-info btn-sm width-100 rounded-corner"
                    >View Details</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- end tab-pane -->

        <!-- begin tab-pane -->
        <div class="tab-pane fade" id="nav-pills-tab-4">
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-invoice" id="dtApproved">
              <thead>
                <tr>
                  <th width="10%">Collector</th>
                  <th>Conatct No</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Started By</th>
                  <th>Ended By</th>
                  <th>Verified By</th>
                  <th>Verified Date</th>
                  <th>Approved By</th>
                  <th>Approved Date</th>
                  <th>Total Payments</th>
                  <th>Total Payments Verified</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in APPROVEDCASHUP"
                  v-bind:key="item.id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                  <td>{{item.collector_firstname == null ? ' N/A' : item.collector_firstname +' '+item.collector_surname == null ? ' N/A' : item.collector_surname}}</td>
                  <td>
                    <div>{{item.collector_mobile_number}}</div>
                  </td>
                  <td>{{item.collection_start_date | formatToShortDateWithTime}}</td>
                  <td>{{item.collection_end_date | formatToShortDateWithTime}}</td>
                  <td>{{item.added_firstname == null ? ' N/A' : item.added_firstname+' '+item.added_surname == null ? '' : item.added_surname}}</td>
                  <td>{{item.updated_firstname == null ? ' N/A' : item.updated_firstname +' '+item.updated_surname == null ? '' : item.updated_surname}}</td>
                  <td>{{item.verified_firstname == null ? ' N/A' : item.verified_firstname +' '+item.verified_surname == null ? '' : item.verified_surname}}</td>
                  <td>{{item.verifieddate}}</td>
                  <td>{{item.approved_firstname == null ? ' N/A' : item.approved_firstname +' '+item.approved_surname == null ? '' : item.approved_surname}}</td>
                  <td>{{item.approvaldate}}</td>
                  <td>{{item.collection_total_receipts_amount}}</td>
                  <td>{{item.collection_total_amount_received}}</td>
                  <td class="with-btn valign-middle" nowrap>
                    <router-link
                      :to="{ name: 'cashupdetails', params: { collectorid: item.assigned_collectors_officer_id,startdate: item.collection_start_date, enddate: item.collection_end_date,activesession:false }}"
                      class="btn btn-info btn-sm width-120"
                    >Details</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- end tab-pane -->
        <!-- begin tab-pane -->
      </div>
      <!-- end tab-content -->

      <!-- Add Modal HTML START-->
      <div id="ApproveModal" class="modal fade">
        <div class="modal-dialog">
          <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Approve Cash Up</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <!-- begin form-group row -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-2 col-form-label text-md-right">
                      Collected By:
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-7">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        v-model="collected_by"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-2 col-form-label text-md-right">
                      Date Verified:
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-7">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        :value="verifieddate | formatToShortDate"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-2 col-form-label text-md-right">
                      Amount Collected:
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-7">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        v-model="amount_collected"
                      />
                    </div>
                  </div>
                  <!-- end form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-2 col-form-label text-md-right">
                      Comments:
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-7">
                      <textarea
                        placeholder="Type your comments"
                        class="form-control m-b-10"
                        id="clipboard-textarea"
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                  <!-- end form-group -->
                </div>
              </div>
              <div class="modal-footer">
                <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
                <button
                  class="btn btn-primary m-5"
                  @click="ApproveVerifiedCashUp(context,APPROVECASHUP)"
                >Approve</button>
              </div>
          </div>
        </div>
      </div>
      <!-- Add Modal HTML START-->
    </div>
  </div>
</template>

<script>
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("cashup");

export default {
  name: "AuthorizeApprovalRequest",
  data: function () {
    return {
      currencySymbol: "R",
      position: "prefix",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      verified_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
      rejected_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
      added_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
      geolocation: {
        latitude: null,
        longitude: null,
      },
      GETSELECTED: Object.assign({}, this.PENDINGAPPROVAL),
      collected_by: "",
      verifieddate: "",
      PENDINGCASHUP: [],
      APPROVEDCASHUP: [],
      PENDINGAPPROVAL: [],
      GETCASHUP: [],
      amount_collected: 0,
      VERIFYCASHUP:{
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
          cashup_id: "",
          amount_collected: 0,
          comments: "",
          verifiedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      APPROVECASHUP: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        cashup_id: "",
        comments: "",
        approvedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      context: this,
      ISLOADING: false,
      addedsuccess: undefined,
      errormessage: undefined,
      myDate: "",
      skip: 0,
      len: 100000,
    };
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters({}),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
    currency() {
      return { [this.position]: this.currencySymbol };
    },
    TODAYSBALANCE: function(){
      if(this.GETCASHUP.length === 0) {
        return 0
      }  else {
        return this.GETCASHUP[0].today_cashup
      }
    },
    THISWEEKSBALANCE: function(){
      if(this.GETCASHUP.length === 0) {
        return 0
      }  else {
        return this.GETCASHUP[0].week_cashup
      }
    },
    THISMONTHSBALANCE: function(){
      if(this.GETCASHUP.length === 0) {
        return 0
      }  else {
        return this.GETCASHUP[0].month_cashup
      }
    },
    THISEXPCTEDWEEKSBALANCE: function(){
      if(this.GETCASHUP.length === 0) {
        return 0
      }  else {
        return this.GETCASHUP[0].weekexp_cashup
      }
    },
    THISEXPCTEDMONTHSBALANCE: function(){
      if(this.GETCASHUP.length === 0) {
        return 0
      }  else {
        return this.GETCASHUP[0].monthexp_cashup
      }
    },
    TOTALCASHUPBAL: function(){
      let sum = 0;
      this.GETCASHUP.forEach(function(item) {
        if(item.collection_total_receipts_amount)
         sum += (parseFloat(item.collection_total_receipts_amount));
      });

     return sum;
   },
   TOTALCASHBACKBALANCE: function(){

      let sum = 0;
      this.GETCASHUP.forEach(function(item) {
        if(item.collection_total_cashback_amount)
         sum += (parseFloat(item.collection_total_cashback_amount));
      });

     return sum;
   }
  },
  methods: {
    ...mapActions([
      "getActiveCashup",
      "getCashupByTaxiAssId",
      "getApprovedCashup",
      "getPendingCashup",
      "getPendingApproval",
      "startCashup",
      "executeVerify",
      "executeReject",
    ]),

    setMyDateToToday() {
      this.myDate = new Date();
    },
    addADayToMyDate() {
      if (this.myDate)
        // as myDate can be null
        // you have to set the this.myDate again, so vue can detect it changed
        // this is not a caveat of this specific solution, but of any binding of dates
        this.myDate = new Date(this.myDate.setDate(this.myDate.getDate() + 1));
    },

    getCashup: (context, item) => {
      console.log("cash up session: ", item);
      context.collected_by = `${item.collector_firstname} ${item.collector_surname}`;
      context.verifieddate = item.verifieddate;
      context.APPROVECASHUP.cashup_id = item.collection_session_id;
      context.amount_collected =
        item.collection_total_receipts_amount;

      $("#ApproveModal").modal("show");
    },

    ApproveVerifiedCashUp: (context, item) => {
      
      

      context.$Notify.YesNo(false, { context, item }).then((value) => {
        
        if (value) {
          // value.context.$wait.start("approve-");

          value.item.comments = document.getElementById(
        "clipboard-textarea"
      ).value;
          value.router = value.context.$router;
          value.context.$store
            .dispatch("cashup/ApproveVerifiedCashUp", {
              router: value.router,
              AddModel: item,
            })
            .then((data) => {
              $("#ApproveModal").modal("hide");
              // value.context.$wait.end("approve-");
              value.context.$Notify.Success("Approved Successfully!", "");
            })
            .catch((error) => {
              console.log("start session errors: ", error);
              // value.context.$wait.end("approve-");

              value.context.$Notify.Error(error.data.data.message, "");
              $("#ApproveModal").modal("hide");
            })
            .finally(() => {});
        }
      });
    },
    activeCashup: (context, increment) => {
      context.ISLOADING = true
      if (increment) context.skip += 0;
      else {
        if (context.skip > 0) context.skip -= 10;
      }

      context
        .getCashupByTaxiAssId({
          taxiassociationid: context.taxiassociationid,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          context.GETCASHUP = data;
          console.log("active Cash Up data Found :", data);
        })
        .catch((error) => {
          console.log("active cash up Errors: ", error);
        }).finally(() => {
          context.ISLOADING = false
        });
    },
    approvedCashup: (context, increment) => {
      if (increment) context.skip += 0;
      else {
        if (context.skip > 0) context.skip -= 10;
      }

      context
        .getApprovedCashup({
          status: context.taxiassociationid,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          context.APPROVEDCASHUP = data;
        })
        .catch((error) => {});
    },

    pendingCashup: (context, increment) => {
      if (increment) context.skip += 0;
      else {
        if (context.skip > 0) context.skip -= 10;
      }

      context
        .getPendingCashup({
          status: context.taxiassociationid,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          
          console.log("pending verification: ", data);
          context.PENDINGCASHUP = data;
        })
        .catch((error) => {
          console.log("collectors errors: ", error);
        });
    },

    pendingApprovalCashup: (context) => {
      context
        .getPendingApproval({
          status: context.taxiassociationid,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          
          context.PENDINGAPPROVAL = data;
        });
    },
    startSession: (context, item, added_by) => {
      
      (item.taxiassociationid = context.taxiassociationid),
        (item.addedby = added_by);

      item.geolocation = context.geolocation;

      context.$Notify.YesNo(false, { context, item }).then((value) => {
        
        if (value) {
          value.context.$wait.start("startSession-" + value.item.collector_id);
          value.router = value.context.$router;
          value.context.$store
            .dispatch("cashup/startCashup", {
              _dataObj: value,
            })
            .then((data) => {
              console.log(
                "session statred: ",
                data[0].fn_collector_cashup_start
              );
              $("#AddModal").modal("hide");
              value.context.$wait.end("startSession-" + value.item.collector_id);

              value.item.collection_is_active = true;

              value.context.$Notify.Success("Session Succesfully Started!", "");
            })
            .catch((error) => {
              console.log("start session errors: ", error);
              value.context.$wait.end("startSession-" + value.item.collector_id);

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    stopSession: (context, item, ended_by) => {
      item.taxiassociation_id = context.taxiassociationid;
      item.updatedby = ended_by;
      item.geolocation = context.geolocation;

      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          value.context.$wait.start("stopSession-" + value.item.collector_id);
          value.router = value.context.$router;
          value.context.$store
            .dispatch("cashup/endCashup", {
              _dataObj: value,
            })
            .then((data) => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("stopSession-" + value.item.collector_id);

              var index = value.context.GETCASHUP.indexOf(value.item);
              value.context.GETCASHUP.splice(index, 1);

              value.item.collection_is_active = false;

              value.context.$Notify.Success("Session Succesfully Ended", "");
            })
            .catch((error) => {
              value.context.$wait.end("stopSession-" + value.item.collector_id);

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    verifyCashUp: (context, item) => {
      
      console.log("cash up details: ",item);
      context.VERIFYCASHUP.cashup_id =  item.collection_session_id
      context.VERIFYCASHUP.amount_collected = 0;
      document.getElementById("clipboard-textarea2").value = "";
      // context.GETSELECTED = item;
      $("#modal-verify").modal("show");
    },

    verifySession: (context, item) => {
      
      var res = $('form[name="form-wizard"]')
        .parsley()
        .validate("verifysession");
      if (!res) return res;
        context.$Notify.YesNo(false, { context, item }).then((value) => {
          if (value) {
            value.item.comments = document.getElementById(
        "clipboard-textarea2"
      ).value;
            value.context.$wait.start("add");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("cashup/executeVerify", {
                router: value.router,
                AddModel: value.item
              })
              .then(() => {
                
                $("#modal-verify").modal("hide");
                value.context.$Notify.Success("Session Has Been Verified!", "");
                value.context.$wait.end("add");
                
                value.context.pendingCashup(value.context, true);
              })
              .catch((error) => {
                value.context.$wait.end("add");

                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },

    declineverify: (context, item) => {
      context.GETSELECTED = item;
      $("#modal-decline").modal();
    },

    declineSession: (context, item) => {
      var res = $('form[name="form-wizard2"]')
        .parsley()
        .validate("declineverify");
      if (!res) return res;
      (item.taxiassociation_id = context.taxiassociationid),
        (item.rejectedby = context.rejected_by),
        context.$Notify.YesNo(false, { context, item }).then((value) => {
          if (value) {
            value.context.$wait.start("add");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("cashup/executeReject", {
                _dataObj: value,
              })
              .then(() => {
                $("#AddModal").modal("hide");
                value.context.$wait.end("add");
                value.context.$Notify.Success("Session Has Been Declined!", "");
              })
              .catch((error) => {
                value.context.$wait.end("add");

                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
  },
  beforeMount() {
    this.activeCashup(this.context, true);
    this.approvedCashup(this.context, true);
    this.pendingCashup(this.context, true);
    this.pendingApprovalCashup(this.context);
  },
  mounted: function () {
    App.init();

    $(document).data("context", this);

    setTimeout(function(){
      var context = $(document).data("context");
      context.activeCashup(context, true);
      context.approvedCashup(context, true);
      context.pendingCashup(context, true);
      context.pendingApprovalCashup(context);
    }, 60000);

    

    $(document).ready(function () {
      $("#dtSession").DataTable();
      $("#dtApproved").DataTable();
      $("#dtPending").DataTable();
      $(".dataTables_length").addClass("bs-select");
    });
  },
};
</script>