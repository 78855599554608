/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';


export default {
  namespaced: true,
  state: {
    lookupTitles: [],
    lookupGender: [],
    LookupUserGroups: [],
    LookupDocumentTypes: [],
    LookupSuburbs:[],
    LookupDistricts:[],
    LookupCountry:[],
    LookupProvinces:[],
    LookupCity:[],
    VehicleEnginetype: [],
    VehicleTransmisionType: [],
    VehicleColor: [],
    TaxiAssociationRoutes:[],
    LookupSupportingDocuments:[],
    LookupTimelineHistory:[],
    LookupPhysicalAddress: [],
    LookupPostalAddress: [],
    lookupDriverStatus: [],
    lookupDriverStatusById: [],
    lookupSystemUsers: [],
    lookupByBillingTypes: [],
    lookupAddonServiceTypes: [],
    lookupTypeOfBillingClients: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETALLTITLES: state => state.lookupTitles,
    GETALLGENDERS: state => state.lookupGender,
    GETALLUSERGROUPS: state => state.LookupUserGroups,
    GETALLDOCUMENTTYPE: state => state.LookupDocumentTypes,
    GETALLSUBURBS: state => state.LookupSuburbs,
    GETALLDISTRICTS: state => state.LookupDistricts,
    GETALLDISTRICTSBYTAXIFILICATION: (state, id) => 
    {
      return state.LookupDistricts.filter(item => item.taxi_affiliation_id === id);
    },
    GETALLCOUNTRY: state => state.LookupCountry,
    GETALLPROVINCES: state => state.LookupProvinces,
    GETALLCITY: state => state.LookupCity,
    GETALLENGINETYPES: state => state.VehicleEnginetype,
    GETALLTRANSMISIONTYPES: state => state.VehicleTransmisionType,  
    GETALLCOLORS: state => state.VehicleColor,
    GETALLTAXIROUTES: state=> state.TaxiAssociationRoutes,
    GETSUPPORTINGDOCUMENTS:  state=> state.LookupSupportingDocuments,
    GETTIMELINEHISTORY: state=> state.LookupTimelineHistory,
    GETPHYSICALADDRESS: state=> state.LookupPhysicalAddress,
    GETPOSTALADDRESS: state => state.LookupPostalAddress,
    GETDRIVERSTATUS: state => state.lookupDriverStatus,
    GETSYSTEMUSERS: state => state.lookupSystemUsers,
    GETBILLINGTYPES: state => state.lookupByBillingTypes,
    GETADDONSERVICETYPES: state => state.lookupAddonServiceTypes,
    GETTYPEOFBILLINGCLIENT: state => state.lookupTypeOfBillingClients,
    GETCOLLECTIONSETTINGS: state => state.lookupCollectionSettings,
    GETDRIVERSTATUSBYID: state => state.lookupDriverStatusById,
  },
  mutations: {
    LOADEDTITLES: (state, _data) => {
      if (_data !== undefined) {
        state.lookupTitles = _data;
      }
    },
    LOADEDGENDER: (state, _data) => {
      if (_data !== undefined) {
        state.lookupGender = _data;
      }
    },
    LOADEDUSERGROUPS: (state, _data) => {
      if (_data !== undefined) {
        state.LookupUserGroups = _data;
      }
    },
    LOADEDDOCUMENTTYPE: (state, _data) => {
      if (_data !== undefined) {
        state.LookupDocumentTypes = _data;
      }
    },
    LOADEDSUBURBS: (state, _data) => {
      if (_data !== undefined) {
        state.LookupSuburbs = _data;
      }
    },
    LOADEDSTATUSBYID: (state, _data) => {
      if (_data !== undefined) {
        state.lookupDriverStatusById = _data;
      }
    },
    LOADEDPHYSICALADDRESS: (state, _data) => {
      if (_data !== undefined) {
        state.LookupPhysicalAddress = _data;
      }
    },
    LOADEDPOSTALADDRESS: (state, _data) => {
      if (_data !== undefined) {
        state.LookupPostalAddress = _data;
      }
    },
    LOADEDDISTRICTS: (state, _data) => {
      if (_data !== undefined) {
        state.LookupDistricts = _data;
      }
    },
    LOADEDCOUNTRIES: (state, _data) => {
      if (_data !== undefined) {
         state.LookupCountry = _data;
        }
      },
     LOADEDPROVINCES: (state, _data) => {
       if (_data !== undefined) {
         state.LookupProvinces = _data;
       }
     },
     LOADEDCITY: (state, _data) => {
       if (_data !== undefined) {
         state.LookupCity = _data;
       }
     },
   LOADEDENGINE: (state, _data) => {
      if (_data !== undefined) {
        state.VehicleEnginetype = _data;
      }
    },
    LOADEDTRANSMISSION: (state, _data) => {
      if (_data !== undefined) {
        state.VehicleTransmisionType = _data;
      }
    },
    LOADEDCOLORS: (state, _data) => {
      if (_data !== undefined) {
        state.VehicleColor = _data;
      }
    },
    LOADEDTAXIROUTES: (state, _data) => {
      if (_data !== undefined) {
        state.TaxiAssociationRoutes = _data;
      }
    },
    LOADEDSUPPORTINGDOCUMENTS: (state, _data) => {
      if (_data !== undefined) {
        state.LookupSupportingDocuments = _data;
      }
    },
    LOADEDGETTIMELINEHISTORY: (state, _data) => {
      if (_data !== undefined) {
        state.LookupTimelineHistory = _data;
      }
    },
    
    LOADEDDRIVERSTATUS: (state, _data) => {
      if (_data !== undefined) {
        state.lookupDriverStatus = _data;
      }
    },
    LOADEDSYSTEMUSERS: (state, _data) => {
      if (_data !== undefined) {
        state.lookupSystemUsers = _data;
      }
    },
    LOADEDBILLINGTYPES: (state, _data) => {
      if (_data !== undefined) {
        state.lookupByBillingTypes = _data;
      }
    },
    LOADEDADDONSERVICETYPES: (state, _data) => {
      if (_data !== undefined) {
        state.lookupAddonServiceTypes = _data;
      }
    },
    LOADEDTYPEOFBILLINGCLIENTS: (state, _data) => {
      if (_data !== undefined) {
        state.lookupTypeOfBillingClients = _data;
      }
    },
    LOADEDCOLLECTONSETTINGS: (state, _data) => {
      if (_data !== undefined) {
        state.lookupCollectionSettings = _data;
      }
    },
 
  },
  actions: {
    /* Async */

    /* Auth.js Mutations */
    init({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router }) {
      return new Promise((resolve, reject) => {

        var count = 16;

        var response = {
          LOADEDCOUNTRIES: [],
          LOADEDTITLES: [],
          LOADEDDRIVERSTATUS: [],
          LOADEDGENDER: [],
          LOADEDUSERGROUPS: [],
          LOADEDDOCUMENTTYPE: [],
          LOADEDSYSTEMUSERS: [],
          LOADEDDISTRICTS: [],
          LOADEDPROVINCES: [],
          LOADEDENGINE: [],
          LOADEDTRANSMISSION: [],
          LOADEDCOLORS: [],
          LOADEDTAXIROUTES: [],
          LOADEDADDONSERVICETYPES: [],
          LOADEDBILLINGTYPES: [],
          LOADEDTYPEOFBILLINGCLIENTS: [],
          LOADEDCOLLECTONSETTINGS: [],
        };

       
          const requestpayload = {
            status: true,
            skip: 0,
            len: 1000,
            cache:true
          };

        let requestheader = {
          router,
         token: rootGetters['auth/TOKEN'],
          apiName: config.COUNTRY_URL,
          status:true,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_Countries) => {
          if (Result_Countries.status) {

          commit('auth/UPDATEPROGRESS', { PROGRESS: 2 , MESSAGE: 'Loading Driver Status' }, { root: true });

          response.LOADEDCOUNTRIES = Result_Countries.data;

          count = count - 1;
          if(count == 0){
            resolve(response);
          }
           //commit('LOADEDCOUNTRIES', Result_Countries.data);
           //resolve();
           // navigateTo(router, parsed_result.data[0]);
         }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
        reject(error);
        });

        requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.TITLE_URL,
        };
     
        this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_Title) => {
          if (Result_Title.status) {
            commit('auth/UPDATEPROGRESS', { PROGRESS: 2 , MESSAGE: 'Loading Title' }, { root: true });

            response.LOADEDTITLES = Result_Title.data;

            count = count - 1;
            if(count == 0){
              resolve(response);
            }

            //commit('LOADEDTITLES', Result_Title.data);
           
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });

      
        requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.DRIVERSTATUS_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_DriverStatus) => {
          if (Result_DriverStatus.status) {

            commit('auth/UPDATEPROGRESS', { PROGRESS: 5 , MESSAGE: 'Loading Gender' }, { root: true });

            response.LOADEDDRIVERSTATUS = Result_DriverStatus.data;

            count = count - 1;
            if(count == 0){
              resolve(response);
            }

            //commit('LOADEDDRIVERSTATUS', Result_DriverStatus.data);
           
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });

        requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.GENDER_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_Gender) => {
          if (Result_Gender.status) {

            commit('auth/UPDATEPROGRESS', { PROGRESS: 5 , MESSAGE: 'Loading User Group' }, { root: true });

            response.LOADEDGENDER = Result_Gender.data;

            count = count - 1;
            if(count == 0){
              resolve(response);
            }

            //commit('LOADEDGENDER', Result_Gender.data);
            
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });

        requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.USERGROUPS_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_Usergroups) => {
          if (Result_Usergroups.status) {
            commit('auth/UPDATEPROGRESS', { PROGRESS: 5 , MESSAGE: 'Loading Document Type' }, { root: true });

            response.LOADEDUSERGROUPS = Result_Usergroups.data;

            count = count - 1;
            if(count == 0){
              resolve(response);
            }

            //commit('LOADEDUSERGROUPS', Result_Usergroups.data);
            //resolve();
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });

        requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.DOCUMENTYPE_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_documenttype) => {
          if (Result_documenttype.status) {
            commit('auth/UPDATEPROGRESS', { PROGRESS: 5 , MESSAGE: 'Loading Physical Address' }, { root: true });

            response.LOADEDDOCUMENTTYPE = Result_documenttype.data;

            count = count - 1;
            if(count == 0){
              resolve(response);
            }

            //commit('LOADEDDOCUMENTTYPE', Result_documenttype.data);
            //resolve();
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });

        requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.PHYSICALADDRESS_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_physicaladdress) => {
        
          if (Result_physicaladdress.status) {
            //commit('auth/UPDATEPROGRESS', { PROGRESS: 5 , MESSAGE: 'Loading Postal Address' }, { root: true });
            commit('LOADEDPHYSICALADDRESS', Result_physicaladdress.data);
            //resolve();

            response.LOADEDPHYSICALADDRESS = Result_physicaladdress.data;

            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });

        requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.POSTALADDRESS_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_postaladdress) => {
          if (Result_postaladdress.status) {
            //commit('auth/UPDATEPROGRESS', { PROGRESS: 5 , MESSAGE: 'Loading Surburbs' }, { root: true });
            commit('LOADEDPOSTALADDRESS', Result_postaladdress.data);
            //resolve();

            response.LOADEDPOSTALADDRESS = Result_postaladdress.data;

            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });

        requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SUBURBS_URL,
          status:true,
          skip:'0',
          len:'100'
        };

        this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_Suburbs) => {
          if (Result_Suburbs.status) {
            //commit('LOADEDSUBURBS', Result_Suburbs.data);
            commit('auth/UPDATEPROGRESS', { PROGRESS: 5 , MESSAGE: 'Loading Surburbs' }, { root: true });

            response.LOADEDSUBURBS = Result_Suburbs.data;
           
            //resolve();
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });

        requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERS_URL,
          status:true,
          skip:'0',
          len:'10000'
        };

        this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_SystemUsers) => {
          if (Result_SystemUsers.status) {
            commit('auth/UPDATEPROGRESS', { PROGRESS: 5 , MESSAGE: 'Loading System Users' }, { root: true });
            //commit('LOADEDSYSTEMUSERS', Result_SystemUsers.data);

            response.LOADEDSYSTEMUSERS = Result_SystemUsers.data;

            count = count - 1;
            if(count == 0){
              resolve(response);
            }

            //resolve();
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });

        requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.DISTRICT_URL,
        };

        this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_District) => {
        
          if (Result_District.status) {
            commit('auth/UPDATEPROGRESS', { PROGRESS: 5 , MESSAGE: 'Loading Provinces' }, { root: true });
            //commit('LOADEDDISTRICTS', Result_District.data);

            response.LOADEDDISTRICTS = Result_District.data;

            count = count - 1;
            if(count == 0){
              resolve(response);
            }

            //resolve();
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject(error);
        });

        

       requestheader = {
         router,
         token: rootGetters['auth/TOKEN'],
         apiName: config.PROVINCE_URL,
       };
       this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_Provinces) => {
         if (Result_Provinces.status) {
          commit('auth/UPDATEPROGRESS', { PROGRESS: 5 , MESSAGE: 'Loading City' }, { root: true });

          response.LOADEDPROVINCES = Result_Provinces.data;

            count = count - 1;
            if(count == 0){
              resolve(response);
            }

           //commit('LOADEDPROVINCES', Result_Provinces.data);
           //resolve();
           // navigateTo(router, parsed_result.data[0]);
         }
       }).catch((error) => {
         // commit('LOGINFAILED', error.data);
         reject(error);
       });  

       requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.CITY_URL,
      };
      this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_cities) => {
        if (Result_cities.status) {
          commit('auth/UPDATEPROGRESS', { PROGRESS: 5 , MESSAGE: 'Loading Engine Type' }, { root: true });
          //commit('LOADEDCITY', Result_cities.data);
          //resolve();
          // navigateTo(router, parsed_result.data[0]);
          response.LOADEDCITY = Result_cities.data;

        }
      }).catch((error) => {
        // commit('LOGINFAILED', error.data);
        reject(error);
      }); 
      
      requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.ENGINETYPE_URL,
      };
      this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_Engine) => {
        if (Result_Engine.status) {
    
          commit('auth/UPDATEPROGRESS', { PROGRESS: 5 , MESSAGE: 'Loading Engine Transmission' }, { root: true });
          //commit('LOADEDENGINE', Result_Engine.data);

          response.LOADEDENGINE = Result_Engine.data;

            count = count - 1;
            if(count == 0){
              resolve(response);
            }
         
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        // commit('LOGINFAILED', error.data);
        reject();
      });

      requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.VEHICLETRANSMISION_URL,
      };

      this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_Transmission) => {
        if (Result_Transmission.status) {
          commit('auth/UPDATEPROGRESS', { PROGRESS: 5 , MESSAGE: 'Loading Vehicle Transmission' }, { root: true });
          //commit('LOADEDTRANSMISSION', Result_Transmission.data);

          response.LOADEDTRANSMISSION = Result_Transmission.data;

            count = count - 1;
            if(count == 0){
              resolve(response);
            }
          
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        // commit('LOGINFAILED', error.data);
        reject(error);
      });

      requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.VEHICLECOLOR_URL,
      };

      this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_Color) => {
        if (Result_Color.status) {
          commit('auth/UPDATEPROGRESS', { PROGRESS: 2.5 , MESSAGE: 'Loading COLORS' }, { root: true });
          //commit('LOADEDCOLORS', Result_Color.data);
          response.LOADEDCOLORS = Result_Color.data;

            count = count - 1;
            if(count == 0){
              resolve(response);
            }

          //resolve(Result_Color.data);
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        // commit('LOGINFAILED', error.data);
        reject(error);
      });

      requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TAXIASSOCIATIONROUTES_URL,
      };

      this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_Routes) => {
        if (Result_Routes.status) {
          commit('auth/UPDATEPROGRESS', { PROGRESS: 2.5 , MESSAGE: 'Loading Vehicle Models' }, { root: true });
          //commit('LOADEDTAXIROUTES', Result_Routes.data);

          response.LOADEDTAXIROUTES = Result_Routes.data;

            count = count - 1;
            if(count == 0){
              resolve(response);
            }
          
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        // commit('LOGINFAILED', error.data);
        reject(error);
      });

      requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TAXIASSOCIATIONADDONSERVICETYPE_URL,
      };

      this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_Routes) => {
        if (Result_Routes.status) {
          commit('auth/UPDATEPROGRESS', { PROGRESS: 2.5 , MESSAGE: 'Loading Add On Service Type' }, { root: true });

          response.LOADEDADDONSERVICETYPES = Result_Routes.data;

            count = count - 1;
            if(count == 0){
              resolve(response);
            }

          //commit('LOADEDADDONSERVICETYPES', Result_Routes.data);
          
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        // commit('LOGINFAILED', error.data);
        reject(error);
      });

      requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TAXIASSOCIATIONBILLINGTYPE_URL,
      };

      this._vm.$TAMS_API.getByPaging(requestheader, requestpayload).then((Result_Routes) => {
        if (Result_Routes.status) {
          commit('auth/UPDATEPROGRESS', { PROGRESS: 2.5 , MESSAGE: 'Loading Billing Type' }, { root: true });
          //commit('LOADEDBILLINGTYPES', Result_Routes.data);

          response.LOADEDBILLINGTYPES = Result_Routes.data;

            count = count - 1;
            if(count == 0){
              resolve(response);
            }
          
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        // commit('LOGINFAILED', error.data);
        reject(error);
      });

      requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.TYPEOFBILLINGCLIENT,
      };

      this._vm.$TAMSFIN_API.getByPaging(requestheader, requestpayload).then((Result_Routes) => {
        if (Result_Routes.status) {
          
          commit('auth/UPDATEPROGRESS', { PROGRESS: 1 , MESSAGE: 'Loading Type Billing Client' }, { root: true });
          //commit('LOADEDTYPEOFBILLINGCLIENTS', Result_Routes.data);

          response.LOADEDTYPEOFBILLINGCLIENTS = Result_Routes.data;

            count = count - 1;
            if(count == 0){
              resolve(response);
            }
          
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        // commit('LOGINFAILED', error.data);
        reject(error);
      });

      requestheader = {
        router,
        token: rootGetters['auth/TOKEN'],
        apiName: config.COLLECTIONSETTINGS,
      };

      this._vm.$TAMSFIN_API.getByPaging(requestheader, requestpayload).then((Result_Routes) => {
        if (Result_Routes.status) {
          commit('auth/UPDATEPROGRESS', { PROGRESS: 1 , MESSAGE: 'Loading Collection Settings' }, { root: true });
          //mcommit('LOADEDCOLLECTONSETTINGS', Result_Routes.data);

          response.LOADEDCOLLECTONSETTINGS = Result_Routes.data;

            count = count - 1;
            if(count == 0){
              resolve(response);
            }
          
          // navigateTo(router, parsed_result.data[0]);
        }
      }).catch((error) => {
        // commit('LOGINFAILED', error.data);
        reject(error);
      });
  
      
      //GET VEHICLE MAKE And Model
      dispatch('vehiclemodel/getall', { router }, { root: true }).then(() => {
        if(count == 0){
          resolve(response);
        }
      }).catch((error) => {
        // commit('LOGINFAILED', error.data);
        reject(error);
      });

      });
    },
  },
};
