<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'FuelManager'"
      :ActiveMenu="'AddFuelType '"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Add/Update Fuel Type"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Fuel Manager"
        breadcrumbitem3="Fuel Type"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

           <!-- begin nav-pills -->
      <ul class="nav nav-pills">
        <li class="nav-items">
          <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active nav-pills-tab-1">
            <span class="d-sm-none">Add Fuel Type</span>
            <span class="d-sm-block d-none">Add Fuel Type</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">View Fuel Type</span>
            <span class="d-sm-block d-none">View Fuel Type</span>
          </a>
        </li>
      </ul>
      <!-- end nav-pills -->

        <!-- begin tab-content -->
        <div class="tab-content">

      <div class="tab-pane fade active show" id="nav-pills-tab-1">
        <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Fuel Type
                      <small class="text-ellipsis">ULP 93 - https://www.fuelsa.co.za/</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-3">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Complete Registration</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->
                    <div class="row">
                      <div class="col-md-8 offset-md-2">
                        <legend
                          class="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-inverse"
                        >Enter Fuel Type Details</legend>
                        
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                          Fuel Description
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                                type="text"
                                data-parsley-required="true"
                                  data-parsley-group="step-1"
                                class="form-control"
                                placeholder="95 Unleaded Petrol"
                                v-model="ADDSYSTEMUSER.fueldescription"
                              >
                          </div>
                        </div>
                        <!-- end form-group -->

                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                           Fuel Price
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                                type="text"
                                data-parsley-required="true"
                                  data-parsley-group="step-1"
                                class="form-control"
                                placeholder="21.75"
                                v-model="ADDSYSTEMUSER.fuelprice"
                              >
                          </div>
                        </div>
                        <!-- end form-group -->

                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                          Vat Percentage
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                                type="text"
                                data-parsley-required="true"
                                  data-parsley-group="step-1"
                                class="form-control"
                                placeholder="15"
                                v-model="ADDSYSTEMUSER.vatpercentage"
                              >
                          </div>
                        </div>
                        <!-- end form-group -->

                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            Rebate Fee Percentage
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                                type="text"
                                data-parsley-required="true"
                                  data-parsley-group="step-1"
                                class="form-control"
                                placeholder="0.5"
                                v-model="ADDSYSTEMUSER.rebatefeepercentage"
                              >
                          </div>
                        </div>
                        <!-- end form-group -->

                      

                    
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                           Code
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                                type="text"
                                data-parsley-required="true"
                                  data-parsley-group="step-1"
                                class="form-control"
                                placeholder="ULP95"
                                v-model="ADDSYSTEMUSER.fuelcode"
                              >
                          </div>
                        </div>
                        <!-- end form-group -->

                      </div>

                 
                    </div>
                    <!-- end row -->
                  </fieldset>
                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->
              

                <!-- begin step-3 -->
                <div id="step-3">
                  <div class="jumbotron m-b-0 text-center">
                    <h2 class="text-inverse">Route Registered Successfully</h2>
                    <p class="m-b-30 f-s-16 ">
                     
                    </p>
                    <p>
                      <a href="#" class="btn btn-primary btn-lg hide">Proceed to User Profile</a>
                    </p>
                  </div>
                </div>
                <!-- end step-3 -->
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>
        <!-- end col-12 -->
      </div>
      </div>

      <!-- begin row -->

       <!-- begin tab-pane -->
       <div class="tab-pane fade" id="nav-pills-tab-2">
        <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <div class="table-responsive">
            <table class="table table-invoice">
              <thead>
                <tr>
                  <th>DESCRIPTION</th>
                  <th>FUEL PRICE</th>
                  <th>VAT</th>
                  <th>REBATE FEE</th>
                  <th>CODE</th>
                  <th class="text-nowrap">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item) in GETALL"
                  v-bind:key="item.id"
                >
                  <td>{{item.fueltypes_desc}}</td>
                  <td>{{item.fuel_price | newcurrencywithSymbol}}</td>
                  <td>{{item.vatpercentage | newcurrencywithSymbol}}</td>
                  <td>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.is_active, 'label-secondary':!item.is_active  }"
                      >{{item.rebatefeepercentage}}%</span>
                      </td>

                  <td>{{item.fueltypes_code}}</td>
                  <td class="with-btn valign-middle" nowrap>
                      <div class="btn-group" role="group" aria-label="Basic example">
                      <button v-if="!item.is_active && item.deleted_by===null" type="button" @click="EnableDisableAccount(context,item,true)" class="btn btn-danger"
                        :disabled="$wait.is('UpdateNextOfKinAction-' +item.id)">
                 <v-wait v-bind:for="'UpdateNextOfKinAction-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                   Delete
                  </v-wait>
                        
                    </button> 

                    <button v-if="item.is_active" type="button" @click="Edit(context,item)" class="btn btn-info"
                      :disabled="$wait.is('UpdateNextOfKinAction-' +item.id)"
                    >
                 <v-wait v-bind:for="'UpdateNextOfKinAction-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Edit
                  </v-wait>
                        
                    </button> 
                  </div>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
        </div>

      </div>
        <!-- end tab-pane -->

      

      <!-- end row -->

  

      <!-- begin #Main Body Template -->

      

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("fuelmanager_dashboard");

export default {
  name: "AddSystemUser",
  data: function() {
    return {
      BANKS:[],
      TPAYPROFILE: this.$store.getters["auth/TPAYPROFILE"],
      isUserNameExists: true,
      ISMOBILEVERIFIED:false,
      GETDRIVERSBYASSID:[],
      GETALL:[],
      ASSOCIATIONRANKS:[],
      ASSOCIATIONROUTES:[],
      OPT:"",
      USERNAME: "",
      GETALLTITLES: [],
      GETALLGENDERS: [],
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      SERPROFILE: this.$store.getters["auth/USERPROFILE"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      selectedUserGroupId: 0,
      GETALLUSERGROUPS: [],
      resolutionWidth: undefined,
      resolutionHeight: undefined,
      hacksmartwizard: false,
      SELECTEDRANK: null,
      SELECTEDTAMSRANK: null,
      EDITMODE:false,
      TAXIFARE: 0,
      ADDSYSTEMUSER: {
        taxi_association_id:this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
        fueldescription: "",
        vatpercentage: 15.00,
        fuelprice:0.00,
        rebatefeepercentage:0.00,
        fuelcode: "",
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
    },
    EDITSYSTEMUSER: {
        id:0,
        fueldescription: "",
        vatpercentage: 15.00,
        fuelprice:0.00,
        rebatefeepercentage:0.00,
        fuelcode: "",
        updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
    },
    VERIFIY: {
        user_id: "",
        webpushtoken: "",
        one_time_pin: "",
      },
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    CapturePhotosControl
  },
  computed: {
    ...mapGetters(["", ""])
  },
  watch: {
    USERNAME: function(val) {
      if (val.toString().length > 3) {
        this.context.$store
          .dispatch("tpayauth/LicenseNoExists", {
            licencenumber: val
          })
          .then(rb => {
            
            this.isUserNameExists = rb.Status;
          });
      }
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted"
    ]),
    init: file => {},
    loadLookUps(context) {
      
      context.$store
        .dispatch("lookups/init", {
          router: context.$router,
        })
        .then((data) => {
          console.log("loadLookUps found: ", data);
          
          context.GETALLTITLES = data.LOADEDTITLES;
          context.GETALLGENDERS = data.LOADEDGENDER;
        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
    Edit: (context,item) => {
      context.EDITSYSTEMUSER.id = item.id;
      context.ADDSYSTEMUSER.fueldescription = item.fueltypes_desc;
      context.ADDSYSTEMUSER.vatpercentage = item.vatpercentage;
      context.ADDSYSTEMUSER.fuelprice = item.fuel_price;
      context.ADDSYSTEMUSER.rebatefeepercentage = item.rebatefeepercentage;
      context.ADDSYSTEMUSER.fuelcode = item.fueltypes_code;
      context.EDITMODE = true;

      $("#wizard").smartWizard("prev");
      $(".sw-btn-next").text("Edit")
      $('.nav-pills-tab-1').click();
    },
    GetALLRoutesById: (context,id) => {
      context.$store
        .dispatch("fuelmanager_dashboard/getFuelTypeByTaxiAssId", {
          id,
          getbyid: context.USERID,
           router: context.$router,
        })
        .then((rb) => {

          

          context.GETALL = rb.data
          
        })
        .finally((error)=>{
          var options = {
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false,
                    order: [[0, 'asc']]
                  };

                  // if ($(window).width() <= 767) {
                  //   options.rowReorder = false;
                  //   options.colReorder = false;
                  //   options.autoFill = false;
                  // }

 	              $(".table-invoice").DataTable(options);
        })
    },
    getbyAssId: context => {
      context.$store
      .dispatch("tpay_systemuser/GetALLRANKSById", {
          id: context.taxi_association_id,
        })
        .then((data) => {
          context.ASSOCIATIONRANKS = data.Data;
          
        }).catch((error)=>{
          console.log("these are the getter errors:",error);
        })
        .finally((error)=>{
           $('#data-table-default').DataTable({
			responsive: true
		});
        })
    },
    getRoutes: context => {
      context.$store
      .dispatch("TA/getTaxiAssRoutes", {
        router: context.$router,
          id: context.taxi_association_id,
        })
        .then((data) => {
          context.ASSOCIATIONROUTES = data;
          
        }).catch((error)=>{
          console.log("these are the getter errors:",error);
        })
        .finally((error)=>{
           $('#data-table-default').DataTable({
			responsive: true
		});
        })
    },
  },
  mounted: function() {
    //this.get(this.context);

    App.init();

    this.getbyAssId(this.context);
    this.GetALLRoutesById(this.context,this.taxi_association_id);
    this.loadLookUps(this.context);

    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "slide",
      transitionSpeed: 400,
      useURLhash: false,
      showStepURLhash: false,
      toolbarSettings: {
        toolbarPosition: "bottom"
      }
    });

    $("#wizard").data("context", this.context);

    $("#wizard").on("leaveStep", function(
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {
     
      const context = $("#wizard").data("context");
      const currentstep = stepNumber + 1;
      var res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

        if (currentstep === 2 && stepDirection == "backward") {
       
                context.hacksmartwizard = false;
                 $(".sw-btn-next").text("Next") 
        }


        if(context.hacksmartwizard)
        return true;


      $(".sw-btn-next").text("Next");

      if (currentstep === 1 && stepDirection == "forward") {
        
        if (!res){
          return res;
        }
      
          $(".sw-btn-next").text("Submit");
          
      }

      

      
      if (currentstep === 1 && stepDirection == "forward") {

           const context = $("#wizard").data("context");

           if(context.EDITMODE){

            context.ADDSYSTEMUSER.vatpercentage = parseFloat(context.ADDSYSTEMUSER.vatpercentage)
           context.ADDSYSTEMUSER.rebatefeepercentage = parseFloat(context.ADDSYSTEMUSER.rebatefeepercentage)
           context.ADDSYSTEMUSER.fuelprice = parseFloat(context.ADDSYSTEMUSER.fuelprice)

           context.EDITSYSTEMUSER.fueldescription = context.ADDSYSTEMUSER.fueldescription;
          context.EDITSYSTEMUSER.vatpercentage = context.ADDSYSTEMUSER.vatpercentage;
          context.EDITSYSTEMUSER.fuelprice = context.ADDSYSTEMUSER.fuelprice;
          context.EDITSYSTEMUSER.rebatefeepercentage = context.ADDSYSTEMUSER.rebatefeepercentage;
          context.EDITSYSTEMUSER.fuelcode = context.ADDSYSTEMUSER.fuelcode;
          
           

        context.$Notify
          .YesNo(false, { context: context, item: context.EDITSYSTEMUSER })
          .then(value => {
            if (value) {
              $(".sw-btn-next")
                .text("")
                .append('<i class="fas fa-spinner fa-spin"></i>')
                .attr("disabled", "disabled");

              value.router = value.context.$router;
              value.context.$store
                .dispatch("fuelmanager_dashboard/EditFuelType", {id:value.item.id, AddModel:value.item})
                .then((parsedResult) => {
                  if(!parsedResult.status){
                    $(".sw-btn-next")
                    .text("Submit")
                    .removeAttr("disabled", "disabled");
                    value.context.$Notify.Error(parsedResult.message, "");
                    return
                  }
                   value.context.hacksmartwizard = true;
            $("#wizard").smartWizard("next");
                  $(".sw-btn-next")
                    .text("Done")
                    .removeAttr("disabled", "disabled");

                    value.context.EDITMODE = false;
                    value.context.GetALLRoutesById(value.context,value.context.taxi_association_id);
                    

                })
                .catch(error => {
                  $(".sw-btn-next")
                    .text("Submit")
                    .removeAttr("disabled", "disabled");

                  value.context.$Notify.Error(parsedResult.message, "");
                })
                .finally(() => {});
            }
          });

           } else {

        context.ADDSYSTEMUSER.vatpercentage = parseFloat(context.ADDSYSTEMUSER.vatpercentage)
           context.ADDSYSTEMUSER.rebatefeepercentage = parseFloat(context.ADDSYSTEMUSER.rebatefeepercentage)
           context.ADDSYSTEMUSER.fuelprice = parseFloat(context.ADDSYSTEMUSER.fuelprice)
          
           

        context.$Notify
          .YesNo(false, { context: context, item: context.ADDSYSTEMUSER })
          .then(value => {
            if (value) {
              $(".sw-btn-next")
                .text("")
                .append('<i class="fas fa-spinner fa-spin"></i>')
                .attr("disabled", "disabled");

              value.router = value.context.$router;
              value.context.$store
                .dispatch("fuelmanager_dashboard/AddFuelType", value.item)
                .then((parsedResult) => {
                  if(!parsedResult.status){
                    $(".sw-btn-next")
                    .text("Submit")
                    .removeAttr("disabled", "disabled");
                    value.context.$Notify.Error(parsedResult.message, "");
                    return
                  }
                   value.context.hacksmartwizard = true;
            $("#wizard").smartWizard("next");
                  $(".sw-btn-next")
                    .text("Done")
                    .removeAttr("disabled", "disabled");

                    value.context.GetALLRoutesById(value.context,value.context.taxi_association_id);

                })
                .catch(error => {
                  $(".sw-btn-next")
                    .text("Submit")
                    .removeAttr("disabled", "disabled");

                  value.context.$Notify.Error(parsedResult.message, "");
                })
                .finally(() => {});
            }
          });

        }

        return false;
      }

      return res;
    });

  }
};
</script>

<style scoped>
</style>

