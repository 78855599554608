<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
      v-on:init="init"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp 
    :USERROLES='ROLES' 
    :USERPROFILE="USERPROFILE" 
    :ActiveSubMenu="'Dashboard'" 
    :ActiveMenu="'Summary'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <transition name="bounce">
      <div id="content" class="content" v-show="USERPROFILE.taxi_associations_id">
        <!-- begin page-header -->
        <headerpagebreadcrumb
          pageheaderTitle="Treasurer Dashboard"
          pageheaderdescription
          breadcrumbitem1="Home"
          breadcrumbitem2="Dashboard"
          breadcrumbitem3="Treasurer"
        />
        <!-- end page-header -->

        <!-- begin #Main Body Template -->

  <!-- begin row -->
			<div class="row">
				<!-- begin col-3 -->
				<div class="col-lg-4 col-md-6">
					<div class="widget widget-stats bg-white text-inverse">
						<div class="stats-icon stats-icon-square bg-gradient-orange"><i class=ion-ios-people></i></div>
						<div class="stats-content">
							<div class="stats-title">Operators</div>
							<div class="stats-number">300</div>
							
							<div class="stats-desc">The total number of Operators in the association</div>
						</div>
					</div>
				</div>
				<!-- end col-3 -->
				<!-- begin col-3 -->
				<div class="col-lg-4 col-md-6">
					<div class="widget widget-stats bg-white text-inverse">
						<div class="stats-icon stats-icon-square bg-gradient-red"><i class="ion-ios-people"></i></div>
						<div class="stats-content">
							<div class="stats-title">Drivers</div>
							<div class="stats-number">2000</div>
							
							<div class="stats-desc">The total number of drivers in the association</div>
						</div>
					</div>
				</div>
				<!-- end col-3 -->
				<!-- begin col-3 -->
				<div class="col-lg-4 col-md-6">
					<div class="widget widget-stats bg-white text-inverse">
					  <div class="stats-icon stats-icon-square bg-gradient-yellow"><i class="fas fa-taxi"></i></div>
						<div class="stats-content">
							<div class="stats-title">Taxis</div>
							<div class="stats-number">1000 </div>
						
							<div class="stats-desc">Total number of taxis in the association</div>
						</div>
					</div>
				</div>
				<!-- end col-3 -->
				<!-- begin col-3 -->
			
				<!-- end col-3 -->
			</div>
			
			<!-- end row -->

				<!-- begin row -->
				<div class="row">
					<!-- begin col-6-->
					<div class="col-md-6 col-lg-6">
						<!-- begin panel -->
						<div class="panel panel-inverse" data-sortable-id="index-1">
							<div class="panel-heading">
								<div class="panel-heading-btn">
									<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
									<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
									<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
									<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-danger" data-click="panel-remove"><i class="fa fa-times"></i></a>
								</div>
								<h4 class="panel-title">Operators By Gender </h4>
							</div>
							<div class="panel-body">
								<div id="donut-chart" class="height-sm"></div>
							</div>
						</div>
						<!-- end panel -->

					</div>
					<!-- end col-6 -->
					<div class="col-lg-6">
						<!-- begin panel -->
						<div class="panel panel-inverse" >
							<div class="panel-heading">
								<div class="panel-heading-btn">
									<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
									<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
									<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
									<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-danger" data-click="panel-remove"><i class="fa fa-times"></i></a>
								</div>
								<h4 class="panel-title">Drivers By Gender</h4>
							</div>
							<div class="panel-body">
							
								<div id="donut-charts" class="height-sm"></div>
							</div>
						</div>
						<!-- end panel -->
					</div>
					<!-- end col-6 -->
				</div>
				<!-- end row -->

      <!-- begin page-header -->
			<h1 class="page-header">Finance Summary</h1>
			<!-- end page-header -->
				
        	<!-- begin row -->

      	<div class="row">
      	
          <!-- begin col-3 -->
						<div class="col-lg-4 col-md-6">
							<div class="widget widget-stats bg-white text-inverse">
								<div class="stats-icon stats-icon-square bg-gradient-green"><i class="ion-logo-usd"></ion-icon></i></div>
								<div class="stats-content">
									<div class="stats-title">Turnover</div>
									<div class="stats-number">R2000000.00</div>
									
									<div class="stats-desc">Money that is in our account</div>
								</div>
							</div>
						</div>
            <!-- begin col-3 -->

            <!-- begin col-3 -->
						<div class="col-lg-4 col-md-6">
					<div class="widget widget-stats bg-white text-inverse">
						<div class="stats-icon stats-icon-square bg-gradient-orange"><i class="ion-logo-usd"></ion-icon></i></div>
						<div class="stats-content">
							<div class="stats-title">Income</div>
							<div class="stats-number">R8000000.00 </div>
						
							<div class="stats-desc">The expected amount</div>
						</div>
					</div>
				</div>
            <!-- begin col-3 -->

 <!-- begin col-3 -->
<div class="col-lg-4 col-md-6">
					<div class="widget widget-stats bg-white text-inverse">
						<div class="stats-icon stats-icon-square bg-gradient-red"><i class="ion-logo-usd"></ion-icon></i></div>
						<div class="stats-content">
							<div class="stats-title">Areas</div>
							<div class="stats-number">R1200000.00 </div>
						
							<div class="stats-desc">How much we need to reach our target</div>
						</div>
					</div>
				</div>
 <!-- begin col-3 -->


 <div class="col-lg-6 col-md-6">
					<!-- begin panel -->
					<div class="panel panel-inverse" >
						<div class="panel-heading">
							<div class="panel-heading-btn">
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
								<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-danger" data-click="panel-remove"><i class="fa fa-times"></i></a>
							</div>
							<h4 class="panel-title">Yearly Turnover Comparison</h4>
						</div>
						<div class="panel-body">
						<canvas id="chrtRev"  class="height-sm"></canvas> 
						</div>
					</div>
		</div>


    <div class="col-md-6 col-lg-6">
				<!-- begin panel -->
				<div class="panel panel-inverse">
					<div class="panel-heading">
						<div class="panel-heading-btn">
							<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
							<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
							<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
							<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-danger" data-click="panel-remove"><i class="fa fa-times"></i></a>
						</div>
						<h4 class="panel-title">Quartely Comparison Turnover Comparison </h4>
					</div>
					<div class="panel-body">
						<canvas id="bar-chart" class="height-sm"></canvas>
					</div>
				</div>
		</div>


        </div>

          	<!-- end row -->

        <!-- begin #Main Body Template -->
      </div>
    </transition>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller/>
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import statsCards from "@/components/charts/statsCards.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("auth");

export default {
  name: "sysadmin_dashboard",
  data: function() {
    return {
      message: "Some Message",
      ROLES: this.$store.getters["auth/GETUSERROLES"]
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    statsCards,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["USERPROFILE"])
  },
  methods: {
    init: function() {

    // `this` points to the vm instance
    /*@@@@@@@@@@@@@@@@@@@@@@@@@@ PIE CHART START @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**/
    var handleDonutChart = function() {
      "use strict";
      //PIE CHART 1
      if ($("#donut-chart").length !== 0) {
        var donutData = [
          { label: "SANTACO", data: 8000, color: COLOR_PURPLE_DARKER },

          { label: "NTA", data: 9000, color: COLOR_PURPLE_LIGHTER }
        ];
        $.plot("#donut-chart", donutData, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true
              }
            }
          },
          legend: {
            show: true
          }
        });
      }

      //PIE CHART 6 taxi association by province

      if ($("#donut-chart5").length !== 0) {
        var donutData = [
          { label: "GP", data: 12, color: COLOR_ORANGE },
          { label: "NW", data: 5, color: COLOR_GREEN },
          { label: "LP", data: 3, color: COLOR_PURPLE },
          { label: "KZN", data: 10, color: COLOR_AQUA },
          { label: "MP", data: 8, color: COLOR_GREY },
          { label: "FP", data: 7, color: COLOR_BLACK },
          { label: "WC", data: 6, color: COLOR_GREY_LIGHTER },
          { label: "EC", data: 2, color: COLOR_BLUE_DARKER },
          { label: "NP", data: 7, color: COLOR_BLUE_DARKER }
        ];
        $.plot("#donut-chart5", donutData, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true
              }
            }
          },
          legend: {
            show: true
          }
        });
      }

      //PIE CHART 7 taxi Operators by province

      if ($("#donut-chart6").length !== 0) {
        var donutData = [
          { label: "GP", data: 12, color: COLOR_ORANGE },
          { label: "NW", data: 5, color: COLOR_GREEN },
          { label: "LP", data: 3, color: COLOR_PURPLE },
          { label: "KZN", data: 10, color: COLOR_AQUA },
          { label: "MP", data: 8, color: COLOR_GREY },
          { label: "FP", data: 7, color: COLOR_BLACK },
          { label: "WC", data: 6, color: COLOR_GREY_LIGHTER },
          { label: "EC", data: 2, color: COLOR_BLUE_DARKER },
          { label: "NP", data: 7, color: COLOR_BLUE_DARKER }
        ];
        $.plot("#donut-chart6", donutData, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true
              }
            }
          },
          legend: {
            show: true
          }
        });
      }
      //PIE CHART 8 taxi Vehicles by province

      if ($("#donut-chart7").length !== 0) {
        var donutData = [
          { label: "GP", data: 12, color: COLOR_ORANGE },
          { label: "NW", data: 5, color: COLOR_GREEN },
          { label: "LP", data: 3, color: COLOR_PURPLE },
          { label: "KZN", data: 10, color: COLOR_AQUA },
          { label: "MP", data: 8, color: COLOR_GREY },
          { label: "FP", data: 7, color: COLOR_BLACK },
          { label: "WC", data: 6, color: COLOR_GREY_LIGHTER },
          { label: "EC", data: 2, color: COLOR_BLUE_DARKER },
          { label: "NP", data: 7, color: COLOR_BLUE_DARKER }
        ];
        $.plot("#donut-chart7", donutData, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true
              }
            }
          },
          legend: {
            show: true
          }
        });
      }
      //PIE CHART 9 taxi Drivers by province

      if ($("#donut-chart8").length !== 0) {
        var donutData = [
          { label: "GP", data: 12, color: COLOR_ORANGE },
          { label: "NW", data: 5, color: COLOR_GREEN },
          { label: "LP", data: 3, color: COLOR_PURPLE },
          { label: "KZN", data: 10, color: COLOR_AQUA },
          { label: "MP", data: 8, color: COLOR_GREY },
          { label: "FP", data: 7, color: COLOR_BLACK },
          { label: "WC", data: 6, color: COLOR_GREY_LIGHTER },
          { label: "EC", data: 2, color: COLOR_BLUE_DARKER },
          { label: "NP", data: 7, color: COLOR_BLUE_DARKER }
        ];
        $.plot("#donut-chart8", donutData, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true
              }
            }
          },
          legend: {
            show: true
          }
        });
      }
    };
    /*=================PIE CHART 2 Taxi Operators by TaxAffiliation AND PIE CHART 4 Taxi Driver by TaxAffiliation============*/
    var handlePieAndDonutChart = function() {
      "use strict";
      var donutChartData = [
        { label: "NTA", value: 300, color: COLOR_RED },
        { label: "SANTACO", value: 258, color: COLOR_AQUA }
      ];
      var pieChartData = [
        { label: "NTA", value: 300, color: COLOR_ORANGE },
        { label: "SANTACO", value: 258, color: COLOR_GREY }
      ];
      nv.addGraph(function() {
        var pieChart = nv.models
          .pieChart()
          .x(function(d) {
            return d.label;
          })
          .y(function(d) {
            return d.value;
          })
          .showLabels(true)
          .labelType("percent")
          .labelThreshold(0.05);

        d3.select("#nv-pie-chart")
          .append("svg")
          .datum(pieChartData)
          .transition()
          .duration(350)
          .call(pieChart);

        return pieChart;
      });
      nv.addGraph(function() {
        var chart = nv.models
          .pieChart()
          .x(function(d) {
            return d.label;
          })
          .y(function(d) {
            return d.value;
          })
          .showLabels(true)
          .labelThreshold(0.05)
          .labelType("percent")
          .donut(true)
          .donutRatio(0.35);

        d3.select("#nv-donut-chart")
          .append("svg")
          .datum(donutChartData)
          .transition()
          .duration(350)
          .call(chart);

        return chart;
      });
    };
    /*=============PIE CHART 3 Taxi Vehicle by TaxiAffiliation=====================*/
    var handleMorrisDonusChart = function() {
      Morris.Donut({
        element: "morris-donut-chart",
        data: [{ label: "SANTACO", value: 25 }, { label: "NTA", value: 40 }],
        formatter: function(y) {
          return y + "%";
        },
        resize: true,
        gridLineColor: [COLOR_GREY_LIGHTER],
        gridTextFamily: FONT_FAMILY,
        gridTextColor: FONT_COLOR,
        gridTextWeight: FONT_WEIGHT,
        gridTextSize: FONT_SIZE,
        colors: [COLOR_AQUA, COLOR_BLUE]
      });
    };

    /*@@@@@@@@@@@@@@@@@@@@@@@@@@ PIE CHART END @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**/

    /*@@@@@@@@@@@@@@@@@@@@@@@@@@ BAR CHART START @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**/

    var handleBarChart = function() {
      "use strict";
      // Bar graph 1 Taxi Association By RegionF
      var barChartData = [
        {
          key: "Cumulative Return",
          values: [
            { label: "SA", value: 29, color: COLOR_RED },
            { label: "NW", value: 15, color: COLOR_ORANGE },
            { label: "LP", value: 32, color: COLOR_GREEN },
            { label: "ff", value: 196, color: COLOR_AQUA },
            { label: "E", value: 44, color: COLOR_BLUE },
            { label: "F", value: 98, color: COLOR_PURPLE },
            { label: "G", value: 13, color: COLOR_GREY },
            { label: "H", value: 5, color: COLOR_BLACK }
          ]
        }
      ];

      nv.addGraph(function() {
        var barChart = nv.models
          .discreteBarChart()
          .x(function(d) {
            return d.label;
          })
          .y(function(d) {
            return d.value;
          })
          .showValues(true)
          .duration(250);

        barChart.yAxis.axisLabel("Total Sales");
        barChart.xAxis.axisLabel("Product");

        d3.select("#nv-bar-chart")
          .append("svg")
          .datum(barChartData)
          .call(barChart);
        nv.utils.windowResize(barChart.update);

        return barChart;
      });

      // Bar graph 2 Taxi Operators By Region
      var barChartData2 = [
        {
          key: "Cumulative Return",
          values: [
            { label: "SA", value: 29, color: COLOR_RED },
            { label: "NW", value: 15, color: COLOR_ORANGE },
            { label: "LP", value: 32, color: COLOR_GREEN },
            { label: "ff", value: 196, color: COLOR_AQUA },
            { label: "E", value: 44, color: COLOR_BLUE },
            { label: "F", value: 98, color: COLOR_PURPLE },
            { label: "G", value: 13, color: COLOR_GREY },
            { label: "H", value: 5, color: COLOR_BLACK }
          ]
        }
      ];

      nv.addGraph(function() {
        var barChart2 = nv.models
          .discreteBarChart()
          .x(function(d) {
            return d.label;
          })
          .y(function(d) {
            return d.value;
          })
          .showValues(true)
          .duration(250);

        barChart2.yAxis.axisLabel("Total Sales");
        barChart2.xAxis.axisLabel("Product");

        d3.select("#nv-bar-chart2")
          .append("svg")
          .datum(barChartData2)
          .call(barChart2);
        nv.utils.windowResize(barChart2.update);

        return barChart2;
      });
      // Bar graph 3 Taxi Operators By Region
      var barChartData3 = [
        {
          key: "Cumulative Return",
          values: [
            { label: "SA", value: 29, color: COLOR_RED },
            { label: "NW", value: 15, color: COLOR_ORANGE },
            { label: "LP", value: 32, color: COLOR_GREEN },
            { label: "ff", value: 196, color: COLOR_AQUA },
            { label: "E", value: 44, color: COLOR_BLUE },
            { label: "F", value: 98, color: COLOR_PURPLE },
            { label: "G", value: 13, color: COLOR_GREY },
            { label: "H", value: 5, color: COLOR_BLACK }
          ]
        }
      ];

      nv.addGraph(function() {
        var barChart3 = nv.models
          .discreteBarChart()
          .x(function(d) {
            return d.label;
          })
          .y(function(d) {
            return d.value;
          })
          .showValues(true)
          .duration(250);

        barChart3.yAxis.axisLabel("Total Sales");
        barChart3.xAxis.axisLabel("Product");

        d3.select("#nv-bar-chart3")
          .append("svg")
          .datum(barChartData3)
          .call(barChart3);
        nv.utils.windowResize(barChart3.update);

        return barChart3;
      });
      // Bar graph 4 Taxi Operators By Region
      var barChartData4 = [
        {
          key: "Cumulative Return",
          values: [
            { label: "SA", value: 29, color: COLOR_RED },
            { label: "NW", value: 15, color: COLOR_ORANGE },
            { label: "LP", value: 32, color: COLOR_GREEN },
            { label: "ff", value: 196, color: COLOR_AQUA },
            { label: "E", value: 44, color: COLOR_BLUE },
            { label: "F", value: 98, color: COLOR_PURPLE },
            { label: "G", value: 13, color: COLOR_GREY },
            { label: "H", value: 5, color: COLOR_BLACK }
          ]
        }
      ];

      nv.addGraph(function() {
        var barChart4 = nv.models
          .discreteBarChart()
          .x(function(d) {
            return d.label;
          })
          .y(function(d) {
            return d.value;
          })
          .showValues(true)
          .duration(250);

        barChart4.yAxis.axisLabel("Total Sales");
        barChart4.xAxis.axisLabel("Product");

        d3.select("#nv-bar-chart4")
          .append("svg")
          .datum(barChartData4)
          .call(barChart4);
        nv.utils.windowResize(barChart4.update);

        return barChart4;
      });
    };

    /*==================For taxi association by province and taxiAffiliation ==========*/

    var barChartData = {
      labels: [
        "Gauteng",
        "Kwazulu Natal",
        "North West",
        "Limpopo",
        "Mpumalanga",
        "North West",
        "Northen Province",
        "Eastern cape",
        "Western cape"
      ],
      datasets: [
        {
          label: "SANTACO",
          borderWidth: 2,
          borderColor: COLOR_PURPLE,
          backgroundColor: COLOR_BLACK_TRANSPARENT_3,
          data: [
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor()
          ]
        },
        {
          label: "NTA",
          borderWidth: 2,
          borderColor: COLOR_BLACK,
          backgroundColor: COLOR_RED_TRANSPARENT_3,
          data: [
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor()
          ]
        }
      ]
    };

    /*==================For taxi Operators by province and taxiAffiliation==========*/
    var barChartData2 = {
      labels: [
        "Gauteng",
        "Kwazulu Natal",
        "North West",
        "Limpopo",
        "Mpumalanga",
        "North West",
        "Northen Province",
        "Eastern cape",
        "Western cape"
      ],
      datasets: [
        {
          label: "SANTACO",
          borderWidth: 2,
          borderColor: COLOR_PURPLE,
          backgroundColor: COLOR_PURPLE,
          data: [
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor()
          ]
        },
        {
          label: "NTA",
          borderWidth: 2,
          borderColor: COLOR_BLACK,
          backgroundColor: COLOR_GREEN,
          data: [
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor()
          ]
        }
      ]
    };
    /*==================For taxi Vehicles by province and taxiAffiliation==========*/
    var barChartData3 = {
      labels: [
        "Gauteng",
        "Kwazulu Natal",
        "North West",
        "Limpopo",
        "Mpumalanga",
        "North West",
        "Northen Province",
        "Eastern cape",
        "Western cape"
      ],
      datasets: [
        {
          label: "SANTACO",
          borderWidth: 2,
          borderColor: COLOR_PURPLE,
          backgroundColor: COLOR_ORANGE_TRANSPARENT_3,
          data: [
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor()
          ]
        },
        {
          label: "NTA",
          borderWidth: 2,
          borderColor: COLOR_BLACK,
          backgroundColor: COLOR_AQUA,
          data: [
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor()
          ]
        }
      ]
    };
    /*==================For taxi Drivers by province and taxiAffiliation==========*/
    var barChartData4 = {
      labels: [
        "Gauteng",
        "Kwazulu Natal",
        "North West",
        "Limpopo",
        "Mpumalanga",
        "North West",
        "Northen Province",
        "Eastern cape",
        "Western cape"
      ],
      datasets: [
        {
          label: "SANTACO",
          borderWidth: 2,
          borderColor: COLOR_PURPLE,
          backgroundColor: COLOR_PURPLE_TRANSPARENT_3,
          data: [
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor()
          ]
        },
        {
          label: "NTA",
          borderWidth: 2,
          borderColor: COLOR_BLACK,
          backgroundColor: COLOR_BLACK_TRANSPARENT_3,
          data: [
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor()
          ]
        }
      ]
    };
    //
    var handleChartJs = function() {
      /*==================For taxi association by province and taxiAffiliation==========*/
      /*var ctx = document.getElementById('bar-chart').getContext('2d');
	var barChart = new Chart(ctx, {
		type: 'bar',
		data: barChartData
	});
*/
      /*==================For taxi Operators by province and taxiAffiliation==========*/
      /*var ctx2 = document.getElementById('bar-chart2').getContext('2d');
	var barChart = new Chart(ctx2, {
		type: 'bar',
		data: barChartData2
	});
*/
      /*==================For taxi Vehicles by province and taxiAffiliation==========*/
      /*var ctx3 = document.getElementById('bar-chart3').getContext('2d');
	var barChart = new Chart(ctx3, {
		type: 'bar',
		data: barChartData3
	});*/
      /*==================For taxi Drivers by province and taxiAffiliation==========*/
      /*var ctx4 = document.getElementById('bar-chart4').getContext('2d');
	var barChart = new Chart(ctx4, {
		type: 'bar',
		data: barChartData4
	});*/
    };

    /*@@@@@@@@@@@@@@@@@@@@@@@@@@ BAR CHART END @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**/
  COLOR_GREEN = "#5AC8FA";

    setTimeout(function(){ 

    DashboardV2.init();

    handleDonutChart();
    handleChartJs();
    //handleMorrisBarChart();
    handleMorrisDonusChart();
    handlePieAndDonutChart();
    handleBarChart();

     }, 1000);
    

    }
  },
  mounted: function() {

     App.init();
     
  }
};
</script>
