<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageVehicle'"
      :ActiveMenu="'Taxi Operator Vehicles'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Taxi Vehicle"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Vehicles"
        breadcrumbitem3="Manage Vehicle"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">All Vehicles</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <a
                  data-toggle="modal"
                  data-target="#AddModal"
                  class="btn btn-white"
                  href="javascript:;"
                >
                  <i class="fa fa-plus"></i>

                  <router-link to="/Vehicle/Add/:id">Add Taxi Vehicle</router-link>
                </a>
              </div>
            </div>

            <!-- <div v-if="!dataLoaded" class="btn-row">
              <div class="text-center">
                <i class="fas fa-circle-notch fa-spin fa-10x fa-blue"></i>
              </div>
            </div> -->

            <br />

            <div v-if="!dataLoaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-default" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Model/Make</th>
                    <th class="text-nowrap">License Plate</th>
                    <th class="text-nowrap">Operator Name</th>
                    <th class="text-nowrap">Assigned Drive</th>
                    <th class="text-nowrap">Balance</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETVEHICLEBYASSID"
                    v-bind:key="item._taxi_operator_vehicles_id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item._vehicle_make+' '+item._vehicle_model}}</td>
                    <td>{{item._licence_plate}}</td>
                    <td>{{item._taxi_operators_name}}</td>
                    <td>{{item._taxidrivers_name}}</td>
                    <td>{{item._available_balance}}</td>
                    <td>{{item._updateby_name || item._addedby_name}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <button
                        type="button"
                        class="btn btn-sm btn-warning width-80 m-r-2"
                        @click="Migrate(context,item)"
                      >
                        <v-wait v-bind:for="'restore-' + item._taxi_operator_vehicles_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Migrate
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

                <!-- begin #Main Body Template -->
 <!-- Add Modal HTML START-->
              <div id="AddModal" class="modal fade" v-if="ADDAGENT">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Add Vehicle</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Display Name</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                placeholder="Enter Display Name"
                                class="form-control"
                                minlength="1"
                                v-model="ADDAGENT.display_name"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->

                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">VIN Number</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                placeholder="Enter VIN Number"
                                class="form-control"
                                minlength="3"
                                v-model="ADDAGENT.vinnumber"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                           <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">License Number</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                placeholder="Enter License Number"
                                class="form-control"
                                minlength="3"
                                v-model="ADDAGENT.licencenumber"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                             <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Commission Type</label>
                            <div class="col-md-9">
                             <select
                                class="default-selectTaxiDrivers default-select form-control"
                                data-size="5"
                                data-live-search="true"
                                v-model="ADDAGENT.commissiontype_code"
                              >
                                <option
                                  v-for="item in COMMISSIONTYPES"
                                  v-bind:key="item.id"
                                  :value="item.id"
                                >{{item.name}}</option>
                            </select>
                            </div>
                          </div>
                          <!-- end form-group row -->
                           <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Commission Amount</label>
                            <div class="col-md-9">
                              <CurrencyInput
                                v-model.lazy="ADDAGENT.commission_value"
                                :auto-decimal-mode="true"
                                :currency="currency"
                                data-parsley-required="false"
                                class="form-control text-left demo__currency-input"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                        
                           <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Commission Percent</label>
                            <div class="col-md-9">
                              <input
                                type="number"
                                placeholder="Enter Account No"
                                class="form-control"
                                minlength="3"
                                v-model="ADDAGENT.commission_percent"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Fare Fixed</label>
                            <div class="col-md-9">
                              <div class="switcher switcher-success">
                              <input
                                type="checkbox"
                                name="switcher_checkbox_hasoverdraft"
                                id="switcher_checkbox_hasoverdraft"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="ADDAGENT.isfare_fixed"
                              />
                              <label for="switcher_checkbox_hasoverdraft"></label>
                            </div>
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Fare Price</label>
                            <div class="col-md-9">
                              <CurrencyInput
                                v-model.lazy="ADDAGENT.fareprice"
                                :auto-decimal-mode="true"
                                :currency="currency"
                                data-parsley-required="false"
                                class="form-control text-left demo__currency-input"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        />
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="VehicleAdd(context,ADDAGENT)"
                          :disabled="$wait.is('add')"
                        >
                          <v-wait v-bind:for="'add'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Add
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Add Modal HTML START-->
      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("OperatorVehicle");

export default {
  name: "ManageVehicles",
  data: function () {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      USERGROUPCODE: this.$store.getters["auth/USERPROFILE"].usergroups_code,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      context: this,
      GETVEHICLEBYASSID: [],
      COMMISSIONTYPES:[],
      addedsuccess: undefined,
      errormessage: undefined,
      id: this.$route.query.id,
      approvalid: this.$route.query.approvalid,
      skip: 0,
      length: 10000,
      dataLoaded: false,
      ADDAGENT: {
        tams_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
        tmas_operators_id:0,
        tams_vehicle_id: 0,
        display_name: "",
        vinnumber: "",
        licencenumber: "",
        commissiontype_code: "",
        commission_value: 0.00,
        commission_percent: 0,
        isfare_fixed: false,
        fareprice: 0.00,
      },
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters({}),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions([
      "getall",
      "getByPaging",
      "getVehiclesByAssId",
      "getVehiclesByAssIdSummary",
      "delete",
      "edit",
      "add",
      "markAsDeleted",
      "restoreDeleted",
    ]),
    getCommissionTypes: context => {
      context.$store
        .dispatch("TA/getcommissiontypes", {
            router: context.$router
        })
        .then((data) => {
          context.COMMISSIONTYPES = data;
        });
    },
    Migrate: (
      context,
      item
    ) => {

      


        context.ADDAGENT.tmas_operators_id = item.tmas_operators_id;
      context.ADDAGENT.tams_vehicle_id = item._taxi_operator_vehicles_id;
      context.ADDAGENT.display_name = item._vehicle_make.trim() + " " + item._vehicle_model.trim();
      //context.ADDAGENT.vinnumber = item.mobilenumber;
      context.ADDAGENT.licencenumber = item._licence_plate;

      $("#AddModal").modal("show");
    },
    Delete: (context, id, deletedby) => {
      
      context.$Notify.YesNo(false, { context, id, deletedby }).then((value) => {
        if (value) {
          value.context.$wait.start("delete-" + value.id);

          value.context
            .markAsDeleted({
              router: value.context.$router,
              id: value.id,
              deletedby: value.deletedby,
            })
            .then(() => {
              value.context.GETVEHICLEBYASSID = value.context.GETVEHICLEBYASSID.filter(item => item._taxi_operator_vehicles_id !== value.id);
              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Success("Successfully Deleted!", "");
            })
            .catch((error) => {
              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    Restore: (context, id, restoredby) => {
      context.$Notify
        .YesNo(false, { context, id, restoredby })
        .then((value) => {
          if (value) {
            value.context.$wait.start("restore-" + value.id);

            value.context
              .restoreDeleted({
                router: value.context.$router,
                id: value.id,
                restoredby: value.restoredby,
              })
              .then(() => {
                value.context.$wait.end("restore-" + value.id);
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch((error) => {
                value.context.$wait.end("restore-" + value.id);
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    getbyid: (context, taxiassociationid) => {
      context
        .getVehiclesByAssIdSummary({
          router: context.$router,
          taxiassociation_id: taxiassociationid,
          skip: context.skip,
          len: context.length,
        })
        .then((data) => {
          console.log("These are the vehicles:(vue page) ", data);

          context.GETVEHICLEBYASSID = data;
          context.dataLoaded = true;

          // TableManageCombine.init();
        })
        .catch((error) => {
          console.log("vehicles error: ", error);
        })
        .finally(() => {
          var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: true,
                    colReorder: true,
                    keys: false,
                    rowReorder: false,
                    select: true
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }

                 

              $("#data-table-default").DataTable(options);
        });
    },

    setAdd(val) {
      this.$store
        .dispatch("OperatorVehicle/add", {
          _dataObj: val,
        })
        .then(() => {});
    },
  },
  beforeMount: function () {
    // this.getbyid(this.context, this.taxiassociationid);
    // $("#data-table-default").DataTable({
    //   responsive: true
    // });
  },
  mounted: function () {
    App.init();
    this.getbyid(this.context, this.taxiassociationid);
    this.getCommissionTypes(this.context);

    

    //  this.getByTaxiAssId(this.context);
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

