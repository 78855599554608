<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TaxiAssociation'"
      :ActiveMenu="'ManageTaxiAssociation'"
    />

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Manage Taxi Association"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Association"
        breadcrumbitem3="Manage"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">All Associations</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <router-link
                  class="btn btn-white"
                  href="javascript:;"
                  to="/TaxiAssociation/Add"
                  @click="clearAdded(GETSELECTED)"
                >
                  <i class="fa fa-plus"></i>
                  Add Association
                </router-link>
              </div>
            </div>

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-default" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Taxi Affiliation</th>
                    <th class="text-nowrap">District Name</th>
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">Abbr Name</th>
                    <th class="text-nowrap">Contact Person</th>
                    <th class="text-nowrap">Contact Number</th>
                    <th class="text-nowrap">Number Of Operators</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Last Updated By</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in TAXIASSOCIATIONS"
                    v-bind:key="item._id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item._taxi_affiliation_name}}</td>
                    <td>{{item._district_name}}</td>
                    <td>{{item._name}}</td>
                    <td>{{item._abbriviated_name}}</td>
                    <td>{{item._contact_person_name}}</td>
                    <td>{{item._contact_person_number}}</td>
                    <td>{{item._current_number_members}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._deleted_by }"
                        v-if="item._deleted_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item._is_active, 'label-secondary':!item._is_active  }"
                        v-if="!item._deleted_by"
                      >{{item._is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td>{{item._deletedby_name || item._updateby_name || item._addedby_name}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <router-link
                        type="button"
                        class="btn btn-sm btn-white width-60 m-r-2"
                        :to="{ name: 'TaxiAssociationProfile', params: {id: item._taxi_associations_id } }"
                      >View</router-link>

                      <router-link
                            type="button"
                            class="btn btn-sm btn-primary width-60 m-r-2"
                            data-role="UTO"
                            :to="{ name: 'taxiassociationupdate', params: {id: item._taxi_associations_id } }"
                          >Edit</router-link>
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-80"
                        data-toggle="modal"
                        data-target="#deletedModal"
                        v-if="item._deleted_by"
                        @click="restoredelete(context,item,USERPROFILE.system_usersid)"
                        :disabled="$wait.is('restoreDeleted-' + item._id)"
                      >
                        <v-wait v-bind:for="'restoreDeleted-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Restore
                        </v-wait>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-80"
                        v-if="!item._deleted_by"
                        @click="deletedata(context,item,USERPROFILE.system_usersid)"
                        :disabled="$wait.is('delete-' + item._id)"
                      >
                        <v-wait v-bind:for="'delete-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Delete
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Edit Modal HTML START-->
              <div id="ViewModal" class="modal fade" v-if="GETSELECTED">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="text-center">
                        <h4 class="modal-title">Association Details</h4>
                      </div>
                      <div class="modal-body">
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Affiliation
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="N/A"
                              v-model="GETSELECTED._taxi_affiliation_name"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            District
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="N/A"
                              v-model="GETSELECTED._district_name"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Abbreviation
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="N/A"
                              v-model="GETSELECTED._abbriviated_name"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Telephone
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="N/A"
                              v-model="GETSELECTED._telephone"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Mobile Number
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="N/A"
                              v-model="GETSELECTED._mobilenumber"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Max Number Of Operators
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="N/A"
                              v-model="GETSELECTED._maximum_number_members"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-4 col-form-label text-md-right">
                            Max Number Of Taxis Per Operator
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              placeholder="N/A"
                              v-model="GETSELECTED._maximum_number_vehicles"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Close"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Edit Modal HTML START-->

              <!-- Edit Modal HTML START-->
              <div id="EditModal" class="modal fade" v-if="GETSELECTED">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Edit Title</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Taxi Affiliation</label>
                            <div class="col-md-9">
                              <select
                                name="DistrictName"
                                class="form-control"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="GETSELECTED.taxi_affiliation_id"
                              >
                                <option value="0">Select Taxi Affiliation</option>
                                <option
                                  v-for="item in TAXIAFFILIATION"
                                  v-bind:key="item._id"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Province</label>
                            <div class="col-md-9">
                              <select
                                name="DistrictName"
                                class="form-control"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="GETSELECTED.province_id"
                              >
                                <option value="0">Select Province</option>
                                <option
                                  v-for="item in GETALLPROVINCES"
                                  v-bind:key="item._id"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Name</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                v-model.lazy="GETSELECTED.district_name"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Description</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                v-model.lazy="GETSELECTED.district_description"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Abbreviated Name</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                v-model.lazy="GETSELECTED.district_abbriviated_name"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Code</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                class="form-control"
                                v-model.lazy="GETSELECTED._code"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Status</label>
                            <div class="checkbox checkbox-css">
                              <input
                                type="checkbox"
                                id="cssCheckbox1edit"
                                v-model="GETSELECTED._is_active"
                                :checked="GETSELECTED._is_active"
                              />
                              <label
                                v-if="!GETSELECTED._deleted_by"
                                for="cssCheckbox1edit"
                              >{{GETSELECTED._is_active ? 'Active' : 'Disabled' }}</label>
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        />
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="edited(context,GETSELECTED,USERPROFILE.system_usersid)"
                          :disabled="$wait.is('edited')"
                        >
                          <v-wait v-bind:for="'edited'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Save
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Edit Modal HTML START-->

              <!-- Add Modal HTML START-->
              <div id="AddModal" class="modal fade" v-if="GETSELECTED">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Add Title</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Taxi Affiliation</label>
                            <div class="col-md-9">
                              <select
                                name="DistrictName"
                                class="form-control"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="GETSELECTED.taxi_affiliation_id"
                              >
                                <option selected value="0">Select Taxi Affiliation</option>
                                <option
                                  v-for="item in TAXIAFFILIATION"
                                  v-bind:key="item._id"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Province</label>
                            <div class="col-md-9">
                              <select
                                name="DistrictName"
                                class="form-control"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="GETSELECTED.province_id"
                              >
                                <option selected value="0">Select Province</option>
                                <option
                                  v-for="item in GETALLPROVINCES"
                                  v-bind:key="item._id"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Name</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                v-model.lazy="GETSELECTED.district_name"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Description</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                v-model.lazy="GETSELECTED.district_description"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Abbreviated Name</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                v-model.lazy="GETSELECTED.district_abbriviated_name"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Code</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                class="form-control"
                                v-model.lazy="GETSELECTED._code"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        />
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="add(context,GETSELECTED,USERPROFILE.system_usersid,LoggedInUserFullName,
                                get_taxi_affiliation_name(context,GETSELECTED.taxi_affiliation_id),
                                get_province_name(context,GETSELECTED.province_id)
                          )"
                          :disabled="$wait.is('edited')"
                        >
                          <v-wait v-bind:for="'edited'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Save
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Add Modal HTML START-->
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TA");

export default {
  name: "ManageTaxiAssociation",
  data: function() {
    return {
      TAXIAFFILIATION: this.$store.getters["taxiAffiliation/GETALLACTIVE"],
      GETALLPROVINCES: [],
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      addedsuccess: undefined,
      errormessage: undefined,
      GETSELECTED: Object.assign({}, this.$store.getters["district/GETALL"])
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["TAXIASSOCIATIONS"]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions(["getall", "delete", "edit", "add"]),
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    loadLookUps(context) {
      
      context.$store
        .dispatch("lookups/init", {
          router: context.$router,
        })
        .then((data) => {
          console.log("loadLookUps found: ", data);
          
          context.GETALLPROVINCES = data.LOADEDPROVINCES;
          context.GETALLSUBURS = data.LOADEDSUBURBS;

          
        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
    get_taxi_affiliation_name: (context, id) => {
      var affiliation_name = context.TAXIAFFILIATION.filter(
        item => item._id === id
      )[0]._name;
      return affiliation_name;
    },
    get_province_name: (context, id) => {
      var province_name = context.GETALLPROVINCES.filter(
        item => item._id === id
      )[0]._name;
      return province_name;
    },
    clearAdded: item => {
      for (var propName in item) {
        item[propName] = undefined;
      }
    },
    add: (
      context,
      item,
      addedby,
      LoggedInUserFullName,
      _taxi_affiliation_name,
      _province_name
    ) => {
      item._addedby_name = LoggedInUserFullName;
      item.taxi_affiliation_name = _taxi_affiliation_name;
      item.province_name = _province_name;
      item._is_active = true;

      context.$Notify.YesNo(false, { context, item, addedby }).then(value => {
        if (value) {
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("district/add", {
              _dataObj: value
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");
              value.context.$Notify.Success("Successfully Added!", "");
            })
            .catch(error => {
              value.context.$wait.end("add");

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    setAdd(val) {
      this.$store
        .dispatch("add", {
          _dataObj: val
        })
        .then(() => {});
    },
    getall: context => {
      context.$store
        .dispatch("district/getall", {
          _dataObj: {
            router: context.$router
          }
        })
        .then(() => {
          App.init();
          Highlight.init();
        });
    },
    edited: (context, item, updatedby) => {
      //item = Object.assign({}, item);
      context.$Notify.YesNo(false, { context, item, updatedby }).then(value => {
        if (value) {
          value.context.$wait.start("edited");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("district/update", {
              _dataObj: value
            })
            .then(() => {
              $("#EditModal").modal("hide");
              value.context.$wait.end("edited");
              value.context.$Notify.Success("Successfully Updated!", "");
            })
            .catch(error => {
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {
              value.context.$wait.end("edited");
            });
        }
      });
    },
    deletedata: (context, item, deletedby) => {
      context.$Notify
        .YesNo(false, { context, id: item._id, deletedby })
        .then(value => {
          if (value) {
            value.context.$wait.start("delete-" + value.id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("district/markAsdelete", {
                _dataObj: value
              })
              .then(id => {
                value.context.$wait.end("delete-" + id);
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch(error => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    restoredelete: (context, item, restoredby) => {
      context.$Notify
        .YesNo(false, { context, id: item._id, restoredby })
        .then(value => {
          if (value) {
            value.context.$wait.start("restoreDeleted-" + value.id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("district/restoreDeleted", {
                _dataObj: value
              })
              .then(id => {
                value.context.$wait.end("restoreDeleted-" + id);
                value.context.$Notify.Success("Successfully Restored!", "");
              })
              .catch(error => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    }
  },
  mounted: function() {
    this.getall(this.context);

    this.loadLookUps(this.context);

    $('#data-table-default').DataTable({
			responsive: true
		});
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

