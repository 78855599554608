<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TaxiAssociation'"
      :ActiveMenu="'ManageLetterOfGoodStanding'"
    />

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Association Letters of Good standing"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Association"
        breadcrumbitem3="Leter of Good Standing"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">Taxi Association Letter of Goodstanding</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <router-link to="/TaxiAssociation/GoodStanding" class="btn btn-white">
                  <i class="fa fa-plus"></i>
                  Add Letter Of Good Standing
                </router-link>
              </div>

              <div class="btn-group m-r-5">
                <button @click="testing()" type="button" class="btn btn-white">
                  <i class="fa fa-plus"></i>
                  Testing
                </button>
              </div>
            </div>

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-default" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th class="text-nowrap">Requested By</th>
                    <th class="text-nowrap">Mobile Number</th>
                    <th class="text-nowrap">Date Requested</th>
                    <th class="text-nowrap">Verification Number</th>
                    <th class="text-nowrap">Verified By</th>
                    <th class="text-nowrap">Verification Date</th>
                    <th class="text-nowrap">Rejected By</th>
                    <th class="text-nowrap">Date Rejected</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(item) in LETTERSOFGOODSTANDING" v-bind:key="item.id">
                    <td>{{item.taxi_operator_name}}</td>
                    <td>{{item.taxi_operators_mobilenumber}}</td>
                    <td>{{item.date_added |formatToShortDate }}</td>
                    <td>{{item.verification_number}}</td>
                    <td>{{item.verified_by_name}}</td>
                    <td>{{item.date_verified | formatToShortDate }}</td>
                    <td>{{item.rejected_by_name}}</td>
                    <td>{{item.date_rejected | formatToShortDate }}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item.deleted_by }"
                        v-if="item.deleted_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.is_active, 'label-secondary':!item.is_active  }"
                        v-if="!item.deleted_by"
                      >{{item.is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td class="with-btn valign-middle" nowrap>
                      <button
                        class="btn btn-primary btn-sm m-r-2"
                        type="button"
                        @click="pdfTester(context,item)"
                        v-if="item.date_verified && !item.pdf_path"
                      >Create PDF</button>
                      <a
                        :href="`${item.pdf_path}`"
                        class="btn btn-success btn-sm m-r-2"
                        type="button"
                        v-if="item.pdf_path"
                      >View PDF</a>
                      <router-link
                        :to="{ name:  'taxiassociationfraudulentreport',  params: { id: item.verification_number }}"
                        class="btn btn-warning btn-sm m-r-2"
                        v-if="item.pdf_path"
                      >Verify PDF</router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import headerComp from "@/components/common/header.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import pageloader from "@/components/common/pageloader.vue";

import { createNamespacedHelpers } from "vuex";
import { setTimeout } from "timers";
const { mapGetters, mapActions } = createNamespacedHelpers("TA");
const imageToBase64 = require("image-to-base64");

export default {
  name: "TaxiAssociationRankSummary",
  data: function () {
    return {
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      id: this.$route.params.id,
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      rankName: "",
      inrank: null,
      outofrank: null,
      routecount: null,
      RANKSUMMARY: [],
      watermark_logo: undefined,
      LETTERSOFGOODSTANDING: [],
      ASSOCIATIONROUTES: [],
      LOGO: undefined,
      ADDRANKROUTE: {
        taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        taxiassociation_routes_id: 0,
        taxiassociation_ranks_id: this.$route.params.id,
        added_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
        document_path: '',
      },
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  beforeMount: function () {},
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters([]),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions(["getall", "delete", "edit", "add"]),
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    clearAdded: (item) => {
      for (var propName in item) {
        item[propName] = undefined;
      }
    },

    testing: () => {
      var canvas = document.createElement("CANVAS");
      var img = document.createElement("img");
      img.src = "/assets/img/logo/logodark.png";
      img.onload = function () {
        canvas.height = img.height;
        canvas.width = img.width;
        var dataURL = canvas.toDataURL("image/png");
        alert(dataURL);
        canvas = null;
      };
    },

    pdfTester: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          value.item.LOGO = context.LOGO;

          if (value.item.physical_suburb_postal_code == null) {
            value.item.physical_suburb_postal_code = "";
          }
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TA/generateLetterOfGoodStandingPdf", {
              AddModel: value.item,
              router: value.router,
            })
            .then((data) => {
              console.log("path Results: ", data);
              value.context.getbyid(value.context);
              value.context.$Notify.Success("PDF Created Successfully!", "");
            })
            .catch((error) => {
              context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        }
      });
    },

    // addRankRoute: (context, item) => {
    //   context.$Notify.YesNo(false, { context, item }).then((value) => {
    //     if (value) {
    //       value.context.$wait.start("add-");
    //       value.router = value.context.$router;
    //       value.context.$store
    //         .dispatch("TA/addRouteByRankId", {
    //           AddModel: value.item,
    //           router: value.router,
    //         })
    //         .then((id) => {
    //           value.context.$wait.end("add-");
    //           value.context.$Notify.Success("Successfully Added!", "");
    //           context.getbyid(context);
    //           $("#AddModal").modal("hide");

    //         })
    //         .catch((error) => {
    //           value.context.$Notify.Error(error.data.message, "");
    //         })
    //         .finally(() => {});
    //     }
    //   });
    // },

    getbyid: (context) => {
      context.$store
        .dispatch("TA/getLetterOfGoodStandingById", {
          id: context.taxiassociationid,
          router: context.$router,
        })
        .then((data) => {
          console.log("letters of goodstanding: ", data);

          context.LETTERSOFGOODSTANDING = data;
        })
        .catch((error) => {
          console.log("summary errors: ", error);
        });
    },

    getRoutesByAssId: (context) => {
      context.$store
        .dispatch("TA/getTaxiAssRoutes", {
          router: context.$router,
          id: context.taxiassociationid,
        })
        .then((data) => {
          console.log("Association Routes:", data);
          context.ASSOCIATIONROUTES = data;
        })
        .catch((error) => {
          console.log("Association Routes errors:", error);
        })
        .finally(() => {});
    },
    deletedata: (context, item, deletedby) => {
      context.$Notify
        .YesNo(false, { context, id: item._id, deletedby })
        .then((value) => {
          if (value) {
            value.context.$wait.start("delete-" + value.id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("district/markAsdelete", {
                _dataObj: value,
              })
              .then((id) => {
                value.context.$wait.end("delete-" + id);
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch((error) => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
  },
  mounted: function () {
    App.init();

    this.getbyid(this.context);

    const contexttemp = this;
    this.$store
      .dispatch("TA/getLOGOURL", {
        id: this.taxiassociationid,
      })
      .then((result) => {
        contexttemp.LOGO = result;
      })
      .catch((err) => {});
  },
};
</script>


<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

