<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'QuickSetup'"
      :ActiveMenu="'AddSystemUser'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Add New System User"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Association"
        breadcrumbitem3="Add"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Personal Info
                      <small class="text-ellipsis">Name, Address, ID No and DOB</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-2">
                    <span class="number">2</span>
                    <span class="info text-ellipsis">
                      Capture Photo
                      <small class="text-ellipsis">Photos required</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-3">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Complete Registration</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->
                    <div class="row">
                      <div class="col-md-8 offset-md-2">
                        <legend
                          class="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-inverse"
                        >Enter Your Personal Details</legend>
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            Username
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">@</span>
                              </div>
                              <input
                                type="text"
                                id="usernameId"
                                placeholder="Enter Username"
                                :class="['form-control',{'is-invalid': isUserNameExists, 'is-valid': !isUserNameExists}]"
                                v-model.lazy="USERNAME"
                              />
                              <div
                                class="invalid-tooltip"
                              >Please choose a unique and valid username.</div>
                              <div class="valid-tooltip">Looks good!</div>
                            </div>
                          </div>
                        </div>
                        <!-- end form-group -->
                      </div>

                      <!-- begin col-8 -->
                      <div class="col-md-8 offset-md-2">
                        <legend class="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-inverse"></legend>

                        <!-- begin form-group -->

                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            Title
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              data-size="10"
                              data-live-search="true"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              data-parsley-min="1"
                              v-model="ADDSYSTEMUSER.titleid"
                            >
                              <option value="0" selected>Select Title</option>
                              <option
                                v-bind:key="item._id"
                                v-for="item in GETALLTITLES"
                                :value="item._id"
                              >{{item._name}}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            Gender
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              data-size="10"
                              data-live-search="true"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              data-parsley-min="1"
                              v-model="ADDSYSTEMUSER.genderid"
                            >
                              <option value="0" selected>Select Gender</option>
                              <option
                                v-bind:key="item._id"
                                v-for="item in GETALLGENDERS"
                                :value="item._id"
                              >{{item._name}}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            First Name
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              name="firstname"
                              autocomplete="off"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              class="form-control"
                              v-model="ADDSYSTEMUSER.firstname"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->

                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            Surname
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="text"
                              name="surname"
                              autocomplete="off"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              class="form-control"
                              v-model="ADDSYSTEMUSER.surname"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            ID Number
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              type="text"
                              class="form-control"
                               data-parsley-maxlength="13"
                              id="masked-input-tid"
                              v-model="ADDSYSTEMUSER.idnumber"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            Mobile Number
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="Number"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              autocomplete="off"
                              class="form-control"
                              v-model="ADDSYSTEMUSER.mobilenumber"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                        <!-- begin form-group -->
                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            User Group
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <select
                              class="form-control"
                              data-size="10"
                              data-live-search="true"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              data-parsley-min="1"
                              v-model="ADDSYSTEMUSER.usergroupsid"
                            >
                              <option value="0" selected>Select a User Groups</option>
                              <option
                                v-bind:key="item._id"
                                v-for="item in GETALLUSERGROUPS"
                                :value="item._id"
                              >{{item._name}}</option>
                            </select>
                          </div>
                        </div>
                        <!-- end form-group -->
                         <!-- begin form-group -->
                         <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            Dialer Agent UserId
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <input
                              type="Number"
                              data-parsley-group="step-1"
                              data-parsley-required="false"
                              autocomplete="off"
                              class="form-control"
                              v-model="ADDSYSTEMUSER.dialeragentuserid"
                            />
                          </div>
                        </div>
                        <!-- end form-group -->
                      </div>
                      <!-- end col-8 -->
                    </div>
                    <!-- end row -->
                  </fieldset>
                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->
                <!-- begin step-2 -->
                <div id="step-2">
                  <CapturePhotosControl
                    :SessionID="ADDSYSTEMUSER.photosessionid"
                    :ShowStopStartWebCamControl="false"
                    :CameraWidth="'40%'"
                    :ShowPreviewControl="true"
                    :CameraHeight="'40%'"
                    :ClearData="true"
                    :resolutionWidth="resolutionWidth"
                    :resolutionHeight="resolutionHeight"
                  />
                </div>
                <!-- end step-2 -->

                <!-- begin step-3 -->
                <div id="step-3">
                  <div class="jumbotron m-b-0 text-center">
                    <h2 class="text-inverse">User Registered Successfully</h2>
                    <p class="m-b-30 f-s-16 ">
                      Password has been sent to the New User via SMS, Please check your phone
                    </p>
                    <p>
                      <a href="#" class="btn btn-primary btn-lg hide">Proceed to User Profile</a>
                    </p>
                  </div>
                </div>
                <!-- end step-3 -->
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("lookups");

export default {
  name: "AddSystemUser",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      SERPROFILE: this.$store.getters["auth/USERPROFILE"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      selectedUserGroupId: 0,
      GETALLUSERGROUPS: [],
      resolutionWidth: undefined,
      resolutionHeight: undefined,
      isUserNameExists: true,
      hacksmartwizard: false,
      GETALLTITLES: [],
      GETALLGENDERS: [],
      USERNAME: "",
      ADDSYSTEMUSER: {
        taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        firstname: "",
        surname: "",
        idnumber: "",
        mobilenumber: "",
        username: "",
        usergroupsid: 0,
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
        titleid: 0,
        genderid: 0,
        photosessionid: this.$uuid.v4()
      }
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    CapturePhotosControl
  },
  beforeMount() {
    this.getUserGrousByTaxiAssId(this.context);
  },
  computed: {
    ...mapGetters(["", ""])
  },
  watch: {
    USERNAME: function(val) {
      if (val.toString().length > 3) {
        this.context.$store
          .dispatch("systemusers/getSearchUsername", {
            router: this.$router,
            id: val
          })
          .then(rb => {
            this.isUserNameExists = rb.data[0].fn_system_users_usernameexists;
          });
      }
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted"
    ]),
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    clearAdded: item => {
      for (var propName in item) {
        item[propName] = undefined;
      }
    },
    init: file => {},
    loadLookUps(context) {
      
      context.$store
        .dispatch("lookups/init", {
          router: context.$router,
        })
        .then((data) => {
          console.log("loadLookUps found: ", data);
          
          context.GETALLTITLES = data.LOADEDTITLES;
          context.GETALLGENDERS = data.LOADEDGENDER;
        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
    getByTaxiAssId: context => {
      context.$store
        .dispatch("systemusers/getByTaxiAssId", {
          _dataObj: {
            router: context.$router,
            id: context.taxi_association_id
          }
        })
        .then(() => {
          App.init();
          TableManageCombine.init();
          Highlight.init();
        });
    },
    getUserGrousByTaxiAssId: context => {
      context.$store
        .dispatch("usergroups/getByTaxiAssId", {
          router: context.$router,
          id: context.taxi_association_id,
          getbyid: context.USERID
        })
        .then((data) => {
          
          context.GETALLUSERGROUPS = data;
        });
    }
  },
  mounted: function() {
    //this.get(this.context);

    App.init();

    this.loadLookUps(this.context);


    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "slide",
      transitionSpeed: 400,
      useURLhash: false,
      showStepURLhash: false,
      toolbarSettings: {
        toolbarPosition: "bottom"
      }
    });

    $("#wizard").data("context", this.context);

    $("#wizard").on("leaveStep", function(
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {
     
      const context = $("#wizard").data("context");
      const currentstep = stepNumber + 1;
      var res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

        if(context.hacksmartwizard)
        return true;


      $(".sw-btn-next").text("Next");

      if (currentstep === 1 && stepDirection == "forward") {
        if (context.isUserNameExists)
          return false;
          else
          $(".sw-btn-next").text("Submit");
      }

      if (currentstep === 2 && stepDirection == "forward") {

           const context = $("#wizard").data("context");

       if(context.$store.getters["capturephotos/GETALL"].length < 2)
        {
          context.$Notify.Error("Please Add More Photos", "");
          return false;
        };

        context.ADDSYSTEMUSER.username = context.USERNAME;

        context.$Notify
          .YesNo(false, { context, item: context.ADDSYSTEMUSER })
          .then(value => {
            if (value) {
              $(".sw-btn-next")
                .text("")
                .append('<i class="fas fa-spinner fa-spin"></i>')
                .attr("disabled", "disabled");
              value.router = value.context.$router;
              value.context.$store
                .dispatch("systemusers/add", {
                  _dataObj: value
                })
                .then(() => {
                   value.context.hacksmartwizard = true;
            $("#wizard").smartWizard("next");
                  $(".sw-btn-next")
                    .text("Done")
                    .attr("disabled", "disabled");
                })
                .catch(error => {
                  $(".sw-btn-next")
                    .text("Submit")
                    .removeAttr("disabled", "disabled");

                  value.context.$Notify.Error(error.data.message, "");
                })
                .finally(() => {});
            }
          });

        return false;
      }

      return res;
    });

  }
};
</script>

<style scoped>
</style>

