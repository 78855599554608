/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../../../configurations/configbase';


export default {
  namespaced: true,
  state: {
    dashboard_cards: [],
    dashboardfinance: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETBYREPORTID: state => (code) => {
        try {
          return state.dashboard_cards.filter(t => t.report_code === code)[0];
        } catch (error) {
        }
      },
      GETBYREPORTIDS: state => (code) => {
        try {
          return state.dashboard_cards.filter(t => t.report_code === code);

        } catch (error) {
        }
      },
      GETFINANCEBYREPORTID: state => (code) => {
        try {
          return state.dashboardfinance.filter(t => t.report_code === code)[0];
        } catch (error) {
        }
      },
      GETFINANCEBYREPORTIDS: state => (code) => {
        try {
          return state.dashboardfinance.filter(t => t.report_code === code);

        } catch (error) {
        }
      },
      GETBYTAXIAFFILIATIONBYREPORTIDS: state => (code,year) => {
        try {
          
          return state.dashboardfinance.filter(t => t.report_code === code && t.additional2 === year);

        } catch (error) {
        }
      },
  },
  mutations: {
    LOADED: (state, _data) => {
        if (_data !== undefined) {
          state.dashboard_cards = _data;
        }
      },
      LOADEDFINANCE: (state, _data) => {
        if (_data !== undefined) {
          state.dashboardfinance = _data;
        }
      },
  },
  actions: {
    /* Async */

    
    /* DashBoard Cards */
    getdashboard({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router }) {
      return new Promise((resolve, reject) => {
       
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSADMINDASHBOARDCARDS_URL,
        };

        this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
          
          if (parsedResult.status) {
            console.log(parsedResult);
            commit('LOADED', parsedResult.data);
            resolve();
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
    getfinancedashboard({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router }) {
      return new Promise((resolve, reject) => {
       
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSADMINDASHBOARDFINANCECARDS_URL,
        };

        this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
          if (parsedResult.status) {
            console.log(parsedResult);
            commit('LOADEDFINANCE', parsedResult.data);
            resolve();
          // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
        // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
}
};
