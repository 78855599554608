<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageVehicle'"
      :ActiveMenu="'Taxi Operator Vehicles'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Taxi Vehicle"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Vehicles"
        breadcrumbitem3="Manage Vehicle"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">All Vehicles</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <a
                  data-toggle="modal"
                  data-target="#AddModal"
                  class="btn btn-white"
                  href="javascript:;"
                >
                  <i class="fa fa-plus"></i>

                  <router-link to="/Vehicle/Add/:id">Add Taxi Vehicle</router-link>
                </a>
              </div>
            </div>

            <!-- <div v-if="!dataLoaded" class="btn-row">
              <div class="text-center">
                <i class="fas fa-circle-notch fa-spin fa-10x fa-blue"></i>
              </div>
            </div> -->

            <br />

            <div v-if="!dataLoaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-default" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Model/Make</th>
                    <th class="text-nowrap">License Plate</th>
                    <th class="text-nowrap">Vin No</th>
                    <th class="text-nowrap">Operator Name</th>
                    <th class="text-nowrap">Driver</th>
                    <th class="text-nowrap">Balance</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Last Updated By</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETVEHICLEBYASSID"
                    v-bind:key="item._taxi_operator_vehicles_id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item._vehicle_make+' '+item._vehicle_model}}</td>
                    <td>{{item._licence_plate}}</td>
                    <td>{{item._vehicle_vin}}</td>
                    <td>{{item._taxi_operators_name}}</td>
                    <td>{{item._taxidrivers_name}}</td>
                    <td>{{item._available_balance | currencywithSymbol}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._deleted_by }"
                        v-if="item._deleted_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12 badge-success"
                        v-if="!item._deleted_by && item.status=='Active'"
                      >{{item.status}}</span>
                      <span
                        class="badge f-s-12 label-secondary"
                        v-if="!item._deleted_by && item.status!=='Active'"
                      >{{item.status}}</span>
                    </td>
                    <td>{{item._deletedby_name || item._updateby_name || item._addedby_name}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <!-- <button
                      type="button"
                      class="btn btn-sm btn-primary width-120 m-r-2"
                      @click="CollectViaDebitOrder(context, item.operator_vehicle_id)"
                    :disabled="$wait.is('collectviadebitorder-' + item.operator_vehicle_id)"
                    >
                                  <v-wait :for="'collectviadebitorder-' + item.operator_vehicle_id">
                                    <template slot="waiting">
                                      <i class="fas fa-spinner fa-spin"></i>
                                    </template>
                                    Collect Via Debit Order
                                  </v-wait>
                    
                    </button> -->
                      <button
                      type="button"
                      class="btn btn-sm btn-success width-120 m-r-2 hide"
                      @click="VerifyOwnershipInformation(context, item.operator_vehicle_id)"
                    :disabled="$wait.is('underBreakDown-' + item.operator_vehicle_id)"
                    >
                                  <v-wait :for="'underBreakDown-' + item.operator_vehicle_id">
                                    <template slot="waiting">
                                      <i class="fas fa-spinner fa-spin"></i>
                                    </template>
                                    Verify With eNatis
                                  </v-wait>
                    
                    </button>
                      <router-link
                        :to="{ name: 'taxivehicleprofile', params: { id: item._taxi_operator_vehicles_id }}"
                        class="btn btn-sm btn-white width-60 m-r-2"
                      >View</router-link>
                      <router-link
                        :to="{ name:  'updatevehicle',  params: { id: item._taxi_operator_vehicles_id }}"
                        class="btn btn-sm btn-primary width-60 m-r-2"
                        v-if="item._taxi_operator_vehicles_id"
                      >Edit</router-link>
                      <!-- <router-link
                        :to="{ name:  'changeofownership',  params: { id: item._taxi_operator_vehicles_id }}"
                        class="btn btn-sm btn-warning width-120 m-r-2"
                        v-if="item._taxi_operator_vehicles_id"
                      >Change Ownership</router-link> -->
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-60 m-r-2"
                        v-if="ISINTERNALSYSTEMUSER && !item._deleted_by || USERGROUPCODE=='CHM'"
                        @click="Delete(context,item._taxi_operator_vehicles_id,USERID)"
                        :disabled="$wait.is('delete-' + item._taxi_operator_vehicles_id)"
                      >
                        <v-wait v-bind:for="'delete-' + item._taxi_operator_vehicles_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Delete
                        </v-wait>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-60 m-r-2"
                        v-if="ISINTERNALSYSTEMUSER && item._deleted_by || USERGROUPCODE=='CHM'"
                        @click="Restore(context,item._taxi_operator_vehicles_id,USERID)"
                        :disabled="$wait.is('restore-' + item._taxi_operator_vehicles_id)"
                      >
                        <v-wait v-bind:for="'restore-' + item._taxi_operator_vehicles_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Restore
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("OperatorVehicle");

export default {
  name: "ManageVehicles",
  data: function () {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$route.params.id || this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      USERGROUPCODE: this.$store.getters["auth/USERPROFILE"].usergroups_code,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      context: this,
      GETVEHICLEBYASSID: [],
      addedsuccess: undefined,
      errormessage: undefined,
      id: this.$route.query.id,
      approvalid: this.$route.query.approvalid,
      skip: 0,
      length: 10000,
      dataLoaded: false,
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters({}),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions([
      "getall",
      "getByPaging",
      "getVehiclesByAssId",
      "getVehiclesByAssIdSummary",
      "delete",
      "edit",
      "add",
      "markAsDeleted",
      "restoreDeleted",
    ]),
    CollectViaDebitOrder: (context, vehicleid) => {

      context.$Notify.Info("Collect Levies Easy Via Debit Order","").then((value) => {
        if (value) {
          value.context.$wait.start("collectviadebitorder-" + value.vehicleid);
          value.router = value.context.$router;
          value.context.getVehiclePlaceUnderBreakdown({
              router: value.router,
              taxiassociationid:context.taxiassociationid,
              taxivehicleid:value.vehicleid,
              updatedby: context.USERID
            }).then(() => {
              value.context.VEHICLEPROFILE.vehicle_status = 'BreakDown'
              value.context.$wait.end("collectviadebitorder-" + value.vehicleid);
              value.context.$Notify.Success("Taxi Status Is Breakdown!", "");
            })
            .catch((error) => {
              value.context.$wait.end("collectviadebitorder-" + value.vehicleid);

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });

    },
    VerifyOwnershipInformation: (context, vehicleid) => {
      context.$Notify.Info("Verify Ownership Against eNatis Databse Is Coming Soon","").then((value) => {
        if (value) {
          value.context.$wait.start("underBreakDown-" + value.vehicleid);
          value.router = value.context.$router;
          value.context.getVehiclePlaceUnderBreakdown({
              router: value.router,
              taxiassociationid:context.taxiassociationid,
              taxivehicleid:value.vehicleid,
              updatedby: context.USERID
            }).then(() => {
              value.context.VEHICLEPROFILE.vehicle_status = 'BreakDown'
              value.context.$wait.end("underBreakDown-" + value.vehicleid);
              value.context.$Notify.Success("Taxi Status Is Breakdown!", "");
            })
            .catch((error) => {
              value.context.$wait.end("underBreakDown-" + value.vehicleid);

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });

    },
    Delete: (context, id, deletedby) => {
      
      context.$Notify.YesNo(false, { context, id, deletedby }).then((value) => {
        if (value) {
          value.context.$wait.start("delete-" + value.id);

          value.context
            .markAsDeleted({
              router: value.context.$router,
              id: value.id,
              deletedby: value.deletedby,
            })
            .then(() => {
              value.context.GETVEHICLEBYASSID = value.context.GETVEHICLEBYASSID.filter(item => item._taxi_operator_vehicles_id !== value.id);
              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Success("Successfully Deleted!", "");
            })
            .catch((error) => {
              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    Restore: (context, id, restoredby) => {
      context.$Notify
        .YesNo(false, { context, id, restoredby })
        .then((value) => {
          if (value) {
            value.context.$wait.start("restore-" + value.id);

            

            value.context
              .restoreDeleted({
                router: value.context.$router,
                id: value.id,
                restoredby: value.restoredby,
              })
              .then(() => {

                value.context.GETVEHICLEBYASSID.filter(item => item._taxi_operator_vehicles_id === value.id)[0]._deleted_by = undefined;
                value.context.GETVEHICLEBYASSID.filter(item => item._taxi_operator_vehicles_id === value.id)[0]._date_deleted = undefined;

                value.context.$wait.end("restore-" + value.id);
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch((error) => {
                value.context.$wait.end("restore-" + value.id);
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    getbyid: (context, taxiassociationid) => {
      context
        .getVehiclesByAssIdSummary({
          router: context.$router,
          taxiassociation_id: taxiassociationid,
          skip: context.skip,
          len: context.length,
        })
        .then((data) => {
          console.log("These are the vehicles:(vue page) ", data);

          context.GETVEHICLEBYASSID = data;
          context.dataLoaded = true;

          // TableManageCombine.init();
        })
        .catch((error) => {
          console.log("vehicles error: ", error);
        })
        .finally(() => {
          var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: false,
                    colReorder: true,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }

                 

              $("#data-table-default").DataTable(options);
        });
    },

    setAdd(val) {
      this.$store
        .dispatch("OperatorVehicle/add", {
          _dataObj: val,
        })
        .then(() => {});
    },
  },
  beforeMount: function () {
    // this.getbyid(this.context, this.taxiassociationid);
    // $("#data-table-default").DataTable({
    //   responsive: true
    // });
  },
  mounted: function () {
    App.init();
    this.getbyid(this.context, this.taxiassociationid);

    //  this.getByTaxiAssId(this.context);
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

